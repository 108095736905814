import React, { useState, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { useCookies } from "react-cookie";

import { domainContext } from "./DomainContext";
import { errorContext } from "./ErrorContext";
import { UserContext } from "./UserContext";
// import { displayContext } from "./DisplayContext";

import useEnv from "../hooks/useEnv";

// import { Config } from "../../config";

export const AuthContext = React.createContext(null);

const AuthProvider = ({ children }) => {
    const navigate = useNavigate();
    const location = useLocation();

    const { setCurrentDomain, setCurrentKnowledgeBase, originalPath } =
        useContext(domainContext);
    const { setErrors, setLoginError } = useContext(errorContext);
    const { loggedIn, setLoggedIn } = useContext(UserContext);

    const [{ backend }] = useEnv();

    const [cookies, setCookie, removeCookie] = useCookies(["user"]);
    const [token, setToken] = React.useState(null);
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [userType, setUserType] = useState(getUserType());

    function getUserType() {
        const userUserType = cookies.userType;
        return userUserType && userUserType;
    }

    const findFormErrors = () => {
        const newErrors = {};

        if (username == "") {
            newErrors.username = "loginUsernameEmpty";
        }

        if (password == "") {
            newErrors.password = "loginPasswordEmpty";
        }

        return newErrors;
    };

    // Fake authentication for testing purposes
    const fakeAuth = () =>
        new Promise((resolve) => {
            setTimeout(() => resolve("2342f2f1d131rf12"), 250);
        });

    // Authenticate user against database userid / password
    async function realAuth() {
        var obj = {};
        var loginInfo = "";

        obj.email = username;
        obj.password = password;


        // var endpoint = process.env.REACT_APP_FLASK_API + "token";
        var endpoint = process.env.REACT_APP_FLASK_API + "token";


        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(obj),
        })
            .then((res) => res.json())
            .then((response) => {
                if (response.message.messageCode == "400") {
                    loginInfo = jwt_decode(response.access_token);
                    return {
                        messageCode: response.message.messageCode,
                        userType: loginInfo["userType"],
                        token: response.access_token,
                    };
                } else if (
                    response.message.messageCode == "401" ||
                    response.message.messageCode == "402"
                ) {
                    return {
                        messageCode: response.message.messageCode,
                        userType: null,
                        token: null,
                    };
                } else {
                    console.log("Login - failed");
                }
            })

            .catch((error) => {
                if (error.response) {
                    console.log(error.response);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
    }

    // async function submitLoginHandler() {
    function submitLoginHandler() {
        console.log("AuthContext - location1: ", location);
        // Get our new errors
        const newErrors = findFormErrors();
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors);
        } else {
            // No errors! Put any logic here for the form submission!
            newErrors.username = "";
            newErrors.password = "";
            setErrors(newErrors);
            handleLogin();
        }
    }

    async function handleLogin() {
        const { messageCode, userType, token } = await realAuth();
        let loginError = "";

        if (messageCode == 401 || messageCode == 402) {
            console.log("AuthContext - login failed with code ", messageCode);
            loginError = "loginUserPwdFail";
            setLoginError(loginError);
        } else if (messageCode == 400) {
            console.log("AuthContext - token: ", token);
            setToken(token);
            setCookie("token", token, { path: "/" });

            setUserType(userType);
            setCookie("userType", userType, { path: "/" });

            setLoggedIn(true);

            // Read the current domain from localStorage if it exists
            // and set the the currentDomain variable, which in turn
            // will trigger loading of the relevant attributes
            // const storedDomain = localStorage.getItem("domain")
            //     ? localStorage.getItem("domain")
            //     : "noDomain";

            // console.log("AuthContext - storedDomain: ", storedDomain);

            // setCurrentDomain("auto");
            // setCurrentDomain(storedDomain);

            // navigate("/evaluateCase");

            // navigate("/TM1");

            // console.log("AuthContext.js - originalPath: ", originalPath);

            // setCurrentKnowledgeBase("test");

            originalPath == "" ? navigate("tm") : navigate(originalPath);

            // if (originalPath == "tm") {
            //     setCurrentKnowledgeBase("varemaerke");
            //     navigate("tm");
            // } else navigate(originalPath);
        }
    }

    async function backendLogout() {
        var obj = {};
        obj.email = username;

        // var endpoint = process.env.REACT_APP_FLASK_API + "logout";
        var endpoint = process.env.REACT_APP_FLASK_API + "logout";


        return fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(obj),
        })
            .then((response) => {
                response.json();
            })
            .catch((error) => {
                if (error.response) {
                    console.log(error.response);
                    console.log(error.response.status);
                    console.log(error.response.headers);
                }
            });
    }

    const handleLogout = () => {
        // setToken(null);
        backendLogout();
        // removeToken();
        removeCookie("token", { path: "/" });
        removeCookie("userType", { path: "/" });
        setLoginError("");
        setToken(null);
        setLoggedIn(false);

        //

        //
    };

    const value = {
        // onLogin: handleLogin,
        onLogin: submitLoginHandler,
        onLogout: handleLogout,
        password,
        setPassword,
        setUsername,
        token,
        userType,
        username,
    };

    return (
        <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
    );
};

export default AuthProvider;
