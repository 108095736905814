import React, { useContext, useState } from "react";

import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import Modal from "react-modal";

import { domainContext } from "../../contexts/DomainContext";
import { evaluationContext } from "../../contexts/EvaluationContext";
import { displayContext } from "../../../common/DisplayContext.js";
import { errorContext } from "../../contexts/ErrorContext";
import { userCaseContext } from "../../contexts/UserCaseContext";
import { rejectedCaseContext } from "../../contexts/CaseContext";
import useAuth from "../../hooks/useAuth";

import styles from '../../styles.module.css'

import Messages from "../Messages.js";
import useClearCase from "../../hooks/useClearCase";
// import ModalCaseRejected from "../Alerts/ModalCaseRejected";

///////////////////////////////////////////////////////////////////////////////////
// react-modal stuff start
///////////////////////////////////////////////////////////////////////////////////

// https://www.npmjs.com/package/react-modal

const customStyles = {
    content: {
        top: "20%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        background: "#E6E6E6",
        width: "25%",
    },
    overlay: {
        zIndex: 1000,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgb(102,102,102,0.8)",
    },
};

///////////////////////////////////////////////////////////////////////////////////
// react-modal stuff end
///////////////////////////////////////////////////////////////////////////////////

function ButtonReject() {
    ///////////////////////////////////////////////////////////////////////////////////
    // react-modal stuff start
    ///////////////////////////////////////////////////////////////////////////////////

    let subtitle = "subtitle";
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = "#f00";
    }

    function closeModal() {
        setIsOpen(false);
    }

    ///////////////////////////////////////////////////////////////////////////////////
    // react-modal stuff end
    ///////////////////////////////////////////////////////////////////////////////////

    const { userCase, caseStatus } = useContext(userCaseContext);

    const {
        buttonRejectDisabled,
        setDisplayMessage,
        setIsLoading,
        t,
        // setModalCaseRejected,
    } = useContext(displayContext);

    const { 
        currentKnowledgeBase, 
        currentDomain 
    } = useContext(domainContext);

    const { 
        currentCaseId, 
        nodeLastTrue, 
        setReturnCode 
    } =
        useContext(evaluationContext);

    const { setErrors } = useContext(errorContext);

    const {
        reloadRejectedCaseList,
        setReloadRejectedCaseList,
        reloadCaseList,
        setReloadCaseList,
        triggerCaseRejected,
        setTriggerCaseRejected,
    } = useContext(rejectedCaseContext);

    // const { token } = useContext(TokenContext);
    const { token } = useAuth();

    const [{ handleClear }] = useClearCase();
    // const useToastCaseRejected = useToastCaseRejected();

    const [show, setShow] = useState(false);

    const [rejectComment, setRejectComment] = useState("");

    const findFormErrors = () => {
        const newErrors = {};

        // Check if currentCaseId is too long ONLY if it actually exists
        if (
            typeof currentCaseId !== "undefined" &&
            currentCaseId &&
            currentCaseId !== "" &&
            currentCaseId.length > 30
        )
            newErrors.currentCaseId =
                "Case no skal være mindre end 30 tegn langt";

        return newErrors;
    };

    function submitRejectHandler(e) {
        e.preventDefault();
        console.log("ButtonReject - submitRejectHandler - start");

        // Get our new errors
        const newErrors = findFormErrors();
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors);
        } else {
            // No errors! Put any logic here for the form submission!

            // setShow(true);
            console.log("ButtonReject - submitRejectHandler - opening modal");
            openModal();
        }
    }

    //   Error handling end

    function handleSaveRejectComment(e) {
        setTriggerCaseRejected(!triggerCaseRejected);
        setIsOpen(false);

        var obj = {};

        obj.mode = "reject";
        obj.caseData = userCase;

        if (currentCaseId == undefined) {
            obj.busCaseId = "";
        } else {
            obj.busCaseId = currentCaseId;
        }
        obj.knowledgeBase = currentKnowledgeBase;
        obj.domain = currentDomain;
        obj.category = "";
        obj.nodeLastTrue = nodeLastTrue;
        obj.comment = rejectComment;
        obj.status = "rejected";

        // Do we need this?
        // Clear fields in window
        setDisplayMessage({
            messageCode: null,
            messageText: "Processing...",
            // messageText: t('processing'),
            displayCode: 1,
        });

        // Do we need this?
        setIsLoading(true);

        var endpoint = process.env.REACT_APP_FLASK_API + "accept";

        fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(obj),
        })
            .then((res) => res.json())
            .then((responseJson) => {
                setReturnCode(responseJson["returnCode"]);

                // Interpret the return code sent by the server

                if (responseJson["message"].messageCode !== 0) {
                    setDisplayMessage(Messages(responseJson["message"]));
                }
                // setDisplayMessage("");
                setDisplayMessage(Messages(responseJson["message"]));
                setReloadRejectedCaseList(!reloadRejectedCaseList);
                setReloadCaseList(!reloadCaseList);
            });

        setIsLoading(false);

        setShow(false);

        handleClear();

        // These lines show a modal-message..
        // setModalCaseRejected(true);
        // ...and then makes it automatically disappear
        // setTimeout(() => {
        //     setModalCaseRejected(false);
        // }, 10000);
    }

    function handleRejectCommentChange(e) {
        setRejectComment(e.target.value);
    }

    const handleClose = () => setShow(false);

    return (
        <>
            <OverlayTrigger
                trigger={["click", "hover", "focus"]}
                delay={400}
                delayHide={0}
                placement={"auto"}
                overlay={<Tooltip>{t("buttonRejectTooltip")}</Tooltip>}
            >
                <div>
                    <Button
                        variant="danger"
                        className={styles.standardButton}
                        onClick={submitRejectHandler}
                        disabled={
                            buttonRejectDisabled || caseStatus == "rejected"
                        }
                    >
                        {t("reject")}
                    </Button>
                </div>
            </OverlayTrigger>
            <Modal
                isOpen={modalIsOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <p className="reject-comment-heading">{t("rejectComment")}</p>
                <textarea
                    className="reject-comment-textarea"
                    value={rejectComment}
                    onChange={handleRejectCommentChange}
                ></textarea>
                <div>
                    <button
                        style={{
                            float: "right",
                            marginTop: "30px",
                        }}
                        className="mentor-button1"
                        onClick={handleSaveRejectComment}
                    >
                        {t("save")}
                    </button>
                </div>
            </Modal>
        </>
    );
}

export default ButtonReject;
