import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    useCallback,
} from 'react'

import i18n from '../../translations/i18n'

export const swiperContext = createContext()

const SwiperContextProvider = ({ children }) => {
    i18n.changeLanguage('da')

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)
    const [fetchedChat, setFetchedChat] = useState(null)

    const [messages, setMessages] = useState([
        {
            message:
                'Hej, beskriv hvordan du bruger varemærket, så forsøger jeg at udtrække de relevante detaljer og hakker de relevante kriterier af. Du kan tilføje og fjerne hakker, hvis jeg tager fejl. Til slut skal du klikke på check-knappen, for at se om du har ret til varemærket.',
            sender: 'ChatGPT',
            direction: 'incoming',
        },
    ])
    const [typing, setTyping] = useState(false)

    const fetchData = useCallback(async function (chatTextLocal) {
        setLoading(true)
        setError(null)
        setChatText(chatTextLocal)

        let newSelectedOptions = []
        var obj = {}
        obj.textToConvert = chatTextLocal
        var endpoint = process.env.REACT_APP_FLASK_API + 'chatToJSON'

        try {
            const response = await fetch(endpoint, {
                method: 'POST',

                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },

                body: JSON.stringify(obj),
            })

            const result = await response.json()

            // setFetchedChat(result)

            for (var key in result) {
                const optionDescription = optionDescriptions.find(
                    (option) =>
                        option.attributeName == key &&
                        option.attributeGroup != 'prompt'
                )

                if (optionDescription != undefined) {
                    newSelectedOptions.push({
                        ball: optionDescription.ball,
                        row: optionDescription.row,
                        column: optionDescription.column,
                    })
                }
            }

            setSelectedOptions(newSelectedOptions)
        } finally {
            setLoading(false)
        }
    }, [])

    const fetchDataQuery = useCallback(async function (
        apiMessages,
        chatMessages
    ) {
        setTyping(true)
        setLoading(true)
        setError(null)
        // setChatText(chatTextLocal)

        let newSelectedOptions = []
        var obj = {}
        // obj.textToConvert = chatTextLocal
        obj.apiMessages = apiMessages
        var endpoint = process.env.REACT_APP_FLASK_API + 'chatToJSONQuery'

        try {
            const response = await fetch(endpoint, {
                method: 'POST',

                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },

                body: JSON.stringify(obj),
            })

            const result = await response.json()

            console.log('SwiperContext - chatResult: ', result.chatResult)
            console.log('SwiperContext - jsonResult: ', result.jsonResult)

            setMessages([
                ...chatMessages,
                {
                    message: result.chatResult,
                    sender: 'ChatGPT',
                    direction: 'incoming',
                },
            ])

            // setFetchedChat(result)

            for (var key in result.jsonResult) {
                const optionDescription = optionDescriptions.find(
                    (option) =>
                        option.attributeName == key &&
                        option.attributeGroup != 'prompt'
                )

                if (optionDescription != undefined) {
                    newSelectedOptions.push({
                        ball: optionDescription.ball,
                        row: optionDescription.row,
                        column: optionDescription.column,
                    })
                }
            }

            setSelectedOptions(newSelectedOptions)

            setTyping(false)
        } finally {
            setLoading(false)
        }
    },
    [])

    const [aggConclusion, setAggConclusion] = useState('')

    // The current width of the viewport
    const width = window.innerWidth

    // The width below which the mobile view should be rendered
    const breakpoint = 620

    // The case being built by the user entering options in the UI
    const [userCase, setUserCase] = useState([])

    const [showConclusion, setShowConclusion] = useState(false)

    // The options that define a case are located within a three-dimensional
    // 'cube' with the dimensions ball, row, column. A location is represented
    // as an object e.g. {"ball": 0, "row": 0, "column": 0}.

    // Focus: this is the location of the end-user's focus within the
    // options cube dimensions ball, row and column. Only one item can be
    // in focus at any given time.

    // Active options: these are the options that are in focus in each
    // ball/column. Each column can have one active option. This is used to
    // determine if an item in a list should be highlighted on entry (?).

    // Selected options: these are the options that the end-user has clicked.
    // Options are located within the three-dimensional options cube.

    // Active slide selected (should be renamed to 'focusSelected')
    // is true when the item in focus (option or ball) was selected by
    // the end-user.

    const [swiper1ActiveSlideHorizontal, setSwiper1ActiveSlideHorizontal] =
        useState(0)
    const [swiper1ActiveSlideVertical, setActiveSlideVertical] = useState([
        0, 0, 0, 0,
    ])

    // This is the selected option within each vertical swiper
    const [swiper1SelectedVertical, setSwiper1SelectedVertical] = useState([
        -1, -1, -1, -1,
    ])

    const [focus, setFocus] = useState({ ball: 0, row: 0, column: 0 })

    const [addEmptyItemsToList, setAddEmptyItemsToList] = useState(false)

    // Two-dimensional array representing the currently active slide in Swiper2

    // The position of the option that is the current focus for the end-user
    const [focusedOption, setFocusedOption] = useState({
        ball: 0,
        row: 0,
        column: 0,
    })

    // The description under the swipeball describing the option in focus
    const [optionDescription, setOptionDescription] = useState('')

    const [optionDescriptions, setOptionDescriptions] = useState([
        {
            ball: 0,
            row: 0,
            column: 0,
            text: '',
            attributeGroup: 'prompt',
            attributeName: '',
        },

        {
            ball: 1,
            row: 0,
            column: 0,
            text: 'Bruger du dit kendetegn som dit brand?',
            attributeGroup: 'prompt',
            attributeName: 'useAsBrand',
        },
        {
            ball: 1,
            row: 0,
            column: 1,
            text: 'Ja, jeg bruger mit kendetegn som mit brand?',
            attributeGroup: 'use',
            attributeName: 'useAsBrand',
        },
        {
            ball: 1,
            row: 0,
            column: 2,
            text: 'Nej, jeg bruger ikke mit kendetegn som mit brand?',
            attributeGroup: 'use',
            attributeName: 'useAsBrandNot',
        },

        {
            ball: 2,
            row: 0,
            column: 0,
            text: 'Bruger du dit kendetegn uden ændringer?',
            attributeGroup: 'prompt',
            attributeName: 'oneVersion',
        },
        {
            ball: 2,
            row: 0,
            column: 1,
            text: 'Ja, jeg bruger du mit kendetegn uden ændringer?',
            attributeGroup: 'version',
            attributeName: 'oneVersion',
        },
        {
            ball: 2,
            row: 0,
            column: 2,
            text: 'Nej, jeg bruger ikke mit kendetegn uden ændringer?',
            attributeGroup: 'version',
            attributeName: 'oneVersionNot',
        },

        {
            ball: 3,
            row: 0,
            column: 0,
            text: 'Hvor bruger du dit kendetegn?',
            attributeGroup: 'prompt',
            attributeName: '',
        },

        {
            ball: 3,
            row: 0,
            column: 1,
            text: 'Steder du bruger dit kendetegn',
            attributeGroup: 'prompt',
            attributeName: '',
        },
        {
            ball: 3,
            row: 1,
            column: 1,
            text: 'Jeg bruger mit kendetegn i en biograf',
            attributeGroup: 'place',
            attributeName: 'placeCinema',
            attributeText: 'biograf',
            subCategory: 'place',
        },
        {
            ball: 3,
            row: 2,
            column: 1,
            text: 'Jeg bruger mit kendetegn i en butik',
            attributeGroup: 'place',
            attributeName: 'placeShop',
            attributeText: 'butik',
            subCategory: 'place',
        },
        {
            ball: 3,
            row: 3,
            column: 1,
            text: 'Jeg bruger mit kendetegn i et fitnesscenter',
            attributeGroup: 'place',
            attributeName: 'placeFitnessCenter',
            attributeText: 'fitnesscenter',
            subCategory: 'place',
        },
        {
            ball: 3,
            row: 4,
            column: 1,
            text: 'Jeg bruger mit kendetegn i en forlystelsespark',
            attributeGroup: 'place',
            attributeName: 'placeAmusementPark',
            attributeText: 'forlystelsespark',
            subCategory: 'place',
        },
        {
            ball: 3,
            row: 5,
            column: 1,
            text: 'Jeg bruger mit kendetegn i en forening',
            attributeGroup: 'place',
            attributeName: 'placeAssociation',
            attributeText: 'forening',
            subCategory: 'place',
        },
        {
            ball: 3,
            row: 6,
            column: 1,
            text: 'Jeg bruger mit kendetegn i et hotel',
            attributeGroup: 'place',
            attributeName: 'placeHotel',
            attributeText: 'hotel',
            subCategory: 'place',
        },
        {
            ball: 3,
            row: 7,
            column: 1,
            text: 'Jeg bruger mit kendetegn i en klinik',
            attributeGroup: 'place',
            attributeName: 'placeClinic',
            attributeText: 'klinik',
            subCategory: 'place',
        },
        {
            ball: 3,
            row: 8,
            column: 1,
            text: 'Jeg bruger mit kendetegn i en madbod',
            attributeGroup: 'place',
            attributeName: 'placeFoodstall',
            attributeText: 'madbod',
            subCategory: 'place',
        },
        {
            ball: 3,
            row: 9,
            column: 1,
            text: 'Jeg bruger mit kendetegn på en messe',
            attributeGroup: 'place',
            attributeName: 'placeExhibition',
            attributeText: 'messe',
            subCategory: 'place',
        },
        {
            ball: 3,
            row: 10,
            column: 1,
            text: 'Jeg bruger mit kendetegn i en restaurant',
            attributeGroup: 'place',
            attributeName: 'placeRestaurant',
            attributeText: 'restaurant',
            subCategory: 'place',
        },
        {
            ball: 3,
            row: 11,
            column: 1,
            text: 'Jeg bruger mit kendetegn på et spillested',
            attributeGroup: 'place',
            attributeName: 'placeClub',
            attributeText: 'spillested',
            subCategory: 'place',
        },
        {
            ball: 3,
            row: 12,
            column: 1,
            text: 'Jeg bruger mit kendetegn i et stormagasin',
            attributeGroup: 'place',
            attributeName: 'placeDepartmentStore',
            attributeText: 'stormagasin',
            subCategory: 'place',
        },
        {
            ball: 3,
            row: 13,
            column: 1,
            text: 'Jeg bruger mit kendetegn i et supermarked',
            attributeGroup: 'place',
            attributeName: 'placeSupermarket',
            attributeText: 'supermarked',
            subCategory: 'place',
        },

        {
            ball: 3,
            row: 0,
            column: 2,
            text: 'Jeg bruger mit kendetegn i en by',
            attributeGroup: 'place',
            attributeName: 'placeTown',
            attributeText: 'by',
            subCategory: 'town',
        },

        {
            ball: 3,
            row: 14,
            column: 1,
            text: 'Udland rettet mod DK',
            attributeGroup: 'place',
            attributeName: 'placeAbroadTowardDK',
            attributeText: 'udlandet rettet mod Danmark',
            subCategory: 'abroad',
        },
        {
            ball: 3,
            row: 15,
            column: 1,
            text: 'Udland ikke rettet mod DK',
            attributeGroup: 'place',
            attributeName: 'placeAbroadNotTowardDK',
            attributeText: 'udlandet ikke rettet mod Danmark',
            subCategory: 'abroad',
        },

        {
            ball: 3,
            row: 0,
            column: 3,
            text: 'Jeg bruger mit kendetegn i Danmark',
            attributeGroup: 'prompt',
            attributeName: 'placeDenmark',
            attributeText: '',
            subCategory: 'denmark',
        },
        {
            ball: 3,
            row: 1,
            column: 3,
            text: 'Jeg bruger mit kendetegn i hele landet',
            attributeGroup: 'place',
            attributeName: 'placeWholeDenmark',
            attributeText: 'hele Danmark',
            subCategory: 'denmark',
        },
        {
            ball: 3,
            row: 2,
            column: 3,
            text: 'Jeg bruger mit kendetegn i en region',
            attributeGroup: 'place',
            attributeName: 'placeRegion',
            attributeText: 'region',
            subCategory: 'denmark',
        },
        {
            ball: 3,
            row: 3,
            column: 3,
            text: 'Jeg bruger mit kendetegn i Grønland',
            attributeGroup: 'place',
            attributeName: 'placeGreenland',
            attributeText: 'Grønland',
            subCategory: 'denmark',
        },
        {
            ball: 3,
            row: 4,
            column: 3,
            text: 'Jeg bruger mit kendetegn i Færøerne',
            attributeGroup: 'place',
            attributeName: 'placeFaroeIslands',
            attributeText: 'Færøerne',
            subCategory: 'denmark',
        },

        {
            ball: 3,
            row: 0,
            column: 4,
            text: 'Jeg bruger mit kendetegn i udlandet',
            attributeGroup: 'prompt',
            attributeName: '',
            attributeText: '',
        },
        {
            ball: 3,
            row: 1,
            column: 4,
            text: 'Min brug er rettet mod Danmark',
            attributeGroup: 'denmark',
            attributeName: 'placeDenmarkTowardDK',
            attributeText: 'rettet mod Danmark',
        },
        {
            ball: 3,
            row: 2,
            column: 4,
            text: 'Min brug er ikke rettet mod Danmark',
            attributeGroup: 'denmark',
            attributeName: 'placeDenmarkNotTowardDK',
            attributeText: 'ikke rettet mod Danmark',
        },

        {
            ball: 3,
            row: 0,
            column: 5,
            text: 'Jeg bruger mit kendetegn online',
            attributeGroup: 'place',
            attributeName: 'placeOnline',
            attributeText: 'online',
            subCategory: 'online',
        },

        {
            ball: 4,
            row: 0,
            column: 0,
            text: 'Hvordan bruger du dit kendetegn?',
            attributeGroup: 'prompt',
            attributeName: '',
            attributeText: '',
        },
        {
            ball: 4,
            row: 0,
            column: 1,
            text: 'Jeg bruger mit kendetegn i detail',
            attributeGroup: 'prompt',
            attributeName: '',
            attributeText: '',
        },
        {
            ball: 4,
            row: 1,
            column: 1,
            text: 'Jeg bruger mit kendetegn på varer',
            attributeGroup: 'context',
            attributeName: 'contextGoods',
            attributeText: 'varer',
            subCategory: 'detail',
        },
        {
            ball: 4,
            row: 2,
            column: 1,
            text: 'Jeg bruger mit kendetegn i forbindelse med ydelser',
            attributeGroup: 'context',
            attributeName: 'contextService',
            attributeText: 'ydelser',
            subCategory: 'detail',
        },

        {
            ball: 4,
            row: 0,
            column: 2,
            text: 'Jeg bruger mit kendetegn i annoncer',
            attributeGroup: 'prompt',
            attributeName: '',
            attributeText: '',
        },
        {
            ball: 4,
            row: 1,
            column: 2,
            text: 'Jeg bruger mit kendetegn på adgangskort',
            attributeGroup: 'context',
            attributeName: 'contextID',
            attributeText: 'adgangskort',
            subCategory: 'ads',
        },
        {
            ball: 4,
            row: 2,
            column: 2,
            text: 'Jeg bruger mit kendetegn i aviser',
            attributeGroup: 'context',
            attributeName: 'contextNewspaper',
            attributeText: 'aviser',
            subCategory: 'ads',
        },
        {
            ball: 4,
            row: 3,
            column: 2,
            text: 'Jeg bruger mit kendetegn i brochurer',
            attributeGroup: 'context',
            attributeName: 'contextBrochure',
            attributeText: 'brochurer',
            subCategory: 'ads',
        },
        {
            ball: 4,
            row: 4,
            column: 2,
            text: 'Jeg bruger mit kendetegn i Den Blå Avis',
            attributeGroup: 'context',
            attributeName: 'contextDenBlaaAvis',
            attributeText: 'Den Blå Avis',
            subCategory: 'ads',
        },
        {
            ball: 4,
            row: 5,
            column: 2,
            text: 'Jeg bruger mit kendetegn på filmplakater',
            attributeGroup: 'context',
            attributeName: 'contextMoviePoster',
            attributeText: 'filmplakater',
            subCategory: 'ads',
        },
        {
            ball: 4,
            row: 6,
            column: 2,
            text: 'Jeg bruger mit kendetegn i kataloger',
            attributeGroup: 'context',
            attributeName: 'contextCatalogoue',
            attributeText: 'kataloger',
            subCategory: 'ads',
        },
        {
            ball: 4,
            row: 7,
            column: 2,
            text: 'Jeg bruger mit kendetegn i magasiner',
            attributeGroup: 'context',
            attributeName: 'contextMagazine',
            attributeText: 'magasiner',
            subCategory: 'ads',
        },
        {
            ball: 4,
            row: 8,
            column: 2,
            text: 'Jeg bruger mit kendetegn i projektpræsentationer',
            attributeGroup: 'context',
            attributeName: 'contextProjectPresentation',
            attributeText: 'projektpræsentation',
            subCategory: 'ads',
        },
        {
            ball: 4,
            row: 9,
            column: 2,
            text: 'Jeg bruger mit kendetegn i pressemeddelelse',
            attributeGroup: 'context',
            attributeName: 'contextPressRelease',
            attributeText: 'pressemeddelelse',
            subCategory: 'ads',
        },
        {
            ball: 4,
            row: 10,
            column: 2,
            text: 'Jeg bruger mit kendetegn i reklamekalendere',
            attributeGroup: 'context',
            attributeName: 'contextCalendar',
            attributeText: 'kalender',
            subCategory: 'ads',
        },
        {
            ball: 4,
            row: 11,
            column: 2,
            text: 'Jeg bruger mit kendetegn på skilte',
            attributeGroup: 'context',
            attributeName: 'contextSign',
            attributeText: 'skilte',
            subCategory: 'ads',
        },
        {
            ball: 4,
            row: 12,
            column: 2,
            text: 'Jeg bruger mit kendetegn i tidsskrifter',
            attributeGroup: 'context',
            attributeName: 'contextPamphlet',
            attributeText: 'tidsskrifter',
            subCategory: 'ads',
        },
        {
            ball: 4,
            row: 13,
            column: 2,
            text: 'Jeg bruger mit kendetegn i tilbudsaviser',
            attributeGroup: 'context',
            attributeName: 'contextAdMagazine',
            attributeText: 'tilbudsaviser',
            subCategory: 'ads',
        },

        {
            ball: 4,
            row: 0,
            column: 3,
            text: 'Jeg bruger mit kendetegn i TV',
            attributeGroup: 'context',
            attributeName: 'contextTv',
            attributeText: 'TV',
            subCategory: 'tv-radio-newsletter-email',
        },

        {
            ball: 4,
            row: 0,
            column: 4,
            text: 'Jeg bruger mit kendetegn i radio',
            attributeGroup: 'context',
            attributeName: 'contextRadio',
            attributeText: 'radio',
            subCategory: 'tv-radio-newsletter-email',
        },

        {
            ball: 4,
            row: 0,
            column: 5,
            text: 'Jeg bruger mit kendetegn i et selskab',
            attributeGroup: 'prompt',
            attributeName: '',
            attributeText: '',
        },
        {
            ball: 4,
            row: 1,
            column: 5,
            text: 'Jeg bruger mit kendetegn i et selskabsnavn',
            attributeGroup: 'context',
            attributeName: 'contextCompanyName',
            attributeText: 'selskabsnavn',
            subCategory: 'company',
        },
        {
            ball: 4,
            row: 2,
            column: 5,
            text: 'Jeg bruger mit kendetegn på selskabsfaktura',
            attributeGroup: 'context',
            attributeName: 'contextCompanyInvoice',
            attributeText: 'selskabsfaktura',
            subCategory: 'company',
        },

        {
            ball: 4,
            row: 0,
            column: 6,
            text: 'Jeg bruger mit kendetegn på transportmidler',
            attributeGroup: 'prompt',
            attributeName: '',
            attributeText: '',
        },
        {
            ball: 4,
            row: 1,
            column: 6,
            text: 'Jeg bruger mit kendetegn på varebiler',
            attributeGroup: 'context',
            attributeName: 'contextVan',
            attributeText: 'varebil',
            subCategory: 'transportation',
        },
        {
            ball: 4,
            row: 2,
            column: 6,
            text: 'Jeg bruger mit kendetegn på reklamebiler',
            attributeGroup: 'context',
            attributeName: 'contextAdCar',
            attributeText: 'reklamebil',
            subCategory: 'transportation',
        },

        {
            ball: 4,
            row: 0,
            column: 7,
            text: 'Jeg bruger mit kendetegn på en hjemmeside',
            attributeGroup: 'prompt',
            attributeName: '',
            attributeText: 'supermarked',
        },
        {
            ball: 4,
            row: 1,
            column: 7,
            text: 'Jeg bruger mit kendetegn på min egen hjemmeside',
            attributeGroup: 'context',
            attributeName: 'contextHomepageOwn',
            attributeText: 'egen hjemmeside',
            subCategory: 'homepage',
        },
        {
            ball: 4,
            row: 2,
            column: 7,
            text: 'Jeg bruger mit kendetegn på andres hjemmeside',
            attributeGroup: 'context',
            attributeName: 'contextHomepageOther',
            attributeText: 'andres hjemmeside',
            subCategory: 'homepage',
        },

        {
            ball: 4,
            row: 0,
            column: 8,
            text: 'Jeg bruger mit kendetegn i sociale medier',
            attributeGroup: 'prompt',
            attributeName: '',
            attributeText: '',
        },
        {
            ball: 4,
            row: 1,
            column: 8,
            text: 'Jeg bruger mit kendetegn i egne opsalg',
            attributeGroup: 'context',
            attributeName: 'contextSomeOwn',
            attributeText: 'egne opslag på sociale medier',
            subCategory: 'some',
        },
        {
            ball: 4,
            row: 2,
            column: 8,
            text: 'Jeg bruger mit kendetegn i andres opslag',
            attributeGroup: 'context',
            attributeName: 'contextSomeOther',
            attributeText: 'andres opslag på sociale medier',
            subCategory: 'some',
        },

        {
            ball: 4,
            row: 0,
            column: 9,
            text: 'Jeg bruger mit kendetegn i nyhedsbreve',
            attributeGroup: 'context',
            attributeName: 'contextNewsletter',
            attributeText: 'nyhedsbrev',
            subCategory: 'tv-radio-newsletter-email',
        },

        {
            ball: 4,
            row: 0,
            column: 10,
            text: 'Jeg bruger mit kendetegn i emails',
            attributeGroup: 'context',
            attributeName: 'contextEmail',
            attributeText: 'e-mail',
            subCategory: 'tv-radio-newsletter-email',
        },

        {
            ball: 4,
            row: 0,
            column: 11,
            text: 'Jeg bruger mit kendetegn i presseomtale',
            attributeGroup: 'prompt',
            attributeName: '',
            attributeText: '',
        },
        {
            ball: 4,
            row: 1,
            column: 11,
            text: 'Jeg bruger mit kendetegn i aviser',
            attributeGroup: 'context',
            attributeName: 'contextPressNewspaper',
            attributeText: 'presseomtale i aviser',
            subCategory: 'press',
        },
        {
            ball: 4,
            row: 2,
            column: 11,
            text: 'Jeg bruger mit kendetegn på internettet',
            attributeGroup: 'context',
            attributeName: 'contextPressInternet',
            attributeText: 'presseomtale på internettet',
            subCategory: 'press',
        },
        {
            ball: 4,
            row: 3,
            column: 11,
            text: 'Jeg bruger mit kendetegn i bøger',
            attributeGroup: 'context',
            attributeName: 'contextPressBook',
            attributeText: 'presseomtale i bøger',
            subCategory: 'press',
        },

        {
            ball: 4,
            row: 0,
            column: 12,
            text: 'Jeg bruger mit kendetegn til intern brug',
            attributeGroup: 'prompt',
            attributeName: '',
            attributeText: '',
        },
        {
            ball: 4,
            row: 1,
            column: 12,
            text: 'Jeg bruger mit kendetegn på interne fakturaer',
            attributeGroup: 'context',
            attributeName: 'contextInternalInvoice',
            attributeText: 'interne fakturaer',
            subCategory: 'internal',
        },
        {
            ball: 4,
            row: 2,
            column: 12,
            text: 'Jeg bruger mit kendetegn i interne drøftelser',
            attributeGroup: 'context',
            attributeName: 'contextInternalDiscussion',
            attributeText: 'interne drøftelser',
            subCategory: 'internal',
        },
        {
            ball: 4,
            row: 3,
            column: 12,
            text: 'Jeg bruger mit kendetegn til internt salg',
            attributeGroup: 'context',
            attributeName: 'contextInternalSale',
            attributeText: 'internt salg',
            subCategory: 'internal',
        },

        {
            ball: 5,
            row: 0,
            column: 0,
            text: 'Bruger du dit kendetegn varigt, holder du pause eller er du stoppet?',
            attributeGroup: 'prompt',
            attributeName: '',
            attributeText: '',
        },
        {
            ball: 5,
            row: 0,
            column: 1,
            text: 'Jeg bruger mit kendetegn varigt',
            attributeGroup: 'continuation',
            attributeName: 'continuationContinuously',
            attributeText: 'varigt',
        },
        {
            ball: 5,
            row: 0,
            column: 2,
            text: 'Jeg holder pause',
            attributeGroup: 'continuation',
            attributeName: 'continuationPause',
            attributeText: 'pause',
        },
        {
            ball: 5,
            row: 0,
            column: 3,
            text: 'Jeg er stoppet',
            attributeGroup: 'continuation',
            attributeName: 'continuationStop',
            attributeText: 'stoppet',
        },
    ])

    useEffect(() => {
        const currentOption = optionDescriptions.filter(
            (item) =>
                item.ball == focus.ball &&
                item.row == focus.row &&
                item.column == focus.column
        )[0]
        setOptionDescription(currentOption.text)
    }, [focus])

    // The position of the option that is active in each column. Only one option will be
    // visible to the end-user as any given time (the activeSlide above), but in every
    // visited column the last visible option will be the activeOption in that column.
    // When a column is visited any existing activeOption object for that column is
    // removed from actveOption, and the corresponding object { "ball": 0, "row": 0, "column": 0 }
    // for the now visited option is inserted.
    const [activeOptions, setActiveOptions] = useState([])
    const [selectedOptions, setSelectedOptions] = useState([])
    const [activeSlideSelected, setActiveSlideSelected] = useState(false)

    const [lastKnownScrollPosition, setLastKnownScrollPosition] = useState(0)

    // Variables receiving the evaluation results from the backend
    const [useEvaluationResult, setUseEvaluationResult] = useState({
        caseLastTrue: [{}],
        conclusionLastTrue: '',
        noteLastTrue: '',
        explanation: [],
        explanationTrues: [],
        nodeLast: 0,
        nodeLastTrue: 0,

        nodesEvaluated: [
            {
                conclusion: '',
                cornerstonecaseid: null,
                evaluationResult: true,
                id: 0,
                nextnodefalse: 0,
                nextnodetrue: 0,
                rule: '',
            },
        ],

        referenceCase: [],
        req_data: {
            age: '',
            weight: '',
        },
    })

    const [versionEvaluationResult, setVersionEvaluationResult] = useState({
        caseLastTrue: [{}],
        conclusionLastTrue: '',
        noteLastTrue: '',
        explanation: [],
        explanationTrues: [],
        nodeLast: 0,
        nodeLastTrue: 0,

        nodesEvaluated: [
            {
                conclusion: '',
                cornerstonecaseid: null,
                evaluationResult: true,
                id: 0,
                nextnodefalse: 0,
                nextnodetrue: 0,
                rule: '',
            },
        ],

        referenceCase: [],
        req_data: {
            age: '',
            weight: '',
        },
    })

    const [placeEvaluationResult, setPlaceEvaluationResult] = useState({
        caseLastTrue: [{}],
        conclusionLastTrue: '',
        noteLastTrue: '',
        explanation: [],
        explanationTrues: [],
        nodeLast: 0,
        nodeLastTrue: 0,

        nodesEvaluated: [
            {
                conclusion: '',
                cornerstonecaseid: null,
                evaluationResult: true,
                id: 0,
                nextnodefalse: 0,
                nextnodetrue: 0,
                rule: '',
            },
        ],

        referenceCase: [],
        req_data: {
            age: '',
            weight: '',
        },
    })

    const [denmarkEvaluationResult, setDenmarkEvaluationResult] = useState({
        caseLastTrue: [{}],
        conclusionLastTrue: '',
        noteLastTrue: '',
        explanation: [],
        explanationTrues: [],
        nodeLast: 0,
        nodeLastTrue: 0,

        nodesEvaluated: [
            {
                conclusion: '',
                cornerstonecaseid: null,
                evaluationResult: true,
                id: 0,
                nextnodefalse: 0,
                nextnodetrue: 0,
                rule: '',
            },
        ],

        referenceCase: [],
        req_data: {
            age: '',
            weight: '',
        },
    })

    const [onlineEvaluationResult, setOnlineEvaluationResult] = useState({
        caseLastTrue: [{}],
        conclusionLastTrue: '',
        noteLastTrue: '',
        explanation: [],
        explanationTrues: [],
        nodeLast: 0,
        nodeLastTrue: 0,

        nodesEvaluated: [
            {
                conclusion: '',
                cornerstonecaseid: null,
                evaluationResult: true,
                id: 0,
                nextnodefalse: 0,
                nextnodetrue: 0,
                rule: '',
            },
        ],

        referenceCase: [],
        req_data: {
            age: '',
            weight: '',
        },
    })

    const [contextEvaluationResult, setContextEvaluationResult] = useState({
        caseLastTrue: [{}],
        conclusionLastTrue: '',
        noteLastTrue: '',
        explanation: [],
        explanationTrues: [],
        nodeLast: 0,
        nodeLastTrue: 0,

        nodesEvaluated: [
            {
                conclusion: '',
                cornerstonecaseid: null,
                evaluationResult: true,
                id: 0,
                nextnodefalse: 0,
                nextnodetrue: 0,
                rule: '',
            },
        ],

        referenceCase: [],
        req_data: {
            age: '',
            weight: '',
        },
    })

    const [continuationEvaluationResult, setContinuationEvaluationResult] =
        useState({
            caseLastTrue: [{}],
            conclusionLastTrue: '',
            noteLastTrue: '',
            explanation: [],
            explanationTrues: [],
            nodeLast: 0,
            nodeLastTrue: 0,

            nodesEvaluated: [
                {
                    conclusion: '',
                    cornerstonecaseid: null,
                    evaluationResult: true,
                    id: 0,
                    nextnodefalse: 0,
                    nextnodetrue: 0,
                    rule: '',
                },
            ],

            referenceCase: [],
            req_data: {
                age: '',
                weight: '',
            },
        })

    const [processing, setProcessing] = useState(false)

    const [chatText, setChatText] = useState('')
    const [chatConverted, setChatConverted] = useState('')

    return (
        <swiperContext.Provider
            value={{
                width,
                breakpoint,
                swiper1ActiveSlideHorizontal,
                setSwiper1ActiveSlideHorizontal,
                swiper1ActiveSlideVertical,
                setActiveSlideVertical,
                swiper1SelectedVertical,
                setSwiper1SelectedVertical,
                focusedOption,
                setFocusedOption,
                selectedOptions,
                setSelectedOptions,
                activeSlideSelected,
                setActiveSlideSelected,
                activeOptions,
                setActiveOptions,
                focus,
                setFocus,
                optionDescription,
                setOptionDescription,
                optionDescriptions,
                setOptionDescriptions,
                lastKnownScrollPosition,
                setLastKnownScrollPosition,
                addEmptyItemsToList,
                setAddEmptyItemsToList,
                userCase,
                setUserCase,
                useEvaluationResult,
                setUseEvaluationResult,
                versionEvaluationResult,
                setVersionEvaluationResult,
                placeEvaluationResult,
                setPlaceEvaluationResult,
                denmarkEvaluationResult,
                setDenmarkEvaluationResult,
                onlineEvaluationResult,
                setOnlineEvaluationResult,
                contextEvaluationResult,
                setContextEvaluationResult,
                continuationEvaluationResult,
                setContinuationEvaluationResult,
                processing,
                setProcessing,
                aggConclusion,
                setAggConclusion,
                showConclusion,
                setShowConclusion,
                chatText,
                setChatText,
                chatConverted,
                setChatConverted,
                loading,
                fetchData,
                fetchedChat,
                fetchDataQuery,
                messages,
                setMessages,
                typing,
                setTyping,
            }}
        >
            {children}
        </swiperContext.Provider>
    )
}

export default SwiperContextProvider
