import React, { useContext, useEffect } from "react";

import { domainContext } from "../contexts/DomainContext";
import { userCaseContext } from "../contexts/UserCaseContext";
import { displayContext } from "../../common/DisplayContext";
import { evaluationContext } from "../contexts/EvaluationContext";
import { errorContext } from "../contexts/ErrorContext";

const useClearEvaluation = () => {
    const {
        // setDisplayMessage,
        buttonRejectDisabled,
        displayMessage,
        newConclusion,
        newExplanation,
        setButtonAcceptDisabled,
        setButtonLetterDisabled,
        setButtonRejectDisabled,
        setButtonSaveNewRuleDisabled,
        setDisplayMessage,
        setNewConclusion,
        setNewExplanation,
        setUseMode,
    } = useContext(displayContext);

    const { currentDomain, setCurrentDomain } = useContext(domainContext);
    const { userCase, setUserCase, setCaseStatus } =
        useContext(userCaseContext);

    const {
        currentCaseId,
        explanation,
        noteLastTrue,
        setConclusionLastTrue,
        setCurrentCaseId,
        setExplanation,
        setExplanationLastTrue,
        setNoteLastTrue,
    } = useContext(evaluationContext);

    const { errors, setErrors } = useContext(errorContext);

    function handleClearEvaluation() {
        // Clear the userCase by resetting all fields
        const cloneCaseData = [...userCase];

        cloneCaseData.forEach((attribute) => {
            attribute["referenceValue"] = "";
            attribute["referenceMatch"] = "";
            attribute["test"] = "";
        });
        setUserCase(cloneCaseData);
        console.log("useClearEvaluation - SETTING userCase");

        // Clear the Conclusion field...
        // ...from the evaluation result...
        setConclusionLastTrue("");

        // ...and the editable Conclusion field
        setNewConclusion("");

        // Clear the Explanation field...
        // ...from the evaluation result...
        setExplanationLastTrue("");

        // ...and the editable Explanation field
        setNewExplanation("");
        setExplanation([]);

        // Clear the Evalation / Note field
        setNoteLastTrue("");

        // Clear the errors control structure
        setErrors("");

        // Reset the current CaseId
        setCurrentCaseId("");

        // Set the buttons and display message
        setButtonRejectDisabled(true);
        setDisplayMessage("");
    }

    return [
        {
            handleClearEvaluation: handleClearEvaluation,
        },
    ];
};

export default useClearEvaluation;
