import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { domainsContext } from "../../contexts/DomainsContext";
import { displayContext } from "../../../common/DisplayContext";

import { domainTheme } from "../../hooks/useReactTableTheme";

import {
    BsArrowDown,
    BsArrowUp,
    BsFileArrowDown,
    BsFileArrowUp,
} from "react-icons/bs";

import {
    Table,
    Header,
    HeaderRow,
    HeaderCell,
    Body,
    Row,
    Cell,
} from "@table-library/react-table-library/table";

import {
    useSort,
    SortToggleType,
} from "@table-library/react-table-library/sort";

// import { defaultTheme } from "../../hooks/useReactTableTheme";
import useReactTableTheme from "../../hooks/useReactTableTheme";

// import { useTheme } from "@table-library/react-table-library/theme";

function DomainTable() {
    const { domains } = useContext(domainsContext);
    const { t } = useContext(displayContext);

    const [{ defaultTheme }] = useReactTableTheme();

    //     const theme = useTheme({
    //         Table: `
    //   height: 100%;
    //   align-content: baseline;
    // `,
    //         BaseRow: `
    //   font-size: 14px;
    // `,
    //         HeaderRow: `
    //   background-color: #FAFAFA;
    //   font-size: 14px;

    //   .th {
    // 	font-weight: normal !important;
    // 	border-width: 0px;
    // 	border-bottom: 0px;
    // 	box-shadow: 0px 0px 0px 0px rgba(0,0,0,0)
    //   }
    // `,
    //         Row: `
    //   &:nth-child(odd) {
    // 	background-color: #C7C7C7;
    //   }

    //   &:nth-child(even) {
    // 	background-color: #F2F2F2;
    //   }

    //   cursor: pointer;

    //   &:hover .td {
    // 	background-color: yellow;
    //   }

    // `,
    //         HeaderCell: `
    //         padding-left: 10px !important;
    // `,
    //         Cell: `
    //         padding-left: 10px !important;
    //         text-align: left;
    // `,
    //         BaseCell: `
    //         padding-left: 0px;
    //         padding-top: 6px;
    //         padding-bottom: 6px;
    // `,
    //     });

    const [data, setData] = useState({ nodes: domains });

    useEffect(() => {
        setData({ nodes: domains });
    }, [domains]);

    const handleNameFilter = (value) => {
        setData({
            nodes:
                value !== ""
                    ? domains.filter(function (item) {
                          return item.domain.includes(value);
                      })
                    : domains,
        });
    };

    const sort = useSort(
        data,
        {
            onChange: onSortChange,
        },
        {
            sortToggleType: SortToggleType.AlternateWithReset,
            sortFns: {
                Domain: (array) =>
                    array.sort((a, b) => a.domain.localeCompare(b.domain)),
            },
        }
    );

    function onSortChange(action, state) {
        console.log(action, state);
    }

    return (
        <div
            style={{
                height: "500px",
                backgroundColor: "#E6E6E6",
            }}
        >
            <Table data={data} theme={defaultTheme} sort={sort}>
                {(tableList) => (
                    <>
                        <Header>
                            <HeaderRow>
                                {/* <HeaderCellSort sortKey="Name"> */}
                                {/* <HeaderCell {...{ style: { height: `100px` } }}> */}
                                <HeaderCell>
                                    {t("Domain")} <br />
                                    <input
                                        className="case-table-filter"
                                        type="text"
                                        placeholder="Filter..."
                                        onChange={(event) =>
                                            handleNameFilter(event.target.value)
                                        }
                                    />
                                </HeaderCell>
                            </HeaderRow>
                        </Header>

                        <Body>
                            {tableList.map((item) => (
                                <Row key={item.domain} item={item}>
                                    <Cell>{item.domain}</Cell>
                                </Row>
                            ))}
                        </Body>
                    </>
                )}
            </Table>
        </div>
    );
}

export default DomainTable;
