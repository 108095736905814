// See https://www.robinwieruch.de/react-table-component/ for the tutorial for creating this type of tables
// https://www.robinwieruch.de/categories/react-table-library/
// samples: https://react-table-library.com/?path=/story/getting-started--demo
// https://github.com/table-library/react-table-library

// Tree tabl
// https://www.robinwieruch.de/react-tree-table/
// https://react-table-library.com/?path=/docs/features-tree--base

import React, { useEffect } from 'react';

import styles from '../../styles.module.css';

import {
    Table,
    Header,
    HeaderRow,
    HeaderCell,
    Body,
    Row,
    Cell,
} from '@table-library/react-table-library/table';

import {
    useRowSelect,
    CellSelect,
    SelectTypes,
} from '@table-library/react-table-library/select';

import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { useSort } from '@table-library/react-table-library/sort';

import { SortToggleType } from '@table-library/react-table-library/sort';

import useReactTableTheme from '../../hooks/useReactTableTheme';

import { useTheme } from '@table-library/react-table-library/theme';
import defaultTableTheme from '../KnowledgeBrowser/DefaultTableTheme';

import { userCaseContext } from '../../contexts/UserCaseContext';
import { displayContext } from '../../../common/DisplayContext';
import { evaluationContext } from '../../contexts/EvaluationContext';
import { navigationContext } from '../../contexts/NavigationContext';

import { useContext, useState } from 'react';

function MCResultTable() {
    const { userCase, setUserCase, setCaseStatus } =
        useContext(userCaseContext);

    const {
        setButtonAcceptDisabled,
        setButtonLetterDisabled,
        setButtonRejectDisabled,
        setDisplayMessage,
        setUseMode,
        t,
        t2,
        code,
        setCode,
    } = useContext(displayContext);

    const { page } = useContext(navigationContext);

    const {
        setCurrentCaseId,
        conclusionLastTrue,
        evaluationResult_tmp,
        evaluationResult,
        evaluationResultMC,
        setEvaluationResultMC,
    } = useContext(evaluationContext);

    const [selectedNodes, setSelectedNodes] = useState(0);

    const [{ defaultTheme }] = useReactTableTheme();

    const defaultTableTheme = {
        Table: `
        height: 100%; 
        align-content: baseline; 
        --data-table-library_grid-template-columns: 20% 20% 20% 20% 20% ;
    `,

        Cell: `
        padding-left: 10px !important;
        text-align: left; 
    `,

        Row: `
    /* No direct background-color here; use CSS class for styling */
    `,

        HeaderCell: `
        /* No direct background-color here; use CSS class for styling */
    `,
    };
    const theme = useTheme(defaultTableTheme);

    // const theme = useTheme({
    //     Table: `
    //   height: 100%;
    //   align-content: baseline;
    //   --data-table-library_grid-template-columns:  15% 15% 40% 15% 15%;
    // `,
    //     BaseRow: `
    //   font-size: var(--font-size5);
    // `,
    //     HeaderRow: `
    //   font-size: var(--font-size5);

    //   .th {
    // 	font-weight: normal !important;
    // 	border-width: 0px;
    // 	border-bottom: 0px;
    // 	box-shadow: 0px 0px 0px 0px rgba(0,0,0,0)
    //   }
    // `,
    //     Row: `
    //   &:nth-child(odd) {
    //     background-color: #C7C7C7;
    //   }

    //   &:nth-child(even) {
    //     background-color: #F2F2F2;
    //   }

    //   cursor: pointer;

    //   &:hover .td {
    // 	background-color: yellow;
    //   }

    // `,
    //     HeaderCell: `
    // 	padding-left: 10px !important;
    // 	`,
    //     Cell: `
    //   padding-left: 10px !important;
    //   text-align: left;
    // `,
    //     BaseCell: `
    //     padding-top: 4px !important;
    //     padding-bottom: 4px !important;
    //     `,
    // });

    let filter = ['class', 'b1s1'];

    function showRow(row) {
        // Here we need to apply the rules loaded with each attribute from the database...
        return !filter.includes(row.name);
    }

    let userCaseFiltered = userCase.filter(
        (el) => el.name != 'class' && el.name != 'b1s1',
    );

    // const [data, setData] = useState({
    //     nodes: userCaseFiltered,
    // });

    // const [data, setData] = useState({
    //     nodes: evaluationResult_tmp[0]["results"]
    // })

    // useEffect(() => {
    //     if (evaluationResult_tmp["nodes"] != undefined) {
    //         setData({ nodes: evaluationResult_tmp[0]["results"] });
    //     }
    // }, [evaluationResult_tmp]);

    // const [evaluationResultMC, setEvaluationResultMC] = useState(
    //     { nodes: evaluationResult["results_mc"]}
    // )

    // useEffect(() => {
    //     if (evaluationResult["results_mc"] != undefined) {
    //         setEvaluationResultMC({ nodes: evaluationResult["results_mc"] });
    //     } else {
    //         setEvaluationResultMC({ nodes: [{}] });
    //     }
    // }, [evaluationResult]);

    // const [data, setData] = useState(
    //     { nodes: evaluationResult["results_mc"]}
    // )

    // const [data, setData] = useState({nodes: [{}]})

    // useEffect(() => {
    //     if (evaluationResult["results_mc"] != undefined) {
    //         setData({ nodes: evaluationResult["results_mc"] });
    //     } else {
    //         setData({ nodes: [{}] });
    //     }
    // }, [evaluationResult]);

    const select = useRowSelect(
        evaluationResultMC,
        {
            onChange: onSelectChange,
        },
        {
            rowSelect: SelectTypes.MultiSelect,
        },
    );

    function onSelectChange(action, state) {
        console.log('CaseTableTestNested - state: ', state);
        setCode(code + state.id);
        // Resetting state.id, so that we do not select/unselect, i.e. have to click
        // two times to get the attribute name again. By resetting, we get the variable
        // name each time we click it.
        setSelectedNodes(state.ids.length);
        state.id = null;
    }

    // useEffect(() => {
    //     // userCaseFiltered = userCase.filter((el) => el.name != "class");
    //     userCaseFiltered = userCase.filter(showRow);
    //     setData({ nodes: userCaseFiltered });
    // }, [userCase]);

    // const handleNameFilter = (value) => {
    //     setData({
    //         nodes:
    //             value !== ""
    //                 ? userCase.filter(function (item) {
    //                     return item.name.includes(value);
    //                 })
    //                 : userCase,
    //     });
    // };

    // const handleDisplayNameFilter = (value) => {
    //     setData({
    //         nodes:
    //             value !== ""
    //                 ? userCase.filter(function (item) {
    //                     return item.displayName.includes(value);
    //                 })
    //                 : userCase,
    //     });
    // };

    const handleConclusionGroupFilter = (value) => {
        setEvaluationResultMC({
            nodes:
                value !== ''
                    ? evaluationResult['results_mc'].filter(function (item) {
                          return item.conclusion_group.includes(value);
                      })
                    : evaluationResult['results_mc'],
        });
    };

    // const sort = useSort(
    //     data,
    //     {
    //         onChange: onSortChange,
    //     },
    //     {
    //         sortToggleType: SortToggleType.AlternateWithReset,
    //         sortFns: {
    //             Name: (array) =>
    //                 array.sort((a, b) => a.name.localeCompare(b.name)),
    //             DisplayName: (array) =>
    //                 array.sort((a, b) =>
    //                     a.displayName.localeCompare(b.displayName)
    //                 ),
    //             Test: (array) =>
    //                 array.sort((a, b) => a.test.localeCompare(b.test)),
    //         },
    //     }
    // );

    function onSortChange(action, state) {
        console.log(action, state);
    }

    const onChangeText = (e) => {
        e.persist();

        setUseMode('read');
        setCurrentCaseId(null);

        setButtonLetterDisabled(true);
        setButtonAcceptDisabled(true);
        setButtonRejectDisabled(true);
        setDisplayMessage('');

        setUserCase((old) =>
            old.map((node) => {
                if (node.name === e.target.name) {
                    return { ...node, ['test']: e.target.value };
                } else {
                    return node;
                }
            }),
        );
    };

    const handleChange = (e) => {
        e.persist();

        // console.log(
        //     "CaseTableTestNested - handleChange - e.target.name: ",
        //     e.target.name
        // );
        // update the variable 'filter' above by applying the rulefor this row
        // for example if the rule is: show factor 'b1s3' if the value of this factor ('b1s2') is true
        // ... no, instead: for each attribute in the database it is specified if it depends on another
        // attribute and with which rule, e.g. for b1s1:
        //  - depends on: b1s2
        //  - dependence rule: true
        // which means that b1s1 should only be shown if b1s2 is true.

        setUseMode('read');
        setCaseStatus(t('na'));
        setCurrentCaseId(null);

        setButtonLetterDisabled(true);
        setButtonAcceptDisabled(true);
        setButtonRejectDisabled(true);
        setDisplayMessage('');

        setUserCase((old) =>
            old.map((node) => {
                if (node.name === e.target.name) {
                    return { ...node, ['test']: e.target.value };
                } else {
                    return node;
                }
            }),
        );
    };

    const handleClick = (e) => {
        e.persist();

        setCaseStatus(t('na'));
        setCurrentCaseId(null);

        setButtonRejectDisabled(true);
        setDisplayMessage('');

        setUserCase((old) =>
            old.map((node) => {
                if (node.name === e.target.name) {
                    return { ...node, ['test']: 0 };
                } else {
                    return node;
                }
            }),
        );
    };

    const style1 =
        page == 'addNodeMC'
            ? {
                  fontWeight: 100,
                  fontSize: 'large',
                  backgroundColor: 'lightgrey',
              }
            : {
                  fontWeight: 100,
                  fontSize: 'large',
                  backgroundColor: '#FFFFFF',
              };

    const style2 =
        page == 'addNodeMC'
            ? {
                  fontWeight: 100,
                  fontSize: 'large',
                  backgroundColor: 'lightgrey',
                  textAlign: 'center',
              }
            : {
                  fontWeight: 100,
                  fontSize: 'large',
                  backgroundColor: '#FFFFFF',
                  textAlign: 'center',
              };

    return (
        <div
            className={
                page === 'addNodeMC'
                    ? styles.evaluateCaseMCResultTableWrapperDimmed
                    : styles.evaluateCaseMCResultTableWrapper
            }
        >
            <Table
                data={evaluationResultMC}
                theme={theme}
                select={select}
                layout={{ custom: true }}
            >
                {(tableList) => (
                    <>
                        <Header>
                            <HeaderRow>
                                <HeaderCell style={style1}>
                                    {t2('conclusion', 'sysadmin')}
                                    <br />
                                    {/* <input
                                        className={styles.caseTableFilter}
                                        type="text"
                                        placeholder="Filter..."
                                        onChange={(event) =>
                                            handleNameFilter(event.target.value)
                                        }
                                    /> */}
                                </HeaderCell>

                                <HeaderCell style={style1}>
                                    {t2('explanation', 'sysadmin')}
                                    <br />
                                    {/* <input
                                        className={styles.caseTableFilter}
                                        type="text"
                                        placeholder="Filter..."
                                        onChange={(event) =>
                                            handleDisplayNameFilter(
                                                event.target.value
                                            )
                                        }
                                    /> */}
                                </HeaderCell>

                                <HeaderCell style={style1}>
                                    {' '}
                                    {t2('rule', 'sysadmin')}
                                </HeaderCell>

                                <HeaderCell style={style1}>
                                    {' '}
                                    {t2('note', 'sysadmin')}
                                </HeaderCell>

                                <HeaderCell style={style2}>
                                    {t2('conclusionGroup', 'sysadmin')}
                                    <br />
                                    <input
                                        className={styles.caseTableFilter}
                                        type="text"
                                        placeholder="Filter..."
                                        onChange={(event) =>
                                            handleConclusionGroupFilter(
                                                event.target.value,
                                            )
                                        }
                                    />
                                </HeaderCell>
                            </HeaderRow>
                        </Header>

                        <Body>
                            {tableList.map((item) => (
                                <Row key={item.id} item={item}>
                                    {/* <Cell item={item}>{item.id}</Cell> */}
                                    <Cell style={style1}>
                                        <div
                                            className={
                                                styles.attributeDisplayName
                                            }
                                        >
                                            {item.conclusion}
                                        </div>
                                    </Cell>
                                    <Cell style={style2}>
                                        <div
                                            className={
                                                styles.attributeDisplayName
                                            }
                                        >
                                            {item.explanation}
                                        </div>
                                    </Cell>
                                    <Cell style={style2}>
                                        <div
                                            className={
                                                styles.attributeDisplayName
                                            }
                                        >
                                            {item.rule}
                                        </div>
                                    </Cell>
                                    <Cell style={style2}>
                                        <div
                                            className={
                                                styles.attributeDisplayName
                                            }
                                        >
                                            {item.note}
                                        </div>
                                    </Cell>
                                    <Cell style={style2}>
                                        <div
                                            className={
                                                styles.attributeDisplayName
                                            }
                                        >
                                            {item.conclusion_group}
                                        </div>
                                    </Cell>
                                </Row>
                            ))}
                        </Body>
                    </>
                )}
            </Table>
        </div>
        // </div>
    );
}

export default MCResultTable;
