import React, { useContext } from 'react';

import { displayContext } from '../../../common/DisplayContext';
import { errorContext } from '../../contexts/ErrorContext';

import styles from '../../styles.module.css';

function NewRule() {
    const { newRule, setNewRule } = useContext(displayContext);

    const { errors } = useContext(errorContext);

    function handleRuleChange(e) {
        delete errors.newRule;
        setNewRule(e.target.value);
    }

    return (
        <div className={styles.newNodeTextareaWrapper}>
            <textarea
                name="newRuleMC"
                type="text"
                value={newRule}
                onChange={(e) => handleRuleChange(e)}
                className={errors.newRule ? styles.textareaError : ''}
            />

            {errors.newRule && (
                <div className={styles.errorOverlay}>{errors.newRule}</div>
            )}
        </div>
    );
}

export default NewRule;
