import React, { createContext, useState, useEffect, useContext } from 'react';
// import { FLASK_API } from "../constants/apiConstants";

import { domainContext } from './DomainContext';
// import { TokenContext } from "./TokenContext.js";
import useAuth from '../hooks/useAuth';
// import { Config } from "../../config";
import useDebugState from '../hooks/useDebugState';

export const userCaseContext = createContext();

const UserCaseContextProvider = ({ children }) => {
    const { currentKnowledgeBase, currentDomain } = useContext(domainContext);

    // const { token } = useContext(TokenContext);
    const { token } = useAuth();

    // userCase is the case to be evaluated by the system, defined by the user or upstream system
    const [userCase, setUserCase] = useState([]);

    // const [userCase, setUserCase] = useDebugState([], 'userCase');

    const [caseStatus, setCaseStatus] = useState('');

    useEffect(() => {
        var obj = {};

        // console.log("UserCaseContext - I WANT TO INITIALISE");

        if (token != null && currentKnowledgeBase != 'noKnowledgeBase') {
            // console.log("UserCaseContext - CALLING BACKEND TO INITIALISE");
            obj.knowledgeBase = currentKnowledgeBase;
            obj.domain = currentDomain;
            obj.requestType = 'initiate';
            // var endpoint = FLASK_API + "evaluate";
            var endpoint = process.env.REACT_APP_FLASK_API + 'evaluate';

            fetch(endpoint, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
                body: JSON.stringify(obj),
            })
                .then((res) => res.json())
                .then((responseJson) => {
                    // setEvaluationResult(responseJson);
                    setUserCase(responseJson['data']);
                    console.log('UserCaseContext - SETTING userCase');

                    // setCurrentCaseId(parseInt(responseJson["caseId"]));
                    // setDisplayMessage({
                    //   messageCode: null,
                    //   messageText: "",
                    //   displayCode: 0,
                    // });
                });
        }
    }, [currentDomain]);

    // function updateUserCase(newUserCase) {
    //   setUserCase(newUserCase)
    // }

    return (
        <userCaseContext.Provider
            value={{
                caseStatus,
                setCaseStatus,
                setUserCase,
                userCase,
            }}
        >
            {children}
        </userCaseContext.Provider>
    );
};

export default UserCaseContextProvider;
