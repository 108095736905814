import React, { useState, useContext } from "react";
import Button from "react-bootstrap/Button";
// import Modal from "react-bootstrap/Modal";
import Modal from "react-modal";

import { displayContext } from "../../../common/DisplayContext";

// https://www.npmjs.com/package/react-modal

const customStyles = {
    content: {
        top: "20%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        background: "#E6E6E6",
        width: "25%",
    },
    overlay: {
        zIndex: 1000,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        // backgroundColor: "rgba(255, 255, 255, 0.75)",
        // backgroundColor: "#BFB2B1B1",
        backgroundColor: "rgb(102,102,102,0.8)",
    },
};

function ModalCaseRejected() {
    const handleClose = () => setModalCaseRejected(false);

    const { modalCaseRejected, setModalCaseRejected, t } = useContext(
        displayContext
    );

    let subtitle = "subtitle";

    function openModal() {
        setModalCaseRejected(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = "#f00";
    }

    function closeModal() {
        setModalCaseRejected(false);
    }

    return (
        <>
            <Modal
                isOpen={modalCaseRejected}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <p className="reject-comment-heading">{t("caseRejected")}</p>

                <div>
                    <button
                        style={{
                            float: "right",
                            marginTop: "30px",
                        }}
                        className="mentor-button1"
                        onClick={closeModal}
                    >
                        {t("ok")}
                    </button>
                </div>
            </Modal>
        </>
    );
}

export default ModalCaseRejected;
