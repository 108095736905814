// import { Config } from "../../config";

function useEnv() {
    function backend(username) {
        return username == "philip1"
            ? "http://100.127.249.109:5000/backend/"
            : process.env.REACT_APP_FLASK_API;
    }
    return [
        {
            backend,
        },
    ];
}

export default useEnv;
