import React, { useRef, useState, useContext, useEffect } from 'react';
// import "../legal.css"

import { swiperContext } from '../contexts/SwiperContext';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import useSwiper from '../hooks/useSwiper';
// import useEvaluate from '../hooks/useEvaluate';
// import useEvaluate from '../../commonTrademark/hooks/useEvaluate';
import useEvaluate from '../hooks/useEvaluate';

import TrademarkSwiperList from './TrademarkSwiperList';

// Import pictures
// import background from './images/background.png';
import swiperball from '../images/swiperball.png'
import swiperballSelected from '../images/swiperball-selected.png'

import timePlay from '../images/time-play.png'
import timeStop from '../images/time-stop.png'
import timePause from '../images/time-pause.png'

import { useNavigate } from "react-router-dom";

function TrademarkSwiper5() {

    const [{ setOption,
        handleHorizontalSlideChange,
        handleVerticalSlideChange,
    }] = useSwiper();

    const [{ handleEvaluate }] = useEvaluate();

    let navigate = useNavigate();

    const ball = 5;

    const {
        focusedOption, setFocusedOption,
        activeOptions, setActiveOptions,
        selectedOptions, setSelectedOptions,
        activeSlideSelected, setActiveSlideSelected,
        focus, setFocus,
        optionDescriptions,
        userCase, setUserCase,
    } = useContext(swiperContext);


    if (focus.ball != ball) {
        setFocus({ "ball": ball, "row": 0, "column": 0 })
    }

    const itemsTime = [
        { text: 'varigt' },
        { text: 'pause' },
        { text: 'stoppet' },
    ]

    // function setOption(ball, row, column) {

    //     // If this option was not already selected
    //     // 1. remove other selected option in the same column
    //     // 2. add this option to the array of selected options...
    //     if (selectedOptions.findIndex(item => (item.ball == ball && item.row == row && item.column == column)) === -1) {
    //         // const newSelectedOptions = selectedOptions.slice();

    //         // remove other options in the same column from selectedOptions
    //         const newSelectedOptions = selectedOptions.filter(item => !(item.ball === ball && item.column == column))

    //         // add currentOption to selectedOptions
    //         newSelectedOptions.push({ "ball": ball, "row": row, "column": column });
    //         setSelectedOptions(newSelectedOptions);
    //         setActiveSlideSelected(true);
    //         // ...else, i.e. this option was already selected, remove it from the array of selected options.
    //     } else {
    //         // remove currentOption from selectOptions
    //         setSelectedOptions(selectedOptions.filter(item => !(item.ball === ball && item.row == row && item.column == column)))
    //         setActiveSlideSelected(false);
    //     }
    // }

    // // function optionSelected(ball, row, column) {
    // //     return selectedOptions.findIndex(item => (item.ball === ball && item.row == row && item.column == column)) != -1
    // // }

    // const handleHorizontalSlideChange = (swiper) => {

    //     let activeOptionInColumn = activeOptions.find(item => item.ball == ball && item.column == swiper.activeIndex)
    //     if (activeOptionInColumn === undefined) {
    //         activeOptionInColumn = { ball: ball, row: 0, column: 0 }
    //     }

    //     setFocusedOption({ "ball": ball, "row": activeOptionInColumn.row, "column": swiper.activeIndex });
    // };

    // const handleVerticalSlideChange = (swiper) => {
    //     // setFocusedOption({ "ball": ball, "row": swiper.activeIndex, "column": focusedOption.column });
    //     setFocus({ "ball": ball, "row": swiper.activeIndex, "column": focus.column });

    //     // Update the active option for this column...
    //     // ...remove current activeOption for this column...
    //     // const newActiveOptions = activeOptions.filter(item => !(item.ball === ball && item.column == focusedOption.column))
    //     const newActiveOptions = activeOptions.filter(item => !(item.ball === ball && item.column == focus.column))

    //     // ...add new activeOption for this column
    //     // newActiveOptions.push({ "ball": ball, "row": swiper.activeIndex, "column": focusedOption.column });
    //     newActiveOptions.push({ "ball": ball, "row": swiper.activeIndex, "column": focus.column });
    //     setActiveOptions(newActiveOptions);
    // };



    // function getEvaluations() {
    //     return Promise.all([handleEvaluate('use'),
    //     handleEvaluate('version'),
    //     handleEvaluate('place'),
    //     handleEvaluate('context'),
    //     handleEvaluate('continuation')])
    // }


    function gotoNext(e) {

        // Find errors
        // const newErrors = findFormErrors();

        // Conditional logic:
        // if (Object.keys(newErrors).length > 0) {
        //     // We got errors!
        //     setErrors(newErrors);
        // } else {
        //     // No errors! Put any logic here for the form submission!
        //     handleEvaluate(e, "use");

        //     navigate("/TrademarkResult");
        // }




        selectedOptions.map((selectedOption) => {
            // const optionDetails = optionDescriptions.find((option) => {
            //     option.ball == selectedOption.ball 
            //     && option.row == selectedOption.row
            //     && option.column == selectedOption.column
            // })

            const updatedUserCase = userCase.map((attribute) => {

                const optionDetails = optionDescriptions.find((option) =>
                    option.attributeName == attribute.name
                )

                return {
                    ...attribute,
                    ["test"]: optionDetails.value
                }
            });

            setUserCase(updatedUserCase);
        })

        // handleEvaluate('use');
        // handleEvaluate('version');
        // handleEvaluate('place');
        // handleEvaluate('context');
        // handleEvaluate('continuation');


        navigate("/TrademarkResult");
    }


    // useEffect(() => {
    //     setActiveSlideSelected(selectedOptions.findIndex(item => (item.ball == focusedOption.ball && item.row == focusedOption.row && item.column == focusedOption.column)) != -1);
    // }, [focusedOption]);


    useEffect(() => {
        setActiveSlideSelected(selectedOptions.findIndex(item => (item.ball == focus.ball && item.row == focus.row && item.column == focus.column)) != -1);
    }, [focus]);



    // <SwiperSlide className='swiperSlideTitle1'>
    //     <div className="swiper-ball-text">swipe & vælg</div>
    // </SwiperSlide>

    // {/* This is the simple list of options... */}
    // <SwiperSlide>
    //     <div className="swiper-line-top"></div>
    //     <div className="swiper-line-bottom"></div>
    //     <TrademarkSwiperList listClassName="time" ball={ball} column="1" items={itemsTime} />
    // </SwiperSlide>


    return (
        <div className='container'>
            <div className='top-bar'></div>
            <div className='boxlegal-1'>
                <h2 className='app-title'>legal</h2>
            </div>

            <div className='boxlegal-flex'>
                {/* Ball color depending on whether option is selected or not... */}
                {activeSlideSelected ?
                    <img src={swiperballSelected} className='swipe-ball' alt="ball" /> : <img src={swiperball} className='swipe-ball' alt="ball" />
                }

                {/* ...or ball color is always the same */}
                {/* <img src={swiperball} className='swipe-ball' alt="ball" /> */}

                <div className='centered'>
                    <SwiperSlide>
                        <Swiper
                            className="my-swiper-h"
                            spaceBetween={50}
                            onSlideChange={handleHorizontalSlideChange}
                        >


                            <SwiperSlide className='swiperSlideTitle1'>swipe & vælg</SwiperSlide>

                            <SwiperSlide>
                                <div onClick={() => setOption(ball, 0, 1, 'replaceInRow')}>
                                    <img src={timePlay} className='swiper-icon1' alt='' />
                                    {/* <p className='swiper-icon-label'>varigt</p> */}
                                </div>
                            </SwiperSlide>



                            <SwiperSlide>
                                <div onClick={() => setOption(ball, 0, 2, 'replaceInRow')}>
                                    <img src={timeStop} className='swiper-icon1' alt='' />
                                    {/* <p className='swiper-icon-label'>stop</p> */}
                                </div>
                            </SwiperSlide>



                            <SwiperSlide>
                                <div onClick={() => setOption(ball, 0, 3, 'replaceInRow')}>
                                    <img src={timePause} className='swiper-icon1' alt='' />
                                    {/* <p className='swiper-icon-label'>pause</p> */}
                                </div>
                            </SwiperSlide>


                        </Swiper>
                    </SwiperSlide>



                </div>
            </div >

            <div className='boxlegal-3 page-description'>
                Bruger du dit kendetegn varigt, holder du pause eller er du stoppet?
            </div>

            <div className='info-button-overlay'></div>

            <div className='boxlegal-4'>
                <div className='myprogress'>45%</div>
                <div className='button-next' onClick={gotoNext}>Næste</div>
            </div>

        </div >
    );

}
export default TrademarkSwiper5;