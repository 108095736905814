// See: https://www.reddit.com/r/reactnative/comments/uxa00x/can_we_call_hooks_inside_useeffect/
import React, { useContext, useCallback, useState, useEffect } from 'react';

import { displayContext } from '../../common/DisplayContext';
import { userCaseContext } from '../contexts/UserCaseContext';
import { evaluationContext } from '../contexts/EvaluationContext';
import { domainContext } from '../contexts/DomainContext';
import { rejectedCaseContext } from '../contexts/CaseContext';

import useAuth from './useAuth';
import Messages from '../components/Messages';
import useMessages from './useMessages';

function useAddNodeMC() {
    const {
        setDisplayMessage,
        setIsEvaluating,
        setIsLoading,
        setButtonAddNodeDisabled,
        // newConclusionMC,
        // newConclusionMCRef,
        // newExplanation,
        // newRule,
        // newNote,
        // newConclusionGroup,
    } = useContext(displayContext);

    const { userCase } = useContext(userCaseContext);

    const { currentCaseId, nodeLastTrue, setReturnCode } =
        useContext(evaluationContext);

    const { currentKnowledgeBase, currentDomain, abekat } =
        useContext(domainContext);

    const {
        reloadRejectedCaseList,
        setReloadRejectedCaseList,
        reloadCaseList,
        setReloadCaseList,
    } = useContext(rejectedCaseContext);

    const { token } = useAuth();

    const getMessageData = useMessages;

    const [responseMessage, setResponseMessage] = useState(null);
    const messageData = useMessages(responseMessage);

    useEffect(() => {
        if (responseMessage) {
            setDisplayMessage(messageData);
        }
    }, [responseMessage, messageData, setDisplayMessage]);

    const addNode = useCallback(
        async (
            newConclusion,
            newConclusionGroup,
            newExplanation,
            newNote,
            newRule,
        ) => {
            var obj = {};

            // obj.mode = 'accept';

            obj.knowledge_base = currentKnowledgeBase;
            obj.domain = currentDomain;

            if (currentCaseId == undefined) {
                obj.bus_case_id = '';
            } else {
                obj.bus_case_id = currentCaseId;
            }

            obj.parent_true = '';
            obj.parent_false = '';

            obj.case_data = userCase;

            obj.conclusion = newConclusion;
            obj.conclusion_group = newConclusionGroup;
            obj.explanation = newExplanation;
            obj.note = newNote;
            obj.rule = newRule;

            // setDisplayMessage({
            //     messageCode: null,
            //     messageText: 'Processing...',
            //     displayCode: 1,
            // });

            setIsLoading(true);

            var endpoint =
                process.env.REACT_APP_FLASK_API + 'add_class_node_mc';

            try {
                const response = await fetch(endpoint, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                    body: JSON.stringify(obj),
                });

                const responseJson = await response.json();

                // setDisplayMessage(Messages(responseJson['message']));

                // Set the response message state which will trigger useMessages
                setResponseMessage(responseJson.message);

                // setDisplayMessage(messageData);

                setReloadRejectedCaseList(!reloadRejectedCaseList);
                setReloadCaseList(!reloadCaseList);

                setButtonAddNodeDisabled(true);

                setIsLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setDisplayMessage({
                    messageCode: -1,
                    messageText: 'Error fetching data',
                    displayCode: 0,
                });
                setIsEvaluating(false);
            }
        },
        [
            currentKnowledgeBase,
            currentDomain,
            currentCaseId,
            userCase,
            token,
            setDisplayMessage,
            setIsLoading,
            setReloadRejectedCaseList,
            reloadRejectedCaseList,
            setReloadCaseList,
            reloadCaseList,
            setButtonAddNodeDisabled,
            setIsEvaluating,
        ],
    );

    return addNode;
}

export default useAddNodeMC;
