import React, { createContext, useState, useEffect, useContext } from 'react';
import { domainContext } from './DomainContext';
// import { TokenContext } from "./TokenContext.js";
import useAuth from '../hooks/useAuth';
// import { Config } from "../../config";

export const rejectedCaseContext = createContext();

const RejectedCaseContextProvider = ({ children }) => {
    const { currentKnowledgeBase, currentDomain } = useContext(domainContext);

    const { token } = useAuth();

    // rejected cases are the cases that were rejected by a user
    const [caseList, setCaseList] = useState([]);
    const [rejectedCaseList, setRejectedCaseList] = useState([]);
    const [reloadRejectedCaseList, setReloadRejectedCaseList] = useState(false);
    const [reloadCaseList, setReloadCaseList] = useState(false);
    const [triggerCaseRejected, setTriggerCaseRejected] = useState(false);
    const [triggerLoadCaseList, setTriggerLoadCaseList] = useState(false);

    useEffect(() => {
        var obj = {};

        if (token != null && currentKnowledgeBase != 'noKnowledgeBase') {
            obj.knowledgeBase = currentKnowledgeBase;
            obj.domain = currentDomain;
            var endpoint = process.env.REACT_APP_FLASK_API + 'getRejectedCases';

            fetch(endpoint, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
                body: JSON.stringify(obj),
            })
                .then((res) => res.json())
                .then((responseJson) => {
                    setRejectedCaseList(responseJson['data']);
                });
        }
    }, [token, currentDomain, reloadRejectedCaseList, triggerCaseRejected]);

    useEffect(() => {
        var obj = {};

        if (token != null && currentKnowledgeBase != 'noKnowledgeBase') {
            obj.knowledgeBase = currentKnowledgeBase;
            obj.domain = currentDomain;
            var endpoint = process.env.REACT_APP_FLASK_API + 'getCases';

            fetch(endpoint, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                    Authorization: 'Bearer ' + token,
                },
                body: JSON.stringify(obj),
            })
                .then((res) => res.json())
                .then((responseJson) => {
                    setCaseList(responseJson['data']);
                });
        }
    }, [token, currentDomain, reloadCaseList, triggerLoadCaseList]);

    return (
        <rejectedCaseContext.Provider
            value={{
                caseList,
                rejectedCaseList,
                setCaseList,
                setRejectedCaseList,
                reloadRejectedCaseList,
                setReloadRejectedCaseList,
                reloadCaseList,
                setReloadCaseList,
                triggerCaseRejected,
                setTriggerCaseRejected,
                triggerLoadCaseList,
                setTriggerLoadCaseList,
            }}
        >
            {children}
        </rejectedCaseContext.Provider>
    );
};

export default RejectedCaseContextProvider;
