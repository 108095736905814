import React, { createContext, useState, useEffect, useContext } from 'react';

import i18n from '../../translations/i18n';

export const versionContext = createContext();

const VersionContextProvider = ({ children }) => {
    i18n.changeLanguage('da');

    const [knowledgeBase, setKnowledgeBase] = useState('karnov');
    const [caseNo, setCaseNo] = useState('Vælg sag...');

    const [attributesOpenApp, setAttributesOpenApp] = useState([]);

    const [time, setTime] = useState('');

    const [aggConclusion, setAggConclusion] = useState('');

    // If an attribute belongs to an evaluation group, then the number of the evaluation group is specified here
    const [evaluationGroups, setEvaluationGroups] = useState({
        // "underretning_indgivet": 1,
        fagprofessionel: 1,
        æresrelateret: 2,
        mistanke_forældre: 3,
        overgreb_oplysning: 4,
        '10år_plus': 5,
        allerede_støttet: 6,
        lukning: 5,
        genvurdering: 6,
    });

    useEffect(() => {
        var obj = {};

        obj.knowledgeBase = knowledgeBase;
        obj.domain = 'default';

        var endpoint =
            process.env.REACT_APP_FLASK_API + 'getAttributesOpenApp1';

        const fetchData = async () => {
            try {
                const response = await fetch(endpoint, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(obj),
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const fetchedData = await response.json();

                const modifiedResponseJson = fetchedData.map((item) => {
                    // Check if there's an item in the dictionary with the same id
                    if (evaluationGroups.hasOwnProperty(item.name)) {
                        // Modify the item accordingly
                        return {
                            ...item,
                            // Add or update properties as needed
                            // For example, add a new property 'modified': true
                            evaluationGroup: evaluationGroups[item.name],
                            selected: item.name == 'ID' ? true : false,
                        };
                    }
                    // If there's no corresponding item in the dictionary, return the original item
                    return item;
                });

                setAttributesOpenApp(modifiedResponseJson);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        var obj = {};

        obj.knowledgeBase = knowledgeBase;

        var endpoint = process.env.REACT_APP_FLASK_API + 'time';
        fetch(endpoint, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(obj),
        })
            .then((res) => res.json())
            .then((responseJson) => {
                setTime(responseJson);
            });
    }, []);

    // Variables receiving the evaluation results from the backend

    const [evaluationResult, setEvaluationResult] = useState({
        caseLastTrue: [{}],
        conclusionLastTrue: '',
        noteLastTrue: '',
        explanation: [],
        explanationTrues: [],
        nodeLast: 0,
        nodeLastTrue: 0,

        nodesEvaluated: [
            {
                conclusion: '',
                cornerstonecaseid: null,
                evaluationResult: true,
                id: 0,
                nextnodefalse: 0,
                nextnodetrue: 0,
                rule: '',
            },
        ],

        referenceCase: [],
        req_data: {
            age: '',
            weight: '',
        },
        message: {
            messageCode: 0,
        },
    });

    const [groupsEvaluationResult, setGroupsEvaluationResult] = useState({});

    const [underretningEvaluationResult, setUnderretningEvaluationResult] =
        useState({
            caseLastTrue: [{}],
            conclusionLastTrue: '',
            noteLastTrue: '',
            explanation: [],
            explanationTrues: [],
            nodeLast: 0,
            nodeLastTrue: 0,

            nodesEvaluated: [
                {
                    conclusion: '',
                    cornerstonecaseid: null,
                    evaluationResult: true,
                    id: 0,
                    nextnodefalse: 0,
                    nextnodetrue: 0,
                    rule: '',
                },
            ],

            referenceCase: [],
            req_data: {
                age: '',
                weight: '',
            },
        });

    const [screeningEvaluationResult, setScreeningEvaluationResult] = useState({
        caseLastTrue: [{}],
        conclusionLastTrue: '',
        noteLastTrue: '',
        explanation: [],
        explanationTrues: [],
        nodeLast: 0,
        nodeLastTrue: 0,

        nodesEvaluated: [
            {
                conclusion: '',
                cornerstonecaseid: null,
                evaluationResult: true,
                id: 0,
                nextnodefalse: 0,
                nextnodetrue: 0,
                rule: '',
            },
        ],

        referenceCase: [],
        req_data: {
            age: '',
            weight: '',
        },
    });

    return (
        <versionContext.Provider
            value={{
                aggConclusion,
                setAggConclusion,
                knowledgeBase,
                setKnowledgeBase,
                evaluationResult,
                setEvaluationResult,
                attributesOpenApp,
                setAttributesOpenApp,
                underretningEvaluationResult,
                setUnderretningEvaluationResult,
                screeningEvaluationResult,
                setScreeningEvaluationResult,
                groupsEvaluationResult,
                setGroupsEvaluationResult,
                caseNo,
                setCaseNo,
            }}
        >
            {children}
        </versionContext.Provider>
    );
};

export default VersionContextProvider;
