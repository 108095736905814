// See: https://www.reddit.com/r/reactnative/comments/uxa00x/can_we_call_hooks_inside_useeffect/
import React, { useContext, useCallback } from 'react';

import { displayContext } from '../../common/DisplayContext';
import { userCaseContext } from '../contexts/UserCaseContext';
import { evaluationContext } from '../contexts/EvaluationContext';
import { domainContext } from '../contexts/DomainContext';
import { knowledgeContext } from '../contexts/KnowledgeContext';

import useAuth from './useAuth';
import Messages from '../components/Messages';

function useGetCase() {
    const {
        setButtonAcceptDisabled,
        setButtonLetterDisabled,
        setButtonRejectDisabled,
        setButtonSaveNewRuleDisabled,
        setDiffIndicatorStatus,
        setDisplayMessage,
        setIsLoading3,
        setNewConclusion,
        setNewExplanation,
        setNewLetterRuleFalse,
        setNewLetterRuleTrue,
        setNewNote,
        setNewRule,
        setSpinnerMessage,
        setTextAreaNewNoteDisabled,
        setTextAreaNewRuleDisabled,
        setTextAreaNewConclusionDisabled,
        t,
        overlayTriggerHelp,
    } = useContext(displayContext);

    const { knowledgebaseType } = useContext(knowledgeContext);

    const { userCase, setUserCase, setCaseStatus } =
        useContext(userCaseContext);

    const {
        currentCaseId,
        nodeLastTrue,
        setConclusionLastTrue,
        setEvaluationResult,
        setExplanation,
        setExplanationLastTrue,
        setExplanationTrues,
        setNodeLast,
        setNodeLastTrue,
        setNodesEvaluated,
        setNoteLastTrue,
        setReferenceCase,
        setReturnCode,
    } = useContext(evaluationContext);

    const { currentKnowledgeBase, currentDomain } = useContext(domainContext);

    const { token } = useAuth();

    async function getCase(mode, busCaseId) {
        var obj = {};
        var ruleFound = false;

        const cloneCaseData = [...userCase];
        cloneCaseData.forEach((attribute) => {
            attribute['referenceValue'] = '';
            attribute['referenceMatch'] = '';
        });

        setUserCase(cloneCaseData);

        obj.mode = mode;
        obj.busCaseId = busCaseId;
        obj.knowledgeBase = currentKnowledgeBase;
        obj.domain = currentDomain;
        // obj.mode = mode;
        obj.category = '';

        // Clear fields in window
        setDisplayMessage({
            messageCode: null,
            messageText: 'Processing...',
            displayCode: 1,
        });

        setNewConclusion('');
        setNewRule('');
        setNewNote('');

        if (obj.busCaseId != '') {
            var endpoint = process.env.REACT_APP_FLASK_API + 'getCase';

            try {
                const response = await fetch(endpoint, {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        Authorization: 'Bearer ' + token,
                    },
                    body: JSON.stringify(obj),
                });

                const responseJson = await response.json();

                setEvaluationResult(responseJson);

                if (knowledgebaseType == 'sc') {
                    setUserCase(responseJson['caseData']);
                    setCaseStatus(responseJson['status']);
                    setConclusionLastTrue(responseJson['conclusionLastTrue']);
                    setExplanationLastTrue(responseJson['explanationLastTrue']);

                    setNewConclusion(responseJson['conclusionLastTrue']);
                    setNewExplanation(responseJson['explanationLastTrue']);
                    setNewNote(responseJson['noteLastTrue']);

                    setNewLetterRuleFalse(responseJson['letterRuleFalseLast']);
                    setNewLetterRuleTrue(responseJson['letterRuleTrueLast']);

                    setExplanationTrues(responseJson['explanationTrues']);

                    setNodeLast(responseJson['nodeLast']);
                    setNodeLastTrue(responseJson['nodeLastTrue']);
                    setNodesEvaluated(responseJson['nodesEvaluated']);
                    setNoteLastTrue(responseJson['noteLastTrue']);
                    setReturnCode(responseJson['returnCode']);

                    // Convert the returned explanation (list of short rules, long rules and evaluation result) to a
                    // list of dictionaries
                    const explanationDict = [];
                    var id = 0;
                    responseJson['explanation'].forEach((expl) => {
                        id = id + 1;
                        explanationDict.push({
                            id: id,
                            explanation: expl[0],
                            explanationLong: expl[1],
                            displayCode: expl[2] === true ? 0 : 1,
                            letterRuleTrue: expl[3],
                            letterRuleFalse: expl[4],
                        });
                    });

                    setExplanation(explanationDict);

                    // Interpret the return code sent by the server
                    if (responseJson['message'].messageCode !== 0) {
                        setDisplayMessage(Messages(responseJson['message']));
                    }
                } else if (knowledgebaseType == 'mc') {
                    setUserCase(responseJson['caseData']);
                }

                // Enable / disable buttons
                // messageCode = 0 if the evaluation found a rule that applies to the case
                ruleFound = responseJson['message'].messageCode == 0;
                setButtonLetterDisabled(false);
                setButtonAcceptDisabled(false);
                setButtonRejectDisabled(false);

                setDiffIndicatorStatus(true);

                // messageCode = 1 if there are no rules for this domain yet (only the dummy startnode)
                if (responseJson['message'].messageCode === 1) {
                    setTextAreaNewConclusionDisabled(false);
                    setTextAreaNewRuleDisabled(false);
                    setTextAreaNewNoteDisabled(false);
                    setButtonSaveNewRuleDisabled(false);
                    setButtonLetterDisabled(true);
                    setButtonAcceptDisabled(true);
                    setButtonRejectDisabled(false);

                    // messageCode = 2 if no rules apply to the case
                } else if (responseJson['message'].messageCode === 2) {
                    setTextAreaNewConclusionDisabled(false);
                    setTextAreaNewRuleDisabled(false);
                    setTextAreaNewNoteDisabled(false);
                    setButtonSaveNewRuleDisabled(false);
                    setButtonLetterDisabled(true);
                    setButtonAcceptDisabled(true);
                    setButtonRejectDisabled(false);
                } else {
                    setDisplayMessage('');
                }
                setIsLoading3(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setDisplayMessage({
                    messageCode: -1,
                    messageText: 'Error fetching data',
                    displayCode: 0,
                });
                setIsLoading3(false);
            }
        } else {
            setDisplayMessage({
                messageCode: null,
                messageText: '',
                displayCode: 1,
            });
        }
        setIsLoading3(false);
    }

    return getCase;
}

export default useGetCase;
