import React, { useState, useContext, useEffect } from 'react'
import '../test.css' // Create this CSS file

import {
    BsCheck,
    BsX,
    BsChevronDoubleDown,
    BsTriangleFill,
} from 'react-icons/bs'

import trademarkStart from '../images/trademark-start.png'

// import styles from '../styles.module.css'

// import { swiperContext } from '../contexts/SwiperContext';
import { swiperContext } from '../contexts/SwiperContext'

import { useNavigate } from 'react-router-dom'
// import useEvaluate from '../hooks/useEvaluate';
// import useEvaluate from '../../commonTrademark/hooks/useEvaluate';
import useEvaluate from '../hooks/useEvaluate'

import { displayContext } from '../../common/DisplayContext'
import { errorContext } from '../../admin/contexts/ErrorContext'

import Button from 'react-bootstrap/Button'
import Spinner from 'react-bootstrap/Spinner'

import Linkify from 'react-linkify'

import TrademarkDesktopChatEmbedQuery from './TrademarkDesktopChatEmbedQuery'

function Trademark() {
    let navigate = useNavigate()

    const { t2, t3 } = useContext(displayContext)

    const { errors, setErrors } = useContext(errorContext)

    const [{ handleEvaluate }] = useEvaluate()

    const {
        optionDescriptions,
        setOptionDescription,
        selectedOptions,
        setSelectedOptions,
        useEvaluationResult,
        versionEvaluationResult,
        placeEvaluationResult,
        contextEvaluationResult,
        continuationEvaluationResult,
        processing,
        setUseEvaluationResult,
        setVersionEvaluationResult,
        setPlaceEvaluationResult,
        setContextEvaluationResult,
        setContinuationEvaluationResult,
        aggConclusion,
        setAggConclusion,
        chatText,
        abekat,
    } = useContext(swiperContext)

    const [infoButton, setInfoButton] = useState(false)
    const [showConclusion, setShowConclusion] = useState(false)
    const [optionSelected, setOptionSelected] = useState([
        true,
        true,
        true,
        true,
        true,
    ])

    let useResult = {}
    let versionResult = {}
    let placeResult = {}
    let contextResult = {}
    let continuationResult = {}

    function handleUseAsBrandYes(e) {
        setOption(1, 0, 1, 'replaceInRow')
    }

    function handleUseAsBrandNo(e) {
        setOption(1, 0, 2, 'replaceInRow')
    }

    function handleOneVersionYes(e) {
        setOption(2, 0, 1, 'replaceInRow')
    }

    function handleOneVersionNo(e) {
        setOption(2, 0, 2, 'replaceInRow')
    }

    function handleOngoing(e) {
        setOption(5, 0, 1, 'replaceInRow')
    }
    function handlePause(e) {
        setOption(5, 0, 2, 'replaceInRow')
    }
    function handleStopped(e) {
        setOption(5, 0, 3, 'replaceInRow')
    }

    function setOption(ball, row, column, mode) {
        // If this option was not already selected,
        // if in replaceInRow mode, then remove other options in the same ball and row from selectedOptions
        // else, if in replaceInColum mode, then remove other options in the same column from selectedOptions
        // else, if in add mode, then just update this option.

        // 1. create copy of the original array of selected options
        // 2. add this option to the array of selected options...
        if (
            selectedOptions.findIndex(
                (item) =>
                    item.ball == ball &&
                    item.row == row &&
                    item.column == column
            ) === -1
        ) {
            let newSelectedOptions = []
            // So, in replace mode, create a new array of the original where we filter away other options set for this column...
            if (mode == 'replaceInRow') {
                newSelectedOptions = selectedOptions.filter(
                    (item) => !(item.ball === ball && item.row == row)
                )
            } else if (mode == 'replaceInColumn') {
                newSelectedOptions = selectedOptions.filter(
                    (item) => !(item.ball === ball && item.column == column)
                )
            } else if (mode == 'add') {
                // ...but in add mode, create a new array as a full copy of the original
                newSelectedOptions = [...selectedOptions]
            }

            // add currentOption to selectedOptions
            newSelectedOptions.push({ ball: ball, row: row, column: column })
            setSelectedOptions(newSelectedOptions)
            // ...else, i.e. this option was already selected, remove it from the array of selected options.
        } else {
            // remove currentOption from selectOptions
            setSelectedOptions(
                selectedOptions.filter(
                    (item) =>
                        !(
                            item.ball === ball &&
                            item.row == row &&
                            item.column == column
                        )
                )
            )
        }
    }

    function clear() {
        setSelectedOptions([])
        setShowConclusion(false)
    }

    function findFormErrors() {
        const newErrors = {}

        const tmpOptionSelected = [
            selectedOptions.findIndex((item) => item.ball == 1) != -1,
            selectedOptions.findIndex((item) => item.ball == 2) != -1,
            selectedOptions.findIndex((item) => item.ball == 3) != -1,
            selectedOptions.findIndex((item) => item.ball == 4) != -1,
            selectedOptions.findIndex((item) => item.ball == 5) != -1,
        ]

        setOptionSelected(tmpOptionSelected)

        if (
            tmpOptionSelected.reduce((accumulator, currentValue) => {
                return accumulator && currentValue
            }, true) == false
        )
            newErrors.csv = 'Options not selected for some dimensions'

        return newErrors
    }

    async function evaluate() {
        setShowConclusion(false)
        setAggConclusion('')
        setUseEvaluationResult({})
        setVersionEvaluationResult({})
        setPlaceEvaluationResult({})
        setContextEvaluationResult({})
        setContinuationEvaluationResult({})

        // Validate input form
        const newErrors = findFormErrors()
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors)
        } else {
            // No errors! Put any logic here for the form submission!
            useResult = await handleEvaluate('trademark', 'use')
            versionResult = await handleEvaluate('trademark', 'version')
            placeResult = await handleEvaluate('trademark', 'place')
            contextResult = await handleEvaluate('trademark', 'context')
            continuationResult = await handleEvaluate(
                'trademark',
                'continuation'
            )

            if (
                useResult.conclusionLastTrue == 'acquired' &&
                versionResult.conclusionLastTrue == 'acquired' &&
                placeResult.conclusionLastTrue == 'acquired' &&
                contextResult.conclusionLastTrue == 'acquired' &&
                continuationResult.conclusionLastTrue == 'acquired'
            )
                setAggConclusion(
                    'Du har ved brug stiftet en varemærkeret til dit kendetegn:'
                )
            else
                setAggConclusion(
                    'Du har ikke stiftet en varemærkeret til dit kendetegn ved brug:'
                )

            setShowConclusion(true)
        }
    }

    // ////////////  START  ///////////
    // Put an overflow indicator arrow in the columns if there is overflow happening
    // ////////////////////////////////

    useEffect(() => {
        checkOverflow('place-div')
        checkOverflow('context-div')
        // checkOverflow('conclusion-div');

        // // Add scroll event listeners to both divs
        document
            .getElementById('place-div')
            .addEventListener('scroll', function () {
                handleScroll('place-div')
            })

        document
            .getElementById('context-div')
            .addEventListener('scroll', function () {
                handleScroll('context-div')
            })

        document
            .getElementById('conclusion-div')
            .addEventListener('scroll', function () {
                handleScroll('conclusion-div')
            })
    }, [])

    // Function to check for overflow and show/hide indicator
    function checkOverflow(divId) {
        var div = document.getElementById(divId)

        if (div.scrollHeight > div.clientHeight) {
            showIndicator(divId)
        } else {
            hideIndicator(divId)
        }
    }

    // Function to handle scroll events and show/hide indicator based on position
    function handleScroll(divId) {
        var div = document.getElementById(divId)

        console.log(div.scrollTop + div.offsetHeight, ', ', div.scrollHeight)

        // if (div.scrollHeight - div.scrollTop === div.clientHeight) {
        if (div.scrollTop + div.offsetHeight >= div.scrollHeight) {
            hideIndicator(divId)
        } else {
            showIndicator(divId)
        }
    }

    // Function to show indicator
    function showIndicator(divId) {
        var indicator = document.getElementById(divId + 'Indicator')
        indicator.style.display = 'block'
    }

    // Function to hide indicator
    function hideIndicator(divId) {
        var indicator = document.getElementById(divId + 'Indicator')
        indicator.style.display = 'none'
    }

    // ////////////  END  ///////////

    function convertPDFLinks(text) {
        // if we find a .pdf in the input text-string
        if (typeof text != 'undefined' && text.indexOf('.pdf') != -1) {
            const pdfLocation = text.indexOf('.pdf')
            const textUntilPdf = text.substring(0, pdfLocation)
            const pdfStart =
                textUntilPdf.lastIndexOf(' ') == -1
                    ? 0
                    : textUntilPdf.lastIndexOf(' ')

            // if headString is text: return headString + recursive call to tailstring
            if (pdfStart != 0) {
                const headString = text.substring(0, pdfStart + 2)
                const tailString = text.substring(pdfStart + 2, text.length)

                return (
                    <>
                        <a>{headString}</a>
                        {convertPDFLinks(tailString)}
                    </>
                )
            }

            // if headString is  the .pdf: return recursive call for headstring + pdf-tag
            else {
                const headString = text.substring(0, pdfLocation + 4)
                const tailString = text.substring(pdfLocation + 4, text.length)

                // const referenceLink = 'http://localhost:8000/' + headString
                const referenceLink = process.env.REACT_APP_PDF + headString

                return (
                    <>
                        <a
                            href={referenceLink}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Se dokument
                        </a>
                        {convertPDFLinks(tailString)}
                    </>
                )
            }
        }

        // else return text
        else {
            return <a>{text}</a>
        }
    }

    // function convertLinks(text, target) {

    //     // if we find a 'target' in the input text-string

    //     // Breakpoint for debugging: text != '' && text != 'Du bruger varemærket som dit brand (Varemærkelovens § 3, stk. 1, nr. 3) - https://www.retsinformation.dk/eli/lta/2019/88'

    //         if (typeof(text) != 'undefined' && text.indexOf(target) != -1) {

    //             const targetLocation = text.indexOf(target)

    //             const textUntilTarget = text.substring(0, targetLocation)
    //             const targetStart = textUntilTarget.lastIndexOf(' ') == -1 ? 0 : textUntilTarget.lastIndexOf(' ')

    //             let referenceStart = 0
    //             let referenceEnd = 0

    //             // Find the start location of the reference
    //             if (target == '.pdf') {
    //                 const textUntilTarget = text.substring(0, targetLocation)
    //                 referenceStart = textUntilTarget.lastIndexOf(' ') == -1 ? 0 : textUntilTarget.lastIndexOf(' ')
    //             } else if (target == 'http://' || target == 'https://') {
    //                 referenceStart = targetLocation
    //             }

    //             // Find the end location of the reference
    //             if (target == '.pdf') {
    //                 referenceEnd = targetLocation + 4
    //             } else if (target == 'http://' || 'https://') {
    //                 referenceEnd = text.indexOf(' ', referenceStart)
    //             }

    //             const reference = text.substring(referenceStart, referenceEnd)

    //             // if headString is text: return headString + recursive call to tailstring
    //             if (referenceStart != 0) {

    //                 const headString = text.substring(0, targetStart + 2)
    //                 const tailString = text.substring(targetStart + 2, text.length)

    //                 return (
    //                     <>
    //                         <a>{headString}</a>
    //                         {convertLinks(tailString, target)}
    //                     </>
    //                 )
    //             }

    //             // if headString is  the .pdf: return recursive call for headstring + pdf-tag
    //             else {

    //                 // const headString = text.substring(0, targetLocation + target.length)
    //                 // const tailString = text.substring(targetLocation + target.length, text.length)
    //                 const headString = text.substring(0, referenceEnd)
    //                 const tailString = text.substring(referenceEnd, text.length)

    //                 // const referenceLink = 'http://localhost:8000/' + headString
    //                 // const referenceLink = process.env.REACT_APP_PDF + reference
    //                 // const referenceLink = 'https://www.retsinformation.dk/eli/lta/2019/88'

    //                 const referenceLink = ''

    //                 if (target == '.pdf') {
    //                     referenceLink = process.env.REACT_APP_PDF + reference
    //                 } else if (target == 'http://' || target == 'https://') {
    //                     referenceLink = reference
    //                 }

    //                 return (
    //                     <>
    //                         {
    //                             target == '.pdf' ?
    //                             <a href={referenceLink} target='_blank' rel='noopener noreferrer'>Se dokument</a>
    //                             :
    //                             <a href={referenceLink} target='_blank' rel='noopener noreferrer'>Se link</a>
    //                         }
    //                         {convertLinks(tailString, target)}
    //                     </>
    //                 )
    //             }

    //         }

    //         // else return text
    //         else {
    //             return  <a>{text}</a>
    //         }

    // }

    function Translate1({ textToTranslate }) {
        return <a>Hundehoved</a>
    }

    // function Translate({textToTranslate}){
    //     // const resultat = convertLinks(t2(textToTranslate, 'trademark'), '.pdf')
    //     const resultat = convertLinks(t2(textToTranslate, 'trademark'), ['.pdf', 'https://', 'http://'])
    //     return resultat
    // }

    // const text1 = 'Du bruger varemærket som dit brand (Varemærkelovens § 3, stk. 1, nr. 3) -<a href="https://www.retsinformation.dk/eli/lta/2019/88" target="_blank" rel="noopener noreferrer">Se link</a>'
    // const text2= <>Du bruger varemærket som dit brand (Varemærkelovens § 3, stk. 1, nr. 3) -<a href="https://www.retsinformation.dk/eli/lta/2019/88" target="_blank" rel="noopener noreferrer">Se link</a></>

    // function fake() {
    //     const abc = <>Hundehoved</>
    //     return (
    //         <>{text2}</>
    //     )
    // }

    // function convertLinks(text, targets) {
    //     // Iterate over each target in the targets array
    //     for (const target of targets) {
    //         // Apply the conversion logic for the current target
    //         text = convertSingleLink(text, target);
    //     }

    //     // Return the converted text
    //     return (
    //         <>
    //             {text}
    //         </>
    //     )
    // }

    // function convertSingleLink(text, target) {

    //     let referenceStart = 0
    //     let referenceEnd = 0
    //     let textBeforeReference = ''
    //     let textAfterReference = ''

    //     let textTargetAndAfter = ''
    //     let closingParenthesis = 0

    //     const linkBeforeReference = '<a href="'
    //     const linkAfterReference = '" target="_blank" rel="noopener noreferrer">Se link</a>'

    //     let nextSearchFrom = 0

    //     // Check if the target exists in the text
    //     if (text.indexOf(target) !== -1) {
    //         // Find the index of the target in the text
    //         let targetLocation = text.indexOf(target);
    //         let previousTargetLocation = 0;

    //         // Repeat conversion logic for each occurrence of the target

    //         while (targetLocation !== -1) {
    //             // Find the reference for the target
    //             let reference = '';

    //             if (target === '.pdf') {
    //                 // Logic for .pdf target
    //                 // Example: reference = process.env.REACT_APP_PDF + 'filename.pdf';
    //                 // Here, you would construct the reference URL for PDF files

    //                 // Find the start location of the reference
    //                 referenceStart = text.substring(0, targetLocation).lastIndexOf(' ') == -1 ? 0 : text.substring(0, targetLocation).lastIndexOf(' ')

    //                 // Find the end location of the reference
    //                 referenceEnd = targetLocation + 4

    //                 textBeforeReference = text.substring(0, text.substring(0,targetLocation).lastIndexOf('(') + 1)

    //                 textTargetAndAfter = text.substring(targetLocation, text.length);
    //                 closingParenthesis = textTargetAndAfter.indexOf(')');
    //                 textAfterReference = textTargetAndAfter.substring(closingParenthesis, text.length)

    //                 // textAfterReference = text.substring(text.substring(targetLocation, text.length).indexOf(')'), text.length)

    //                 reference = process.env.REACT_APP_PDF + text.substring(referenceStart + 2, referenceEnd);
    //                 // nextSearchFrom = targetLocation + linkBeforeReference.length + reference.length - '.pdf'.length
    //                 nextSearchFrom = textBeforeReference.length + linkBeforeReference.length + reference.length + linkAfterReference.length

    //             } else if (target === 'http://' || target === 'https://') {
    //                 // Logic for http:// or https:// target
    //                 // Example: reference = 'https://example.com';
    //                 // Here, you would construct the reference URL for HTTP/HTTPS links

    //                 // Find the start location of the reference
    //                 referenceStart = targetLocation

    //                 // Find the end location of the reference
    //                 referenceEnd = text.indexOf(' ', referenceStart) == -1 ? text.length : text.indexOf(' ', referenceStart)

    //                 textBeforeReference = text.substring(0, referenceStart -1)
    //                 textAfterReference = text.substring(referenceEnd + 1, text.length)

    //                 reference = text.substring(referenceStart, referenceEnd);
    //                 // nextSearchFrom = targetLocation + linkBeforeReference.length + reference.length - '.pdf'.length

    //             }

    //             // text = text.substring(0, targetLocation) + `<a href="${reference}" target="_blank" rel="noopener noreferrer">Se link</a>` + text.substring(targetLocation + target.length);
    //             // text = textBeforeReference + `<a href="${reference}" target="_blank" rel="noopener noreferrer">Se link</a>` + textAfterReference;
    //             text = textBeforeReference + linkBeforeReference + reference + linkAfterReference + textAfterReference;

    //             // Find the next occurrence of the target
    //             // previousTargetLocation = text.indexOf(target, targetLocation + 1);
    //             nextSearchFrom = textBeforeReference.length + linkBeforeReference.length + reference.length + linkAfterReference.length
    //             targetLocation = text.indexOf(target, nextSearchFrom);
    //         }
    //     }
    //     // Return the text after all occurrences of the target have been converted
    //     return (
    //         <>{text}</>
    //     )
    // }

    // Example usage
    // const text = 'This is a sample string with a .pdf link doc1.pdf and http:// link and maybe http://dr.dk/kdkd/kkk';
    // const targets = ['.pdf', 'http://', 'https://'];

    // const convertedText = convertLinks(text, targets);
    // console.log(convertedText);

    // Return the last character in the link
    function firstSeparator(text) {
        const targets = [' ', ',', '. ']

        let lastCharacter = -1

        for (const target of targets) {
            const separatorLocation = text.indexOf(target, text)
            if (
                // Only accept the new location if
                separatorLocation > -1 && // the target is actually found
                (separatorLocation < lastCharacter || lastCharacter == -1) // the new one 'earlier' in the text
            ) {
                lastCharacter = separatorLocation - 1
            }
        }

        // If the link is followed immediately by a period
        if (
            text.lastIndexOf('/') != -1 &&
            text.lastIndexOf('/') < text.lastIndexOf('.') &&
            (text.lastIndexOf('.') < lastCharacter || lastCharacter == -1)
        ) {
            lastCharacter = text.lastIndexOf('.') - 1
        }

        // If we did not find a separator, then the link is actually the full input text
        if ((lastCharacter = -1)) {
            lastCharacter = text.length
        }

        return lastCharacter
    }

    function Translate({ textToTranslate }) {
        const convertedText = convertLinks(t2(textToTranslate, 'trademark'), [
            '.pdf',
            'https://',
            'http://',
        ])
        // const convertedText = convertLinks(t2(textToTranslate, 'trademark'), ['.pdf']);

        return <>{convertedText}</>
    }

    function convertLinks(text, targets) {
        const elements = []
        let lastIndex = 0
        let linkEnd1 = 0

        // Iterate over each target in the targets array
        for (const target of targets) {
            // Regular expression to match the target in the text
            const regex = new RegExp(target, 'g')
            let match

            // Iterate over each match of the target
            while ((match = regex.exec(text)) !== null) {
                // Add the text before the match as a React fragment
                // elements.push(<React.Fragment key={`before_${lastIndex}`}>{text.substring(lastIndex, match.index)}</React.Fragment>);

                const linkTitleStart = text
                    .substring(0, match.index)
                    .lastIndexOf('<LinkTitle>')
                const linkTitleEnd = text
                    .substring(0, match.index)
                    .lastIndexOf('</LinkTitle>')
                const linkTitle = text.substring(
                    linkTitleStart + '<LinkTitle>'.length,
                    linkTitleEnd
                )

                const linkStart = text
                    .substring(0, match.index)
                    .lastIndexOf('<Link>')
                const linkEnd =
                    match.index + text.substring(match.index).indexOf('</Link>')
                const link = text.substring(
                    linkStart + '<Link>'.length,
                    linkEnd
                )

                // elements.push(<React.Fragment key={`before_${lastIndex}`}>{text.substring(lastIndex, text.substring(0, match.index).lastIndexOf(' ') + 1)}</React.Fragment>);
                elements.push(
                    <React.Fragment key={`before_${lastIndex}`}>
                        {text.substring(lastIndex, linkTitleStart)}
                    </React.Fragment>
                )

                // Construct the link element
                let reference = match[0]
                if (target === '.pdf') {
                    // linkEnd = match.index + 4
                    // const fileName = text.substring(text.substring(0, match.index).lastIndexOf(' ') + 2, linkEnd)
                    // reference = <a href={process.env.REACT_APP_PDF + fileName} target="_blank" rel="noopener noreferrer">{fileName.slice(0, -4)}</a>;

                    reference = (
                        <a
                            href={process.env.REACT_APP_PDF + link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {linkTitle}
                        </a>
                    )
                } else if (
                    match[0].startsWith('http://') ||
                    match[0].startsWith('https://')
                ) {
                    // Regular expression to capture the entire URL
                    // const urlRegex = /https?:\/\/[^\s]+/g;
                    // urlRegex.lastIndex = match.index; // Reset regex index
                    // const urlMatch = urlRegex.exec(text);

                    // linkEnd1 = firstSeparator(text.substring(match.index, text.length)) + match.index
                    // const linkName = text.substring(match.index, linkEnd1)

                    // if (urlMatch) {
                    // reference = <a href={linkName} target="_blank" rel="noopener noreferrer">{linkName}</a>;
                    reference = (
                        <a
                            href={link}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {linkTitle}
                        </a>
                    )
                    // }
                }

                // Add the link element to the array
                elements.push(reference)

                // Update the lastIndex to the end of the match
                // lastIndex = match.index + match[0].length;
                lastIndex = linkEnd + '</Link>'.length
            }
        }

        // Add the remaining text after the last match
        elements.push(
            <React.Fragment key={`after_${lastIndex}`}>
                {text.substring(lastIndex)}
            </React.Fragment>
        )

        // Return the array of React elements
        return elements
    }

    return (
        <div className="container-desktop desktop-font">
            <div className="desktop-topbar">
                <span className="desktop-product-name">
                    Varemærkecheck (demo)
                </span>
                <span className="desktop-catchline">
                    {' '}
                    - svar på de 5 spørgsmål nedenfor og se om du har ret til
                    varemærket
                </span>
            </div>
            <div className="desktop-trademark-main">
                <div className="desktop-trademark-column">
                    {/* Chat */}
                    {/* <div className="desktop-trademark-row desktop-trademark-row-first"> */}
                    <div>
                        {/* <p>Beskriv hvordan du bruger varemærket</p> */}
                        <TrademarkDesktopChatEmbedQuery />
                    </div>
                </div>

                <div className="desktop-trademark-column">
                    {/* Use */}
                    <div className="desktop-trademark-row">
                        <p>Anvender du varemærket som brand?</p>
                        <div
                            className={
                                optionSelected[0]
                                    ? 'desktop-radio-group desktop-no-error-border'
                                    : 'desktop-radio-group desktop-no-error-border desktop-error-border'
                            }
                        >
                            <div
                                className="desktop-select-option"
                                onClick={handleUseAsBrandYes}
                            >
                                <input
                                    type="checkbox"
                                    className="checkbox-to-radio"
                                    checked={
                                        selectedOptions.findIndex(
                                            (item) =>
                                                item.ball == 1 &&
                                                item.row == 0 &&
                                                item.column == 1
                                        ) != -1
                                    }
                                />
                                {t2('yes', 'trademark')}
                            </div>

                            <div
                                className="desktop-select-option"
                                onClick={handleUseAsBrandNo}
                            >
                                <input
                                    type="checkbox"
                                    className="checkbox-to-radio"
                                    checked={
                                        selectedOptions.findIndex(
                                            (item) =>
                                                item.ball == 1 &&
                                                item.row == 0 &&
                                                item.column == 2
                                        ) != -1
                                    }
                                />
                                {t2('no', 'trademark')}
                            </div>
                        </div>
                    </div>

                    {/* Version */}
                    <div className="desktop-trademark-row">
                        <p>Anvender du kun varemærket i én version?</p>
                        <div
                            className={
                                optionSelected[1]
                                    ? 'desktop-radio-group desktop-no-error-border'
                                    : 'desktop-radio-group desktop-no-error-border desktop-error-border'
                            }
                        >
                            <div
                                className="desktop-select-option"
                                onClick={handleOneVersionYes}
                            >
                                <input
                                    type="checkbox"
                                    className="checkbox-to-radio"
                                    checked={
                                        selectedOptions.findIndex(
                                            (item) =>
                                                item.ball == 2 &&
                                                item.row == 0 &&
                                                item.column == 1
                                        ) != -1
                                    }
                                />
                                {t2('yes', 'trademark')}
                            </div>

                            <div
                                className="desktop-select-option"
                                onClick={handleOneVersionNo}
                            >
                                <input
                                    type="checkbox"
                                    className="checkbox-to-radio"
                                    checked={
                                        selectedOptions.findIndex(
                                            (item) =>
                                                item.ball == 2 &&
                                                item.row == 0 &&
                                                item.column == 2
                                        ) != -1
                                    }
                                />
                                {t2('no', 'trademark')}
                            </div>
                        </div>
                    </div>

                    {/* Continuation */}
                    <div className="desktop-trademark-row desktop-trademark-row-last ">
                        <p>
                            Bruger du varemærket varigt, holder du pause, eller
                            er du stoppet?
                        </p>
                        <div
                            className={
                                optionSelected[4]
                                    ? 'desktop-radio-group desktop-no-error-border'
                                    : 'desktop-radio-group desktop-no-error-border desktop-error-border'
                            }
                        >
                            <div
                                className="desktop-select-option"
                                onClick={handleOngoing}
                            >
                                <input
                                    type="checkbox"
                                    className="checkbox-to-radio"
                                    checked={
                                        selectedOptions.findIndex(
                                            (item) =>
                                                item.ball == 5 &&
                                                item.row == 0 &&
                                                item.column == 1
                                        ) != -1
                                    }
                                />
                                {t2('labelOngoing', 'trademark')}
                            </div>

                            <div
                                className="desktop-select-option"
                                onClick={handlePause}
                            >
                                <input
                                    type="checkbox"
                                    className="checkbox-to-radio"
                                    checked={
                                        selectedOptions.findIndex(
                                            (item) =>
                                                item.ball == 5 &&
                                                item.row == 0 &&
                                                item.column == 2
                                        ) != -1
                                    }
                                />
                                {t2('labelPaused', 'trademark')}
                            </div>

                            <div
                                className="desktop-select-option"
                                onClick={handleStopped}
                            >
                                <input
                                    type="checkbox"
                                    className="checkbox-to-radio"
                                    checked={
                                        selectedOptions.findIndex(
                                            (item) =>
                                                item.ball == 5 &&
                                                item.row == 0 &&
                                                item.column == 3
                                        ) != -1
                                    }
                                />
                                {t2('labelStopped', 'trademark')}
                            </div>
                        </div>
                    </div>
                </div>

                {/* Place */}
                <div className="desktop-trademark-column2">
                    <p>Hvor bruger du varemærket?</p>
                    <div id="place-divIndicator">
                        <BsTriangleFill className="desktop-overflow-icon desktop-overflow-icon-place" />
                    </div>
                    <div
                        id="place-div"
                        className={
                            optionSelected[2]
                                ? 'desktop-no-error-border'
                                : 'desktop-no-error-border desktop-error-border'
                        }
                    >
                        <div className="desktop-option-group">
                            {optionDescriptions
                                .filter(
                                    (option) =>
                                        option.ball == 3 &&
                                        option.attributeGroup == 'place' &&
                                        (option.subCategory == 'town' ||
                                            option.subCategory == 'online')
                                )
                                .map((option) => {
                                    return (
                                        <div>
                                            <label>
                                                <input
                                                    className="desktop-checkbox"
                                                    type="checkbox"
                                                    checked={
                                                        selectedOptions.findIndex(
                                                            (item) =>
                                                                item.ball ==
                                                                    option.ball &&
                                                                item.row ==
                                                                    option.row &&
                                                                item.column ==
                                                                    option.column
                                                        ) != -1
                                                    }
                                                    onChange={() => {
                                                        setOption(
                                                            option.ball,
                                                            option.row,
                                                            option.column,
                                                            'add'
                                                        )
                                                    }}
                                                />
                                                {option.attributeText}
                                            </label>
                                        </div>
                                    )
                                })}
                        </div>

                        <div className="desktop-option-group">
                            <a>{t2('labelPlace', 'trademark')}</a>

                            {optionDescriptions
                                .filter(
                                    (option) =>
                                        option.ball == 3 &&
                                        option.attributeGroup == 'place' &&
                                        option.subCategory == 'place'
                                )
                                .map((option) => {
                                    return (
                                        <div>
                                            <label>
                                                <input
                                                    className="desktop-checkbox"
                                                    type="checkbox"
                                                    checked={
                                                        selectedOptions.findIndex(
                                                            (item) =>
                                                                item.ball ==
                                                                    option.ball &&
                                                                item.row ==
                                                                    option.row &&
                                                                item.column ==
                                                                    option.column
                                                        ) != -1
                                                    }
                                                    onChange={() => {
                                                        setOption(
                                                            option.ball,
                                                            option.row,
                                                            option.column,
                                                            'add'
                                                        )
                                                    }}
                                                />
                                                {option.attributeText}
                                            </label>
                                        </div>
                                    )
                                })}
                        </div>

                        <div className="desktop-option-group">
                            <a>{t2('labelDenmark', 'trademark')}</a>
                            {optionDescriptions
                                .filter(
                                    (option) =>
                                        option.ball == 3 &&
                                        option.attributeGroup == 'place' &&
                                        option.subCategory == 'denmark'
                                )
                                .map((option) => {
                                    return (
                                        <div>
                                            <label>
                                                <input
                                                    className="desktop-checkbox"
                                                    type="checkbox"
                                                    checked={
                                                        selectedOptions.findIndex(
                                                            (item) =>
                                                                item.ball ==
                                                                    option.ball &&
                                                                item.row ==
                                                                    option.row &&
                                                                item.column ==
                                                                    option.column
                                                        ) != -1
                                                    }
                                                    onChange={() => {
                                                        setOption(
                                                            option.ball,
                                                            option.row,
                                                            option.column,
                                                            'add'
                                                        )
                                                    }}
                                                />
                                                {option.attributeText}
                                            </label>
                                        </div>
                                    )
                                })}
                        </div>

                        <div className="desktop-option-group">
                            <a>{t2('labelAbroad', 'trademark')}</a>
                            {optionDescriptions
                                .filter(
                                    (option) =>
                                        option.ball == 3 &&
                                        option.attributeGroup == 'place' &&
                                        option.subCategory == 'abroad'
                                )
                                .map((option) => {
                                    return (
                                        <div>
                                            <label>
                                                <input
                                                    className="desktop-checkbox"
                                                    type="checkbox"
                                                    checked={
                                                        selectedOptions.findIndex(
                                                            (item) =>
                                                                item.ball ==
                                                                    option.ball &&
                                                                item.row ==
                                                                    option.row &&
                                                                item.column ==
                                                                    option.column
                                                        ) != -1
                                                    }
                                                    onChange={() => {
                                                        setOption(
                                                            option.ball,
                                                            option.row,
                                                            option.column,
                                                            'add'
                                                        )
                                                    }}
                                                />
                                                {option.attributeText}
                                            </label>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </div>

                {/* Context */}
                <div className="desktop-trademark-column">
                    <p>Hvordan bruger du varemærket?</p>
                    <div id="context-divIndicator">
                        <BsTriangleFill className="desktop-overflow-icon desktop-overflow-icon-context" />
                    </div>
                    <div
                        id="context-div"
                        className={
                            optionSelected[3]
                                ? 'desktop-no-error-border'
                                : 'desktop-no-error-border desktop-error-border'
                        }
                    >
                        <div className="desktop-option-group">
                            {optionDescriptions
                                .filter(
                                    (option) =>
                                        option.ball == 4 &&
                                        option.attributeGroup == 'context' &&
                                        option.subCategory ==
                                            'tv-radio-newsletter-email'
                                )
                                .map((option) => {
                                    return (
                                        <div>
                                            <label>
                                                <input
                                                    className="desktop-checkbox"
                                                    type="checkbox"
                                                    checked={
                                                        selectedOptions.findIndex(
                                                            (item) =>
                                                                item.ball ==
                                                                    option.ball &&
                                                                item.row ==
                                                                    option.row &&
                                                                item.column ==
                                                                    option.column
                                                        ) != -1
                                                    }
                                                    onChange={() => {
                                                        setOption(
                                                            option.ball,
                                                            option.row,
                                                            option.column,
                                                            'add'
                                                        )
                                                    }}
                                                />
                                                {option.attributeText}
                                            </label>
                                        </div>
                                    )
                                })}
                        </div>

                        <div className="desktop-option-group">
                            <a>{t2('labelDetail', 'trademark')}</a>
                            {optionDescriptions
                                .filter(
                                    (option) =>
                                        option.ball == 4 &&
                                        option.attributeGroup == 'context' &&
                                        option.subCategory == 'detail'
                                )
                                .map((option) => {
                                    return (
                                        <div>
                                            <label>
                                                <input
                                                    className="desktop-checkbox"
                                                    type="checkbox"
                                                    checked={
                                                        selectedOptions.findIndex(
                                                            (item) =>
                                                                item.ball ==
                                                                    option.ball &&
                                                                item.row ==
                                                                    option.row &&
                                                                item.column ==
                                                                    option.column
                                                        ) != -1
                                                    }
                                                    onChange={() => {
                                                        setOption(
                                                            option.ball,
                                                            option.row,
                                                            option.column,
                                                            'add'
                                                        )
                                                    }}
                                                />
                                                {option.attributeText}
                                            </label>
                                        </div>
                                    )
                                })}
                        </div>

                        <div className="desktop-option-group">
                            <a>{t2('labelAds', 'trademark')}</a>
                            {optionDescriptions
                                .filter(
                                    (option) =>
                                        option.ball == 4 &&
                                        option.attributeGroup == 'context' &&
                                        option.subCategory == 'ads'
                                )
                                .map((option) => {
                                    return (
                                        <div>
                                            <label>
                                                <input
                                                    className="desktop-checkbox"
                                                    type="checkbox"
                                                    checked={
                                                        selectedOptions.findIndex(
                                                            (item) =>
                                                                item.ball ==
                                                                    option.ball &&
                                                                item.row ==
                                                                    option.row &&
                                                                item.column ==
                                                                    option.column
                                                        ) != -1
                                                    }
                                                    onChange={() => {
                                                        setOption(
                                                            option.ball,
                                                            option.row,
                                                            option.column,
                                                            'add'
                                                        )
                                                    }}
                                                />
                                                {option.attributeText}
                                            </label>
                                        </div>
                                    )
                                })}
                        </div>

                        <div className="desktop-option-group">
                            <a>{t2('labelCompany', 'trademark')}</a>
                            {optionDescriptions
                                .filter(
                                    (option) =>
                                        option.ball == 4 &&
                                        option.attributeGroup == 'context' &&
                                        option.subCategory == 'company'
                                )
                                .map((option) => {
                                    return (
                                        <div>
                                            <label>
                                                <input
                                                    className="desktop-checkbox"
                                                    type="checkbox"
                                                    checked={
                                                        selectedOptions.findIndex(
                                                            (item) =>
                                                                item.ball ==
                                                                    option.ball &&
                                                                item.row ==
                                                                    option.row &&
                                                                item.column ==
                                                                    option.column
                                                        ) != -1
                                                    }
                                                    onChange={() => {
                                                        setOption(
                                                            option.ball,
                                                            option.row,
                                                            option.column,
                                                            'add'
                                                        )
                                                    }}
                                                />
                                                {option.attributeText}
                                            </label>
                                        </div>
                                    )
                                })}
                        </div>

                        <div className="desktop-option-group">
                            <a>{t2('labelTransportation', 'trademark')}</a>
                            {optionDescriptions
                                .filter(
                                    (option) =>
                                        option.ball == 4 &&
                                        option.attributeGroup == 'context' &&
                                        option.subCategory == 'transportation'
                                )
                                .map((option) => {
                                    return (
                                        <div>
                                            <label>
                                                <input
                                                    className="desktop-checkbox"
                                                    type="checkbox"
                                                    checked={
                                                        selectedOptions.findIndex(
                                                            (item) =>
                                                                item.ball ==
                                                                    option.ball &&
                                                                item.row ==
                                                                    option.row &&
                                                                item.column ==
                                                                    option.column
                                                        ) != -1
                                                    }
                                                    onChange={() => {
                                                        setOption(
                                                            option.ball,
                                                            option.row,
                                                            option.column,
                                                            'add'
                                                        )
                                                    }}
                                                />
                                                {option.attributeText}
                                            </label>
                                        </div>
                                    )
                                })}
                        </div>

                        <div className="desktop-option-group">
                            <a>{t2('labelHomepage', 'trademark')}</a>
                            {optionDescriptions
                                .filter(
                                    (option) =>
                                        option.ball == 4 &&
                                        option.attributeGroup == 'context' &&
                                        option.subCategory == 'homepage'
                                )
                                .map((option) => {
                                    return (
                                        <div>
                                            <label>
                                                <input
                                                    className="desktop-checkbox"
                                                    type="checkbox"
                                                    checked={
                                                        selectedOptions.findIndex(
                                                            (item) =>
                                                                item.ball ==
                                                                    option.ball &&
                                                                item.row ==
                                                                    option.row &&
                                                                item.column ==
                                                                    option.column
                                                        ) != -1
                                                    }
                                                    onChange={() => {
                                                        setOption(
                                                            option.ball,
                                                            option.row,
                                                            option.column,
                                                            'add'
                                                        )
                                                    }}
                                                />
                                                {option.attributeText}
                                            </label>
                                        </div>
                                    )
                                })}
                        </div>

                        <div className="desktop-option-group">
                            <a>{t2('labelSome', 'trademark')}</a>
                            {optionDescriptions
                                .filter(
                                    (option) =>
                                        option.ball == 4 &&
                                        option.attributeGroup == 'context' &&
                                        option.subCategory == 'some'
                                )
                                .map((option) => {
                                    return (
                                        <div>
                                            <label>
                                                <input
                                                    className="desktop-checkbox"
                                                    type="checkbox"
                                                    checked={
                                                        selectedOptions.findIndex(
                                                            (item) =>
                                                                item.ball ==
                                                                    option.ball &&
                                                                item.row ==
                                                                    option.row &&
                                                                item.column ==
                                                                    option.column
                                                        ) != -1
                                                    }
                                                    onChange={() => {
                                                        setOption(
                                                            option.ball,
                                                            option.row,
                                                            option.column,
                                                            'add'
                                                        )
                                                    }}
                                                />
                                                {option.attributeText}
                                            </label>
                                        </div>
                                    )
                                })}
                        </div>

                        <div className="desktop-option-group">
                            <a>{t2('labelPress', 'trademark')}</a>
                            {optionDescriptions
                                .filter(
                                    (option) =>
                                        option.ball == 4 &&
                                        option.attributeGroup == 'context' &&
                                        option.subCategory == 'press'
                                )
                                .map((option) => {
                                    return (
                                        <div>
                                            <label>
                                                <input
                                                    className="desktop-checkbox"
                                                    type="checkbox"
                                                    checked={
                                                        selectedOptions.findIndex(
                                                            (item) =>
                                                                item.ball ==
                                                                    option.ball &&
                                                                item.row ==
                                                                    option.row &&
                                                                item.column ==
                                                                    option.column
                                                        ) != -1
                                                    }
                                                    onChange={() => {
                                                        setOption(
                                                            option.ball,
                                                            option.row,
                                                            option.column,
                                                            'add'
                                                        )
                                                    }}
                                                />
                                                {option.attributeText}
                                            </label>
                                        </div>
                                    )
                                })}
                        </div>

                        <div className="desktop-option-group">
                            <a>{t2('labelInternal', 'trademark')}</a>
                            {optionDescriptions
                                .filter(
                                    (option) =>
                                        option.ball == 4 &&
                                        option.attributeGroup == 'context' &&
                                        option.subCategory == 'internal'
                                )
                                .map((option) => {
                                    return (
                                        <div>
                                            <label>
                                                <input
                                                    className="desktop-checkbox"
                                                    type="checkbox"
                                                    checked={
                                                        selectedOptions.findIndex(
                                                            (item) =>
                                                                item.ball ==
                                                                    option.ball &&
                                                                item.row ==
                                                                    option.row &&
                                                                item.column ==
                                                                    option.column
                                                        ) != -1
                                                    }
                                                    onChange={() => {
                                                        setOption(
                                                            option.ball,
                                                            option.row,
                                                            option.column,
                                                            'add'
                                                        )
                                                    }}
                                                />
                                                {option.attributeText}
                                            </label>
                                        </div>
                                    )
                                })}
                        </div>
                    </div>
                </div>

                <div className="desktop-trademark-column desktop-trademark-column-last">
                    <div className="desktop-conclusion-heading">
                        <a>Konklusion</a>
                        <div>
                            <Button
                                variant="success"
                                size="sm"
                                onClick={evaluate}
                            >
                                {t2('check', 'trademark')}
                            </Button>{' '}
                            <Button variant="danger" size="sm" onClick={clear}>
                                {t2('clear', 'trademark')}
                            </Button>
                        </div>
                    </div>

                    <div>
                        <div
                            className={
                                showConclusion
                                    ? 'desktop-show-conclusions'
                                    : 'desktop-hide-conclusions'
                            }
                        >
                            <div id="conclusion-divIndicator">
                                <BsTriangleFill className="desktop-overflow-icon desktop-overflow-icon-conclusion" />
                            </div>
                            <div className="desktop-agg-conclusion">
                                {aggConclusion}
                            </div>

                            <div
                                id="conclusion-div"
                                className="desktop-explanation-list"
                            >
                                {useEvaluationResult.explanationLastTrue !=
                                '' ? (
                                    <div
                                        className={
                                            useEvaluationResult.conclusionLastTrue ==
                                            'acquired'
                                                ? 'desktop-explanation-box desktop-explanation-approved'
                                                : 'desktop-explanation-box desktop-explanation-rejected'
                                        }
                                    >
                                        {/* <Linkify
                                            componentDecorator={(
                                                decoratedHref,
                                                decoratedText,
                                                key
                                            ) => (
                                                <a
                                                    target="blank"
                                                    href={decoratedHref}
                                                    key={key}
                                                >
                                                    {t2("labelSeeDecision", "trademark")}
                                                </a>
                                            )}
                                        >
                                            {t3(useEvaluationResult.explanationLastTrue, "trademark")}
                                        </Linkify> */}

                                        {/* <Translate textToTranslate={useEvaluationResult.explanationLastTrue} /> */}
                                        <Translate
                                            textToTranslate={
                                                useEvaluationResult.explanationLastTrue
                                            }
                                        />
                                    </div>
                                ) : (
                                    // </li>
                                    ''
                                )}

                                {versionEvaluationResult.explanationLastTrue !=
                                '' ? (
                                    <div
                                        className={
                                            versionEvaluationResult.conclusionLastTrue ==
                                            'acquired'
                                                ? 'desktop-explanation-box desktop-explanation-approved'
                                                : 'desktop-explanation-box desktop-explanation-rejected'
                                        }
                                    >
                                        {/* <Linkify
                                            componentDecorator={(
                                                decoratedHref,
                                                decoratedText,
                                                key
                                            ) => (
                                                <a
                                                    target="blank"
                                                    href={decoratedHref}
                                                    key={key}
                                                >
                                                    {t2("labelSeeDecision", "trademark")}
                                                </a>
                                            )}
                                        >
                                        </Linkify> */}
                                        <Translate
                                            textToTranslate={
                                                versionEvaluationResult.explanationLastTrue
                                            }
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}

                                {continuationEvaluationResult.explanationLastTrue !=
                                '' ? (
                                    <div
                                        className={
                                            continuationEvaluationResult.conclusionLastTrue ==
                                            'acquired'
                                                ? 'desktop-explanation-box desktop-explanation-approved'
                                                : 'desktop-explanation-box desktop-explanation-rejected'
                                        }
                                    >
                                        {/* <Linkify
                                            componentDecorator={(
                                                decoratedHref,
                                                decoratedText,
                                                key
                                            ) => (
                                                <a
                                                    target="blank"
                                                    href={decoratedHref}
                                                    key={key}
                                                >
                                                    {t2("labelSeeDecision", "trademark")}
                                                </a>
                                            )}
                                        >
                                            {t3(continuationEvaluationResult.explanationLastTrue, "trademark")}
                                        </Linkify> */}
                                        <Translate
                                            textToTranslate={
                                                continuationEvaluationResult.explanationLastTrue
                                            }
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}

                                {placeEvaluationResult.explanationLastTrue !=
                                '' ? (
                                    <div
                                        className={
                                            placeEvaluationResult.conclusionLastTrue ==
                                            'acquired'
                                                ? 'desktop-explanation-box desktop-explanation-approved'
                                                : 'desktop-explanation-box desktop-explanation-rejected'
                                        }
                                    >
                                        {/* <Linkify
                                            componentDecorator={(
                                                decoratedHref,
                                                decoratedText,
                                                key
                                            ) => (
                                                <a
                                                    target="blank"
                                                    href={decoratedHref}
                                                    key={key}
                                                >
                                                    {t2("labelSeeDecision", "trademark")}
                                                </a>
                                            )}
                                        >
                                            {t3(placeEvaluationResult.explanationLastTrue, "trademark")}
                                        </Linkify> */}
                                        <Translate
                                            textToTranslate={
                                                placeEvaluationResult.explanationLastTrue
                                            }
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}

                                {contextEvaluationResult.explanationLastTrue !=
                                '' ? (
                                    <div
                                        className={
                                            contextEvaluationResult.conclusionLastTrue ==
                                            'acquired'
                                                ? 'desktop-explanation-box desktop-explanation-approved'
                                                : 'desktop-explanation-box desktop-explanation-rejected'
                                        }
                                    >
                                        {/* <Linkify
                                            componentDecorator={(
                                                decoratedHref,
                                                decoratedText,
                                                key
                                            ) => (
                                                <a
                                                    target="blank"
                                                    href={decoratedHref}
                                                    key={key}
                                                >
                                                    {t2("labelSeeDecision", "trademark")}
                                                </a>
                                            )}
                                        >
                                            {t3(contextEvaluationResult.explanationLastTrue, "trademark")}
                                        </Linkify> */}
                                        <Translate
                                            textToTranslate={
                                                contextEvaluationResult.explanationLastTrue
                                            }
                                        />
                                    </div>
                                ) : (
                                    ''
                                )}
                            </div>
                        </div>

                        {/* : */}

                        {processing && (
                            <div
                                style={{
                                    display: !showConclusion ? 'flex' : 'none',
                                }}
                                className="desktop-spinner-container"
                            >
                                <div className="desktop-spinner">
                                    <Spinner
                                        animation="border"
                                        variant="secondary"
                                    />
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <div className="desktop-bottom-bar">bottom</div>
        </div>
    )
}

export default Trademark
