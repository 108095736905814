import React, { useEffect, useContext } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import { Button } from "react-bootstrap";

import Messages from "../Messages.js";

import { domainContext } from "../../contexts/DomainContext";
import { evaluationContext } from "../../contexts/EvaluationContext";
import { displayContext } from "../../../common/DisplayContext.js";
import { userCaseContext } from "../../contexts/UserCaseContext";

import styles from '../../styles.module.css';

import { IoMdSearch } from "react-icons/io";

import useAuth from "../../hooks/useAuth.js";
// import { Config } from "../../../config.js";
function ButtonSearchCase() {
    const { userCase, setUserCase, setCaseStatus } =
        useContext(userCaseContext);
    const { token } = useAuth();

    const {
        setButtonAcceptDisabled,
        setButtonLetterDisabled,
        setButtonRejectDisabled,
        setButtonSaveNewRuleDisabled,
        setDiffIndicatorStatus,
        setDisplayMessage,
        setIsLoading3,
        setNewConclusion,
        setNewExplanation,
        setNewLetterRuleFalse,
        setNewLetterRuleTrue,
        setNewNote,
        setNewRule,
        setSpinnerMessage,
        setTextAreaNewNoteDisabled,
        setTextAreaNewRuleDisabled,
        setTextAreaNewConclusionDisabled,
        t,
        t2,
        overlayTriggerHelp,
    } = useContext(displayContext);

    const { currentKnowledgeBase, currentDomain } = useContext(domainContext);

    const {
        currentCaseId,
        setConclusionLastTrue,
        setEvaluationResult,
        setExplanation,
        setExplanationLastTrue,
        setExplanationTrues,
        setNodeLast,
        setNodeLastTrue,
        setNodesEvaluated,
        setNoteLastTrue,
        setReferenceCase,
        setReturnCode,
    } = useContext(evaluationContext);

    function handleGetThisCase() {
        if (typeof currentCaseId !== "undefined") {
            setSpinnerMessage(t("evaluateCase"));
            setIsLoading3(true);
            HandleGetCase("this");
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////
    //  handleGetCase: reads a case from the knowledge base.
    //
    //  Input:
    //    mode: determines if the backend function reads the next, previous or a specific case
    //
    //  Output: setting of the case data state variable, various evaluation variables
    //  and various control variables
    //
    ///////////////////////////////////////////////////////////////////////////////////

    function HandleGetCase(mode) {
        var obj = {};
        var ruleFound = false;

        const cloneCaseData = [...userCase];
        cloneCaseData.forEach((attribute) => {
            attribute["referenceValue"] = "";
            attribute["referenceMatch"] = "";
        });
        setUserCase(cloneCaseData);

        // HandleClear();    <<<<<<<<<< I deleted this for test. We still need
        //                              to clear the fields some way, so
        //                              we need to do something here...

        obj.busCaseId = currentCaseId;
        obj.knowledgeBase = currentKnowledgeBase;
        obj.domain = currentDomain;
        obj.mode = mode;
        obj.category = "";

        // Clear fields in window
        setDisplayMessage({
            messageCode: null,
            // messageText: "Processing...",
            messageText: t("processing"),
            displayCode: 1,
        });

        // setIsLoading(true);
        setNewConclusion("");
        setNewRule("");
        setNewNote("");

        // var noRulesInDomain = true;
        // var noRulesApply = true;

        if (obj.busCaseId != "") {
            var endpoint = process.env.REACT_APP_FLASK_API + "getCase";
            fetch(endpoint, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify(obj),
            })
                .then((res) => res.json())
                .then((responseJson) => {
                    setEvaluationResult(responseJson);
                    setUserCase(responseJson["caseData"]);
                    console.log("ButtonLoadCase - SETTING userCase");
                    setCaseStatus(responseJson["status"]);
                    // setCurrentCaseId(responseJson["caseId"]);
                    setConclusionLastTrue(responseJson["conclusionLastTrue"]);
                    setExplanationLastTrue(responseJson["explanationLastTrue"]);

                    setNewConclusion(responseJson["conclusionLastTrue"]);
                    setNewExplanation(responseJson["explanationLastTrue"]);
                    setNewNote(responseJson["noteLastTrue"]);

                    // setNewLetter(responseJson["letter"]);
                    setNewLetterRuleFalse(responseJson["letterRuleFalseLast"]);
                    setNewLetterRuleTrue(responseJson["letterRuleTrueLast"]);

                    setExplanationTrues(responseJson["explanationTrues"]);

                    setNodeLast(responseJson["nodeLast"]);
                    setNodeLastTrue(responseJson["nodeLastTrue"]);
                    setNodesEvaluated(responseJson["nodesEvaluated"]);
                    setNoteLastTrue(responseJson["noteLastTrue"]);
                    // setReferenceCase(responseJson["referenceCase"]);
                    setReturnCode(responseJson["returnCode"]);

                    // Convert the returned explanation (list of short rules, long rules and evaluation result) to a
                    // list of dictionaries
                    const explanationDict = [];
                    var id = 0;
                    responseJson["explanation"].forEach((expl) => {
                        id = id + 1;
                        explanationDict.push({
                            id: id,
                            explanation: expl[0],
                            explanationLong: expl[1],
                            displayCode: expl[2] === true ? 0 : 1,
                            letterRuleTrue: expl[3],
                            letterRuleFalse: expl[4],
                        });
                    });
                    setExplanation(explanationDict);

                    // Interpret the return code sent by the server
                    if (responseJson["message"].messageCode !== 0) {
                        // setDisplayMessage(Messages(responseJson["message"]));

                        const messageText = Messages(responseJson["message"]).messageText;

                        setDisplayMessage({
                            messageCode: Messages(responseJson["message"]).messageCode,
                            messageText: t2(messageText, 'sysadmin'),
                            displayCode: Messages(responseJson["message"]).displayCode,
                        });


                    }

                    // Enable / disable buttons
                    // messageCode = 0 if the evaluation found a rule that applies to the case
                    ruleFound = responseJson["message"].messageCode == 0;
                    setButtonLetterDisabled(false);
                    setButtonAcceptDisabled(false);
                    setButtonRejectDisabled(false);

                    setDiffIndicatorStatus(true);

                    // messageCode = 1 if there are no rules for this domain yet (only the dummy startnode)
                    if (responseJson["message"].messageCode === 1) {
                        setTextAreaNewConclusionDisabled(false);
                        setTextAreaNewRuleDisabled(false);
                        setTextAreaNewNoteDisabled(false);
                        setButtonSaveNewRuleDisabled(false);
                        setButtonLetterDisabled(true);
                        setButtonAcceptDisabled(true);
                        setButtonRejectDisabled(false);

                        // messageCode = 2 if no rules apply to the case
                    } else if (responseJson["message"].messageCode === 2) {
                        setTextAreaNewConclusionDisabled(false);
                        setTextAreaNewRuleDisabled(false);
                        setTextAreaNewNoteDisabled(false);
                        setButtonSaveNewRuleDisabled(false);
                        setButtonLetterDisabled(true);
                        setButtonAcceptDisabled(true);
                        setButtonRejectDisabled(false);
                    } else setDisplayMessage("");
                    setIsLoading3(false);
                });
        } else {
            setDisplayMessage({
                messageCode: null,
                messageText: "",
                displayCode: 1,
            });
        }
        setIsLoading3(false);
    }

    // useEffect(() => {
    //     setSpinnerMessage(t("evaluatingCase"));
    //     handleGetThisCase();
    // }, []);

    return (
        <OverlayTrigger
            trigger={overlayTriggerHelp ? ["click", "hover", "focus"] : []}
            delay={400}
            delayHide={0}
            placement={"auto"}
            overlay={<Tooltip>{t("buttonLoadTooltip")}</Tooltip>}
        >
            {/* <Button
                className='me-1'
                size="sm"
                variant="success"
                onClick={handleGetThisCase}>
                <a>{t("load")}</a>
            </Button> */}


            <Button
                className={styles.caseIdBoxIcon}
                onClick={handleGetThisCase}>
                <IoMdSearch />
            </Button>






        </OverlayTrigger>
    );
}

export default ButtonSearchCase;
