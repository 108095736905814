import React, { createContext, useState, useEffect, useContext } from "react";

import i18n from "../../translations/i18n";

export const AlkaContext = createContext();

const AlkaContextProvider = ({ children }) => {

    i18n.changeLanguage("da")

    const [knowledgeBase, setKnowledgeBase] = useState('alka');

    const [attributesOpenApp, setAttributesOpenApp] = useState([]);

    const [time, setTime] = useState('');

    const [aggConclusion, setAggConclusion] = useState('');

    const [evaluationGroups, setEvaluationGroups] = useState({
        "risksidentified": 1,
        "risksanalysed": 2,
        "risksdetermined": 3,
        "datadocumented": 4,
        "devdatadocumented": 5,
        "devdataquality": 6,
        "devdatavolume": 7,
        "devdatabias": 8,
        "devdatapurpose": 9,
        "devdatanew": 10,
        "newdatalogged": 11,
        "inputdatalogged": 12,
        "explainable": 13,
        "interpretable": 14,
        "humanoversight": 15,
        "aisuitable": 16,
        "precision": 17,
        "consistent": 18,
        "qualitymanagement": 19,
        "conformity": 20,
        "ce": 21,
        "dataverification": 22,
        "riskmitigation": 23,
    })


    useEffect(() => {
        var obj = {};

        obj.knowledgeBase = knowledgeBase;
        obj.domain = 'default';

        var endpoint = process.env.REACT_APP_FLASK_API + "getAttributesOpenApp1";

        const fetchData = async () => {
            try {
                const response = await fetch(endpoint, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(obj),
                });

                if (!response.ok) {
                    throw new Error('Failed to fetch data');
                }
                const fetchedData = await response.json();

                const modifiedResponseJson = fetchedData.map((item) => {
                    // Check if there's an item in the dictionary with the same id
                    if (evaluationGroups.hasOwnProperty(item.name)) {
                        // Modify the item accordingly
                        return {
                            ...item,
                            // Add or update properties as needed
                            // For example, add a new property 'modified': true
                            evaluationGroup: evaluationGroups[item.name],
                            selected: item.name == 'ID' ? true : false,
                        };
                    }
                    // If there's no corresponding item in the dictionary, return the original item
                    return item;
                });

                setAttributesOpenApp(modifiedResponseJson);

            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData();
    }, []);






    useEffect(() => {
        var obj = {};

        obj.knowledgeBase = knowledgeBase;

        var endpoint =
            process.env.REACT_APP_FLASK_API + "time";
        fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify(obj),
        })
            .then((res) => res.json())
            .then((responseJson) => {
                setTime(responseJson);
            });
    }, []);





    // Variables receiving the evaluation results from the backend



    const [evaluationResult, setEvaluationResult] = useState({
        caseLastTrue: [{}],
        conclusionLastTrue: "",
        noteLastTrue: "",
        explanation: [],
        explanationTrues: [],
        nodeLast: 0,
        nodeLastTrue: 0,

        nodesEvaluated: [
            {
                conclusion: "",
                cornerstonecaseid: null,
                evaluationResult: true,
                id: 0,
                nextnodefalse: 0,
                nextnodetrue: 0,
                rule: "",
            },
        ],

        referenceCase: [],
        req_data: {
            age: "",
            weight: "",
        },
        message: {
            messageCode: 0,
        }
    });


    const [groupsEvaluationResult, setGroupsEvaluationResult] = useState({})

    const [risksEvaluationResult, setRisksEvaluationResult] = useState({
        caseLastTrue: [{}],
        conclusionLastTrue: "",
        noteLastTrue: "",
        explanation: [],
        explanationTrues: [],
        nodeLast: 0,
        nodeLastTrue: 0,

        nodesEvaluated: [
            {
                conclusion: "",
                cornerstonecaseid: null,
                evaluationResult: true,
                id: 0,
                nextnodefalse: 0,
                nextnodetrue: 0,
                rule: "",
            },
        ],

        referenceCase: [],
        req_data: {
            age: "",
            weight: "",
        },
    });

    const [dataEvaluationResult, setDataEvaluationResult] = useState({
        caseLastTrue: [{}],
        conclusionLastTrue: "",
        noteLastTrue: "",
        explanation: [],
        explanationTrues: [],
        nodeLast: 0,
        nodeLastTrue: 0,

        nodesEvaluated: [
            {
                conclusion: "",
                cornerstonecaseid: null,
                evaluationResult: true,
                id: 0,
                nextnodefalse: 0,
                nextnodetrue: 0,
                rule: "",
            },
        ],

        referenceCase: [],
        req_data: {
            age: "",
            weight: "",
        },
    });

    const [dataForDevEvaluationResult, setDataForDevEvaluationResult] =
        useState({
            caseLastTrue: [{}],
            conclusionLastTrue: "",
            noteLastTrue: "",
            explanation: [],
            explanationTrues: [],
            nodeLast: 0,
            nodeLastTrue: 0,

            nodesEvaluated: [
                {
                    conclusion: "",
                    cornerstonecaseid: null,
                    evaluationResult: true,
                    id: 0,
                    nextnodefalse: 0,
                    nextnodetrue: 0,
                    rule: "",
                },
            ],

            referenceCase: [],
            req_data: {
                age: "",
                weight: "",
            },
        });

    const [techDocEvaluationResult, setTechDocEvaluationResult] =
        useState({
            caseLastTrue: [{}],
            conclusionLastTrue: "",
            noteLastTrue: "",
            explanation: [],
            explanationTrues: [],
            nodeLast: 0,
            nodeLastTrue: 0,

            nodesEvaluated: [
                {
                    conclusion: "",
                    cornerstonecaseid: null,
                    evaluationResult: true,
                    id: 0,
                    nextnodefalse: 0,
                    nextnodetrue: 0,
                    rule: "",
                },
            ],

            referenceCase: [],
            req_data: {
                age: "",
                weight: "",
            },
        });

    const [loggingEvaluationResult, setLoggingEvaluationResult] =
        useState({
            caseLastTrue: [{}],
            conclusionLastTrue: "",
            noteLastTrue: "",
            explanation: [],
            explanationTrues: [],
            nodeLast: 0,
            nodeLastTrue: 0,

            nodesEvaluated: [
                {
                    conclusion: "",
                    cornerstonecaseid: null,
                    evaluationResult: true,
                    id: 0,
                    nextnodefalse: 0,
                    nextnodetrue: 0,
                    rule: "",
                },
            ],

            referenceCase: [],
            req_data: {
                age: "",
                weight: "",
            },
        });

    const [transparencyEvaluationResult, setTransparencyEvaluationResult] =
        useState({
            caseLastTrue: [{}],
            conclusionLastTrue: "",
            noteLastTrue: "",
            explanation: [],
            explanationTrues: [],
            nodeLast: 0,
            nodeLastTrue: 0,

            nodesEvaluated: [
                {
                    conclusion: "",
                    cornerstonecaseid: null,
                    evaluationResult: true,
                    id: 0,
                    nextnodefalse: 0,
                    nextnodetrue: 0,
                    rule: "",
                },
            ],

            referenceCase: [],
            req_data: {
                age: "",
                weight: "",
            },
        });


    const [oversightEvaluationResult, setOversightEvaluationResult] =
        useState({
            caseLastTrue: [{}],
            conclusionLastTrue: "",
            noteLastTrue: "",
            explanation: [],
            explanationTrues: [],
            nodeLast: 0,
            nodeLastTrue: 0,

            nodesEvaluated: [
                {
                    conclusion: "",
                    cornerstonecaseid: null,
                    evaluationResult: true,
                    id: 0,
                    nextnodefalse: 0,
                    nextnodetrue: 0,
                    rule: "",
                },
            ],

            referenceCase: [],
            req_data: {
                age: "",
                weight: "",
            },
        });




    const [secureEvaluationResult, setSecureEvaluationResult] =
        useState({
            caseLastTrue: [{}],
            conclusionLastTrue: "",
            noteLastTrue: "",
            explanation: [],
            explanationTrues: [],
            nodeLast: 0,
            nodeLastTrue: 0,

            nodesEvaluated: [
                {
                    conclusion: "",
                    cornerstonecaseid: null,
                    evaluationResult: true,
                    id: 0,
                    nextnodefalse: 0,
                    nextnodetrue: 0,
                    rule: "",
                },
            ],

            referenceCase: [],
            req_data: {
                age: "",
                weight: "",
            },
        });



    const [vendorEvaluationResult, setVendorEvaluationResult] =
        useState({
            caseLastTrue: [{}],
            conclusionLastTrue: "",
            noteLastTrue: "",
            explanation: [],
            explanationTrues: [],
            nodeLast: 0,
            nodeLastTrue: 0,

            nodesEvaluated: [
                {
                    conclusion: "",
                    cornerstonecaseid: null,
                    evaluationResult: true,
                    id: 0,
                    nextnodefalse: 0,
                    nextnodetrue: 0,
                    rule: "",
                },
            ],

            referenceCase: [],
            req_data: {
                age: "",
                weight: "",
            },
        });



    const [ongoingEvaluationResult, setOngoingEvaluationResult] =
        useState({
            caseLastTrue: [{}],
            conclusionLastTrue: "",
            noteLastTrue: "",
            explanation: [],
            explanationTrues: [],
            nodeLast: 0,
            nodeLastTrue: 0,

            nodesEvaluated: [
                {
                    conclusion: "",
                    cornerstonecaseid: null,
                    evaluationResult: true,
                    id: 0,
                    nextnodefalse: 0,
                    nextnodetrue: 0,
                    rule: "",
                },
            ],

            referenceCase: [],
            req_data: {
                age: "",
                weight: "",
            },
        });







    return (
        <AlkaContext.Provider
            value={{
                aggConclusion, setAggConclusion,
                knowledgeBase, setKnowledgeBase,
                evaluationResult, setEvaluationResult,
                attributesOpenApp, setAttributesOpenApp,

                risksEvaluationResult, setRisksEvaluationResult,
                dataEvaluationResult, setDataEvaluationResult,
                dataForDevEvaluationResult, setDataForDevEvaluationResult,
                techDocEvaluationResult, setTechDocEvaluationResult,
                loggingEvaluationResult, setLoggingEvaluationResult,
                transparencyEvaluationResult, setTransparencyEvaluationResult,
                oversightEvaluationResult, setOversightEvaluationResult,
                secureEvaluationResult, setSecureEvaluationResult,
                vendorEvaluationResult, setVendorEvaluationResult,
                ongoingEvaluationResult, setOngoingEvaluationResult,
                groupsEvaluationResult, setGroupsEvaluationResult,
            }}
        >
            {children}
        </AlkaContext.Provider>
    );
};

export default AlkaContextProvider;
