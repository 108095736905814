import React from 'react';
import '../test.css'; // Create this CSS file

// Images
import bubbleComprehensive from '../images/bubble-comprehensive.png'
import bubblePurchase from '../images/bubble-purchase.png'
import bubbleSomething from '../images/bubble-something.png'
import bubbleTrademark from '../images/bubble-trademark.png'

import { useNavigate } from "react-router-dom";

function LegalTypesMobile() {

    let navigate = useNavigate();

    function gotoTrademark(e) {
        navigate("/TrademarkMobile");
    }

    return (
        <div className='container-basic'>
            <div className='top-bar top-bar-basic'></div>
            <div className='boxlegal-1'>
                <h2 className='app-title'>legal</h2>
            </div>

            <div className='ball-container-trademark mobile-only' onClick={gotoTrademark}>
                <img className="ball-trademark" src={bubbleTrademark} />
                <h5 className='img-caption-trademark'>varemærkeret</h5>
            </div>

            <div className='ball-container-purchase mobile-only'>
                <img className="ball-purchase" src={bubblePurchase} />
                <h5 className='img-caption-purchase'>køb</h5>
            </div>

            <div className='ball-container-something mobile-only'>
                <img className="ball-something" src={bubbleSomething} />
                <h5 className='img-caption-something'>retsområde</h5>
            </div>

            <div className='ball-container-comprehensive mobile-only'>
                <img className="ball-comprehensive" src={bubbleComprehensive} />
                <h5 className='img-caption-comprehensive'>forsikring</h5>
            </div>

        </div>
    )
};

export default LegalTypesMobile;
