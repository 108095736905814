import React, { useEffect, useContext } from "react";
import Messages from "../Messages.js";

import { Button, Form, InputGroup, ButtonGroup } from "react-bootstrap";

import { domainContext } from "../../contexts/DomainContext";
import { domainsContext } from "../../contexts/DomainsContext";
import { evaluationContext } from "../../contexts/EvaluationContext";
import { displayContext } from "../../../common/DisplayContext.js";
import { errorContext } from "../../contexts/ErrorContext";
import { userCaseContext } from "../../contexts/UserCaseContext";
// import { TokenContext } from "../../contexts/TokenContext.js";
import useAuth from "../../hooks/useAuth.js";

// import { Config } from "../../../config.js";

///////////////////////////////////////////////////////////////////////////////////
//
///////////////////////////////////////////////////////////////////////////////////

function ButtonSaveDomain() {
    // const { token } = useContext(TokenContext);
    const { token } = useAuth();

    const { setDisplayMessage, t } = useContext(displayContext);

    const { setErrors } = useContext(errorContext);

    const { newDomain } = useContext(domainContext);
    const { realoadDomains, setReloadDomains } = useContext(domainsContext);

    ///////////////////////////////////////////////////////////////////////////////////
    //
    ///////////////////////////////////////////////////////////////////////////////////

    function isAlphaNumeric(str) {
        var code, i, len;

        for (i = 0, len = str.length; i < len; i++) {
            code = str.charCodeAt(i);
            if (
                !(code > 47 && code < 58) && // numeric (0-9)
                !(code > 64 && code < 91) && // upper alpha (A-Z)
                !(code > 96 && code < 123)
            ) {
                // lower alpha (a-z)
                return false;
            }
        }
        return true;
    }

    const findFormErrors = () => {
        const newErrors = {};

        if (typeof newDomain == "undefined" || !newDomain || newDomain === "")
            newErrors.newDomain = "NewDomain må ikke være tom";
        else if (!isAlphaNumeric(newDomain))
            newErrors.newDomain = "NewDomain skal være alpha-numerisk";

        return newErrors;
    };

    function handleSubmitSaveDomain(e) {
        e.preventDefault();

        // Get our new errors
        const newErrors = findFormErrors();
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors);
        } else {
            // No errors! Put any logic here for the form submission!

            handleSaveDomain();
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////
    //
    ///////////////////////////////////////////////////////////////////////////////////

    function handleSaveDomain() {
        setDisplayMessage({
            messageCode: null,
            messageText: "Processing...",
            // messageText: t('processing'),
            displayCode: 0,
        });

        var obj = {};

        obj.domain = newDomain;

        var endpoint = process.env.REACT_APP_FLASK_API + "createDomain";
        fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(obj),
        })
            .then((res) => res.json())
            .then((responseJson) => {
                // Display a message for the result of the transaction
                setDisplayMessage(Messages(responseJson["message"]));

                // This is a trigger the backend function getDomainNames in DomainsContext to reload domains from the backend
                setReloadDomains(realoadDomains + 1);
            });
    }

    // Save domain button
    return (
        <Button
            className="mentor-button1"
            onClick={handleSubmitSaveDomain}
        // style={{ height: "30px", marginRight: "10px" }}
        >
            {t("save")}
        </Button>
    );
}

export default ButtonSaveDomain;
