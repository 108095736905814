import React, { useState, useContext } from 'react';
import '../test.css'; // Create this CSS file

import trademarkStart from '../images/trademark-start.png'
import { swiperContext } from '../contexts/SwiperContext';

import { useNavigate } from "react-router-dom";

function TrademarkMobile() {

    let navigate = useNavigate();

    const {
        setOptionDescription,
    } = useContext(swiperContext);

    const [infoButton, setInfoButton] = useState(false);

    function gotoTrademarkSwiper1(e) {
        setOptionDescription('Bruger du dit kendetegn som dit brand?')
        navigate("/TrademarkSwiper1");
    }

    function toggleInfoButton() {
        setInfoButton(!infoButton)
    }

    return (
        <div className='container'>
            <div className='top-bar'></div>

            <div className='boxlegal-1'>
                <h2 className='app-title'>legal</h2>
            </div>

            <div className='boxlegal-flex' onClick={gotoTrademarkSwiper1}>
                <img className="swipe-ball" src={trademarkStart} />
                {infoButton ?
                    <p className='info-text'>Vi kan teste, om du
                        har stiftet en varemærkeret
                        til dit kendetegn ved at bruge det
                        i Danmark. Det gør vi ved at undersøge, om du opfylder betingelserne
                        i varemærkelovens § 3, stk. 1, nr. 3
                        som fortolket i retspraksis.
                    </p > :
                    <div className='img-comprehensive-caption'>start</div>}
                {/* <div className='swiperSlideTitle1'>start</div>} */}
            </div>

            <div className='boxlegal-3 page-description'>
                Stiftelse af varemærkeret ved brug
            </div>

            <div className='boxlegal-4'></div>

            <div className='info-button-overlay' onClick={toggleInfoButton}></div>
        </div>
    )
};

export default TrademarkMobile;
