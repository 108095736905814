import React, { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import "../node_modules/bootstrap/dist/css/bootstrap.min.css";

import DomainContextProvider from "./admin/contexts/DomainContext";
import ErrorContextProvider from "./admin/contexts/ErrorContext";
import UserContextProvider from "./admin/contexts/UserContext";
import AuthProvider from "./admin/contexts/AuthContext";

import App from "./App";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    // <StrictMode>
        <BrowserRouter>
            <DomainContextProvider>
                <ErrorContextProvider>
                    <UserContextProvider>
                        <AuthProvider>
                            <React.Suspense fallback="loading lige om lidt">
                                <App />
                            </React.Suspense>
                        </AuthProvider>
                    </UserContextProvider>
                </ErrorContextProvider>
            </DomainContextProvider>
        </BrowserRouter>
    // </StrictMode>
);
