import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Form,
    Row,
    Col,
    InputGroup,
    Button,
    ButtonGroup,
} from "react-bootstrap";

import Modal from "react-modal";

// import { Config } from "../../config";

// Flags: https://www.npmjs.com/package/flag-icons
import "/node_modules/flag-icons/css/flag-icons.min.css";

import { BsPlayFill, BsPauseFill, BsStopFill } from "react-icons/bs";

import { domainContext } from "../../admin/contexts/DomainContext";
import { userCaseContext } from "../../admin/contexts/UserCaseContext";
import { displayContext } from "../../common/DisplayContext";

import useTMEvaluateTm1 from "../hooks/useTMEvaluateTm1";

import Header from "../components/Header";

function TM1() {
    const { setDisplayMessage, previousPath, setUseMode, t2 } =
        useContext(displayContext);

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        setDisplayMessage("");
    }, [location.key]);

    const {
        currentDomain,
        setCurrentDomain,
        currentKnowledgeBase,
        setCurrentKnowledgeBase,
    } = useContext(domainContext);

    const { userCase, setUserCase, setCaseStatus } =
        useContext(userCaseContext);

    const [{ handleEvaluate }] = useTMEvaluateTm1();

    if (userCase == []) {
        setCurrentDomain("varemaerke");
    }

    if (previousPath == "/learn") {
        setUseMode("read");
    }

    const [errors, setErrors] = useState("");
    const [tMName, setTMName] = useState("");
    const [tMPurpose, setTMPurpose] = useState("");

    function handleSubmit(e) {
        navigate("/TM1UsePage");
    }

    console.log("TM1 - userCase: ", userCase);

    return (
        <>
            <Header />
            <div className="tm1-intro">
                <h2 className="tm1-intro-question">
                    {t2("introQuestion1", "varemaerke")}
                </h2>
                <div className="tm1-intro-boxes">
                    <div className="tm1-intro-textbox">
                        {t2("introText1", "varemaerke")}
                    </div>
                    <div className="tm1-intro-textbox">
                        {t2("introText2", "varemaerke")}
                    </div>
                </div>
                <div
                    className="bubble-item4 bubble-item-disabled"
                    onClick={handleSubmit}
                >
                    <BsPlayFill size={40} />
                </div>
            </div>
        </>
    );
}
export default TM1;
