import React, { useContext } from "react";

import { Button } from "react-bootstrap";

import { CurrentCategoryContext } from "../../contexts/CurrentCategoryContext";
import { displayContext } from "../../../common/DisplayContext.js";
import { domainContext } from "../../contexts/DomainContext";
import { errorContext } from "../../contexts/ErrorContext";
import { evaluationContext } from "../../contexts/EvaluationContext";
import { userCaseContext } from "../../contexts/UserCaseContext";
import { rejectedCaseContext } from "../../contexts/CaseContext";
import { attributesContext } from "../../contexts/AttributesContext";

import styles from '../../styles.module.css'

import useAuth from "../../hooks/useAuth";
import useNewNodeFields from "../../hooks/useNewNodeFields.js";

import Messages from "../Messages.js";
// import { Config } from "../../../config";

///////////////////////////////////////////////////////////////////////////////////
//
///////////////////////////////////////////////////////////////////////////////////

function ButtonSaveNewRule() {
    const { userCase } = useContext(userCaseContext);

    const {
        buttonSaveNewRuleDisabled,
        currentLanguage,
        newConclusion,
        newExplanation,
        newLetter,
        newLetterRuleFalse,
        newLetterRuleTrue,
        newNote,
        newConclusionGroup,
        newRule,
        setButtonSaveNewRuleDisabled,
        setDisplayMessage,
        setTextAreaNewNoteDisabled,
        setTextAreaNewRuleDisabled,
        setUseMode,
        setTextAreaNewConclusionDisabled,
        setTextAreaNewExplanationDisabled,
        t,
        useMode,
    } = useContext(displayContext);

    const { currentKnowledgeBase, currentDomain } = useContext(domainContext);

    const { currentCaseId, evaluationResult } = useContext(evaluationContext);

    const { setErrors } = useContext(errorContext);

    const { currentCategory } = useContext(CurrentCategoryContext);

    const {
        reloadCaseList,
        setReloadCaseList,
        reloadRejectedCaseList,
        setReloadRejectedCaseList,
    } = useContext(rejectedCaseContext);

    const { currentAttributes } = useContext(attributesContext);

    const { token } = useAuth();
    const [{ enableNewNodeFields, disableNewNodeFields }] = useNewNodeFields();

    // Translate rules to using 'yes' and 'no' instead of other languages words for yes and no
    function translate(str, replaceWhat, replaceTo) {
        var re = new RegExp(replaceWhat, "g");
        return str.replace(re, replaceTo);
    }

    const findFormErrors = () => {
        const newErrors = {};

        if (newConclusion === "") {
            newErrors.newConclusion = "Ny konklusion skal udfyldes";
        }

        // if (newLetterRuleTrue === "") {
        //     newErrors.newLetterRuleTrue =
        //         "Ny positiv-regel til brev skal udfyldes";
        // }

        // if (newLetterRuleFalse === "") {
        //     newErrors.newLetterRuleFalse =
        //         "Ny negativ-regel til brev skal udfyldes";
        // }

        if (newRule === "") {
            newErrors.newRule = "Ny regel skal udfyldes";
        }

        return newErrors;
    };

    function submitSaveNewRuleHandler(e) {
        e.preventDefault();

        // Get our new errors
        const newErrors = findFormErrors();
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors);
        } else {
            // No errors! Put any logic here for the form submission!
            handleSaveNewRule(e);
        }
    }

    //   Error handling end

    function handleSaveNewRule(e) {
        e.preventDefault();

        // // Translate rules to using 'yes' and 'no' instead of other languages words for yes and no
        // function translate(str, replaceWhat, replaceTo) {
        //     var re = new RegExp(replaceWhat, "g");
        //     return str.replace(re, replaceTo);
        // }
        let translatedNewRule = translate(
            newRule,
            " " + t("no") + " ",
            " " + "no" + " "
        );
        translatedNewRule = translate(
            newRule,
            " " + t("no"),
            " " + "no"
        );
        translatedNewRule = translate(
            translatedNewRule,
            " " + t("yes") + " ",
            " " + "yes" + " "
        );
        translatedNewRule = translate(
            translatedNewRule,
            " " + t("yes"),
            " " + "yes"
        );
        translatedNewRule = translate(
            translatedNewRule,
            " " + t("and") + " ",
            " " + "and" + " "
        );
        translatedNewRule = translate(
            translatedNewRule,
            " " + t("or") + " ",
            " " + "or" + " "
        );

        var obj = {};

        obj.mode = "corner";
        obj.nodeLast = evaluationResult.nodeLast;
        obj.nodeLastTrue = evaluationResult.nodeLastTrue;
        obj.newConclusion = newConclusion;
        obj.newExplanation = newExplanation;
        obj.newLetter = newLetter;
        obj.newLetterRuleFalse = newLetterRuleFalse;
        obj.newLetterRuleTrue = newLetterRuleTrue;
        obj.newRule = translatedNewRule;
        obj.newNote = newNote;
        obj.newConclusionGroup = newConclusionGroup;
        obj.knowledgeBase = currentKnowledgeBase;
        // obj.username = username;
        obj.domain = currentDomain;
        obj.caseData = userCase;
        obj.category = currentCategory;
        obj.busCaseId = (typeof currentCaseId !== 'undefined') ? currentCaseId : '';
        obj.comment = "";
        obj.status = "accepted";
        obj.language = currentLanguage;

        var endpoint = process.env.REACT_APP_FLASK_API + "create_node";
        fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                // "Content-Type": "text/plain"
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(obj),
        })
            .then((res) => res.json())
            .then((responseJson) => {
                setDisplayMessage(Messages(responseJson["message"]));
                setReloadCaseList(!reloadCaseList);
                setReloadRejectedCaseList(!reloadRejectedCaseList);
                // The new rule was saved successfully in the database
                if (responseJson["message"]["messageCode"] === 0) {
                    // Set the 'New Rule' section
                    // setTextAreaNewConclusionDisabled(true);
                    // setTextAreaNewExplanationDisabled(true);
                    // setTextAreaNewRuleDisabled(true);
                    // setTextAreaNewNoteDisabled(true);
                    // setButtonSaveNewRuleDisabled(true);

                    disableNewNodeFields();
                }

                // The new rule has bad syntax (503) or semanitc (504)
                else if (
                    responseJson["status"] === "503" ||
                    responseJson["status"] === "504"
                ) {
                    // Set the 'New Rule' section
                    // setTextAreaNewConclusionDisabled(false);
                    // setTextAreaNewRuleDisabled(false);
                    // setTextAreaNewNoteDisabled(false);

                    enableNewNodeFields();
                }
            });

        if (useMode == "learn") {
            setUseMode("learn");
            setButtonSaveNewRuleDisabled(true);
        } else {
            setUseMode("read");
        }
    }

    return (
        <>
            <Button
                className={styles.standardButton}
                variant="success"
                size="sm"
                disabled={buttonSaveNewRuleDisabled}
                onClick={submitSaveNewRuleHandler}
            >
                {t("save")}
            </Button>
        </>
    );
}

export default ButtonSaveNewRule;
