import React, { useState } from 'react';
import '../test.css'; // Create this CSS file

import logo from '../images/logo.svg';
import comprehensiveStart from '../images/comprehensive-start.png'

import { useNavigate } from "react-router-dom";

function Comprehensive() {

    let navigate = useNavigate();

    const [infoButton, setInfoButton] = useState(false);

    function gotoKasko(e) {
        navigate("/ComprehensiveSwiper");
    }

    function toggleInfoButton() {
        setInfoButton(!infoButton)
    }

    return (
        <div className='container'>
            <div className='top-bar'></div>

            <div className='box-1'>
                <img src={logo} className='logo' alt="logo" />
            </div>

            <div className='box-flex' onClick={gotoKasko}>
                <img className="swipe-ball" src={comprehensiveStart} />
                {infoButton ?
                    <p className='info-text'>Vi kan teste, om
                        kaskoskaden på din bil
                        er dækket. Det gør vi ved at
                        undersøge, om du opfylder
                        forsikringsbetingelserne som
                        fortolket i rets- og ankenævnspraksis.
                    </p > :
                    <h5 className='img-comprehensive-caption'>start</h5>}
            </div>

            <div className='box-3 page-description'>
                Anmeldelse af kaskoskade
            </div>

            <div className='info-button-overlay' onClick={toggleInfoButton}></div>
        </div>
    )
};

export default Comprehensive;
