import React, { useContext } from "react";

import { evaluationContext } from "../../contexts/EvaluationContext";
import { displayContext } from "../../../common/DisplayContext";

import styles from '../../styles.module.css';

import ResultExplanationHeading from "./ResultExplanationHeading";

///////////////////////////////////////////////////////////////////////////////////
//
//  ResultExplanation: show the explanation after evaluation
//
///////////////////////////////////////////////////////////////////////////////////

function ResultExplanation1() {
    const { explanationLastTrue } = useContext(evaluationContext);

    const { t } = useContext(displayContext);

    return (
        <>
            <div className={styles.conclusion}
            >            <ResultExplanationHeading />
                {explanationLastTrue}
            </div>
        </>
    );
}

export default ResultExplanation1;
