import React from 'react';

import VersionContextProvider from '../contexts/Context';

import styles from '../styles.module.css';

import Topbar from './Topbar';
import Main from './Main';
import Bottombar from './Bottombar';

function Karnov() {
    return (
        <VersionContextProvider>
            <div
                className={[styles.desktopContainer, styles.desktopFont].join(
                    ' ',
                )}
            >
                <Topbar />

                <Main />

                <Bottombar />
            </div>
        </VersionContextProvider>
    );
}

export default Karnov;
