import React, { useRef, useState } from 'react';
import "../swipeballDesign.css"

import { Button } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import '../swipeballDesign.css';

// import required modules
import { Pagination } from 'swiper/modules';

// Import pictures
// import background from './images/background.png';
import logo from '../images/logo.svg';
import startButtonDepressed from '../images/Ball-Final.svg';

function Top() {


    const [infoButton, setInfoButton] = useState(false);

    let navigate = useNavigate();



    function toggleInfoButton() {
        setInfoButton(!infoButton)
    }


    function testClick(e) {
        console.log('testClick')
    }



    function gotoKasko1(e) {
        // // Find errors
        // const newErrors = findFormErrors();

        // // Conditional logic:
        // if (Object.keys(newErrors).length > 0) {
        //     // We got errors!
        //     setErrors(newErrors);
        // } else {
        //     // No errors! Put any logic here for the form submission!
        //     // setIsEvaluating(true);
        //     handleEvaluate(e, "geography");

        //     navigate("/TM1IntensityPage");
        // }

        console.log('Image clicked');

        navigate("/Kasko1");

    }


    return (

        <div className='container'>

            <div className='top-bar'></div>

            <div className='box-1'>
                <img src={logo} className='logo' alt="logo" />
            </div>

            <div className='box-flex' onClick={gotoKasko1}>
                <img src={startButtonDepressed} className='start-button' alt="logo" />
                {infoButton ?
                    <p className='info-text' >Vi kan teste, om
                        kaskoskaden på din bil
                        er dækket. Det gør vi ved at
                        undersøge, om du opfylder
                        forsikringsbetingelserne som
                        fortolket i rets- og anke-
                        nævnspraksis.
                    </p > : <div className='box-caption' >start</div>
                }
            </div>





            <div className='box-3 description-text'>Anmeldelse af kaskoskade</div>

            {/* <div className='info-button-overlay'></div> */}

            <Button className='info-button-overlay' onClick={toggleInfoButton}></Button>


            {/* 
      <Button
        // className="mentor-button1"
        onClick={toggleInfoButton}
      >
        info
      </Button> */}

        </div >
    );

}
export default Top;