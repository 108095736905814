import React from 'react';
import '../test.css'; // Create this CSS file

import logo from '../images/logo.svg';

import accident from '../images/accident.png';
import contents from '../images/contents.png';
import travel from '../images/travel.png';
import comprehensive from '../images/comprehensive.png';

import { useNavigate } from "react-router-dom";

function InsuranceTypes() {

    let navigate = useNavigate();

    function gotoComprehensive(e) {
        navigate("/Comprehensive");
    }

    return (
        <div className='container1'>
            <div className='box-1'>
                <img src={logo} className='logo' alt="logo" />
            </div>


            <div className='ball-container-accident'>
                <img className="ball-accident" src={accident} />
                <h5 className='img-caption-accident'>ulykke</h5>
            </div>


            <div className='ball-container-contents'>
                <img className="ball-contents" src={contents} />
                <h5 className='img-caption-contents'>indbo</h5>
            </div>

            <div className='ball-container-travel'>
                <img className="ball-travel" src={travel} />
                <h5 className='img-caption-travel'>rejse</h5>
            </div>

            <div className='ball-container-comprehensive' onClick={gotoComprehensive}>
                <img className="ball-comprehensive" src={comprehensive} />
                <h5 className='img-caption-comprehensive'>kasko</h5>
            </div>


            {/* <div className="ball ball2">
                indbo<br></br>
                ANMELDELSE
            </div>             */}


        </div>
    )
};

export default InsuranceTypes;
