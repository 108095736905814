import React, { useContext } from "react";
import { Form, Row } from "react-bootstrap";

// NPM page: https://www.npmjs.com/package/react-linkify
// See examples: http://tasti.github.io/react-linkify/
// import Linkify from "react-linkify";

// General info: https://linkify.js.org/docs/
// React specific info: https://linkify.js.org/docs/linkify-react.html
import Linkify from "linkify-react";

import { evaluationContext } from "../../contexts/EvaluationContext";
import { displayContext } from "../../../common/DisplayContext";
import { errorContext } from "../../contexts/ErrorContext";

import styles from '../../styles.module.css';

///////////////////////////////////////////////////////////////////////////////////
//
//  ResultConclusion: show the conclusion after evaluation
//
///////////////////////////////////////////////////////////////////////////////////

function ConclusionGroup() {
    const { conclusionLastTrue, explanationLastTrue, noteLastTrue, conclusionGroupLastTrue } =
        useContext(evaluationContext);

    const {
        newConclusion,
        newExplanation,
        newLetterRuleFalse,
        newLetterRuleTrue,
        setButtonSaveNewRuleDisabled,
        setNewConclusion,
        setNewExplanation,
        setNewLetterRuleFalse,
        setNewLetterRuleTrue,
        t,
        useMode,
        newNote,
        setNewNote,
        textAreaNewNoteDisabled,
        newConclusionGroup,
        setNewConclusionGroup,
    } = useContext(displayContext);

    const { errors } = useContext(errorContext);

    function handleNoteChange(e) {
        setButtonSaveNewRuleDisabled(false);
        setNewNote(e.target.value);
    }

    function handleConclusionGroupChange(e) {
        setButtonSaveNewRuleDisabled(false);
        setNewConclusionGroup(e.target.value);
    }

    const options = {
        /* … */
    };

    return (
        <div style={{ width: "100%" }}>
            {(useMode === "read" ||
                (useMode === "learn" && textAreaNewNoteDisabled)) && (
                   
                   
                   
                //    <>
                //         <div className={styles.conclusionGroup1}>
                //             <p className={styles.labelText}>{t("note")}</p>
                //             <Linkify as="p" options={options}>
                //                 {noteLastTrue}
                //             </Linkify>
                //         </div>
                //     </>
               
                <>
                    <div className={styles.conclusionGroup1}>
                        <p className={styles.labelText}>{t("conclusionGroup")}</p>
                        <p>
                            {conclusionGroupLastTrue}
                        </p>
                    </div>

                </>
               
               
               )}







            {(useMode === "write" ||
                (useMode == "learn" && !textAreaNewNoteDisabled)) && (
                    <>
                        <div className={styles.conclusionGroup2}>
                            <p className={styles.labelText}>{t("conclusionGroup")}</p>
                            {/* <textarea className={styles.conclusionGroupTextArea}
                                name='newConclusionGroup'
                                type="text"
                                value={newConclusionGroup}
                                onChange={(e) => handleConclusionGroupChange(e)}
                                isInvalid={!!errors.newConclusionGroup}
                            /> */}
                            <input
                                name='newConclusionGroup'
                                type="text"
                                value={newConclusionGroup}
                                onChange={(e) => handleConclusionGroupChange(e)}
                                isInvalid={!!errors.newConclusionGroup}
                            />
                        </div>

                    </>
                )}
        </div>
    );
}

export default ConclusionGroup;
