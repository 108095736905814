import React, { useState, useContext, useEffect } from 'react'

// import SwiperContextProvider from '../contexts/SwiperContext';

import Trademark from './TrademarkDesktop'

function TrademarkDesktopPage() {
    // let navigate = useNavigate();

    return (
        // <SwiperContextProvider>
        <Trademark />
        // </SwiperContextProvider>
    )
}

export default TrademarkDesktopPage
