import React, { createContext, useState, useEffect, useContext } from "react";
// import { FLASK_API } from "../constants/apiConstants.js";
import { useTranslation } from "react-i18next";
import "../../translations/i18n";
import { useJwt } from "react-jwt";

import { Redirect, BrowserRouter as Router } from "react-router-dom";

import { attributesContext } from "./AttributesContext";
import { domainContext } from "./DomainContext";
import useAuth from "../hooks/useAuth";
// import { Config } from "../../config";

export const categoriesContext = createContext();

const CategoriesContextProvider = (props) => {
    // This does not seem corret; currentDomain ought to come from domainContext
    const { currentDomain } = useContext(attributesContext);

    const { currentKnowledgeBase } = useContext(domainContext);

    const { token, tokenHasExpired, setTokenHasExpired } = useAuth();

    const [categories, setCategories] = useState([{}]);

    const { t } = useTranslation();

    const { decodedToken, isExpired } = useJwt(token);

    useEffect(() => {
        var obj = {};
        obj.knowledgeBase = currentKnowledgeBase;
        obj.domain = currentDomain;

        if (token != null) {
            var endpoint = process.env.REACT_APP_FLASK_API + "getCategories";

            fetch(endpoint, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify(obj),
            })
                .then((res) => res.json())
                .then((responseJson) => {
                    responseJson.unshift({ name: t("allCategories") }); // Add item to the beginning of the array
                    setCategories(responseJson);
                });
        }
    }, [currentKnowledgeBase, currentDomain]);

    return (
        <categoriesContext.Provider
            value={{
                categories,
                setCategories,
            }}
        >
            {props.children}
        </categoriesContext.Provider>
    );
};

export default CategoriesContextProvider;
