import React, { createContext, useState, useEffect, useContext } from "react";

export const topContext = createContext();

const TopContextProvider = ({ children }) => {

    const [stoneDamage, setStoneDamage] = useState('');

    return (
        <topContext.Provider
            value={{
                stoneDamage,
                setStoneDamage,
            }}
        >
            {children}
        </topContext.Provider>
    );
};

export default TopContextProvider;
