import React, { useContext } from "react";

import { displayContext } from "../../../common/DisplayContext";
import { errorContext } from "../../contexts/ErrorContext";

import styles from '../../styles.module.css';

import ResultExplanationHeading from "./ResultExplanationHeading";

///////////////////////////////////////////////////////////////////////////////////
//
//  ResultExplanationNew: input textbox for creating a new explanation.
//  newExplanation is set to explanationLastTrue, if a case was evaluated before this
//  component is shown, for example if we came from the rejected cases list.
//
///////////////////////////////////////////////////////////////////////////////////

function ResultExplanationNew() {
    const {
        newExplanation,
        setButtonSaveNewRuleDisabled,
        setNewExplanation,
        t,
    } = useContext(displayContext);

    const { errors } = useContext(errorContext);

    function handleExplanationChange(e) {
        setButtonSaveNewRuleDisabled(false);
        setNewExplanation(e.target.value);
    }

    return (
        <>

            <div className={styles.conclusion}>
                <ResultExplanationHeading />

                <textarea
                    name="newExplanation"
                    className={styles.newExplanation}
                    type="text"
                    value={newExplanation}
                    onChange={(e) => handleExplanationChange(e)}
                    isInvalid={!!errors.newExplanation}
                />
            </div>
            {errors.newExplanation && (
                <div
                    style={{
                        width: "400px",
                        padding: "10px",
                    }}
                >
                    <a
                        type="text"
                        placeholder="You must enter the explanation for this type of case"
                    />
                </div>
            )}
        </>
    );
}

export default ResultExplanationNew;
