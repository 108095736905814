///////////////////////////////////////////////////////////////////////////////////
//  Messages: based on the input message object this function creates determines
//  what is displayed to the end user. All messages should be managed by calling this function.
//
//  Input: message object
//
//  Output:
//    messageCode: a message code which can be used for reference
//    messageText: the message text shown to the end user
//    displayCode: determines how the message should be displayed, e.g. which highlighting color
//      0: error => red
//      1-99: success => green
//
///////////////////////////////////////////////////////////////////////////////////

import '../../translations/i18n';
import { displayContext } from '../../common/DisplayContext';
import { useContext } from 'react';

function Messages(message) {
    const { t2 } = useContext(displayContext);

    var attributeName = '';
    var domainName = '';

    switch (message.function) {
        case 'createKnowledgeBase':
            switch (message.messageCode) {
                //  Knowledge base successfully created
                case 0:
                    return {
                        messageCode: '0',
                        // messageText: "Knowledge base successfully created",
                        messageText: 'knowledgeBaseCreated',
                        displayCode: 1,
                    };

                // The user tried to create a knowledge base that already exists... probably not, wrong messageCode... need to test
                case '23505':
                    return {
                        messageCode: '23505',
                        messageText: 'KB already exists',
                        displayCode: '1',
                    };

                default:
                    return {
                        messageCode: '9999',
                        messageText:
                            'Unknown error code from function createKnowledgeBase: ' +
                            message.messageCode,
                        displayCode: '1',
                    };
            }

        ///////////////

        case 'createDomain':
            switch (message.messageCode) {
                //  Domain successfully created  (response when adding *row* to the 'domain' table)
                case 0:
                    return {
                        messageCode: '0',
                        // messageText: "Domain successfully created",
                        messageText: 'domainCreated',
                        displayCode: 1,
                    };

                // The user tried to create a domain that already exists (response when adding *row* to the 'domain' table)
                case '23505':
                    return {
                        messageCode: '23505',
                        messageText: 'Domain already exists',
                        displayCode: '1',
                    };

                default:
                    return {
                        messageCode: '9999',
                        messageText:
                            'Unknown error code from function createDomain: ' +
                            message.messageCode,
                        displayCode: '1',
                    };
            }

        ///////////////

        case 'resetKnowledgeBase':
            switch (message.messageCode) {
                //  Knowledge base successfully reset (response when resetting varioius tables)
                case 0:
                    return {
                        messageCode: '0',
                        // messageText: "Knowledge base successfully reset",
                        messageText: 'knowledgeBaseReset',
                        displayCode: 1,
                    };

                case 1:
                    return {
                        messageCode: '1',
                        messageText: 'knowledgeBaseNotReset',
                        displayCode: 0,
                    };

                default:
                    return {
                        messageCode: '9999',
                        messageText:
                            'Unknown error code from function resetKnowledgeBase: ' +
                            message.messageCode,
                        displayCode: '1',
                    };
            }

        ///////////////

        case 'loadCases':
            switch (message.messageCode) {
                //  Cases were successfully loaded
                case 0:
                    return {
                        messageCode: '0',
                        // messageText: "Cases were loaded",
                        messageText: 'casesLoaded',
                        displayCode: 1,
                    };

                default:
                    return {
                        messageCode: '9999',
                        messageText:
                            'Unknown error code from function loadCases: ' +
                            message.messageCode,
                        displayCode: '1',
                    };
            }

        ///////////////

        case 'getCaseForLearning':
            switch (message.messageCode) {
                case 1:
                    // No rules applies
                    return {
                        messageCode: '1',
                        messageText: 'noRulesForCase',
                        displayCode: 0,
                    };

                case 2:
                    // Conclusions mismatch
                    return {
                        messageCode: '1',
                        messageText: 'conclusionsMismatch',
                        displayCode: 0,
                    };

                case 3:
                    // Conclusions mismatch
                    return {
                        messageCode: '1',
                        messageText: 'noMoreLearningCases',
                        displayCode: 0,
                    };

                default:
                    return {
                        messageCode: '9999',
                        messageText:
                            'Unknown error code from function loadCases: ' +
                            message.messageCode,
                        displayCode: '1',
                    };
            }

        ///////////////

        case 'resetDomain':
            switch (message.messageCode) {
                //  Domain successfully reset (response when resetting varioius tables to the contents of that domains backup tables)
                case 0:
                    return {
                        messageCode: '0',
                        // messageText: "Domain successfully reset",
                        messageText: 'domainReset',
                        displayCode: 1,
                    };

                case 1:
                    return {
                        messageCode: '1',
                        messageText: 'domainNotReset',
                        displayCode: 0,
                    };

                default:
                    return {
                        messageCode: '9999',
                        messageText:
                            'Unknown error code from function resetDomain: ' +
                            message.messageCode,
                        displayCode: '1',
                    };
            }

        ///////////////

        case 'resetAttributes':
            switch (message.messageCode) {
                //  Attributes successfully reset
                case 0:
                    return {
                        messageCode: '0',
                        messageText: 'attributesReset',
                        displayCode: 1,
                    };

                case 1:
                    return {
                        messageCode: '1',
                        messageText: 'attributesNotReset',
                        displayCode: 0,
                    };

                default:
                    return {
                        messageCode: '9999',
                        messageText:
                            'Unknown error code from function resetAttributes: ' +
                            message.messageCode,
                        displayCode: '1',
                    };
            }

        ///////////////
        case 'createAttribute':
            switch (message.messageCode) {
                //  Attribute successfully created  (response when adding *row* to the 'attribute' table)
                case 0:
                    return {
                        messageCode: '0',
                        messageText: 'attributeAdded',
                        displayCode: 1,
                    };

                // The user tried to create an attribute that already exists (response when adding *row* to the 'attribute' table)
                case '23505':
                    attributeName = message.messageText.substring(
                        message.messageText.indexOf(')=(') + 3,
                        message.messageText.indexOf(
                            ', ',
                            message.messageText.indexOf(')=(') + 3,
                        ),
                    );

                    domainName = message.messageText.substring(
                        message.messageText.indexOf(
                            ', ',
                            message.messageText.indexOf(')=(') + 5,
                        ) + 2,
                        message.messageText.indexOf(
                            ') ',
                            message.messageText.indexOf(')=(') + 5,
                        ),
                    );

                    return {
                        messageCode: '23505',
                        // messageText:
                        // "An attribute with the name '" +
                        // attributeName +
                        //   "' already exists in the '" +
                        //   domainName +
                        //   "' domain",
                        messageText: 'attributeExists',
                        displayCode: 0,
                    };

                default:
                    return {
                        messageCode: '9999',
                        messageText:
                            'Unknown error code from function createAttribute: ' +
                            message.messageCode,
                        displayCode: '1',
                    };
            }

        ///////////////

        case 'createCategory':
            switch (message.messageCode) {
                //  Category successfully created  (response when adding *row* to the 'category' table)
                case 0:
                    return {
                        messageCode: '0',
                        messageText: 'Category successfully added',
                        displayCode: 1,
                    };

                // The user tried to create a category that already exists (response when adding *row* to the 'category' table)
                case '23505':
                    attributeName = message.messageText.substring(
                        message.messageText.indexOf(')=(') + 3,
                        message.messageText.indexOf(
                            ', ',
                            message.messageText.indexOf(')=(') + 3,
                        ),
                    );

                    domainName = message.messageText.substring(
                        message.messageText.indexOf(
                            ', ',
                            message.messageText.indexOf(')=(') + 5,
                        ) + 2,
                        message.messageText.indexOf(
                            ') ',
                            message.messageText.indexOf(')=(') + 5,
                        ),
                    );

                    return {
                        messageCode: '23505',
                        messageText:
                            "A category with the name '" +
                            attributeName +
                            "' already exists in the '" +
                            domainName +
                            "' domain",
                        displayCode: 0,
                    };

                default:
                    return {
                        messageCode: '9999',
                        messageText:
                            'Unknown error code from function createCategory: ' +
                            message.messageCode,
                        displayCode: 0,
                    };
            }

        ///////////////

        case 'evaluate':
            switch (message.messageCode) {
                //  Attribute successfully created  (response when adding *row* to the 'attribute' table)
                case 0:
                    return {
                        messageCode: '0',
                        messageText: 'Evaluation succeeded',
                        displayCode: '0',
                    };

                case 1:
                    return {
                        messageCode: '0',
                        // messageText: "There are no rules in the domain",
                        // messageText: "RADAR har endnu ingen regler for ydelsen",
                        messageText: 'noRules',
                        // messageText: t2("noRules", 'sysadmin'),
                        displayCode: 0,
                    };

                case 2:
                    return {
                        messageCode: '0',
                        // messageText: "No rules apply to the case",
                        // messageText: "RADAR har endnu ingen regler for sagen",
                        messageText: 'noRulesForCase',
                        // messageText: t2("noRulesForCase", 'sysadmin'),
                        displayCode: 0,
                    };

                default:
                    return {
                        messageCode: '9999',
                        messageText:
                            'Unknown error code from function evaluate: ' +
                            message.messageCode,
                        displayCode: '1',
                    };
            }

        ///////////////

        case 'accept':
            switch (message.messageCode) {
                //  Case sucessfully stored to database as accepted
                case 0:
                    return {
                        // Successfully accepted
                        messageCode: '0',
                        messageText: 'accepted',
                        displayCode: 1,
                    };

                case 1:
                    return {
                        // Successfully accepted - mode: accept
                        messageCode: '0',
                        messageText: 'caseAccepted',
                        displayCode: 1,
                    };

                case 2:
                    return {
                        // Successfully rejected - mode: reject
                        messageCode: '0',
                        messageText: 'caseRejected',
                        displayCode: 1,
                    };

                default:
                    return {
                        messageCode: '9999',
                        messageText:
                            'Unknown error code from function accept: ' +
                            message.messageCode,
                        displayCode: 0,
                    };
            }

        ///////////////

        // The user clicked to 'save' to save a new rule
        case 'create':
            switch (message.messageCode) {
                //  Rule successfully created
                case 0:
                    return {
                        messageCode: '0',
                        // messageText: "Rule successfully created",
                        messageText: 'ruleCreated',
                        displayCode: 1,
                    };

                default:
                    return {
                        messageCode: '9999',
                        messageText:
                            'Unknown error code from function create: ' +
                            message.messageCode,
                        displayCode: '1',
                    };
            }

        ///////////////

        // The user clicked to 'save' to save a new node in MC mode
        case 'add_class_node_mc':
            switch (Number(message.messageCode)) {
                //  Node successfully created
                case 0:
                    return {
                        messageCode: '0',
                        messageText: 'nodeCreatedMC',
                        displayCode: 1,
                    };

                case 504:
                    return {
                        messageCode: '504',
                        messageText: t2('semanticError504', 'sysadmin'),
                        displayCode: '1',
                    };

                default:
                    return {
                        messageCode: '9999',
                        messageText:
                            'Unknown error code from function add_class_node_mc: ' +
                            message.messageCode,
                        displayCode: '1',
                    };
            }

        ///////////////

        case 'frontEnd':
            switch (message.messageCode) {
                //  The user tried to save a new rule but either 'New Conclusion' or 'New Explanation' were empty
                case 502:
                    return {
                        messageCode: '502',
                        messageText:
                            // "Both 'New Conslusion' and 'New Explanation' must be filled in",
                            "Både 'Konklusion' og 'Forklaring' skal udfyldes",
                        displayCode: '1',
                    };

                default:
                    return {
                        messageCode: '9999',
                        messageText:
                            'Unknown error code from function frontEnd: ' +
                            message.messageCode,
                        displayCode: '1',
                    };
            }

        ///////////////

        // The user batch processed cases in a CSV file
        case 'batchProcess':
            switch (message.messageCode) {
                //  Cases successfully processed
                case 0:
                    return {
                        messageCode: '0',
                        // messageText: "Evaluation results stored to file",
                        messageText: 'batchProcessCompleted',
                        displayCode: 1,
                    };

                default:
                    return {
                        messageCode: '9999',
                        messageText:
                            'Unknown error code from function batchProcess: ' +
                            message.messageCode,
                        displayCode: '1',
                    };
            }

        default:
            return {
                messageCode: '9999',
                messageText: 'Unknown function call: ' + message.function,
                displayCode: '1',
            };
    }
}

export default Messages;
