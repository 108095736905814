import React, { useState, useContext } from "react";
import Modal from "react-modal";

import { displayContext } from "../../../common/DisplayContext";

// https://www.npmjs.com/package/react-modal

const customStyles = {
    content: {
        top: "20%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        background: "#E6E6E6",
        width: "25%",
    },
    overlay: {
        zIndex: 1000,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgb(102,102,102,0.8)",
    },
};

function ModalExplanationHelp() {
    const { t, modalExplanationHelp, setModalExplanationHelp } = useContext(
        displayContext
    );

    function closeModal() {
        setModalExplanationHelp(false);
    }

    return (
        <>
            <Modal
                isOpen={modalExplanationHelp}
                onRequestClose={closeModal}
                style={customStyles}
            >
                <p className="modal-help-text ">{t("explanationHelp")}</p>

                <div>
                    <button
                        style={{
                            float: "right",
                            marginTop: "30px",
                        }}
                        className="mentor-button1"
                        onClick={closeModal}
                    >
                        {t("ok")}
                    </button>
                </div>
            </Modal>
        </>
    );
}

export default ModalExplanationHelp;
