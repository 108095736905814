import React, { useEffect, useRef, useState, useContext } from "react";

// See https://visjs.github.io/vis-network/docs/network/edges.html# for vis options
import Graph from 'react-graph-vis';


import { displayContext } from "../../../common/DisplayContext.js";
import { knowledgeContext } from "../../contexts/KnowledgeContext.js";
import { domainContext } from "../../contexts/DomainContext.js";

import styles from '../../styles.module.css'
import { color } from "d3";
import { highlight } from "prismjs";

function RuleGraph() {
    
    const {
        ruleGraph,
    } = useContext(knowledgeContext);

    const {
        t2,
    } = useContext(displayContext);

    const [hoveredNodeId, setHoveredNodeId] = useState(null); // State to track hovered node ID
    const [hoveredNode, setHoveredNode] = useState({}); // State to track hovered node ID


    // let tmp = ruleGraph


    // Transform ruleGraph data to include detailed labels
    const transformedNodes = ruleGraph.nodes.map(node => ({
        ...node,
        label: `${t2("ruleId", "sysadmin")}: ${node.id}\n${t2("conclusion", "sysadmin")}: ${node.conclusion}\n${t2("rule", "sysadmin")}: ${node.rule}`,
        shape: 'box',
        font: { multi: true, align: 'left' }, // Enable multi-line labels
        x: node.x, // Assuming your node data includes x coordinate
        y: node.y  // Assuming your node data includes y coordinate
    }));

    // Construct the new graphData with transformed nodes
    const transformedGraphData = {
        nodes: transformedNodes,
        edges: ruleGraph.edges.map(edge => ({
            ...edge,
            smooth: {
                type: edge.smooth ? edge.smooth.type : 'curvedCW',
                roundness: edge.smooth ? edge.smooth.roundness : 0.2
            },
            color: {
                color: getEdgeColor(edge),
                highlight: getEdgeColor(edge),
                hover: getEdgeColor(edge)
            }
        }))
    };


    function getEdgeColor(edge) {
        console.log("Edge data:", edge);
        if (edge.relationship === "true") {
          return  "green" ;
        } else if (edge.relationship === "false") {
          return "red";
        }
        // Default color or other conditions
        return { color: "yellow" };
    };
    
    // const getEdgeColor = (edge) => {
    //     console.log("Edge data:", edge);
    //     if (edge.relationship === "true") {
    //       return { color: "green" };
    //     } else if (edge.relationship === "false") {
    //       return { color: "red" };
    //     }
    //     // Default color or other conditions
    //     return { color: "yellow" };
    // };

    const graphOptions = {
    //   layout: {
    //     hierarchical: {
    //       direction: 'UD', // Vertical layout
    //       sortMethod: 'directed', // Sort nodes based on their links
    //       nodeSpacing: 150, // Spacing between nodes
    //       levelSeparation: 250, // Separation between levels
    //     },
    //   },
        layout: {
            // Disable hierarchical layout
            hierarchical: false
        },

      nodes: {
        shape: 'box',
        font: {
          multi: 'html' // Enable HTML in labels
        },
        fixed: {
            x: true,
            y: true
        }
      },

      edges: {
        arrows: {
          to: { enabled: true },
        },
        color: { inherit: false },
        font: { color: '#343434', size: 11, face: 'arial', background: 'none', strokeWidth: 0, strokeColor: '#ffffff', align: 'horizontal' },
        smooth: {
          type: 'curvedCW', // Default smooth configuration for other edges
          roundness: 0.2,
        },
        width: 4,
        hoverWidth: 0,
        selectionWidth: 0
      },
      interaction: {
            hover: true,
            zoomView: true, // Enable zooming
            dragView: true,
            tooltipDelay: 200, // Delay before showing tooltips
            hideEdgesOnDrag: false, // Show edges while dragging
            hideNodesOnDrag: false, // Show nodes while dragging
            navigationButtons: true, // Show navigation buttons
            keyboard: {
                enabled: true,
                speed: { x: 10, y: 10, zoom: 0.01 },
                bindToWindow: true
            },
            zoomSpeed: 0.1,
        },
        physics: false, // Disable physics simulation for better control
        // minZoom: 0.5, // Adjust minimum zoom level
        // maxZoom: 1.0, // Adjust maximum zoom level
    };
    
    const graphEvents = {
        // Event handler for when a node is hovered
        hoverNode: (event) => {
            if (event["node"] != undefined) {
                setHoveredNodeId(event["node"])
                setHoveredNode(ruleGraph["nodes"].find(item => item.id == event["node"]))
            }

        },
        // Event handler for when a node is no longer hovered
        blurNode: () => {
            setHoveredNodeId(null); // Reset hovered node ID state
        },
    };

    function get_node_details(node) {
        return (
            // ruleGraph["nodes"][hoveredNodeId]["conclusion"]
            
            <div>

                <p>
                    <span className={styles.boldLabel}>{t2("ruleId", "sysadmin")}: </span>
                    {node["id"]}
                    (F: {node["next_node_false"]}, T: {node["next_node_true"]})
                </p>

                <p>
                    <span className={styles.boldLabel}>{t2("rule", "sysadmin")}: </span>
                    {node["rule"]}
                </p>

                <p>
                    <span className={styles.boldLabel}>{t2("conclusion", "sysadmin")}: </span>
                    {node["conclusion"]}
                </p>

                <p>
                    <span className={styles.boldLabel}>{t2("explanation", "sysadmin")}: </span>
                    {node["explanation"]}
                </p>

            </div>
        )
    }

    return (
        <div className={[styles.flexRow, styles.ruleTreeMain].join(' ')}>
            <div className={styles.ruleTree}>
                <Graph
                        graph={transformedGraphData}
                        options={graphOptions}
                        // getEdgeColor={getEdgeColor}
                        events={graphEvents}
                        style={{ height: '800px' }}
                />
            </div>

            <div className={[styles.box, styles.ruleTreeNodeDetailsWrapper].join(' ')}>
                {hoveredNodeId !== null && (
                    <div className="hovered-node-info">
                        {get_node_details(hoveredNode)}
                        {/* Replace with actual data for the hovered node */}
                        {/* Example: Fetch and display data from a context or API */}
                        {/* Example: Display node details from graphData */}
                    </div>
                )}
            </div>     
        </div>     
    );


}

export default RuleGraph;
