import React from 'react';
import '../test.css'; // Create this CSS file

// Images
// import bubbleComprehensive from '../images/bubble-comprehensive.png'
// import bubblePurchase from '../images/bubble-purchase.png'
// import bubbleSomething from '../images/bubble-something.png'
// import bubbleTrademark from '../images/bubble-trademark.png'

import { useNavigate } from "react-router-dom";

function LegalTypesDesktop() {

    let navigate = useNavigate();

    function gotoTrademark(e) {
        navigate("/TrademarkDesktop");
    }

    return (
        <div className='container-basic'>
            <div className='top-bar top-bar-basic'></div>
            <div className='boxlegal-1'>
                <h2 className='app-title'>legal</h2>
            </div>

            <div className='ball-container-trademark desktop-only' onClick={gotoTrademark}>
                <ball className="ball-trademark-desktop" />
                <h5 className='img-caption-trademark'>varemærkeret</h5>
            </div>

            <div className='ball-container-purchase desktop-only'>
                <ball className="ball-purchase-desktop" />
                <h5 className='img-caption-purchase'>køb</h5>
            </div>

            <div className='ball-container-something desktop-only'>
                <ball className="ball-something-desktop" />
                <h5 className='img-caption-something'>retsområde</h5>
            </div>

            <div className='ball-container-comprehensive desktop-only'>
                <ball className="ball-comprehensive-desktop" />
                <h5 className='img-caption-comprehensive'>forsikring</h5>
            </div>

        </div>
    )
};

export default LegalTypesDesktop;
