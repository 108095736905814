import React, { useContext } from "react";
import { Form, Row } from "react-bootstrap";

import { evaluationContext } from "../../contexts/EvaluationContext";
import { displayContext } from "../../../common/DisplayContext";
import { errorContext } from "../../contexts/ErrorContext";

import styles from '../../styles.module.css';

import ResultConclusionHeading from "./ResultConclusionHeading";

///////////////////////////////////////////////////////////////////////////////////
//
//  ResultConclusion: show the conclusion after evaluation
//
///////////////////////////////////////////////////////////////////////////////////

function ResultConclusionNew() {
    const { conclusionLastTrue } = useContext(evaluationContext);

    const {
        newConclusion,

        setButtonSaveNewRuleDisabled,
        setNewConclusion,
        t,
    } = useContext(displayContext);

    const { errors } = useContext(errorContext);

    // setNewConclusion({ conclusionLastTrue });

    function handleConclusionChange(e) {
        setButtonSaveNewRuleDisabled(false);
        setNewConclusion(e.target.value);
    }

    return (
        <>


            <div className={styles.conclusion}>
                <ResultConclusionHeading />
                <textarea
                    name="newConclusion"
                    className="newConclusion"
                    type="text"
                    value={newConclusion}
                    onChange={(e) => handleConclusionChange(e)}
                    isInvalid={!!errors.newConclusion}
                />
            </div>

            {errors.newConclusion && (
                <div
                    style={{
                        width: "400px",
                        padding: "10px",
                    }}
                >
                    <a type="text" placeholder="You must enter a conclusion" />
                </div>
            )}
        </>
    );
}

export default ResultConclusionNew;
