import React from "react";

import styles from '../../styles.module.css'

import menuItemsList from "./menuItemsList";
import MenuItems from "./MenuItems";

const Navbar = () => {
    const menuList = menuItemsList();

    return (
        <nav>
            <ul className={styles.menus}>
                {menuList.map((menu, index) => {
                    const depthLevel = 0;
                    return (
                        <MenuItems
                            items={menu}
                            key={index}
                            depthLevel={depthLevel}
                        />
                    );
                })}
            </ul>
        </nav>
    );
};

export default Navbar;
