import React, { useContext } from "react";

import {
    Button,
    ButtonGroup,
    Spinner,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";

import styles from '../../styles.module.css'

import { displayContext } from "../../../common/DisplayContext.js";
import { knowledgeContext } from "../../contexts/KnowledgeContext.js";

function ButtonKnowledge(button) {

    const {
        t2,
        overlayTriggerHelp,
    } = useContext(displayContext);

    const {
        activeButton,
        setActiveButton,
    } = useContext(knowledgeContext);


    function handleButtonClick() {
        setActiveButton(button.name)
    }

    return (
        <span>
            <Button
                // className="ms-1 me-1"
                className={styles.standardButton}
                variant={activeButton == button.name ? 'success' : 'outline-success'}
                // size="sm"
                onClick={handleButtonClick}
            >
                {t2(button.name, "sysadmin")}
            </Button>
        </span>
    );
}

export default ButtonKnowledge;
