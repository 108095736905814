// TODO:
// 
// I swiperen skal jeg fra 'stenslag' kunne swipe lodret og komme til undermenuen
// Se link for at fange lodrette swipes: https://stackoverflow.com/questions/70612769/how-do-i-recognize-swipe-events-in-react
// Det skal være sådan at det første lodrette swipe åbner undermenuen, og et vandret swipe i undermenuen skal returnere 
// til overmenuen...


import React, { useRef, useState, useContext } from 'react';
import TopContextProvider from '../contexts/TopContext';
import ComprehensiveSwiperMain from './ComprehensiveSwiperMain';

function ComprehensiveSwiper() {


    return (
        <TopContextProvider>
            <ComprehensiveSwiperMain />
        </TopContextProvider >
    )
}
export default ComprehensiveSwiper;