import React, { useContext } from "react";

import { displayContext } from "../../common/DisplayContext";

function useTranslateRuleToEnglish() {
    const { t } = useContext(displayContext);

    // Replace 'replaceWhat' to 'replaceTo' in the string 'str'
    function translate(str, replaceWhat, replaceTo) {
        var re = new RegExp(replaceWhat, "g");
        return str.replace(re, replaceTo);
    }

    function translateRuleToEnglish(str) {
        let translatedStr = translate(str, t("no"), "no");
        translatedStr = translate(
            translatedStr,

            t("yes"),
            "yes"
        );
        translatedStr = translate(translatedStr, t("and"), "and");
        translatedStr = translate(translatedStr, t("or"), "or");

        return translatedStr;
    }

    return [
        {
            translateRuleToEnglish: translateRuleToEnglish,
        },
    ];
}

export default useTranslateRuleToEnglish;
