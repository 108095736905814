import React, {
    createContext,
    useState,
    useEffect,
    useContext,
    useRef,
} from 'react';
import { useLocation } from 'react-router-dom';
// import { createBrowserHistory } from "history";

import { useTranslation } from 'react-i18next';
import '../translations/i18n';

import { domainContext } from '../admin/contexts/DomainContext';

import useAuth from '../admin/hooks/useAuth';

export const displayContext = createContext();

const DisplayContextProvider = (props) => {
    const { t } = useTranslation();

    function t1(key) {
        return t(key, { ns: currentKnowledgeBase });
    }

    function t2(key, kb) {
        return t(key, { ns: kb });
    }

    // function t3(key, kb) {

    //     // const inputString = 'Du bruger varemærket i flere versioner, ligesom i Den Store Bagedyst (https://tm.cognitysystems.com/docs/DenStoreBagedyst.pdf), Gyro (https://tm.cognitysystems.com/docs/Gyro.pdf), Tingfinder (https://tm.cognitysystems.com/docs/Tingfinder.pdf) og Dencon Foods (https://tm.cognitysystems.com/docs/DenconFoods.pdf).'
    //     let inputString = t(key, { ns: kb });

    //     let convertedInputString = ''
    //     let start = 0
    //     let end = 0

    //     let lengthOfString = 0

    //     let reference = ''
    //     let headString = ''
    //     let tailString = ''
    //     let referenceLink = ''

    //     do {
    //         start = inputString.indexOf('https://')
    //         end = inputString.indexOf('.pdf')

    //         lengthOfString = inputString.length

    //         reference = inputString.substring(start, end + 4)
    //         headString = inputString.substring(0, start)
    //         tailString = inputString.substring(end + 4, lengthOfString)

    //         referenceLink = '<a>' + reference.substring(reference.lastIndexOf('/') + 1, end) + '</a>'

    //         convertedInputString = convertedInputString + headString + referenceLink
    //         inputString = tailString
    //     } while (tailString.indexOf('.pdf') != -1)

    //     convertedInputString = convertedInputString + tailString;

    //     return convertedInputString;
    // }

    const { token } = useAuth();
    const [translations, setTranslations] = useState('');

    const {
        currentDomain,
        currentKnowledgeBase,
        // setCurrentDomain,
        // storedDomain,
        // storedKnowledgeBase,
    } = useContext(domainContext);

    const [previousPath, setPreviousPath] = useState('');
    // const [originalPath, setOriginalPath] = useState("");

    const [displayMessage, setDisplayMessage] = useState({
        messageCode: null,
        messageText: '',
        displayCode: 0,
    });

    const [buttonAcceptDisabled, setButtonAcceptDisabled] = useState(true);
    const [buttonRejectDisabled, setButtonRejectDisabled] = useState(true);
    const [buttonLetterDisabled, setButtonLetterDisabled] = useState(true);
    const [buttonCancelDisabled, setButtonCancelDisabled] = useState(false);

    const [buttonSaveNewRuleDisabled, setButtonSaveNewRuleDisabled] =
        useState(true);

    const [buttonAddNodeDisabled, setButtonAddNodeDisabled] = useState(false);
    const [buttonAddeDisabled, setButtonAddDisabled] = useState(true);

    const [checkboxFormula, setCheckboxFormula] = useState(true);
    const [diffIndicatorStatus, setDiffIndicatorStatus] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [newConclusion, setNewConclusion] = useState('');
    const [newLetterRuleTrue, setNewLetterRuleTrue] = useState('');
    const [newLetterRuleFalse, setNewLetterRuleFalse] = useState('');
    const [newLetter, setNewLetter] = useState('');
    const [newExplanation, setNewExplanation] = useState('');
    const [newNote, setNewNote] = useState('');
    const [newConclusionGroup, setNewConclusionGroup] = useState('');
    const [newRule, setNewRule] = useState('');

    const [newConclusionMC, setNewConclusionMC] = useState('');
    const newConclusionMCRef = useRef(newConclusionMC);

    const [code, setCode] = useState('');
    const [textAreaNewConclusionDisabled, setTextAreaNewConclusionDisabled] =
        useState(true);
    const [textAreaNewExplanationDisabled, setTextAreaNewExplanationDisabled] =
        useState(true);
    const [textAreaNewNoteDisabled, setTextAreaNewNoteDisabled] =
        useState(true);
    const [textAreaNewRuleDisabled, setTextAreaNewRuleDisabled] =
        useState(true);

    const [useMode, setUseMode] = useState('read');
    const [uIModeRejectCaseMC, setUIModeRejectCaseMC] = useState(false);
    const [uiModeMCAddNode, setUiModeMCAddNode] = useState(false);
    const [currentPage, setCurrentPage] = useState('evaluate');

    const [caseSelected, setCaseSelected] = useState('');
    const [checkboxTrue, setCheckboxTrue] = useState(true);
    const [checkboxFalse, setCheckboxFalse] = useState(true);
    const [newAttributeNorMinDisabled, setNewAttributeNorMinDisabled] =
        useState(false);
    const [newAttributeNorMaxDisabled, setNewAttributeNorMaxDisabled] =
        useState(false);

    const [pageIndex, setPageIndex] = useState();
    // const history = createBrowserHistory();

    const [modalCaseRejected, setModalCaseRejected] = useState(false);
    const [modalSelectLanguage, setModalSelectLanguage] = useState(false);

    const [isLoading3, setIsLoading3] = useState(false);
    const [spinnerMessage, setSpinnerMessage] = useState('');
    const [isLoadingLearningCase, setIsLoadingLearningCase] = useState(false);
    const [isEvaluating, setIsEvaluating] = useState(false);
    const [goToEval, setGoToEval] = useState(false);
    const [goToHome, setGoToHome] = useState(false);
    const [currentLanguage, setCurrentLanguage] = useState(
        localStorage.getItem('i18nextLng'),
    );

    const [overlayTriggerHelp, setOverlayTriggerHelp] = useState(false);
    const [modalConclusionHelp, setModalConclusionHelp] = useState(false);
    const [modalExplanationHelp, setModalExplanationHelp] = useState(false);
    const [modalRuleHelp, setModalRuleHelp] = useState(false);

    const languages = [
        {
            title: 'English',
            action: 'language',
            language: 'english',
        },
        {
            title: 'Danish',
            action: 'language',
            language: 'danish',
        },
    ];

    const [bubbleInfoGeography, setBubbleInfoGeography] = useState(
        t2('geographyPromptTm1', 'varemaerke'),
    );

    const [bubbleInfoIntensity, setBubbleInfoIntensity] = useState(
        t2('intensityPromptTm1', 'varemaerke'),
    );

    const [bubbleState, setBubbleState] = useState([
        { name: 'useOwnedByMe', enabled: false },
        { name: 'useNotOwnedByMe', enabled: false },
        { name: 'useOneVersion', enabled: false },
        { name: 'useMultipleVersions', enabled: false },

        { name: 'placeCinema', enabled: false },
        { name: 'placeShop', enabled: false },
        { name: 'placeFitnessCenter', enabled: false },
        { name: 'placeAmusementPark', enabled: false },
        { name: 'placeAssociation', enabled: false },
        { name: 'placeHotel', enabled: false },
        { name: 'placeClinic', enabled: false },
        { name: 'placeFoodstall', enabled: false },
        { name: 'placeExhibition', enabled: false },
        { name: 'placeRestaurant', enabled: false },
        { name: 'placeClub', enabled: false },
        { name: 'placeDepartmentStore', enabled: false },
        { name: 'placeSupermarket', enabled: false },
        { name: 'placeTown', enabled: false },
        { name: 'placeAbroadTowardDK', enabled: false },
        { name: 'placeAbroadNotTowardDK', enabled: false },
        { name: 'placeWholeDenmark', enabled: false },
        { name: 'placeRegion', enabled: false },
        { name: 'placeGreenland', enabled: false },
        { name: 'placeFaroeIslands', enabled: false },
        { name: 'placeOnline', enabled: false },

        { name: 'contextGoods', enabled: false },
        { name: 'contextService', enabled: false },
        { name: 'contextID', enabled: false },
        { name: 'contextNewspaper', enabled: false },
        { name: 'contextBrochure', enabled: false },
        { name: 'contextDenBlaaAvis', enabled: false },
        { name: 'contextMoviePoster', enabled: false },
        { name: 'contextCatalogoue', enabled: false },
        { name: 'contextMagazine', enabled: false },
        { name: 'contextProjectPresentation', enabled: false },
        { name: 'contextPressRelease', enabled: false },
        { name: 'contextCalendar', enabled: false },
        { name: 'contextSign', enabled: false },
        { name: 'contextPamphlet', enabled: false },
        { name: 'contextAdMagazine', enabled: false },
        { name: 'contextTv', enabled: false },
        { name: 'contextRadio', enabled: false },
        { name: 'contextCompanyName', enabled: false },
        { name: 'contextCompanyInvoice', enabled: false },
        { name: 'contextVan', enabled: false },
        { name: 'contextAdCar', enabled: false },
        { name: 'contextHomepageOwn', enabled: false },
        { name: 'contextHomepageOther', enabled: false },
        { name: 'contextSomeOwn', enabled: false },
        { name: 'contextSomeOther', enabled: false },
        { name: 'contextNewsletter', enabled: false },
        { name: 'contextEmail', enabled: false },
        { name: 'contextPressNewspaper', enabled: false },
        { name: 'contextPressInternet', enabled: false },
        { name: 'contextPressBook', enabled: false },
        { name: 'contextInternalInvoice', enabled: false },
        { name: 'contextInternalDiscussion', enabled: false },
        { name: 'contextInternalSale', enabled: false },

        { name: 'continuationContinuously', enabled: false },
        { name: 'continuationPause', enabled: false },
        { name: 'continuationStop', enabled: false },
        { name: 'timeYears', enabled: false },
    ]);

    const [timePrompt, setTimePrompt] = useState('');
    const [sidebarWidth, setSidebarWidth] = useState(35);
    // const [bubbleStateGeography, setBubbleStateGeography] = useState([
    //     { name: "geographyLocalCityTown", enabled: false },
    //     { name: "geographyLocalAssociation", enabled: false },
    //     { name: "geographyLocalRestaurant", enabled: false },
    //     { name: "geographyLocalAmusementPark", enabled: false },
    //     { name: "geographyLocalHotel", enabled: false },
    //     { name: "geographyRegional", enabled: false },
    //     { name: "geographyDenmark", enabled: false },
    //     { name: "geographyFGGreenland", enabled: false },
    //     { name: "geographyFGFaroeIslands", enabled: false },
    //     { name: "geographyOutsideDenmarkDirectedDenmark", enabled: false },
    //     { name: "geographyOutsideDenmarkNotDirectedDenmark", enabled: false },
    //     { name: "geographyUseOnline", enabled: false },
    // ]);

    return (
        <displayContext.Provider
            value={{
                bubbleInfoIntensity,
                buttonAcceptDisabled,
                buttonCancelDisabled,
                buttonLetterDisabled,
                buttonRejectDisabled,
                buttonSaveNewRuleDisabled,
                caseSelected,
                checkboxFalse,
                checkboxFormula,
                checkboxTrue,
                code,
                currentLanguage,
                diffIndicatorStatus,
                displayMessage: displayMessage,
                goToEval,
                goToHome,
                isEvaluating,
                isLoading,
                isLoading3,
                isLoadingLearningCase,
                languages,
                modalCaseRejected,
                modalConclusionHelp,
                modalExplanationHelp,
                modalRuleHelp,
                modalSelectLanguage,
                newAttributeNorMaxDisabled,
                newAttributeNorMinDisabled,
                newConclusion,
                newExplanation,
                newLetter,
                newLetterRuleFalse,
                newLetterRuleTrue,
                newNote,
                newRule,
                pageIndex,
                previousPath,
                setBubbleInfoIntensity,
                setButtonAcceptDisabled,
                setButtonCancelDisabled,
                setButtonLetterDisabled,
                setButtonRejectDisabled,
                setButtonSaveNewRuleDisabled,
                setCaseSelected,
                setCheckboxFalse,
                setCheckboxFormula,
                setCheckboxTrue,
                setCode,
                setCurrentLanguage,
                setDiffIndicatorStatus,
                setDisplayMessage,
                setGoToEval,
                setGoToHome,
                setIsEvaluating,
                setIsLoading,
                setIsLoading3,
                setIsLoadingLearningCase,
                setModalCaseRejected,
                setModalConclusionHelp,
                setModalExplanationHelp,
                setModalRuleHelp,
                setModalSelectLanguage,
                setNewAttributeNorMaxDisabled,
                setNewAttributeNorMinDisabled,
                setNewConclusion,
                setNewExplanation,
                setNewLetter,
                setNewLetterRuleFalse,
                setNewLetterRuleTrue,
                setNewNote,
                setNewRule,
                setPageIndex,
                setPreviousPath,
                setSpinnerMessage,
                setTextAreaNewConclusionDisabled,
                setTextAreaNewExplanationDisabled,
                setTextAreaNewNoteDisabled,
                setTextAreaNewRuleDisabled,
                setUseMode,
                spinnerMessage,
                t2: t2,
                t: t1,
                textAreaNewConclusionDisabled,
                textAreaNewExplanationDisabled,
                textAreaNewNoteDisabled,
                textAreaNewRuleDisabled,
                useMode,
                bubbleState,
                setBubbleState,
                bubbleInfoGeography,
                setBubbleInfoGeography,
                timePrompt,
                setTimePrompt,
                sidebarWidth,
                overlayTriggerHelp,
                setOverlayTriggerHelp,
                newConclusionGroup,
                setNewConclusionGroup,
                uIModeRejectCaseMC,
                setUIModeRejectCaseMC,
                buttonAddNodeDisabled,
                setButtonAddNodeDisabled,
                uiModeMCAddNode,
                setUiModeMCAddNode,
                buttonAddeDisabled,
                setButtonAddDisabled,
                currentPage,
                setCurrentPage,
            }}
        >
            {props.children}
        </displayContext.Provider>
    );
};

export default DisplayContextProvider;
