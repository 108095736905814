import React, { useContext, useState } from "react";

import styles from '../../styles.module.css';

import Navbar from "../Settings/Navbar";
import NewAttribute from "./NewAttribute";
import AttributeTable from "./AttributeTable";

// import Messages from "../components/Messages";

function AttributePageMain() {
    return (
        <>
            <div className={styles.standardPage}>
                <div className={styles.standardPageTop}>
                    <div>
                        <Navbar />
                    </div>
                    <div className={styles.attributesNewAttribute}>
                        <NewAttribute />
                    </div>
                </div>
                <div className={styles.attributeTableBox}>
                    <AttributeTable />
                </div>
            </div>
        </>
    );
}
export default AttributePageMain;
