// See https://www.robinwieruch.de/react-table-component/ for the tutorial for creating this type of tables
// https://www.robinwieruch.de/categories/react-table-library/
// samples: https://react-table-library.com/?path=/story/getting-started--demo
// https://github.com/table-library/react-table-library
import React, { useEffect } from "react";
import { useHistory, useNavigate } from "react-router-dom";

import styles from '../../styles.module.css';

import {
    Table,
    Header,
    HeaderRow,
    HeaderCell,
    Body,
    Row,
    Cell,
} from "@table-library/react-table-library/table";

import {
    useSort,
    // HeaderCellSort,
} from "@table-library/react-table-library/sort";

import {
    // Sort,
    // SortOptions,
    // SortIconPositions,
    SortToggleType,
} from "@table-library/react-table-library/sort";

// import { defaultTheme } from "../../hooks/useReactTableTheme";
import useReactTableTheme from "../../hooks/useReactTableTheme";
import defaultTableTheme from "./DefaultTableTheme";

import { useTheme } from "@table-library/react-table-library/theme";

import {
    DEFAULT_OPTIONS,
    getTheme,
} from '@table-library/react-table-library/mantine';

// import { CompactTable } from '@table-library/react-table-library/compact';
// import { useTheme } from "@table-library/react-table-library/theme";

import { displayContext } from "../../../common/DisplayContext";
import { knowledgeContext } from "../../contexts/KnowledgeContext";

import { useContext, useState } from "react";

import RuleDetails from "./RuleDetails";

function RuleTable() {
    let navigate = useNavigate();

    const mantineTheme = getTheme(DEFAULT_OPTIONS);
    const theme1 = useTheme(mantineTheme);

    const {
        ruleList,
        currentRuleId,
        setCurrentRuleId,
    } = useContext(knowledgeContext);

    const {
        t2,
    } = useContext(displayContext);

    const [{ defaultTheme }] = useReactTableTheme();

    defaultTableTheme.Table = `
        height: 100%; 
        width: 100%; 
        align-content: baseline; 
        --data-table-library_grid-template-columns: 6% 60% 34%;
    `
    const theme = useTheme(defaultTableTheme)
    
    const [data, setData] = useState({ nodes: ruleList });
    const nodeAttributesToFilter = ['id', 'rule', 'conclusion', 'userid', 'createdtime']

    useEffect(() => {
        const filteredData = ruleList.map(obj => {
            const newObj = {};

            nodeAttributesToFilter.forEach(attr => {
                newObj[attr] = obj[attr]
            });
            return newObj;
        })

        setData(
            { 
                nodes: filteredData 
            }
        );
    }, [ruleList]);


    const handleFilter = (value, property) => {
        setData({
            nodes:
                value !== ""
                    ? ruleList.filter(function (item) {
                        return typeof (item[property]) == 'string' ?
                            item[property].includes(value) :
                            item[property] == value
                    })
                    : ruleList,
        });
    };


    const sort = useSort(
        data,
        {
            onChange: onSortChange,
        },
        {
            sortToggleType: SortToggleType.AlternateWithReset,
            sortFns: {
                CaseId: (array) =>
                    array.sort((a, b) => a.caseId.localeCompare(b.caseId)),
            },
        }
    );

    function onSortChange(action, state) {
        console.log(action, state);
    }

    function handleRule(e) {
        setCurrentRuleId(e.id)
    }


    
    function handleRuleMouseOver(item) {
        console.log('Mouseover - item.caseId: ', item.caseId)
        // setCurrentRuleId(e.id)
    }

    function headerCell(property, filter) {
        return (
            <HeaderCell resize>
                <input
                    className={styles.caseTableFilter}
                    type="text"
                    placeholder={t2(filter, "sysadmin")}
                    onChange={(event) =>
                        handleFilter(
                            event.target.value,
                            property
                        )
                    }
                />
            </HeaderCell>
        )
    }

    return (
        <div className={styles.ruleTable}>
        <div className={[styles.standardTableWrapper, styles.kbRulesRuleTableWrapper].join(' ')}>
            <div className={styles.boxHeading}>{t2("rules", "sysadmin")}</div>

            <Table 
                className={styles.standardTable2}
                data={data} 
                theme={theme} 
                sort={sort} 
                layout={{custom: true}}
            >
                {(tableList) => (
                    <>
                        <Header>
                            <HeaderRow>
                                {headerCell('id', 'idFilter')}
                                {headerCell('rule', 'ruleFilter')}
                                {headerCell('conclusion', 'conclusionFilter')}
                            </HeaderRow>
                        </Header>

                        <Body>
                            {tableList.map((item) => (
                                <Row
                                    key={item.caseId}
                                    item={item}
                                    onClick={(event) => handleRule(event)}
                                >
                                    <Cell><div className={styles.caseTableComment}>{item.id}</div></Cell>
                                    <Cell><div className={styles.caseTableComment}>{item.rule}</div></Cell>
                                    <Cell><div className={styles.caseTableComment}>{item.conclusion}</div></Cell>
                                </Row>
                            ))}
                        </Body>
                    </>
                )}
            </Table>
            </div>
            </div>
    );
}

export default RuleTable;
