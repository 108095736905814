import React, { useContext, useEffect } from "react";

import styles from '../../styles.module.css';

import { useLocation } from "react-router-dom";

import HeaderLoggedIn from "../Header/HeaderLoggedIn";
import NavigationSideBar from "../Navigation/NavigationSideBar";
import EvaluateCaseMain from "./EvaluateCaseMain";

import { domainContext } from "../../contexts/DomainContext";
import { userCaseContext } from "../../contexts/UserCaseContext";
import { displayContext } from "../../../common/DisplayContext";

import useClearEvaluation from "../../hooks/useClearEvaluation";
import useNewNodeFields from "../../hooks/useNewNodeFields";

///////////////////////////////////////////////////////////////////////////////////
//
///////////////////////////////////////////////////////////////////////////////////

function LearnPage() {
    const { currentDomain, setCurrentDomain } = useContext(domainContext);
    const { userCase, setUserCase, setCaseStatus } =
        useContext(userCaseContext);
    const {
        buttonRejectDisabled,
        displayMessage,
        newConclusion,
        newExplanation,
        setButtonAcceptDisabled,
        setButtonLetterDisabled,
        setButtonRejectDisabled,
        setButtonSaveNewRuleDisabled,
        setPreviousPath,
        setDisplayMessage,
        setNewConclusion,
        setNewExplanation,
        setUseMode,
        setTextAreaNewConclusionDisabled,
    } = useContext(displayContext);

    let location = useLocation();

    if (userCase == []) {
        setCurrentDomain(currentDomain);
    }

    const [{ handleClearEvaluation }] = useClearEvaluation();
    const [{ disableNewNodeFields }] = useNewNodeFields();

    // This is how we execute statements only when arriving at this page by navigating from another page.
    // When navigating from another page location.key will have changed,
    // location.key does *not* change when I stay on the same tab
    useEffect(() => {
        setUseMode("learn");
        disableNewNodeFields();
        handleClearEvaluation();
        setPreviousPath(location.pathname);
    }, [location.key]);

    return (
        <>
            <div className={styles.page}>
                <div className={styles.pageMain}>
                    <NavigationSideBar />
                    <EvaluateCaseMain />
                </div>
            </div>
        </>
    );
}

export default LearnPage;
