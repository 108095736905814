import React, {
    createContext,
    useEffect,
    useState,
    useContext,
    useRef,
} from "react";

import { domainContext } from "./DomainContext";
import { userCaseContext } from "./UserCaseContext";
import { CurrentCategoryContext } from "./CurrentCategoryContext";
// import { TokenContext } from "./TokenContext.js";
import useAuth from "../hooks/useAuth";
import useFetch from "../hooks/useFetch";
// import { Config } from "../../config";

export const attributesContext = createContext();

const AttributesContextProvider = (props) => {
    const {
        currentDomain,
        currentKnowledgeBase,
        setCurrentDomain,
        storedDomain,
        storedKnowledgeBase,
    } = useContext(domainContext);

    const { currentCategory } = useContext(CurrentCategoryContext);

    const { userCase, setUserCase } = useContext(userCaseContext);
    const [reloadUserCase, setReloadUserCase] = useState(false);

    // const { token } = useContext(TokenContext);
    const { token } = useAuth();
    const { fetchEvaluate } = useFetch();
    const { fetchGetAttributes } = useFetch();
    

    const [displayMessage, setDisplayMessage] = useState({
        messageCode: null,
        messageText: "",
        displayCode: 0,
    });

    const [newAttributeName, setNewAttributeName] = useState();
    const [newAttributeDisplayName, setNewAttributeDisplayName] = useState();
    const [newAttributeType, setNewAttributeType] = useState();
    const [newAttributeNormMin, setNewAttributeNormMin] = useState();
    const [newAttributeNormMax, setNewAttributeNormMax] = useState();
    const [newAttributeCategory, setNewAttributeCategory] = useState();
    const [reloadCurrentAttributes, setReloadCurrentAttributes] = useState(0);

    // // Set currentDomain to the domain name stored in localStorage, of if nothing is stored in
    // // localStorage, then set currentDomain to the string 'noDomain'
    // const storedDomain = (localStorage.getItem("domain") ? localStorage.getItem("domain") : 'noDomain')
    // const [currentDomain, setCurrentDomain] = useState(storedDomain);

    // currentAttributes is the attributes defined for the current domain
    const [currentAttributes, setCurrentAttributes] = useState([]);

    // // userCase is the case to be evaluated by the system, defined by the user or upstream system
    // const [userCase, setUserCase] = useState([]);

    // Update userCase at start and whenever the currentDomain is changed, i.e. when the
    // user changes from one domain to another

    // isMounted is used to check whether the component is mounted, and
    // we use this to prevent the useEffect for updating userCase when currentCategory
    // has changed to run on the first render (see below).
    const isMounted = useRef(false);

    useEffect(() => {
        // fetchEvaluate("initiate");
        fetchGetAttributes();
   }, [token, currentKnowledgeBase, currentDomain, reloadUserCase]);
    // }, [token, currentDomain]);

    // Update userCase whenever the currentCategory is changed, i.e. when the
    // user changes from one category to another. We use isMounted to prevent the
    // running of the code on the first render, i.e. when the app starts. We only want to
    // run the code after the user actively selected a new category (see above).
    useEffect(() => {
        if (token != null) {
            if (isMounted.current) {
                const cloneUserCase = [...userCase];

                cloneUserCase.forEach((attribute) => {
                    attribute["referenceValue"] = "";
                    attribute["referenceMatch"] = "";
                });

                var obj = {};
                obj.knowledgeBase = currentKnowledgeBase;
                obj.domain = currentDomain;
                obj.category = currentCategory;
                obj.caseData = userCase;

                // var endpoint = FLASK_API + "getAttributeCategories";
                var endpoint =
                    process.env.REACT_APP_FLASK_API + "getAttributeCategories";

                fetch(endpoint, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                    body: JSON.stringify(obj),
                })
                    .then((res) => res.json())
                    .then((responseJson) => {
                        setUserCase(responseJson["caseData"]);
                        console.log("AttributesContext - SETTING userCase2");
                    });
            } else {
                isMounted.current = true;
            }
        }
        // }, [currentCategory, reloadUserCase]);      // <<<<<< DETTE KALD GIVER FEJL FORDI reloadUserCase ER MED. VED IKKE HVAD PROBLEMET ER.
    }, [currentCategory]);

    // Update currentAttributes at start and whenever the currentDomain is changed, i.e. when the
    // user changes from one domain to another
    useEffect(() => {
        var obj = {};

        if (token != null && currentKnowledgeBase != "noKnowledgeBase") {
            obj.knowledgeBase =
                currentKnowledgeBase === ""
                    ? storedKnowledgeBase
                    : currentKnowledgeBase;
            obj.domain = currentDomain === "" ? storedDomain : currentDomain;

            var endpoint =
                process.env.REACT_APP_FLASK_API + "getAttributes_json1";
            fetch(endpoint, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify(obj),
            })
                .then((res) => res.json())
                .then((responseJson) => {
                    setCurrentAttributes(responseJson);
                    setReloadUserCase(!reloadUserCase);
                });
        }
    }, [token, currentKnowledgeBase, currentDomain, reloadCurrentAttributes]);

    // Common initial state for data fetches
    // const initialState_fetch = {
    //     loading: true,
    //     error: '',
    //     data: []
    //   }

    return (
        <attributesContext.Provider
            value={{
                currentAttributes,
                currentDomain,
                displayMessage,
                newAttributeCategory,
                newAttributeDisplayName,
                newAttributeName,
                newAttributeNormMax,
                newAttributeNormMin,
                newAttributeType,
                reloadCurrentAttributes,
                reloadUserCase,
                setCurrentAttributes,
                setCurrentDomain,
                setDisplayMessage,
                setNewAttributeCategory,
                setNewAttributeDisplayName,
                setNewAttributeName,
                setNewAttributeNormMax,
                setNewAttributeNormMin,
                setNewAttributeType,
                setReloadCurrentAttributes,
                setReloadUserCase,
            }}
        >
            {props.children}
        </attributesContext.Provider>
    );
};

export default AttributesContextProvider;
