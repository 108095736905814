import React from "react";
import RejectedCaseTable from "./RejectedCaseTable";

import styles from '../../styles.module.css';

import Navbar from "../Settings/Navbar";

function RejectedCaseMain() {
    return (
        <div className={styles.rejectedCaseMain}>
            <div className={styles.standardPageTop}>
                <Navbar />
            </div>

            <div className={styles.standardPageMiddle}>
                <RejectedCaseTable />
            </div>
        </div>
    );
}

export default RejectedCaseMain;
