import React, { useState, useContext, useRef } from "react";
import {
    Container,
    Form,
    Col,
    Row,
    Button,
    InputGroup,
    ButtonGroup,
} from "react-bootstrap";

import { displayContext } from "../../../common/DisplayContext.js";
import { domainContext } from "../../contexts/DomainContext";
import { errorContext } from "../../contexts/ErrorContext";
import { rejectedCaseContext } from "../../contexts/CaseContext";

import styles from '../../styles.module.css';

import useAuth from "../../hooks/useAuth";

import Messages from "../Messages.js";
import Navbar from "../Settings/Navbar.js";

// import { Config } from "../../../config";

function BatchProcessPageMain() {
    const {
        setDisplayMessage,
        t,
        t2,
    } = useContext(displayContext);


    const { token } = useAuth();
    const { newDomain, setNewDomain, currentKnowledgeBase } =
        useContext(domainContext);
    const { reloadCaseList, setReloadCaseList } =
        useContext(rejectedCaseContext);
    const { errors, setErrors } = useContext(errorContext);

    const [checkboxCreate, setCheckboxCreate] = useState(false);
    const [csv, setCsv] = useState("");
    const [versionsLookback, setVersionsLookback] = useState(0);
    const [outputFile, setOutputFile] = useState("");

    const inputRef = useRef < HTMLInputElement > null;

    ///////////////////////////////////////////////////////////////////////////////////
    //  isAlphaNumeric: verify that the string consists of alphanumeric characters
    //
    //  Input:
    //    str: the string to be verified
    //
    //  Output: true / false
    //
    ///////////////////////////////////////////////////////////////////////////////////

    function isAlphaNumeric(str) {
        var code, i, len;

        for (i = 0, len = str.length; i < len; i++) {
            code = str.charCodeAt(i);
            if (
                !(code > 47 && code < 58) && // numeric (0-9)
                !(code > 64 && code < 91) && // upper alpha (A-Z)
                !(code > 96 && code < 123)
            ) {
                // lower alpha (a-z)
                return false;
            }
        }
        return true;
    }

    function handleCreateChange(event) {
        if (event.target.checked) {
            setCheckboxCreate(true);
        } else {
            setCheckboxCreate(false);
        }
    }

    function handleBatchProcess() {
        setDisplayMessage({
            messageCode: null,
            messageText: "Processing...",
            displayCode: 1,
        });

        let formData = new FormData();
        formData.append("file", csv);
        formData.append("mode", checkboxCreate ? "create" : "doNotCreate");
        formData.append("knowledgeBase", currentKnowledgeBase);
        formData.append("versionsLookback", versionsLookback);
        formData.append("outputFile", outputFile);

        var obj = {};

        obj.mode = checkboxCreate;
        obj.file = csv;

        var endpoint = process.env.REACT_APP_FLASK_API + "batchProcess";
        fetch(endpoint, {
            method: "POST",
            headers: {
                Authorization: "Bearer " + token,
                ContentType: "multipart/form-data",
            },
            body: formData,
        })
            .then((res) => res.json())
            .then((responseJson) => {
                // Display a message for the result of the transaction
                setDisplayMessage(Messages(responseJson["message"]));

                // Trigger reload of cases in CaseContext
                // setReloadCaseList(!reloadCaseList);
            });
    }

    const findFormErrors = () => {
        const newErrors = {};

        if (typeof csv == "undefined" || !csv || csv === "")
            newErrors.csv = "Der skal vælge en fil";
        else if (!isAlphaNumeric(csv))
            newErrors.csv = "Filnavnet skal være alpha-numerisk";

        return newErrors;
    };

    function handleSubmitBatchProcess(e) {
        e.preventDefault();

        // Get our new errors
        const newErrors = findFormErrors();
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors);
        } else {
            // No errors! Put any logic here for the form submission!

            handleBatchProcess();
        }
    }

    const handleUpload = () => {
        inputRef.current?.click();
    };

    function handleFileChange(e) {
        console.log("BatchProcessPageMain - triggered");
        setCsv(e.target.files[0]);
    }

    function handleOutputFileChange(e) {
        setOutputFile(e.target.value);
    }

    function handleVersionLookbackChange(e) {
        setVersionsLookback(e.target.value);
        // setVersionsLookback(7);
    }

    return (
        <div className={styles.standardPage}>
            <div className={styles.standardPageTop}>
                <Navbar />
            </div>

            <div className={styles.standardPageMiddle}>
                <div className={styles.batchProcessMiddle}>

                    <div className={styles.batchProcessHelpText}>
                        <p className={styles.modalHelpText}>
                            {t2("batchProcessHelp", 'sysadmin')}
                        </p>
                    </div>


                    <div className={styles.batchProcessInput}>
                        <div className={[styles.flexColumn, styles.batchProcessColumn2].join(' ')}>
                            <div className={styles.labelText1}>
                                {t2('inputFile', 'sysadmin')}:
                            </div>

                            <input
                                className={styles.filePicker}
                                type="file"
                                name="file"
                                onChange={(e) => handleFileChange(e)}
                            />

                            <div className={styles.labelText1}>
                                {t2('noVersionsToCheck', 'sysadmin')}:
                            </div>

                            <input
                                className={styles.numberInput}
                                type="number"
                                name="versionsLookback"
                                onChange={handleVersionLookbackChange}
                            />

                            <div className={styles.labelText1}>
                                {t2('outputFile', 'sysadmin')}:
                            </div>


                            <input
                                id={styles.batchProcessOutputFile}
                                className={styles.outputFile}
                                type="text"
                                name="outputFile"
                                onChange={handleOutputFileChange}
                            />
                        </div>

                        <Button
                            className={[styles.standardButton, styles.batchProcessButton].join(' ')}
                                variant="success"
                                size="sm"
                                onClick={handleSubmitBatchProcess}
                            >
                                {t2("process", "sysadmin")}
                        </Button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BatchProcessPageMain;
