import React, { useState, useEffect, useContext } from "react";

import { GrCircleQuestion } from "react-icons/gr";

// See: https://react-bootstrap-v3.netlify.app/components/tooltips/
// ...and: https://react-bootstrap.github.io/components/overlays/#overlay-props
import { Tooltip } from "react-bootstrap";
import { OverlayTrigger } from "react-bootstrap";

import { evaluationContext } from "../../contexts/EvaluationContext";
import { displayContext } from "../../../common/DisplayContext";

import styles from '../../styles.module.css';

// import { domainContext } from "../../contexts/DomainContext";

// import { useTranslation } from "react-i18next";
// import "../../translations/i18n";

import { Container, Form, Row, Col } from "react-bootstrap";

///////////////////////////////////////////////////////////////////////////////////
//
// Show the filter checkboxes for the explanation. These determine which rules
// are being shown in the explanation, and whether in short or long form.
//
///////////////////////////////////////////////////////////////////////////////////

function ResultExplanationFilter() {
    // const { t } = useTranslation();

    const [explanationTrue, setExplanationTrue] = useState([]);
    const [explanationFalse, setExplanationFalse] = useState([]);

    const { explanation, setExplanationFiltered } =
        useContext(evaluationContext);

    const {
        checkboxFalse,
        checkboxFormula,
        checkboxTrue,
        setCheckboxFalse,
        setCheckboxFormula,
        setCheckboxTrue,
        setModalRuleHelp,
        t,
        useMode,
        overlayTriggerHelp,
    } = useContext(displayContext);

    // const { currentKnowledgeBase } = useContext(domainContext);

    /////////////////////////////////////////////////////////////////////////////////////////////
    // USEEFFECT
    /////////////////////////////////////////////////////////////////////////////////////////////
    useEffect(() => {
        var explanationTmp = [];

        explanation.forEach((expl) => {
            if (expl.displayCode === 0) {
                explanationTmp.push(expl);
            }
        });
        setExplanationTrue(explanationTmp);
    }, [explanation]);

    useEffect(() => {
        var explanationTmp = [];
        explanation.forEach((expl) => {
            if (expl.displayCode === 1) {
                explanationTmp.push(expl);
            }
        });
        setExplanationFalse(explanationTmp);
    }, [explanation]);

    // When we enter this function, i.e. when the user has clicked 'classify', then we need
    // to display the explanation which is filtered according the 'true' and 'false' checkboxes.
    // The state variables 'explanationTrue' and 'explanationFase' set in the useEffects above
    // are not ready, and so we have to also create them here - as explanationTrueTmp and explanationFalseTmp.
    useEffect(() => {
        // Extract the trues, because the state variable explanationTrue is not ready when first entering the function
        var explanationTrueTmp = [];
        explanation.forEach((expl) => {
            if (expl.displayCode === 0) {
                explanationTrueTmp.push(expl);
            }
        });

        var explanationFalseTmp = [];
        explanation.forEach((expl) => {
            if (expl.displayCode === 1) {
                explanationFalseTmp.push(expl);
            }
        });

        if (checkboxTrue && checkboxFalse) {
            setExplanationFiltered(explanation);
        } else if (checkboxTrue && !checkboxFalse) {
            setExplanationFiltered(explanationTrueTmp);
        } else if (!checkboxTrue && checkboxFalse) {
            setExplanationFiltered(explanationFalseTmp);
        } else if (!checkboxTrue && !checkboxFalse) {
            setExplanationFiltered([]);
        }
    }, [explanation]);

    ///////////////////////////////////////////////////////////////////////////////////
    //  handleFormulaChange: handle when the user clicks the checkbox determining whether
    //  the explanation should be displayed in short or long form
    //
    //  Input: the form event
    //
    //  Output: N/A
    //
    ///////////////////////////////////////////////////////////////////////////////////

    function handleFormulaChange(event) {
        if (event.target.checked) {
            setCheckboxFormula(true);
        } else {
            setCheckboxFormula(false);
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////
    //  handleTrueChange: handle when the user clicks the checkbox determining whether
    //  to show the explanation for nodes that evaluated to true
    //
    //  Input: the form event
    //
    //  Output: N/A
    //
    ///////////////////////////////////////////////////////////////////////////////////

    function handleTrueChange(event) {
        if (event.target.checked) {
            setCheckboxTrue(true);
            if (!checkboxFalse) {
                setExplanationFiltered(explanationTrue);
            } else if (checkboxFalse) {
                setExplanationFiltered(explanation);
            }
        } else {
            setCheckboxTrue(false);
            if (!checkboxFalse) {
                setExplanationFiltered([]);
            } else if (checkboxFalse) {
                setExplanationFiltered(explanationFalse);
            }
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////
    //  handleFalseChange: handle when the user clicks the checkbox determining whether
    //  to show the explanation for nodes that evaluated to false
    //
    //  Input: the form event
    //
    //  Output: N/A
    //
    ///////////////////////////////////////////////////////////////////////////////////

    function handleFalseChange(event) {
        if (event.target.checked) {
            setCheckboxFalse(true);
            if (!checkboxTrue) {
                setExplanationFiltered(explanationFalse);
            } else if (checkboxTrue) {
                setExplanationFiltered(explanation);
            }
        } else {
            setCheckboxFalse(false);
            if (!checkboxTrue) {
                setExplanationFiltered([]);
            } else if (checkboxTrue) {
                setExplanationFiltered(explanationTrue);
            }
        }
    }

    function handleRuleHelp() {
        setModalRuleHelp(true);
    }

    // {t("ruleHelp", { ns: currentKnowledgeBase })}

    // function t1(key) {
    //     return t(key, { ns: currentKnowledgeBase });
    // }

    return (
        // <p className={styles.mentorBoxLabel}>
        <div className={styles.labelText}>
            <OverlayTrigger
                trigger={overlayTriggerHelp ? ["click", "hover", "focus"] : []}
                delay={400}
                delayHide={0}
                overlay={<Tooltip>{t("ruleHelp")}</Tooltip>}
            >
                <a>{t("explanationLabel")}</a>
            </OverlayTrigger>

            <span style={{ float: "right" }}>
                <label style={{ marginRight: "10px" }}>
                    <input
                        style={{ marginRight: "5px" }}
                        inline
                        type="checkbox"
                        id={`inline-$"checkbox"-1`}
                        checked={checkboxFormula}
                        onChange={handleFormulaChange}
                    // className="checkbox"
                    />
                    {t("shortForm")}
                </label>

                <label style={{ marginRight: "10px" }}>
                    <input
                        style={{ marginRight: "5px" }}
                        inline
                        label={t("true")}
                        type="checkbox"
                        id={`inline-$"checkbox"-2`}
                        checked={checkboxTrue}
                        onChange={handleTrueChange}
                    // className="checkbox"
                    />
                    {t("true")}
                </label>

                <label>
                    <input
                        style={{ marginRight: "5px" }}
                        inline
                        label={t("false")}
                        type="checkbox"
                        id={`inline-$"checkbox"-3`}
                        checked={checkboxFalse}
                        onChange={handleFalseChange}
                    // className="checkbox"
                    />
                    {t("false")}
                </label>
            </span>
        </div>
    );
}

export default ResultExplanationFilter;
