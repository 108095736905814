import React, { useEffect, useContext } from "react";
import Messages from "../Messages.js";

import { Button, Form, InputGroup, ButtonGroup } from "react-bootstrap";

import { domainContext } from "../../contexts/DomainContext";
import { domainsContext } from "../../contexts/DomainsContext";
import { evaluationContext } from "../../contexts/EvaluationContext";
import { displayContext } from "../../../common/DisplayContext.js";
import { errorContext } from "../../contexts/ErrorContext";
import { userCaseContext } from "../../contexts/UserCaseContext";
// import { TokenContext } from "../../contexts/TokenContext.js";

import styles from '../../styles.module.css';

import useAuth from "../../hooks/useAuth.js";
// import { Config } from "../../../config.js";

///////////////////////////////////////////////////////////////////////////////////
//
///////////////////////////////////////////////////////////////////////////////////

function ButtonSaveKnowledgeBase() {
    // const { token } = useContext(TokenContext);
    // const { token } = useAuth();

    const { token, username } = useAuth();

    const { 
        setDisplayMessage, 
        t,
    } = useContext(displayContext);

    const { setErrors } = useContext(errorContext);

    const { newKnowledgeBase } = useContext(domainContext);
    const {
        realoadDomains,
        setReloadDomains,
        reloadKnowledgeBases,
        setReloadKnowledgeBases,
        setTrigger,
    } = useContext(domainsContext);

    ///////////////////////////////////////////////////////////////////////////////////
    //
    ///////////////////////////////////////////////////////////////////////////////////

    function isAlphaNumeric(str) {
        var code, i, len;

        for (i = 0, len = str.length; i < len; i++) {
            code = str.charCodeAt(i);
            if (
                !(code > 47 && code < 58) && // numeric (0-9)
                !(code > 64 && code < 91) && // upper alpha (A-Z)
                !(code > 96 && code < 123)
            ) {
                // lower alpha (a-z)
                return false;
            }
        }
        return true;
    }

    const findFormErrors = () => {
        const newErrors = {};

        if (
            typeof newKnowledgeBase == "undefined" ||
            !newKnowledgeBase ||
            newKnowledgeBase === ""
        )
            newErrors.newKnowledgeBase = "NewKnowledgeBase må ikke være tom";
        else if (!isAlphaNumeric(newKnowledgeBase))
            newErrors.newKnowledgeBase =
                "NewKnowledgeBase skal være alpha-numerisk";

        return newErrors;
    };

    function handleSubmitSaveKnowledgeBase(e) {

        setTrigger(true);

        e.preventDefault();

        // Get our new errors
        const newErrors = findFormErrors();
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors);
        } else {
            // No errors! Put any logic here for the form submission!

            handleSaveKnowledgeBase();
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////
    //
    ///////////////////////////////////////////////////////////////////////////////////

    function handleSaveKnowledgeBase() {
        setDisplayMessage({
            messageCode: null,
            messageText: "Processing...",
            // messageText: t('processing'),
            displayCode: 0,
        });

        var obj = {};

        obj.knowledgeBase = newKnowledgeBase;
        obj.username = username;

        var endpoint = process.env.REACT_APP_FLASK_API + "createKnowledgeBase";
        fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(obj),
        })
            .then((res) => res.json())
            .then((responseJson) => {
                // Display a message for the result of the transaction
                setDisplayMessage(Messages(responseJson["message"]));
                // This will trigger the backend function getDomainNames in DomainsContext to reload domains from the backend
                setReloadDomains(realoadDomains + 1);
                // Trigger reload of knowledge base names for the settings menu. The reload happens in domainsContext.
                setReloadKnowledgeBases(reloadKnowledgeBases + 1);
            });

       
    }

    // Save knowledge base button
    return (
        <Button
            // className="ms-1 me-1"
            className={styles.standardButton}
            variant="success"
            size="sm"
            onClick={handleSubmitSaveKnowledgeBase}
        >
            {t("create")}
        </Button>
    );
}

export default ButtonSaveKnowledgeBase;
