import React, { useContext, useEffect } from 'react';

import { domainContext } from '../../contexts/DomainContext';
import { userCaseContext } from '../../contexts/UserCaseContext';
import { displayContext } from '../../../common/DisplayContext';
import { knowledgeContext } from '../../contexts/KnowledgeContext';

import styles from '../../styles.module.css';

import { useNavigate, useLocation } from 'react-router-dom';

import HeaderLoggedIn from '../Header/HeaderLoggedIn';
import NavigationSideBar from '../Navigation/NavigationSideBar';
import EvaluateCaseMainSC from './EvaluateCaseMainSC';
// import EvaluateCaseMainMC from './EvaluateCaseMainMC';
import EvaluateCaseMainMC from '../RejectedCase/EvaluateCaseMainMC';

import Footer from '../Footer';

///////////////////////////////////////////////////////////////////////////////////
//
///////////////////////////////////////////////////////////////////////////////////

function EvaluateCasePage() {
    const { setDisplayMessage, previousPath, setUseMode, setCurrentPage } =
        useContext(displayContext);

    setCurrentPage('evaluate');

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        setDisplayMessage('');
    }, [location.key]);

    const { currentDomain, setCurrentDomain } = useContext(domainContext);

    const { userCase, setUserCase, setCaseStatus } =
        useContext(userCaseContext);

    const { knowledgebaseType } = useContext(knowledgeContext);

    // Reset buttons

    if (userCase == []) {
        setCurrentDomain(currentDomain);
    }

    if (previousPath == '/learn') {
        setUseMode('read');
    }

    return (
        <div className={styles.page}>
            <div className={styles.pageMain}>
                <NavigationSideBar />

                {knowledgebaseType == 'sc' && <EvaluateCaseMainSC />}

                {knowledgebaseType == 'mc' && <EvaluateCaseMainMC />}
            </div>
        </div>
    );
}

export default EvaluateCasePage;
