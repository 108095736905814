import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

// import KnowledgeContextProvider from "../../contexts/KnowledgeContext";

import { displayContext } from "../../../common/DisplayContext";
import { knowledgeContext } from "../../contexts/KnowledgeContext";

import styles from '../../styles.module.css';

import KnowledgeBrowserButtonTopbar from "./KnowledgeBrowserButtonTopbar";
import RuleMain from "./RuleMain";
import RuleTreeMain from "./RuleTreeMain";
import RuleGraph from "./RuleGraph";

import CaseMain from "./CaseMain";
import AttributeMain from "./AttributeMain";

import HeaderLoggedIn from "../Header/HeaderLoggedIn";
import NavigationSideBar from "../Navigation/NavigationSideBar";
import Navbar from "../Settings/Navbar";

function KnowledgeBrowserMain() {
    const {
        setDisplayMessage
    } = useContext(displayContext);

    const {
        activeButton,
    } = useContext(knowledgeContext);

    let location = useLocation();

    useEffect(() => {
        setDisplayMessage("");
    }, [location.key]);

    return (
        <div className={styles.standardPage}>
            <div className={styles.standardPageTop}>
                <div>
                    <Navbar />
                </div>

                <div className={styles.knowledgeBrowserPageMainBox}>
                    <KnowledgeBrowserButtonTopbar />                           
                </div>
            </div>

            <div className={styles.standardPageMiddle}>
                {activeButton == 'ruleTree' && <RuleTreeMain />}
                {activeButton == 'rules' && <RuleMain />}
                {activeButton == 'cases' && <CaseMain />}
                {activeButton == 'attributes' && <AttributeMain />}
                {activeButton == 'ruleGraph' && <RuleGraph />}                
            </div>
            
        </div>
    );
}

export default KnowledgeBrowserMain;
