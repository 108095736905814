import { useTheme } from "@table-library/react-table-library/theme";

function useReactTableTheme() {
    const baseTheme = {
        Table: `
            height: 100%;
            align-content: baseline;
        `,
        BaseRow: `
            font-size: 14px;
        `,
        HeaderRow: `
            font-size: 14px;

            .th {
            font-weight: normal !important;
            border-width: 0px;
            border-bottom: 0px;
            box-shadow: 0px 0px 0px 0px rgba(0,0,0,0)
            }
        `,
        Row: `
            &:nth-child(odd) {
            background-color: #C7C7C7;
            }

            &:nth-child(even) {
            background-color: #F2F2F2;
            }

            cursor: pointer;

            &:hover .td {
            background-color: yellow !important;
            }

        `,
        HeaderCell: `
            padding-left: 10px !important;
            `,
        Cell: `
            padding-left: 10px !important;
            text-align: left; 
            background-color: inherit !important;
        `,
        BaseCell: `
            padding-left: 0px;
            padding-top: 16px;
            padding-bottom: 16px;
        `,
    };

    const adaptedThemeOverrides = {
        Row: `
        &:nth-child(odd) {
            background-color: red;
        }
        
        &:nth-child(even) {
            background-color: blue;
        }
        
        cursor: pointer;
        
        &:hover .td {
            background-color: yellow;
        }
        
        `,
    };

    //   export const defaultTheme = useTheme(baseTheme);

    //   export const adaptedTheme = useTheme({
    //       ...baseTheme,
    //       ...adaptedThemeOverrides,
    //   });

    return [
        {
            defaultTheme: useTheme(baseTheme),
            adaptedTheme: useTheme({
                ...baseTheme,
                ...adaptedThemeOverrides,
            }),
        },
    ];
}

export default useReactTableTheme;
