import React, { useContext } from "react";

// import { displayContext } from "../../contexts/DisplayContext";

import styles from '../../styles.module.css';

// import { Dropdown } from "react-bootstrap";
// import DropdownToggle from "react-bootstrap/esm/DropdownToggle";
// import DropdownMenu from "react-bootstrap/esm/DropdownMenu";

// import LanguageSelect from "../Navigation/LanguageSelect";
// import InputSetSelect from "../Navigation/InputSetSelect";
// import Settings from "./Settings";
import Navbar from "../Settings/Navbar";
import DisplayMessage from "./DisplayMessage";

// import useAuth from "../../hooks/useAuth";

function HeaderLoggedIn() {
    // const { 
    //     t, 
    //     setDisplayMessage 
    // } = useContext(displayContext);

    // const { token, onLogout } = useAuth();

    return (
        <>
            <div className={styles.header}>
                <div className={styles.mentorTitle}>
                    MENTOR<span className={styles.mentorBar}>I</span>
                    <span className={styles.mentorByline}>
                        Unbiased & explainable decision making using AI
                    </span>
                </div>

                <DisplayMessage />

                <Navbar />

            </div>
        </>
    );
}

export default HeaderLoggedIn;
