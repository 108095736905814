import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';

import { evaluationContext } from '../../contexts/EvaluationContext';
import { displayContext } from '../../../common/DisplayContext';
import { errorContext } from '../../contexts/ErrorContext';
import { navigationContext } from '../../contexts/NavigationContext';

import styles from '../../styles.module.css';

import { Button } from 'react-bootstrap';
import { IoMdSearch } from 'react-icons/io';

import ButtonSearchCase from './ButtonSearchCase';

function Caseid() {
    const { t2 } = useContext(displayContext);

    const { page } = useContext(navigationContext);

    const { errors } = useContext(errorContext);

    const { currentCaseId, setCurrentCaseId } = useContext(evaluationContext);

    function handleChangeCaseId(e) {
        setCurrentCaseId(e.target.value);
        delete errors.currentCaseId;
    }

    return (
        <>
            <div
                className={
                    page == 'addNodeMC'
                        ? styles.caseIdBoxDimmed
                        : styles.caseIdBox
                }
            >
                <ButtonSearchCase />
                <input
                    id="caseno"
                    name="caseno"
                    placeholder={t2('caseNo', 'sysadmin')}
                    type="text"
                    value={currentCaseId}
                    onChange={(e) => handleChangeCaseId(e)}
                    as="input"
                    htmlSize="10"
                    isInvalid={!!errors.currentCaseId}
                />
            </div>
        </>
    );
}

export default Caseid;
