import React from 'react';

import VlfrdContextProvider from '../contexts/Context';

import styles from '../styles.module.css';

import Topbar from './Topbar';
import Main from './Main';
import Bottombar from './Bottombar';

function Vlfrdmc() {
    return (
        <VlfrdContextProvider>
            <div className={[styles.desktopContainer, styles.desktopFont].join(' ')}>

                <Topbar />

                <Main />

                <Bottombar />

            </div >
        </VlfrdContextProvider >
    )
};

export default Vlfrdmc;
