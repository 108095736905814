import React, { useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";

// Flags: https://www.npmjs.com/package/flag-icons
import "/node_modules/flag-icons/css/flag-icons.min.css";

import { displayContext } from "../../common/DisplayContext";
import { UserContext } from "../../admin/contexts/UserContext";

import ButtonLogin from "./ButtonLogin";
import ButtonLogout from "./ButtonLogout";
import ButtonSelectLanguage from "./ButtonSelectLanguage";


function Header() {
    const { t2 } = useContext(displayContext);

    let navigate = useNavigate();

    const { loggedIn } = useContext(UserContext);

    function HandleGoHome(e) {
        navigate("/TM1");
    }

    return (
        <>
            <div
                className="vm-grid-header-left-center"
                style={{ height: "10vh" }}
            >
                <div onClick={HandleGoHome} style={{ cursor: "pointer" }}>
                    <h3 className="vm-servicename">
                        {t2("serviceName", "varemaerke")}
                    </h3>
                </div>
                {/* <div style={{ display: "flex" }}>
                    {loggedIn ? <ButtonLogout /> : <ButtonLogin />}
                    <ButtonSelectLanguage />
                </div> */}
            </div>
        </>
    );
}

export default Header;
