import React, { useEffect, useContext, useState } from "react";
import { evaluationContext } from "../../contexts/EvaluationContext";
import { displayContext } from "../../../common/DisplayContext";
import { errorContext } from "../../contexts/ErrorContext";
import { attributesContext } from "../../contexts/AttributesContext";

import styles from '../../styles.module.css';

import useTranslateRuleToEnglish from "../../hooks/useTranslateRuleToEnglish";
import NewRule from "./NewRule";

import { Form } from "react-bootstrap";

import ResultExplanationFilter from "./ResultRuleTraceFilter";

import {
    Table,
    Body,
    Row,
    Cell,
} from "@table-library/react-table-library/table";

import { useTheme } from "@table-library/react-table-library/theme";

import { BsCheck, BsX } from "react-icons/bs";

///////////////////////////////////////////////////////////////////////////////////
//
//  ResultExplanation: show the explanation in temrrules
//
///////////////////////////////////////////////////////////////////////////////////

function ResultRuleNew() {
    //     {
    //     handleAccept,
    //     handleSaveNewRule,
    //     buttonSaveNewRuleDisabled,
    //     t,
    // }
    //const { t } = useTranslation();

    // See: https://www.pluralsight.com/guides/event-listeners-in-react-components
    // useEffect(() => {
    //     window.addEventListener('keydown', (event) => {
    //         console.log('ResultExplanation - the event listener fired')
    //         console.log('ResultExplanation - newRule.value: ', document.getElementById("newRule1").value)
    //         }
    //     );
    // }, []);

    const theme = useTheme({
        Table: `
      height: 100%;
	  align-content: baseline;
      background-color: blue;
    `,
        BaseRow: `
      font-size: 14px;
    `,
        HeaderRow: `
      background-color: #FAFAFA;
	  font-size: 14px;
	  
	  .th {
		font-weight: normal !important;
	  }
    `,
        Row: `
		  &:nth-child(odd) {
			background-color: #C7C7C7;
		  }
	
		  &:nth-child(even) {
			background-color: #F2F2F2;
		  }
	
		  cursor: pointer;
	
		  &:hover .td {
			background-color: yellow;
		  }





        `,
        HeaderCell: `
    `,
        Cell: `
      padding-left: 10px !important;
	  text-align: left; 
    `,
        BaseCell: `
      padding-left: 10px !important;
      padding-top: 0px !important;
      padding-bottom: 0px !important;
    `,
    });

    // const [explanationTrue, setExplanationTrue] = useState([]);
    // const [explanationFalse, setExplanationFalse] = useState([]);

    const { explanation, explanationFiltered, setExplanationFiltered } =
        useContext(evaluationContext);

    const {
        // setCheckboxFalse,
        // setCheckboxTrue,
        checkboxFalse,
        checkboxFormula,
        checkboxTrue,
        newRule,
        setButtonSaveNewRuleDisabled,
        setNewRule,
        t,
        useMode,
        textAreaNewRuleDisabled,
    } = useContext(displayContext);

    const { currentAttributes } = useContext(attributesContext);

    const { errors } = useContext(errorContext);

    const [{ translateRuleToEnglish }] = useTranslateRuleToEnglish();

    const [text, setText] = useState(
        "<span style= 'color: green' >Dette er min tekst</span>"
    );

    function getMarkup() {
        // return { __html: "<h3>My Content here..</h3>" }; // The HTML string you want to set
        return { __html: text }; // The HTML string you want to set
    }

    /////////////////////////////////////////////////////////////////////////////////////////////
    // USEEFFECT
    /////////////////////////////////////////////////////////////////////////////////////////////
    // useEffect(() => {
    //     var explanationTmp = [];

    //     explanation.forEach((expl) => {
    //         if (expl.displayCode === 0) {
    //             explanationTmp.push(expl);
    //         }
    //     });
    //     setExplanationTrue(explanationTmp);
    // }, [explanation]);

    // useEffect(() => {
    //     var explanationTmp = [];
    //     explanation.forEach((expl) => {
    //         if (expl.displayCode === 1) {
    //             explanationTmp.push(expl);
    //         }
    //     });
    //     setExplanationFalse(explanationTmp);
    // }, [explanation]);

    // When we enter this function, i.e. when the user has clicked 'classify', then we need
    // to display the explanation which is filtered according the 'true' and 'false' checkboxes.
    // The state variables 'explanationTrue' and 'explanationFase' set in the useEffects above
    // are not ready, and so we have to also create them here - as explanationTrueTmp and explanationFalseTmp.
    useEffect(() => {
        // Extract the trues, because the state variable explanationTrue is not ready when first entering the function
        var explanationTrueTmp = [];
        explanation.forEach((expl) => {
            if (expl.displayCode === 0) {
                explanationTrueTmp.push(expl);
            }
        });

        var explanationFalseTmp = [];
        explanation.forEach((expl) => {
            if (expl.displayCode === 1) {
                explanationFalseTmp.push(expl);
            }
        });

        if (checkboxTrue && checkboxFalse) {
            setExplanationFiltered(explanation);
        } else if (checkboxTrue && !checkboxFalse) {
            setExplanationFiltered(explanationTrueTmp);
        } else if (!checkboxTrue && checkboxFalse) {
            setExplanationFiltered(explanationFalseTmp);
        } else if (!checkboxTrue && !checkboxFalse) {
            setExplanationFiltered([]);
        }
    }, [explanation]);

    const data = { nodes: explanationFiltered };

    // Create a list of operators allowed in the new rule
    const operators = [
        ">",
        ">=",
        "=",
        "<=",
        "<",
        "!=",
        "and",
        "or",
        "(",
        ")",
        "og",
        "eller",
        "ja",
        "nej",
        "yes",
        "no",
    ];

    // Create a list of attributes allowed in the new rule
    const attributes = [];
    currentAttributes.map((attribute) => {
        attributes.push(attribute.name);
    });

    // const REGEX = /({{.*?}})/g;

    function handleRuleChange(e) {
        setButtonSaveNewRuleDisabled(false);

        // Keep the rule in the local language and make a translation
        let localRule = e.target.value;
        let translatedRule = translateRuleToEnglish(localRule);

        // See: https://blog.abelotech.com/posts/split-string-into-tokens-javascript/
        let ruleTokens = translatedRule.match(/\S+/g);

        // set error... maybe use findformerror...
        let errorInRule = false;

        console.log("ResultExplanation - localRule: ", localRule);
        if (ruleTokens) {
            ruleTokens.map((token) => {
                if (
                    !(operators.includes(token) || attributes.includes(token))
                ) {
                    errorInRule = true;
                    // localRule = translate(localRule, token, "<a>hundehoved</a>");
                }
            });
        }

        setNewRule(e.target.value);
    }

    // Translate rules to using 'yes' and 'no' instead of other languages words for yes and no
    function translate(str, replaceWhat, replaceTo) {
        var re = new RegExp(replaceWhat, "g");
        return str.replace(re, replaceTo);
    }

    function translateExplanationToLocal(explanation) {
        let translatedExplanation = translate(explanation, "no", t("no"));
        translatedExplanation = translate(
            translatedExplanation,
            "yes",
            t("yes")
        );
        translatedExplanation = translate(
            translatedExplanation,
            "and",
            t("and")
        );
        translatedExplanation = translate(translatedExplanation, "or", t("or"));

        return translatedExplanation;
    }

    return (
        <div style={{ width: "100%" }}>
            <div
                className={styles.explanationRuleNew}
            >
                {(useMode === "write" || useMode == "learn") && (
                    <div
                        className={
                            textAreaNewRuleDisabled
                                ? "text-area-new-rule-disabled"
                                : "text-area-new-rule-enabled"
                        }
                    >
                        <NewRule />
                    </div>
                )}
            </div>
            {errors.newRule && (
                <div
                    style={{
                        width: "400px",
                        padding: "10px",
                    }}
                >
                    <Form.Control
                        type="text"
                        placeholder="You must enter a new rule"
                    />
                </div>
            )}
        </div>
    );
}

export default ResultRuleNew;
