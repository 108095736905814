import React, { useContext } from "react";

import { AiFillForward, AiFillBackward } from "react-icons/ai";

import "../../../App.css";

function ForwardBackward(ForwardFunction, BackwardFunction) {
    return (
        <>
            <div
                style={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "center",
                    marginTop: "10px",
                }}
            >
                <div
                    className="tm1-intensity-backward"
                    onClick={BackwardFunction}
                >
                    <AiFillBackward size={25} />
                </div>
                <div
                    className="tm1-intensity-forward"
                    onClick={ForwardFunction}
                >
                    <AiFillForward size={25} />
                </div>
            </div>
        </>
    );
}

export default ForwardBackward;
