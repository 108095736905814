import React, { useContext } from 'react';

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { domainContext } from '../../contexts/DomainContext.js';
import { evaluationContext } from '../../contexts/EvaluationContext.js';
import { displayContext } from '../../../common/DisplayContext.js';
import { errorContext } from '../../contexts/ErrorContext.js';
import { userCaseContext } from '../../contexts/UserCaseContext.js';
import { rejectedCaseContext } from '../../contexts/CaseContext.js';

import useAuth from '../../hooks/useAuth.js';
import useAddNodeMC from '../../hooks/useAddNodeMC.js';

import styles from '../../styles.module.css';

import Messages from '../Messages.js';
import NewConclusionGroup from './NewConclusionGroup.js';

function ButtonAddNode() {
    const { userCase } = useContext(userCaseContext);

    const {
        setIsLoading,
        t,
        t2,
        buttonAddNodeDisabled,
        setButtonAddNodeDisabled,
        newConclusion,
        newConclusionGroup,
        newExplanation,
        newNote,
        newRule,
    } = useContext(displayContext);

    const { currentKnowledgeBase, currentDomain } = useContext(domainContext);

    const { currentCaseId, nodeLastTrue, setReturnCode } =
        useContext(evaluationContext);

    const { setErrors } = useContext(errorContext);

    const {
        reloadRejectedCaseList,
        setReloadRejectedCaseList,
        reloadCaseList,
        setReloadCaseList,
    } = useContext(rejectedCaseContext);

    const { token } = useAuth();
    const addNode = useAddNodeMC();

    // Error handling start

    const findFormErrors = () => {
        const newErrors = {};

        if (
            typeof currentCaseId == 'undefined' ||
            !currentCaseId ||
            currentCaseId === ''
        )
            newErrors.currentCaseId = 'case no må ikke være tom';

        if (
            typeof currentCaseId !== 'undefined' &&
            currentCaseId &&
            currentCaseId !== '' &&
            currentCaseId.length > 30
        )
            newErrors.currentCaseId =
                'Case no skal være mindre end 30 tegn langt';

        if (
            typeof newConclusion == 'undefined' ||
            !newConclusion ||
            newConclusion === ''
        )
            newErrors.newConclusion = t2('newConclusionMissing', 'sysadmin');

        if (
            typeof newExplanation == 'undefined' ||
            !newExplanation ||
            newExplanation === ''
        )
            newErrors.newExplanation = t2('newExplanationMissing', 'sysadmin');

        if (typeof newRule == 'undefined' || !newRule || newRule === '')
            newErrors.newRule = t2('newRuleMissing', 'sysadmin');

        return newErrors;
    };

    function submitAcceptHandler(e) {
        e.preventDefault();

        setErrors('errors');
        // Get our new errors
        const newErrors = findFormErrors();
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors);
        } else {
            // No errors! Put any logic here for the form submission!

            // handleAccept();
            addNode(
                newConclusion,
                newConclusionGroup,
                newExplanation,
                newNote,
                newRule,
            );
        }
    }
    //   Error handling end

    return (
        <OverlayTrigger
            trigger={['click', 'hover', 'focus']}
            delay={400}
            delayHide={0}
            placement={'auto'}
            overlay={<Tooltip>{t('buttonAcceptTooltip')}</Tooltip>}
        >
            <Button
                className={styles.standardButton}
                variant="success"
                disabled={buttonAddNodeDisabled}
                onClick={submitAcceptHandler}
            >
                {t('add')}
            </Button>
        </OverlayTrigger>
    );
}

export default ButtonAddNode;
