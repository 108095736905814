import React, { useContext } from "react";
import { Form, Row } from "react-bootstrap";

// NPM page: https://www.npmjs.com/package/react-linkify
// See examples: http://tasti.github.io/react-linkify/
// import Linkify from "react-linkify";

// General info: https://linkify.js.org/docs/
// React specific info: https://linkify.js.org/docs/linkify-react.html
import Linkify from "linkify-react";

import { evaluationContext } from "../../contexts/EvaluationContext";
import { displayContext } from "../../../common/DisplayContext";
import { errorContext } from "../../contexts/ErrorContext";

import styles from '../../styles.module.css';

///////////////////////////////////////////////////////////////////////////////////
//
//  ResultConclusion: show the conclusion after evaluation
//
///////////////////////////////////////////////////////////////////////////////////

function Note() {
    const { conclusionLastTrue, explanationLastTrue, noteLastTrue } =
        useContext(evaluationContext);

    const {
        newConclusion,
        newExplanation,
        newLetterRuleFalse,
        newLetterRuleTrue,
        setButtonSaveNewRuleDisabled,
        setNewConclusion,
        setNewExplanation,
        setNewLetterRuleFalse,
        setNewLetterRuleTrue,
        t,
        useMode,
        newNote,
        setNewNote,
        textAreaNewNoteDisabled,
    } = useContext(displayContext);

    const { errors } = useContext(errorContext);

    function handleNoteChange(e) {
        setButtonSaveNewRuleDisabled(false);
        setNewNote(e.target.value);
    }

    const options = {
        /* … */
    };

    return (
        <div style={{ width: "100%" }}>
            {(useMode === "read" ||
                (useMode === "learn" && textAreaNewNoteDisabled)) && (
                    <>
                        <div className={styles.note1}>
                            <p className={styles.labelText}>{t("note")}</p>
                            <Linkify as="p" options={options}>
                                {noteLastTrue}
                            </Linkify>
                        </div>
                    </>
                )}

            {(useMode === "write" ||
                (useMode == "learn" && !textAreaNewNoteDisabled)) && (
                    <>
                        <div className={styles.note2}>
                            <p className={styles.labelText}>{t("note")}</p>
                            <textarea className={styles.noteTextArea}
                                name='newNote'
                                type="text"
                                value={newNote}
                                onChange={(e) => handleNoteChange(e)}
                                isInvalid={!!errors.newNote}
                            />
                        </div>

                        {errors.newExplanation && (
                            <div
                                style={{
                                    width: "400px",
                                    padding: "10px",
                                }}
                            >
                                <Form.Control
                                    type="text"
                                    placeholder="You must enter the note for this type of case"
                                />
                            </div>
                        )}
                    </>
                )}
        </div>
    );
}

export default Note;
