// See https://www.robinwieruch.de/react-table-component/ for the tutorial for creating this type of tables
// https://www.robinwieruch.de/categories/react-table-library/
// samples: https://react-table-library.com/?path=/story/getting-started--demo
// https://github.com/table-library/react-table-library
import React, { useEffect } from "react";

import {
    Table,
    Header,
    HeaderRow,
    HeaderCell,
    Body,
    Row,
    Cell,
} from "@table-library/react-table-library/table";

import {
    useSort,
    // HeaderCellSort,
} from "@table-library/react-table-library/sort";

import {
    // Sort,
    // SortOptions,
    // SortIconPositions,
    SortToggleType,
} from "@table-library/react-table-library/sort";

// import { defaultTheme } from "../../hooks/useReactTableTheme";
import useReactTableTheme from "../../hooks/useReactTableTheme";
import defaultTableTheme from "./DefaultTableTheme";

import { useTheme } from "@table-library/react-table-library/theme";

import { displayContext } from "../../../common/DisplayContext";
import { knowledgeContext } from "../../contexts/KnowledgeContext";
import { domainContext } from "../../contexts/DomainContext";
import { useContext, useState } from "react";

import useAuth from "../../hooks/useAuth";

import styles from '../../styles.module.css';
import { DefaultContext } from "react-icons";


function AttributeDetails() {

    const {
        currentAttributeName,
    } = useContext(knowledgeContext);

    const {
        t2,
    } = useContext(displayContext);

    const {
        currentKnowledgeBase,
        currentDomain,
    } = useContext(domainContext);

    const { token } = useAuth();

    const [attributeDetails, setAttributeDetails] = useState([]);


    useEffect(() => {
        var obj = {};

        if (token != null && currentKnowledgeBase != "noKnowledgeBase") {
            obj.knowledgeBase = currentKnowledgeBase;
            obj.domain = currentDomain;
            obj.attributeName = currentAttributeName;
            var endpoint = process.env.REACT_APP_FLASK_API + "getCaseAttributeDetails";

            const fetchData = async () => {
                try {
                    const response = await fetch(endpoint, {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                        },
                        body: JSON.stringify(obj),
                    })


                    const fetchedData = await response.json();

                    setAttributeDetails(fetchedData["attributeDetails"][0]);   // get the cases

                } catch (error) {
                    console.error('Error fetching data: ', error)
                }
            }
            fetchData();
        }
    }, [currentAttributeName]);


    function attributeDetail(property) {
        return (
            <div className={styles.lineColonCentered}>
                <div className={styles.lineColonCenteredBefore}>{t2(property, "sysadmin")}</div>
                :
                <div className={styles.lineColonCenteredAfter}>{attributeDetails[property]}</div>
            </div>
        )
    }

    defaultTableTheme.Table = `
        height: 100%; 
        width: 100%; 
        align-content: baseline; 
        --data-table-library_grid-template-columns: 30% 40%;
    `

    const theme = useTheme(defaultTableTheme)


    const list = [
        {
            name: 'navn',
            value: typeof (attributeDetails) != "undefined" ? attributeDetails.name : '',
        },
        {
            name: 'display name',
            value: typeof (attributeDetails) != "undefined" ? attributeDetails.displayname : '',
        },
        {
            name: 'type',
            value: typeof (attributeDetails) != "undefined" ? attributeDetails.type : '',
        },
        {
            name: 'normmin',
            value: typeof (attributeDetails) != "undefined" ? attributeDetails.normmin : '',
        },
        {
            name: 'normmax',
            value: typeof (attributeDetails) != "undefined" ? attributeDetails.normmax : '',
        },
        {
            name: 'userid',
            value: typeof (attributeDetails) != "undefined" ? attributeDetails.userid : '',
        },
        {
            name: 'created time',
            value: typeof (attributeDetails) != "undefined" ? attributeDetails.created_time : '',
        }
    ];


    const data = { nodes: list };

    return (

        <div className={styles.selectedRuleDetails}>
        <div className={styles.ruleDetails}>
        <div className={[styles.standardTableWrapper, styles.kbAttributesAttributeDetailsWrapper].join(' ')}>
            <div className={styles.boxHeading}>{t2("attributeDetails", "sysadmin")}</div>
            <Table 
                className={styles.standardTable2}
                data={data}
                theme={theme}
                layout={{custom: true}}
            >
                {(tableList) => (
                    <>
                        <Header>
                            <HeaderRow>
                                <HeaderCell resize>Name</HeaderCell>
                                <HeaderCell resize>Value</HeaderCell>
                            </HeaderRow>
                        </Header>

                        <Body>
                            {tableList.map((item) => (
                                <Row 
                                    key={item.name} 
                                        item={item}
                                    >
                                    <Cell>{item.name}</Cell>
                                    <Cell>{item.value}</Cell>          
                                </Row>
                            ))}
                        </Body>
                    </>

                )}
            </Table>    
                
            </div >
            </div >
            </div >
    );
}

export default AttributeDetails;
