import React, { useContext } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

import { displayContext } from "../../../common/DisplayContext";
import { domainContext } from "../../contexts/DomainContext";

import styles from '../../styles.module.css';

function ResultConclusionHeading() {
    const {
        t,
        overlayTriggerHelp,
    } = useContext(displayContext);
    const { currentKnowledgeBase, currentDomain } = useContext(domainContext);

    return (
        <>
            {/* <p className={styles.mentorBoxLabel}> */}
            <OverlayTrigger
                trigger={overlayTriggerHelp ? ["click", "hover", "focus"] : []}
                delay={400}
                delayHide={0}
                overlay={
                    <Tooltip>
                        {t("conclusionHelp")}
                    </Tooltip>
                }
            >
                <div className={styles.labelText}>{t("conclusion", { ns: currentKnowledgeBase })}</div>
            </OverlayTrigger>
            {/* </p> */}
        </>
    );
}

export default ResultConclusionHeading;
