import React, { useContext } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import { displayContext } from "../../../common/DisplayContext";
import useAuth from "../../../admin/hooks/useAuth";

import "../../../App.css";

function NavigationSideBarTM2() {
    const { t2, setDisplayMessage, setUseMode } = useContext(displayContext);
    const { userType, onLogout } = useAuth();

    let location = useLocation();

    // var menuItemActive = "";

    function handleMenuItemSelect(item) {
        // setDisplayMessage("");
        console.log("NavigationSideBar - handleMenuItmSelect");
    }

    function handleUnselectCase(item) {
        handleMenuItemSelect(item);
    }

    function getLiClass(path) {
        return location.pathname === path
            ? "sidebar1-item vm-active"
            : "sidebar1-item vm-inactive";
    }

    function getDivNavLinkClass(path) {
        return location.pathname === path
            ? "tm1-menu-bubble tm1-menu-bubble-enabled"
            : "tm1-menu-bubble tm1-menu-bubble-disabled";
    }

    function getMenuBubbleClass(path) {
        return location.pathname === path
            ? "tm1-menu-bubble tm1-menu-bubble-enabled"
            : "tm1-menu-bubble tm1-menu-bubble-disabled";
    }

    function getNavLinkClass(path) {
        return location.pathname.toLowerCase() === path.toLowerCase()
            ? "sidebar1-menu-text tm1-sidebar1-item-navlink-active"
            : "sidebar1-menu-text tm1-sidebar1-item-navlink-inactive";
    }

    return (
        <>
            <div
                className="tm1-nav-sidebar"
                id="nav-sidebar"
            // style={{ borderColor: "black", borderStyle: "solid" }}
            // style={{ width: "35vw" }}
            >
                {/* <div> */}
                <nav className="tm1-nav">
                    {/* Use */}
                    {/* <OverlayTrigger
                        trigger={["click", "hover", "focus"]}
                        delay={400}
                        delayHide={0}
                        placement={"auto"}
                        overlay={
                            <Tooltip>
                                {t2("tabVMUsePageTooltip", "varemaerke")}
                            </Tooltip>
                        }
                    > */}
                    <div className="tm1-menu-line">

                        <span className="tm1-menu-text">
                            <NavLink
                                id="navlink-time"
                                to="/TM1UsePage"
                                className="tm1-sidebar1-menu-text"
                            ><a
                                className={getMenuBubbleClass("/TM1UsePage")}
                            ></a>
                                {/* {t2("Tm1UsePage", "varemaerke")} */}
                                {t2("Tm1Step1", "varemaerke")}
                            </NavLink>
                        </span>
                    </div>
                    {/* </OverlayTrigger> */}

                    {/* Geography */}
                    {/* <OverlayTrigger
                        trigger={["click", "hover", "focus"]}
                        delay={400}
                        delayHide={0}
                        placement={"auto"}
                        overlay={
                            <Tooltip>
                                {t2("tabVMGeographyPageTooltip", "varemaerke")}
                            </Tooltip>
                        }
                    > */}
                    <div className="tm1-menu-line">

                        <span className="tm1-menu-text">
                            <NavLink
                                id="navlink-time"
                                to="/TM1GeographyPage"
                                className="tm1-sidebar1-menu-text"
                            > <a
                                className={getMenuBubbleClass(
                                    "/TM1GeographyPage"
                                )}
                            ></a>
                                {/* {t2("Tm1GeographyPage", "varemaerke")} */}
                                {t2("Tm1Step2", "varemaerke")}
                            </NavLink>
                        </span>
                    </div>
                    {/* </OverlayTrigger> */}

                    {/* Intensity */}
                    {/* <OverlayTrigger
                        trigger={["click", "hover", "focus"]}
                        delay={400}
                        delayHide={0}
                        placement={"auto"}
                        overlay={
                            <Tooltip>
                                {t2("tabVMIntensityPageTooltip", "varemaerke")}
                            </Tooltip>
                        }
                    > */}
                    <div className="tm1-menu-line">

                        <span className="tm1-menu-text">
                            <NavLink
                                id="navlink-intensity"
                                to="/TM1IntensityPage"
                                className="tm1-sidebar1-menu-text"
                            > <a
                                className={getMenuBubbleClass(
                                    "/TM1IntensityPage"
                                )}
                            ></a>
                                {/* {t2("Tm1IntensityPage", "varemaerke")} */}
                                {t2("Tm1Step3", "varemaerke")}
                            </NavLink>
                        </span>
                    </div>
                    {/* </OverlayTrigger> */}

                    {/* Time */}
                    {/* <OverlayTrigger
                        trigger={["click", "hover", "focus"]}
                        delay={400}
                        delayHide={0}
                        placement={"auto"}
                        overlay={
                            <Tooltip>
                                {t2("tabVMTimePageTooltip", "varemaerke")}
                            </Tooltip>
                        }
                    > */}
                    <div className="tm1-menu-line">

                        <span className="tm1-menu-text">
                            <NavLink
                                id="navlink-time"
                                to="/TM1TimePage"
                                className="tm1-sidebar1-menu-text"
                            > <a
                                className={getMenuBubbleClass("/TM1TimePage")}
                            ></a>
                                {/* {t2("Tm1TimePage", "varemaerke")} */}
                                {t2("Tm1Step4", "varemaerke")}
                            </NavLink>
                        </span>
                    </div>
                    {/* </OverlayTrigger> */}

                    {/* Result */}
                    {/* <OverlayTrigger
                        trigger={["click", "hover", "focus"]}
                        delay={400}
                        delayHide={0}
                        placement={"auto"}
                        overlay={
                            <Tooltip>
                                {t2("tabVMResultPageTooltip", "varemaerke")}
                            </Tooltip>
                        }
                    > */}
                    <div className="tm1-menu-line">

                        <span className="tm1-menu-text">
                            <NavLink
                                id="navlink-time"
                                to="/TM1ResultPage"
                                className="tm1-sidebar1-menu-text"
                            > <a
                                className={getMenuBubbleClass("/TM1ResultPage")}
                            ></a>
                                {/* {t2("Tm1ResultPage", "varemaerke")} */}
                                {t2("Tm1Step5", "varemaerke")}
                            </NavLink>
                        </span>
                    </div>
                    {/* </OverlayTrigger> */}
                </nav>
                {/* </div> */}
                {/* <div className="tm1-grid-navigation-sidebar3">
                    <nav className="vm-nav">
                        {userType == "sysadmin" && (
                            <NavLink to="/evaluateCase" className="vm-logout">
                                {t2("admin", "varemaerke")}
                            </NavLink>
                        )}
                        {userType == "sysadmin" && (
                            <div className="vm-logout">
                                <p onClick={onLogout}>Logout</p>
                            </div>
                        )}
                    </nav>
                </div> */}
            </div>
        </>
    );
}

export default NavigationSideBarTM2;
