import React, { useContext } from "react";

import { domainContext } from "../../admin/contexts/DomainContext";
import { userCaseContext } from "../../admin/contexts/UserCaseContext";
import { tmevaluationContext } from "./TMEvaluationContext";

import useAuth from "../../admin/hooks/useAuth";
// import { Config } from "../../config";

export const FetchContext = React.createContext(null);

const FetchProvider = ({ children }) => {
    const { currentDomain, currentKnowledgeBase } = useContext(domainContext);
    const { setUserCase } = useContext(userCaseContext);

    const {
        setTMGeographyEvaluationResult,
        setTMIntensityEvaluationResult,
        setTMTimeEvaluationResult,
        setTMUseEvaluationResult,
    } = useContext(tmevaluationContext);

    const { token } = useAuth();


    function fetchGetAttributes() {
        if (currentKnowledgeBase != "noKnowledgeBase") {
            var obj = {};
            obj.knowledgeBase = currentKnowledgeBase;
            obj.domain = currentDomain;
            // obj.requestType = requestType;

            var endpoint = process.env.REACT_APP_FLASK_API + "get_attributes3";

            // Note: we only send the Authorization header if token is not null. This is
            // because in the backend we have jwt_required(optional=True) which raises
            // an exception if there is an invalid or expired token, but not if there is
            // no Authorization header. So, we dont send the Authorization header if there
            // is not a valid token.
            return fetch(endpoint, {
                method: "POST",

                headers:
                    token != null
                        ? {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                        }
                        : {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },

                body: JSON.stringify(obj),
            })
                .then((res) => res.json())
                .then((responseJson) => {
                    setUserCase(responseJson["data"]);
                });
        }

    }





    // This call to evaluate is used to iniatize the userCase variable
    function fetchEvaluate(requestType) {
        if (currentKnowledgeBase != "noKnowledgeBase") {
            var obj = {};
            obj.knowledgeBase = currentKnowledgeBase;
            obj.domain = currentDomain;
            obj.requestType = requestType;

            var endpoint = process.env.REACT_APP_FLASK_API + "evaluate";

            // Note: we only send the Authorization header if token is not null. This is
            // because in the backend we have jwt_required(optional=True) which raises
            // an exception if there is an invalid or expired token, but not if there is
            // no Authorization header. So, we dont send the Authorization header if there
            // is not a valid token.
            return fetch(endpoint, {
                method: "POST",

                headers:
                    token != null
                        ? {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                        }
                        : {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },

                body: JSON.stringify(obj),
            })
                .then((res) => res.json())
                .then((responseJson) => {
                    setUserCase(responseJson["data"]);
                });
        }
    }

    // This call to evaluate is used to respond to requests for evaluation
    // from the use, time, intensity and geography pages
    function fetchEvaluate2(obj, caller) {
        if (currentKnowledgeBase != "noKnowledgeBase") {
            var endpoint = process.env.REACT_APP_FLASK_API + "evaluate";

            // Note: we only send the Authorization header if token is not null. This is
            // because in the backend we have jwt_required(optional=True) which raises
            // an exception if there is an invalid or expired token, but not if there is
            // no Authorization header. So, we dont send the Authorization header if there
            // is not a valid token.
            return fetch(endpoint, {
                method: "POST",

                headers:
                    token != null
                        ? {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                        }
                        : {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },

                body: JSON.stringify(obj, caller),
            })
                .then((res) => res.json())
                .then((responseJson) => {
                    if (caller == "use") {
                        setTMUseEvaluationResult(responseJson);
                    } else if (caller == "time") {
                        setTMTimeEvaluationResult(responseJson);
                    } else if (caller == "intensity") {
                        setTMIntensityEvaluationResult(responseJson);
                    } else if (caller == "geography") {
                        setTMGeographyEvaluationResult(responseJson);
                    }
                });
        }
    }

    const value = {
        fetchEvaluate: fetchEvaluate,
        fetchEvaluate2,
        fetchGetAttributes,
    };

    return (
        <FetchContext.Provider value={value}>{children}</FetchContext.Provider>
    );
};

export default FetchProvider;
