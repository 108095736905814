import React, { useState, useContext } from "react";
import {
    Container,
    Form,
    Col,
    Row,
    Button,
    InputGroup,
    ButtonGroup,
} from "react-bootstrap";

import KnowledgeBaseTable from "./KnowledgeBaseTable";

import { displayContext } from "../../../common/DisplayContext.js";
// import { TokenContext } from "../../contexts/TokenContext.js";
import { domainContext } from "../../contexts/DomainContext";
import { errorContext } from "../../contexts/ErrorContext";

import styles from '../../styles.module.css';

import useAuth from "../../hooks/useAuth";

import Messages from "../Messages.js";
import Navbar from "../Settings/Navbar.js";
import ButtonSaveKnowledgeBase from "./ButtonSaveKnowledgeBase";
// import { Config } from "../../../config";

///////////////////////////////////////////////////////////////////////////////////
//  KnowledgeBase: show the knowledge base page with field for creating a new knowledgebase
//  and a table listing the existing knowledgebases
//
//  Input:
//    knowledgeBaseNames: existing knowledge base names
//    handleCreateKnowledgeBase: function
//
//  Output: N/A
//
///////////////////////////////////////////////////////////////////////////////////

function KnowledgeBasePageMain() {

    const {
        setDisplayMessage,
        t,
        t2,
    } = useContext(displayContext);
    const { token } = useAuth();
    const { newKnowledgeBase, setNewKnowledgeBase } = useContext(domainContext);
    const { errors, setErrors } = useContext(errorContext);

    ///////////////////////////////////////////////////////////////////////////////////
    //  handleCreateKnowledgeBase: store a new knowledgebase in the database
    //
    //  Input:
    //    knowledgeBase: name of the knowledge base to be stored
    //
    //  Output:
    //
    ///////////////////////////////////////////////////////////////////////////////////

    // The user has clicked the 'Create Knowledge Base' button
    //
    function HandleCreateKnowledgeBase(knowledgeBase) {
        // const {
        //   setDisplayMessage,
        // } = useContext(displayContext)

        setDisplayMessage({
            messageCode: null,
            messageText: "Processing...",
            // messageText: t('processing'),
            displayCode: 0,
        });

        var obj = {};

        obj.knowledgeBase = knowledgeBase;

        var endpoint = process.env.REACT_APP_FLASK_API + "createKnowledgeBase";
        fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                // Authorization: "Bearer " + token,
            },
            body: JSON.stringify(obj),
        })
            .then((res) => res.json())
            .then((responseJson) => {
                // Display a message for the result of the transaction
                setDisplayMessage(Messages(responseJson["message"]));
            });
    }

    const [state, setState] = useState({
        knowledgeBase: "",
        domain: "",
        lname: "",
        email: "",
        city: "",
        state: "",
        zip: "",
        errors: [],
    });

    ///////////////////////////////////////////////////////////////////////////////////
    //  isAlphaNumeric: verify that the string consists of alphanumeric characters
    //
    //  Input:
    //    str: the string to be verified
    //
    //  Output: true / false
    //
    ///////////////////////////////////////////////////////////////////////////////////

    function isAlphaNumeric(str) {
        var code, i, len;

        for (i = 0, len = str.length; i < len; i++) {
            code = str.charCodeAt(i);
            if (
                !(code > 47 && code < 58) && // numeric (0-9)
                !(code > 64 && code < 91) && // upper alpha (A-Z)
                !(code > 96 && code < 123)
            ) {
                // lower alpha (a-z)
                return false;
            }
        }
        return true;
    }

    ///////////////////////////////////////////////////////////////////////////////////
    //  submitHandler: handle when the knowledge base form is submitted
    //
    //  Input:
    //    event: the knowledge base form event
    //
    //  Output: call handleCreateKnowledgeBase
    //
    ///////////////////////////////////////////////////////////////////////////////////

    function submitHandler(event) {
        event.preventDefault();
        // event.target.className += " was-validated";

        let errors = [];

        // Verify that the knowledge base is entered by the user and that is one word of alpahnumeric characters
        if (state.knowledgeBase === "" || !isAlphaNumeric(state.knowledgeBase)) {
            errors.push("knowledgeBase");
        }

        setState({ errors: errors });

        if (errors.length > 0) {
            alert("ERROR");
        } else {
            HandleCreateKnowledgeBase(state.knowledgeBase);
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////
    //  changeHandler: handle when the knowledge base input field is changed
    //
    //  Input:
    //    event: the knowledge base field event
    //
    //  Output: update the state variable for the knowledge base field
    //
    ///////////////////////////////////////////////////////////////////////////////////

    function changeHandler(event) {
        setState({ [event.target.name]: event.target.value });
    }

    function handleChangeNewKnowledgeBase(e) {
        setNewKnowledgeBase(e.target.value);
    }

    return (
        <div className={styles.standardPage}>
            <div className={styles.standardPageTop}>
                <Navbar />
            </div>

            <div className={styles.standardPageMiddleRow}>
                <KnowledgeBaseTable />

                <div className={styles.newKnowledgeBaseBox}>
                    <div className={styles.labelText1}>
                        Opret vidensbase
                    </div>

                    <div className={styles.newKnowledgeBaseInput}>
                        <div className={styles.newKnowledgeBaseInput1}>
                            <input
                                name="kbname"
                                placeholder={t2("newKnowledgeBase", 'sysadmin')}
                                type="text"
                                value={newKnowledgeBase}
                                onChange={(e) => handleChangeNewKnowledgeBase(e)}
                                isInvalid={!!errors.newKnowledgeBase}
                            />

                            <div className={styles.labelTextSmall}>
                                {t("knowledgeBaseNameRule")}
                            </div>
                        </div>
                        <ButtonSaveKnowledgeBase />
                    </div>
                </div>
            </div>
        </div >
    );
}

export default KnowledgeBasePageMain;
