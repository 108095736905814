import { useContext } from "react";

import { AlkaContext } from '../contexts/Context';

import styles from '../styles.module.css';

import useHandleOptionClick from "../hooks/useHandleOptionClick";

function CheckboxGroup({ group }) {

    const [{ setOption }] = useHandleOptionClick();

    const {
        attributesOpenApp,
    } = useContext(AlkaContext);

    function handleCheckboxGroupChange(e) {
        // const newValue = e.target.value;
        const newValue = e.target.checked == true ? '1' : '2';
        setOption(e.target.id, newValue, 'add')
    }

    return (
        <div className={styles.desktopOptionGroup}>
            <form onChange={handleCheckboxGroupChange}>
                {attributesOpenApp.filter(attribute => attribute.groupname == group)
                    .map(attribute => (
                        <div key={attribute.label} className={styles.radioGroupLine}>
                            <label for={attribute.label}> {attribute.label}</label><br></br>
                            <input
                                type="checkbox"
                                id={attribute.name}
                                // name={group}
                                value='1'
                            />
                        </div>
                    )
                    )}
            </form>
        </div>
    )
}

export default CheckboxGroup;