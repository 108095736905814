import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { displayContext } from "../../../common/DisplayContext";
import { knowledgeContext } from "../../contexts/KnowledgeContext";

// import styles from '../styles.module.css';
import styles from '../../styles.module.css';

import HeaderLoggedIn from "../Header/HeaderLoggedIn";
import NavigationSideBar from "../Navigation/NavigationSideBar";
import RejectedCaseMain from "./RejectedCaseMain";
import RejectedCaseMC from "./RejectedCaseMC";

import Footer from "../Footer";

function RejectedCasePage() {
    const { 
        setDisplayMessage 
    } = useContext(displayContext);

    const { 
        knowledgebaseType, 
    } = useContext(knowledgeContext);

    let location = useLocation();

    useEffect(() => {
        setDisplayMessage("");
    }, [location.key]);

    return (
        <div className={styles.page}>
            <div className={styles.pageMain}>
                <NavigationSideBar />
                
                <RejectedCaseMain />

                {/* {knowledgebaseType == "sc" && 
                    <RejectedCaseMain />
                }

                {knowledgebaseType == "mc" && 
                    <RejectedCaseMC />
                } */}



            </div>
        </div>
    );
}

export default RejectedCasePage;
