import React, { useContext, useEffect, useState } from "react";

import Badge from 'react-bootstrap/Badge';

import useAuth from "../../../admin/hooks/useAuth";


import { BsPlayFill, BsPauseFill, BsCheck, BsX } from "react-icons/bs";

import TM1BubbleUsePage1 from "../EvaluateCase/BubbleUsePage1";
import TM1BubbleUsePage2 from "../EvaluateCase/BubbleUsePage2";

import { domainContext } from "../../../admin/contexts/DomainContext";
import { userCaseContext } from "../../../admin/contexts/UserCaseContext";
import { displayContext } from "../../../common/DisplayContext";


function TM1UseBubbles() {
    const { t2 } = useContext(displayContext);

    const { userCase, setUserCase, setCaseStatus } =
        useContext(userCaseContext);

    const { currentKnowledgeBase, currentDomain, setCurrentDomain } =
        useContext(domainContext);

    if (userCase == []) {
        setCurrentDomain(currentDomain);
    }

    const { token } = useAuth();


    return (
        <>
            <div style={{ position: "relative" }}>
                {/* This div centers the child-div which contains the bubble-circle */}
                <div className="tm1-use-bubbles">
                    <div>
                        <div className="tm1-middle-form-prompt">
                            <div>{t2("TM1UsePrompt1Tm1", "varemaerke")}</div>
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                position: "relative"
                            }}
                        >
                            <TM1BubbleUsePage1
                                Icon={BsCheck}
                                attributeName={"useOwnedByMe"}
                            />

                            <TM1BubbleUsePage1
                                Icon={BsX}
                                attributeName={"useNotOwnedByMe"}
                            />
                            {/* {!token &&
                                <div class="box arrow-right use-page-box1" >
                                    {t2("demoUse1", "varemaerke")}
                                </div>
                            } */}
                        </div>

                        <div
                            className="tm1-middle-form-prompt"
                            style={{ marginTop: "10vh" }}
                        >
                            {t2("TM1UsePrompt2Tm1", "varemaerke")}
                        </div>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "center",
                                position: "relative"
                            }}
                        >
                            <TM1BubbleUsePage2
                                Icon={BsCheck}
                                attributeName={"useOneVersion"}
                            />

                            <TM1BubbleUsePage2
                                Icon={BsX}
                                attributeName={"useMultipleVersions"}
                            />

                            {/* {!token &&
                                <div class="box arrow-left use-page-box2" >
                                    {t2("demoUse2", "varemaerke")}
                                </div>
                            } */}

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default TM1UseBubbles;
