import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';
import useAuth from "../../admin/hooks/useAuth";

// Flags: https://www.npmjs.com/package/flag-icons
import "/node_modules/flag-icons/css/flag-icons.min.css";

import useTMEvaluateTm1 from "../hooks/useTMEvaluateTm1";

import TM1IntensityBubbles from "../components/Intensity/IntensityBubbles";
import ForwardBackward from "../components/Navigation/ForwardBackward";

import { domainContext } from "../../admin/contexts/DomainContext";
import { userCaseContext } from "../../admin/contexts/UserCaseContext";
import { displayContext } from "../../common/DisplayContext";
import { swiperContext } from "../../legal/contexts/SwiperContext";

import NavigationSideBarTM2 from "../components/Navigation/NavigationSideBar2";
import Header from "../components/Header";

function TM1IntensityPage() {
    const { token } = useAuth();
    const { setDisplayMessage, previousPath, setUseMode, t2 } =
        useContext(displayContext);

    let navigate = useNavigate();
    // let location = useLocation();

    // useEffect(() => {
    //     setDisplayMessage("");
    // }, [location.key]);

    const { userCase, setUserCase, setCaseStatus } =
        useContext(userCaseContext);

    const {
        currentDomain,
        setCurrentDomain
    } = useContext(domainContext);

    const {
        selectedOptions,
    } = useContext(swiperContext)



    const [{ handleEvaluate }] = useTMEvaluateTm1();

    if (userCase == []) {
        setCurrentDomain(currentDomain);
    }

    if (previousPath == "/learn") {
        setUseMode("read");
    }

    const [errors, setErrors] = useState("errors");

    function attributeTestExists(attributeName) {
        return (
            typeof userCase.find(
                (attribute) => attribute.name == attributeName
            )["test"] != "undefined" &&
            userCase.find((attribute) => attribute.name == attributeName)[
            "test"
            ] == true
        );
    }

    const findFormErrors = () => {
        const newErrors = {};

        if (selectedOptions.findIndex(item => (item.ball == 4)) == -1) {
            newErrors.use = t2("intensityPageError", "varemaerke");
        }

        return newErrors;
    };

    function HandleSubmit(e) {
        // Find errors
        const newErrors = findFormErrors();

        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors);
        } else {
            // No errors! Put any logic here for the form submission!

            // handleEvaluate(e, "intensity");

            navigate("/TM1TimePage");
        }
    }

    function HandleBackward(e) {
        navigate("/TM1GeographyPage");
    }

    // See this link for a description of how the bubble component is designed: https://www.developerway.com/posts/react-component-as-prop-the-right-way
    return (
        <>
            <Header />
            <div
                style={{
                    display: "flex",
                    marginTop: "8vh"
                }}
            >
                <div>
                    <NavigationSideBarTM2 />
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                    }}
                >
                    <TM1IntensityBubbles />

                    {ForwardBackward(HandleSubmit, HandleBackward)}

                    {typeof errors.intensity != "undefined" && (
                        <p
                            className="vm-error-message"
                            style={{ position: "absolute", marginTop: "-5vh" }}
                        >
                            {errors.intensity}
                        </p>
                    )}

                </div>
            </div>
        </>
    );
}

export default TM1IntensityPage;
