import React, { createContext, useState } from "react";

export const commonContext = createContext();

const CommonContextProvider = ({ children }) => {

    const [showConclusion, setShowConclusion] = useState(false);
    const [processing, setProcessing] = useState(false)


    // The case being built by the user entering options in the UI
    const [userCase, setUserCase] = useState([]);


    // The current width of the viewport
    const width = window.innerWidth;

    // The width below which the mobile view should be rendered
    const breakpoint = 620;

    const [lastKnownScrollPosition, setLastKnownScrollPosition] = useState(0)

    return (
        <commonContext.Provider
            value={{
                width,
                breakpoint,
                userCase,
                setUserCase,
                processing,
                setProcessing,
                showConclusion,
                setShowConclusion,
            }}
        >
            {children}
        </commonContext.Provider>
    );
};

export default CommonContextProvider;
