import React, { useContext } from "react";

import { Button, ButtonGroup, OverlayTrigger, Tooltip } from "react-bootstrap";

import { displayContext } from "../../../common/DisplayContext";
import useClearCase from "../../hooks/useClearCase";

import styles from '../../styles.module.css'

function ButtonClearCase() {
    const {
        t,
        t2,
        overlayTriggerHelp,
    } = useContext(displayContext);

    const [{ handleClear }] = useClearCase();

    return (
        <OverlayTrigger
            trigger={overlayTriggerHelp ? ["click", "hover", "focus"] : []}
            delay={400}
            delayHide={0}
            placement={"auto"}
            overlay={<Tooltip>{t("buttonClearTooltip")}</Tooltip>}
        >
            <Button
                className={styles.standardButton}
                variant="danger"
                // size="sm"
                onClick={handleClear}
            >
                {t2("clear", 'sysadmin')}
            </Button>
        </OverlayTrigger>
    );
}

export default ButtonClearCase;
