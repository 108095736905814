import React, { useContext, useEffect } from "react";

import { VlfrdContext } from "../contexts/Context";

function useHandleOptionClick() {

    const {
        setAttributesOpenApp,
    } = useContext(VlfrdContext);

    function setOption(attributeName, value, mode) {
        setAttributesOpenApp(
            prevData => {
                const updatedData = prevData.map(item => {
                    if (item.name == attributeName) {
                        return {
                            ...item,
                            selected: value != item.value,
                            value: value == item.value ? 'abekat' : value,
                        }
                    }
                    return item;
                })
                return updatedData
            }
        )
    }

    return [
        {
            setOption,
        },
    ];
}

export default useHandleOptionClick;
