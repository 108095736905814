import React, { useState, useContext } from "react";
import {
    Container,
    Form,
    Col,
    Row,
    Button,
    InputGroup,
    ButtonGroup,
} from "react-bootstrap";

import { displayContext } from "../../../common/DisplayContext.js";
import { domainContext } from "../../contexts/DomainContext";
import { errorContext } from "../../contexts/ErrorContext";
import { rejectedCaseContext } from "../../contexts/CaseContext";

import styles from '../../styles.module.css';

import useAuth from "../../hooks/useAuth";

import Messages from "../Messages.js";
// import { Config } from "../../../config";
import Navbar from "../Settings/Navbar.js";

function UploadCasesPageMain() {
    const {
        setDisplayMessage,
        t2,
    } = useContext(displayContext);

    const { token } = useAuth();

    const {
        newDomain,
        setNewDomain,
        currentKnowledgeBase
    } = useContext(domainContext);

    const {
        reloadCaseList,
        setReloadCaseList
    } = useContext(rejectedCaseContext);

    const {
        errors,
        setErrors
    } = useContext(errorContext);

    const [checkboxCreate, setCheckboxCreate] = useState(false);
    const [csv, setCsv] = useState("");

    ///////////////////////////////////////////////////////////////////////////////////
    //  isAlphaNumeric: verify that the string consists of alphanumeric characters
    //
    //  Input:
    //    str: the string to be verified
    //
    //  Output: true / false
    //
    ///////////////////////////////////////////////////////////////////////////////////

    function isAlphaNumeric(str) {
        var code, i, len;

        for (i = 0, len = str.length; i < len; i++) {
            code = str.charCodeAt(i);
            if (
                !(code > 47 && code < 58) && // numeric (0-9)
                !(code > 64 && code < 91) && // upper alpha (A-Z)
                !(code > 96 && code < 123)
            ) {
                // lower alpha (a-z)
                return false;
            }
        }
        return true;
    }

    ///////////////////////////////////////////////////////////////////////////////////
    //  submitHandler: handle when the domain form is submitted
    //
    //  Input:
    //    event: the domain form event
    //
    //  Output: call handleCreateDomain
    //
    ///////////////////////////////////////////////////////////////////////////////////

    // function submitHandler(event) {
    //     event.preventDefault();
    //     // event.target.className += " was-validated";

    //     let errors = [];

    //     // Verify that the domain is entered by the user and that is one word of alpahnumeric characters
    //     if (state.domain === "" || !isAlphaNumeric(state.domain)) {
    //         errors.push("domain");
    //     }

    //     setState({ errors: errors });

    //     if (errors.length > 0) {
    //         alert("ERROR");
    //     } else {
    //         HandleCreateDomain(state.domain);
    //     }
    // }

    ///////////////////////////////////////////////////////////////////////////////////
    //  changeHandler: handle when the domain input field is changed
    //
    //  Input:
    //    event: the domain field event
    //
    //  Output: update the state variable for the domain field
    //
    ///////////////////////////////////////////////////////////////////////////////////

    // function changeHandler(event) {
    //     setState({ [event.target.name]: event.target.value });
    // }

    function handleCreateChange(event) {
        if (event.target.checked) {
            setCheckboxCreate(true);
        } else {
            setCheckboxCreate(false);
        }
    }

    function handleUploadCases() {
        setDisplayMessage({
            messageCode: null,
            messageText: "Processing...",
            // messageText: t('processing'),
            displayCode: 1,
        });

        let formData = new FormData();
        formData.append("file", csv);
        formData.append("mode", checkboxCreate ? "create" : "doNotCreate");
        formData.append("knowledgeBase", currentKnowledgeBase);

        var obj = {};

        obj.mode = checkboxCreate;
        obj.file = csv;

        var endpoint = process.env.REACT_APP_FLASK_API + "uploadCases";
        fetch(endpoint, {
            method: "POST",
            // headers: {
            //     Accept: "application/json",
            //     "Content-Type": "application/json",
            //     Authorization: "Bearer " + token,
            // },
            headers: {
                Authorization: "Bearer " + token,
                ContentType: "multipart/form-data",
            },
            // body: JSON.stringify(obj),
            body: formData,
        })
            .then((res) => res.json())
            .then((responseJson) => {
                // Display a message for the result of the transaction
                setDisplayMessage(Messages(responseJson["message"]));

                // Trigger reload of cases in CaseContext
                setReloadCaseList(!reloadCaseList);
            });
    }

    const findFormErrors = () => {
        const newErrors = {};

        if (typeof csv == "undefined" || !csv || csv === "")
            newErrors.csv = "Der skal vælge en fil";
        else if (!isAlphaNumeric(csv))
            newErrors.csv = "Filnavnet skal være alpha-numerisk";

        return newErrors;
    };

    function handleSubmitUploadCases(e) {
        e.preventDefault();

        // Get our new errors
        const newErrors = findFormErrors();
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors);
        } else {
            // No errors! Put any logic here for the form submission!

            handleUploadCases();
        }
    }

    function handleFileChange(e) {
        setCsv(e.target.files[0]);
    }

    return (
        <div className={styles.standardPage}>
            <div className={styles.standardPageTop}>
                <Navbar />
            </div>

            <div className={styles.standardPageMiddle}>
                <div className={styles.uploadCasesMiddle}>
                    <div className={styles.uploadCasesHelpText}>
                        <p className={styles.modalHelpText}>
                            {t2("uploadFileHelp", 'sysadmin')}
                        </p>
                    </div>

                    <div className={styles.uploadCasesFileBox}>
                        <div className={styles.labelText1}>
                            {t2('uploadCases', 'sysadmin')}:
                        </div>

                        <div className={styles.uploadCasesFileInput}>

                        <div className={styles.uploadCasesFilePicker}>
                                <input
                                    type="file"
                                    name="file"
                                    id={styles.uploadCasesFile}
                                    onChange={(e) => handleFileChange(e)}
                                />
                            </div>
                            <Button
                                className={styles.standardButton}
                                variant="success"
                                size="sm"
                                onClick={handleSubmitUploadCases}
                            >
                                {t2("upload", 'sysadmin')}
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default UploadCasesPageMain;
