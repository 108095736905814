import { useContext, useState } from "react";

import { VlfrdContext } from '../contexts/Context';

import styles from '../styles.module.css';

import useHandleOptionClick1 from "../hooks/useHandleOptionClick1";

function MixedGroup({ group }) {

    const [{ setOption }] = useHandleOptionClick1();

    const {
        attributesOpenApp,
    } = useContext(VlfrdContext);

    const [optionValue, setOptionValue] = useState(null)

    function handleCheckboxChange(e) {
        // const newValue = e.target.value;
        const newValue = e.target.checked == true ? '1' : '2';
        setOption(e.target.id, newValue, 'add')
    }

    function handleNumberChange(e) {
        // const newValue = e.target.value;
        const newValue = e.target.checked == true ? '1' : '2';
        setOption(e.target.id, e.target.value, 'add')
        setOptionValue(e.target.value)
    }

    return (
        <div className={styles.optionGroup}>
            <form >
                {attributesOpenApp.filter(attribute => attribute.groupname == group)
                    .map(attribute => (

                        attribute.type == 'check' &&
                        <div key={attribute.label} className={styles.radioGroupLine}>
                            {/* <label for={attribute.label}> {attribute.label}</label> */}
                            <label htmlFor={attribute.name}>{attribute.label}</label>
                            <input
                                className={styles.inputCheckbox}
                                type="checkbox"
                                id={attribute.name}
                                value='1'
                                onChange={handleCheckboxChange}
                            />
                        </div>

                        ||

                        attribute.type == 'integer' &&
                        <div key={attribute.label} className={styles.radioGroupLine} onChange={handleNumberChange}>
                            <label for={attribute.label}> {attribute.label}</label>
                            <input
                                className={styles.inputNumber}
                                type="number"
                                id={attribute.name}
                                value={optionValue}
                            />
                        </div>


                    )
                    )}
            </form>
        </div>
    )
}

export default MixedGroup;