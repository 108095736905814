import React, { useContext } from "react";

import { displayContext } from "../../../common/DisplayContext";

import styles from '../../styles.module.css'

function DisplayMessage() {
    const {
        displayMessage,
        t,
    } = useContext(displayContext);

    return (
        <div>
            {displayMessage.messageText && (
                <a
                    className={
                        displayMessage.displayCode === 0
                            ? styles.messageAlert
                            : styles.messageOk
                    }
                >
                    {t(displayMessage.messageText)}
                </a>
            )}
        </div>
    );
}

export default DisplayMessage;
