import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
// import { errorContext } from "../contexts/ErrorContext";
import {
    domainContext,
    setCurrentKnowledgeBase,
} from "../contexts/DomainContext";

import useAuth from "../hooks/useAuth";

const ProtectedRoute = ({ children }) => {
    const { token } = useAuth();
    // const location = useLocation();
    // const { setOriginalPath, setCurrentKnowledgeBase } =
    //     useContext(domainContext);

    // setOriginalPath(location);

    if (!token) {
        return <Navigate to="/home" replace />;
    }

    return children;
};

export default ProtectedRoute;
