import React, { useContext } from 'react';

import { displayContext } from '../../../common/DisplayContext';
// import { knowledgeContext } from "../../contexts/KnowledgeContext";
import { navigationContext } from '../../contexts/NavigationContext';
import { rejectedCaseContext } from '../../contexts/CaseContext';

import styles from '../../styles.module.css';

import { NavLink, useLocation } from 'react-router-dom';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import { FcProcess } from 'react-icons/fc';
import { PiArrowsClockwise } from 'react-icons/pi';

import { GrDocument } from 'react-icons/gr';
import { GrDocumentExcel } from 'react-icons/gr';
import { LuDatabase, LuTv2 } from 'react-icons/lu';
import { BsBoxes } from 'react-icons/bs';
import { GrDocumentUpload } from 'react-icons/gr';
import { MdBatchPrediction } from 'react-icons/md';
import { MdModelTraining } from 'react-icons/md';

import useAuth from '../../hooks/useAuth';
import DisplayMessage from '../DisplayMessage/DisplayMessage';

import '../../../App.css';

function NavigationSideBar() {
    const { t, t2, setDisplayMessage, setUseMode, overlayTriggerHelp } =
        useContext(displayContext);

    const { triggerCaseRejected, setTriggerCaseRejected, setPage } =
        useContext(navigationContext);

    const { triggerLoadCaseList, setTriggerLoadCaseList } =
        useContext(rejectedCaseContext);

    const { userType } = useAuth();

    let location = useLocation();

    function handleMenuItemSelect(item) {
        console.log('NavigationSideBar - handleMenuItmSelect');
    }

    function handleUnselectCase(item) {
        handleMenuItemSelect(item);
    }

    function getNavLinkClass(path) {
        return location.pathname === path
            ? styles.sidebar1ItemDivActive
            : styles.sidebar1ItemDivInactive;
    }

    function getNavLinkClassText(path) {
        return location.pathname === path
            ? styles.sidebar1MenuTextActive
            : styles.sidebar1MenuTextInactive;
    }

    function getNavLinkClassIcon(path) {
        return location.pathname === path
            ? styles.sidebarMenuIconActive
            : styles.sidebarMenuIconInactive;
    }

    function handleGoToEvaluateCasePage() {
        setPage('evaluateCase');
    }

    function handleGoToRejectedCasePage() {
        setTriggerCaseRejected(!triggerCaseRejected);
        setPage('rejectedCaseList');
    }

    function handleGoToCasePage() {
        setTriggerLoadCaseList(!triggerLoadCaseList);
        setPage('casePage');
    }

    return (
        <div className={styles.navigationSideBar}>
            <div className={styles.mentorTitle}>
                {/* MENTOR */}
                {t2('appTitle', 'sysadmin')}
            </div>

            <DisplayMessage />

            <nav>
                <ul className={styles.sidebar1}>
                    <OverlayTrigger
                        trigger={
                            overlayTriggerHelp
                                ? ['click', 'hover', 'focus']
                                : []
                        }
                        delay={400}
                        delayHide={0}
                        placement={'auto'}
                        overlay={
                            <Tooltip>{t('tabEvaluateCaseTooltip')}</Tooltip>
                        }
                    >
                        <div className={getNavLinkClass('/evaluateCase')}>
                            <NavLink
                                to="/evaluateCase"
                                className={getNavLinkClassText('/evaluateCase')}
                                onClick={handleGoToEvaluateCasePage}
                            >
                                <div className={styles.sidebarMenuItem}>
                                    <PiArrowsClockwise
                                        className={getNavLinkClassIcon(
                                            '/evaluateCase',
                                        )}
                                    />
                                    {t('evaluateCase')}
                                </div>
                            </NavLink>
                        </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                        trigger={
                            overlayTriggerHelp
                                ? ['click', 'hover', 'focus']
                                : []
                        }
                        delay={400}
                        delayHide={0}
                        placement={'auto'}
                        overlay={<Tooltip>{t('tabCasePageTooltip')}</Tooltip>}
                    >
                        <div className={getNavLinkClass('/casePage')}>
                            <NavLink
                                to="/casePage"
                                className={getNavLinkClassText('/casePage')}
                                onClick={handleGoToCasePage}
                            >
                                <div className={styles.sidebarMenuItem}>
                                    <GrDocument
                                        className={getNavLinkClassIcon(
                                            '/casePage',
                                        )}
                                    />
                                    {t('cases')}
                                </div>
                            </NavLink>
                        </div>
                    </OverlayTrigger>

                    <OverlayTrigger
                        trigger={
                            overlayTriggerHelp
                                ? ['click', 'hover', 'focus']
                                : []
                        }
                        delay={400}
                        delayHide={0}
                        placement={'auto'}
                        overlay={
                            <Tooltip>{t('tabRejectedCasePageTooltip')}</Tooltip>
                        }
                    >
                        <div className={getNavLinkClass('/rejectedCasePage')}>
                            <NavLink
                                to="/rejectedCasePage"
                                className={getNavLinkClassText(
                                    '/rejectedCasePage',
                                )}
                                onClick={handleGoToRejectedCasePage}
                            >
                                <div className={styles.sidebarMenuItem}>
                                    <GrDocumentExcel
                                        className={getNavLinkClassIcon(
                                            '/rejectedCasePage',
                                        )}
                                    />
                                    {t('rejectedCases')}
                                </div>
                            </NavLink>
                        </div>
                    </OverlayTrigger>

                    {userType == 'sysadmin' && (
                        <div className={getNavLinkClass('/knowledgeBasePage')}>
                            <NavLink
                                to="/knowledgeBasePage"
                                className={getNavLinkClassText(
                                    '/knowledgeBasePage',
                                )}
                            >
                                <div className={styles.sidebarMenuItem}>
                                    <LuDatabase
                                        className={getNavLinkClassIcon(
                                            '/knowledgeBasePage',
                                        )}
                                    />
                                    {t('knowledgeBases')}
                                </div>
                            </NavLink>
                        </div>
                    )}

                    {/* Domains were removed from the sidebar when Knowledge Bases were introduced.  */}
                    {/* Domains can be turned on again, if separation is needed within knowledged bases */}
                    {/* Until then, 'default' is used as domain */}
                    {/* {userType == "sysadmin" && (
                        <li className="sidebar1-item">
                            <div className={getNavLinkClass("/domainPage")}>
                                <NavLink
                                    to="/domainPage"
                                    className="sidebar1-menu-text"
                                >
                                    {t("domains")}
                                </NavLink>
                            </div>
                        </li>
                    )} */}

                    {(userType == 'admin' || userType == 'sysadmin') && (
                        <li className={styles.sidebar1Item}>
                            <div className={getNavLinkClass('/attributePage')}>
                                <NavLink
                                    to="/attributePage"
                                    className={getNavLinkClassText(
                                        '/attributePage',
                                    )}
                                >
                                    <div className={styles.sidebarMenuItem}>
                                        <BsBoxes
                                            className={getNavLinkClassIcon(
                                                '/attributePage',
                                            )}
                                        />
                                        {t('attributes')}
                                    </div>
                                </NavLink>
                            </div>
                        </li>
                    )}

                    {(userType == 'admin' || userType == 'sysadmin') && (
                        <div className={getNavLinkClass('/UploadCasesPage')}>
                            <NavLink
                                to="/UploadCasesPage"
                                className={getNavLinkClassText(
                                    '/UploadCasesPage',
                                )}
                            >
                                <div className={styles.sidebarMenuItem}>
                                    <GrDocumentUpload
                                        className={getNavLinkClassIcon(
                                            '/UploadCasesPage',
                                        )}
                                    />
                                    {t('uploadCases')}
                                </div>
                            </NavLink>
                        </div>
                    )}

                    {(userType == 'admin' || userType == 'sysadmin') && (
                        <li className={styles.sidebar1Item}>
                            <div
                                className={getNavLinkClass('/batchProcessPage')}
                            >
                                <NavLink
                                    to="/batchProcessPage"
                                    className={getNavLinkClassText(
                                        '/batchProcessPage',
                                    )}
                                >
                                    <div className={styles.sidebarMenuItem}>
                                        <MdBatchPrediction
                                            className={getNavLinkClassIcon(
                                                '/batchProcessPage',
                                            )}
                                        />
                                        {t('batchProcess')}
                                    </div>
                                </NavLink>
                            </div>
                        </li>
                    )}

                    {(userType == 'admin' || userType == 'sysadmin') && (
                        <li className={styles.sidebar1Item}>
                            <div className={getNavLinkClass('/learn')}>
                                <NavLink
                                    to="/learn"
                                    className={getNavLinkClassText('/learn')}
                                >
                                    <div className={styles.sidebarMenuItem}>
                                        <MdModelTraining
                                            className={getNavLinkClassIcon(
                                                '/learn',
                                            )}
                                        />
                                        {t('learn')}
                                    </div>
                                </NavLink>
                            </div>
                        </li>
                    )}

                    {(userType == 'admin' || userType == 'sysadmin') && (
                        <li className={styles.sidebar1Item}>
                            <div
                                className={getNavLinkClass(
                                    '/knowledgeBrowserPage',
                                )}
                            >
                                <NavLink
                                    to="/knowledgeBrowserPage"
                                    className={getNavLinkClassText(
                                        '/knowledgeBrowserPage',
                                    )}
                                >
                                    <div className={styles.sidebarMenuItem}>
                                        <MdBatchPrediction
                                            className={getNavLinkClassIcon(
                                                '/knowledgeBrowserPage',
                                            )}
                                        />
                                        {t('knowledgeBrowser')}
                                    </div>
                                </NavLink>
                            </div>
                        </li>
                    )}

                    {/* {(userType == "admin" || userType == "sysadmin") && ( */}
                    {/* <OverlayTrigger
                        trigger={["click", "hover", "focus"]}
                        delay={400}
                        delayHide={0}
                        placement={"auto"}
                        overlay={<Tooltip>{t("tabVMUseTooltip")}</Tooltip>}
                    >
                        <li className={styles.sidebar1Item}>
                            <div className={getNavLinkClass("/VMUsePage")}>
                                <NavLink
                                    // onClick={handleMenuItemSelect("evaluateCase")}
                                    to="/VMUsePage"
                                    className={styles.sidebar1MenuText}
                                >
                                    {t("VM")}
                                </NavLink>
                            </div>
                        </li>
                    </OverlayTrigger> */}
                    {/* )} */}

                    {/* {(userType == "admin" || userType == "sysadmin") && (
                        <OverlayTrigger
                            trigger={["click", "hover", "focus"]}
                            delay={400}
                            delayHide={0}
                            placement={"auto"}
                            overlay={<Tooltip>{t("tabVM")}</Tooltip>}
                        >
                            <li className="sidebar1-item">
                                <div className={getNavLinkClass("/VM")}>
                                    <NavLink
                                        // onClick={handleMenuItemSelect("evaluateCase")}
                                        to="/VM"
                                        className="sidebar1-menu-text"
                                    >
                                        {t("VM")}
                                    </NavLink>
                                </div>
                            </li>
                        </OverlayTrigger>
                    )} */}

                    {/* {userType == "sysadmin" && (
                        <li className="sidebar1-item">
                            <div className={getNavLinkClass("/test")}>
                                <NavLink
                                    to="/test"
                                    className="sidebar1-menu-text"
                                >
                                    {t("test")}
                                </NavLink>
                            </div>
                        </li>
                    )} */}
                </ul>
            </nav>
        </div>
    );
}

export default NavigationSideBar;
