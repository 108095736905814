import React, { useState, useContext, useEffect } from 'react'

// import SwiperContextProvider from '../contexts/SwiperContext'

import Trademark from './TrademarkDesktopChatQuery'

function TrademarkDesktopPageChatQuery() {
    // let navigate = useNavigate();

    return (
        // <SwiperContextProvider>
        // <TrademarkDesktopChat1 />
        <Trademark />
        // </SwiperContextProvider>
    )
}

export default TrademarkDesktopPageChatQuery
