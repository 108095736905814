import React, { useState, useContext, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import i18n from "i18next";

import Dropdown from "./Dropdown";

import useAuth from "../../hooks/useAuth";
import useClearCase from "../../hooks/useClearCase";

import { displayContext } from "../../../common/DisplayContext";
import { domainContext } from "../../contexts/DomainContext";
import { domainsContext } from "../../contexts/DomainsContext";
import { evaluationContext } from "../../contexts/EvaluationContext";
import { errorContext } from "../../contexts/ErrorContext";
import { attributesContext } from "../../contexts/AttributesContext";

import styles from '../../styles.module.css';

import Messages from "../Messages";

import { rejectedCaseContext } from "../../contexts/CaseContext";
// import { Config } from "../../../config";
const MenuItems = ({ items, depthLevel }) => {
    const [dropdown, setDropdown] = useState(false);

    const { token, onLogout } = useAuth();

    const [{ handleClear }] = useClearCase();

    const {
        setDisplayMessage,
        t,
        setButtonRejectDisabled,
        setUseMode,
        setNewConclusion,
        setNewExplanation,
        setNewRule,
        setNewNote,
        setCurrentLanguage,
    } = useContext(displayContext);

    const { setErrors } = useContext(errorContext);

    const {
        currentDomain,
        currentKnowledgeBase,
        setCurrentDomain,
        setCurrentKnowledgeBase,
    } = useContext(domainContext);

    const { reloadCurrentAttributes, setReloadCurrentAttributes } =
        useContext(attributesContext);

    const {
        setConclusionLastTrue,
        setExplanationLastTrue,
        setExplanation,
        setNoteLastTrue,
        setCurrentCaseId,
    } = useContext(evaluationContext);

    const {
        reloadRejectedCaseList,
        setReloadRejectedCaseList,
        reloadCaseList,
        setReloadCaseList,
    } = useContext(rejectedCaseContext);

    let ref = useRef();

    useEffect(() => {
        const handler = (event) => {
            if (
                dropdown &&
                ref.current &&
                !ref.current.contains(event.target)
            ) {
                setDropdown(false);
            }
        };
        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);
        return () => {
            // Cleanup the event listener
            document.removeEventListener("mousedown", handler);
            document.removeEventListener("touchstart", handler);
        };
    }, [dropdown]);

    const onMouseEnter = () => {
        window.innerWidth > 960 && setDropdown(true);
    };

    const onMouseLeave = () => {
        window.innerWidth > 960 && setDropdown(false);
    };

    // <Link to={items.url}>{items.title}</Link>

    // onClick={() => {
    //     onLogout();
    // }}

    // function handleSelectDomain(domain) {
    //     console.log("MenuItems - domain: ", domain);
    //     // setCurrentDomain(domain);
    //     localStorage.setItem("domain", domain);

    //     // Get the categories for this domain
    //     var obj = {};
    //     obj.knowledgeBase = currentKnowledgeBase;
    //     obj.domain = domain;

    //     //
    //     // It seems that the following is not necessary any more as instead
    //     // the categories state variable is updated with a call to getCategories
    //     // by way of a useEffect statement in CategoryContext.js
    //     //

    //     var endpoint = process.env.REACT_APP_FLASK_API + "getCategories";

    //     fetch(endpoint, {
    //         method: "POST",
    //         headers: {
    //             Accept: "application/json",
    //             "Content-Type": "application/json",
    //             Authorization: "Bearer " + token,
    //         },
    //         body: JSON.stringify(obj),
    //     })
    //         .then((res) => res.json())
    //         .then((responseJson) => {
    //             responseJson.unshift({ name: t("allCategories") }); // Add item to the beginning of the array
    //         })
    //         .then(() => {
    //             setCurrentDomain(domain);

    //             // Clear the Conclusion field
    //             setConclusionLastTrue("");
    //             setExplanationLastTrue("");

    //             // Clear the Explanation field
    //             setExplanation([]);

    //             // Clear the Evalation / Note field
    //             setNoteLastTrue("");

    //             setErrors("errors");

    //             setCurrentCaseId("");

    //             setButtonRejectDisabled(true);
    //         });
    //     return <p>kdkdk</p>;
    // }

    function handleSelectDomain(domain) {
        console.log("MenuItems - domain: ", domain);
        localStorage.setItem("domain", domain);

        setCurrentDomain(domain);

        // Clear the Conclusion field
        setConclusionLastTrue("");
        setExplanationLastTrue("");

        // Clear the Explanation field
        setExplanation([]);

        // Clear the Evalation / Note field
        setNoteLastTrue("");

        setErrors("errors");

        setCurrentCaseId("");

        setButtonRejectDisabled(true);
    }

    function handleSelectKnowledgeBase(knowledgeBase) {
        localStorage.setItem("knowledgeBase", knowledgeBase);

        setCurrentKnowledgeBase(knowledgeBase);
        handleSelectDomain(currentDomain);
        setReloadCaseList(!reloadCaseList);

        return <p>kdkdk</p>;
    }

    function resetKnowledgeBase() {
        console.log("MenuItems - resetKnowledgeBase");

        setNewConclusion("");
        setNewExplanation("");
        setNewRule("");
        setNewNote("");

        var obj = {};
        obj.knowledgeBase = currentKnowledgeBase;

        var endpoint = process.env.REACT_APP_FLASK_API + "resetKnowledgeBase";

        fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(obj),
        })
            .then((res) => res.json())
            .then((responseJson) => {
                setReloadCurrentAttributes(!reloadCurrentAttributes);
                setReloadRejectedCaseList(!reloadRejectedCaseList);
                setReloadCaseList(!reloadCaseList);
                handleClear();
                setUseMode("read");
                setDisplayMessage(Messages(responseJson["message"]));
            });
    }

    function resetDomain() {
        var obj = {};
        obj.knowledgeBase = currentKnowledgeBase;
        obj.domain = currentDomain;

        var endpoint = process.env.REACT_APP_FLASK_API + "resetDomain";

        fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(obj),
        })
            .then((res) => res.json())
            .then((responseJson) => {
                setReloadCurrentAttributes(!reloadCurrentAttributes);
                setReloadRejectedCaseList(!reloadRejectedCaseList);
                setReloadCaseList(!reloadCaseList);
                setDisplayMessage(Messages(responseJson["message"]));
            });
    }

    function resetAttributes() {
        var obj = {};
        obj.knowledgeBase = currentKnowledgeBase;
        obj.domain = currentDomain;

        var endpoint = process.env.REACT_APP_FLASK_API + "resetAttributes";

        fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(obj),
        })
            .then((res) => res.json())
            .then((responseJson) => {
                setReloadCurrentAttributes(!reloadCurrentAttributes);
                setReloadRejectedCaseList(!reloadRejectedCaseList);
                setReloadCaseList(!reloadCaseList);
                setDisplayMessage(Messages(responseJson["message"]));
            });
    }

    function handleSelectLanguage(lang) {
        i18n.changeLanguage(lang);
        setCurrentLanguage(lang);
    }

    return (
        <li
            // className="menu-items"
            className={styles.menuItems}
            ref={ref}
            onMouseEnter={onMouseEnter}
            onMouseLeave={onMouseLeave}
        >
            {items.submenu ? (
                <>
                    <button
                        id='settingsButton'
                        className={styles.settingsMenu}
                        type="button"
                        aria-haspopup="menu"
                        aria-expanded={dropdown ? "true" : "false"}
                        onClick={() => setDropdown((prev) => !prev)}
                    >
                        {items.title}{" "}
                        {depthLevel > 0 ? (
                            <span>&raquo;</span>
                        ) : (
                            <span className={styles.arrow} />
                        )}
                    </button>
                    <Dropdown
                        depthLevel={depthLevel}
                        submenus={items.submenu}
                        dropdown={dropdown}
                    />
                </>
            ) : items.action ? (
                <button
                    className={
                        items.action == "knowledgeBase" &&
                            items.title == currentKnowledgeBase
                            ? styles.settingsMenuHighlight
                            : styles.settingsMenu
                    }
                    type="button"
                    aria-haspopup="menu"
                    aria-expanded={dropdown ? "true" : "false"}
                    onClick={
                        items.action == "onLogout"
                            ? () => {
                                onLogout();
                            }
                            : items.action == "knowledgeBase"
                                ? () => {
                                    handleSelectKnowledgeBase(items.name);
                                }
                                : items.action == "domain"
                                    ? () => {
                                        handleSelectDomain(items.domain);
                                    }
                                    : items.action == "language"
                                        ? () => {
                                            handleSelectLanguage(items.language);
                                        }
                                        : items.action == "resetDomain"
                                            ? () => {
                                                resetDomain(items.domain);
                                            }
                                            : items.action == "resetKnowledgeBase"
                                                ? () => {
                                                    resetKnowledgeBase(items.knowledgeBase);
                                                }
                                                : items.action == "resetAttributes"
                                                    ? () => {
                                                        resetAttributes(items.domain);
                                                    }
                                                    : undefined
                    }
                >
                    {items.title}{" "}
                </button>
            ) : (
                <Link className={styles.settingsMenu} to={items.url}>
                    {items.title}
                </Link>
            )}
        </li>
    );
};

export default MenuItems;
