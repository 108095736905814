// See https://www.robinwieruch.de/react-table-component/ for the tutorial for creating this type of tables
// https://www.robinwieruch.de/categories/react-table-library/
// samples: https://react-table-library.com/?path=/story/getting-started--demo
// https://github.com/table-library/react-table-library
import React, { useEffect } from "react";

import { displayContext } from "../../../common/DisplayContext";
import { knowledgeContext } from "../../contexts/KnowledgeContext";

import { useContext, useState } from "react";

import styles from '../../styles.module.css';

function RuleDetails() {

    const {
        currentRuleId,
        ruleList,
    } = useContext(knowledgeContext);

    const {
        t2,
    } = useContext(displayContext);

    return (
        <div className={styles.ruleRuleDetailsWrapper}>
        <div className={[styles.box, styles.ruleDetails].join(' ')}>
            
            <div className={styles.boxHeading}>{t2("ruleDetails", "sysadmin")}</div>

            {ruleList.length > 0 &&
                <div className={[styles.flexColumnCentered, styles.boxText].join(' ')}>

                    <div className={styles.lineColonCentered}>
                        <div className={styles.lineColonCenteredBefore}>{t2("rule", "sysadmin")}</div>
                        :
                        <div className={styles.lineColonCenteredAfter}>{ruleList[currentRuleId - 1].rule}</div>
                    </div>

                    <div className={styles.lineColonCentered}>
                        <div className={styles.lineColonCenteredBefore}>{t2("conclusion", "sysadmin")}</div>
                        :
                        <div className={styles.lineColonCenteredAfter}>{ruleList[currentRuleId - 1].conclusion}</div>
                    </div>

                    <div className={styles.lineColonCentered}>
                        <div className={styles.lineColonCenteredBefore}>{t2("explanation", "sysadmin")}</div>
                        :
                        <div className={styles.lineColonCenteredAfter}>{ruleList[currentRuleId - 1].explanation}</div>
                    </div>


                    <div className={styles.lineColonCentered}>
                        <div className={styles.lineColonCenteredBefore}>{t2("nextNodeTrue", "sysadmin")}</div>
                        :
                        <div className={styles.lineColonCenteredAfter}>{ruleList[currentRuleId - 1].nextnodetrue}</div>
                    </div>


                    <div className={styles.lineColonCentered}>
                        <div className={styles.lineColonCenteredBefore}>{t2("nextNodeFalse", "sysadmin")}</div>
                        :
                        <div className={styles.lineColonCenteredAfter}>{ruleList[currentRuleId - 1].nextnodefalse}</div>
                    </div>

                    <div className={styles.lineColonCentered}>
                        <div className={styles.lineColonCenteredBefore}>{t2("note", "sysadmin")}</div>
                        :
                        <div className={styles.lineColonCenteredAfter}>{ruleList[currentRuleId - 1].note}</div>
                    </div>

                </div>}
                </div >
                </div >
    );
}

export default RuleDetails;
