import React, { useState, useContext, useEffect } from 'react'
import Button from 'react-bootstrap/Button'

import { displayContext } from '../../common/DisplayContext'
import { swiperContext } from '../contexts/SwiperContext'

import styles from '../styles.module.css'

import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css'
import {
    MainContainer,
    ChatContainer,
    MessageList,
    Message,
    MessageInput,
    TypingIndicator,
    MessageSeparator,
} from '@chatscope/chat-ui-kit-react'

function TrademarkDesktopChatEmbedQuery() {
    // const [chatTextLocal, setChatTextLocal] = useState('')

    // const [typing, setTyping] = useState(false)
    // const [messages, setMessages] = useState([
    //     {
    //         message: 'Hello, I am ChatGPT!',
    //         sender: 'ChatGPT',
    //     },
    // ])

    const { t2, t3 } = useContext(displayContext)

    const {
        chatText,
        setChatText,
        fetchDataQuery,
        fetchedChat,
        setSelectedOptions,
        loading,
        optionDescriptions,
        typing,
        // setTyping,
        messages,
        setMessages,
    } = useContext(swiperContext)

    // const handleSend = async (message) => {

    const handleSend = async (innerHtml, textContent, innerText, nodes) => {
        const newMessage = {
            // message: message,
            message: textContent,
            sender: 'user',
            direction: 'outgoing',
        }

        const newMessages = [...messages, newMessage] // all the old messages + the new message

        // update our messages state
        setMessages(newMessages)

        // set a typing indicator (chatgpt is typing)
        // setTyping(true)

        // process message to ChatGPT (send it over and see the response)
        await processMessagetoChatGPT(newMessages)
    }

    async function processMessagetoChatGPT(chatMessages) {
        // We need to reformat our messages to the format required by the OpenAI API
        // chatMessages - our format here is: { sender: "user" or "ChatGPT", message: "The message content here"}
        // apiMessages - OpenAI API's format: { role: "user" or "assistant", content: "The message content here"}

        let apiMessages = chatMessages.map((messageObject) => {
            let role = ''
            if (messageObject.sender === 'ChatGPT') {
                role = 'assistant'
            } else {
                role = 'user'
            }
            return { role: role, content: messageObject.message }
        })

        // let apiMessages = chatMessages.map((messageObject) => {
        //     let role = ''
        //     if (messageObject.sender === 'user') {
        //         role = ' user'
        //     }
        //     return { role: role, content: messageObject.message }
        // })

        // role: "user" -> a message from the user, "assistant" -> a response from chatGPT
        // "system" -> genereally one initial message defining HOW we want catgpt to talk

        const systemMessage = {
            role: 'system',
            content: 'Explain all concepts like I am 10 years old',
        }

        const apiRequestBody = {
            model: 'gpt-3.5-turbo',
            // messages: [systemMessage, ...apiMessages],
            messages: [...apiMessages],
        }

        // await fetch("https://api.openai.com/v1/chat/completions", {
        //   method: "POST",
        //   headers: {
        //     Authorization: "Bearer " + API_KEY,
        //     "Content-Type": "application/json",
        //   },
        //   body: JSON.stringify(apiRequestBody),
        // })
        //   .then((data) => {
        //     return data.json();
        //   })
        //   .then((data) => {
        //     console.log(data);
        //   });

        await fetchDataQuery(apiMessages, chatMessages)
    }

    // function handleChatTextChange(e) {
    //     setChatTextLocal(e.target.value)
    //     setChatText(e.target.value)
    //     localStorage.setItem('chatText', e.target.value)
    // }

    // async function handleFetchData() {
    //     console.log('TrademarkDesktopTextInput - now calling fetchdata')
    //     localStorage.setItem('chatText', chatTextLocal)
    //     await fetchData(chatTextLocal)
    // }

    // useEffect(() => {
    //     setChatTextLocal(localStorage.getItem('chatText'))
    // }, [])

    // return (
    //     <div className={styles.chatColumn}>
    //         <textarea
    //             className={styles.chatTextArea}
    //             name="chatText"
    //             type="text"
    //             value={chatTextLocal}
    //             placeholder={t2('chatInfo', 'trademark')}
    //             onChange={(e) => handleChatTextChange(e)}
    //         />
    //         <div>
    //             <Button variant="success" size="sm" onClick={handleFetchData}>
    //                 {t2('checkChat', 'trademark')}
    //             </Button>{' '}
    //         </div>
    //     </div>
    // )

    return (
        <>
            <div
                // style={{
                //     position: 'relative',
                //     height: '600px',
                //     width: '300px',
                // }}
                className="chatBox"
            >
                <MainContainer
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        border: 'none',
                    }}
                >
                    <MessageList
                        typingIndicator={
                            typing ? (
                                <TypingIndicator content="ChatGPT is typing" />
                            ) : null
                        }
                    >
                        {messages.map((message, i) => {
                            return <Message key={i} model={message} />
                        })}
                    </MessageList>
                    <MessageInput
                        placeholder="Type message here"
                        onSend={handleSend}
                    />
                </MainContainer>
            </div>
        </>
    )
}

export default TrademarkDesktopChatEmbedQuery
