// https://blog.hubspot.com/website/css-grid-vs-flexbox
// https://www.w3schools.com/css/css_grid.asp
import React, { useContext } from "react";
import { Button } from "react-bootstrap";

import useAuth from "../hooks/useAuth";
import { displayContext } from "../../common/DisplayContext";
import { errorContext } from "../contexts/ErrorContext";

import styles from '../styles.module.css';

const Home = () => {
    const {
        onLogin,
        setUsername,
        setPassword
    } = useAuth();

    const {
        t,
        t2,
    } = useContext(displayContext);

    const { errors, loginError } = useContext(errorContext);

    return (
        <>
            <div className={styles.page}>

                <div className={styles.mentorTitle}>
                    MENTOR<span className={styles.mentorBar}>I</span>
                    <span className={styles.mentorByline}>
                        {t2('byline', 'sysadmin')}
                    </span>
                </div>

                <div className={styles.loginMain}>

                    <h2 className={styles.authFormTitle}>
                        {t2('loginTo', 'sysadmin')}
                        {/* Log in to */}
                        <span className={styles.mentorName}>MENTOR</span>
                    </h2>

                    <div className={styles.loginBox}>
                        <div className={styles.loginFields}>

                            {/* Userid */}
                            <div className={styles.loginField}>
                                <label id="mentor-label">{t2('username', 'sysadmin')}</label>
                                <input
                                    name="loginUserid"
                                    type="text"
                                    placeholder="sam@samson.com"
                                    onChange={(e) =>
                                        setUsername(e.target.value)
                                    }
                                />
                            </div>

                            <div className={styles.formFieldError}>
                                {t(errors.username)}
                            </div>

                            {/* Password */}
                            <div className={styles.loginField}>
                                <label id="mentor-label">{t2('password', 'sysadmin')}</label>
                                <input
                                    name="loginPassword"
                                    type="password"
                                    onChange={(e) =>
                                        setPassword(e.target.value)
                                    }
                                />
                            </div>

                            <div className={styles.formFieldError}>
                                {t(errors.password)}
                            </div>

                            {/* Error message */}
                            <div className={styles.formFieldError}>
                                {t(loginError)}
                            </div>

                            {/* Login button */}
                            <Button
                                className="ms-1 me-1"
                                variant="success"
                                size="sm"
                                onClick={onLogin}
                            >
                                Sign in
                            </Button>

                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Home;


