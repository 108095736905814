import React, { useContext } from 'react';

import { displayContext } from '../../common/DisplayContext';
import { kbhKContext } from '../contexts/KbhKContext';

import styles from '../styles.module.css';


function Topbar() {

    const {
        t2,
    } = useContext(displayContext);

    const {
        knowledgeBase,
    } = useContext(kbhKContext);

    return (

        <div className={styles.desktopTopbar}>
            <span className={styles.desktopProductName}>{t2('AppTitle', knowledgeBase)}</span>
            <span className={styles.desktopCatchline}>{t2('AppByline', knowledgeBase)}</span>
        </div>
    )
};

export default Topbar;
