import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";

import { commonContext } from '../../common/CommonContext';
import { displayContext } from '../../common/DisplayContext';
import { attributesContext } from '../../admin/contexts/AttributesContext';
import { errorContext } from '../../admin/contexts/ErrorContext';
import { EUAIActContext } from '../contexts/Context';

import styles from '../styles.module.css';

import useEvaluate from '../hooks/useEvaluate';

import { BsTriangleFill } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Linkify from "react-linkify";

function Main() {

    // let navigate = useNavigate();

    const {
        t2,
    } = useContext(displayContext);

    const {
        currentAttributes,
    } = useContext(attributesContext);

    const {
        setErrors,
    } = useContext(errorContext);

    const [{ handleEvaluate }] = useEvaluate();

    // const {
    //     // optionDescriptions,
    //     optionDescriptions1,
    //     // selectedOptions,
    //     // setSelectedOptions,
    //     aggConclusion,
    //     setAggConclusion,
    //     knowledgeBase,
    //     evaluationResult,
    //     setEvaluationResult,
    //     attributesOpenApp, setAttributesOpenApp,
    //     risksEvaluationResult, setRisksEvaluationResult,
    //     dataEvaluationResult, setDataEvaluationResult,
    //     dataForDevEvaluationResult, setDataForDevEvaluationResult,
    //     techDocEvaluationResult, setTechDocEvaluationResult,
    //     loggingEvaluationResult, setLoggingEvaluationResult,
    //     transparencyEvaluationResult, setTransparencyEvaluationResult,
    //     oversightEvaluationResult, setOversightEvaluationResult,
    //     secureEvaluationResult, setSecureEvaluationResult,
    //     vendorEvaluationResult, setVendorEvaluationResult,
    //     ongoingEvaluationResult, setOngoingEvaluationResult,
    //     evalutionGroups, setEvaluationGroups,
    //     groupsEvaluationResult, setGroupsEvaluationResult
    // } = useContext(EUAIActContext);


    const {
        aggConclusion,
        setAggConclusion,
        attributesOpenApp, setAttributesOpenApp,
        setRisksEvaluationResult,
        setDataEvaluationResult,
        setDataForDevEvaluationResult,
        setTechDocEvaluationResult,
        setLoggingEvaluationResult,
        setTransparencyEvaluationResult,
        setOversightEvaluationResult,
        setSecureEvaluationResult,
        setVendorEvaluationResult,
        setOngoingEvaluationResult,
        groupsEvaluationResult, setGroupsEvaluationResult
    } = useContext(EUAIActContext);

    const {
        processing,
    } = useContext(commonContext);

    const [infoButton, setInfoButton] = useState(false);
    const [showConclusion, setShowConclusion] = useState(false);

    const [pageCleared, setPageCleared] = useState(true)


    let allResult = {}

    let risksResult = {}
    let dataResult = {}
    let dataForDevResult = {}
    let techDocResult = {}
    let loggingResult = {}
    let transparencyResult = {}
    let oversightResult = {}
    let secureResult = {}
    let vendorResult = {}
    let ongoingResult = {}

    let groupsResult = []


    function handleOptionClick(attributeName, value, mode) {
        setOption2(attributeName, value, mode)
    }


    function setOption2(attributeName, value, mode) {
        setAttributesOpenApp(
            prevData => {
                const updatedData = prevData.map(item => {
                    if (item.name == attributeName) {
                        return {
                            ...item,
                            selected: value != item.value,
                            value: value == item.value ? '' : value,
                        }
                    }
                    return item;
                })
                return updatedData
            }
        )
    }


    function clear() {
        // setSelectedOptions([]);
        setPageCleared(true);

        setAttributesOpenApp(
            attributesOpenApp.map((option) => {
                return {
                    ...option,
                    "value": option.value = '',
                    "test": option.value = 0,
                    "selected": false,
                };
            })
        )
        setShowConclusion(false);
    }


    function findFormErrors() {
        const errors = attributesOpenApp.some(attribute => attribute.selected == false);

        return errors;
    };


    async function evaluate() {

        setPageCleared(false)

        setShowConclusion(false);
        setAggConclusion('')
        setRisksEvaluationResult({})
        setDataEvaluationResult({})
        setDataForDevEvaluationResult({})
        setTechDocEvaluationResult({})
        setLoggingEvaluationResult({})
        setTransparencyEvaluationResult({})
        setOversightEvaluationResult({})
        setSecureEvaluationResult({})
        setVendorEvaluationResult({})
        setOngoingEvaluationResult({})
        setGroupsEvaluationResult({})

        // Validate input form
        const errors = findFormErrors();
        // Conditional logic:
        if (errors) {
            // We got errors!
            setErrors(errors);
        } else {
            // No errors! Put any logic here for the form submission!

            if (
                risksResult.conclusionLastTrue == 'passed' &&
                dataResult.conclusionLastTrue == 'passed' &&
                dataForDevResult.conclusionLastTrue == 'passed' &&
                techDocResult.conclusionLastTrue == 'passed' &&
                loggingResult.conclusionLastTrue == 'passed' &&
                transparencyResult.conclusionLastTrue == 'passed' &&
                oversightResult.conclusionLastTrue == 'passed' &&
                secureResult.conclusionLastTrue == 'passed' &&
                vendorResult.conclusionLastTrue == 'passed' &&
                ongoingResult.conclusionLastTrue == 'passed'
            )
                setAggConclusion('Du opfylder kravene i EU AI forordningen:')
            else
                setAggConclusion('Du ikke kravene i EU AI forordningen:')

            // Here we evaluate each evaluationGroup in isolation, but all in one call to the backend
            // The evaluationGroup variable is set in context.js...
            groupsResult = await handleEvaluate('euaiact', 'byEvaluationGroup')

            setShowConclusion(true);
        }
    }


    // // ////////////////////////////////
    // // Overflow indicator arrow - START
    // // ////////////////////////////////

    useEffect(() => {
        checkOverflow('column1-div');
        checkOverflow('column2-div');
        // checkOverflow('context-div');
        checkOverflow('conclusion-div');

        // // Add scroll event listeners to both divs
        document.getElementById('column1-div').addEventListener('scroll', function () {
            handleScroll('column1-div');
        });

        document.getElementById('column2-div').addEventListener('scroll', function () {
            handleScroll('column2-div');
        });

        // document.getElementById('context-div').addEventListener('scroll', function () {
        //     handleScroll('context-div');
        // });

        document.getElementById('conclusion-div').addEventListener('scroll', function () {
            handleScroll('conclusion-div');
        });

    }, []);


    // Function to check for overflow and show/hide indicator
    function checkOverflow(divId) {
        var div = document.getElementById(divId);

        if (div.scrollHeight > div.clientHeight) {
            showIndicator(divId);
        } else {
            hideIndicator(divId);
        }
    }


    // Function to handle scroll events and show/hide indicator based on position
    function handleScroll(divId) {
        var div = document.getElementById(divId);

        console.log(div.scrollTop + div.offsetHeight, ', ', div.scrollHeight)

        // if (div.scrollHeight - div.scrollTop === div.clientHeight) {
        if (div.scrollTop + div.offsetHeight >= div.scrollHeight) {
            hideIndicator(divId);
        } else {
            showIndicator(divId);
        }
    }


    // Function to show indicator
    function showIndicator(divId) {
        var indicator = document.getElementById(divId + 'Indicator');
        indicator.style.display = 'block';
    }


    // Function to hide indicator
    function hideIndicator(divId) {
        var indicator = document.getElementById(divId + 'Indicator');
        indicator.style.display = 'none';
    }


    // // ////////////////////////////////
    // // Overflow indicator arrow - END
    // // ////////////////////////////////

    function group(group) {

        return (
            <div className={styles.desktopOptionGroup}>
                {attributesOpenApp.filter(option => option.groupname == group).map(option => {
                    return (

                        <div className={pageCleared || option.selected ? [styles.selectionBoxNoErrorOption].join(' ') : [styles.selectionBoxNoErrorOption, styles.selectionBoxErrorOption].join(' ')} >
                            <OverlayTrigger
                                delay={400}
                                delayHide={0}
                                placement={"auto"}
                                overlay={<Tooltip>{option.displayname}</Tooltip>}
                            >
                                <div className={styles.selectionBoxAttributeText}>
                                    {option.displayname}
                                </div>
                            </OverlayTrigger>

                            <div className={styles.selectionBoxRadioButtons}>
                                <div className={styles.desktopSelectOption} onClick={() => handleOptionClick(option.name, '1', 'add')}>
                                    <input
                                        type="checkbox"
                                        name="yes"
                                        className={styles.checkboxToRadio}
                                        checked={option.value == '1'}
                                    />
                                    {t2("yes", "euaiact")}
                                </div>

                                <div className={styles.desktopSelectOption} onClick={() => handleOptionClick(option.name, '2', 'add')}>
                                    <input
                                        type="checkbox"
                                        className="checkbox-to-radio"
                                        checked={option.value == '2'}
                                    />
                                    {t2("no", "euaiact")}
                                </div>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }



    // function resultForGroup(groupResult) {
    //     return (
    //         <>
    //             {groupResult.explanationLastTrue != '' ?

    //                 <div className={
    //                     groupResult.conclusionLastTrue == 'passed' ?
    //                         'desktop-explanation-box desktop-explanation-approved'
    //                         : 'desktop-explanation-box desktop-explanation-rejected'
    //                 }
    //                 >
    //                     <Linkify
    //                         componentDecorator={(
    //                             decoratedHref,
    //                             decoratedText,
    //                             key
    //                         ) => (
    //                             <a
    //                                 target="blank"
    //                                 href={decoratedHref}
    //                                 key={key}
    //                             >
    //                                 {t2("labelSeeDecision", "euaiact")}
    //                             </a>
    //                         )}
    //                     >
    //                         {t2(groupResult.explanationLastTrue, "euaiact")}
    //                     </Linkify>
    //                 </div>

    //                 : ''
    //             }
    //         </>
    //     )
    // }



    function isEmptyObject(obj) {
        return obj && typeof obj === 'object' && Object.keys(obj).length === 0;
    }


    function resultForEvaluationGroup(evaluationGroupResults, group) {

        if (!isEmptyObject(evaluationGroupResults)) {
            // Convert object of objects to array of objects
            const evaluationGroupResultsArray = Object.values(evaluationGroupResults);
            const filteredEvaluationGroupResults = evaluationGroupResultsArray.filter(obj => obj.group === group)[0];

            return (
                <>
                    {

                        filteredEvaluationGroupResults.explanationLastTrue != '' ?

                            <div className={
                                filteredEvaluationGroupResults.conclusionLastTrue == 'passed' ?
                                    'desktop-explanation-box desktop-explanation-approved'
                                    : 'desktop-explanation-box desktop-explanation-rejected'
                            }
                            >
                                <Linkify
                                    componentDecorator={(
                                        decoratedHref,
                                        decoratedText,
                                        key
                                    ) => (
                                        <a
                                            target="blank"
                                            href={decoratedHref}
                                            key={key}
                                        >
                                            {t2("labelLink", "euaiact")}
                                        </a>
                                    )}
                                >
                                    {t2(filteredEvaluationGroupResults.explanationLastTrue, "euaiact")}
                                </Linkify>
                            </div>

                            : ''
                    }
                </>
            )
        }
    }


    console.log(process.env.REACT_APP_FLASK_API);

    return (

        <div>
            {attributesOpenApp &&
                <div className={styles.desktopMain}>

                    <div className={styles.desktopColumn1}>
                        <div id='column1-divIndicator'><BsTriangleFill className={[styles.desktopOverflowIcon, styles.desktopOverflowIconColumn1].join(' ')} /></div>
                        <div id='column1-div' className={styles.desktopNoErrorBorder} >
                            <p className={styles.groupHeading}>Risks</p>
                            {group('risks')}

                            <p className={styles.groupHeading}>Data and data management</p>
                            {group('data')}

                            <p className={styles.groupHeading}>Data for AI development</p>
                            {group('devdata')}

                            <p className={styles.groupHeading}>Technical documentation</p>
                            {group('technicaldoc')}

                            <p className={styles.groupHeading}>Logging in operation</p>
                            {group('logging')}

                        </div >
                    </div >

                    <div className={styles.desktopColumn2}>
                        <div id='column2-divIndicator'><BsTriangleFill className={[styles.desktopOverflowIcon, styles.desktopOverflowIconcolumn2].join(' ')} /></div>
                        <div id='column2-div' className={styles.desktopNoErrorBorder} >

                            <p className={styles.groupHeading}>Transparency</p>
                            {group('transparency')}

                            <p className={styles.groupHeading}>Oversight</p>
                            {group('oversight')}

                            <p className={styles.groupHeading}>Precision, robustness, cyber security</p>
                            {group('secure')}

                            <p className={styles.groupHeading}>Vendor</p>
                            {group('vendor')}

                            <p className={styles.groupHeading}>Ongoing</p>
                            {group('ongoing')}

                        </div >
                    </div >


                    {/* Conclusion column */}
                    <div className={[styles.desktopColumn2, styles.desktopColumnLast].join(' ')}>

                        <div className={styles.desktopConclusionHeading}>
                            {/* <a>Konklusion</a> */}
                            <div>
                                <Button
                                    variant="success"
                                    size="sm"
                                    onClick={evaluate}
                                >
                                    {t2('check', 'euaiact')}
                                </Button>

                                {' '}

                                <Button variant="danger" size="sm" onClick={clear}>{t2('clear', 'euaiact')}</Button>
                            </div>
                        </div>

                        <div>

                            <div className={showConclusion ? 'desktop-show-conclusions' : 'desktop-hide-conclusions'}>

                                <div id='conclusion-divIndicator'><BsTriangleFill className='desktop-overflow-icon desktop-overflow-icon-conclusion' /></div>

                                <div className='desktop-agg-conclusion'>
                                    {aggConclusion}
                                </div>

                                <div id='conclusion-div' className='desktop-explanation-list'>

                                    {resultForEvaluationGroup(groupsEvaluationResult, 1)}
                                    {resultForEvaluationGroup(groupsEvaluationResult, 2)}
                                    {resultForEvaluationGroup(groupsEvaluationResult, 3)}
                                    {resultForEvaluationGroup(groupsEvaluationResult, 4)}
                                    {resultForEvaluationGroup(groupsEvaluationResult, 5)}
                                    {resultForEvaluationGroup(groupsEvaluationResult, 6)}
                                    {resultForEvaluationGroup(groupsEvaluationResult, 7)}
                                    {resultForEvaluationGroup(groupsEvaluationResult, 8)}
                                    {resultForEvaluationGroup(groupsEvaluationResult, 9)}
                                    {resultForEvaluationGroup(groupsEvaluationResult, 10)}
                                    {resultForEvaluationGroup(groupsEvaluationResult, 11)}
                                    {resultForEvaluationGroup(groupsEvaluationResult, 12)}
                                    {resultForEvaluationGroup(groupsEvaluationResult, 13)}
                                    {resultForEvaluationGroup(groupsEvaluationResult, 14)}
                                    {resultForEvaluationGroup(groupsEvaluationResult, 15)}
                                    {resultForEvaluationGroup(groupsEvaluationResult, 16)}
                                    {resultForEvaluationGroup(groupsEvaluationResult, 17)}
                                    {resultForEvaluationGroup(groupsEvaluationResult, 18)}
                                    {resultForEvaluationGroup(groupsEvaluationResult, 19)}
                                    {resultForEvaluationGroup(groupsEvaluationResult, 20)}
                                    {resultForEvaluationGroup(groupsEvaluationResult, 21)}
                                    {resultForEvaluationGroup(groupsEvaluationResult, 22)}
                                    {resultForEvaluationGroup(groupsEvaluationResult, 23)}

                                </div>

                            </div>

                            {processing &&
                                <div style={{ display: !showConclusion ? 'flex' : 'none' }} className='desktop-spinner-container'>
                                    <div className='desktop-spinner'>
                                        <Spinner
                                            animation="border"
                                            variant="secondary"
                                        />
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                </div >
            }
        </div >

    )
};

export default Main;










