import React, { useContext } from 'react';

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { displayContext } from '../../../common/DisplayContext.js';
import { navigationContext } from '../../contexts/NavigationContext.js';

import styles from '../../styles.module.css';

function ButtonAdd() {
    const {
        setUiModeMCAddNode,
        t2,
        setNewConclusion,
        setNewExplanation,
        setNewRule,
        setNewNote,
        setNewConclusionGroup,
        setButtonAddNodeDisabled,
    } = useContext(displayContext);

    const { setPage } = useContext(navigationContext);

    function submitAddHandler(e) {
        e.preventDefault();
        setNewConclusion('');
        setNewExplanation('');
        setNewRule('');
        setNewNote('');
        setNewConclusionGroup('');

        setUiModeMCAddNode(true);
        setButtonAddNodeDisabled(false);
        setPage('addNodeMC');
    }

    return (
        <OverlayTrigger
            trigger={['click', 'hover', 'focus']}
            delay={400}
            delayHide={0}
            placement={'auto'}
            overlay={<Tooltip>{t2('buttonAddTooltip', 'sysadmin')}</Tooltip>}
        >
            <div>
                <Button
                    className={styles.standardButton}
                    variant="success"
                    // disabled={buttonAddDisabled}
                    onClick={submitAddHandler}
                >
                    {t2('add', 'sysadmin')}
                </Button>
            </div>
        </OverlayTrigger>
    );
}

export default ButtonAdd;
