import React, { createContext, useContext, useState } from 'react';
import { useLocation } from 'react-router-dom';

export const domainContext = createContext();

const DomainContextProvider = ({ children }) => {
    const location = useLocation();

    const [originalPath, setOriginalPath] = useState(location.pathname);

    // Set currentKnowledgeBase to the knowledge base name stored in localStorage, of if nothing is stored in
    // localStorage, then set currentKnowledgeBase to the string 'noKnowledgeBase'
    const storedKnowledgeBase = localStorage.getItem('knowledgeBase')
        ? localStorage.getItem('knowledgeBase')
        : 'noKnowledgeBase';

    // const [currentKnowledgeBase, setCurrentKnowledgeBase] =
    //     useState(storedKnowledgeBase);

    console.log('DomainContext - originalPath: ', originalPath);

    // const abc =
    //     typeof originalPath.pathname == "undefined" ? "varemaerke" : "test";

    // const abc = originalPath.pathname == "tm1" ? "varemaerke" : "test";
    let abc = '';

    if (
        originalPath.toLowerCase() == '/tm' ||
        originalPath.toLowerCase() == '/tm1'
    ) {
        console.log(
            'DomainContext - originalPath is /tm1, so now setting abc to varemaerke: ',
            originalPath,
        );
        // abc = "varemaerke";
        abc = 'trademark';
    } else {
        console.log(
            'DomainContext - originalPath is NOT /tm1, so now setting abc to test: ',
            originalPath,
        );
        abc = storedKnowledgeBase;
    }

    const [currentKnowledgeBase, setCurrentKnowledgeBase] = useState(abc);
    const [abekat, setAbekat] = useState('aaa');

    // TESTING START
    // setCurrentKnowledgeBase("varemaerke");

    // TESTING END

    const [newKnowledgeBase, setNewKnowledgeBase] = useState();

    // Set currentDomain to the domain name stored in localStorage, of if nothing is stored in
    // localStorage, then set currentDomain to the string 'noDomain'

    let storedDomain = localStorage.getItem('domain')
        ? localStorage.getItem('domain')
        : 'noDomain';

    storedDomain = 'default'; // 221104: NOW SPLITTING DOMAINS INTO SEPERATE DATABASES, AND THEREFORE ALWAYS USING THE SAME DOMAIN NAMED 'default'
    // SO THE USER CAN ONLY SELECCT KNOWLEDGE BASE NOT DOMAIN. I HAVE KEPT DOAMIN BEHIND THE SCENES, IF THERE IS LATER
    // A REQUIREMENT TO HAVE MORE 'GROUPS' WITHIN A KNOWLEDGE BASE, AND THEREFORE KEPT THE GETTING OF A STORED DOMAIN ABOVE.

    const [currentDomain, setCurrentDomain] = useState(storedDomain);
    const [newDomain, setNewDomain] = useState();

    return (
        <domainContext.Provider
            value={{
                currentDomain,
                currentKnowledgeBase,
                newDomain,
                newKnowledgeBase,
                setCurrentDomain,
                setCurrentKnowledgeBase,
                setNewDomain,
                setNewKnowledgeBase,
                storedDomain,
                originalPath,
                setOriginalPath,
                abekat,
            }}
        >
            {children}
        </domainContext.Provider>
    );
};

export default DomainContextProvider;
