import React, { useEffect, useContext, useState } from "react";
import Messages from "../Messages.js";

import {
    Button,
    Form,
    InputGroup,
    ButtonGroup,
    Spinner,
} from "react-bootstrap";

import { domainContext } from "../../contexts/DomainContext";
import { evaluationContext } from "../../contexts/EvaluationContext";
import { displayContext } from "../../../common/DisplayContext.js";
import { errorContext } from "../../contexts/ErrorContext";
import { userCaseContext } from "../../contexts/UserCaseContext";
// import { TokenContext } from "../../contexts/TokenContext.js";

import useAuth from "../../hooks/useAuth.js";
import useNewNodeFields from "../../hooks/useNewNodeFields.js";
import { clear } from "fetch-intercept";
// import { Config } from "../../../config.js";

import styles from '../../styles.module.css'

///////////////////////////////////////////////////////////////////////////////////
//  ButtonLoadCase: display and handle the buttons shown in the case block
//
//  Input:
//    handleClassify: function
//    HandleClear: function
//    handleGetCase: function
//    currentCaseId: the case id of the case that was retrieved from the knowledge base
//    handleChangeCaseId: function
//
//  Output: N/A
//
///////////////////////////////////////////////////////////////////////////////////

function ButtonGetNextLearningCase() {
    const { userCase, setUserCase, caseStatus, setCaseStatus } =
        useContext(userCaseContext);

    const { token, setTokenHasExpired } = useAuth();

    const [{ clearNewNodeFields, enableNewNodeFields, disableNewNodeFields }] =
        useNewNodeFields();

    const {
        // setIsLoading,
        caseSelected,
        isLoading3,
        setButtonAcceptDisabled,
        setButtonLetterDisabled,
        setButtonRejectDisabled,
        setButtonSaveNewRuleDisabled,
        setDiffIndicatorStatus,
        setDisplayMessage,
        setIsLoading3,
        setNewConclusion,
        setNewExplanation,
        setNewLetter,
        setNewLetterRuleFalse,
        setNewLetterRuleTrue,
        setNewNote,
        setNewRule,
        setSpinnerMessage,
        setTextAreaNewNoteDisabled,
        setTextAreaNewRuleDisabled,
        setTextAreaNewConclusionDisabled,
        setTextAreaNewExplanationDisabled,
        t,
    } = useContext(displayContext);

    const { errors } = useContext(errorContext);

    const { currentKnowledgeBase, currentDomain } = useContext(domainContext);

    const {
        currentCaseId,
        setConclusionLastTrue,
        setCurrentCaseId,
        setEvaluationResult,
        setExplanation,
        setExplanationLastTrue,
        setExplanationTrues,
        setNodeLast,
        setNodeLastTrue,
        setNodesEvaluated,
        setNoteLastTrue,
        setReferenceCase,
        setReturnCode,
        setActualClass,
    } = useContext(evaluationContext);

    ///////////////////////////////////////////////////////////////////////////////////
    //  handleGetNextCase, handleGetPreviousCase, handleGetThisCase: make a call to
    //  handleGetCase with a parameter which tells whether to get the next, the previous
    //  or the current case.
    //
    //  Input: N/A
    //
    //  Output: N/A
    //
    ///////////////////////////////////////////////////////////////////////////////////

    // function handleGetNextCase() {
    //     HandleGetCase("next");
    // }

    // function handleGetPreviousCase() {
    //     HandleGetCase("previous");
    // }

    function handleGetCaseForLearning() {
        setSpinnerMessage(t("getCaseForLearning"));
        setIsLoading3(true);

        HandleGetCase("this");
    }

    ///////////////////////////////////////////////////////////////////////////////////
    //  handleChangeCaseId: sets the current case id state variable, when the user
    //  edits the case id input field
    //
    //  Input: N/A
    //
    //  Output: saves the current case id entered by the user into the currenctCaseId state variable
    //
    ///////////////////////////////////////////////////////////////////////////////////

    function handleChangeCaseId(e) {
        setCurrentCaseId(e.target.value);
        delete errors.currentCaseId;
        // myMap.delete("key2")
    }

    ///////////////////////////////////////////////////////////////////////////////////
    //  handleGetCase: reads a case from the knowledge base.
    //
    //  Input:
    //    mode: determines if the backend function reads the next, previous or a specific case
    //
    //  Output: setting of the case data state variable, various evaluation variables
    //  and various control variables
    //
    ///////////////////////////////////////////////////////////////////////////////////

    function HandleGetCase(mode) {
        var obj = {};
        var ruleFound = false;

        const cloneCaseData = [...userCase];
        cloneCaseData.forEach((attribute) => {
            attribute["referenceValue"] = "";
            attribute["referenceMatch"] = "";
        });
        setUserCase(cloneCaseData);

        // HandleClear();    <<<<<<<<<< I deleted this for test. We still need
        //                              to clear the fields some way, so
        //                              we need to do something here...

        obj.knowledgeBase = currentKnowledgeBase;
        obj.domain = currentDomain;

        // Clear fields in window
        setDisplayMessage({
            messageCode: null,
            messageText: t("processing"),
            displayCode: 1,
        });

        // setNewConclusion("");
        // setNewExplanation("");
        // setNewRule("");
        // setNewNote("");
        clearNewNodeFields();

        var endpoint = process.env.REACT_APP_FLASK_API + "getCaseForLearning";
        fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(obj),
        })
            .then((res) => res.json())
            .then((responseJson) => {
                setEvaluationResult(responseJson);
                setUserCase(responseJson["caseData"]);
                setCaseStatus(responseJson["status"]);
                setCurrentCaseId(responseJson["caseId"]);
                setActualClass(responseJson["className"]);
                setConclusionLastTrue(responseJson["conclusionLastTrue"]);
                setExplanationLastTrue(responseJson["explanationLastTrue"]);

                setNewConclusion(responseJson["conclusionLastTrue"]);
                setNewExplanation(responseJson["explanationLastTrue"]);
                setNewNote(responseJson["noteLastTrue"]);

                // setNewLetter(responseJson["letter"]);
                setNewLetterRuleFalse(responseJson["letterRuleFalseLast"]);
                setNewLetterRuleTrue(responseJson["letterRuleTrueLast"]);

                setExplanationTrues(responseJson["explanationTrues"]);

                setNodeLast(responseJson["nodeLast"]);
                setNodeLastTrue(responseJson["nodeLastTrue"]);
                setNodesEvaluated(responseJson["nodesEvaluated"]);
                setNoteLastTrue(responseJson["noteLastTrue"]);
                // setReferenceCase(responseJson["referenceCase"]);
                setReturnCode(responseJson["returnCode"]);

                // Convert the returned explanation (list of short rules, long rules and evaluation result) to a
                // list of dictionaries
                const explanationDict = [];
                var id = 0;
                responseJson["explanation"].forEach((expl) => {
                    id = id + 1;
                    explanationDict.push({
                        id: id,
                        explanation: expl[0],
                        explanationLong: expl[1],
                        displayCode: expl[2] === true ? 0 : 1,
                        letterRuleTrue: expl[3],
                        letterRuleFalse: expl[4],
                    });
                });
                setExplanation(explanationDict);

                // messageCode is 0 if the evaluation found a rule that applies to the case
                // and so if the messageCode is not equal to 0 we want to
                // set the displayMessage informing the user by interpreting the return code sent by the server
                if (responseJson["message"].messageCode !== 0) {
                    setDisplayMessage(Messages(responseJson["message"]));
                }

                // Enable / disable buttons
                // messageCode = 0 if the evaluation found a rule that applies to the case
                ruleFound = responseJson["message"].messageCode == 0;

                setButtonLetterDisabled(!ruleFound);
                setButtonAcceptDisabled(!ruleFound);
                setButtonRejectDisabled(!ruleFound);

                setDiffIndicatorStatus(true);

                // messageCode = 1 if no rule applies
                if (responseJson["message"].messageCode === 1) {
                    enableNewNodeFields();

                    // messageCode = 2 Conclusion found but mismatch
                } else if (responseJson["message"].messageCode === 2) {
                    enableNewNodeFields();
                } else if (responseJson["message"].messageCode === 3) {
                    disableNewNodeFields();
                    setDisplayMessage(Messages(responseJson["message"]));
                } else setDisplayMessage("");
                setIsLoading3(false);
            });
    }

    return (
        <>
            <div class="form-group">
                <Button
                    className={styles.standardButton}
                    variant="success"
                    size="sm"
                    // className="mentor-button1"
                    onClick={handleGetCaseForLearning}
                >
                    {isLoading3 && (
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                    {t("getNext")}
                    {isLoading3 && (
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                        />
                    )}
                </Button>
                {/* <input
                    style={{
                        height: "30px",
                        marginRight: "0.5rem",
                        marginTop: "0.5rem",
                        marginLeft: "0.5rem",
                        width: "10rem",
                        textAlign: "center",
                    }}
                    className="input-field"
                    id="caseno"
                    variant="mentor"
                    placeholder={t("caseNo")}
                    type="text"
                    value={currentCaseId}
                    onChange={(e) => handleChangeCaseId(e)}
                    as="input"
                    htmlSize="10"
                    isInvalid={!!errors.currentCaseId}
                /> */}
            </div>
        </>
    );
}

export default ButtonGetNextLearningCase;
