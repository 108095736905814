
const defaultTableTheme =  {
    Table: `
  height: 100%;
  width: 100%;
  align-content: baseline;
  --data-table-library_grid-template-columns: 50% 15%;
`,
    BaseRow: `
  font-size: var(--font-size5);
`,
    HeaderRow: `
  font-size: var(--font-size5);

  .th {
    font-weight: normal !important;
    border-width: 0px;
    border-bottom: 0px;
    box-shadow: 0px 0px 0px 0px rgba(0,0,0,0)
  }
`,
    Row: `
//   &:nth-child(odd) {
//     background-color: #C7C7C7;
//   }

//   &:nth-child(even) {
//     background-color: #F2F2F2;
//   }

  cursor: pointer;

  &:hover .td {
    background-color: yellow;
  }
`,
    HeaderCell: `
    padding-left: 7px !important;
    `,
    Cell: `
  padding-left: 10px !important;
  text-align: left; 
//   background-color: red;
  border-bottom-color: rgb(222, 226, 230);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  margin-top: 5px;
  margin-bottom: 5px;


`,
    BaseCell: `
    padding-top: 4px !important;
    padding-bottom: 4px !important;
    `,
};

export default defaultTableTheme;

