import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Badge from 'react-bootstrap/Badge';

import useAuth from "../../admin/hooks/useAuth";

// Flags: https://www.npmjs.com/package/flag-icons
import "/node_modules/flag-icons/css/flag-icons.min.css";

import useTMEvaluateTm1 from "../hooks/useTMEvaluateTm1";
import TM1GeographyBubbles from "../components/Geography/GeograpyBubbles";
import ForwardBackward from "../components/Navigation/ForwardBackward";

import { domainContext } from "../../admin/contexts/DomainContext";
import { userCaseContext } from "../../admin/contexts/UserCaseContext";
import { displayContext } from "../../common/DisplayContext";
import { swiperContext } from "../../legal/contexts/SwiperContext";

import NavigationSideBarTM2 from "../components/Navigation/NavigationSideBar2";
import Header from "../components/Header";

function TM1GeographyPage() {
    const { token } = useAuth();

    const { setDisplayMessage, previousPath, setUseMode, t2 } =
        useContext(displayContext);

    let navigate = useNavigate();
    // let location = useLocation();

    // useEffect(() => {
    //     setDisplayMessage("");
    // }, [location.key]);

    const { userCase, setUserCase, setCaseStatus } =
        useContext(userCaseContext);

    const {
        currentDomain,
        setCurrentDomain
    } = useContext(domainContext);

    const {
        selectedOptions,
    } = useContext(swiperContext);

    const [{ handleEvaluate }] = useTMEvaluateTm1();

    if (userCase == []) {
        setCurrentDomain(currentDomain);
    }

    if (previousPath == "/learn") {
        setUseMode("read");
    }

    const [errors, setErrors] = useState("errors");

    let caller = "";

    function attributeTestExists(attributeName) {
        return (
            typeof userCase.find(
                (attribute) => attribute.name == attributeName
            )["test"] != "undefined" &&
            userCase.find((attribute) => attribute.name == attributeName)[
            "test"
            ] == true
        );
    }

    const findFormErrors = () => {
        const newErrors = {};
        // if (
        //     !attributeTestExists("geographyLocalCityTown") &&
        //     !attributeTestExists("geographyLocalCityTown") &&
        //     !attributeTestExists("geographyLocalAssociation") &&
        //     !attributeTestExists("geographyLocalRestaurant") &&
        //     !attributeTestExists("geographyLocalAmusementPark") &&
        //     !attributeTestExists("geographyLocalHotel") &&
        //     !attributeTestExists("geographyRegional") &&
        //     !attributeTestExists("geographyDenmark") &&
        //     !attributeTestExists("geographyFGGreenland") &&
        //     !attributeTestExists("geographyFGFaroeIslands") &&
        //     !attributeTestExists("geographyOutsideDenmarkDirectedDenmark") &&
        //     !attributeTestExists("geographyOutsideDenmarkNotDirectedDenmark") &&
        //     !attributeTestExists("geographyUseOnline")
        // ) {
        //     newErrors.geography = t2("geographyPageError", "varemaerke");
        // }



        if (selectedOptions.findIndex(item => (item.ball == 3)) == -1) {
            newErrors.use = t2("geographyPageError", "varemaerke");
        }

        return newErrors;
    };

    // useEffect(() => {
    //     setDisplayMessage("");
    // }, [location.key]);

    // function handleCheckbox(e) {
    //     const updatedUserCase = userCase.map((attribute) => {
    //         if (attribute.name === e.target.name) {
    //             return {
    //                 ...attribute,
    //                 ["test"]: e.target.checked,
    //             };
    //         } else {
    //             return attribute;
    //         }
    //     });

    //     setUserCase(updatedUserCase);
    // }

    function HandleSubmit(e) {
        // Find errors
        const newErrors = findFormErrors();

        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors);
        } else {
            // No errors! Put any logic here for the form submission!
            // setIsEvaluating(true);
            // handleEvaluate(e, "geography");

            navigate("/TM1IntensityPage");
        }
    }

    function HandleBackward(e) {
        navigate("/TM1UsePage");
    }

    return (
        <>
            <Header />
            <div
                style={{
                    display: "flex",
                    marginTop: "8vh"
                }}
            >
                <div>
                    <NavigationSideBarTM2 />
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                    }}
                >
                    <TM1GeographyBubbles />

                    {ForwardBackward(HandleSubmit, HandleBackward)}

                    {typeof errors.geography != "undefined" && (
                        <p
                            className="vm-error-message"
                            style={{ position: "absolute", marginTop: "-5vh" }}
                        >
                            {errors.geography}
                        </p>
                    )}


                </div>
            </div>
        </>
    );
}

export default TM1GeographyPage;
