import React, { useState } from 'react';


const Testpdf = ({filename}) => {

    const [filePath, setFilePath] = useState('');

    var endpoint = process.env.REACT_APP_FLASK_API + "pdf";

    const explanation = 'The criteria for use is not satisfied, despite the fact that the trademark is used individually (Varemærkelovens § 3, stk. 1, nr. 3.), because it is used in multiple versions. The Trademark ”Den Store Bagedyst”, which was used in multiple versions, including “bagedyst”, ”Den store julebagedyst 2012”, ”Den store julebagedyst 2013”, ”Den store jule- og nytårsbagedyst 2014” and ”DEN LILLE BAGEDYST” (Sø- og Handelsrettens decision in case V-28-15 (https://tm.cognitysystems.com/docs/DenStoreBagedyst.pdf)); The trademark ”Gyro”, which was used in multiple versions and in combination with ”SG” and ”Junistar” (Sø- og Handelsrettens decision in case BS-40415/2020 (https://tm.cognitysystems.com/docs/Gyro.pdf)); The trademark ”Tingfinder”, which was used in multiple versions, including “Alle Tiders Ting”, “Alle Tiders Design” and “Tingfinderdesign” Handelsrettens decision in case V-85-15); Landsret’s decision in case 17723/2020). (https://tm.cognitysystems.com/docs/Tingfinder.pdf).'

    const currentHost = `${window.location.hostname}`;

    return (

      <div>
        {/* <button onClick={handleClick}>'Andel.pdf'</button> */}
        <p>{currentHost}</p>
        <p>{explanation}</p>

      {currentHost != 'localhost' ? explanation : 'explanation translated'}

      </div>

    )
};


export default Testpdf;


