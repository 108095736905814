import React, { useContext } from "react";

import { Button, ButtonGroup, OverlayTrigger, Tooltip } from "react-bootstrap";

import { displayContext } from "../../../common/DisplayContext";

import styles from '../../styles.module.css'

function ButtonClear({ setInputValues }) {
    const {
        t2,
        overlayTriggerHelp,
        setDisplayMessage,
    } = useContext(displayContext);

   

    const handleClear= () => {
        setInputValues(
            // "attributeDetails",
            {
                shortName: "",
                displayName: "",
                type: "",
                normmin: "",
                normmax: "",
                groupName: "",
                errors: [],
            }
        )


        setDisplayMessage ({
            messageCode: null,
            messageText: "",
            displayCode: 0,
        });



    }

    return (
        <OverlayTrigger
            trigger={overlayTriggerHelp ? ["click", "hover", "focus"] : []}
            delay={400}
            delayHide={0}
            placement={"auto"}
            overlay={<Tooltip>{t2("buttonClearTooltip", 'sysadmin')}</Tooltip>}
        >
            <Button
                className={styles.standardButton}
                variant="danger"
                onClick={handleClear}
            >
                {t2("clear", 'sysadmin')}
            </Button>
        </OverlayTrigger>
    );
}

export default ButtonClear;
