import React, { useState, useContext, useEffect } from 'react'
import Button from 'react-bootstrap/Button'

import { useNavigate } from 'react-router-dom'

// import useChatToJSON1 from '../hooks/useChatToJSON1'

import { displayContext } from '../../common/DisplayContext'
import { swiperContext } from '../contexts/SwiperContext'

import styles from '../styles.module.css'

function TrademarkDesktopTextInput() {
    const navigate = useNavigate()

    const [inputData, setInputData] = useState('')
    const [chatTextLocal, setChatTextLocal] = useState('')

    // const [{ convertToJSON }] = useChatToJSON()

    const { t2, t3 } = useContext(displayContext)

    const {
        chatText,
        setChatText,
        fetchData,
        fetchedChat,
        setSelectedOptions,
        loading,
        optionDescriptions,
    } = useContext(swiperContext)

    // const [processedDdata, setProcessedData] = useState(null)

    function handleChatTextChange(e) {
        // setButtonSaveNewRuleDisabled(false);
        // setChatText(e.target.value)
        setChatTextLocal(e.target.value)
        setChatText(e.target.value)
    }

    const [processedData, setProcessedData] = useState(null)

    async function handleFetchData() {
        console.log('TrademarkDesktopTextInput - now calling fetchdata')
        localStorage.setItem('chatText', chatTextLocal)
        // await fetchData(chatText)
        await fetchData(chatTextLocal)

        navigate('/trademarkdesktoppagechat')
        // navigate('/trademarkdesktoppage')
    }

    useEffect(() => {
        // localStorage.getItem("chatText")
        // setChatTextLocal(chatText)
        setChatTextLocal(localStorage.getItem('chatText'))
    }, [])

    return (
        <div className="container-desktop desktop-font">
            <div className="desktop-topbar">
                <span className="desktop-product-name">
                    Varemærkecheck (demo){' '}
                </span>
                <span className="desktop-catchline">
                    {' '}
                    - beskriv hvordan, hvor længe og hvor du bruger varemærket
                </span>
            </div>
            <div className="desktop-trademark-main">
                <div className={styles.chatColumn}>
                    <textarea
                        className={styles.chatTextArea}
                        name="chatText"
                        type="text"
                        value={chatTextLocal}
                        onChange={(e) => handleChatTextChange(e)}
                    />

                    <div>
                        <Button
                            variant="success"
                            size="sm"
                            onClick={handleFetchData}
                        >
                            {t2('check', 'trademark')}
                        </Button>{' '}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default TrademarkDesktopTextInput
