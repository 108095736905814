// TODO:
// 
// I swiperen skal jeg fra 'stenslag' kunne swipe lodret og komme til undermenuen
// Se link for at fange lodrette swipes: https://stackoverflow.com/questions/70612769/how-do-i-recognize-swipe-events-in-react
// Det skal være sådan at det første lodrette swipe åbner undermenuen, og et vandret swipe i undermenuen skal returnere 
// til overmenuen...


import React, { useRef, useState, useContext } from 'react';
import "../swipeballDesign.css"

import { Button } from "react-bootstrap";

import { topContext } from '../contexts/TopContext';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import '../swipeballDesign.css';

// import required modules
import { Pagination } from 'swiper/modules';

// Import pictures
// import background from './images/background.png';
import logo from '../images/logo.svg';
import startButtonDepressed from '../images/Ball-Final.svg';

import picture1 from '../images/picture 1.svg';
import picture2 from '../images/picture 2.svg';
import picture3 from '../images/picture 3.svg';
import swiperball from '../images/swiperball.png'
import swiperballSelected from '../images/swiperball-selected.png'
import stenslag from '../images/stenslag.png'
import { BsFillCarFrontFill } from "react-icons/bs";
import { BsFillCloudHailFill } from "react-icons/bs";

function ComprehensiveSwiperMain() {

    const { stoneDamage, setStoneDamage } = useContext(topContext);
    // const { selectedOptionIsActive, setSelectedOptionIsActive } = useContext(false);

    const { activeSlide, setActiveSlide } = useState({ horizontal: 0, vertical: 0 });
    // let activeSlideHorizontal = 0;
    const [activeSlideHorizontal, setActiveSlideHorizontal] = useState(0);
    const [activeSlideVertical, setActiveSlideVertical] = useState([0, 0, 0, 0]);

    const [ballColorSelected, setBallColorSelected] = useState(false);


    // This is the selected option within each vertical swiper
    // let selectedOptionInHorizontal = [-1, -1, -1, -1];
    const [selectedOptionInHorizontal, setSelectedOptionInHorizontal] = useState([-1, -1, -1, -1]);

    const [infoButton, setInfoButton] = useState(false);
    const [descriptionText, setDescriptionText] = useState("");

    const [swipeBallFocus, setSwipeBallFocus] = useState("kaskoskadetyper");

    const descriptionTexts = [
        "Hvilken kaskoskade har din bil fået?"
        , "Min bil har fået en stenslagsskade"
        , "Min bil har fået en bilkabule"
        , "Min bil har fået en haglskade"
    ]

    function toggleInfoButton() {
        setInfoButton(!infoButton)
    }

    function damage(horizontal, vertical) {
        if (selectedOptionInHorizontal[horizontal] == vertical) {
            setSelectedOptionInHorizontal(selectedOptionInHorizontal.map((c, i) => {
                if (i === horizontal) {
                    return -1;
                } else {
                    return c;
                }
            }));
            setBallColorSelected(false);
        } else {
            setSelectedOptionInHorizontal(selectedOptionInHorizontal.map((c, i) => {
                if (i === horizontal) {
                    return vertical;
                } else {
                    return c;
                }
            }));
            setBallColorSelected(true)
        }
    }

    function checkBallColor(activeHorizontalIndex, activeVerticalIndex) {
        setBallColorSelected(selectedOptionInHorizontal[activeHorizontalIndex] == activeVerticalIndex)
    }

    const handleHorizontalSlideChange = (swiper) => {
        setActiveSlideHorizontal(swiper.activeIndex);
        checkBallColor(swiper.activeIndex, activeSlideVertical[swiper.activeIndex])
        console.log('ActiveSlideHorizontal:ActiveSlideVertical: ', activeSlideHorizontal, ':', activeSlideVertical[activeSlideHorizontal]);
    };

    const handleVerticalSlideChange0 = (swiper) => {
        setActiveSlideVertical(activeSlideVertical.map((c, i) => {
            if (i === 1) {
                return swiper.activeIndex;
            } else {
                return c;
            }
        }));
        checkBallColor(activeSlideHorizontal, swiper.activeIndex)
    };

    const handleVerticalSlideChange1 = (swiper) => {
        setActiveSlideVertical(activeSlideVertical.map((c, i) => {
            if (i === 2) {
                return swiper.activeIndex;
            } else {
                return c;
            }
        }));
    };

    const handleVerticalSlideChange2 = (swiper) => {
        setActiveSlideVertical(activeSlideVertical.map((c, i) => {
            if (i === 3) {
                // Increment the clicked counter
                return swiper.activeIndex;
            } else {
                return c;
            }
        }));
    };


    return (

        <div className='container'>

            <div className='top-bar'></div>

            <div className='box-1'>
                <img src={logo} className='logo' alt="logo" />
            </div>

            <div className='box-flex'>
                {ballColorSelected ?
                    <img src={swiperballSelected} className='swipe-ball' alt="ball" /> : <img src={swiperball} className='swipe-ball' alt="ball" />}

                <div className='centered'>

                    {/* Top level - kaskoskadetyper */}

                    <Swiper
                        className="my-swiper-h"
                        spaceBetween={50}
                        onSlideChange={handleHorizontalSlideChange}
                    >
                        <SwiperSlide>Swipe og vælg</SwiperSlide>
                        <SwiperSlide>
                            <Swiper
                                className="my-swiper-v"
                                direction={'vertical'}
                                spaceBetween={50}
                                onSlideChange={handleVerticalSlideChange0}
                            >
                                <SwiperSlide>
                                    <div>
                                        <img src={stenslag} className='swiper-icon' />
                                        <p className='swiper-icon-label'>stenslag</p>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div onClick={() => damage(1, 1)}>stenslag i lygten</div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div onClick={() => damage(1, 2)}>stenslag i lakken</div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div onClick={() => damage(1, 3)}>stenslag i ruden</div>
                                </SwiperSlide>
                            </Swiper>
                        </SwiperSlide>
                        <SwiperSlide>
                            <Swiper
                                className="mySwiper3 swiper-v"
                                direction={'vertical'}
                                spaceBetween={50}
                                onSlideChange={handleVerticalSlideChange1}
                            >
                                <SwiperSlide> <div>
                                    <BsFillCarFrontFill size={120} />
                                    <p className='swiper-icon-label'>bilkabule</p>
                                </div></SwiperSlide>
                                <SwiperSlide>Vertical Slide 3.1</SwiperSlide>
                                <SwiperSlide>Vertical Slide 3.2</SwiperSlide>
                                <SwiperSlide>Vertical Slide 3.3</SwiperSlide>
                                <SwiperSlide>Vertical Slide 3.4</SwiperSlide>
                                <SwiperSlide>Vertical Slide 3.5</SwiperSlide>
                            </Swiper>
                        </SwiperSlide>

                        <SwiperSlide>
                            <Swiper
                                className="mySwiper3 swiper-v"
                                direction={'vertical'}
                                spaceBetween={50}
                                onSlideChange={handleVerticalSlideChange2}
                            >

                                <SwiperSlide>
                                    <div>
                                        <BsFillCloudHailFill size={100} />
                                        <p className='swiper-icon-label'>haglskade</p>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>Vertical Slide 4.1</SwiperSlide>
                                <SwiperSlide>Vertical Slide 4.2</SwiperSlide>
                                <SwiperSlide>Vertical Slide 4.3</SwiperSlide>
                                <SwiperSlide>Vertical Slide 4.4</SwiperSlide>
                                <SwiperSlide>Vertical Slide 4.5</SwiperSlide>
                            </Swiper>
                        </SwiperSlide>
                    </Swiper >
                </div>
            </div >

            <div className='box-3 description-text'>{descriptionText}</div>

            {/* <div className='info-button-overlay'></div> */}

            <Button className='info-button-overlay' onClick={toggleInfoButton}></Button>
        </div >
    );

}
export default ComprehensiveSwiperMain;