import React, { useContext, useState } from "react";
import "../../../App.css";
import "../../../translations/i18n";

import { Button } from "react-bootstrap";
import Modal from "react-modal";

import { evaluationContext } from "../../contexts/EvaluationContext";
import { displayContext } from "../../../common/DisplayContext";
import { errorContext } from "../../contexts/ErrorContext";

import styles from '../../styles.module.css'
///////////////////////////////////////////////////////////////////////////////////
// react-modal stuff start
///////////////////////////////////////////////////////////////////////////////////

// https://www.npmjs.com/package/react-modal

const customStyles = {
    content: {
        top: "20%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        background: "#E6E6E6",
        width: "25%",
    },
    overlay: {
        zIndex: 1000,
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        // backgroundColor: "rgba(255, 255, 255, 0.75)",
        // backgroundColor: "#BFB2B1B1",
        backgroundColor: "rgb(102,102,102,0.8)",
    },
};

///////////////////////////////////////////////////////////////////////////////////
// react-modal stuff end
///////////////////////////////////////////////////////////////////////////////////

function ButtonAutoLetter() {
    ///////////////////////////////////////////////////////////////////////////////////
    // react-modal stuff start
    ///////////////////////////////////////////////////////////////////////////////////

    let subtitle = "subtitle";
    const [modalIsOpen, setIsOpen] = React.useState(false);

    function openModal() {
        setIsOpen(true);
    }

    function afterOpenModal() {
        // references are now sync'd and can be accessed.
        subtitle.style.color = "#f00";
    }

    function closeModal() {
        setIsOpen(false);
    }

    ///////////////////////////////////////////////////////////////////////////////////
    // react-modal stuff end
    ///////////////////////////////////////////////////////////////////////////////////

    const { buttonLetterDisabled, t } = useContext(displayContext);

    const { conclusionLastTrue, explanationLastTrue, explanationFiltered } =
        useContext(evaluationContext);

    const { setErrors } = useContext(errorContext);

    const [show, setShow] = useState(false);

    const findFormErrors = () => {
        const newErrors = {};

        return newErrors;
    };

    function submitLetterHandler(e) {
        e.preventDefault();

        // Get our new errors
        const newErrors = findFormErrors();
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors);
        } else {
            // No errors! Put any logic here for the form submission!
            openModal();
        }
    }

    const handleClose = () => setShow(false);

    const tmpDisabled = true;

    return (
        <>
            <Button
                className={styles.standardButton}
                variant="success"
                size="sm"
                disabled={buttonLetterDisabled}
                onClick={submitLetterHandler}
            >
                {t("letter")}
            </Button>

            <Modal
                isOpen={modalIsOpen}
                // onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                {t("letterHeading")}

                <p>{t("letterIntro")}</p>

                <p>
                    {t("letterConclusion")}&nbsp;
                    {conclusionLastTrue}
                </p>

                <p>
                    {t("letterExplanation")}&nbsp;
                    {explanationLastTrue}
                </p>

                {/* <p>{t("letterRules")}</p>

                {explanationFiltered.map(function(d, idx) {
                    if (d.displayCode === 0) {
                        return <li key={idx}>{d.letterRuleTrue}</li>;
                    } else if (d.displayCode === 1) {
                        return <li key={idx}>{d.letterRuleFalse}</li>;
                    }
                })} */}

                <p>Venlig hilsen kommunen</p>

                <div>
                    <button
                        style={{
                            float: "right",
                            marginTop: "30px",
                        }}
                        className="mentor-button1"
                        onClick={closeModal}
                    >
                        {t("close")}
                    </button>
                </div>
            </Modal>
        </>
    );
}

export default ButtonAutoLetter;
