import React, {
    useState,
    useContext,
    useEffect,
    useCallback,
    useRef,
} from 'react';

import Modal from 'react-modal';

import { useNavigate } from 'react-router-dom';

import { commonContext } from '../../common/CommonContext';
import { displayContext } from '../../common/DisplayContext';
import { attributesContext } from '../../admin/contexts/AttributesContext';
import { errorContext } from '../../admin/contexts/ErrorContext';
import { versionContext } from '../contexts/Context';

import 'bootstrap/dist/css/bootstrap.min.css';

import styles from '../styles.module.css';

import useEvaluate from '../hooks/useEvaluate';

import { BsTriangleFill } from 'react-icons/bs';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import RadioGroup from './RadioGroup';
import CheckboxGroup from './CheckboxGroup';
import MixedGroup from './MixedGroup';
import Dropdown from './Dropdown';
import Dropdown1 from './Dropdown1';

// import MenuItems from './MenuItems';
// import menuItemsList from './menuItemsList';

import Linkify from 'react-linkify';

const customStyles = {
    content: {
        top: '40%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        background: '#E6E6E6',
        width: '25%',
    },
    overlay: {
        zIndex: 1000,
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgb(102,102,102,0.8)',
    },
};

function Forebyggende() {
    let navigate = useNavigate();
    const { t2 } = useContext(displayContext);

    const [modalAction1, setModalAction1] = useState(false);
    const [modalAction2, setModalAction2] = useState(false);
    const [modalAction3, setModalAction3] = useState(false);
    const [modalAction4, setModalAction4] = useState(false);
    const [modalAction5, setModalAction5] = useState(false);
    const [modalAction6, setModalAction6] = useState(false);
    const [modalAction7, setModalAction7] = useState(false);
    const [modalAction8, setModalAction8] = useState(false);
    const [modalAction9, setModalAction9] = useState(false);
    const [modalAction10, setModalAction10] = useState(false);
    const [modalAction11, setModalAction11] = useState(false);

    const { currentAttributes } = useContext(attributesContext);

    const { setErrors } = useContext(errorContext);

    const [{ handleEvaluate }] = useEvaluate();

    const {
        evaluationResult,
        // setEvaluationResult,
        attributesOpenApp,
        setAttributesOpenApp,
        setUnderretningEvaluationResult,
        setScreeningEvaluationResult,
        groupsEvaluationResult,
        setGroupsEvaluationResult,
        caseNo,
    } = useContext(versionContext);

    useEffect(() => {
        if (caseNo == 'xxx-1111') {
            setOption2('alder', 10, 'dummy_mode');
            setOption2('direkte_alvorlig_skadelig', 'yes', 'dummy_mode');
        } else if (caseNo == 'def-5678') {
            setOption2('alder', 16, 'dummy_mode');
            setOption2('direkte_alvorlig_skadelig', 'no', 'dummy_mode');
        }
    }, [caseNo]);

    const { processing } = useContext(commonContext);

    const [infoButton, setInfoButton] = useState(false);
    const [showConclusion, setShowConclusion] = useState(false);
    const [showConclusionGroup, setShowConclusionGroup] = useState('');

    const [pageCleared, setPageCleared] = useState(true);

    const [selectedOption, setSelectedOption] = useState('Select...');

    const [dropdown, setDropdown] = useState(false);

    let allResult = {};

    let risksResult = {};

    let dummy_result = [];

    let result = [];

    function handleOptionClick(attributeName, value, mode) {
        setOption2(attributeName, value, mode);
    }

    function setOption2(attributeName, value, mode) {
        setAttributesOpenApp((prevData) => {
            const updatedData = prevData.map((item) => {
                if (item.name == attributeName) {
                    return {
                        ...item,
                        selected: value != item.value,
                        value: value == item.value ? '' : value,
                    };
                }
                return item;
            });
            return updatedData;
        });
    }

    const setDropdownOption = (e) => {
        console.log('setDropdownOption: ', e);
        setSelectedOption(e);
    };

    function clear() {
        // setSelectedOptions([]);
        setPageCleared(true);

        setAttributesOpenApp(
            attributesOpenApp.map((option) => {
                return {
                    ...option,
                    value: (option.value = ''),
                    test: (option.value = 0),
                    selected: false,
                };
            }),
        );
        setShowConclusion(false);
    }

    function findFormErrors() {
        // const errors = attributesOpenApp.some(attribute => attribute.selected == false);

        const errors = false;
        return errors;
    }

    async function evaluate() {
        setPageCleared(false);
        setShowConclusion(false);

        setUnderretningEvaluationResult({});
        setScreeningEvaluationResult({});
        setGroupsEvaluationResult({});

        // setAggConclusion('')
        // setRisksEvaluationResult({})

        // Validate input form
        const errors = findFormErrors();
        // Conditional logic:
        if (errors) {
            // We got errors!
            setErrors(errors);
        } else {
            // No errors! Put any logic here for the form submission!

            // if (
            //     risksResult.conclusionLastTrue == 'passed'
            // )
            //     setAggConclusion('dækket:')
            // else
            //     setAggConclusion('ikke dækket:')

            // Here we evaluate each evaluationGroup in isolation, but all in one call to the backend
            // The evaluationGroup variable is set in context.js...

            // result = await handleEvaluate('karnov', 'all')
            // result = await handleEvaluate('karnov', 'byEvaluationGroup')
            dummy_result = await handleEvaluate('karnov', 'all');

            setShowConclusion(true);
        }
    }

    // // ////////////////////////////////
    // // Overflow indicator arrow - START
    // // ////////////////////////////////

    // useEffect(() => {
    //     checkOverflow('column1-div');
    //     // checkOverflow('column2-div');
    //     // checkOverflow('conclusion-div');

    //     // // Add scroll event listeners to both divs
    //     document
    //         .getElementById('column1-div')
    //         .addEventListener('scroll', function () {
    //             handleScroll('column1-div');
    //         });

    //     // document.getElementById('column2-div').addEventListener('scroll', function () {
    //     //     handleScroll('column2-div');
    //     // });

    //     // document.getElementById('conclusion-div').addEventListener('scroll', function () {
    //     //     handleScroll('conclusion-div');
    //     // });
    // }, []);

    // // Function to check for overflow and show/hide indicator
    // function checkOverflow(divId) {
    //     var div = document.getElementById(divId);

    //     if (div.scrollHeight > div.clientHeight) {
    //         showIndicator(divId);
    //     } else {
    //         hideIndicator(divId);
    //     }
    // }

    // // Function to handle scroll events and show/hide indicator based on position
    // function handleScroll(divId) {
    //     var div = document.getElementById(divId);

    //     console.log(div.scrollTop + div.offsetHeight, ', ', div.scrollHeight);

    //     // if (div.scrollHeight - div.scrollTop === div.clientHeight) {
    //     if (div.scrollTop + div.offsetHeight >= div.scrollHeight) {
    //         hideIndicator(divId);
    //     } else {
    //         showIndicator(divId);
    //     }
    // }

    // // Function to show indicator
    // function showIndicator(divId) {
    //     var indicator = document.getElementById(divId + 'Indicator');
    //     indicator.style.display = 'block';
    // }

    // // Function to hide indicator
    // function hideIndicator(divId) {
    //     var indicator = document.getElementById(divId + 'Indicator');
    //     indicator.style.display = 'none';
    // }

    // // ////////////////////////////////
    // // Overflow indicator arrow - END
    // // ////////////////////////////////

    const onMouseEnter = () => {
        window.innerWidth > 960 && setDropdown(true);
    };

    const onMouseLeave = () => {
        window.innerWidth > 960 && setDropdown(false);
    };

    let ref = useRef();

    const dropdownBtn = document.getElementById('btn');
    const dropdownMenu = document.getElementById('dropdown');
    const toggleArrow = document.getElementById('arrow');

    function handleDropdownOption(e) {
        console.log('handleDropdownOption: ', e);
        setDropdownButtonText(e.id);
    }

    const [dropdownButtonText, setDropdownButtonText] = useState('Select...');
    const [dropdownButton, setDropdownButton] = useState(false);

    function dropDownbuttonVisible() {
        setDropdownButton(true);
        console.log('dropDownbuttonVisible');
    }

    function dropDownbuttonHidden() {
        setDropdownButton(false);
        console.log('dropDownbuttonHidden');
    }
    const [selectedValue, setSelectedValue] = useState(null);

    const handleDropdownChange = (event) => {
        setSelectedValue(event.target.value);
        console.log(event.target.value);
    };

    // const handleMouseEnter = (event) => {
    //     event.target.size = event.target.length;
    // };

    // const handleMouseLeave = (event) => {
    //     event.target.size = 1;
    // };

    const [selectedValue1, setSelectedValue1] = useState(null);

    const handleSelectChange = (event) => {
        const selectedOption = event.target.value;
        setSelectedValue1(selectedOption);
        handleOptionClick(event.target.name, selectedOption, 'add');
    };

    function group(group) {
        return (
            <div className={styles.desktopOptionGroup}>
                {attributesOpenApp
                    .filter((attribute) => attribute.groupname == group)
                    .map((attribute) => {
                        if (attribute.uitype == 'check')
                            return (
                                // <div className={pageCleared || attribute.selected ? [styles.selectionBoxNoErrorOption].join(' ') : [styles.selectionBoxNoErrorOption, styles.selectionBoxErrorOption].join(' ')} >
                                <div
                                    className={styles.selectionBoxNoErrorOption}
                                >
                                    <OverlayTrigger
                                        delay={400}
                                        delayHide={0}
                                        placement={'auto'}
                                        overlay={
                                            <Tooltip>
                                                {attribute.displayname}
                                            </Tooltip>
                                        }
                                    >
                                        <div
                                            className={
                                                styles.selectionBoxAttributeText
                                            }
                                        >
                                            {attribute.label}
                                        </div>
                                    </OverlayTrigger>

                                    <div
                                        className={
                                            styles.selectionBoxRadioButtons
                                        }
                                    >
                                        <div
                                            className={
                                                styles.desktopSelectOption
                                            }
                                            onClick={() =>
                                                handleOptionClick(
                                                    attribute.name,
                                                    '1',
                                                    'add',
                                                )
                                            }
                                        >
                                            <input
                                                type="checkbox"
                                                name="yes"
                                                className={
                                                    styles.checkboxToRadio
                                                }
                                                checked={attribute.value == '1'}
                                            />
                                            {t2('yes', 'karnov')}
                                        </div>

                                        <div
                                            className={
                                                styles.desktopSelectOption
                                            }
                                            onClick={() =>
                                                handleOptionClick(
                                                    attribute.name,
                                                    '2',
                                                    'add',
                                                )
                                            }
                                        >
                                            <input
                                                type="checkbox"
                                                className="checkbox-to-radio"
                                                checked={attribute.value == '2'}
                                            />
                                            {t2('no', 'karnov')}
                                        </div>
                                    </div>
                                </div>
                            );
                        else if (attribute.uitype == 'dropdown')
                            return (
                                // <div className={pageCleared || attribute.selected ? [styles.selectionBoxNoErrorOption].join(' ') : [styles.selectionBoxNoErrorOption, styles.selectionBoxErrorOption].join(' ')} >
                                <div
                                    className={styles.selectionBoxNoErrorOption}
                                >
                                    <div className={styles.dropdownLine}>
                                        <OverlayTrigger
                                            delay={400}
                                            delayHide={0}
                                            placement={'auto'}
                                            overlay={
                                                <Tooltip>
                                                    {attribute.displayname}
                                                </Tooltip>
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.selectionBoxAttributeText
                                                }
                                            >
                                                {attribute.displayname}
                                            </div>
                                        </OverlayTrigger>

                                        <div
                                            className={styles.dropdownContainer}
                                        >
                                            <select
                                                className={styles.dropdown}
                                                value={selectedValue}
                                                name={attribute.name}
                                                onChange={handleSelectChange}
                                            >
                                                <option value="select">
                                                    {t2(
                                                        'selectAnOption',
                                                        'karnov',
                                                    )}
                                                </option>
                                                <option value="ægtefælle">
                                                    Ægtefælle / samlever
                                                </option>
                                                <option value="husstand">
                                                    Medlem af husstanden
                                                </option>
                                                <option value="bruger">
                                                    Den faste bruger
                                                </option>
                                                <option value="anden">
                                                    Ingen af ovenstående
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            );
                    })}
            </div>
        );
    }

    function isEmptyObject(obj) {
        return obj && typeof obj === 'object' && Object.keys(obj).length === 0;
    }

    function resultForEvaluationGroup(evaluationGroupResults, group) {
        if (!isEmptyObject(evaluationGroupResults)) {
            // Convert object of objects to array of objects
            const evaluationGroupResultsArray = Object.values(
                evaluationGroupResults,
            );
            const filteredEvaluationGroupResults =
                evaluationGroupResultsArray.filter(
                    (obj) => obj.group === group,
                )[0];

            return (
                <>
                    {filteredEvaluationGroupResults.explanationLastTrue != '' &&
                    filteredEvaluationGroupResults.explanationLastTrue !=
                        null ? (
                        <div>
                            <div
                                className={
                                    filteredEvaluationGroupResults.conclusionLastTrue ==
                                    'passed'
                                        ? 'desktop-explanation-box desktop-explanation-approved'
                                        : 'desktop-explanation-box desktop-explanation-rejected'
                                }
                            >
                                <Linkify
                                    componentDecorator={(
                                        decoratedHref,
                                        decoratedText,
                                        key,
                                    ) => (
                                        <a
                                            target="blank"
                                            href={decoratedHref}
                                            key={key}
                                        >
                                            {t2('labelLink', 'karnov')}
                                        </a>
                                    )}
                                >
                                    {t2(
                                        filteredEvaluationGroupResults.explanationLastTrue,
                                        'karnov',
                                    )}
                                </Linkify>
                            </div>
                            <div>
                                {/* {t2(filteredEvaluationGroupResults.conclusionLastTrue, "karnov")} */}

                                <pre style={{ whiteSpace: 'pre-wrap' }}>
                                    {
                                        filteredEvaluationGroupResults.conclusionLastTrue
                                    }
                                </pre>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </>
            );
        }
    }

    function showEvaluationResult(evaluation_result) {
        // if (!isEmptyObject(evaluation_result)) {
        if (evaluation_result[0] != undefined) {
            // Convert object of objects to array of objects
            const evaluation_result_array = Object.values(evaluation_result);

            return (
                <>
                    {
                        <pre style={{ whiteSpace: 'pre-wrap' }}>
                            {evaluation_result[0].results.map((result) => (
                                <div>
                                    <div className={styles.conclusion_heading}>
                                        {result.explanation}
                                    </div>
                                    <div className={styles.conclusion_details}>
                                        {result.conclusion}
                                    </div>
                                </div>
                            ))}
                        </pre>
                    }
                </>
            );
        }
    }

    function showEvaluationResultGroup(evaluation_result) {
        if (evaluation_result[0] != undefined) {
            // Convert object of objects to array of objects
            const evaluation_result_array = Object.values(evaluation_result);

            return (
                <>
                    {
                        <pre style={{ whiteSpace: 'pre-wrap' }}>
                            {evaluation_result[0].results
                                .filter(
                                    (result) =>
                                        result.conclusion_group ==
                                        showConclusionGroup,
                                )
                                .map((result) => (
                                    <div>
                                        <div
                                            className={
                                                styles.conclusion_heading
                                            }
                                        >
                                            {result.explanation}
                                        </div>
                                        <div
                                            className={
                                                styles.conclusion_details
                                            }
                                        >
                                            {result.conclusion}
                                        </div>
                                    </div>
                                ))}
                        </pre>
                    }
                </>
            );
        }
    }

    function resultForAll(evaluationResult) {
        if (!isEmptyObject(evaluationResult)) {
            // Convert object of objects to array of objects

            return (
                <>
                    {evaluationResult.explanationLastTrue != '' ? (
                        <div
                            className={
                                evaluationResult.conclusionLastTrue == 'passed'
                                    ? 'desktop-explanation-box desktop-explanation-approved'
                                    : 'desktop-explanation-box desktop-explanation-rejected'
                            }
                        >
                            <Linkify
                                componentDecorator={(
                                    decoratedHref,
                                    decoratedText,
                                    key,
                                ) => (
                                    <a
                                        target="blank"
                                        href={decoratedHref}
                                        key={key}
                                    >
                                        {t2('labelLink', 'karnov')}
                                    </a>
                                )}
                            >
                                {t2(
                                    evaluationResult.explanationLastTrue,
                                    'karnov',
                                )}
                            </Linkify>
                        </div>
                    ) : (
                        ''
                    )}
                </>
            );
        }
    }

    const førerDropdown = [
        'vælg...',
        'ægtefælle',
        'husstand',
        'bruger',
        'anden',
    ];

    const evaluate_conclusion_group = (conclusion_group) => {
        setShowConclusionGroup(conclusion_group);
        evaluate();
    };

    function HandleAction(e) {
        navigate('/evaluateCase');
    }

    function setModalAction1True() {
        setModalAction1(true);
    }

    function setModalAction2True() {
        setModalAction2(true);
    }

    function setModalAction3True() {
        setModalAction3(true);
    }

    function setModalAction4True() {
        setModalAction4(true);
    }

    function setModalAction5True() {
        setModalAction5(true);
    }

    function setModalAction6True() {
        setModalAction6(true);
    }

    function setModalAction7True() {
        setModalAction7(true);
    }

    function setModalAction8True() {
        setModalAction8(true);
    }

    function setModalAction9True() {
        setModalAction9(true);
    }

    function setModalAction10True() {
        setModalAction10(true);
    }

    function setModalAction11True() {
        setModalAction11(true);
    }

    function ModalAction1() {
        function closeModal() {
            setModalAction1(false);
        }

        return (
            <>
                <Modal
                    isOpen={modalAction1}
                    onRequestClose={closeModal}
                    style={customStyles}
                >
                    <p
                        className={styles.groupHeading}
                        style={{ marginBottom: '1vh' }}
                    >
                        Inddragelse af barnet eller den unge
                    </p>

                    <p>
                        Du skal inddrage barnet eller den unge inden du træffer
                        beslutning om en tidligt forebyggende indsats og du skal
                        tillægge barnets eller den unges holdning og synspunkter
                        vægt. Husk at du kan inddrage barnet eller den unge uden
                        samtykke fra forældremyndighedsindehaver og uden dennes
                        tilstedeværelse.
                    </p>

                    <p>
                        Hvis barnet eller den unge er fyldt 10 år, skal du
                        partshøre barnet eller den unge i forhold til de
                        oplysninger, der ligger til grund for afgørelsen.
                    </p>

                    <div>
                        <button
                            style={{
                                float: 'right',
                                marginTop: '30px',
                            }}
                            className="mentor-button1"
                            onClick={closeModal}
                        >
                            {'OK'}
                        </button>
                    </div>
                </Modal>
            </>
        );
    }

    function ModalAction2() {
        function closeModal() {
            setModalAction2(false);
        }

        return (
            <>
                <Modal
                    isOpen={modalAction2}
                    onRequestClose={closeModal}
                    style={customStyles}
                >
                    <p
                        className={styles.groupHeading}
                        style={{ marginBottom: '1vh' }}
                    >
                        Barnets eller den unges holdning og synspunkter skal
                        tilvejebringes
                    </p>

                    <p>
                        Barnets eller den unges holdning og synspunkter skal
                        tilvejebringes ved samtaler og anden direkte kontakt.
                        Kravet kan i helt særlige tilfælde helt eller delvist
                        fraviges, hvis barnets eller den unges alder eller andre
                        forhold i afgørende grad taler imod det. I disse
                        tilfælde skal du tilvejebringe barnets eller den unges
                        holdning og synspunkter på anden vis.
                    </p>

                    <div>
                        <button
                            style={{
                                float: 'right',
                                marginTop: '30px',
                            }}
                            className="mentor-button1"
                            onClick={closeModal}
                        >
                            {'OK'}
                        </button>
                    </div>
                </Modal>
            </>
        );
    }

    function ModalAction3() {
        function closeModal() {
            setModalAction3(false);
        }

        return (
            <>
                <Modal
                    isOpen={modalAction3}
                    onRequestClose={closeModal}
                    style={customStyles}
                >
                    <p
                        className={styles.groupHeading}
                        style={{ marginBottom: '1vh' }}
                    >
                        Vejledning om bisidder
                    </p>

                    <p>
                        Du skal huske at vejlede om retten til bisidder inden
                        møder med barnet eller den unge.
                    </p>

                    <div>
                        <button
                            style={{
                                float: 'right',
                                marginTop: '30px',
                            }}
                            className="mentor-button1"
                            onClick={closeModal}
                        >
                            {'OK'}
                        </button>
                    </div>
                </Modal>
            </>
        );
    }

    function ModalAction4() {
        function closeModal() {
            setModalAction4(false);
        }

        return (
            <>
                <Modal
                    isOpen={modalAction4}
                    onRequestClose={closeModal}
                    style={customStyles}
                >
                    <p
                        className={styles.groupHeading}
                        style={{ marginBottom: '1vh' }}
                    >
                        Partshøring af forældrene
                    </p>

                    <p>
                        Inden du træffer afgørelse om støttende indsatser skal
                        du partshøre forældremyndighedsindehaver i forhold til
                        de oplysninger, der ligger til grund for afgørelsen.
                    </p>
                    <p
                        className={styles.groupHeading}
                        style={{ marginBottom: '1vh' }}
                    >
                        Vejledning om bisidder
                    </p>
                    <p>
                        Du skal huske at vejlede om retten til bisidder inden
                        møder med forældremyndighedsindehaver.
                    </p>

                    <div>
                        <button
                            style={{
                                float: 'right',
                                marginTop: '30px',
                            }}
                            className="mentor-button1"
                            onClick={closeModal}
                        >
                            {'OK'}
                        </button>
                    </div>
                </Modal>
            </>
        );
    }

    function ModalAction5() {
        function closeModal() {
            setModalAction5(false);
        }

        return (
            <>
                <Modal
                    isOpen={modalAction5}
                    onRequestClose={closeModal}
                    style={customStyles}
                >
                    <p
                        className={styles.groupHeading}
                        style={{ marginBottom: '1vh' }}
                    >
                        Afgørelse om indsats
                    </p>

                    <p>
                        Du skal træffe afgørelse om en eller flere støttende
                        indsatser, når betingelserne herfor er opfyldt.
                        Afgørelsen skal angive formålet med indsatsen og den
                        forventede varighed.
                    </p>

                    <div>
                        <button
                            style={{
                                float: 'right',
                                marginTop: '30px',
                            }}
                            className="mentor-button1"
                            onClick={closeModal}
                        >
                            {'OK'}
                        </button>
                    </div>
                </Modal>
            </>
        );
    }

    function ModalAction6() {
        function closeModal() {
            setModalAction6(false);
        }

        return (
            <>
                <Modal
                    isOpen={modalAction6}
                    onRequestClose={closeModal}
                    style={customStyles}
                >
                    <p
                        className={styles.groupHeading}
                        style={{ marginBottom: '1vh' }}
                    >
                        De forvaltningsretlige krav til afgørelsen
                    </p>

                    <p>
                        Afgørelsen skal være skriftlig og indeholde begrundelse,
                        henvisning til lovgrundlaget og klagevejledning.
                    </p>

                    <div>
                        <button
                            style={{
                                float: 'right',
                                marginTop: '30px',
                            }}
                            className="mentor-button1"
                            onClick={closeModal}
                        >
                            {'OK'}
                        </button>
                    </div>
                </Modal>
            </>
        );
    }

    function ModalAction7() {
        function closeModal() {
            setModalAction7(false);
        }

        return (
            <>
                <Modal
                    isOpen={modalAction7}
                    onRequestClose={closeModal}
                    style={customStyles}
                >
                    <p
                        className={styles.groupHeading}
                        style={{ marginBottom: '1vh' }}
                    >
                        Modtagere af afgørelsen
                    </p>

                    <p>
                        Det er forældremyndighedsindehaver og barnet eller den
                        unge, der er fyldt 10 år, der skal modtage afgørelsen.
                        Afgørelsen sendes digitalt til forældrene. Afgørelsen
                        bør udleveres til barnet eller den unge i forbindelse
                        med en mundtlig gennemgang.
                    </p>

                    <div>
                        <button
                            style={{
                                float: 'right',
                                marginTop: '30px',
                            }}
                            className="mentor-button1"
                            onClick={closeModal}
                        >
                            {'OK'}
                        </button>
                    </div>
                </Modal>
            </>
        );
    }

    function ModalAction8() {
        function closeModal() {
            setModalAction8(false);
        }

        return (
            <>
                <Modal
                    isOpen={modalAction8}
                    onRequestClose={closeModal}
                    style={customStyles}
                >
                    <p
                        className={styles.groupHeading}
                        style={{ marginBottom: '1vh' }}
                    >
                        Iværksættelse
                    </p>

                    <p>
                        Indsatsen skal iværksættes straks efter afgørelsen er
                        truffet, medmindre:
                    </p>

                    <ul>
                        <li>Særlige forhold gør det umuligt</li>
                        <li>
                            Afgørelsen indeholder flere forskellige tiltag, som
                            ikke skal iværksættes samtidigt
                        </li>
                    </ul>

                    <div>
                        <button
                            style={{
                                float: 'right',
                                marginTop: '30px',
                            }}
                            className="mentor-button1"
                            onClick={closeModal}
                        >
                            {'OK'}
                        </button>
                    </div>
                </Modal>
            </>
        );
    }

    function ModalAction9() {
        function closeModal() {
            setModalAction9(false);
        }

        return (
            <>
                <Modal
                    isOpen={modalAction9}
                    onRequestClose={closeModal}
                    style={customStyles}
                >
                    <p
                        className={styles.groupHeading}
                        style={{ marginBottom: '1vh' }}
                    >
                        Beslutning om barnets plan/ungeplan
                    </p>

                    <p>
                        Du skal tage stilling til, om der skal udarbejdes en
                        barnets plan eller en ungeplan i forbindelse med
                        afgørelsen om støttende indsatser. Din beslutning om
                        behovet for en plan skal tage udgangspunkt i barnets
                        eller den unges egne ønsker og behov, samt tage hensyn
                        til forholdende i den konkrete sag. Du skal derfor huske
                        at inddrage barnet eller den unge i beslutningen. Husk
                        at du altid skal foretage en konkret og individuel
                        vurdering.
                    </p>

                    <div>
                        <button
                            style={{
                                float: 'right',
                                marginTop: '30px',
                            }}
                            className="mentor-button1"
                            onClick={closeModal}
                        >
                            {'OK'}
                        </button>
                    </div>
                </Modal>
            </>
        );
    }

    function ModalAction10() {
        function closeModal() {
            setModalAction10(false);
        }

        return (
            <>
                <Modal
                    isOpen={modalAction10}
                    onRequestClose={closeModal}
                    style={customStyles}
                >
                    <p
                        className={styles.groupHeading}
                        style={{ marginBottom: '1vh' }}
                    >
                        Opfølgning
                    </p>

                    <p>
                        Du skal løbende følge op på barnets eller den unges
                        trivsel og udvikling, når der er iværksat en støttende
                        indsats.
                    </p>

                    <div>
                        <button
                            style={{
                                float: 'right',
                                marginTop: '30px',
                            }}
                            className="mentor-button1"
                            onClick={closeModal}
                        >
                            {'OK'}
                        </button>
                    </div>
                </Modal>
            </>
        );
    }

    function ModalAction11() {
        function closeModal() {
            setModalAction11(false);
        }

        return (
            <>
                <Modal
                    isOpen={modalAction11}
                    onRequestClose={closeModal}
                    style={customStyles}
                >
                    <p
                        className={styles.groupHeading}
                        style={{ marginBottom: '1vh' }}
                    >
                        Afgørelse om ophør af indsats
                    </p>

                    <p>
                        Du skal træffe afgørelse om ophør af indsats, når
                        formålet er nået, når de ikke længere opfylder deres
                        formål, eller når den unge fylder 18 år. Husk at du
                        inden afgørelsen træffes skal inddrage barnet eller den
                        unge og partshøre forældremyndighedsindehaver.
                    </p>
                    <p
                        className={styles.groupHeading}
                        style={{ marginBottom: '1vh' }}
                    >
                        De forvaltningsretlige krav til afgørelsen
                    </p>
                    <p>
                        Afgørelsen skal være skriftlig og indeholde begrundelse,
                        henvisning til lovgrundlaget og klagevejledning.
                    </p>
                    <p
                        className={styles.groupHeading}
                        style={{ marginBottom: '1vh' }}
                    >
                        Modtagere af afgørelsen
                    </p>
                    <p>
                        Det er forældremyndighedsindehaver og barnet eller den
                        unge, der er fyldt 10 år, der skal modtage afgørelsen.
                        Afgørelsen sendes digitalt til forældrene. Afgørelsen
                        bør udleveres til barnet eller den unge i forbindelse
                        med en mundtlig gennemgang.
                    </p>
                    <div>
                        <button
                            style={{
                                float: 'right',
                                marginTop: '30px',
                            }}
                            className="mentor-button1"
                            onClick={closeModal}
                        >
                            {'OK'}
                        </button>
                    </div>
                </Modal>
            </>
        );
    }

    return (
        <>
            <div>
                <ModalAction1 />
                <ModalAction2 />
                <ModalAction3 />
                <ModalAction4 />
                <ModalAction5 />
                <ModalAction6 />
                <ModalAction7 />
                <ModalAction8 />
                <ModalAction9 />
                <ModalAction10 />
                <ModalAction11 />
                {attributesOpenApp && (
                    <div className={styles.desktopMain}>
                        <div className={styles.desktopColumn1}>
                            <div id="column1-divIndicator">
                                {/* <BsTriangleFill
                                    className={[
                                        styles.desktopOverflowIcon,
                                        styles.desktopOverflowIconColumn1,
                                    ].join(' ')}
                                /> */}
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div style={{ display: 'flex' }}>
                                    Sagsnr.: abc-1234
                                    {/* <Dropdown1 /> */}
                                </div>
                            </div>

                            <div
                                id="column1-div"
                                className={styles.desktopNoErrorBorder}
                            >
                                {/* {caseNo == 'xxx-1111' ? ( */}
                                <div>
                                    <div
                                        style={{
                                            overflowY: 'scroll',
                                            height: '20vh',
                                            marginTop: '1vh',
                                            marginBottom: '2vh',
                                            // borderTop:
                                            //     '15px solid rgba(255, 255, 255, .9)',
                                            // borderBottom:
                                            //     '15px solid rgba(255, 255, 255, .9)',
                                        }}
                                    >
                                        <p className={styles.groupHeading}>
                                            Sagsdokumentation
                                        </p>
                                        <div
                                            style={{
                                                display: 'flex',
                                                flexDirection: 'row',
                                                justifyContent: 'space-around',
                                                width: '30vw',
                                                height: '12vh',
                                                marginTop: '2vh',
                                            }}
                                        >
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '10vw',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Button
                                                    variant="success"
                                                    size="sm"
                                                    style={{
                                                        marginBottom: '2px',
                                                    }}
                                                >
                                                    {t2('dok1', 'karnov')}
                                                </Button>
                                                <Button
                                                    variant="success"
                                                    size="sm"
                                                    style={{
                                                        marginBottom: '2px',
                                                    }}
                                                >
                                                    {t2('dok2', 'karnov')}
                                                </Button>
                                            </div>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    flexDirection: 'column',
                                                    width: '10vw',
                                                    justifyContent:
                                                        'space-between',
                                                }}
                                            >
                                                <Button
                                                    variant="success"
                                                    size="sm"
                                                    style={{
                                                        marginBottom: '2px',
                                                    }}
                                                >
                                                    {t2('dok3', 'karnov')}
                                                </Button>
                                                <Button
                                                    variant="success"
                                                    size="sm"
                                                    style={{
                                                        marginBottom: '2px',
                                                    }}
                                                >
                                                    {t2('dok4', 'karnov')}
                                                </Button>
                                            </div>
                                        </div>
                                    </div>

                                    <div>
                                        <p className={styles.groupHeading}>
                                            Konkluderende punkter
                                        </p>
                                        <ul>
                                            <li>
                                                Gentagende problematik af
                                                alvorlig karakter, som har
                                                direkte skadende effekt
                                            </li>
                                        </ul>

                                        <p className={styles.groupHeading}>
                                            Fremhævede punkter - børnesamtale,
                                            forældresamtale
                                        </p>
                                        <div style={{ display: 'flex' }}>
                                            <ul style={{ width: '15vw' }}>
                                                <li>Misbrug af alkohol</li>
                                                <li>Skænderi</li>
                                                <li>Vold mellem forældre</li>
                                                <li>Aggressiv far</li>
                                                <li>
                                                    Politiet tilstede -
                                                    husspektakel
                                                </li>
                                                <li>
                                                    Store bekymringer for datter
                                                </li>
                                            </ul>

                                            <ul style={{ width: '15vw' }}>
                                                <li>Manglende søvn</li>
                                                <li>
                                                    Manglende fokus i skolen,
                                                    kan ikke huske noget og
                                                    heller ikke folge med
                                                </li>
                                                <li>
                                                    Datter handler, forsoger at
                                                    stoppe volden
                                                </li>
                                                <li>
                                                    Matematiklæreren er en
                                                    vigtig person
                                                </li>
                                            </ul>
                                        </div>

                                        <p className={styles.groupHeading}>
                                            Fremhævede punkter - børnefaglig
                                            undersøgelse
                                        </p>
                                        <div style={{ display: 'flex' }}>
                                            <ul style={{ width: '15vw' }}>
                                                <li>
                                                    To mindre søskende er
                                                    udsatte
                                                </li>
                                                <li>
                                                    Mistanke om misbrug af
                                                    barnet
                                                </li>
                                            </ul>
                                        </div>

                                        <p className={styles.groupHeading}>
                                            Fremhævede punkter - billeder fra
                                            hjemmet
                                        </p>
                                        <div style={{ display: 'flex' }}>
                                            <ul style={{ width: '15vw' }}>
                                                <li>
                                                    Hjemmet fremstår i pæn orden
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                {/* ) : null} */}
                            </div>
                        </div>

                        <div className={styles.desktopColumn2}>
                            <div id="column2-divIndicator">
                                {/* <BsTriangleFill
                                    className={[
                                        styles.desktopOverflowIcon,
                                        styles.desktopOverflowIconcolumn2,
                                    ].join(' ')}
                                /> */}

                                <div>
                                    <div>
                                        <p className={styles.groupHeading}>
                                            Risikofaktorer
                                        </p>

                                        <ul>
                                            <li>Misbrug i familien</li>
                                            <li>Vold mellem forældre</li>
                                            <li>
                                                Manglende koncentration i skolen
                                            </li>
                                            <li>Ukoncentreret i skolen</li>
                                        </ul>
                                    </div>

                                    <div>
                                        <p className={styles.groupHeading}>
                                            Beskyttelsesfaktorer
                                        </p>
                                        <ul>
                                            <li>Pigen taler om volden</li>
                                            <li>
                                                Pigen holder af begge sine
                                                forældre
                                            </li>
                                            <li>Pigen kommer i skole</li>
                                            <li>
                                                Matematiklæreren er en vigtig
                                                person
                                            </li>
                                        </ul>
                                    </div>

                                    <div>
                                        <p className={styles.groupHeading}>
                                            Lovgivning
                                        </p>
                                        <ul>
                                            <li>
                                                <a
                                                    // href="https://www.sm.dk/arbejdsomraader/boern-og-unge-i-udsatte-positioner/barnets-lov"
                                                    href="https://www.karnovgroup.dk/da-dk/kaila"
                                                    target="_blank"
                                                >
                                                    Barnets lov
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    // href="https://danskelove.dk/serviceloven"
                                                    href="https://www.karnovgroup.dk/da-dk/kaila"
                                                    target="_blank"
                                                >
                                                    Serviceloven
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    // href="https://www.retsinformation.dk/eli/lta/2022/1396"
                                                    href="https://www.karnovgroup.dk/da-dk/kaila"
                                                    target="_blank"
                                                >
                                                    Folkeskoleloven
                                                </a>
                                            </li>
                                        </ul>
                                    </div>

                                    <div>
                                        <p className={styles.groupHeading}>
                                            Relevant lovgivning
                                        </p>
                                        <ul>
                                            <li>
                                                <a
                                                    href="https://www.karnovgroup.dk/da-dk/kaila"
                                                    target="_blank"
                                                >
                                                    Barnets lov §32 støttende
                                                    indsatser
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.karnovgroup.dk/da-dk/kaila"
                                                    target="_blank"
                                                >
                                                    Serviceloven §101
                                                    misbrugsbehandling
                                                </a>
                                            </li>
                                            <li>
                                                <a
                                                    href="https://www.karnovgroup.dk/da-dk/kaila"
                                                    target="_blank"
                                                >
                                                    Folkeskoleloven §20-23
                                                    skolens ansvar
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* <div className={styles.desktopColumn2}> */}
                        <div
                            style={{
                                height: '100%',
                                width: '20vw',
                                padding: '20px',
                                display: 'flex',
                                flexDirection: 'column',
                                borderLeft: 'solid lightgrey',
                                width: '30vw',
                            }}
                        >
                            <div id="column2-divIndicator">
                                {/* <BsTriangleFill
                                    className={[
                                        styles.desktopOverflowIcon,
                                        styles.desktopOverflowIconcolumn2,
                                    ].join(' ')}
                                /> */}
                            </div>

                            {/* <div
                                id="column2-div"
                                className={styles.desktopNoErrorBorder}
                            ></div> */}

                            {/* {caseNo == 'xxx-1111' ? ( */}
                            <div>
                                <div>
                                    <p className={styles.groupHeading}>
                                        Anbefalede handlinger:
                                    </p>

                                    <ol>
                                        <li
                                            onClick={setModalAction1True}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Inddrag barnet eller den unge inden
                                            du træffer beslutning om en tidligt
                                            forebyggende indsats
                                        </li>
                                        <li
                                            onClick={setModalAction2True}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Barnets eller den unges holdning og
                                            synspunkter skal tilvejebringes
                                        </li>
                                        <li
                                            onClick={setModalAction3True}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Vejled om retten til bisidder inden
                                            møder med barnet eller den unge
                                        </li>
                                        <li
                                            onClick={setModalAction4True}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Partshør forældremyndighedsindehaver
                                            og vejlede om retten til bisidder
                                            inden mødet
                                        </li>
                                        <li
                                            onClick={setModalAction5True}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Du skal træffe afgørelse om en eller
                                            flere støttende indsatser
                                        </li>
                                        <li
                                            onClick={setModalAction6True}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Afgørelsen skal være skriftlig og
                                            indeholde begrundelse, henvisning
                                            til lovgrundlaget og klagevejledning
                                        </li>
                                        <li
                                            onClick={setModalAction7True}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Modtagere af afgørelsen er
                                            forældremyndighedsindehaver barnet
                                            eller den unge, hvis det er fyldt 10
                                            år
                                        </li>
                                        <li
                                            onClick={setModalAction8True}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Indsatsen skal iværksættes straks
                                            efter afgørelsen er truffet,
                                            medmindre:
                                        </li>
                                        <ul>
                                            <li>
                                                Særlige forhold gør det umuligt
                                            </li>
                                            <li>
                                                Afgørelsen indeholder flere
                                                forskellige tiltag, som ikke
                                                skal iværksættes samtidigt
                                            </li>
                                        </ul>
                                        <li
                                            onClick={setModalAction9True}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Hvis der er behov for en barnets
                                            plan eller en ungeplan, skal planen
                                            skal foreligge senest tre måneder
                                            efter afgørelsen om indsats
                                        </li>
                                        <li
                                            onClick={setModalAction10True}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Følg løbende op på barnets eller den
                                            unges trivsel og udvikling
                                        </li>
                                        <li
                                            onClick={setModalAction11True}
                                            style={{ cursor: 'pointer' }}
                                        >
                                            Træf afgørelse om ophør af indsats,
                                            når formålet er nået, når de ikke
                                            længere opfylder deres formål, eller
                                            når den unge fylder 18 år
                                        </li>
                                        <ul>
                                            <li>
                                                Afgørelsen skal være skriftlig
                                                og indeholde begrundelse,
                                                henvisning til lovgrundlaget og
                                                klagevejledning
                                            </li>
                                            <li>
                                                Modtagere af afgørelsen er
                                                forældremyndighedsindehaver og
                                                barnet eller den unge, hvis det
                                                er fyldt 10 år
                                            </li>
                                        </ul>
                                    </ol>
                                </div>
                            </div>
                            {/* ) : null} */}
                        </div>

                        {/* Conclusion column */}
                        {/* <div
                            className={[
                                styles.desktopColumn2,
                                styles.desktopColumnLast,
                            ].join(' ')}
                        >
                            <div>
                                <div>
                                    <p className={styles.groupHeading}>
                                        Handlinger / drift:
                                    </p>
                                    <p className={styles.groupHeading}>
                                        Børnefaglig undersøgelse:
                                    </p>
                                    <ul>
                                        <li>
                                            Inddragelse af barnet eller den unge
                                        </li>
                                        <li>
                                            Samtaler og anden direkte kontakt
                                        </li>
                                        <li>Vejledning om bisidder</li>
                                        <li>Partshøring af forældrene</li>
                                        <li>Vejledning om bisidder</li>
                                        <li>Andre børn i familien</li>
                                        <li>
                                            Afgørelse om børnefaglig
                                            undersøgelse
                                        </li>
                                        <li>
                                            De forvaltningsretlige krav til
                                            afgørelsen
                                        </li>
                                        <li>Modtagere af afgørelsen</li>
                                        <li>
                                            Viden eller mistanke om overgreb
                                        </li>
                                        <li>Sideløbende indsats</li>
                                        <li>Samarbejde med familien</li>
                                        <li>Sagsoplysning</li>
                                        <li>Indsamling af oplysninger</li>
                                        <li>
                                            Lægelig / psykologisk undersøgelse
                                        </li>
                                        <li>
                                            Undersøgelse af barnet eller den
                                            unge
                                        </li>
                                        <li>Undersøgelse af forældrene</li>
                                        <li>
                                            Pædagogisk-psykologisk vurdering
                                        </li>
                                        <li>
                                            Inddragelse af barnet eller den unge
                                            og forældrene
                                        </li>
                                        <li>Afslutning på undersøgelsen</li>
                                        <li>Konklusion</li>
                                        <li>Faglig vurdering</li>
                                    </ul>
                                </div>

                                <div>
                                    <Button
                                        variant="success"
                                        size="sm"
                                        onClick={() =>
                                            evaluate_conclusion_group(
                                                'underretning',
                                            )
                                        }
                                    >
                                        {t2('underretning', 'vlfrdmc')}
                                    </Button>
                                    <Button
                                        variant="success"
                                        size="sm"
                                        onClick={(event) => HandleAction(event)}
                                    >
                                        {t2('forebyggende', 'vlfrdmc')}
                                    </Button>
                                    <Button
                                        variant="success"
                                        size="sm"
                                        onClick={() =>
                                            evaluate_conclusion_group(
                                                'afdækning',
                                            )
                                        }
                                    >
                                        {t2('afdækning', 'vlfrdmc')}
                                    </Button>
                                    <Button
                                        variant="success"
                                        size="sm"
                                        onClick={() =>
                                            evaluate_conclusion_group(
                                                'børnefaglig',
                                            )
                                        }
                                    >
                                        {t2('børnefaglig', 'vlfrdmc')}
                                    </Button>
                                </div>
                            </div>
                        </div> */}
                    </div>
                )}
            </div>
        </>
    );
}

export default Forebyggende;
