// See https://www.robinwieruch.de/react-table-component/ for the tutorial for creating this type of tables
// https://www.robinwieruch.de/categories/react-table-library/
// samples: https://react-table-library.com/?path=/story/getting-started--demo
// https://github.com/table-library/react-table-library
import React, { useEffect } from "react";

// import { useTranslation } from "react-i18next";
// import "../../translations/i18n";

import {
    Table,
    Header,
    HeaderRow,
    HeaderCell,
    Body,
    Row,
    Cell,
} from "@table-library/react-table-library/table";

import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";

import {
    useSort,
    // HeaderCellSort,
} from "@table-library/react-table-library/sort";

import {
    // Sort,
    // SortOptions,
    // SortIconPositions,
    SortToggleType,
} from "@table-library/react-table-library/sort";

// import { defaultTheme } from "../../hooks/useReactTableTheme";
import useReactTableTheme from "../../hooks/useReactTableTheme";

// import { CompactTable } from '@table-library/react-table-library/compact';
import { useTheme } from "@table-library/react-table-library/theme";
import defaultTableTheme from "../KnowledgeBrowser/DefaultTableTheme";

import { userCaseContext } from "../../contexts/UserCaseContext";
import { displayContext } from "../../../common/DisplayContext";
import { evaluationContext } from "../../contexts/EvaluationContext";

import styles from '../../styles.module.css';

import { useContext, useState } from "react";

function CaseTableTest() {
    const { userCase, setUserCase, setCaseStatus } =
        useContext(userCaseContext);

    const {
        setButtonAcceptDisabled,
        setButtonLetterDisabled,
        setButtonRejectDisabled,
        setDisplayMessage,
        setUseMode,
        t,
    } = useContext(displayContext);

    const { setCurrentCaseId, conclusionLastTrue } =
        useContext(evaluationContext);

    const [{ defaultTheme }] = useReactTableTheme();

    // HeaderRow: `
    //     background-color: #FAFAFA;
    //     font-size: 14px;

    //     .th {
    //     font-weight: normal !important;
    //     border-width: 0px;
    //     border-bottom: 0px;
    //     box-shadow: 0px 0px 0px 0px rgba(0,0,0,0)
    //     }
    // `,

    // const theme = useTheme({
    //     Table: `
    //   height: 100%;
    //   align-content: baseline;
    //   --data-table-library_grid-template-columns:  15% 15% 40% 15% 15%;
    // `,
    //     BaseRow: `
    //   font-size: 14px;
    // `,
    //     HeaderRow: `
    //   font-size: 14px;

    //   .th {
    // 	font-weight: normal !important;
    // 	border-width: 0px;
    // 	border-bottom: 0px;
    // 	box-shadow: 0px 0px 0px 0px rgba(0,0,0,0)
    //   }
    // `,
    //     Row: `
    //   &:nth-child(odd) {
    //     background-color: #C7C7C7;
    //   }

    //   &:nth-child(even) {
    //     background-color: #F2F2F2;
    //   }

    //   cursor: pointer;

    //   &:hover .td {
    // 	background-color: yellow;
    //   }

    // `,
    //     HeaderCell: `
    // 	padding-left: 10px !important;
    // 	`,
    //     Cell: `
    //   padding-left: 10px !important;
    //   text-align: left; 
    // `,
    //     BaseCell: `
    //   padding-left: 0px;
    //   padding-top: 16px;
    //   padding-bottom: 16px;
    // `,
    // });

    defaultTableTheme.Table = `
        height: 100%; 
        width: 100%; 
        align-content: baseline; 
        --data-table-library_grid-template-columns: 15% 15% 40% 15% 15%;
    `
 
    defaultTableTheme.Cell = `
        padding-left: 10px !important;
        text-align: left; 
    `

    const theme = useTheme(defaultTableTheme)

    // const theme = useTheme({
    //     Table: `
    //   height: 100%;
	//   align-content: baseline;
    //   --data-table-library_grid-template-columns:  15% 15% 40% 15% 15%;
    // `,
    //     BaseRow: `
    //   font-size: var(--font-size5);
    // `,
    //     HeaderRow: `
	//   font-size: var(--font-size5);

	//   .th {
	// 	font-weight: normal !important;
	// 	border-width: 0px;
	// 	border-bottom: 0px;
	// 	box-shadow: 0px 0px 0px 0px rgba(0,0,0,0)
	//   }
    // `,
    //     Row: `
    //   &:nth-child(odd) {
    //     background-color: #C7C7C7;
    //   }

    //   &:nth-child(even) {
    //     background-color: #F2F2F2;
    //   }

	//   cursor: pointer;

	//   &:hover .td {
	// 	background-color: yellow;
	//   }

    // `,
    //     HeaderCell: `
	// 	padding-left: 10px !important;
	// 	`,
    //     Cell: `
    //   padding-left: 10px !important;
	//   text-align: left; 
    // `,
    //     BaseCell: `
    //     padding-top: 4px !important;
    //     padding-bottom: 4px !important;
    //     `,
    // });


    let userCaseFiltered = userCase.filter((el) => el.name != "class");

    const [data, setData] = useState({
        nodes: userCaseFiltered,
    });

    useEffect(() => {
        userCaseFiltered = userCase.filter((el) => el.name != "class");
        setData({ nodes: userCaseFiltered });
    }, [userCase]);

    // const handleUpdate = (test, name) => {
    //     setUserCase((old) =>
    //         old.map((node) => {
    //             if (node.name === name) {
    //                 return { ...node, [name]: test };
    //             } else {
    //                 return node;
    //             }
    //         })
    //     );
    // };

    const handleNameFilter = (value) => {
        setData({
            nodes:
                value !== ""
                    ? userCase.filter(function (item) {
                        return item.name.includes(value);
                    })
                    : userCase,
        });
    };

    const handleDisplayNameFilter = (value) => {
        setData({
            nodes:
                value !== ""
                    ? userCase.filter(function (item) {
                        return item.displayName.includes(value);
                    })
                    : userCase,
        });
    };

    // const handleTestFilter = (value) => {
    //     setData({
    //         nodes:
    //             value !== ""
    //                 ? userCase.filter(function (item) {
    //                       return item.test.includes(value);
    //                   })
    //                 : userCase,
    //     });
    // };

    const sort = useSort(
        data,
        {
            onChange: onSortChange,
        },
        {
            sortToggleType: SortToggleType.AlternateWithReset,
            sortFns: {
                Name: (array) =>
                    array.sort((a, b) => a.name.localeCompare(b.name)),
                DisplayName: (array) =>
                    array.sort((a, b) =>
                        a.displayName.localeCompare(b.displayName)
                    ),
                Test: (array) =>
                    array.sort((a, b) => a.test.localeCompare(b.test)),
            },
        }
    );

    function onSortChange(action, state) {
        console.log(action, state);
    }

    // if (userCase.length !== 0) {

    const onChangeText = (e) => {
        e.persist();

        setUseMode("read");
        setCurrentCaseId(null);

        setButtonLetterDisabled(true);
        setButtonAcceptDisabled(true);
        setButtonRejectDisabled(true);
        setDisplayMessage("");

        setUserCase((old) =>
            old.map((node) => {
                if (node.name === e.target.name) {
                    return { ...node, ["test"]: e.target.value };
                } else {
                    return node;
                }
            })
        );
    };

    // const [item, setItem] = useState({ kindOfStand: "", another: "another" });

    // const { kindOfStand } = item;

    const handleChange = (e) => {
        e.persist();

        setUseMode("read");
        setCaseStatus(t("na"));
        setCurrentCaseId(null);

        setButtonLetterDisabled(true);
        setButtonAcceptDisabled(true);
        setButtonRejectDisabled(true);
        setDisplayMessage("");

        setUserCase((old) =>
            old.map((node) => {
                if (node.name === e.target.name) {
                    return { ...node, ["test"]: e.target.value };
                } else {
                    return node;
                }
            })
        );
    };

    const handleClick = (e) => {
        e.persist();
        setCaseStatus(t("na"));
        setCurrentCaseId(null);

        setButtonRejectDisabled(true);
        setDisplayMessage("");

        setUserCase((old) =>
            old.map((node) => {
                if (node.name === e.target.name) {
                    return { ...node, ["test"]: 0 };
                } else {
                    return node;
                }
            })
        );
    };

    return (
        // <div className="div-case-table">
        <div className={styles.divCaseTable}>
            <Table 
                data={data} 
                theme={theme} 
                sort={sort}
                layout={{custom: true}}
            >
                {(tableList) => (
                    <>
                        <Header>
                            <HeaderRow>
                                {/* <HeaderCellSort sortKey="Name"> */}
                                {/* <HeaderCell {...{ style: { height: `100px` } }}> */}

                                <HeaderCell
                                    style={{
                                        backgroundColor:
                                            "var(--boxBackground)",
                                    }}
                                >
                                    {t("name")}
                                    <br />
                                    <input
                                        className="case-table-filter"
                                        type="text"
                                        placeholder="Filter..."
                                        onChange={(event) =>
                                            handleNameFilter(event.target.value)
                                        }
                                    />
                                </HeaderCell>

                                {/* <HeaderCellSort sortKey="DisplayName"> */}
                                <HeaderCell
                                    style={{
                                        backgroundColor:
                                            "var(--boxBackground)",
                                    }}
                                >
                                    {t("description")}
                                    <br />
                                    <input
                                        className="case-table-filter"
                                        type="text"
                                        placeholder="Filter..."
                                        onChange={(event) =>
                                            handleDisplayNameFilter(
                                                event.target.value
                                            )
                                        }
                                    />
                                </HeaderCell>

                                <HeaderCell
                                    style={{
                                        textAlign: "center",
                                        backgroundColor:
                                            "var(--boxBackground)",
                                    }}
                                >
                                    {/* {t("testCase")} */}
                                </HeaderCell>

                                <HeaderCell
                                    style={{
                                        backgroundColor:
                                            "var(--boxBackground)",
                                    }}
                                >
                                    {" "}
                                    {t("referenceCase")}
                                </HeaderCell>

                                <HeaderCell
                                    style={{
                                        textAlign: "center",
                                        backgroundColor:
                                            "var(--boxBackground)",
                                    }}
                                >
                                    {" "}
                                    {t("referenceMatch")}
                                </HeaderCell>
                            </HeaderRow>
                        </Header>

                        <Body>
                            {tableList.map((item) => (
                                <Row key={item.name} item={item}>
                                    <Cell>{item.name}</Cell>
                                    <Cell>
                                        <div className="attribute-display-name">
                                            {item.displayName}
                                        </div>
                                    </Cell>

                                    {/* <Cell>{item.test}</Cell> */}
                                    {/* item.type can be integer, text, check */}
                                    <Cell
                                        style={{
                                            textAlign: "center",
                                            marginBottom: "0px",
                                        }}
                                    >
                                        {item.type === "check" && (
                                            <div>
                                                <form
                                                    onChange={handleChange}
                                                    onClick={handleClick}
                                                >
                                                    <input
                                                        style={{
                                                            marginLeft: "5px",
                                                            marginRight: "5px",
                                                        }}
                                                        type="radio"
                                                        id="yes"
                                                        value="1"
                                                        checked={item.test == 1}
                                                        name={item.name}
                                                    />
                                                    <label for="yes">
                                                        {t("yes")}
                                                    </label>
                                                    <input
                                                        style={{
                                                            marginLeft: "5px",
                                                            marginRight: "5px",
                                                        }}
                                                        type="radio"
                                                        id="no"
                                                        value="2"
                                                        checked={item.test == 2}
                                                        name={item.name}
                                                    />
                                                    <label for="no">
                                                        {t("no")}
                                                    </label>
                                                </form>
                                            </div>
                                        )}

                                        {(item.type === "integer" ||
                                            item.type === "real" ||
                                            item.type === "text") && (
                                                // ...on other types return a text-field

                                                <input
                                                    value={item.test}
                                                    onChange={onChangeText}
                                                    className="editable-cell"
                                                    name={item.name}
                                                    style={{
                                                        textAlign: "center",
                                                        width: "40%",
                                                    }}
                                                />
                                            )}

                                        {/* {(item.type === "real" ||
                                            item.type === "text") && (
                                            // ...on other types return a text-field

                                            <input
                                                value={item.test}
                                                onChange={onChangeText}
                                                className="editable-cell"
                                                name={item.name}
                                                style={{
                                                    textAlign: "center",
                                                    width: "40%",
                                                }}
                                            />
                                        )} */}

                                        {/* 
										{
											<input
												type="text"
												style={{ width: '100%', border: 'none', fontSize: '1rem', padding: 0, margin: 0 }}
												value={item.test}
												onChange={(event) => handleUpdate(event.target.value, item.name, 'test')}
											/>
										} */}
                                    </Cell>

                                    <Cell>
                                        {item.type === "check" &&
                                            item.referenceValue === "yes" &&
                                            t("yes")}
                                        {item.type === "check" &&
                                            item.referenceValue === "no" &&
                                            t("no")}
                                        {item.type !== "check" &&
                                            item.referenceValue}
                                    </Cell>

                                    {/* Mismatch indicator */}
                                    <Cell
                                        style={{
                                            textAlign: "center",
                                            marginBottom: "0px",
                                        }}
                                    >
                                        {/* For Checkboxes */}
                                        {/* They have the same value, whether both are empty or both are not empty */}
                                        {conclusionLastTrue != "" &&
                                            item.type === "check" &&
                                            ((item.referenceValue == "no" &&
                                                item.test == 2) ||
                                                (item.referenceValue == "yes" &&
                                                    item.test == 1) ||
                                                ((typeof item.referenceValue ==
                                                    undefined ||
                                                    item.referenceValue ==
                                                    null ||
                                                    item.referenceValue ==
                                                    "") &&
                                                    (typeof item.test ==
                                                        undefined ||
                                                        item.test == null ||
                                                        item.test == "")))}

                                        {/* One and only one of them is empty */}
                                        {conclusionLastTrue != "" &&
                                            item.type === "check" &&
                                            // reference is empty and test is not empty
                                            (((typeof item.referenceValue ==
                                                undefined ||
                                                item.referenceValue == null ||
                                                item.referenceValue == "" ||
                                                item.referenceValue ==
                                                "unknown") &&
                                                !(
                                                    typeof item.test ==
                                                    undefined ||
                                                    item.test == null ||
                                                    item.test == ""
                                                )) ||
                                                // reference is not empty and test is empty
                                                (!(
                                                    typeof item.referenceValue ==
                                                    undefined ||
                                                    item.referenceValue ==
                                                    null ||
                                                    item.referenceValue == "" ||
                                                    item.referenceValue ==
                                                    "unknown"
                                                ) &&
                                                    (typeof item.test ==
                                                        undefined ||
                                                        item.test == null ||
                                                        item.test == ""))) && (
                                                <div class="ref-match-one-empty"></div>
                                            )}

                                        {/* None of them is empty and they are different */}
                                        {conclusionLastTrue != "" &&
                                            item.type === "check" &&
                                            item.referenceValue != null &&
                                            item.test != "" &&
                                            ((item.referenceValue == "no" &&
                                                item.test == 1) ||
                                                (item.referenceValue == "yes" &&
                                                    item.test == 2)) && (
                                                <div class="ref-match-different"></div>
                                            )}

                                        {/* For Text, Integer and Real */}
                                        {/* They have the same value, whether both are empty or both are not empty */}
                                        {conclusionLastTrue != "" &&
                                            (item.type === "text" ||
                                                item.type === "integer" ||
                                                item.type === "real") &&
                                            (item.referenceValue == item.test ||
                                                ((typeof item.referenceValue ==
                                                    undefined ||
                                                    item.referenceValue ==
                                                    null ||
                                                    item.referenceValue ==
                                                    "") &&
                                                    (typeof item.test ==
                                                        undefined ||
                                                        item.test == null ||
                                                        item.test == "")))}

                                        {/* One and only one of them is empty */}
                                        {conclusionLastTrue != "" &&
                                            (item.type === "text" ||
                                                item.type === "integer" ||
                                                item.type === "real") &&
                                            // reference is empty and test is not empty
                                            (((typeof item.referenceValue ==
                                                undefined ||
                                                item.referenceValue == null ||
                                                (typeof item.referenceValue ==
                                                    "string" &&
                                                    item.referenceValue ==
                                                    "")) &&
                                                !(
                                                    typeof item.test ==
                                                    undefined ||
                                                    item.test == null ||
                                                    item.test == ""
                                                )) ||
                                                // reference is not empty and test is empty
                                                (!(
                                                    typeof item.referenceValue ==
                                                    undefined ||
                                                    item.referenceValue ==
                                                    null ||
                                                    (typeof item.referenceValue ==
                                                        "string" &&
                                                        item.referenceValue ==
                                                        "")
                                                ) &&
                                                    (typeof item.test ==
                                                        undefined ||
                                                        item.test == null ||
                                                        item.test == ""))) && (
                                                <div class="ref-match-one-empty"></div>
                                            )}

                                        {/* None of them is empty and they are different */}
                                        {conclusionLastTrue != "" &&
                                            (item.type === "text" ||
                                                item.type === "integer" ||
                                                item.type === "real") &&
                                            item.referenceValue != null &&
                                            item.test != "" &&
                                            item.referenceValue !=
                                            item.test && (
                                                <div class="ref-match-different"></div>
                                            )}
                                    </Cell>
                                </Row>
                            ))}
                        </Body>
                    </>
                )}
            </Table>
        </div >
    );
    // }
    // return <div></div>
}

export default CaseTableTest;
