import React, { useEffect, useState, useContext } from 'react';
import '../../top/swipeballDesign.css'

import { swiperContext } from '../contexts/SwiperContext';

import useSwiper from '../hooks/useSwiper';

function TrademarkSwiperList({ listClassName, ball, column, items }) {

    const {
        focusedOption, setFocusedOption,
        selectedOptions, setSelectedOptions,
        setActiveSlideSelected,
        activeOptions, setActiveOptions,
        focus, setFocus,
        addEmptyItemsToList, setAddEmptyItemsToList,
    } = useContext(swiperContext);


    const [{
        setOption,
    }] = useSwiper();


    const [highlightedIndex1, setHighlightedIndex1] = useState(4); // Initialize with the first item (index 0) highlighted
    const itemHeight = 40; // Set the height of each list item
    const numVisibleItems = items.length; // Number of visible items in focus
    const numInvisibleItems = 2; // Number of invisible items at the start and end
    const [initialized, setInitialized] = useState(false);

    const [iHaveScrolled, setIHaveScrolled] = useState(false)

    useEffect(() => {
        const list = document.querySelector(`.${listClassName}`);

        function handleScroll() {
            const scrollTop = list.scrollTop;

            // console.log('scrollTop: ', scrollTop)
            // setIHaveScrolled(true)
            setAddEmptyItemsToList(true)

            // Calculate the index of the middle item within the visible list of 5 items
            const middleIndex = Math.floor(scrollTop / itemHeight) + numInvisibleItems;

            // Update the highlighted index
            setHighlightedIndex1(middleIndex);

            // Set the focused option (the one option that the end-user focus on)
            // setFocusedOption({ "ball": ball, "row": middleIndex - 1, "column": column });
            setFocus({ "ball": ball, "row": middleIndex - 1, "column": column });

            // Update the active option for this column...
            // ...remove current activeOption for this column...
            const newActiveOptions = activeOptions.filter(item => !(item.ball === ball && item.column == column))

            // ...add new activeOption for this column
            newActiveOptions.push({ "ball": ball, "row": middleIndex - 1, "column": column });
            setActiveOptions(newActiveOptions);
        }

        function initializeHighlight() {
            if (!initialized) {
                handleScroll();
                setInitialized(true);
            }
        }

        // Add a scroll event listener to the list container
        list.addEventListener('scroll', handleScroll);


        // Add a scroll event listener to initialize the highlight on user interaction
        list.addEventListener('wheel', initializeHighlight, { once: true });

        // Clean up the event listener
        return () => {
            list.removeEventListener('scroll', handleScroll);
            list.removeEventListener('wheel', initializeHighlight);
        };
    }, [initialized, listClassName]);

    function optionSelected(ball, row, column) {
        return selectedOptions.findIndex(item => (item.ball === ball && item.row == row && item.column == column)) != -1
    }

    // function setOptionOne(ball, row, column) {

    //     // If this option was not already selected
    //     // 1. remove other selected option in the same column
    //     // 2. add this option to the array of selected options...
    //     if (selectedOptions.findIndex(item => (item.ball == ball && item.row == row && item.column == column)) === -1) {

    //         // remove other options in the same column from selectedOptions
    //         const newSelectedOptions = selectedOptions.filter(item => !(item.ball === ball && item.column == column))

    //         // add currentOption to selectedOptions
    //         newSelectedOptions.push({ "ball": ball, "row": row, "column": column });
    //         setSelectedOptions(newSelectedOptions);
    //         setActiveSlideSelected(true);

    //     } // ...else, i.e. this option was already selected, remove it from the array of selected options.
    //     else {
    //         // remove currentOption from selectOptions
    //         setSelectedOptions(selectedOptions.filter(item => !(item.ball === ball && item.row == row && item.column == column)))
    //         setActiveSlideSelected(false);
    //     }
    // }


    // function setOptionMultiple(ball, row, column) {

    //     let newSelectedOptions = [...selectedOptions]

    //     // If this option was not already selected...
    //     // ...add this option to the array of selected options...
    //     if (selectedOptions.findIndex(item => (item.ball == ball && item.row == row && item.column == column)) === -1) {

    //         // create a copy of selectedOptions
    //         // const newSelectedOptions = [...selectedOptions]

    //         // setSelectedOptions([...selectedOptions].push({ "ball": ball, "row": row, "column": column }));

    //         // add currentOption to selectedOptions
    //         newSelectedOptions.push({ "ball": ball, "row": row, "column": column });
    //         setSelectedOptions(newSelectedOptions);
    //         setActiveSlideSelected(true);

    //     } // ...else, i.e. this option was already selected, remove it from the array of selected options.
    //     else {
    //         // remove currentOption from selectOptions
    //         setSelectedOptions(selectedOptions.filter(item => !(item.ball === ball && item.row == row && item.column == column)))
    //         setActiveSlideSelected(false);
    //     }
    // }

    return (
        <div className="scrollable-container">
            <ul className={"scrollable-list " + listClassName}>
                {/* Add invisible placeholders at the start */}

                {addEmptyItemsToList || items.length < 5 ?
                    Array.from({ length: numInvisibleItems }, (_, index) => (
                        <li
                            key={`start-placeholder-${index}`}
                            className={highlightedIndex1 === index ? 'highlighted' : ''}
                            style={{ minHeight: itemHeight }}
                        >
                        </li>
                    ))
                    :
                    ''
                }

                {Array.from({ length: numVisibleItems }, (_, index) => (
                    <li
                        key={index}
                        // Gradually make items darker when further away from the middle index which is highlighted
                        className={
                            highlightedIndex1 === index + numInvisibleItems ? (optionSelected(ball, index + 1, column) ? 'highlighted option-selected' : 'highlighted')
                                : (highlightedIndex1 === index + numInvisibleItems - 1) || (highlightedIndex1 === index + numInvisibleItems + 1) ? 'second' :
                                    'third'
                        }
                        style={{ height: itemHeight }}
                        onClick={() => setOption(ball, index + 1, column, 'add')}
                    >
                        {items[index].text}
                    </li>
                ))}

                {/* Add invisible placeholders at the end */}
                {Array.from({ length: numInvisibleItems }, (_, index) => (
                    <li
                        key={`end-placeholder-${index}`}
                        className={highlightedIndex1 === index + numVisibleItems + numInvisibleItems ? 'highlighted' : ''}
                        style={{ minHeight: itemHeight }}
                    >
                        {/* Add content here if needed */}
                    </li>
                ))}
            </ul>
        </div >
    );
}

export default TrademarkSwiperList;