import React, { useContext } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ResultConclusionHeading from "./ResultConclusionHeading";

import { displayContext } from "../../../common/DisplayContext";
import { evaluationContext } from "../../contexts/EvaluationContext";

import styles from '../../styles.module.css';

///////////////////////////////////////////////////////////////////////////////////
//
//  ResultConclusion: show the conclusion after evaluation
//
///////////////////////////////////////////////////////////////////////////////////

function ResultConclusion() {
    const { t } = useContext(displayContext);
    const { conclusionLastTrue } = useContext(evaluationContext);

    return (
        <>
            <div className={styles.conclusion}
            >
                <ResultConclusionHeading />
                {conclusionLastTrue}
            </div>
        </>
    );
}

export default ResultConclusion;
