import React, { useContext } from "react";

import {
    Button,
    ButtonGroup,
    Spinner,
    OverlayTrigger,
    Tooltip,
} from "react-bootstrap";

import { domainContext } from "../../contexts/DomainContext";
import { evaluationContext } from "../../contexts/EvaluationContext";
import { displayContext } from "../../../common/DisplayContext.js";
import { userCaseContext } from "../../contexts/UserCaseContext";
// import { TokenContext } from "../../contexts/TokenContext.js";
import { knowledgeContext } from "../../contexts/KnowledgeContext.js";

import useAuth from "../../hooks/useAuth";
import useEvaluate from "../../hooks/useEvaluate.js";

import styles from '../../styles.module.css';

import Messages from "../Messages.js";
// import { Config } from "../../../config";
///////////////////////////////////////////////////////////////////////////////////
//
///////////////////////////////////////////////////////////////////////////////////

function ButtonEvaluateCase() {
    const { 
        userCase, 
        setUserCase 
    } = useContext(userCaseContext);

    const { 
        knowledgebaseType,
     } = useContext(knowledgeContext);

    const {
        // setIsLoading,
        isEvaluating,
        setButtonAcceptDisabled,
        setButtonLetterDisabled,
        setButtonRejectDisabled,
        setButtonSaveNewRuleDisabled,
        setDiffIndicatorStatus,
        setDisplayMessage,
        setIsEvaluating,
        setTextAreaNewNoteDisabled,
        setTextAreaNewRuleDisabled,
        setTextAreaNewConclusionDisabled,
        t,
        t2,
        overlayTriggerHelp,
    } = useContext(displayContext);

    const { currentKnowledgeBase, currentDomain } = useContext(domainContext);

    const {
        currentCaseId,
        nodeLastTrue,
        setConclusionLastTrue,
        setEvaluationResult,
        setExplanation,
        setExplanationLastTrue,
        setExplanationTrues,
        setNodeLast,
        setNodeLastTrue,
        setNodesEvaluated,
        setNoteLastTrue,
        setReferenceCase,
        setReturnCode,
    } = useContext(evaluationContext);

    // const { token } = useContext(TokenContext);
    const { token } = useAuth();
    const evaluate = useEvaluate();

    // function handleSubmitEvaluate(e) {
    //     setIsEvaluating(true);

    //     if (knowledgebaseType == "sc") {
    //         handleEvaluateSC(e);
    //     } else if (knowledgebaseType == "mc") {
    //         handleEvaluateMC(e);
    //     }
    // }


    function handleSubmitEvaluate(e) {
        setIsEvaluating(true);
        evaluate();
    }






    function handleEvaluateSC(e) {
        var obj = {};
        var ruleFound = false;

        obj.caseData = userCase;
        obj.caseId = currentCaseId;
        obj.knowledgeBase = currentKnowledgeBase;
        obj.domain = currentDomain;
        obj.category = "";
        obj.nodeLastTrue = nodeLastTrue;
        obj.requestType = "evaluate";

        // Do we need this?
        // Clear fields in window
        setDisplayMessage({
            messageCode: null,
            messageText: "Processing...",
            // messageText: t('processing'),
            displayCode: 1,
        });

        // Do we need this?
        // setIsLoading(true);
        var endpoint = process.env.REACT_APP_FLASK_API + "evaluate";
        fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(obj),
        })
            .then((res) => res.json())
            .then((responseJson) => {
                console.log(
                    "ButtonEvaluateCase - responseJson[message].messageCode: ",
                    responseJson["message"].messageCode
                );
                setEvaluationResult(responseJson);
                setUserCase(responseJson["caseData"]);
                console.log("ButtonEvaluateCase - SETTING userCase");
                // setCurrentCaseId(responseJson["caseId"]);
                setConclusionLastTrue(responseJson["conclusionLastTrue"]);
                setExplanationLastTrue(responseJson["explanationLastTrue"]);
                setExplanationTrues(responseJson["explanationTrues"]);
                setNodeLast(responseJson["nodeLast"]);
                setNodeLastTrue(responseJson["nodeLastTrue"]);
                setNodesEvaluated(responseJson["nodesEvaluated"]);
                setNoteLastTrue(responseJson["noteLastTrue"]);
                // setReferenceCase(responseJson["referenceCase"]);
                setReturnCode(responseJson["returnCode"]);

                // Convert the returned explanation (list of short rules, long rules and evaluation result) to a
                // list of dictionaries
                const explanationDict = [];
                var id = 0;
                responseJson["explanation"].forEach((expl) => {
                    id = id + 1;
                    explanationDict.push({
                        id: id,
                        explanation: expl[0],
                        explanationLong: expl[1],
                        displayCode: expl[2] === true ? 0 : 1,
                        letterRuleTrue: expl[3],
                        letterRuleFalse: expl[4],
                    });
                });
                setExplanation(explanationDict);

                // Interpret the return code sent by the server

                if (responseJson["message"].messageCode !== 0) {
                    // setDisplayMessage(Messages(responseJson["message"]));
                    setDisplayMessage({
                        messageCode: Messages(responseJson["message"]).messageCode,
                        messageText: t2('noRulesForCase', 'sysadmin'),
                        displayCode: Messages(responseJson["message"]).displayCode,
                    });
                }

                // Enable / disable buttons
                // messageCode = 0 if the evaluation found a rule that applies to the case
                // ruleFound = responseJson["message"].messageCode == 0;
                setButtonLetterDisabled(false);
                setButtonAcceptDisabled(false);
                setButtonRejectDisabled(false);

                setDiffIndicatorStatus(true);

                // messageCode = 1 if there are no rules for this domain yet (only the dummy startnode)
                if (responseJson["message"].messageCode === 1) {
                    setTextAreaNewConclusionDisabled(false);
                    setTextAreaNewRuleDisabled(false);
                    setTextAreaNewNoteDisabled(false);
                    setButtonLetterDisabled(true);
                    setButtonAcceptDisabled(true);
                    setButtonRejectDisabled(false);
                    console.log("ButtonEvaluateCase - no rules for this domain yet");

                    // messageCode = 2 if no rules apply to the case
                } else if (responseJson["message"].messageCode === 2) {
                    setTextAreaNewConclusionDisabled(false);
                    setTextAreaNewRuleDisabled(false);
                    setTextAreaNewNoteDisabled(false);
                    setButtonLetterDisabled(true);
                    setButtonAcceptDisabled(true);
                    setButtonRejectDisabled(false);
                    console.log("ButtonEvaluateCase - no rules apply");
                } else setDisplayMessage("");
                setIsEvaluating(false);
            });
        // setIsLoading(false);
    }



    function handleEvaluateMC(e) {
        var obj = {};
        var ruleFound = false;

        obj.caseData = userCase;
        obj.caseId = currentCaseId;
        obj.knowledgeBase = currentKnowledgeBase;
        obj.domain = currentDomain;
        obj.category = "";
        obj.nodeLastTrue = nodeLastTrue;
        obj.requestType = "evaluate";

        // Do we need this?
        // Clear fields in window
        setDisplayMessage({
            messageCode: null,
            messageText: "Processing...",
            // messageText: t('processing'),
            displayCode: 1,
        });

        // Do we need this?
        // setIsLoading(true);
        var endpoint = process.env.REACT_APP_FLASK_API + "evaluate";
        fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(obj),
        })
            .then((res) => res.json())
            .then((responseJson) => {
                setEvaluationResult(responseJson);
                // setUserCase(responseJson["caseData"]);
                // console.log("ButtonEvaluateCase - SETTING userCase");
                // setConclusionLastTrue(responseJson["conclusionLastTrue"]);
                // setExplanationLastTrue(responseJson["explanationLastTrue"]);
                // setExplanationTrues(responseJson["explanationTrues"]);
                // setNodeLast(responseJson["nodeLast"]);
                // setNodeLastTrue(responseJson["nodeLastTrue"]);
                // setNodesEvaluated(responseJson["nodesEvaluated"]);
                // setNoteLastTrue(responseJson["noteLastTrue"]);
                // setReturnCode(responseJson["returnCode"]);

                // // Convert the returned explanation (list of short rules, long rules and evaluation result) to a
                // // list of dictionaries
                // const explanationDict = [];
                // var id = 0;
                // responseJson["explanation"].forEach((expl) => {
                //     id = id + 1;
                //     explanationDict.push({
                //         id: id,
                //         explanation: expl[0],
                //         explanationLong: expl[1],
                //         displayCode: expl[2] === true ? 0 : 1,
                //         letterRuleTrue: expl[3],
                //         letterRuleFalse: expl[4],
                //     });
                // });
                // setExplanation(explanationDict);

                // // Interpret the return code sent by the server

                // if (responseJson["message"].messageCode !== 0) {
                //     // setDisplayMessage(Messages(responseJson["message"]));
                //     setDisplayMessage({
                //         messageCode: Messages(responseJson["message"]).messageCode,
                //         messageText: t2('noRulesForCase', 'sysadmin'),
                //         displayCode: Messages(responseJson["message"]).displayCode,
                //     });
                // }

                // Enable / disable buttons
                // messageCode = 0 if the evaluation found a rule that applies to the case
                // ruleFound = responseJson["message"].messageCode == 0;
                setButtonLetterDisabled(false);
                setButtonAcceptDisabled(false);
                setButtonRejectDisabled(false);

                setDiffIndicatorStatus(true);

                // messageCode = 1 if there are no rules for this domain yet (only the dummy startnode)
                if (responseJson[0]["message"].messageCode === 1) {
                    setTextAreaNewConclusionDisabled(false);
                    setTextAreaNewRuleDisabled(false);
                    setTextAreaNewNoteDisabled(false);
                    setButtonLetterDisabled(true);
                    setButtonAcceptDisabled(true);
                    setButtonRejectDisabled(false);
                    console.log("ButtonEvaluateCase - no rules for this domain yet");

                    // messageCode = 2 if no rules apply to the case
                } else if (responseJson[0]["message"].messageCode === 2) {
                    setTextAreaNewConclusionDisabled(false);
                    setTextAreaNewRuleDisabled(false);
                    setTextAreaNewNoteDisabled(false);
                    setButtonLetterDisabled(true);
                    setButtonAcceptDisabled(true);
                    setButtonRejectDisabled(false);
                    console.log("ButtonEvaluateCase - no rules apply");
                } else setDisplayMessage("");
                setIsEvaluating(false);
            });
        // setIsLoading(false);
    }






    return (
        <OverlayTrigger
            trigger={overlayTriggerHelp ? ["click", "hover", "focus"] : []}
            delay={400}
            delayHide={0}
            placement={"auto"}
            overlay={<Tooltip>{t("buttonEvaluateTooltip")}</Tooltip>}
        >
            <span>
                <Button
                    // className="ms-1 me-1"
                    className={styles.standardButton}
                    variant="success"
                    // size="sm"
                    onClick={handleSubmitEvaluate}
                >

                    {t("evaluate")}
                </Button>
            </span>
        </OverlayTrigger>
    );
}

export default ButtonEvaluateCase;
