import React, { useContext } from "react";
import Editor from "react-simple-code-editor";

import { attributesContext } from "../../contexts/AttributesContext";
import { displayContext } from "../../../common/DisplayContext";

import useTranslateRuleToEnglish from "../../hooks/useTranslateRuleToEnglish";

// This Component returns a box where the user can enter a new rule and
// the rule is syntax-checked as the user types. Recognized tokens such as attribute names
// and operators are colored green and other tokens are colored red. This is in order to
// inform the user, and a rule can be perfectly correct even though some tokens are colored red.
// We could use differnet formatting such as italices, etc, if the react-simple-code-editor allows for that.
//
// Note that we are not using prism to highlight the rules, but have instead written our own small
// function named 'ighlight' as shown below.
//
// See: https://github.com/react-simple-code-editor/react-simple-code-editor for more info on
// react-simple-code-editor

function NewRule() {
    const [{ translateRuleToEnglish }] = useTranslateRuleToEnglish();

    const { currentAttributes } = useContext(attributesContext);

    const {
        newRule,
        setNewRule,
        setButtonSaveNewRuleDisabled,
        textAreaNewRuleDisabled,
        code,
        setCode,
    } = useContext(displayContext);

    // const [code, setCode] = React.useState("");

    function highlight1(code) {
        // Create a list of operators allowed in the new rule
        const operators = [
            ">",
            ">=",
            "=",
            "<=",
            "<",
            "!=",
            "and",
            "or",
            "(",
            ")",
            "og",
            "eller",
            "ja",
            "nej",
            "yes",
            "no",
        ];

        // Create a list of attributes allowed in the new rule
        const attributes = [];
        currentAttributes.map((attribute) => {
            attributes.push(attribute.name.toLowerCase());
        });

        let highlightedCode = "";

        // This regex gave error in Safari because it does not support 'look-beind'
        // See: https://medium.com/@shemar.gordon32/how-to-split-and-keep-the-delimiter-s-d433fb697c65
        // code.split(/(?=[ ()])|(?<=[ ()])/g).map((token, i) => {

        // This is supposed to work in Safari
        // See: https://stackoverflow.com/questions/68944115/regexp-javascript-split-string-on-multiple-characters-keep-separators-withou
        // answer 1, so not the one with the check-mark
        // ...and here is for testing javascript - jsfiddle: https://jsfiddle.net/
        code.split(/([ ()])/g).map((token, i) => {
            if (
                operators.includes(token.toLowerCase()) ||
                attributes.includes(token.toLowerCase())
            ) {
                highlightedCode =
                    highlightedCode +
                    '<span class="ruleSyntaxKeyword">' +
                    token +
                    "</span>";
            } else {
                highlightedCode =
                    highlightedCode +
                    '<span class="ruleSyntaxNotKeyword">' +
                    token +
                    "</span>";
            }
        });

        return highlightedCode;
    }

    function handleRuleChange(code) {
        setButtonSaveNewRuleDisabled(false);
        setCode(code);

        // Keep the rule in the local language and make a translation
        let localRule = code;
        let translatedRule = translateRuleToEnglish(localRule);

        // See: https://blog.abelotech.com/posts/split-string-into-tokens-javascript/
        let ruleTokens = translatedRule.match(/\S+/g);

        // set error... maybe use findformerror...
        // let errorInRule = false;

        // console.log("ResultExplanation - localRule: ", localRule);
        // if (ruleTokens) {
        //     ruleTokens.map((token) => {
        //         if (
        //             !(operators.includes(token) || attributes.includes(token))
        //         ) {
        //             errorInRule = true;
        //         }
        //     });
        // }

        setNewRule(code);
    }

    return (
        (textAreaNewRuleDisabled && (
            <Editor
                className="ruleEditor"
                preClassName="ruleEditorPre"
                disabled
                value={code}
                // onValueChange={(code) => setCode(code)}
                onValueChange={(code) => handleRuleChange(code)}
                // highlight={(code) => highlight(code, languages.js)}
                highlight={(code) => highlight1(code)}
                padding={10}
            />
        )) ||
        (!textAreaNewRuleDisabled && (
            <Editor
                className="ruleEditor"
                preClassName="ruleEditorPre"
                value={code}
                // onValueChange={(code) => setCode(code)}
                onValueChange={(code) => handleRuleChange(code)}
                // highlight={(code) => highlight(code, languages.js)}
                highlight={(code) => highlight1(code)}
                padding={10}
            />
        ))
    );
}

export default NewRule;
