import React, { useState, useContext, useEffect, useCallback } from 'react';
import { useNavigate } from "react-router-dom";

import { commonContext } from '../../common/CommonContext';
import { displayContext } from '../../common/DisplayContext';
import { errorContext } from '../../admin/contexts/ErrorContext';
import { kbhKContext } from '../contexts/KbhKContext';

import styles from '../styles.module.css';

import useEvaluate from '../hooks/useEvaluate';

import { BsTriangleFill } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Linkify from "react-linkify";

function Main() {

    // let navigate = useNavigate();

    const {
        t2,
    } = useContext(displayContext);

    const {
        setErrors,
    } = useContext(errorContext);

    const [{ handleEvaluate }] = useEvaluate();

    const {
        // optionDescriptions,
        optionDescriptions1,
        selectedOptions,
        setSelectedOptions,
        useEvaluationResult,
        versionEvaluationResult,
        placeEvaluationResult,
        contextEvaluationResult,
        continuationEvaluationResult,
        setUseEvaluationResult,
        setVersionEvaluationResult,
        setPlaceEvaluationResult,
        setContextEvaluationResult,
        setContinuationEvaluationResult,
        aggConclusion,
        setAggConclusion,
        knowledgeBase,
        evaluationResult,
        setEvaluationResult,
    } = useContext(kbhKContext);

    const {
        processing,
    } = useContext(commonContext);

    const [infoButton, setInfoButton] = useState(false);
    const [showConclusion, setShowConclusion] = useState(false);
    const [optionSelected, setOptionSelected] = useState([true, true, true, true, true]);


    let allResult = {}
    // let useResult = {}
    // let versionResult = {}
    // let placeResult = {}
    // let contextResult = {}
    // let continuationResult = {}


    // useEffect(() => {
    //     console.log('Main - selectedOptions: ', selectedOptions)

    // }, [selectedOptions])


    function handleOptionClick(attributeName, value, mode) {
        setOption1(attributeName, value, mode)
    }




    function setOption(ball, row, column, mode) {

        // If this option was not already selected,
        // if in replaceInRow mode, then remove other options in the same ball and row from selectedOptions 
        // else, if in replaceInColum mode, then remove other options in the same column from selectedOptions 
        // else, if in add mode, then just update this option.

        // 1. create copy of the original array of selected options
        // 2. add this option to the array of selected options...
        if (selectedOptions.findIndex(item => (item.ball == ball && item.row == row && item.column == column)) === -1) {

            let newSelectedOptions = [];
            // So, in replace mode, create a new array of the original where we filter away other options set for this column...
            if (mode == 'replaceInRow') {
                newSelectedOptions = selectedOptions.filter(item => !(item.ball === ball && item.row == row))
            }

            else if (mode == 'replaceInColumn') {
                newSelectedOptions = selectedOptions.filter(item => !(item.ball === ball && item.column == column))
            }

            else if (mode == 'add') {
                // ...but in add mode, create a new array as a full copy of the original
                newSelectedOptions = [...selectedOptions];
            }

            // add currentOption to selectedOptions
            newSelectedOptions.push({ "ball": ball, "row": row, "column": column });
            setSelectedOptions(newSelectedOptions);
            // ...else, i.e. this option was already selected, remove it from the array of selected options.
        } else {
            // remove currentOption from selectOptions
            setSelectedOptions(selectedOptions.filter(item => !(item.ball === ball && item.row == row && item.column == column)))
        }
    }



    function setOption1(attributeName, value, mode) {

        // If 
        //  - attribute was already selected with this value, then remove the attribute
        //  - attribute was already selected with a different value, then set it to this value
        //  - attribute was not selected, then add it with this value

        // attribute was already selected with this value => remove the attribute to reflect that it was de-selected
        if (selectedOptions.findIndex(item => (item.attributeName == attributeName && item.value == value)) != -1) {
            setSelectedOptions(selectedOptions.filter(item => (item.attributeName != attributeName)))
        }
        // attribute was already selected with a different value => set it to this value
        else if (selectedOptions.findIndex(item => (item.attributeName == attributeName && item.value != value)) != -1) {
            let newSelectedOptions = [];
            newSelectedOptions = selectedOptions.filter(item => !(item.attributeName === attributeName))
            newSelectedOptions.push({ "attributeName": attributeName, "value": value });
            setSelectedOptions(newSelectedOptions);
        }
        // attribute was not selected => add attribute with this value
        else if (selectedOptions.findIndex(item => (item.attributeName == attributeName)) === -1) {
            let newSelectedOptions = [];
            newSelectedOptions = [...selectedOptions];
            newSelectedOptions.push({ "attributeName": attributeName, "value": value });
            setSelectedOptions(newSelectedOptions);
        }
    }






    function clear() {
        setSelectedOptions([]);
        setShowConclusion(false);
    }

    function findFormErrors() {
        const newErrors = {};

        // setOptionSelected(tmpOptionSelected)

        if (selectedOptions.length == 0)
            newErrors.csv = "Options not selected"

        return newErrors;
    };

    async function evaluate() {
        setShowConclusion(false);
        // setAggConclusion('')
        // setUseEvaluationResult({})
        // setVersionEvaluationResult({})
        // setPlaceEvaluationResult({})
        // setContextEvaluationResult({})
        // setContinuationEvaluationResult({})
        setEvaluationResult({})

        // Validate input form
        const newErrors = findFormErrors();
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors);
        } else {
            // No errors! Put any logic here for the form submission!
            allResult = await handleEvaluate('kbhk', 'all');
            // useResult = await handleEvaluate('kbhk', 'all');
            // versionResult = await handleEvaluate('trademark', 'version');
            // placeResult = await handleEvaluate('trademark', 'place');
            // contextResult = await handleEvaluate('trademark', 'context');
            // continuationResult = await handleEvaluate('trademark', 'continuation');

            // if (
            //     useResult.conclusionLastTrue == 'acquired' &&
            //     versionResult.conclusionLastTrue == 'acquired' &&
            //     placeResult.conclusionLastTrue == 'acquired' &&
            //     contextResult.conclusionLastTrue == 'acquired' &&
            //     continuationResult.conclusionLastTrue == 'acquired'
            // )
            //     setAggConclusion('Du har ved brug stiftet en varemærkeret til dit kendetegn:')
            // else
            //     setAggConclusion('Du har ikke stiftet en varemærkeret til dit kendetegn ved brug:')

            setShowConclusion(true);
        }
    }


    // // ////////////////////////////////
    // // Overflow indicator arrow - START
    // // ////////////////////////////////

    useEffect(() => {
        checkOverflow('column1-div');
        checkOverflow('column2-div');
        // checkOverflow('context-div');
        checkOverflow('conclusion-div');

        // // Add scroll event listeners to both divs
        document.getElementById('column1-div').addEventListener('scroll', function () {
            handleScroll('column1-div');
        });

        document.getElementById('column2-div').addEventListener('scroll', function () {
            handleScroll('column2-div');
        });

        // document.getElementById('context-div').addEventListener('scroll', function () {
        //     handleScroll('context-div');
        // });

        document.getElementById('conclusion-div').addEventListener('scroll', function () {
            handleScroll('conclusion-div');
        });

    }, []);

    // Function to check for overflow and show/hide indicator
    function checkOverflow(divId) {
        var div = document.getElementById(divId);

        if (div.scrollHeight > div.clientHeight) {
            showIndicator(divId);
        } else {
            hideIndicator(divId);
        }
    }

    // Function to handle scroll events and show/hide indicator based on position
    function handleScroll(divId) {
        var div = document.getElementById(divId);

        console.log(div.scrollTop + div.offsetHeight, ', ', div.scrollHeight)

        // if (div.scrollHeight - div.scrollTop === div.clientHeight) {
        if (div.scrollTop + div.offsetHeight >= div.scrollHeight) {
            hideIndicator(divId);
        } else {
            showIndicator(divId);
        }
    }

    // Function to show indicator
    function showIndicator(divId) {
        var indicator = document.getElementById(divId + 'Indicator');
        indicator.style.display = 'block';
    }

    // Function to hide indicator
    function hideIndicator(divId) {
        var indicator = document.getElementById(divId + 'Indicator');
        indicator.style.display = 'none';
    }

    // // ////////////////////////////////
    // // Overflow indicator arrow - END
    // // ////////////////////////////////


    return (
        <div>
            <div className={styles.desktopMain}>

                <div className={styles.desktopColumn1}>
                    <div id='column1-divIndicator'><BsTriangleFill className={[styles.desktopOverflowIcon, styles.desktopOverflowIconColumn1].join(' ')} /></div>
                    <div id='column1-div' className={optionSelected[2] ? [styles.desktopNoErrorBorder].join(' ') : [styles.desktopNoErrorBorder, styles.desktopErrorBorder].join(' ')} >

                        <div className={styles.desktopOptionGroup}>
                            {optionDescriptions1.filter(option => option.attributeGroup == 'group1').map(option => {
                                return (
                                    <div className={styles.selectionBox}>

                                        <OverlayTrigger
                                            // trigger={overlayTriggerHelp ? ["click", "hover", "focus"] : []}
                                            delay={400}
                                            delayHide={0}
                                            placement={"auto"}
                                            overlay={<Tooltip>{option.attributeText}</Tooltip>}
                                        >
                                            <div className={styles.selectionBoxAttributeText}>
                                                {option.attributeShort}
                                            </div>
                                        </OverlayTrigger>

                                        <div className={styles.selectionBoxRadioButtons}>

                                            <div className="desktop-select-option" >
                                                <input
                                                    type="checkbox"
                                                    className="checkbox-to-radio"
                                                    checked={selectedOptions.findIndex(item => (item.attributeName == option.attributeName && item.value == '1')) != -1}
                                                    onClick={() => handleOptionClick(option.attributeName, '1', 'add')}
                                                />
                                                {t2("yes", "trademark")}
                                            </div>

                                            <div className="desktop-select-option" >
                                                <input
                                                    type="checkbox"
                                                    className="checkbox-to-radio"
                                                    checked={selectedOptions.findIndex(item => (item.attributeName == option.attributeName && item.value == '2')) != -1}
                                                    onClick={() => handleOptionClick(option.attributeName, '2', 'add')}
                                                />
                                                {t2("no", "trademark")}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div >
                </div >


                <div className={styles.desktopColumn2}>
                    <div id='column2-divIndicator'><BsTriangleFill className={[styles.desktopOverflowIcon, styles.desktopOverflowIconcolumn2].join(' ')} /></div>
                    <div id='column2-div' className={optionSelected[2] ? [styles.desktopNoErrorBorder].join(' ') : [styles.desktopNoErrorBorder, styles.desktopErrorBorder].join(' ')} >

                        <div className={styles.desktopOptionGroup}>
                            {optionDescriptions1.filter(option => option.attributeGroup == 'group2').map(option => {
                                return (
                                    <div className={styles.selectionBox}>

                                        <OverlayTrigger
                                            // trigger={overlayTriggerHelp ? ["click", "hover", "focus"] : []}
                                            delay={400}
                                            delayHide={0}
                                            placement={"auto"}
                                            overlay={<Tooltip>{option.attributeText}</Tooltip>}
                                        >
                                            <div className={styles.selectionBoxAttributeText}>
                                                {option.attributeShort}
                                            </div>
                                        </OverlayTrigger>

                                        <div className={styles.selectionBoxRadioButtons}>

                                            <div className="desktop-select-option" >
                                                <input
                                                    type="checkbox"
                                                    className="checkbox-to-radio"
                                                    checked={selectedOptions.findIndex(item => (item.attributeName == option.attributeName && item.value == '1')) != -1}
                                                    onClick={() => handleOptionClick(option.attributeName, '1', 'add')}
                                                />
                                                {t2("yes", "trademark")}
                                            </div>

                                            <div className="desktop-select-option" >
                                                <input
                                                    type="checkbox"
                                                    className="checkbox-to-radio"
                                                    checked={selectedOptions.findIndex(item => (item.attributeName == option.attributeName && item.value == '2')) != -1}
                                                    onClick={() => handleOptionClick(option.attributeName, '2', 'add')}
                                                />
                                                {t2("no", "trademark")}
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div >
                </div >


                {/* Conclusion column */}
                <div className={[styles.desktopColumn2, styles.desktopColumnLast].join(' ')}>

                    <div className={styles.desktopConclusionHeading}>
                        {/* <a>Konklusion</a> */}
                        <div>
                            <Button
                                variant="success"
                                size="sm"
                                onClick={evaluate}
                            >
                                {t2('check', 'trademark')}
                            </Button>

                            {' '}

                            <Button variant="danger" size="sm" onClick={clear}>{t2('clear', 'trademark')}</Button>
                        </div>
                    </div>





                    <div>

                        <div className={showConclusion ? 'desktop-show-conclusions' : 'desktop-hide-conclusions'}>

                            <div id='conclusion-divIndicator'><BsTriangleFill className='desktop-overflow-icon desktop-overflow-icon-conclusion' /></div>
                            {/* <div className='desktop-agg-conclusion'>
                                {aggConclusion}
                            </div> */}



                            <div id='conclusion-div' className='desktop-explanation-list'>

                                {Object.keys(evaluationResult).length != 0 && evaluationResult.message.messageCode == 2 ?



                                    <div className={styles.noRulesBox}>
                                        {t2('noRulesForCase', knowledgeBase)}
                                    </div>

                                    :




                                    <div>



                                        <div className={styles.labelTextConclusion}>Konklusion</div>
                                        {evaluationResult.explanationLastTrue != '' ?

                                            <div className={
                                                Object.keys(evaluationResult).length != 0 && evaluationResult.conclusionLastTrue.includes('Ansøgningen afvises') ?
                                                    [styles.desktopExplanationBox, styles.desktopExplanationRejected].join(' ') :
                                                    [styles.desktopExplanationBox, styles.desktopExplanationApproved].join(' ')
                                            }
                                            >



                                                <Linkify
                                                    componentDecorator={(
                                                        decoratedHref,
                                                        decoratedText,
                                                        key
                                                    ) => (
                                                        <a
                                                            target="blank"
                                                            href={decoratedHref}
                                                            key={key}
                                                        >
                                                            {t2("labelSeeDecision", knowledgeBase)}
                                                        </a>
                                                    )}
                                                >
                                                    {t2(evaluationResult.conclusionLastTrue, knowledgeBase)}
                                                </Linkify>
                                            </div>

                                            : ''

                                        }

                                        {evaluationResult.explanationLastTrue != '' ?
                                            // < div className={
                                            //     evaluationResult.conclusionLastTrue == 'acquired' ?
                                            //         [styles.desktopExplanationBox, styles.desktopExplanationApproved].join(' ')
                                            //         : [styles.desktopExplanationBox, styles.desktopExplanationRejected].join(' ')
                                            // }
                                            // >
                                            <div className={styles.desktopExplanationBox}>
                                                <div className={styles.labelText}>Forklaring</div>
                                                <Linkify
                                                    componentDecorator={(
                                                        decoratedHref,
                                                        decoratedText,
                                                        key
                                                    ) => (
                                                        <a
                                                            target="blank"
                                                            href={decoratedHref}
                                                            key={key}
                                                        >
                                                            {t2("goToLink", 'sysadmin')}
                                                        </a>
                                                    )}
                                                >
                                                    {t2(evaluationResult.explanationLastTrue, knowledgeBase)}
                                                </Linkify>
                                            </div>

                                            : ''
                                        }



                                        {evaluationResult.explanationLastTrue != '' ?
                                            // < div className={
                                            //     evaluationResult.conclusionLastTrue == 'acquired' ?
                                            //         [styles.desktopExplanationBox, styles.desktopExplanationApproved].join(' ')
                                            //         : [styles.desktopExplanationBox, styles.desktopExplanationRejected].join(' ')
                                            // }
                                            // >
                                            <div className={styles.desktopExplanationBox}>
                                                <div className={styles.labelText}>Note</div>

                                                <Linkify
                                                    componentDecorator={(
                                                        decoratedHref,
                                                        decoratedText,
                                                        key
                                                    ) => (
                                                        <a
                                                            target="blank"
                                                            href={decoratedHref}
                                                            key={key}
                                                        >
                                                            {t2("goToLink", 'sysadmin')}
                                                        </a>
                                                    )}
                                                >
                                                    {t2(evaluationResult.noteLastTrue, knowledgeBase)}
                                                </Linkify>
                                            </div>

                                            : ''
                                        }
                                    </div>
                                }
                            </div>

                        </div>

                        {/* : */}

                        {processing &&
                            <div style={{ display: !showConclusion ? 'flex' : 'none' }} className='desktop-spinner-container'>
                                <div className='desktop-spinner'>
                                    <Spinner
                                        animation="border"
                                        variant="secondary"
                                    />
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div >
        </div >
    )
};

export default Main;










