import React, { useContext, useMemo, useState, useEffect } from "react";

import styles from '../../styles.module.css';

// Table imports
import {
    Table,
    Header,
    HeaderRow,
    HeaderCell,
    Body,
    Row,
    Cell,
} from "@table-library/react-table-library/table";

import {
    useSort,
    // HeaderCellSort,
} from "@table-library/react-table-library/sort";

import {
    // Sort,
    // SortOptions,
    // SortIconPositions,
    SortToggleType,
} from "@table-library/react-table-library/sort";

// import { defaultTheme } from "../../hooks/useReactTableTheme";
import useReactTableTheme from "../../hooks/useReactTableTheme";

import { useTheme } from "@table-library/react-table-library/theme";
import defaultTableTheme from "../KnowledgeBrowser/DefaultTableTheme";

import {
    BsArrowDown,
    BsArrowUp,
    BsFileArrowDown,
    BsFileArrowUp,
} from "react-icons/bs";

import { attributesContext } from "../../contexts/AttributesContext";
import { displayContext } from "../../../common/DisplayContext";

function AttributeTable() {
    const { currentAttributes } = useContext(attributesContext);

    const { t } = useContext(displayContext);

    const [{ defaultTheme }] = useReactTableTheme();

    const [data, setData] = useState({ nodes: currentAttributes });

    useEffect(() => {
        setData({ nodes: currentAttributes });
    }, [currentAttributes]);


    defaultTableTheme.Table = `
        height: 100%; 
        width: 100%; 
        align-content: baseline; 
        --data-table-library_grid-template-columns: 20% 40% 10% 10% 10% 10%;
    `
    const theme = useTheme(defaultTableTheme)

    const handleNameFilter = (value) => {
        setData({
            nodes:
                value !== ""
                    ? currentAttributes.filter(function (item) {
                        return item.name.includes(value);
                    })
                    : currentAttributes,
        });
    };

    const handleDisplayNameFilter = (value) => {
        setData({
            nodes:
                value !== ""
                    ? currentAttributes.filter(function (item) {
                        return item.displayname.includes(value);
                    })
                    : currentAttributes,
        });
    };

    const handleTypeFilter = (value) => {
        setData({
            nodes:
                value !== ""
                    ? currentAttributes.filter(function (item) {
                        return item.type.includes(value);
                    })
                    : currentAttributes,
        });
    };

    // const handleNormMinFilter = (value) => {
    //     setData({
    //         nodes:
    //             value !== ""
    //                 ? currentAttributes.filter(function(item) {
    //                       return item.normmin.includes(value);
    //                   })
    //                 : currentAttributes,
    //     });
    // };

    // const handleNormMaxFilter = (value) => {
    //     setData({
    //         nodes:
    //             value !== ""
    //                 ? currentAttributes.filter(function(item) {
    //                       return item.normmax.includes(value);
    //                   })
    //                 : currentAttributes,
    //     });
    // };

    const sort = useSort(
        data,
        {
            onChange: onSortChange,
        },
        {
            sortToggleType: SortToggleType.AlternateWithReset,
            sortFns: {
                Name: (array) =>
                    array.sort((a, b) => a.name.localeCompare(b.name)),
                DisplayName: (array) =>
                    array.sort((a, b) =>
                        a.displayName.localeCompare(b.displayName)
                    ),
                Test: (array) =>
                    array.sort((a, b) => a.test.localeCompare(b.test)),
            },
        }
    );

    function onSortChange(action, state) {
        console.log(action, state);
    }

    console.log("AttributeTable - data: ", data);

    return (
        <div className={styles.attributeCaseTable}>
            <Table 
                data={data} 
                theme={theme} 
                sort={sort}
                layout={{custom: true}}
            >
                {(tableList) => (
                    <>
                        <Header>
                            <HeaderRow>
                                <HeaderCell>
                                    {t("name")} <br />
                                    <input
                                        className={styles.caseTableFilter}
                                        type="text"
                                        placeholder="Filter..."
                                        onChange={(event) =>
                                            handleNameFilter(event.target.value)
                                        }
                                    />
                                </HeaderCell>
                                <HeaderCell>
                                    {t("displayName")} <br />
                                    <input
                                        className={styles.caseTableFilter}
                                        type="text"
                                        placeholder="Filter..."
                                        onChange={(event) =>
                                            handleDisplayNameFilter(
                                                event.target.value
                                            )
                                        }
                                    />
                                </HeaderCell>

                                <HeaderCell>
                                    {t("type")} <br />
                                    <input
                                        className={styles.caseTableFilter}
                                        type="text"
                                        placeholder="Filter..."
                                        onChange={(event) =>
                                            handleTypeFilter(event.target.value)
                                        }
                                    />
                                </HeaderCell>

                                <HeaderCell>
                                    {t("normMin")} <br />
                                    {/* <input
                                        className="case-table-filter"
                                        type="text"
                                        placeholder="Filter..."
                                        onChange={(event) =>
                                            handleNormMinFilter(
                                                event.target.value
                                            )
                                        }
                                    /> */}
                                </HeaderCell>

                                <HeaderCell>
                                    {t("normMax")} <br />
                                    {/* <input
                                        className="case-table-filter"
                                        type="text"
                                        placeholder="Filter..."
                                        onChange={(event) =>
                                            handleNormMaxFilter(
                                                event.target.value
                                            )
                                        }
                                    /> */}
                                </HeaderCell>


                                <HeaderCell>
                                    {t("groupName")} <br />
                                </HeaderCell>


                            </HeaderRow>
                        </Header>

                        <Body>
                            {tableList.map((item) => (
                                <Row key={item.name} item={item}>
                                    <Cell>{item.name}</Cell>
                                    <Cell>
                                        <div className={styles.attributeTableComment}>
                                            {" "}
                                            {item.displayname}
                                        </div>
                                    </Cell>
                                    <Cell>{item.type}</Cell>
                                    <Cell>{item.normmin}</Cell>
                                    <Cell>{item.normmax}</Cell>
                                    <Cell>{item.groupname}</Cell>
                                </Row>
                            ))}
                        </Body>
                    </>
                )}
            </Table>
        </div>
    );
}

export default AttributeTable;
