// src/contexts/NavigationContext.js
import React, { createContext, useState } from 'react';

export const navigationContext = createContext();

export const NavigationContextProvider = ({ children }) => {
    const [triggerCaseRejected, setTriggerCaseRejected] = useState(false);
    const [page, setPage] = useState('evaluateCase');

    return (
        <navigationContext.Provider
            value={{
                triggerCaseRejected,
                setTriggerCaseRejected,
                page,
                setPage,
            }}
        >
            {children}
        </navigationContext.Provider>
    );
};
