import React from 'react';

import EUAIActContextProvider from '../contexts/Context';

import styles from '../styles.module.css';

import Topbar from './Topbar';
import Main from './Main';
import Bottombar from './Bottombar';

function EUAIAct() {
    return (
        <EUAIActContextProvider>
            <div className={[styles.desktopContainer, styles.desktopFont].join(' ')}>

                <Topbar />

                <Main />

                <Bottombar />

            </div >
        </EUAIActContextProvider >
    )
};

export default EUAIAct;
