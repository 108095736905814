import React, { useContext, useEffect, useState, Text } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Form,
    Row,
    Col,
    InputGroup,
    Button,
    ButtonGroup,
} from "react-bootstrap";

import {
    BsCheck,
    BsFillCheckCircleFill,
    BsFillQuestionCircleFill,
    BsFillXCircleFill,
} from "react-icons/bs";

import Spinner from 'react-bootstrap/Spinner';

import Modal from "react-modal";
// import Linkify from "linkify-react";
import Linkify from "react-linkify";

// Flags: https://www.npmjs.com/package/flag-icons
import "/node_modules/flag-icons/css/flag-icons.min.css";

import { domainContext } from "../../admin/contexts/DomainContext";
import { userCaseContext } from "../../admin/contexts/UserCaseContext";
import { displayContext } from "../../common/DisplayContext";
import { evaluationContext } from "../../admin/contexts/EvaluationContext";
import { tmevaluationContext } from "../contexts/TMEvaluationContext";
import { swiperContext } from "../../legal/contexts/SwiperContext";

import useAuth from "../../admin/hooks/useAuth";
// import useEvaluate from "../../commonTrademark/hooks/useEvaluate";
import useEvaluate from "../hooks/useEvaluate";

import NavigationSideBarTM2 from "../components/Navigation/NavigationSideBar2";

import Header from "../components/Header";

// import { Config } from "../../config";

function TM1ResultPage() {
    const { token } = useAuth();

    const [{ handleEvaluate, fetchData }] = useEvaluate();

    const { setDisplayMessage, previousPath, setUseMode, t2, setIsEvaluating } =
        useContext(displayContext);

    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        setDisplayMessage("");
    }, [location.key]);


    const [showConclusion, setShowConclusion] = useState(false);


    // useEffect(() => {
    //     setShowConclusion(false)
    //     Promise.all([
    //         fetchData('use').then(res => { useResult = res }),
    //         fetchData('version').then(res => { versionResult = res }),
    //         fetchData('continuation').then(res => { versionResult = res }),
    //         fetchData('place').then(res => { versionResult = res }),
    //         fetchData('context').then(res => { versionResult = res }),
    //     ]).then(([one, two, three, four, five]) => {
    //         setShowConclusion(true)
    //     })
    // }, []);

    useEffect(() => {
        setShowConclusion(false)
        Promise.all([
            handleEvaluate('trademark', 'use'),
            handleEvaluate('trademark', 'version'),
            handleEvaluate('trademark', 'continuation'),
            handleEvaluate('trademark', 'place'),
            handleEvaluate('trademark', 'context'),
        ]).then(([one, two, three, four, five]) => {
            setShowConclusion(true)
        })
    }, []);


    const { currentKnowledgeBase, currentDomain, setCurrentDomain } =
        useContext(domainContext);

    const { userCase, setUserCase, setCaseStatus } =
        useContext(userCaseContext);

    const {
        conclusionLastTrue,
        explanationLastTrue,
        currentCaseId,
        nodeLastTrue,
        setConclusionLastTrue,
        setEvaluationResult,
        setExplanation,
        setExplanationLastTrue,
        setExplanationTrues,
        setNodeLast,
        setNodeLastTrue,
        setNodesEvaluated,
        setNoteLastTrue,
        setReferenceCase,
        setReturnCode,
    } = useContext(evaluationContext);

    const {
        tmUseEvaluationResult,
        tmTimeEvaluationResult,
        tmIntensityEvaluationResult,
        tmGeographyEvaluationResult,
    } = useContext(tmevaluationContext);

    const {
        useEvaluationResult,
        versionEvaluationResult,
        continuationEvaluationResult,
        placeEvaluationResult,
        contextEvaluationResult,
        selectedOptions,
        setUseEvaluationResult,
        setVersionEvaluationResult,
        setPlaceEvaluationResult,
        setContextEvaluationResult,
        setContinuationEvaluationResult,
        aggConclusion,
        setAggConclusion,
        processing,
    } = useContext(swiperContext);


    function handleSubmit(e) {
        setIsEvaluating(true);
        handleEvaluate(e);
    }



    function getResultClass1(result) {
        if (result == "") return "vm-grid-result1x vm-no-information-given";
        else
            return [
                "useCriteriaNotMet",
                "timeCriteriaNotMet",
                "intensityCriteriaNotMet",
                "geographyCriteriaNotMet",
            ].includes(result)
                ? "vm-grid-result1x vm-criteria-not-met"
                : "vm-grid-result1x vm-criteria-met";
    }

    function getIcon(result) {
        if (result == "acquired")
            return (<BsFillCheckCircleFill
                style={{
                    color: "#75335d",
                    fontSize: "30px",
                }}
            />)
        else if (result == "acquiredNot")
            return (<BsFillXCircleFill
                // className={getIconClass()}
                style={{
                    color: "#75335d",
                    fontSize: "30px",
                }}
            />)
        else return (
            <BsFillQuestionCircleFill
                // className={getIconClass()}
                style={{
                    color: "#75335d",
                    fontSize: "30px",
                }}
            />
        );
    }



    function OLD_getIcon(result) {
        if (result == "")
            return (
                <BsFillQuestionCircleFill
                    // className={getIconClass()}
                    style={{
                        color: "#75335d",
                        fontSize: "30px",
                    }}
                />
            );
        else
            return [
                "useCriteriaNotMet",
                "timeCriteriaNotMet",
                "intensityCriteriaNotMet",
                "geographyCriteriaNotMet",
            ].includes(result) ? (
                <BsFillXCircleFill
                    // className={getIconClass()}
                    style={{
                        color: "#75335d",
                        fontSize: "30px",
                    }}
                />
            ) : (
                <BsFillCheckCircleFill
                    // className={getIconClass()}
                    style={{
                        color: "#75335d",
                        fontSize: "30px",
                    }}
                />
            );
    }






    function viewPDF(e) {
        console.log("TMResultPage - viewPDF has been clicked - e: ", e);
    }

    function extractLinks1(s) {
        console.log("TMResultPage - s: ", s);

        let t = "";
        let convertedText = "";

        // First get all text before a potential mentor-link
        convertedText = s.substr(
            0,
            s.indexOf("mentor-link") != -1 ? s.indexOf("mentor-link") : s.length
        );

        console.log(
            "TMResultPage - convertedText the mentor-link: ",
            convertedText
        );

        // Then get the remainder of the string starting with the mentor-link
        s = s.substr(
            s.indexOf("mentor-link") != -1
                ? s.indexOf("mentor-link")
                : s.length,
            s.length
        );

        console.log("TMResultPage - s after the mentor-link: ", s);

        while (s.length > 0) {
            // The url of the link
            let linkURL = s.substr(0, s.substr(0, s.indexOf(")")).indexOf(","));
            // The describing text of the link
            let linkText = s.substr(
                s.substr(0, s.indexOf(")")).indexOf(","),
                s.indexOf(")")
            );
            // The remaining text after the link (which might contain one or more extra links)
            s = s.substr(s.indexOf(")") + 1, s.length);

            // Create the a-tag from the mentor-link
            let atag =
                "<a href=" + linkURL + ' target="_blank"' + linkText + "</a>";

            convertedText = convertedText + atag;

            // If there are no more mentor-links left, then add the reamining text to the converted text and reset 's' (the reamining string)
            if (s.substr("mentor-link") == -1) {
                convertedText = convertedText + s;
                s = "";
            }
        }

        return convertedText;
    }

    function extractLinks(s) {
        <Linkify>s</Linkify>;
    }

    function testurl() {
        return (
            <a href="http://google.com" target="_blank">
                klik
            </a>
        );
    }



    function preapareDecision(decision) {
        if (typeof decision != "undefined") {
            // let decision = t2(explanation, "varemaerke");
            let convertedStrings = [];
            let splitText = decision.split("https");
            let i = 0;
            for (let substring of splitText) {
                if (
                    substring.indexOf("://mentor.cognitysystems.com/docs/") ==
                    -1
                ) {
                    convertedStrings.push(<div>substring</div>);
                } else {
                    convertedStrings.push =
                        <div>her kommer knappen</div> + <div>substring</div>;
                }
            }

            return convertedStrings;

            // return [<div>hunde</div>, <div>hoved </div>];
        }
    }

    function replaceLink(text) {
        // let vartxt = "Hundehoved";
        // start = text.indexOf("://") + 40;
        // end = text.length();

        const textAfterLink = text.substring(
            text.indexOf(".pdf") == -1 ? 0 : text.indexOf(".pdf") + 4,
            text.length
        );

        // ://mentor.cognitysystems.com/docs/DenStoreBagedyst.pdf));

        // const textIndexOfPdf = text.indexOf(".pdf");
        const docName =
            text.indexOf(".pdf") != -1
                ? text.substring(
                    text.substring(0, text.indexOf(".pdf")).lastIndexOf("/"),
                    text.indexOf(".pdf")
                )
                : "";

        if (typeof text != "undefined") {
            return (
                <div>
                    {textAfterLink}
                    {/* <button onClick={handleGetPdf(docName)}>{docName}</button> */}
                    <a
                        href="localhost:3000/backend/getDecision"
                        target="_blank"
                    >
                        {docName}
                    </a>
                </div>
            );
        }
    }

    return (
        <>
            <Header />
            <div style={{
                display: "flex", flexDirection: "row", marginTop: "8vh"
            }}>
                <div>
                    <NavigationSideBarTM2 />
                </div>

                <div className="tm1-result">

                    <div style={{ display: !showConclusion ? 'flex' : 'none' }} className='desktop-spinner-container'>
                        <div className='desktop-spinner'>
                            <Spinner
                                animation="border"
                                variant="success"
                            />
                        </div>
                    </div>

                    <div className={showConclusion ? 'desktop-show-conclusions' : 'desktop-hide-conclusions'}>

                        <div
                            id="result-intro"
                            style={{ paddingBottom: "2em" }}
                        >
                            <strong>{t2("resultIntro", "varemaerke")}</strong>
                            {tmUseEvaluationResult["conclusionLastTrue"] ==
                                "useCriteriaMet" &&
                                tmTimeEvaluationResult["conclusionLastTrue"] ==
                                "timeCriteriaMet" &&
                                tmIntensityEvaluationResult["conclusionLastTrue"] ==
                                "intensityCriteriaMet" &&
                                tmGeographyEvaluationResult["conclusionLastTrue"] ==
                                "geographyCriteriaMet"
                                ? t2("trademarkEstablished", "varemaerke")
                                : t2("trademarkNotEstablished", "varemaerke")}
                        </div>

                        {/* Use */}
                        <div style={{ marginBottom: "20px" }}>
                            <div style={{ display: "flex" }}>
                                <div>
                                    {getIcon(
                                        useEvaluationResult.conclusionLastTrue
                                    )}
                                </div>

                                <div
                                    style={{
                                        marginLeft: "20px",
                                    }}
                                >
                                    <Linkify
                                        componentDecorator={(
                                            decoratedHref,
                                            decoratedText,
                                            key
                                        ) => (
                                            <a
                                                target="blank"
                                                href={decoratedHref}
                                                key={key}
                                            >
                                                {t2("seeDecision", "varemaerke")}
                                            </a>
                                        )}
                                    >
                                        {t2(useEvaluationResult.explanationLastTrue, "trademark")}
                                    </Linkify>
                                </div>
                            </div>
                        </div>

                        {/* Version */}
                        <div style={{ marginBottom: "20px" }}>
                            <div style={{ display: "flex" }}>
                                <div>
                                    {getIcon(
                                        versionEvaluationResult.conclusionLastTrue
                                    )}
                                </div>

                                <div
                                    style={{
                                        marginLeft: "20px",
                                    }}
                                >
                                    <Linkify
                                        componentDecorator={(
                                            decoratedHref,
                                            decoratedText,
                                            key
                                        ) => (
                                            <a
                                                target="blank"
                                                href={decoratedHref}
                                                key={key}
                                            >
                                                {t2("seeDecision", "varemaerke")}
                                            </a>
                                        )}
                                    >
                                        {t2(versionEvaluationResult.explanationLastTrue, "trademark")}
                                    </Linkify>
                                </div>
                            </div>
                        </div>

                        {/* Geography */}
                        <div style={{ marginBottom: "20px" }}>
                            <div style={{ display: "flex" }}>
                                <div>
                                    {getIcon(
                                        placeEvaluationResult.conclusionLastTrue
                                    )}
                                </div>

                                <div style={{ marginLeft: "20px" }}>
                                    <Linkify
                                        componentDecorator={(
                                            decoratedHref,
                                            decoratedText,
                                            key
                                        ) => (
                                            <a
                                                target="blank"
                                                href={decoratedHref}
                                                key={key}
                                            >
                                                {t2("seeDecision", "varemaerke")}
                                            </a>
                                        )}
                                    >
                                        {t2(placeEvaluationResult.explanationLastTrue, "trademark")}
                                    </Linkify>
                                </div>
                            </div>
                        </div>

                        {/* Intenstiy  */}
                        <div style={{ marginBottom: "20px" }}>
                            <div style={{ display: "flex" }}>
                                <div>
                                    {getIcon(
                                        contextEvaluationResult.conclusionLastTrue
                                    )}
                                </div>

                                <div style={{ marginLeft: "20px" }}>
                                    <Linkify
                                        componentDecorator={(
                                            decoratedHref,
                                            decoratedText,
                                            key
                                        ) => (
                                            <a
                                                target="blank"
                                                href={decoratedHref}
                                                key={key}
                                            >
                                                {t2("seeDecision", "varemaerke")}
                                            </a>
                                        )}
                                    >
                                        {t2(contextEvaluationResult.explanationLastTrue, "trademark")}
                                    </Linkify>
                                </div>
                            </div>
                        </div>

                        {/* Time  */}
                        <div>
                            <div style={{ display: "flex" }}>
                                <div>
                                    {getIcon(
                                        continuationEvaluationResult.conclusionLastTrue
                                    )}
                                </div>

                                <div style={{ marginLeft: "20px" }}>
                                    <Linkify
                                        componentDecorator={(
                                            decoratedHref,
                                            decoratedText,
                                            key
                                        ) => (
                                            <a
                                                target="blank"
                                                href={decoratedHref}
                                                key={key}
                                            >
                                                {t2("seeDecision", "varemaerke")}
                                            </a>
                                        )}
                                    >
                                        {t2(continuationEvaluationResult.explanationLastTrue, "trademark")}
                                    </Linkify>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </>
    );
}

export default TM1ResultPage;





