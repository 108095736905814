import React, { createContext, useState } from "react";

export const tmevaluationContext = createContext();

const TMEvaluationContextProvider = (props) => {
    const [tmUseEvaluationResult, setTMUseEvaluationResult] = useState({
        caseLastTrue: [{}],
        conclusionLastTrue: "",
        noteLastTrue: "",
        explanation: [],
        explanationTrues: [],
        nodeLast: 0,
        nodeLastTrue: 0,

        nodesEvaluated: [
            {
                conclusion: "",
                cornerstonecaseid: null,
                evaluationResult: true,
                id: 0,
                nextnodefalse: 0,
                nextnodetrue: 0,
                rule: "",
            },
        ],

        referenceCase: [],
        req_data: {
            age: "",
            weight: "",
        },
    });

    const [tmTimeEvaluationResult, setTMTimeEvaluationResult] = useState({
        caseLastTrue: [{}],
        conclusionLastTrue: "",
        noteLastTrue: "",
        explanation: [],
        explanationTrues: [],
        nodeLast: 0,
        nodeLastTrue: 0,

        nodesEvaluated: [
            {
                conclusion: "",
                cornerstonecaseid: null,
                evaluationResult: true,
                id: 0,
                nextnodefalse: 0,
                nextnodetrue: 0,
                rule: "",
            },
        ],

        referenceCase: [],
        req_data: {
            age: "",
            weight: "",
        },
    });

    const [tmIntensityEvaluationResult, setTMIntensityEvaluationResult] =
        useState({
            caseLastTrue: [{}],
            conclusionLastTrue: "",
            noteLastTrue: "",
            explanation: [],
            explanationTrues: [],
            nodeLast: 0,
            nodeLastTrue: 0,

            nodesEvaluated: [
                {
                    conclusion: "",
                    cornerstonecaseid: null,
                    evaluationResult: true,
                    id: 0,
                    nextnodefalse: 0,
                    nextnodetrue: 0,
                    rule: "",
                },
            ],

            referenceCase: [],
            req_data: {
                age: "",
                weight: "",
            },
        });

    const [tmGeographyEvaluationResult, setTMGeographyEvaluationResult] =
        useState({
            caseLastTrue: [{}],
            conclusionLastTrue: "",
            noteLastTrue: "",
            explanation: [],
            explanationTrues: [],
            nodeLast: 0,
            nodeLastTrue: 0,

            nodesEvaluated: [
                {
                    conclusion: "",
                    cornerstonecaseid: null,
                    evaluationResult: true,
                    id: 0,
                    nextnodefalse: 0,
                    nextnodetrue: 0,
                    rule: "",
                },
            ],

            referenceCase: [],
            req_data: {
                age: "",
                weight: "",
            },
        });

    // const [currentCaseId, setCurrentCaseId] = useState();
    // const [actualClass, setActualClass] = useState("");
    // const [conclusionLastTrue, setConclusionLastTrue] = useState("");
    // const [explanationLastTrue, setExplanationLastTrue] = useState("");
    // const [explanationTrues, setExplanationTrues] = useState([]);
    // const [explanation, setExplanation] = useState([]);
    // const [explanationFiltered, setExplanationFiltered] = useState([]);
    // const [nodeLast, setNodeLast] = useState(0);
    // const [nodeLastTrue, setNodeLastTrue] = useState(0);
    // const [nodesEvaluated, setNodesEvaluated] = useState([]);
    // const [noteLastTrue, setNoteLastTrue] = useState("");
    // const [referenceCase, setReferenceCase] = useState([]);
    // const [returnCode, setReturnCode] = useState(0);

    return (
        <tmevaluationContext.Provider
            value={{
                setTMGeographyEvaluationResult,
                setTMIntensityEvaluationResult,
                setTMTimeEvaluationResult,
                setTMUseEvaluationResult,
                tmGeographyEvaluationResult,
                tmIntensityEvaluationResult,
                tmTimeEvaluationResult,
                tmUseEvaluationResult,
            }}
        >
            {props.children}
        </tmevaluationContext.Provider>
    );
};

export default TMEvaluationContextProvider;
