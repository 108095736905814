import React, { createContext, useState, useEffect } from 'react';

import useDebugState from '../hooks/useDebugState';

export const evaluationContext = createContext();

const EvaluationContextProvider = (props) => {
    const [evaluationResult_tmp, setEvaluationResult_tmp] = useState([
        {
            attributes: [
                {
                    category: null,
                    displayname: null,
                    name: '10år_plus',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: '15år_plus',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'afdækning_besluttet',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'alder',
                    normmax: null,
                    normmin: null,
                    type: 'integer',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'allerede_støttet',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'andre_børn_afdækning',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'andre_børn_børnefaglig',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'barn_ikke_inddragelse',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'barn_uenig_afdækning',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'barnets_plan_ungeplan',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'børnefaglig_afsluttet',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'børnefaglig_behov',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'fagprofessionel',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'forebyggende',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'forældremyndige_uenige_afdækning',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'fravig_barnet',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'genvurdering',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'indsats_lukkes_alder',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'indsats_lukkes_formål',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'indsats_lukkes_målet_nået',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'kommende_forældre',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'lukning',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'mistanke_forældre',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'mistrivsel_mistanke',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'observation',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'oplysninger_andre_forvaltninger',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'oplysninger_fra_andre',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'oplysninger_yderligere',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'overgreb_mistanke',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'overgreb_oplysning',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'overgreb_udsat',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'politi',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'psykolog_undersøgelse_barn',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'psykolog_undersøgelse_forældremyndige',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'risikovurdering_besluttet',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'risikovurdering_tvivl',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'sideløbende',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'støttebehov_større',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'støttende_umuligt',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'tidligere_indsats',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'tidsfrist_børnefaglig',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: 'Der er indgivet underretning',
                    name: 'underretning_indgivet',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'undervisningsbehov',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'æresrelateret_mistanke',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
                {
                    category: null,
                    displayname: null,
                    name: 'æresrelateret_socialkontrol',
                    normmax: null,
                    normmin: null,
                    type: 'check',
                },
            ],
            caseData: [
                {
                    category: 'null',
                    displayName: 'ID',
                    id: 'id',
                    name: 'id',
                    referenceValue: 0,
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'fagprofessionel',
                    name: 'fagprofessionel',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'mistanke_forældre',
                    name: 'mistanke_forældre',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'æresrelateret_mistanke',
                    name: 'æresrelateret_mistanke',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'æresrelateret_socialkontrol',
                    name: 'æresrelateret_socialkontrol',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'mistrivsel_mistanke',
                    name: 'mistrivsel_mistanke',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: '10år_plus',
                    name: '10år_plus',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'overgreb_mistanke',
                    name: 'overgreb_mistanke',
                    test: '1',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'allerede_støttet',
                    name: 'allerede_støttet',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'lukning',
                    name: 'lukning',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'overgreb_oplysning',
                    name: 'overgreb_oplysning',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'genvurdering',
                    name: 'genvurdering',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'overgreb_udsat',
                    name: 'overgreb_udsat',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'politi',
                    name: 'politi',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'støttebehov_større',
                    name: 'støttebehov_større',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'støttende_umuligt',
                    name: 'støttende_umuligt',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'tidsfrist_børnefaglig',
                    name: 'tidsfrist_børnefaglig',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'afdækning_besluttet',
                    name: 'afdækning_besluttet',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'oplysninger_fra_andre',
                    name: 'oplysninger_fra_andre',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'Der er indgivet underretning',
                    id: 'underretning_indgivet',
                    name: 'underretning_indgivet',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'sideløbende',
                    name: 'sideløbende',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'forebyggende',
                    name: 'forebyggende',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'observation',
                    name: 'observation',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'barnets_plan_ungeplan',
                    name: 'barnets_plan_ungeplan',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'børnefaglig_afsluttet',
                    name: 'børnefaglig_afsluttet',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'indsats_lukkes_formål',
                    name: 'indsats_lukkes_formål',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'indsats_lukkes_alder',
                    name: 'indsats_lukkes_alder',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'alder',
                    name: 'alder',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'risikovurdering_tvivl',
                    name: 'risikovurdering_tvivl',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'oplysninger_yderligere',
                    name: 'oplysninger_yderligere',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'oplysninger_andre_forvaltninger',
                    name: 'oplysninger_andre_forvaltninger',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'kommende_forældre',
                    name: 'kommende_forældre',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'børnefaglig_behov',
                    name: 'børnefaglig_behov',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'indsats_lukkes_målet_nået',
                    name: 'indsats_lukkes_målet_nået',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'fravig_barnet',
                    name: 'fravig_barnet',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'barn_ikke_inddragelse',
                    name: 'barn_ikke_inddragelse',
                    test: '1',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'barn_uening_afdækning',
                    name: 'barn_uening_afdækning',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: '15år_plus',
                    name: '15år_plus',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'psykolog_undersøgelse_barn',
                    name: 'psykolog_undersøgelse_barn',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'undervisningsbehov',
                    name: 'undervisningsbehov',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'forældremyndige_uenige_afdækning',
                    name: 'forældremyndige_uenige_afdækning',
                    test: '1',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'psykolog_undersøgelse_forældremyndige',
                    name: 'psykolog_undersøgelse_forældremyndige',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'andre_børn_afdækning',
                    name: 'andre_børn_afdækning',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'andre_børn_børnefaglig',
                    name: 'andre_børn_børnefaglig',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'tidligere_indsats',
                    name: 'tidligere_indsats',
                    test: '',
                    type: 'check',
                },
                {
                    category: 'null',
                    displayName: 'None',
                    id: 'risikovurdering_besluttet',
                    name: 'risikovurdering_besluttet',
                    test: '',
                    type: 'check',
                },
            ],
            caseId: 0,
            message: {
                function: 'evaluate',
                messageCode: 0,
                messageText: '',
            },
            nodesEvaluated: [
                {
                    conclusion: '',
                    conclusion_group: null,
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: true,
                    explanation: null,
                    id: 1,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 2,
                    next_node_true: 2,
                    note: null,
                    rule: '',
                },
                {
                    conclusion:
                        '1. Registrer underretning\n2. Orientér underretter, senest 6 hverdage efter modtagelsen\n3. Oplyse forældre / forældremyndige om at der er en indbretning\n4. Vurdér, indenfor 24 timer, om sundhed / udvikling i fare\n5. Beslut indsats',
                    conclusion_group: 'underretning',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: true,
                    explanation: 'Der er modtaget en underretning.',
                    id: 2,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 3,
                    next_node_true: 3,
                    note: null,
                    rule: '1 = 1',
                },
                {
                    conclusion:
                        '1. Orientér om at der iværksættes afdækning, børnefaglig undersøgelse eller indsats',
                    conclusion_group: 'underretning',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: false,
                    explanation: 'Indberetter er fagprofessionel.',
                    id: 3,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 4,
                    next_node_true: 4,
                    note: null,
                    rule: 'fagprofessionel = yes',
                },
                {
                    conclusion:
                        '1. Foretag risikovurdering\n2. Inddrag netværk hvis risikovurdering tillader',
                    conclusion_group: 'underretning',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: false,
                    explanation:
                        'Der er mistanke om at det er en æresrelateret konflikt',
                    id: 4,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 5,
                    next_node_true: 5,
                    note: null,
                    rule: 'æresrelateret_mistanke = yes',
                },
                {
                    conclusion: '1. Inddrag netværk',
                    conclusion_group: 'underretning',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: true,
                    explanation:
                        'Der er ikke mistanke om at det er en æresrelateret konflikt',
                    id: 5,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 6,
                    next_node_true: 6,
                    note: null,
                    rule: 'æresrelateret_mistanke != yes',
                },
                {
                    conclusion:
                        '1. Søg samtykke til inddragelse af barnet\n2. Informer barnet og vejled om ret til bisidder inden møde\n3. Afhold samtale med barnet - forstå dets perspektiv',
                    conclusion_group: 'underretning',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: true,
                    explanation: 'Der er ikke mistanke mod forældrene.',
                    id: 6,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 61,
                    next_node_true: 61,
                    note: null,
                    rule: 'mistanke_forældre != yes',
                },
                {
                    conclusion:
                        '1. Søg ikke samtykke til inddragelse af barnet\n2. Informer barnet og vejled om ret til bisidder inden møde\n3. Afhold samtale med barnet - forstå dets perspektiv',
                    conclusion_group: 'underretning',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: false,
                    explanation: 'Mistanke er rettet mod forældrene',
                    id: 61,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 7,
                    next_node_true: 7,
                    note: null,
                    rule: 'mistanke_forældre = yes',
                },
                {
                    conclusion:
                        '1. Vær opmærksom på regler for børnesamtale \n2. Kontakt evt Børnehuset for hjælp',
                    conclusion_group: 'underretning',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: false,
                    explanation: 'Der er oplysning om overgreb',
                    id: 7,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 8,
                    next_node_true: 8,
                    note: null,
                    rule: 'overgreb_oplysning = yes',
                },
                {
                    conclusion: '1. Orienter forældremyndige',
                    conclusion_group: 'underretning',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: false,
                    explanation:
                        'Sagen skal lukkes og barnet er ikke over 10 år gammel',
                    id: 8,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 9,
                    next_node_true: 9,
                    note: null,
                    rule: 'lukning = yes and alder < 10',
                },
                {
                    conclusion:
                        '1. Orientér barnet\n2. Orientér forældremyndige',
                    conclusion_group: 'underretning',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: false,
                    explanation:
                        'Sagen skal lukkes og barnet er over 10 år gammel',
                    id: 9,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 10,
                    next_node_true: 10,
                    note: null,
                    rule: 'lukning = yes and alder >= 10',
                },
                {
                    conclusion: '1. Genvurdér med rådgiverkollega',
                    conclusion_group: 'underretning',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: false,
                    explanation:
                        'Sagen skal genvurderes og barnet modtager allerede støtte',
                    id: 10,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 11,
                    next_node_true: 11,
                    note: null,
                    rule: 'genvurdering = yes and allerede_støttet = yes',
                },
                {
                    conclusion: '1. Vurdér om der skal foretages screening',
                    conclusion_group: 'underretning',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: false,
                    explanation:
                        'Sagen skal genvurderes og barnet modtager ikke allerede støtte',
                    id: 11,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 12,
                    next_node_true: 12,
                    note: null,
                    rule: 'genvurdering = yes and allerede_støttet != yes',
                },
                {
                    conclusion:
                        '1. Inddrag barnet\n2. Sikr at sagen er tilstrækkeligt oplyst\n3. Vurdèr om der er behov for at afdække den problematik som underretningen går på\n4. Udarbejd faglig vurdering af om der er behov for yderligere afdækning\n5. Dokumentèr i sagens akter\n - beskrivelse af de tilgængelige oplysninger\n - Begrundelse for den faglige vurdering\n - Beskrivelse af de trufne beslutninger\n6. Børnefaglig undersøgelse?\n7. Politianmeldelse?\n',
                    conclusion_group: 'screening',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: true,
                    explanation: 'Det er besluttet at sagen skal screenes',
                    id: 12,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 13,
                    next_node_true: 13,
                    note: null,
                    rule: '1 = 1',
                },
                {
                    conclusion:
                        'Vurdér om der er behov for børnefaglig undersøgelse, politianmeldelse, mm',
                    conclusion_group: 'screening',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: false,
                    explanation: 'Der er tale om kommende forældre',
                    id: 13,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 14,
                    next_node_true: 14,
                    note: null,
                    rule: 'kommende_forældre = yes',
                },
                {
                    conclusion:
                        'Kontakt Børnehuset telefonisk\nog få en rådgivende samtale for at afklare sagens videre forløb',
                    conclusion_group: 'screening',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: true,
                    explanation: 'Der er mistanke om overgreb',
                    id: 14,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 15,
                    next_node_true: 15,
                    note: null,
                    rule: 'overgreb_mistanke = yes',
                },
                {
                    conclusion:
                        '1. Kontakt RED - Center mod æresrelaterede konflikter\n',
                    conclusion_group: 'screening',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: false,
                    explanation:
                        'Der er tvivl om der skal foretages risikovurdering',
                    id: 15,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 16,
                    next_node_true: 16,
                    note: null,
                    rule: 'risikovurdering_tvivl = yes',
                },
                {
                    conclusion:
                        '1. Riskovurdering\n2. Inddrag  barnets netværk hvis risikovurdering tillader det',
                    conclusion_group: 'screening',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: false,
                    explanation:
                        'Det er besluttet at der skal foretages risikovurdering',
                    id: 16,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 62,
                    next_node_true: 62,
                    note: null,
                    rule: 'risikovurdering_besluttet = yes',
                },
                {
                    conclusion:
                        '1. Riskovurdering\n2. Inddrag  barnets netværk hvis risikovurdering tillader det',
                    conclusion_group: 'screening',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: false,
                    explanation:
                        'Der er tegn på en æresrelateret konflikt eller negativ social kontrol',
                    id: 62,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 17,
                    next_node_true: 17,
                    note: null,
                    rule: 'æresrelateret_socialkontrol = yes and risikovurdering_besluttet != yes',
                },
                {
                    conclusion:
                        'Foretag afdækning eller børnefaglig undesøgelse',
                    conclusion_group: 'screening',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: false,
                    explanation:
                        'Der er brug for at indhente yderligere oplysninger',
                    id: 17,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 18,
                    next_node_true: 18,
                    note: null,
                    rule: 'oplysninger_yderligere = yes',
                },
                {
                    conclusion:
                        '1. Orientér forældremyndige\n2. Søg forældremyndiges samtykke (du kan vurdere, indsamle information og afdække selvom samtykke afvises)\n3. Orientér barnet\n4. Vurdér hvilke forhold der er behov for at afdække\n5. Afklar hvilke andre fagligheder kan hjælpe dig med at skabe det mest sikre billede af situationen\n6. Vurder om igangsat sideløbende støtte skal fortsætte, ændres eller ophøre ',
                    conclusion_group: 'afdækning',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: true,
                    explanation:
                        'Det vurderes at der et eller flere forhold som har betydning for barnets eller den unges støttebehov og at der derfor skal foretages afdækning',
                    id: 18,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 19,
                    next_node_true: 19,
                    note: null,
                    rule: '1 = 1',
                },
                {
                    conclusion:
                        '1. Man kan vejlede om, at de kan klage over sagsbehandlingen til kommunens Borgerrådgiver eller Folketingets Ombudsmand - men ikke til Ankestyrelsen',
                    conclusion_group: 'afdækning',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: true,
                    explanation:
                        'Barnet eller forældremyndige er uenige i beslutning om at foretage afdækning',
                    id: 19,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 20,
                    next_node_true: 20,
                    note: null,
                    rule: 'barn_uening_afdækning = yes or forældremyndige_uenige_afdækning = yes',
                },
                {
                    conclusion:
                        '1. Indhent barnets synspunkter på anden vis end inddragelse',
                    conclusion_group: 'afdækning',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: true,
                    explanation:
                        'Barnets alder eller andre forhold i afgørende grad taler imod inddragelse af barnet',
                    id: 20,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 21,
                    next_node_true: 21,
                    note: null,
                    rule: 'barn_ikke_inddragelse = yes',
                },
                {
                    conclusion:
                        '1. Informer om retten til bisidder\n2. Inddrag barnet',
                    conclusion_group: 'afdækning',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: false,
                    explanation:
                        'Hverken barnets alder eller andre forhold i afgørende grad taler imod inddragelse af barnet',
                    id: 21,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 22,
                    next_node_true: 22,
                    note: null,
                    rule: 'barn_ikke_inddragelse != yes',
                },
                {
                    conclusion:
                        'Bemærk: afdækningen kan foretages som én samlet afdækning for hele familien',
                    conclusion_group: 'afdækning',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: false,
                    explanation:
                        'Der er andre børn i familien som trænger til hjælp eller støtte og som har behov for afdækning',
                    id: 22,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 23,
                    next_node_true: 23,
                    note: null,
                    rule: 'andre_børn_afdækning = yes',
                },
                {
                    conclusion: '1. Opstart sideløbende støtte',
                    conclusion_group: 'afdækning',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: false,
                    explanation:
                        'Den faglige vurdering af oplysninger fra underretningen er, at det er til barnets fordel at opstarte sideløbende støtte',
                    id: 23,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 24,
                    next_node_true: 24,
                    note: null,
                    rule: 'sideløbende = yes',
                },
                {
                    conclusion: '1. Opstart forebyggende indsats',
                    conclusion_group: 'afdækning',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: false,
                    explanation:
                        'Den faglige vurdering af oplysninger fra underretningen er, at det er til barnets fordel at opstarte forebyggende indsats',
                    id: 24,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 25,
                    next_node_true: 25,
                    note: null,
                    rule: 'forebyggende = yes',
                },
                {
                    conclusion: '1. Opstart kortere forløb\n',
                    conclusion_group: 'afdækning',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: false,
                    explanation:
                        'Det er nødvendigt at iværksætte kortere forløb, fx observation af barnet og forældrene',
                    id: 25,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 26,
                    next_node_true: 26,
                    note: null,
                    rule: 'observation = yes',
                },
                {
                    conclusion:
                        'Bemærk: \n1) Undersøgelsen skal afsluttes senest 4 måneder efter du blev bekendt med at barnet kunne have behov for særlig støtte og \n2) Undersøgelsen skal gennemføres i samarbejde med forældremyndige\n',
                    conclusion_group: 'børnefaglig',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: true,
                    explanation:
                        'Der skal gennemføres en børnefaglig undersøgelse',
                    id: 26,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 27,
                    next_node_true: 27,
                    note: null,
                    rule: '1 = 1',
                },
                {
                    conclusion:
                        '1. Udarbejd foreløbig vurdering\n2. Afslut sagen hurtigst muligt',
                    conclusion_group: 'børnefaglig',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: false,
                    explanation: 'Det er ikke muligt at overholde tidsfrist',
                    id: 27,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 28,
                    next_node_true: 28,
                    note: null,
                    rule: 'tidsfrist_børnefaglig = yes',
                },
                {
                    conclusion:
                        'Bemærk:Undersøgelsen skal gennemføres i samarbejde med barnet',
                    conclusion_group: 'børnefaglig',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: false,
                    explanation: 'Barnet er fyldt 15 år',
                    id: 28,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 29,
                    next_node_true: 29,
                    note: null,
                    rule: 'alder >= 15',
                },
                {
                    conclusion:
                        '1. Det vurderes, at det er en alvorlig sag, hvor der også kan være overvejelser om anbringelse\n2. Søg forældremyndiges samtykke (du inddrage barnet selvom samtykke afvises)\n3. Informer forældremyndige om retten til bisidder\n4. Partshør forældremyndighedsindehaver i forhold til de oplysninger, der ligger til grund for afgørelsen\n5. Træf afgørelse om at iværksætte børnefaglig undersøgelse\n\n',
                    conclusion_group: 'børnefaglig',
                    cornerstonecaseid: 0,
                    displayrule: null,
                    evaluationresult: false,
                    explanation:
                        'Barnet har været udsat for overgreb, eller der er mistanke om alvorlig mistrivsel / konflit eller der er et større støttebehov',
                    id: 29,
                    letterrulefalse: null,
                    letterruletrue: null,
                    next_node_false: 0,
                    next_node_true: 30,
                    note: null,
                    rule: 'overgreb_udsat = yes or mistrivsel_mistanke = yes or støttebehov_større = yes',
                },
            ],
            results: [
                {
                    conclusion:
                        '1. Registrer underretning\n2. Orientér underretter, senest 6 hverdage efter modtagelsen\n3. Oplyse forældre / forældremyndige om at der er en indbretning\n4. Vurdér, indenfor 24 timer, om sundhed / udvikling i fare\n5. Beslut indsats',
                    conclusion_group: 'underretning',
                    explanation: 'Der er modtaget en underretning.',
                    node: 2,
                    note: null,
                },
                {
                    conclusion: '1. Inddrag netværk',
                    conclusion_group: 'underretning',
                    explanation:
                        'Der er ikke mistanke om at det er en æresrelateret konflikt',
                    node: 5,
                    note: null,
                },
                {
                    conclusion:
                        '1. Søg samtykke til inddragelse af barnet\n2. Informer barnet og vejled om ret til bisidder inden møde\n3. Afhold samtale med barnet - forstå dets perspektiv',
                    conclusion_group: 'underretning',
                    explanation: 'Der er ikke mistanke mod forældrene.',
                    node: 6,
                    note: null,
                },
                {
                    conclusion:
                        '1. Inddrag barnet\n2. Sikr at sagen er tilstrækkeligt oplyst\n3. Vurdèr om der er behov for at afdække den problematik som underretningen går på\n4. Udarbejd faglig vurdering af om der er behov for yderligere afdækning\n5. Dokumentèr i sagens akter\n - beskrivelse af de tilgængelige oplysninger\n - Begrundelse for den faglige vurdering\n - Beskrivelse af de trufne beslutninger\n6. Børnefaglig undersøgelse?\n7. Politianmeldelse?\n',
                    conclusion_group: 'screening',
                    explanation: 'Det er besluttet at sagen skal screenes',
                    node: 12,
                    note: null,
                },
                {
                    conclusion:
                        'Kontakt Børnehuset telefonisk\nog få en rådgivende samtale for at afklare sagens videre forløb',
                    conclusion_group: 'screening',
                    explanation: 'Der er mistanke om overgreb',
                    node: 14,
                    note: null,
                },
                {
                    conclusion:
                        '1. Orientér forældremyndige\n2. Søg forældremyndiges samtykke (du kan vurdere, indsamle information og afdække selvom samtykke afvises)\n3. Orientér barnet\n4. Vurdér hvilke forhold der er behov for at afdække\n5. Afklar hvilke andre fagligheder kan hjælpe dig med at skabe det mest sikre billede af situationen\n6. Vurder om igangsat sideløbende støtte skal fortsætte, ændres eller ophøre ',
                    conclusion_group: 'afdækning',
                    explanation:
                        'Det vurderes at der et eller flere forhold som har betydning for barnets eller den unges støttebehov og at der derfor skal foretages afdækning',
                    node: 18,
                    note: null,
                },
                {
                    conclusion:
                        '1. Man kan vejlede om, at de kan klage over sagsbehandlingen til kommunens Borgerrådgiver eller Folketingets Ombudsmand - men ikke til Ankestyrelsen',
                    conclusion_group: 'afdækning',
                    explanation:
                        'Barnet eller forældremyndige er uenige i beslutning om at foretage afdækning',
                    node: 19,
                    note: null,
                },
                {
                    conclusion:
                        '1. Indhent barnets synspunkter på anden vis end inddragelse',
                    conclusion_group: 'afdækning',
                    explanation:
                        'Barnets alder eller andre forhold i afgørende grad taler imod inddragelse af barnet',
                    node: 20,
                    note: null,
                },
                {
                    conclusion:
                        'Bemærk: \n1) Undersøgelsen skal afsluttes senest 4 måneder efter du blev bekendt med at barnet kunne have behov for særlig støtte og \n2) Undersøgelsen skal gennemføres i samarbejde med forældremyndige\n',
                    conclusion_group: 'børnefaglig',
                    explanation:
                        'Der skal gennemføres en børnefaglig undersøgelse',
                    node: 26,
                    note: null,
                },
            ],
            returnCode: '1000011',
        },
    ]);

    const [evaluationResult, setEvaluationResult] = useState({
        caseLastTrue: [{}],
        conclusionLastTrue: '',
        noteLastTrue: '',
        conclusionGroupLastTrue: '',
        explanation: [],
        explanationTrues: [],
        nodeLast: 0,
        nodeLastTrue: 0,

        nodesEvaluated: [
            {
                conclusion: '',
                cornerstonecaseid: null,
                evaluationResult: true,
                id: 0,
                nextnodefalse: 0,
                nextnodetrue: 0,
                rule: '',
            },
        ],

        referenceCase: [],
        req_data: {
            age: '',
            weight: '',
        },
        results_mc: [],
    });

    // const [evaluationResult, setEvaluationResult] = useDebugState(
    //     {
    //         caseLastTrue: [{}],
    //         conclusionLastTrue: '',
    //         noteLastTrue: '',
    //         conclusionGroupLastTrue: '',
    //         explanation: [],
    //         explanationTrues: [],
    //         nodeLast: 0,
    //         nodeLastTrue: 0,

    //         nodesEvaluated: [
    //             {
    //                 conclusion: '',
    //                 cornerstonecaseid: null,
    //                 evaluationResult: true,
    //                 id: 0,
    //                 nextnodefalse: 0,
    //                 nextnodetrue: 0,
    //                 rule: '',
    //             },
    //         ],

    //         referenceCase: [],
    //         req_data: {
    //             age: '',
    //             weight: '',
    //         },
    //         results_mc: [],
    //     },
    //     'evaluationResult',
    // );

    const [evaluationResultMC, setEvaluationResultMC] = useState({
        nodes: [{}],
    });

    useEffect(() => {
        if (evaluationResult['results_mc'] != undefined) {
            setEvaluationResultMC({ nodes: evaluationResult['results_mc'] });
        } else {
            setEvaluationResultMC({ nodes: [{}] });
        }
    }, [evaluationResult]);

    // const [currentCaseId, setCurrentCaseId] = useState(0);
    const [currentCaseId, setCurrentCaseId] = useState();
    const [actualClass, setActualClass] = useState('');
    const [conclusionLastTrue, setConclusionLastTrue] = useState('');
    const [explanationLastTrue, setExplanationLastTrue] = useState('');
    const [explanationTrues, setExplanationTrues] = useState([]);
    const [explanation, setExplanation] = useState([]);
    const [explanationFiltered, setExplanationFiltered] = useState([]);
    const [nodeLast, setNodeLast] = useState(0);
    const [nodeLastTrue, setNodeLastTrue] = useState(0);
    const [nodesEvaluated, setNodesEvaluated] = useState([]);
    const [noteLastTrue, setNoteLastTrue] = useState('');
    const [conclusionGroupLastTrue, setConclusionGroupLastTrue] = useState('');
    const [referenceCase, setReferenceCase] = useState([]);
    const [returnCode, setReturnCode] = useState(0);

    return (
        <evaluationContext.Provider
            value={{
                actualClass,
                conclusionLastTrue,
                currentCaseId,
                evaluationResult: evaluationResult,
                explanation,
                explanationFiltered,
                explanationLastTrue,
                explanationTrues,
                nodeLast,
                nodeLastTrue,
                nodesEvaluated,
                noteLastTrue,
                referenceCase,
                returnCode,
                setActualClass,
                setConclusionLastTrue,
                setCurrentCaseId,
                setEvaluationResult: setEvaluationResult,
                setExplanation,
                setExplanationFiltered,
                setExplanationLastTrue,
                setExplanationTrues,
                setNodeLast,
                setNodeLastTrue,
                setNodesEvaluated,
                setNoteLastTrue,
                setReferenceCase,
                setReturnCode,
                conclusionGroupLastTrue,
                setConclusionGroupLastTrue,
                evaluationResult_tmp,
                setEvaluationResult_tmp,
                evaluationResultMC,
                setEvaluationResultMC,
            }}
        >
            {props.children}
        </evaluationContext.Provider>
    );
};

export default EvaluationContextProvider;
