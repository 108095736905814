import React, { useState, useContext, useRef } from "react";

import { displayContext } from "../../../common/DisplayContext.js";

import styles from '../../styles.module.css';

import RuleTable from "./RuleTable.js";
import RuleDetails from "./RuleDetails.js";
import RuleFoundingCaseDetails from "./RuleFoundingCaseDetails.js";

function RuleMain() {
    const {
        t,
        t2,
    } = useContext(displayContext);

    return (
        <div className={styles.flexRow}>
            <RuleTable />
            <div className={styles.selectedRuleDetails}>
                <RuleDetails />
                <RuleFoundingCaseDetails />
            </div>
        </div>
    );
}

export default RuleMain;
