import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

// import './styles.css';

// import required modules
import { Pagination } from 'swiper/modules';

export default function App() {

    const horizontalSwiperRef = React.useRef(null);
    const verticalSwiperRef = React.useRef(null);


    const handleHorizontalSlideChange = (swiper) => {
        // Get the index of the current horizontal slide
        console.log('Horizontal Slide Index:', swiper.activeIndex);
        // You can also use swiper.activeIndex to synchronize vertical swiper
        if (verticalSwiperRef.current) {
            verticalSwiperRef.current.slideTo(swiper.activeIndex);
        }
    };

    const handleVerticalSlideChange2 = (swiper) => {
        // Get the index of the current vertical slide
        console.log('Vertical Slide Index2:', swiper.activeIndex);
        // You can also use swiper.activeIndex to synchronize horizontal swiper
        if (horizontalSwiperRef.current) {
            horizontalSwiperRef.current.slideTo(swiper.activeIndex);
        }
    };

    const handleVerticalSlideChange3 = (swiper) => {
        // Get the index of the current vertical slide
        console.log('Vertical Slide Index3:', swiper.activeIndex);
        // You can also use swiper.activeIndex to synchronize horizontal swiper
        if (horizontalSwiperRef.current) {
            horizontalSwiperRef.current.slideTo(swiper.activeIndex);
        }
    };

    return (
        <>
            <Swiper
                className="mySwiper swiper-h"
                spaceBetween={50}
                pagination={{
                    clickable: true,
                }}
                // loop='true'
                modules={[Pagination]}
                onSlideChange={handleHorizontalSlideChange}
            >
                <SwiperSlide>Horizontal Slide 1</SwiperSlide>
                <SwiperSlide>
                    <Swiper
                        className="mySwiper2 swiper-v"
                        direction={'vertical'}
                        spaceBetween={50}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        onSlideChange={handleVerticalSlideChange2}
                    >
                        <SwiperSlide>Horizontal Slide 2</SwiperSlide>
                        <SwiperSlide>Vertical Slide 2.1</SwiperSlide>
                        <SwiperSlide>Vertical Slide 2.2</SwiperSlide>
                        <SwiperSlide>Vertical Slide 2.3</SwiperSlide>
                        <SwiperSlide>Vertical Slide 2.4</SwiperSlide>
                        <SwiperSlide>Vertical Slide 2.5</SwiperSlide>
                    </Swiper>
                </SwiperSlide>
                <SwiperSlide>
                    <Swiper
                        className="mySwiper3 swiper-v"
                        direction={'vertical'}
                        spaceBetween={50}
                        pagination={{
                            clickable: true,
                        }}
                        modules={[Pagination]}
                        onSlideChange={handleVerticalSlideChange3}
                    >
                        <SwiperSlide>Horizontal Slide 3</SwiperSlide>
                        <SwiperSlide>Vertical Slide 3.1</SwiperSlide>
                        <SwiperSlide>Vertical Slide 3.2</SwiperSlide>
                        <SwiperSlide>Vertical Slide 3.3</SwiperSlide>
                        <SwiperSlide>Vertical Slide 3.4</SwiperSlide>
                        <SwiperSlide>Vertical Slide 3.5</SwiperSlide>
                    </Swiper>
                </SwiperSlide>
            </Swiper >
        </>
    );
}
