import React, { useContext } from 'react';
import i18n from "i18next";

import { displayContext } from '../../common/DisplayContext';
import { VlfrdContext as VlfrdContext } from '../contexts/Context';

import styles from '../styles.module.css';

import KnowledgeBaseDropdown from './KnowledgeBaseDropdown';

import {
    Button,
} from "react-bootstrap";

function Topbar() {

    const {
        t2,
    } = useContext(displayContext);

    const {
        knowledgeBase,
    } = useContext(VlfrdContext);

    return (

        <div className={styles.desktopTopbar}>
            <div className={styles.desktopTopbarAppTitle}>
                <span className={styles.desktopProductName}>{t2('AppTitle', knowledgeBase)}</span>
                <span className={styles.desktopCatchline}>{t2('AppByline', knowledgeBase)}</span>
            </div>
        </div>
    )
};

export default Topbar;
