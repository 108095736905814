import React, { useContext } from 'react';

import { displayContext } from '../../common/DisplayContext';

import styles from '../styles.module.css';

function Bottombar() {

    const {
        t2,
    } = useContext(displayContext);

    return (
        <div className={styles.desktopBottombar}>
            <div className={styles.desktopBottombar}></div>
        </div >
    )
};

export default Bottombar;
