import React, { useContext, useEffect } from 'react';
import './App.css';

import { Routes, Route, useLocation } from 'react-router-dom';

import ProtectedRoute from './admin/components/ProtectedRoute';
import Home from './admin/pages/Home';
import EvaluateCasePage from './admin/components/EvaluateCase/EvaluateCasePage.js';

import Testpdf from './test/Testpdf.js';

// Common
import CommonContextProvider from './common/CommonContext.js';

// Alka
import Alka from './Alka/components/Alka.js';

// EU AU Act
import EUAIAct from './EUAIAct/components/EUAIAct.js';

// Copenhagen Municipality
import KbhK from './KbhK/components/front/KbhK.js';
import KbhK1 from './KbhK1/components/front/KbhK.js';

// Trademark
import TM from './TM/pages/TM';

import TM1 from './TM/pages/TM1';
import TM1UsePage from './TM/pages/TM1UsePage';
import TM1TimePage from './TM/pages/TM1TimePage';
import TM1IntensityPage from './TM/pages/TM1IntensityPage';
import TM1GeographyPage from './TM/pages/TM1GeographyPage';
import TM1ResultPage from './TM/pages/TM1ResultPage';

// Vlfrd
import Vlfrd from './vlfrd/components/Vlfrd.js';
import Vlfrdmc from './vlfrdmc/components/Vlfrd.js';

// Karnov
import Karnov from './Karnov/components/Karnov.js';
import KarnovForebyggende from './Karnov/components/KarnovForebyggende.js';

// Legal - new
import Legal from './legal/pages/Legal';
import LegalTypes from './legal/pages/LegalTypes';
import LegalTypesDesktop from './legal/pages/LegalTypesDesktop';
import LegalTypesMobile from './legal/pages/LegalTypesMobile';

// import TrademarkDesktop from "./legal/pages/TrademarkDesktop";
import TrademarkDesktopTextInputPage from './legal/pages/TrademarkDesktopTextInputPage.js';
import TrademarkDesktopPage from './legal/pages/TrademarkDesktopPage.js';
import TrademarkDesktopPageChat from './legal/pages/TrademarkDesktopPageChat.js';
// import TrademarkDesktopChat from './legal/pages/TrademarkDesktopChat.js'
import TrademarkDesktopPageChatQuery from './legal/pages/TrademarkDesktopPageChatQuery.js';

import TrademarkMobile from './legal/pages/TrademarkMobile';
import TrademarkSwiper1 from './legal/pages/TrademarkSwiper1';
import TrademarkSwiper2 from './legal/pages/TrademarkSwiper2';
import TrademarkSwiper3 from './legal/pages/TrademarkSwiper3';
import TrademarkSwiper4 from './legal/pages/TrademarkSwiper4';
import TrademarkSwiper5 from './legal/pages/TrademarkSwiper5';
import TrademarkResult from './legal/pages/TrademarkResult';
import LegalTest from './legal/pages/LegalTest';

// TopDanmark
import Top from './top/pages/Top.js';
import Kaskoskade from './top/pages/Kaskoskade.js';
import InsuranceTypes from './top/pages/InsuranceTypes';
import Comprehensive from './top/pages/Comprehensive';
import ComprehensiveSwiper from './top/pages/ComprehensiveSwiper';
import NestedSwiper from './top/pages/NestedSwiper';
import TopTest from './top/pages/TopTest';

// Admin
import CasePage from './admin/components/Case/CasePage.js';
import RejectedCasePage from './admin/components/RejectedCase/RejectedCasePage.js';
import RejectedCaseMC from './admin/components/RejectedCase/RejectedCaseMC.js';
import KnowledgeBasePage from './admin/components/KnowledgeBase/KnowledgeBasePage.js';
import DomainPage from './admin/components/Domain/DomainPage.js';
import AttributePage from './admin/components/Attribute/AttributePage.js';
import UploadCasesPage from './admin/components/UploadCases/UploadCasesPage.js';
import BatchProcessPage from './admin/components/BatchProcess/BatchProcessPage.js';

import KnowledgeBrowserPage from './admin/components/KnowledgeBrowser/KnowledgeBrowserPage.js';
import KnowledgeBrowserMain from './admin/components/KnowledgeBrowser/KnowledgeBrowserMain.js';

import LearnPage from './admin/components/Learn/LearnPage.js';
import TestPage from './admin/pages/TestPage';

import NoMatch from './admin/pages/NoMatch';

import Spinner from './admin/components/Alerts/Spinner';

import ModalCaseRejected from './admin/components/Alerts/ModalCaseRejected';

import ModalConclusionHelp from './admin/components/Alerts/ModalConclusionHelp';
import ModalExplanationHelp from './admin/components/Alerts/ModalExplanationHelp';
import ModalRuleHelp from './admin/components/Alerts/ModalRuleHelp';

import FetchProvider from './TM/contexts/FetchContext';

import DisplayContextProvider from './common/DisplayContext.js';
import RejectedCaseContextProvider from './admin/contexts/CaseContext';
import DomainsContextProvider from './admin/contexts/DomainsContext';
import EvaluationContextProvider from './admin/contexts/EvaluationContext';
import UserCaseContextProvider from './admin/contexts/UserCaseContext';
import CurrentCategoryContextProvider from './admin/contexts/CurrentCategoryContext';
import CategoryContextProvider from './admin/contexts/CategoriesContext';
import AttributesContextProvider from './admin/contexts/AttributesContext';
import { NavigationContextProvider } from './admin/contexts/NavigationContext.js';
import VersionContextProvider from './Karnov/contexts/Context.js';

// import TMContextProvider from "./TM/contexts/TMContext";
import TMEvaluationContextProvider from './TM/contexts/TMEvaluationContext';

import TopContextProvider from './top/contexts/TopContext';
import SwiperContextProvider from './legal/contexts/SwiperContext';

import KnowledgeContextProvider from './admin/contexts/KnowledgeContext.js';

import useAuth from './admin/hooks/useAuth';
import { Navigation } from 'swiper/modules';

const App = () => {
    const { token, setToken } = useAuth();

    return (
        <DisplayContextProvider>
            <CommonContextProvider>
                <CurrentCategoryContextProvider>
                    <NavigationContextProvider>
                        <RejectedCaseContextProvider>
                            <UserCaseContextProvider>
                                <DomainsContextProvider>
                                    <EvaluationContextProvider>
                                        <TMEvaluationContextProvider>
                                            <FetchProvider>
                                                <AttributesContextProvider>
                                                    <CategoryContextProvider>
                                                        <VersionContextProvider>
                                                            {/* <TopContextProvider> */}
                                                            <SwiperContextProvider>
                                                                <KnowledgeContextProvider>
                                                                    {/* <ModalCaseRejected /> */}
                                                                    <ModalConclusionHelp />
                                                                    <ModalExplanationHelp />
                                                                    <ModalRuleHelp />
                                                                    <Spinner />
                                                                    <Routes>
                                                                        <Route
                                                                            index
                                                                            element={
                                                                                <Home />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="home"
                                                                            element={
                                                                                <Home />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="evaluateCase"
                                                                            element={
                                                                                <ProtectedRoute>
                                                                                    <EvaluateCasePage />
                                                                                </ProtectedRoute>
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="admin"
                                                                            element={
                                                                                <ProtectedRoute>
                                                                                    <EvaluateCasePage />
                                                                                </ProtectedRoute>
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="casePage"
                                                                            element={
                                                                                <ProtectedRoute>
                                                                                    <CasePage />
                                                                                </ProtectedRoute>
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="/rejectedCasePage"
                                                                            element={
                                                                                <ProtectedRoute>
                                                                                    <RejectedCasePage />
                                                                                </ProtectedRoute>
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="/rejectedCaseMC"
                                                                            element={
                                                                                <ProtectedRoute>
                                                                                    <RejectedCaseMC />
                                                                                </ProtectedRoute>
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="/knowledgeBasePage"
                                                                            element={
                                                                                <ProtectedRoute>
                                                                                    <KnowledgeBasePage />
                                                                                </ProtectedRoute>
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="/domainPage"
                                                                            element={
                                                                                <ProtectedRoute>
                                                                                    <DomainPage />
                                                                                </ProtectedRoute>
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="/attributePage"
                                                                            element={
                                                                                <ProtectedRoute>
                                                                                    <AttributePage />
                                                                                </ProtectedRoute>
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="/UploadCasesPage"
                                                                            element={
                                                                                <ProtectedRoute>
                                                                                    <UploadCasesPage />
                                                                                </ProtectedRoute>
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="/batchProcessPage"
                                                                            element={
                                                                                <ProtectedRoute>
                                                                                    <BatchProcessPage />
                                                                                </ProtectedRoute>
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="/learn"
                                                                            element={
                                                                                <ProtectedRoute>
                                                                                    <LearnPage />
                                                                                </ProtectedRoute>
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="/knowledgeBrowser"
                                                                            element={
                                                                                <ProtectedRoute>
                                                                                    <KnowledgeBrowserMain />
                                                                                </ProtectedRoute>
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="/knowledgeBrowserPage"
                                                                            element={
                                                                                <ProtectedRoute>
                                                                                    <KnowledgeBrowserPage />
                                                                                </ProtectedRoute>
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="/test"
                                                                            element={
                                                                                <ProtectedRoute>
                                                                                    <TestPage />
                                                                                </ProtectedRoute>
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="TM"
                                                                            element={
                                                                                <TM />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="TM1"
                                                                            element={
                                                                                <TM1 />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="TM1UsePage"
                                                                            element={
                                                                                <TM1UsePage />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="TM1TimePage"
                                                                            element={
                                                                                <TM1TimePage />
                                                                            }
                                                                        />
                                                                        <Route
                                                                            path="TM1IntensityPage"
                                                                            element={
                                                                                <TM1IntensityPage />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="TM1GeographyPage"
                                                                            element={
                                                                                <TM1GeographyPage />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="TM1ResultPage"
                                                                            element={
                                                                                <TM1ResultPage />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="Top"
                                                                            element={
                                                                                <Top />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="Kaskoskade"
                                                                            element={
                                                                                <Kaskoskade />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="InsuranceTypes"
                                                                            element={
                                                                                <InsuranceTypes />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="Comprehensive"
                                                                            element={
                                                                                <Comprehensive />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="ComprehensiveSwiper"
                                                                            element={
                                                                                <ComprehensiveSwiper />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="NestedSwiper"
                                                                            element={
                                                                                <NestedSwiper />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="TopTest"
                                                                            element={
                                                                                <TopTest />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="Legal"
                                                                            element={
                                                                                <Legal />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="LegalTypes"
                                                                            element={
                                                                                <LegalTypes />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="LegalTypesDesktop"
                                                                            element={
                                                                                <LegalTypesDesktop />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="LegalTypesMobile"
                                                                            element={
                                                                                <LegalTypesMobile />
                                                                            }
                                                                        />

                                                                        {/* <Route
                                                                    path="Trademark"
                                                                    element={<Trademark />}
                                                                />
 */}
                                                                        <Route
                                                                            path="TrademarkMobile"
                                                                            element={
                                                                                <TrademarkMobile />
                                                                            }
                                                                        />

                                                                        {/* <Route
                                                                        path="TrademarkDesktop"
                                                                        element={<TrademarkDesktop />}
                                                                    /> */}

                                                                        <Route
                                                                            path="TrademarkDesktopTextInputPage"
                                                                            element={
                                                                                <TrademarkDesktopTextInputPage />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="TrademarkDesktopPage"
                                                                            element={
                                                                                <TrademarkDesktopPage />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="TrademarkDesktopPageChat"
                                                                            element={
                                                                                <TrademarkDesktopPageChat />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="TrademarkDesktopPageChatQuery"
                                                                            element={
                                                                                <TrademarkDesktopPageChatQuery />
                                                                            }
                                                                        />

                                                                        {/* <Route
                                                                path="TrademarkDesktopChat"
                                                                element={
                                                                    <TrademarkDesktopChat />
                                                                }
                                                            /> */}

                                                                        <Route
                                                                            path="TrademarkSwiper1"
                                                                            element={
                                                                                <TrademarkSwiper1 />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="TrademarkSwiper2"
                                                                            element={
                                                                                <TrademarkSwiper2 />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="TrademarkSwiper3"
                                                                            element={
                                                                                <TrademarkSwiper3 />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="TrademarkSwiper4"
                                                                            element={
                                                                                <TrademarkSwiper4 />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="TrademarkSwiper5"
                                                                            element={
                                                                                <TrademarkSwiper5 />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="TrademarkResult"
                                                                            element={
                                                                                <TrademarkResult />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="LegalTest"
                                                                            element={
                                                                                <LegalTest />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="KbhK"
                                                                            element={
                                                                                <KbhK />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="KbhK1"
                                                                            element={
                                                                                <KbhK1 />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="EUAIAct"
                                                                            element={
                                                                                <EUAIAct />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="Alka"
                                                                            element={
                                                                                <Alka />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="Vlfrd"
                                                                            element={
                                                                                <Vlfrd />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="Vlfrdmc"
                                                                            element={
                                                                                <Vlfrdmc />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="Karnov"
                                                                            element={
                                                                                <Karnov />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="Testpdf"
                                                                            element={
                                                                                <Testpdf />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="*"
                                                                            element={
                                                                                <NoMatch />
                                                                            }
                                                                        />

                                                                        <Route
                                                                            path="karnovForebyggende"
                                                                            element={
                                                                                <KarnovForebyggende />
                                                                            }
                                                                        />
                                                                    </Routes>
                                                                </KnowledgeContextProvider>
                                                            </SwiperContextProvider>
                                                            {/* </TopContextProvider> */}
                                                        </VersionContextProvider>
                                                    </CategoryContextProvider>
                                                </AttributesContextProvider>{' '}
                                            </FetchProvider>
                                        </TMEvaluationContextProvider>
                                    </EvaluationContextProvider>
                                </DomainsContextProvider>
                            </UserCaseContextProvider>
                        </RejectedCaseContextProvider>
                    </NavigationContextProvider>
                </CurrentCategoryContextProvider>
            </CommonContextProvider>
        </DisplayContextProvider>
    );
};

export default App;
