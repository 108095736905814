import React, { useContext, createContext } from 'react';

import { displayContext } from '../../../common/DisplayContext.js';

import styles from '../../styles.module.css';

import Navbar from '../Settings/Navbar.js';
// import DisplayMessage from "./DisplayMessage.js";

import ButtonLoadCase from './ButtonLoadCase.js';
// import Caseid from "./CaseId.js";

import CaseTableTest from './CaseTableTest.js';
import CaseTableTestNested from './CaseTableTestNested.js';

import ResultConclusion from './ResultConclusion.js';
import ResultConclusionNew from './ResultConclusionNew.js';

import ResultExplanation from './ResultExplanation.js';
import ResultExplanationNew from './ResultExplanationNew.js';

import ResultExplanationFilter from './ResultRuleTraceFilter.js';
import ResultRuleNew from './ResultRuleNew.js';
import ResultRuleTrace from './ResultRuleTrace.js';

import Note from './Note.js';
import ConclusionGroup from './ConclusionGroup.js';

import EvaluateCaseButtonTopbar from './EvaluateCaseButtonTopbar.js';
import TopBar from '../TopBar/TopBar.js';

///////////////////////////////////////////////////////////////////////////////////
//
///////////////////////////////////////////////////////////////////////////////////

// import CurrentCategoryContextProvider from "../../contexts/CurrentCategoryContext.js";

function EvaluateCaseMainSC() {
    const {
        previousPath,
        t,
        useMode,
        textAreaNewRuleDisabled,
        // displayMessage,
    } = useContext(displayContext);

    return (
        <div className={styles.standardPage}>
            <div className={styles.standardPageTop}>
                <Navbar />
                {/* <EvaluateCaseButtonTopbar /> */}
                <TopBar />
            </div>

            <div className={styles.standardPageMiddle}>
                <CaseTableTestNested />

                <div className={styles.conclusionRulesNotes}>
                    {/* <ResultConclusion /> */}
                    {useMode === 'read' && (
                        <div className={styles.result}>
                            <ResultConclusion />
                            <ResultExplanation />
                        </div>
                    )}

                    {(useMode === 'write' || useMode === 'learn') && (
                        <div className={styles.result}>
                            <ResultConclusionNew />
                            <ResultExplanationNew />
                        </div>
                    )}

                    <div className={styles.explanation}>
                        <ResultExplanationFilter />

                        {(useMode === 'write' || useMode === 'learn') && (
                            <ResultRuleNew />
                        )}
                        <ResultRuleTrace />
                    </div>

                    <div className={styles.note}>
                        <Note />
                        <ConclusionGroup />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default EvaluateCaseMainSC;
