import React, { useContext } from 'react';

import styles from '../../styles.module.css';

import { useLocation } from 'react-router-dom';

import { displayContext } from '../../../common/DisplayContext.js';
import { navigationContext } from '../../contexts/NavigationContext.js';
import { knowledgeContext } from '../../contexts/KnowledgeContext.js';

import ButtonAccept from '../EvaluateCase/ButtonAccept.js';
import ButtonReject from '../EvaluateCase/ButtonReject.js';
import ButtonClearCase from '../EvaluateCase/ButtonClearCase.js';
import ButtonEvaluateCase from '../EvaluateCase/ButtonEvaluateCase.js';
import ButtonRemove from '../RejectedCase/ButtonRemove.js';
import ButtonAdd from '../RejectedCase/ButtonAdd.js';
import ButtonRemoveAndAdd from '../RejectedCase/ButtonRemoveAndAdd.js';

import Caseid from '../EvaluateCase/CaseId.js';

function TopBar() {
    const {
        useMode,
        uIModeRejectCaseMC,
        setUIModeRejectCaseMC,
        currentPage,
        setCurrentPage,
    } = useContext(displayContext);

    const { knowledgebaseType } = useContext(knowledgeContext);

    const { page } = useContext(navigationContext);

    let location = useLocation();

    function buttons() {
        // For SC knowledge bases
        if (knowledgebaseType == 'sc') {
            if (page == 'evaluateCase') {
                return (
                    <>
                        <div className={styles.evaluateCaseButtons1}>
                            <div className={styles.evaluateCaseButtons2}>
                                <ButtonEvaluateCase />
                            </div>

                            <div className={styles.evaluateCaseButtons2}>
                                <ButtonAccept />
                                {useMode === 'read' && <ButtonReject />}
                                <ButtonClearCase />
                            </div>
                        </div>
                    </>
                );
            }
        }

        // For MC knowledge bases
        else if (knowledgebaseType == 'mc') {
            if (page == 'evaluateCase') {
                return (
                    <div className={styles.evaluateCaseButtons1}>
                        <div className={styles.evaluateCaseButtons2}>
                            <ButtonEvaluateCase />
                        </div>
                        <div className={styles.evaluateCaseButtons2}>
                            <ButtonAccept />
                            {useMode === 'read' && <ButtonReject />}
                            <ButtonClearCase />
                        </div>
                    </div>
                );
            } else if (page == 'rejectedCaseMC') {
                return (
                    <div className={styles.evaluateCaseButtons3}>
                        <div className={styles.evaluateCaseButtons2}>
                            <ButtonAccept />
                            <ButtonRemove />
                            <ButtonAdd />
                            <ButtonRemoveAndAdd />
                        </div>
                    </div>
                );
            }
        }
    }

    return (
        <div className={styles.evaluateCaseButtonBar}>
            <div style={{ display: 'flex' }}>
                <Caseid />
            </div>

            {buttons()}
        </div>
    );
}

export default TopBar;
