import { useState, useContext } from "react";

import { AlkaContext } from "../contexts/Context";
import { displayContext } from "../../common/DisplayContext";

import styles from '../styles.module.css';

import useHandleOptionClick from "../hooks/useHandleOptionClick";

function Dropdown({ attributeName, dropdowns }) {

    const [{ setOption }] = useHandleOptionClick();

    const {
        t2,
    } = useContext(displayContext);

    const {
        attributesOpenApp,
    } = useContext(AlkaContext);

    const [selectedValue, setSelectedValue] = useState(null);

    const handleSelectChange = (event) => {
        const selectedOption = event.target.value;
        setSelectedValue(selectedOption);
        // handleOptionClick(event.target.name, selectedOption, 'add')

        const newValue = event.target.value;
        setOption(event.target.id, newValue, 'add')
    };


    return (
        <div className={styles.desktopOptionGroup}>
            <form>
                {attributesOpenApp
                    .filter(attribute => attribute.name == attributeName)
                    .map(attribute => (
                        <div className={styles.dropdownGroup}>
                            {attribute.label}

                            <div className={styles.dropdownContainer}>
                                <select
                                    className={styles.dropdown}
                                    value={selectedValue}
                                    id={attribute.name}
                                    // name={attribute.name}
                                    onChange={handleSelectChange}
                                >
                                    {dropdowns.map(dropdownItem => (
                                        <option value={dropdownItem}>{dropdownItem}</option>
                                    ))

                                    }
                                </select>
                            </div>
                        </div>
                    ))
                }
            </form>
        </div>
    )
}

export default Dropdown;



