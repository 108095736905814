import React, { useContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { displayContext } from "../../../common/DisplayContext";

import styles from '../../styles.module.css';

import AttributePageMain from "./AttributePageMain";
import HeaderLoggedIn from "../Header/HeaderLoggedIn";
import NavigationSideBar from "../Navigation/NavigationSideBar";

function AttributePage() {
    const { setDisplayMessage } = useContext(displayContext);

    let location = useLocation();

    useEffect(() => {
        setDisplayMessage("");
    }, [location.key]);

    return (
        <div className={styles.page}>
            <div className={styles.pageMain}>
                <NavigationSideBar />
                <AttributePageMain />
            </div>
        </div>
    );
}
export default AttributePage;
