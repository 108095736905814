// See https://www.robinwieruch.de/react-table-component/ for the tutorial for creating this type of tables
// https://www.robinwieruch.de/categories/react-table-library/
// samples: https://react-table-library.com/?path=/story/getting-started--demo
// https://github.com/table-library/react-table-library
import React, { useEffect } from "react";
import { useHistory, useNavigate } from "react-router-dom";

import styles from '../../styles.module.css';

import {
    Table,
    Header,
    HeaderRow,
    HeaderCell,
    Body,
    Row,
    Cell,
} from "@table-library/react-table-library/table";

import {
    useSort,
    // HeaderCellSort,
} from "@table-library/react-table-library/sort";

import {
    // Sort,
    // SortOptions,
    // SortIconPositions,
    SortToggleType,
} from "@table-library/react-table-library/sort";

// import { defaultTheme } from "../../hooks/useReactTableTheme";
import useReactTableTheme from "../../hooks/useReactTableTheme";
import defaultTableTheme from "./DefaultTableTheme";

import { useTheme } from "@table-library/react-table-library/theme";

// import { CompactTable } from '@table-library/react-table-library/compact';
// import { useTheme } from "@table-library/react-table-library/theme";

import { displayContext } from "../../../common/DisplayContext";
import { knowledgeContext } from "../../contexts/KnowledgeContext";

import { useContext, useState } from "react";


function AttributeTable() {
    let navigate = useNavigate();

    const {
        attributeList,
        currentCaseId,
        setCurrentCaseId,
        currentAttributeId,
        setCurrentAttributeName,
    } = useContext(knowledgeContext);

    const {
        t2,
    } = useContext(displayContext);

    const [{ defaultTheme }] = useReactTableTheme();


    defaultTableTheme.Table = `
        height: 100%; 
        width: 100%; 
        align-content: baseline; 
        --data-table-library_grid-template-columns: 50% 15% 15% 20%;
    `

    const theme = useTheme(defaultTableTheme)



    const [data, setData] = useState({ nodes: attributeList });

    useEffect(() => {
        setData({ nodes: attributeList });
    }, [attributeList]);

    const handleFilter = (value, property) => {
        setData({
            nodes:
                value !== ""
                    ? attributeList.filter(function (item) {
                        return typeof (item[property]) == 'string' ?
                            item[property].includes(value) :
                            item[property] == value
                    })
                    : attributeList,
        });
    };

    const sort = useSort(
        data,
        {
            onChange: onSortChange,
        },
        {
            sortToggleType: SortToggleType.AlternateWithReset,
            sortFns: {
                CaseId: (array) =>
                    array.sort((a, b) => a.caseId.localeCompare(b.caseId)),
            },
        }
    );

    function onSortChange(action, state) {
        console.log(action, state);
    }

    function handleAttribute(e) {
        setCurrentAttributeName(e.name)
    }

    function headerCell(property, filter) {
        return (
            <HeaderCell resize>
                <input
                    className={styles.caseTableFilter}
                    type="text"
                    placeholder={t2(filter, "sysadmin")}
                    onChange={(event) =>
                        handleFilter(
                            event.target.value,
                            property
                        )
                    }
                />
            </HeaderCell>
        )
    }


    return (
        <div className={styles.ruleTable}> 

        <div className={[styles.standardTableWrapper, styles.kbAttributesAttributeDetailsWrapper].join(' ')}>
            <div className={styles.boxHeading}>{t2("attributes", "sysadmin")}</div>

            <Table 
                className={styles.standardTable2}
                data={data} 
                theme={theme} 
                sort={sort}
                layout={{custom: true}}
            >
                {(tableList) => (
                    <>
                        <Header>
                            <HeaderRow>
                                {headerCell('name', 'attributeNameFilter')}
                                {headerCell('type', 'typeFilter')}
                                {headerCell('userid', 'userIdFilter')}
                                {headerCell('created_time', 'createdTimeFilter')}
                            </HeaderRow>
                        </Header>

                        <Body>
                            {tableList.map((item) => (
                                <Row
                                    key={item.caseId}
                                    item={item}
                                    onClick={(event) => handleAttribute(event)}
                                >
                                    <Cell><div className={styles.caseTableComment}>{item.name}</div></Cell>
                                    <Cell><div className={styles.caseTableComment}>{item.type}</div></Cell>
                                    <Cell><div className={styles.caseTableComment}>{item.userid}</div></Cell>
                                    <Cell><div className={styles.caseTableComment}>{item.createdtime}</div></Cell>
                                </Row>
                            ))}
                        </Body>
                    </>
                )}
            </Table>
            </div>
            </div>
    );
}

export default AttributeTable;
