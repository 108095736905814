import React, { useContext } from "react";

import { displayContext } from "../../../common/DisplayContext.js";

import styles from '../../styles.module.css';

import Navbar from "../Settings/Navbar.js";
import ButtonGetNextLearningCase from "./ButtonGetNextLearningCase";

import CaseTableTest from "../EvaluateCase/CaseTableTest";

import ResultConclusion1 from "../EvaluateCase/ResultConclusion";
import ResultConclusionNew from "../EvaluateCase/ResultConclusionNew";

import ResultExplanation from "../EvaluateCase/ResultExplanation";
import ResultExplanationNew from "../EvaluateCase/ResultExplanationNew";

import ResultExplanationFilter from "../EvaluateCase/ResultRuleTraceFilter";
import ResultRuleNew from "../EvaluateCase/ResultRuleNew";
// import ResultRuleTrace from "../EvaluateCase/ResultRuleTrace";
import ResultRuleTrace from "./ResultRuleTrace.js";

import Note from "../EvaluateCase/Note";

import ButtonAutoLetter from "../EvaluateCase/ButtonAutoLetter.js";
import ButtonAccept from "../EvaluateCase/ButtonAccept.js";
import ButtonCancel from "../EvaluateCase/ButtonCancel";
import ButtonReject from "../EvaluateCase/ButtonReject.js";
import ButtonSaveNewRule from "../EvaluateCase/ButtonSaveNewRule.js";

///////////////////////////////////////////////////////////////////////////////////
//
///////////////////////////////////////////////////////////////////////////////////

function EvaluateCaseMain() {
    const { useMode, t } = useContext(displayContext);

    return (
        <div className={styles.evaluateCaseMain}>
            <div className={styles.evaluateCaseButtonBar}>
                {/* <div className={styles.learnButtonBarLeft}> */}
                <ButtonGetNextLearningCase />

                <div>
                    <ButtonSaveNewRule />
                    <ButtonAutoLetter />
                    <ButtonAccept />
                    <ButtonCancel />
                </div>
                <Navbar />
            </div>

            <div className={styles.caseTableBox}>
                <CaseTableTest />
            </div>

            <div className={styles.conclusionRulesNotes}>
                {/* <ResultConclusion /> */}
                {useMode === "read" && (
                    <div className={styles.result}>
                        <ResultConclusion1 />
                        <ResultExplanation />
                    </div>
                )}

                {(useMode === "write" || useMode === "learn") && (
                    <div className={styles.result}>
                        <ResultConclusionNew />
                        <ResultExplanationNew />
                    </div>
                )}

                <div className={styles.explanation}>
                    <ResultExplanationFilter />

                    {(useMode === "write" || useMode === "learn") && (
                        <ResultRuleNew />
                    )}

                    <ResultRuleTrace className={styles.explanationRuleTraceLow} />
                </div>

                <div className={styles.note}>
                    <Note />

                </div>

            </div>
        </div>
    );
}

export default EvaluateCaseMain;
