import React, {
    useState,
    useContext,
    useEffect,
    useCallback,
    useRef,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { commonContext } from '../../common/CommonContext';
import { displayContext } from '../../common/DisplayContext';
import { attributesContext } from '../../admin/contexts/AttributesContext';
import { errorContext } from '../../admin/contexts/ErrorContext';
import { versionContext } from '../contexts/Context';

import 'bootstrap/dist/css/bootstrap.min.css';

import styles from '../styles.module.css';

import useEvaluate from '../hooks/useEvaluate';

import { BsTriangleFill } from 'react-icons/bs';
import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import RadioGroup from './RadioGroup';
import CheckboxGroup from './CheckboxGroup';
import MixedGroup from './MixedGroup';
import Dropdown from './Dropdown';
import Dropdown1 from './Dropdown1';

// import MenuItems from './MenuItems';
// import menuItemsList from './menuItemsList';

import Linkify from 'react-linkify';

function Main() {
    let navigate = useNavigate();
    const { t2 } = useContext(displayContext);

    const { currentAttributes } = useContext(attributesContext);

    const { setErrors } = useContext(errorContext);

    const [{ handleEvaluate }] = useEvaluate();

    const {
        evaluationResult,
        // setEvaluationResult,
        attributesOpenApp,
        setAttributesOpenApp,
        setUnderretningEvaluationResult,
        setScreeningEvaluationResult,
        groupsEvaluationResult,
        setGroupsEvaluationResult,
        caseNo,
    } = useContext(versionContext);

    useEffect(() => {
        if (caseNo == 'abc-1234') {
            setOption2('alder', 10, 'dummy_mode');
            setOption2('direkte_alvorlig_skadelig', 'yes', 'dummy_mode');
        } else if (caseNo == 'def-5678') {
            setOption2('alder', 16, 'dummy_mode');
            setOption2('direkte_alvorlig_skadelig', 'no', 'dummy_mode');
        }
    }, [caseNo]);

    const { processing } = useContext(commonContext);

    const [infoButton, setInfoButton] = useState(false);
    const [showConclusion, setShowConclusion] = useState(false);
    const [showConclusionGroup, setShowConclusionGroup] = useState('');

    const [pageCleared, setPageCleared] = useState(true);

    const [selectedOption, setSelectedOption] = useState('Select...');

    const [dropdown, setDropdown] = useState(false);

    let allResult = {};

    let risksResult = {};

    let dummy_result = [];

    let result = [];

    function handleOptionClick(attributeName, value, mode) {
        setOption2(attributeName, value, mode);
    }

    function setOption2(attributeName, value, mode) {
        setAttributesOpenApp((prevData) => {
            const updatedData = prevData.map((item) => {
                if (item.name == attributeName) {
                    return {
                        ...item,
                        selected: value != item.value,
                        value: value == item.value ? '' : value,
                    };
                }
                return item;
            });
            return updatedData;
        });
    }

    const setDropdownOption = (e) => {
        console.log('setDropdownOption: ', e);
        setSelectedOption(e);
    };

    function clear() {
        // setSelectedOptions([]);
        setPageCleared(true);

        setAttributesOpenApp(
            attributesOpenApp.map((option) => {
                return {
                    ...option,
                    value: (option.value = ''),
                    test: (option.value = 0),
                    selected: false,
                };
            }),
        );
        setShowConclusion(false);
    }

    function findFormErrors() {
        // const errors = attributesOpenApp.some(attribute => attribute.selected == false);

        const errors = false;
        return errors;
    }

    async function evaluate() {
        setPageCleared(false);
        setShowConclusion(false);

        setUnderretningEvaluationResult({});
        setScreeningEvaluationResult({});
        setGroupsEvaluationResult({});

        // setAggConclusion('')
        // setRisksEvaluationResult({})

        // Validate input form
        const errors = findFormErrors();
        // Conditional logic:
        if (errors) {
            // We got errors!
            setErrors(errors);
        } else {
            // No errors! Put any logic here for the form submission!

            // if (
            //     risksResult.conclusionLastTrue == 'passed'
            // )
            //     setAggConclusion('dækket:')
            // else
            //     setAggConclusion('ikke dækket:')

            // Here we evaluate each evaluationGroup in isolation, but all in one call to the backend
            // The evaluationGroup variable is set in context.js...

            // result = await handleEvaluate('karnov', 'all')
            // result = await handleEvaluate('karnov', 'byEvaluationGroup')
            dummy_result = await handleEvaluate('karnov', 'all');

            setShowConclusion(true);
        }
    }

    // // ////////////////////////////////
    // // Overflow indicator arrow - START
    // // ////////////////////////////////

    // useEffect(() => {
    //     checkOverflow('column1-div');
    //     // checkOverflow('column2-div');
    //     // checkOverflow('conclusion-div');

    //     // // Add scroll event listeners to both divs
    //     document
    //         .getElementById('column1-div')
    //         .addEventListener('scroll', function () {
    //             handleScroll('column1-div');
    //         });

    //     // document.getElementById('column2-div').addEventListener('scroll', function () {
    //     //     handleScroll('column2-div');
    //     // });

    //     // document.getElementById('conclusion-div').addEventListener('scroll', function () {
    //     //     handleScroll('conclusion-div');
    //     // });
    // }, []);

    // // Function to check for overflow and show/hide indicator
    // function checkOverflow(divId) {
    //     var div = document.getElementById(divId);

    //     if (div.scrollHeight > div.clientHeight) {
    //         showIndicator(divId);
    //     } else {
    //         hideIndicator(divId);
    //     }
    // }

    // // Function to handle scroll events and show/hide indicator based on position
    // function handleScroll(divId) {
    //     var div = document.getElementById(divId);

    //     console.log(div.scrollTop + div.offsetHeight, ', ', div.scrollHeight);

    //     // if (div.scrollHeight - div.scrollTop === div.clientHeight) {
    //     if (div.scrollTop + div.offsetHeight >= div.scrollHeight) {
    //         hideIndicator(divId);
    //     } else {
    //         showIndicator(divId);
    //     }
    // }

    // // Function to show indicator
    // function showIndicator(divId) {
    //     var indicator = document.getElementById(divId + 'Indicator');
    //     indicator.style.display = 'block';
    // }

    // // Function to hide indicator
    // function hideIndicator(divId) {
    //     var indicator = document.getElementById(divId + 'Indicator');
    //     indicator.style.display = 'none';
    // }

    // // ////////////////////////////////
    // // Overflow indicator arrow - END
    // // ////////////////////////////////

    const onMouseEnter = () => {
        window.innerWidth > 960 && setDropdown(true);
    };

    const onMouseLeave = () => {
        window.innerWidth > 960 && setDropdown(false);
    };

    let ref = useRef();

    const dropdownBtn = document.getElementById('btn');
    const dropdownMenu = document.getElementById('dropdown');
    const toggleArrow = document.getElementById('arrow');

    function handleDropdownOption(e) {
        console.log('handleDropdownOption: ', e);
        setDropdownButtonText(e.id);
    }

    const [dropdownButtonText, setDropdownButtonText] = useState('Select...');
    const [dropdownButton, setDropdownButton] = useState(false);

    function dropDownbuttonVisible() {
        setDropdownButton(true);
        console.log('dropDownbuttonVisible');
    }

    function dropDownbuttonHidden() {
        setDropdownButton(false);
        console.log('dropDownbuttonHidden');
    }
    const [selectedValue, setSelectedValue] = useState(null);

    const handleDropdownChange = (event) => {
        setSelectedValue(event.target.value);
        console.log(event.target.value);
    };

    // const handleMouseEnter = (event) => {
    //     event.target.size = event.target.length;
    // };

    // const handleMouseLeave = (event) => {
    //     event.target.size = 1;
    // };

    const [selectedValue1, setSelectedValue1] = useState(null);

    const handleSelectChange = (event) => {
        const selectedOption = event.target.value;
        setSelectedValue1(selectedOption);
        handleOptionClick(event.target.name, selectedOption, 'add');
    };

    function group(group) {
        return (
            <div className={styles.desktopOptionGroup}>
                {attributesOpenApp
                    .filter((attribute) => attribute.groupname == group)
                    .map((attribute) => {
                        if (attribute.uitype == 'check')
                            return (
                                // <div className={pageCleared || attribute.selected ? [styles.selectionBoxNoErrorOption].join(' ') : [styles.selectionBoxNoErrorOption, styles.selectionBoxErrorOption].join(' ')} >
                                <div
                                    className={styles.selectionBoxNoErrorOption}
                                >
                                    <OverlayTrigger
                                        delay={400}
                                        delayHide={0}
                                        placement={'auto'}
                                        overlay={
                                            <Tooltip>
                                                {attribute.displayname}
                                            </Tooltip>
                                        }
                                    >
                                        <div
                                            className={
                                                styles.selectionBoxAttributeText
                                            }
                                        >
                                            {attribute.label}
                                        </div>
                                    </OverlayTrigger>

                                    <div
                                        className={
                                            styles.selectionBoxRadioButtons
                                        }
                                    >
                                        <div
                                            className={
                                                styles.desktopSelectOption
                                            }
                                            onClick={() =>
                                                handleOptionClick(
                                                    attribute.name,
                                                    '1',
                                                    'add',
                                                )
                                            }
                                        >
                                            <input
                                                type="checkbox"
                                                name="yes"
                                                className={
                                                    styles.checkboxToRadio
                                                }
                                                checked={attribute.value == '1'}
                                            />
                                            {t2('yes', 'karnov')}
                                        </div>

                                        <div
                                            className={
                                                styles.desktopSelectOption
                                            }
                                            onClick={() =>
                                                handleOptionClick(
                                                    attribute.name,
                                                    '2',
                                                    'add',
                                                )
                                            }
                                        >
                                            <input
                                                type="checkbox"
                                                className="checkbox-to-radio"
                                                checked={attribute.value == '2'}
                                            />
                                            {t2('no', 'karnov')}
                                        </div>
                                    </div>
                                </div>
                            );
                        else if (attribute.uitype == 'dropdown')
                            return (
                                // <div className={pageCleared || attribute.selected ? [styles.selectionBoxNoErrorOption].join(' ') : [styles.selectionBoxNoErrorOption, styles.selectionBoxErrorOption].join(' ')} >
                                <div
                                    className={styles.selectionBoxNoErrorOption}
                                >
                                    <div className={styles.dropdownLine}>
                                        <OverlayTrigger
                                            delay={400}
                                            delayHide={0}
                                            placement={'auto'}
                                            overlay={
                                                <Tooltip>
                                                    {attribute.displayname}
                                                </Tooltip>
                                            }
                                        >
                                            <div
                                                className={
                                                    styles.selectionBoxAttributeText
                                                }
                                            >
                                                {attribute.displayname}
                                            </div>
                                        </OverlayTrigger>

                                        <div
                                            className={styles.dropdownContainer}
                                        >
                                            <select
                                                className={styles.dropdown}
                                                value={selectedValue}
                                                name={attribute.name}
                                                onChange={handleSelectChange}
                                            >
                                                <option value="select">
                                                    {t2(
                                                        'selectAnOption',
                                                        'karnov',
                                                    )}
                                                </option>
                                                <option value="ægtefælle">
                                                    Ægtefælle / samlever
                                                </option>
                                                <option value="husstand">
                                                    Medlem af husstanden
                                                </option>
                                                <option value="bruger">
                                                    Den faste bruger
                                                </option>
                                                <option value="anden">
                                                    Ingen af ovenstående
                                                </option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            );
                    })}
            </div>
        );
    }

    function isEmptyObject(obj) {
        return obj && typeof obj === 'object' && Object.keys(obj).length === 0;
    }

    function resultForEvaluationGroup(evaluationGroupResults, group) {
        if (!isEmptyObject(evaluationGroupResults)) {
            // Convert object of objects to array of objects
            const evaluationGroupResultsArray = Object.values(
                evaluationGroupResults,
            );
            const filteredEvaluationGroupResults =
                evaluationGroupResultsArray.filter(
                    (obj) => obj.group === group,
                )[0];

            return (
                <>
                    {filteredEvaluationGroupResults.explanationLastTrue != '' &&
                    filteredEvaluationGroupResults.explanationLastTrue !=
                        null ? (
                        <div>
                            <div
                                className={
                                    filteredEvaluationGroupResults.conclusionLastTrue ==
                                    'passed'
                                        ? 'desktop-explanation-box desktop-explanation-approved'
                                        : 'desktop-explanation-box desktop-explanation-rejected'
                                }
                            >
                                <Linkify
                                    componentDecorator={(
                                        decoratedHref,
                                        decoratedText,
                                        key,
                                    ) => (
                                        <a
                                            target="blank"
                                            href={decoratedHref}
                                            key={key}
                                        >
                                            {t2('labelLink', 'karnov')}
                                        </a>
                                    )}
                                >
                                    {t2(
                                        filteredEvaluationGroupResults.explanationLastTrue,
                                        'karnov',
                                    )}
                                </Linkify>
                            </div>
                            <div>
                                {/* {t2(filteredEvaluationGroupResults.conclusionLastTrue, "karnov")} */}

                                <pre style={{ whiteSpace: 'pre-wrap' }}>
                                    {
                                        filteredEvaluationGroupResults.conclusionLastTrue
                                    }
                                </pre>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                </>
            );
        }
    }

    function showEvaluationResult(evaluation_result) {
        // if (!isEmptyObject(evaluation_result)) {
        if (evaluation_result[0] != undefined) {
            // Convert object of objects to array of objects
            const evaluation_result_array = Object.values(evaluation_result);

            return (
                <>
                    {
                        <pre style={{ whiteSpace: 'pre-wrap' }}>
                            {evaluation_result[0].results.map((result) => (
                                <div>
                                    <div className={styles.conclusion_heading}>
                                        {result.explanation}
                                    </div>
                                    <div className={styles.conclusion_details}>
                                        {result.conclusion}
                                    </div>
                                </div>
                            ))}
                        </pre>
                    }
                </>
            );
        }
    }

    function showEvaluationResultGroup(evaluation_result) {
        if (evaluation_result[0] != undefined) {
            // Convert object of objects to array of objects
            const evaluation_result_array = Object.values(evaluation_result);

            return (
                <>
                    {
                        <pre style={{ whiteSpace: 'pre-wrap' }}>
                            {evaluation_result[0].results
                                .filter(
                                    (result) =>
                                        result.conclusion_group ==
                                        showConclusionGroup,
                                )
                                .map((result) => (
                                    <div>
                                        <div
                                            className={
                                                styles.conclusion_heading
                                            }
                                        >
                                            {result.explanation}
                                        </div>
                                        <div
                                            className={
                                                styles.conclusion_details
                                            }
                                        >
                                            {result.conclusion}
                                        </div>
                                    </div>
                                ))}
                        </pre>
                    }
                </>
            );
        }
    }

    function resultForAll(evaluationResult) {
        if (!isEmptyObject(evaluationResult)) {
            // Convert object of objects to array of objects

            return (
                <>
                    {evaluationResult.explanationLastTrue != '' ? (
                        <div
                            className={
                                evaluationResult.conclusionLastTrue == 'passed'
                                    ? 'desktop-explanation-box desktop-explanation-approved'
                                    : 'desktop-explanation-box desktop-explanation-rejected'
                            }
                        >
                            <Linkify
                                componentDecorator={(
                                    decoratedHref,
                                    decoratedText,
                                    key,
                                ) => (
                                    <a
                                        target="blank"
                                        href={decoratedHref}
                                        key={key}
                                    >
                                        {t2('labelLink', 'karnov')}
                                    </a>
                                )}
                            >
                                {t2(
                                    evaluationResult.explanationLastTrue,
                                    'karnov',
                                )}
                            </Linkify>
                        </div>
                    ) : (
                        ''
                    )}
                </>
            );
        }
    }

    const førerDropdown = [
        'vælg...',
        'ægtefælle',
        'husstand',
        'bruger',
        'anden',
    ];

    const evaluate_conclusion_group = (conclusion_group) => {
        setShowConclusionGroup(conclusion_group);
        evaluate();
    };

    function HandleAction(e) {
        navigate('/karnovForebyggende');
    }

    return (
        <>
            <div>
                {attributesOpenApp && (
                    <div className={styles.desktopMain}>
                        <div className={styles.desktopColumn1}>
                            <div id="column1-divIndicator">
                                {/* <BsTriangleFill
                                    className={[
                                        styles.desktopOverflowIcon,
                                        styles.desktopOverflowIconColumn1,
                                    ].join(' ')}
                                /> */}
                            </div>

                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                }}
                            >
                                <div style={{ display: 'flex' }}>
                                    Sagsnr.:
                                    <Dropdown1 />
                                </div>
                            </div>

                            <div
                                id="column1-div"
                                className={styles.desktopNoErrorBorder}
                            >
                                {caseNo == 'abc-1234' ? (
                                    <div>
                                        <div
                                            style={{
                                                overflowY: 'scroll',
                                                height: '40vh',
                                                marginTop: '1vh',
                                                marginBottom: '2vh',
                                                borderTop:
                                                    '15px solid rgba(255, 255, 255, .9)',
                                                borderBottom:
                                                    '15px solid rgba(255, 255, 255, .9)',
                                            }}
                                        >
                                            <p className={styles.groupHeading}>
                                                Referat
                                            </p>
                                            <p>Deltagere:</p>
                                            <ul>
                                                <li>
                                                    Socialrådgiver: Signe Olsen
                                                </li>
                                                <li>Barn: Pige 10 år</li>
                                                <li>Bisidder: Naja</li>
                                            </ul>
                                            <p>
                                                Formål med samtalen: Formålet
                                                med samtalen var at tale om
                                                pigens trivsel i hjemmet, især i
                                                forhold til forældrenes
                                                konflikter, samt at støtte pigen
                                                i at sætte ord på sine følelser
                                                og oplevelser.
                                            </p>
                                            <p>Samtalens indhold:</p>

                                            <ol>
                                                <li>
                                                    Situationen i hjemmet: Signe
                                                    spurgte ind til pigens
                                                    oplevelser, når forældrene
                                                    skændes, især når faren har
                                                    drukket og bliver aggressiv.
                                                    Pigen fortalte, at det ofte
                                                    prøver at fjerne faren for
                                                    at stoppe skænderierne.
                                                    Pigen bekræftede, at
                                                    politiet nogle gange kommer
                                                    hjem til dem, og at dette
                                                    gør hende bange – særligt
                                                    fordi hun frygter, at faren
                                                    ikke vil komme hjem igen.{' '}
                                                </li>
                                                <li>
                                                    Pigens følelser: Pigen
                                                    udtrykte, at det føler skyld
                                                    og bekymring i forbindelse
                                                    med forældrenes skænderier.
                                                    Hun nævnte, at hun har svært
                                                    ved at falde i søvn, da hun
                                                    frygter, hvad der kan ske,
                                                    mens hun sover. Pigen
                                                    forklarede også, at hun
                                                    indimellem forsøger at
                                                    stoppe skænderierne ved at
                                                    gå ind i stuen eller ringe
                                                    på dørklokken.
                                                </li>
                                                <li>
                                                    {' '}
                                                    Påvirkning i skolen: Pigen
                                                    delte, at hun ofte tænker på
                                                    forældrenes konflikter, selv
                                                    når hun er i skole, hvilket
                                                    gør det svært at koncentrere
                                                    sig og følge med i
                                                    undervisningen.
                                                </li>
                                                <li>
                                                    {' '}
                                                    Pigens bekymringsniveau:
                                                    Signe brugte klodser til at
                                                    illustrere pigens
                                                    bekymringsniveau. Pigen
                                                    angav, at hun er meget
                                                    bekymret (otte ud af ti
                                                    klodser), men ønskede, at
                                                    bekymringen kun skulle være
                                                    på niveau to.
                                                </li>
                                                <li>
                                                    {' '}
                                                    Plan for videre forløb:
                                                    Signe aftalte med pigen,
                                                    hvad der skulle siges videre
                                                    til hendes mor, der ventede
                                                    udenfor. Pigen udtrykte, at
                                                    hun ikke ønskede, at moren
                                                    skulle vide, at hun tænker
                                                    meget på skænderierne i
                                                    skoletiden, men efter en
                                                    drøftelse blev de enige om,
                                                    at det var vigtigt at dele
                                                    denne information for at
                                                    kunne få hjælp. Signe
                                                    foreslog også, at pigen
                                                    kunne tale med sin
                                                    matematiklærer, som hun
                                                    føler sig trygt ved.{' '}
                                                </li>
                                                <li>
                                                    Afslutning: Samtalen blev
                                                    rundet af ved at aftale, at
                                                    Signe ville tale med moren
                                                    om pigens bekymring for
                                                    farens indtag af alkohol,
                                                    voldelige adfærd samt hendes
                                                    bekymring for, at faren
                                                    bliver taget af politiet.
                                                    Aftalen blev også, at Signe
                                                    fortæller moren, at pigen
                                                    bekymrer sig for forældrene
                                                    i skolen. De skal tale
                                                    sammen igen.
                                                </li>
                                            </ol>
                                        </div>

                                        <div>
                                            <p className={styles.groupHeading}>
                                                Konkluderende punkter
                                            </p>
                                            <ul>
                                                <li>
                                                    Gentagende problematik af
                                                    alvorlig karakter, som har
                                                    direkte skadende effekt
                                                </li>
                                            </ul>

                                            <p className={styles.groupHeading}>
                                                Fremhævede punkter
                                            </p>
                                            <div style={{ display: 'flex' }}>
                                                <ul style={{ width: '15vw' }}>
                                                    <li>Misbrug af alkohol</li>
                                                    <li>Skænderi</li>
                                                    <li>
                                                        Vold mellem forældre
                                                    </li>
                                                    <li>Aggressiv far</li>
                                                    <li>
                                                        Politiet tilstede -
                                                        husspektakel
                                                    </li>
                                                    <li>
                                                        Store bekymringer for
                                                        datter
                                                    </li>
                                                </ul>

                                                <ul style={{ width: '15vw' }}>
                                                    <li>Manglende søvn</li>
                                                    <li>
                                                        Manglende fokus i
                                                        skolen, kan ikke huske
                                                        noget og heller ikke
                                                        folge med
                                                    </li>
                                                    <li>
                                                        Datter handler, forsoger
                                                        at stoppe volden
                                                    </li>
                                                    <li>
                                                        Matematiklæreren er en
                                                        vigtig person
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        <div className={styles.desktopColumn2}>
                            <div id="column2-divIndicator">
                                {/* <BsTriangleFill
                                    className={[
                                        styles.desktopOverflowIcon,
                                        styles.desktopOverflowIconcolumn2,
                                    ].join(' ')}
                                /> */}

                                {caseNo == 'abc-1234' ? (
                                    <div>
                                        <div>
                                            <p className={styles.groupHeading}>
                                                Risikofaktorer
                                            </p>

                                            <ul>
                                                <li>Misbrug i familien</li>
                                                <li>Vold mellem forældre</li>
                                                <li>
                                                    Manglende koncentration i
                                                    skolen
                                                </li>
                                                <li>Ukoncentreret i skolen</li>
                                            </ul>
                                        </div>

                                        <div>
                                            <p className={styles.groupHeading}>
                                                Beskyttelsesfaktorer
                                            </p>
                                            <ul>
                                                <li>Pigen taler om volden</li>
                                                <li>
                                                    Pigen holder af begge sine
                                                    forældre
                                                </li>
                                                <li>Pigen kommer i skole</li>
                                                <li>
                                                    Matematiklæreren er en
                                                    vigtig person
                                                </li>
                                            </ul>
                                        </div>

                                        <div>
                                            <p className={styles.groupHeading}>
                                                Lovgivning
                                            </p>
                                            <ul>
                                                <li>
                                                    <a
                                                        // href="https://www.sm.dk/arbejdsomraader/boern-og-unge-i-udsatte-positioner/barnets-lov"
                                                        href="https://www.karnovgroup.dk/da-dk/kaila"
                                                        target="_blank"
                                                    >
                                                        Barnets lov
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        // href="https://danskelove.dk/serviceloven"
                                                        href="https://www.karnovgroup.dk/da-dk/kaila"
                                                        target="_blank"
                                                    >
                                                        Serviceloven
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        // href="https://www.retsinformation.dk/eli/lta/2022/1396"
                                                        href="https://www.karnovgroup.dk/da-dk/kaila"
                                                        target="_blank"
                                                    >
                                                        Folkeskoleloven
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>

                                        <div>
                                            <p className={styles.groupHeading}>
                                                Relevant lovgivning
                                            </p>
                                            <ul>
                                                <li>
                                                    <a
                                                        href="https://www.karnovgroup.dk/da-dk/kaila"
                                                        target="_blank"
                                                    >
                                                        Barnets lov §20
                                                        børnefaglig undersøgelse
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="https://www.karnovgroup.dk/da-dk/kaila"
                                                        target="_blank"
                                                    >
                                                        Serviceloven §101
                                                        misbrugsbehandling
                                                    </a>
                                                </li>
                                                <li>
                                                    <a
                                                        href="https://www.karnovgroup.dk/da-dk/kaila"
                                                        target="_blank"
                                                    >
                                                        Folkeskoleloven §20-23
                                                        skolens ansvar
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        </div>

                        <div className={styles.desktopColumn2}>
                            <div id="column2-divIndicator">
                                {/* <BsTriangleFill
                                    className={[
                                        styles.desktopOverflowIcon,
                                        styles.desktopOverflowIconcolumn2,
                                    ].join(' ')}
                                /> */}
                            </div>

                            {/* <div
                                id="column2-div"
                                className={styles.desktopNoErrorBorder}
                            ></div> */}

                            {caseNo == 'abc-1234' ? (
                                <div>
                                    <div>
                                        <p className={styles.groupHeading}>
                                            Anbefalede handlinger:
                                        </p>

                                        <ol>
                                            <li>
                                                Du skal vurdere, om der skal
                                                træffes afgørelse om en
                                                børnefaglig undersøgelse
                                            </li>
                                            <li>
                                                Du skal tage stilling til
                                                tidligt forebyggende indsatser
                                            </li>
                                            <li>
                                                Når der udarbejdes en
                                                børnefaglig undersøgelse
                                                anbefales det at lægge speciel
                                                vægt på følgende forhold:
                                            </li>
                                            <ul>
                                                <li>
                                                    De hjemlige forhold,
                                                    hjemmebesøg
                                                </li>
                                                <li>
                                                    Faderens alkoholmisbrug samt
                                                    voldsmønster (kontakt
                                                    politiet)
                                                </li>
                                                <li>
                                                    Moderens strategier ift. at
                                                    passe på pigen
                                                </li>
                                                <li>
                                                    Forældrenes
                                                    beskæftigelsesgrad
                                                </li>
                                                og økonomiske forhold
                                                <li>Netværk</li>
                                                <li>Skole</li>
                                                <li>Fritid</li>
                                            </ul>
                                            <li>
                                                Du skal sideløbende med
                                                undersøgelsen tage stilling til
                                                indsatser til pigen og familien.
                                            </li>{' '}
                                        </ol>
                                    </div>

                                    <div>
                                        <p className={styles.groupHeading}>
                                            Tværfaglige handlinger
                                        </p>
                                        <ul>
                                            <li>
                                                Støttende indsatser til
                                                barnet/pigen
                                            </li>
                                            <li>
                                                Skolen skal inddrages ift.
                                                støtte
                                            </li>
                                            <li>Misbrugskonsulent til faren</li>
                                        </ul>
                                    </div>
                                </div>
                            ) : null}
                        </div>

                        {/* Conclusion column */}
                        <div
                            className={[
                                styles.desktopColumn2,
                                styles.desktopColumnLast,
                            ].join(' ')}
                        >
                            {caseNo == 'abc-1234' ? (
                                <div>
                                    <div>
                                        <p className={styles.groupHeading}>
                                            Handlinger / drift:
                                        </p>
                                        <p className={styles.groupHeading}>
                                            Børnefaglig undersøgelse:
                                        </p>
                                        <ul>
                                            <li>
                                                Inddragelse af barnet eller den
                                                unge
                                            </li>
                                            <li>
                                                Samtaler og anden direkte
                                                kontakt
                                            </li>
                                            <li>Vejledning om bisidder</li>
                                            <li>Partshøring af forældrene</li>
                                            <li>Andre børn i familien</li>
                                            <li>
                                                Afgørelse om børnefaglig
                                                undersøgelse
                                            </li>
                                            <li>
                                                De forvaltningsretlige krav til
                                                afgørelsen
                                            </li>
                                            <li>Modtagere af afgørelsen</li>
                                            <li>
                                                Viden eller mistanke om overgreb
                                            </li>
                                            <li>Sideløbende indsats</li>
                                            <li>Samarbejde med familien</li>
                                            <li>Sagsoplysning</li>
                                            <li>Indsamling af oplysninger</li>
                                            <li>
                                                Lægelig / psykologisk
                                                undersøgelse
                                            </li>
                                            <li>
                                                Undersøgelse af barnet eller den
                                                unge
                                            </li>
                                            <li>Undersøgelse af forældrene</li>
                                            <li>
                                                Pædagogisk-psykologisk vurdering
                                            </li>
                                            <li>
                                                Inddragelse af barnet eller den
                                                unge og forældrene
                                            </li>
                                            <li>Afslutning på undersøgelsen</li>
                                            <li>Konklusion</li>
                                            <li>Faglig vurdering</li>
                                        </ul>
                                    </div>

                                    <div
                                        style={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                        }}
                                    >
                                        <Button
                                            variant="success"
                                            size="sm"
                                            style={{ marginBottom: '2px' }}
                                        >
                                            {t2('rådgivende', 'karnov')}
                                        </Button>
                                        <Button
                                            variant="success"
                                            size="sm"
                                            style={{ marginBottom: '2px' }}
                                            onClick={(event) =>
                                                HandleAction(event)
                                            }
                                        >
                                            {t2('støttende', 'karnov')}
                                        </Button>
                                        <Button
                                            variant="success"
                                            size="sm"
                                            style={{ marginBottom: '2px' }}
                                        >
                                            {t2('anbringelse', 'karnov')}
                                        </Button>
                                        <Button
                                            variant="success"
                                            size="sm"
                                            style={{ marginBottom: '2px' }}
                                        >
                                            {t2('lukning', 'karnov')}
                                        </Button>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                )}
            </div>
        </>
    );
}

export default Main;
