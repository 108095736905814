import React, { useContext, useEffect } from 'react';

import { commonContext } from '../../common/CommonContext';

import { displayContext } from '../../common/DisplayContext';
import { domainContext } from '../../admin/contexts/DomainContext';
import { userCaseContext } from '../../admin/contexts/UserCaseContext';
import { evaluationContext } from '../../admin/contexts/EvaluationContext';
import { versionContext } from '../contexts/Context';

import useAuth from '../../admin/hooks/useAuth';
import useFetch from '../../admin/hooks/useFetch';
// import { Config } from "../../config";

function useEvaluate() {
    const { t } = useContext(displayContext);

    const {
        currentDomain,
        setCurrentDomain,
        currentKnowledgeBase,
        setCurrentKnowledgeBase,
    } = useContext(domainContext);

    const {
        optionDescriptions1,
        selectedOptions,
        attributesOpenApp,
        setEvaluationResult,
    } = useContext(versionContext);

    // const {
    //     attributesOpenApp,
    // } = useContext(EUAIActContext2);

    const { processing, setProcessing } = useContext(commonContext);

    const { token } = useAuth();

    // This call to evaluate is used to respond to requests for evaluation
    // from the use, time, intensity and geography pages
    async function fetchEvaluate2(obj, caller) {
        // if (obj.currentKnowledgeBase != "noKnowledgeBase") {
        if (obj.knowledgeBase != 'noKnowledgeBase') {
            // var endpoint = process.env.REACT_APP_FLASK_API + "evaluate";
            // var endpoint = "http://20.54.72.161:5000/backend/" + "evaluate";

            // if (caller == "all")
            //     var endpoint = process.env.REACT_APP_FLASK_API + "evaluate";
            // else
            //     var endpoint = process.env.REACT_APP_FLASK_API + "evaluate";

            var endpoint = process.env.REACT_APP_FLASK_API + 'evaluate';

            // Note: we only send the Authorization header if token is not null. This is
            // because in the backend we have jwt_required(optional=True) which raises
            // an exception if there is an invalid or expired token, but not if there is
            // no Authorization header. So, we dont send the Authorization header if there
            // is not a valid token.
            return fetch(endpoint, {
                method: 'POST',

                headers:
                    token != null
                        ? {
                              Accept: 'application/json',
                              'Content-Type': 'application/json',
                              Authorization: 'Bearer ' + token,
                          }
                        : {
                              Accept: 'application/json',
                              'Content-Type': 'application/json',
                          },

                body: JSON.stringify(obj, caller),
            })
                .then((res) => res.json())
                .then((responseJson) => {
                    return responseJson;
                });
        }
    }

    // Create a user case based on the user's selected options for a certain group
    // by copying all attributes from optionDescriptions except
    // when an option was actually selected by the user, in which case we copy
    // the selected value for that option.

    function DELETE_createUserCase(group) {
        const groupOptions = optionDescriptions1;

        // return groupOptions.map((option) => {
        return optionDescriptions1.map((option) => {
            const selectedOption = selectedOptions.find(
                (selectedOption) =>
                    selectedOption.attributeName == option.attributeName,
            );

            if (selectedOption != undefined) {
                return {
                    id: option.attributeName,
                    category: 'null',
                    name: option.attributeName,
                    displayName: option.attributeName,
                    type: 'check',
                    test: selectedOption.value,
                };
            } else {
                return {
                    id: option.attributeName,
                    category: 'null',
                    name: option.attributeName,
                    displayName: option.attributeName,
                    type: 'check',
                    test: '0',
                };
            }
        });
    }

    function createUserCase(group) {
        if (group == 'all') {
            return attributesOpenApp.map((option) => {
                return {
                    id: option.name,
                    category: 'null',
                    name: option.name,
                    displayName: option.displayname,
                    type: 'check',
                    test: option.value,
                };
            });
        } else {
            return attributesOpenApp
                .filter((option) => option.groupname == group)
                .map((option) => {
                    const selectedOption = selectedOptions.find(
                        (selectedOption) =>
                            selectedOption.attributeName == option.name,
                    );

                    if (selectedOption != undefined) {
                        return {
                            id: option.name,
                            category: 'null',
                            name: option.name,
                            displayName: option.displayname,
                            type: 'check',
                            test: selectedOption.value,
                        };
                    } else {
                        return {
                            id: option.name,
                            category: 'null',
                            name: option.name,
                            displayName: option.displayname,
                            type: 'check',
                            test: '0',
                        };
                    }
                });
        }
    }

    function createUserCaseByEvaluationGroup() {
        return attributesOpenApp.map((option) => {
            return {
                ...option,
                test: option.value == '' ? 2 : option.value,
                evaluationGroup: option.evaluationGroup,
            };
        });
    }

    async function handleEvaluate(knowledgeBase, caller) {
        var obj = {};
        var ruleFound = false;

        // Callers:
        // all - if there is only one global call
        // underretning -

        setProcessing(true);

        // if (caller == 'byEvaluationGroup')
        //     obj.caseData = createUserCaseByEvaluationGroup()
        // Create a user case based on the user's selected options
        // else
        obj.caseData = createUserCase(caller);

        // obj.knowledgeBase = 'trademark';
        obj.knowledgeBase = knowledgeBase;

        obj.domain = currentDomain;
        obj.category = '';
        obj.requestType = 'evaluate';

        const fetch_evaluation_result = await fetchEvaluate2(obj, caller);

        // if (caller == "underretning") {
        //     setUnderretningEvaluationResult(evaluationResult);
        // } else if (caller == "screening") {
        //     setScreeningEvaluationResult(evaluationResult);
        // } else if (caller == "byEvaluationGroup") {
        //     setGroupsEvaluationResult(evaluationResult);
        // }
        setEvaluationResult(fetch_evaluation_result);

        setProcessing(false);

        return fetch_evaluation_result;
    }

    return [
        {
            handleEvaluate,
        },
    ];
}

export default useEvaluate;
