import React, { createContext, useState, useEffect, useContext } from 'react';

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import HttpApi from "i18next-http-backend";
import Fetch from 'i18next-http-backend';

import LanguageDetector from 'i18next-browser-languagedetector';

// import { TRANSLATIONS_CS } from "./cs/translations";

// import { TRANSLATIONS_DA } from "./da/translations";

// import { TRANSLATIONS_EN } from "./en/translations";
// import { TRANSLATIONS_MS } from "./ms/translations";
// import { options } from "linkifyjs";

import useAuth from '../admin/hooks/useAuth';

import { domainContext } from '../admin/contexts/DomainContext';

// import { Config } from "../config";

// .use(HttpApi)

// https://stackoverflow.com/questions/70589040/how-to-incorporate-api-data-in-i18next-instead-of-static-file

function GetTranslations(lng) {
    const { token } = useAuth();

    const { currentDomain, currentKnowledgeBase } = useContext(domainContext);

    const [translations, setTranslations] = useState('');

    var obj = {};
    obj.knowledgeBase = currentKnowledgeBase;
    obj.domain = currentDomain;
    if (token != null && currentKnowledgeBase != 'noKnowledgeBase') {
        // var endpoint = process.env.REACT_APP_FLASK_API + "getTranslations";
        var endpoint = process.env.REACT_APP_FLASK_API + 'getTranslations';
        // var endpoint = process.env.api_url + "getTranslations";

        fetch(endpoint, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(obj),
        })
            .then((res) => res.json())
            .then((responseJson) => {
                console.log(
                    'DisplayContext - responseJson[translation]: ',
                    responseJson[0],
                );
                setTranslations(responseJson['translation']);
            });
    }

    return 'abc';
}

function MyPath() {
    // return "http://localhost:5000/backend/getTranslations/{{lng}}/{{ns}}";
    const endpoint =
        // Config.REACT_APP_FLASK_API + "getTranslations/{{lng}}/{{ns}}";
        process.env.REACT_APP_FLASK_API + 'getTranslations/{{lng}}/{{ns}}';
    return endpoint;
}

i18n.use(Fetch)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        // ns: ["varemaerke", "sysadmin"],
        ns: [
            'trademark',
            'varemaerke',
            'kbhk',
            'kbhk1',
            'euaiact',
            'sysadmin',
            'alka',
            'vlfrd',
            'vlfrdmc',
            'karnov',
        ],
        defaultNS: 'sysadmin',
        fallbackNS: 'sysadmin',
        backend: {
            // path where resources get loaded from, or a function
            // returning a path:
            // function(lngs, namespaces) { return customPath; }
            // the returned path will interpolate lng, ns if provided like giving a static path
            // the function might return a promise
            // returning falsy will abort the download
            //
            // If allowMultiLoading is false, lngs and namespaces will have only one element each,
            // If allowMultiLoading is true, lngs and namespaces can have multiple elements
            // loadPath: "/locales/{{lng}}/{{ns}}.json",
            // loadPath: "/locales/{{lng}}/translations.json",
            // loadPath: Config.REACT_APP_FLASK_API + "getTranslations",
            // loadPath: "http://localhost:5000/backend/getTranslations{{lng}}",
            loadPath: MyPath(),
            // parse: function (data) {
            //     console.log(data);
            //     return data;
            // },
            // fetch: function (url, options, callback) {
            //     console.log(
            //         "i18n.js - custom fetch - url, options, callback: ",
            //         url,
            //         ", ",
            //         options,
            //         ", ",
            //         callback
            //     );
            // },
        },

        // resources: {
        //     cs: {
        //         translation: TRANSLATIONS_CS,
        //     },
        //     da: {
        //         translation: TRANSLATIONS_DA,
        //     },
        //     en: {
        //         translation: TRANSLATIONS_EN,
        //     },
        //     ms: {
        //         translation: TRANSLATIONS_MS,
        //     },
        // },
        fallbackLng: 'en',
        lng: 'en', // default language - https://stackoverflow.com/questions/65921459/how-to-set-i18n-default-translation-language-in-reactch
        detection: {
            // order and from where user language should be detected
            order: [
                'cookie',
                'localStorage',
                'sessionStorage',
                'querystring',
                'navigator',
                'htmlTag',
                'path',
                'subdomain',
            ],

            // keys or params to lookup language from
            lookupLocalStorage: 'i18nextLng',
            lookupQuerystring: 'lng',
            lookupCookie: 'i18next',
            lookupSessionStorage: 'i18nextLng',
            lookupFromPathIndex: 0,
            lookupFromSubdomainIndex: 0,

            // cache user language on
            caches: ['localStorage', 'cookie'],
            excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)

            // optional expire and domain for set cookie
            cookieMinutes: 10,
            // cookieDomain: "myDomain",

            // optional htmlTag with lang attribute, the default is:
            htmlTag: document.documentElement,

            // optional set cookie options, reference:[MDN Set-Cookie docs](https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie)
            cookieOptions: { path: '/', sameSite: 'strict' },
        },

        // lng: "en",
        debug: false,
        // interpolation: {
        //     escapeValue: false,
        // },
    });

// i18n.changeLanguage('da');

export default i18n;
