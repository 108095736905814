import React, { useContext } from 'react';

import { displayContext } from '../../../common/DisplayContext';
import { errorContext } from '../../contexts/ErrorContext';

import styles from '../../styles.module.css';

function NewConclusionGroup() {
    const { newConclusionGroup, setNewConclusionGroup } =
        useContext(displayContext);

    const { errors } = useContext(errorContext);

    function handleConclusionGroupChange(e) {
        setNewConclusionGroup(e.target.value);
    }

    return (
        <>
            <textarea
                name="newConclusionGroupMC"
                // className="NewConclusionGroup"
                type="text"
                value={newConclusionGroup}
                onChange={(e) => handleConclusionGroupChange(e)}
                // isInvalid={!!errors.NewConclusionGroup}
            />

            {errors.newConclusion && (
                <div
                    style={{
                        width: '400px',
                        padding: '10px',
                    }}
                >
                    <a type="text" placeholder="You must enter a note" />
                </div>
            )}
        </>
    );
}

export default NewConclusionGroup;
