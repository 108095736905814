import React, { useContext, createContext } from 'react';

import { displayContext } from '../../../common/DisplayContext.js';
import { navigationContext } from '../../contexts/NavigationContext.js';

import styles from '../../styles.module.css';

import Navbar from '../Settings/Navbar.js';

import CaseTableTestNested from '../EvaluateCase/CaseTableTestNested.js';
import MCResultTable from '../EvaluateCase/MCResultTable.js';

import AddNode from './AddNode.js';

import RejectedCaseButtonTopbar from './RejectedCaseButtonTopbar.js';
import TopBar from '../TopBar/TopBar.js';

function EvaluateCaseMainMC() {
    const {
        previousPath,
        t,
        useMode,
        textAreaNewRuleDisabled,
        uiModeMCAddNode,
    } = useContext(displayContext);

    const { page } = useContext(navigationContext);

    return (
        <div className={styles.standardPage}>
            <div className={styles.standardPageTop}>
                <Navbar />
                <TopBar />
            </div>

            <div className={styles.standardPageMiddle}>
                <CaseTableTestNested />
                {page == 'addNodeMC' && <AddNode />}
                <MCResultTable />
            </div>
        </div>
    );
}

export default EvaluateCaseMainMC;
