// TODO:
// 
// I swiperen skal jeg fra 'stenslag' kunne swipe lodret og komme til undermenuen
// Se link for at fange lodrette swipes: https://stackoverflow.com/questions/70612769/how-do-i-recognize-swipe-events-in-react
// Det skal være sådan at det første lodrette swipe åbner undermenuen, og et vandret swipe i undermenuen skal returnere 
// til overmenuen...

// import '../swipeballDesign.css';
// import '../test.css';

// import React from 'react';
// import 'swiper/swiper.css'; // Import Swiper styles
// import { Swiper, SwiperSlide } from 'swiper/react';
// import 'swiper/css/navigation.css'; // Import Swiper Navigation styles
// import 'swiper/css/pagination.css'; // Import Swiper Pagination styles
// import SwiperCore from 'swiper';

// import { Navigation, Pagination } from 'swiper/modules';

// // Initialize Swiper modules
// SwiperCore.use([Navigation, Pagination]);



// function TopTest() {
//     const horizontalSwiperRef = React.useRef(null);
//     const verticalSwiperRef = React.useRef(null);

//     const handleHorizontalSlideChange = (swiper) => {
//         console.log('Horizontal Slide Index:', swiper.activeIndex);


//     };

//     const handleVerticalSlideChange = (swiper) => {
//         console.log('Vertical Slide Index:', swiper.activeIndex);

//         if (horizontalSwiperRef.current) {
//             horizontalSwiperRef.current.slideTo(swiper.activeIndex);
//         }
//     };

//     return (
//         <Swiper
//             onSlideChange={handleHorizontalSlideChange}
//             ref={horizontalSwiperRef}
//         >
//             <SwiperSlide>Slide 1</SwiperSlide>
//             <SwiperSlide>Slide 2</SwiperSlide>
//             <SwiperSlide>Slide 3</SwiperSlide>
//             <Swiper
//                 direction="vertical"
//                 onSlideChange={handleVerticalSlideChange}
//                 ref={verticalSwiperRef}
//             >
//                 <SwiperSlide>Vertical Slide 1</SwiperSlide>
//                 <SwiperSlide>Vertical Slide 2</SwiperSlide>
//                 <SwiperSlide>Vertical Slide 3</SwiperSlide>
//             </Swiper>
//             <SwiperSlide>Slide 4</SwiperSlide>
//             <SwiperSlide>Slide 5</SwiperSlide>
//         </Swiper>
//     );
// }

// export default TopTest;




// TODO:
// 
// I swiperen skal jeg fra 'stenslag' kunne swipe lodret og komme til undermenuen
// Se link for at fange lodrette swipes: https://stackoverflow.com/questions/70612769/how-do-i-recognize-swipe-events-in-react
// Det skal være sådan at det første lodrette swipe åbner undermenuen, og et vandret swipe i undermenuen skal returnere 
// til overmenuen...


import React, { useRef, useState, useContext } from 'react';
import "../swipeballDesign.css"

import { Button } from "react-bootstrap";

import { topContext } from '../contexts/TopContext';

// Import Swiper React components
import { Swiper, SwiperSlide, useSwiperSlide, useSwiper } from 'swiper/react';
import { Virtual } from 'swiper/modules';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import '../swipeballDesign.css';

// import required modules
import { Pagination } from 'swiper/modules';

// Import pictures
// import background from './images/background.png';
import logo from '../images/logo.svg';
import startButtonDepressed from '../images/Ball-Final.svg';

import picture1 from '../images/picture 1.svg';
import picture2 from '../images/picture 2.svg';
import picture3 from '../images/picture 3.svg';
import swiperball from '../images/swiperball.png'
import swiperballSelected from '../images/swiperball-selected.png'
import stenslag from '../images/stenslag.png'
import { BsFillCarFrontFill } from "react-icons/bs";
import { BsFillCloudHailFill } from "react-icons/bs";

function ComprehensiveSwiper() {



    const handleHorizontalSlideChange = (swiper) => {
        // Get the index of the current horizontal slide
        console.log('Horizontal Slide Index:', swiper.activeIndex);
    };

    const handleVerticalSlideChange = (swiper) => {
        // Get the index of the current vertical slide
        console.log('Vertical Slide Index:', swiper.activeIndex);
    };




    const { stoneDamage, setStoneDamage } = useContext(topContext);
    // const { selectedOptionIsActive, setSelectedOptionIsActive } = useContext(false);

    const { activeSlide, setActiveSlide } = useState('');

    const [infoButton, setInfoButton] = useState(false);
    const [descriptionText, setDescriptionText] = useState("");

    const [swipeBallFocus, setSwipeBallFocus] = useState("kaskoskadetyper");

    const descriptionTexts = [
        "Hvilken kaskoskade har din bil fået?"
        , "Min bil har fået en stenslagsskade"
        , "Min bil har fået en bilkabule"
        , "Min bil har fået en haglskade"
    ]

    function SlideTitle() {
        const swiperSlide = useSwiperSlide();
        return (
            <p>Current slide is {swiperSlide.isActive ? 'active' : 'not active'}</p>
        )
    };



    const SlideTitle1 = "abekat"


    function SlideTitle2() {
        return ('abekat')
    }



    function SwiperInfo() {
        const swiper = useSwiper();
        return (<p>sådannermanner</p>)
    }

    function SlideTitle3() {
        const swiperSlide = useSwiperSlide();
        // setActiveSlide()
        return (<p>abekat {swiperSlide.isActive ? 'active' : 'not active'}</p>)
    }


    function toggleInfoButton() {
        setInfoButton(!infoButton)
    }

    function damage(e) {
        if (e.target.id == stoneDamage) {
            setStoneDamage('')
        } else {
            setStoneDamage(e.target.id)
        }
    }

    // function isSelectedOptionActive(e) {
    //     if (e.target.id == stoneDamage) {
    //         return true
    //     } else {
    //         return false
    //     }
    // }



    // const damage = (e) => {
    //     // e.persist();
    //     setStoneDamage(e.target.id)
    //     console.log(console.log('ComprehensiveSwiper - damageType: ', e.target.id))
    // }


    return (

        <div className='container'>

            <div className='top-bar'></div>

            <div className='box-1'>
                <img src={logo} className='logo' alt="logo" />
            </div>

            <div className='box-flex'>

                <img src={swiperball} className='swipe-ball' alt="ball" />

                {/* {stoneDamage == 'light' ? <img src={swiperball} className='swipe-ball' alt="ball" />
                    : <img src={swiperballSelected} className='swipe-ball' alt="ball" />}
                hundehoved */}


                {/* <img src={swiperballSelected} className='swipe-ball' alt="ball" /> */}

                <div className='centered'>

                    {/* Top level - kaskoskadetyper */}

                    <Swiper
                        className="my-swiper-h"
                        spaceBetween={10}
                        modules={[Pagination]}
                        onActiveIndexChange={({ realIndex }) => {
                            console.log(realIndex + 1);
                            setDescriptionText(descriptionTexts[realIndex]);
                        }}
                        loop='true'
                        // onSlideChange={({ realIndex }) => { console.log(realIndex) }}
                        onSlideChange={handleHorizontalSlideChange}
                    >

                        <SwiperSlide>
                            Swipe og vælg
                        </SwiperSlide>

                        <Swiper
                            className="my-swiper-h"
                            spaceBetween={10}
                            modules={[Pagination]}
                            onActiveIndexChange={({ realIndex }) => {
                                console.log(realIndex + 1);
                                setDescriptionText(descriptionTexts[realIndex]);
                            }}
                            // onSlideNextTransitionEnd={console.log('slide transition - level 1')}
                            loop='true'
                        >
                            <SwiperSlide>
                                <Swiper
                                    className="my-swiper-v"
                                    direction={'vertical'}
                                    spaceBetween={10}
                                    modules={[Pagination]}
                                    effect='coverflow'
                                    loop='true'
                                    centeredSlides='true'
                                    slidesPerView='1'
                                    coverflowEffect={{
                                        rotate: 0,
                                        stretch: 80,
                                        depth: 200,
                                        modifier: 1, // 2,3
                                        slideShadows: false,
                                    }}
                                // onSlideChange={({ realIndex }) => { console.log(realIndex) }}


                                >
                                    <SwiperSlide>
                                        <div>
                                            {/* <img src={stenslag} className='swiper-icon' onClick={() => setSwipeBallFocus('stenslagstyper')} onTouchStart={onTouchStart} onTouchMove={onTouchMove} onTouchEnd={onTouchEnd} /> */}
                                            <img src={stenslag} className='swiper-icon' />
                                            <p className='swiper-icon-label'>stenslag</p>
                                        </div>
                                    </SwiperSlide>
                                    <Swiper
                                        className="my-swiper-v"
                                        direction={'vertical'}
                                        spaceBetween={10}
                                        modules={[Pagination]}
                                        effect='coverflow'
                                        loop='true'
                                        centeredSlides='true'
                                        slidesPerView='3'
                                        coverflowEffect={{
                                            rotate: 0,
                                            stretch: 80,
                                            depth: 200,
                                            modifier: 1, // 2,3
                                            slideShadows: false,
                                        }}
                                        onSlideChange={handleVerticalSlideChange}

                                    >
                                        <SwiperInfo />
                                        <SwiperSlide>

                                            <div id="light" onClick={damage}>stenslag i lygten kdkddk </div>
                                            <SlideTitle3 />

                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div id="paint" onClick={damage}>stenslag i lakken</div>
                                        </SwiperSlide>

                                        <SwiperSlide>
                                            <div id="screen" onClick={damage}>stenslag i ruden</div>
                                        </SwiperSlide>
                                    </Swiper>
                                </Swiper>
                            </SwiperSlide>
                        </Swiper>

                        <SwiperSlide>
                            <div>
                                <BsFillCarFrontFill size={120} />
                                <p className='swiper-icon-label'>bilkabule</p>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div>
                                <BsFillCloudHailFill size={100} />
                                <p className='swiper-icon-label'>haglskade</p>
                            </div>
                        </SwiperSlide>
                    </Swiper>


                </div>

            </div >

            {/* <div className='box-3 description-text'>Hvilken kaskoskade har din bil fået?</div> */}
            <div className='box-3 description-text'>{descriptionText}</div>

            {/* <div className='info-button-overlay'></div> */}

            <Button className='info-button-overlay' onClick={toggleInfoButton}></Button>


            {/* 
            <Button
                // className="mentor-button1"
                onClick={toggleInfoButton}
            >
                info
            </Button> */}

        </div >
    );

}
export default ComprehensiveSwiper;