import React, { useRef, useState, useContext, useEffect } from 'react';

import { swiperContext } from '../contexts/SwiperContext';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import useSwiper from '../hooks/useSwiper';

import TrademarkSwiperList from './TrademarkSwiperList';

// Import pictures
import swiperball from '../images/swiperball.png'
import swiperballSelected from '../images/swiperball-selected.png'

import wherePlace from '../images/where-place.png'
import whereCity from '../images/where-city.png'
import whereDenmark from '../images/where-denmark.png'
import whereAbroad from '../images/where-abroad.png'
import whereOnline from '../images/where-online.png'

import { useNavigate } from "react-router-dom";

function TrademarkSwiper3() {

    const [{ setOption,
        handleHorizontalSlideChange,
        handleVerticalSlideChange,
    }] = useSwiper();

    let navigate = useNavigate();

    const ball = 3;

    const {
        activeOptions, setActiveOptions,
        selectedOptions, setSelectedOptions,
        activeSlideSelected, setActiveSlideSelected,
        focus, setFocus,
        optionDescription,
    } = useContext(swiperContext);

    if (focus.ball != ball) {
        setFocus({ "ball": ball, "row": 0, "column": 0 })
    }

    const itemsPlace = [
        { text: 'fitnesscenter' },
        { text: 'forlystelsespark' },
        { text: 'forening' },
        { text: 'hotel' },
        { text: 'klinik' },
        { text: 'madbod' },
        { text: 'messe' },
        { text: 'restaurant' },
        { text: 'spillested' },
        { text: 'stormagasin' },
        { text: 'supermarked' },
    ]

    const itemsDenmark = [
        { text: 'hele landet' },
        { text: 'region' },
        { text: 'Grønland' },
        { text: 'Færøerne' },
    ]

    const itemsAbroad = [
        { text: 'mod Danmark' },
        { text: 'ikke mod Danmark' }
    ]

    // function setOption(ball, row, column) {

    //     // If this option was not already selected
    //     // 1. remove other selected option in the same column
    //     // 2. add this option to the array of selected options...
    //     if (selectedOptions.findIndex(item => (item.ball == ball && item.row == row && item.column == column)) === -1) {

    //         // remove other options in the same column from selectedOptions
    //         const newSelectedOptions = selectedOptions.filter(item => !(item.ball === ball && item.column == column))

    //         // add currentOption to selectedOptions
    //         newSelectedOptions.push({ "ball": ball, "row": row, "column": column });
    //         setSelectedOptions(newSelectedOptions);
    //         setActiveSlideSelected(true);
    //         // ...else, i.e. this option was already selected, remove it from the array of selected options.
    //     } else {
    //         // remove currentOption from selectOptions
    //         setSelectedOptions(selectedOptions.filter(item => !(item.ball === ball && item.row == row && item.column == column)))
    //         setActiveSlideSelected(false);
    //     }
    // }

    // const handleHorizontalSlideChange = (swiper) => {

    //     let activeOptionInColumn = activeOptions.find(item => item.ball == ball && item.column == swiper.activeIndex)
    //     if (activeOptionInColumn === undefined) {
    //         activeOptionInColumn = { ball: ball, row: 0, column: 0 }
    //     }

    //     setFocus({ "ball": ball, "row": activeOptionInColumn.row, "column": swiper.activeIndex });
    // };

    // const handleVerticalSlideChange = (swiper) => {
    //     setFocus({ "ball": ball, "row": swiper.activeIndex, "column": focus.column });

    //     // Update the active option for this column...
    //     // ...remove current activeOption for this column...
    //     const newActiveOptions = activeOptions.filter(item => !(item.ball === ball && item.column == focus.column))

    //     // ...add new activeOption for this column
    //     newActiveOptions.push({ "ball": ball, "row": swiper.activeIndex, "column": focus.column });
    //     setActiveOptions(newActiveOptions);
    // };

    useEffect(() => {
        setActiveSlideSelected(selectedOptions.findIndex(item => (item.ball == focus.ball && item.row == focus.row && item.column == focus.column)) != -1);
    }, [focus]);

    function gotoNext(e) {
        setFocus({ "ball": 4, "row": 0, "column": 0 });
        navigate("/TrademarkSwiper4");
    }

    return (
        <div className='container'>
            <div className='top-bar'></div>
            <div className='boxlegal-1'>
                <h2 className='app-title'>legal</h2>
            </div>

            <div className='boxlegal-flex'>
                {/* Ball color depending on whether option is selected or not... */}
                {activeSlideSelected ?
                    <img src={swiperballSelected} className='swipe-ball' alt="ball" /> : <img src={swiperball} className='swipe-ball' alt="ball" />
                }

                {/* ...or ball color is always the same */}
                {/* <img src={swiperball} className='swipe-ball' alt="ball" /> */}


                <div className='centered'>
                    <Swiper
                        className="my-swiper-h"
                        spaceBetween={50}
                        onSlideChange={handleHorizontalSlideChange}
                    >

                        <SwiperSlide className='swiperSlideTitle1'>
                            <div className="swiper-ball-text">swipe & vælg</div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <Swiper
                                className="my-swiper-v"
                                direction={'vertical'}
                                spaceBetween={50}
                                onSlideChange={handleVerticalSlideChange}
                            >

                                <SwiperSlide>
                                    <div>
                                        <div className="swiper-line-top"></div>
                                        <div className="swiper-line-bottom"></div>
                                        <img src={wherePlace} className='swiper-icon1' alt='' />
                                        <p className='swiper-icon-label'>sted</p>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    {/* <TrademarkSwiper3Place /> */}
                                    <div className="swiper-line-top"></div>
                                    <div className="swiper-line-bottom"></div>
                                    <TrademarkSwiperList listClassName="place" ball="3" column="1" items={itemsPlace} />
                                </SwiperSlide>
                            </Swiper>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div onClick={() => setOption(ball, 0, 2, 'add')}>
                                <img src={whereCity} className='swiper-icon1' alt='' />
                                <p className='swiper-icon-label'>by</p>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <Swiper
                                className="my-swiper-v"
                                direction={'vertical'}
                                spaceBetween={50}
                                onSlideChange={handleVerticalSlideChange}
                            >

                                <SwiperSlide>
                                    <div>
                                        <div className="swiper-line-top"></div>
                                        <div className="swiper-line-bottom"></div>
                                        <img src={whereDenmark} className='swiper-icon1' alt='' />
                                        <p className='swiper-icon-label'>Danmark</p>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="swiper-line-top"></div>
                                    <div className="swiper-line-bottom"></div>
                                    <TrademarkSwiperList listClassName="denmark" ball="3" column="3" items={itemsDenmark} />

                                </SwiperSlide>

                            </Swiper>
                        </SwiperSlide>

                        <SwiperSlide>
                            <Swiper
                                className="my-swiper-v"
                                direction={'vertical'}
                                spaceBetween={50}
                                onSlideChange={handleVerticalSlideChange}
                            >

                                <SwiperSlide>
                                    <div>
                                        <div className="swiper-line-top"></div>
                                        <div className="swiper-line-bottom"></div>
                                        <img src={whereAbroad} className='swiper-icon1' alt='' />
                                        <p className='swiper-icon-label'>udland</p>
                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className="swiper-line-top"></div>
                                    <div className="swiper-line-bottom"></div>
                                    <TrademarkSwiperList listClassName="abroad" ball="3" column="4" items={itemsAbroad} />
                                </SwiperSlide>

                            </Swiper>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div onClick={() => setOption(ball, 0, 5, 'add')}>
                                <img src={whereOnline} className='swiper-icon1' alt='' />
                                <p className='swiper-icon-label'>online</p>

                            </div>
                        </SwiperSlide>
                    </Swiper>

                </div>
            </div >

            <div className='boxlegal-3 page-description'>
                {optionDescription}
            </div>

            <div className='info-button-overlay'></div>

            <div className='boxlegal-4'>
                <div className='myprogress'>45%</div>
                <div className='button-next' onClick={gotoNext}>Næste</div>
            </div>

        </div >
    );

}
export default TrademarkSwiper3;