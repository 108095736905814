// https://codepen.io/Twixes/details/rNeGNrz


// InfiniteScrollLoop.js
import React, { useState, useRef, useCallback, useEffect } from 'react';

const GERMAN_DIGITS = [
    "Null",
    "Ein",
    "Zwei",
    "Drei",
    "Vier",
    "Fünf",
    "Sechs",
    "Sieben",
    "Acht",
    "Neun"
];

const LegalTest = () => {
    const [visibleItems, setVisibleItems] = useState([]);
    const contentRef = useRef(null);
    const scrollRef = useRef(null);
    const itemHeight = 40; // Adjust as needed
    const numVisibleItems = 5;
    const totalItems = GERMAN_DIGITS.length;

    useEffect(() => {
        const totalHeight = totalItems * itemHeight;
        const scrollHeight = numVisibleItems * itemHeight;


        const updateVisibleItems = () => {
            if (contentRef.current && scrollRef.current) {
                const scrollTop = scrollRef.current.scrollTop;

                const startIdx = Math.floor(scrollTop / itemHeight);
                const endIdx = startIdx + numVisibleItems;
                const visible = [];

                for (let i = startIdx; i < endIdx; i++) {
                    const adjustedIndex = i % totalItems;
                    visible.push(adjustedIndex);
                }

                setVisibleItems(visible);
            }
        };


        // Set initial visible items
        updateVisibleItems();

        // Add scroll event listener
        scrollRef.current.addEventListener('scroll', updateVisibleItems);

        return () => {
            // Remove scroll event listener
            scrollRef.current.removeEventListener('scroll', updateVisibleItems);
        };
    }, []);


    return (
        <div className="infinite-scroll-loop-outer">
            <div
                className="infinite-scroll-loop-inner"
                ref={scrollRef}
                onScroll={() => { }}
                style={{ height: `${numVisibleItems * itemHeight}px`, overflowY: 'scroll' }}

            >
                <div ref={contentRef} style={{ height: `${totalItems * itemHeight}px` }}>
                    {visibleItems.map((index) => (
                        <div key={index}>
                            {index}.{' '}
                            <span
                                style={{
                                    color: `hsl(${(index / totalItems) * 360}deg 100% 50%)`
                                }}
                            >
                                {GERMAN_DIGITS[index]}.
                            </span>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default LegalTest;

