import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
    Form,
    Row,
    Col,
    InputGroup,
    Button,
    ButtonGroup,
} from 'react-bootstrap';

import Modal from 'react-modal';

// Flags: https://www.npmjs.com/package/flag-icons
import '/node_modules/flag-icons/css/flag-icons.min.css';

import { domainContext } from '../../admin/contexts/DomainContext';
import { userCaseContext } from '../../admin/contexts/UserCaseContext';
import { displayContext } from '../../common/DisplayContext';

import useTMEvaluate from '../hooks/useTMEvaluate';

import NavigationSideBar from '../../admin/components/Navigation/NavigationSideBar';
import Header from '../components/Header';

function TM() {
    const { setDisplayMessage, previousPath, setUseMode, t2 } =
        useContext(displayContext);

    let navigate = useNavigate();
    let location = useLocation();

    // const { token } = useAuth();

    useEffect(() => {
        setDisplayMessage('');
    }, [location.key]);

    const {
        currentDomain,
        setCurrentDomain,
        currentKnowledgeBase,
        setCurrentKnowledgeBase,
    } = useContext(domainContext);

    const { userCase, setUserCase, setCaseStatus } =
        useContext(userCaseContext);

    // const {
    //     conclusionLastTrue,
    //     explanationLastTrue,
    //     currentCaseId,
    //     nodeLastTrue,
    //     setConclusionLastTrue,
    //     setEvaluationResult,
    //     setExplanation,
    //     setExplanationLastTrue,
    //     setExplanationTrues,
    //     setNodeLast,
    //     setNodeLastTrue,
    //     setNodesEvaluated,
    //     setNoteLastTrue,
    //     setReferenceCase,
    //     setReturnCode,
    // } = useContext(evaluationContext);

    const [{ handleEvaluate }] = useTMEvaluate();

    // if (
    //     typeof currentKnowledgeBase == "undefined" ||
    //     currentKnowledgeBase != "varemaerke"
    // ) {
    //     setCurrentKnowledgeBase("varemaerke");
    // }

    if (userCase == []) {
        setCurrentDomain('varemaerke');
    }

    if (previousPath == '/learn') {
        setUseMode('read');
    }

    // Validation

    // const [VMUse, setVMUse] = useState({
    //     oneVersion: 0,
    //     multipleVersions: 0,
    // });

    // const [errors, setErrors] = useState("errors");
    const [errors, setErrors] = useState('');
    const [tMName, setTMName] = useState('');
    const [tMPurpose, setTMPurpose] = useState('');

    const findFormErrors = () => {
        const newErrors = {};

        if (
            !['one', 'multiple'].includes(
                userCase.find((attribute) => attribute.name == 'use')['test']
            )
        ) {
            newErrors.use = t2('selectInput', 'varemaerke');
        }

        if (
            !['individual', 'organisation'].includes(
                userCase.find((attribute) => attribute.name == 'userType')[
                    'test'
                ]
            )
        ) {
            newErrors.userType = t2('selectInput', 'varemaerke');
        }

        return newErrors;
    };

    function handleChange(e) {
        const updatedUserCase = userCase.map((attribute) => {
            if (attribute.name === e.target.name) {
                return {
                    ...attribute,
                    ['test']: e.target.value,
                };
            } else {
                return attribute;
            }
        });

        setUserCase(updatedUserCase);

        if (e.target.name == 'tMName') {
            setTMName(e.target.value);
        } else if (e.target.name == 'tMPurpose') {
            setTMPurpose(e.target.value);
        }
    }

    function handleSubmit(e) {
        // Find errors
        const newErrors = findFormErrors();

        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors);
        } else {
            // No errors! Put any logic here for the form submission!
            handleEvaluate(e, 'use');

            navigate('/TMTimePage');
        }
    }

    console.log('TM1 - userCase: ', userCase);

    return (
        <>
            <div className="vm-grid-container">
                <NavigationSideBar />
                <Header />

                {/* Container for the middle area - positions everyting top left */}
                <div className="vm-grid-middle">
                    {/* Container for radio-buttons and submit button - positions everyting right, but no vertical alignment*/}
                    <div className="vm-middle">
                        <div>
                            <div className="vm-intro-question">
                                {t2('introQuestion', 'varemaerke')}
                            </div>
                            <div className="vm-intro-text">
                                {t2('introText', 'varemaerke')}
                            </div>

                            {/* Container - trademark name and purpose */}

                            {/* <div class="row" className="vm-middle-row1"> */}
                            <div
                                // class="row"
                                className={
                                    typeof errors.purpose == 'undefined'
                                        ? 'vm-middle-row vm-middle-row2'
                                        : 'vm-middle-row vm-middle-row3'
                                }
                            >
                                <form className="vm-middle-form">
                                    <div className="vm-middle-form-prompt">
                                        {t2('purposePrompt', 'varemaerke')}
                                    </div>

                                    <div className="vm-grid vm-grid-use1">
                                        {/* Radio-button */}

                                        <div className="vm-btn-radio2">
                                            <p>
                                                <label
                                                    htmlFor="iUseTm"
                                                    className="vm-lbl-radio"
                                                >
                                                    <a className="vm-lbl-radio-text">
                                                        {t2(
                                                            'iUseTm',
                                                            'varemaerke'
                                                        )}
                                                    </a>{' '}
                                                </label>

                                                <input
                                                    id="iUseTm"
                                                    className="vm-input-text"
                                                    type="text"
                                                    name="tMName"
                                                    value={
                                                        tMName
                                                        // typeof userCase ==
                                                        //     "undefined" ||
                                                        // userCase.length == 0 ||
                                                        // userCase.find(
                                                        //     (attribute) =>
                                                        //         attribute.name ==
                                                        //         "tMName"
                                                        // )["test"] == "undefined"
                                                        //     ? ""
                                                        //     : userCase.find(
                                                        //           (attribute) =>
                                                        //               attribute.name ==
                                                        //               "tMName"
                                                        //       )["test"]
                                                    }
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                />
                                            </p>

                                            <p>
                                                <label
                                                    htmlFor="iUseTmFor"
                                                    className="vm-lbl-radio"
                                                >
                                                    <a className="vm-lbl-radio-text">
                                                        {t2(
                                                            'iUseTmFor',
                                                            'varemaerke'
                                                        )}
                                                    </a>{' '}
                                                </label>

                                                <input
                                                    id="iUseTmFor"
                                                    className="vm-input-text"
                                                    type="text"
                                                    name="tMPurpose"
                                                    value={tMPurpose}
                                                    // value={
                                                    //     typeof userCase ==
                                                    //         "undefined" ||
                                                    //     userCase.length == 0
                                                    //         ? ""
                                                    //         : userCase.find(
                                                    //               (attribute) =>
                                                    //                   attribute.name ==
                                                    //                   "tMPurpose"
                                                    //           )["test"]
                                                    // }
                                                    onChange={(e) =>
                                                        handleChange(e)
                                                    }
                                                />
                                            </p>
                                        </div>
                                    </div>

                                    {/* {errors} */}

                                    {typeof errors.iUseTm != 'undefined' && (
                                        <p className="vm-error-message">
                                            {errors.iUseTm}
                                        </p>
                                    )}
                                </form>
                            </div>

                            {/* Container - user type, i.e. whether an individual or an organisation */}

                            <div
                                // class="row"
                                className={
                                    typeof errors.userType == 'undefined'
                                        ? 'vm-middle-row vm-middle-row2'
                                        : 'vm-middle-row vm-middle-row3'
                                }
                            >
                                <form className="vm-middle-form">
                                    <div className="vm-middle-form-prompt">
                                        {t2('userTypePropmt', 'varemaerke')}
                                    </div>
                                    <div className="vm-grid vm-grid-use1">
                                        {/* Radio-button */}
                                        {/* 
                                        <VMRadioButton
                                            attributeName="userType"
                                            attributeValue="individual"
                                            attributeLabel="userTypeIndividual"
                                        /> */}

                                        {/* <div className="vm-btn-radio1"> */}
                                        <label className="vm-lbl-radio1">
                                            <input
                                                id="individual"
                                                type="radio"
                                                name="userType"
                                                value="individual"
                                                checked={
                                                    userCase !== undefined &&
                                                    userCase.length != 0 &&
                                                    userCase.find(
                                                        (attribute) =>
                                                            attribute.name ==
                                                            'userType'
                                                    )['test'] == 'individual'
                                                }
                                                onChange={(e) =>
                                                    handleChange(e)
                                                }
                                            />
                                            <a className="vm-lbl-radio-text">
                                                {t2(
                                                    'userTypeIndividual',
                                                    'varemaerke'
                                                )}
                                            </a>
                                        </label>
                                        {/* </div> */}

                                        {/* Radio-button */}
                                        {/* <div className="vm-btn-radio1"> */}
                                        <label className="vm-lbl-radio1">
                                            <input
                                                id="organisation"
                                                type="radio"
                                                name="userType"
                                                value="organisation"
                                                checked={
                                                    userCase !== undefined &&
                                                    userCase.length != 0 &&
                                                    userCase.find(
                                                        (attribute) =>
                                                            attribute.name ==
                                                            'userType'
                                                    )['test'] == 'organisation'
                                                }
                                                onChange={(e) =>
                                                    handleChange(e)
                                                }
                                            />
                                            <a className="vm-lbl-radio-text">
                                                {t2(
                                                    'userTypeOrganisation',
                                                    'varemaerke'
                                                )}
                                            </a>
                                        </label>
                                        {/* </div> */}
                                    </div>

                                    <div className="vm-footnote">
                                        Et individuelt varemærke bruger du selv
                                        i modsætning til et kollektivt varemærke
                                        som bruges af medlemmerne af en
                                        sammenslutning.
                                    </div>

                                    {/* {errors} */}
                                    {typeof errors.userType != 'undefined' && (
                                        <p className="vm-error-message">
                                            {errors.userType}
                                        </p>
                                    )}
                                </form>
                            </div>

                            {/* Container - number of versions of the trademark */}

                            <div
                                // class="row"
                                className={
                                    typeof errors.use == 'undefined'
                                        ? 'vm-middle-row vm-middle-row2'
                                        : 'vm-middle-row vm-middle-row3'
                                }
                            >
                                {/* <div class="row" className="vm-middle-row1"> */}
                                <form className="vm-middle-form">
                                    <div className="vm-middle-form-prompt">
                                        {t2('usePrompt', 'varemaerke')}
                                    </div>

                                    <div className="vm-grid vm-grid-use1">
                                        {/* Radio-button */}
                                        {/* <div className="vm-btn-radio1"> */}
                                        <label className="vm-lbl-radio1">
                                            <input
                                                id="one"
                                                type="radio"
                                                name="use"
                                                value="one"
                                                checked={
                                                    userCase !== undefined &&
                                                    userCase.length != 0 &&
                                                    userCase.find(
                                                        (attribute) =>
                                                            attribute.name ==
                                                            'use'
                                                    )['test'] == 'one'
                                                }
                                                onChange={(e) =>
                                                    handleChange(e)
                                                }
                                            />
                                            <a className="vm-lbl-radio-text">
                                                {t2(
                                                    'useOneVersion',
                                                    'varemaerke'
                                                )}
                                            </a>
                                        </label>
                                        {/* </div> */}

                                        {/* Radio-button */}
                                        {/* <div className="vm-btn-radio1"> */}
                                        <label className="vm-lbl-radio1">
                                            <input
                                                id="multiple"
                                                type="radio"
                                                name="use"
                                                value="multiple"
                                                checked={
                                                    userCase !== undefined &&
                                                    userCase.length != 0 &&
                                                    userCase.find(
                                                        (attribute) =>
                                                            attribute.name ==
                                                            'use'
                                                    )['test'] == 'multiple'
                                                }
                                                onChange={(e) =>
                                                    handleChange(e)
                                                }
                                            />
                                            <a className="vm-lbl-radio-text">
                                                {t2(
                                                    'useMultipleVersions',
                                                    'varemaerke'
                                                )}
                                            </a>
                                        </label>
                                        {/* </div> */}
                                    </div>

                                    {/* {errors} */}
                                    {typeof errors.use != 'undefined' && (
                                        <p className="vm-error-message">
                                            {errors.use}
                                        </p>
                                    )}
                                </form>
                            </div>

                            <Row className="vm-middle-row-bottom">
                                <div className="vm-submit-button-div">
                                    <button
                                        id="useSubmitButton"
                                        className="vm-btn-submit"
                                        onClick={handleSubmit}
                                    >
                                        {t2('submit', 'varemaerke')}
                                    </button>
                                </div>
                            </Row>
                        </div>
                    </div>
                </div>
                <div className="vm-grid-footer"></div>
            </div>
        </>
    );
}

export default TM;
