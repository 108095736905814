import { useContext } from "react";
import { domainsContext } from "../../contexts/DomainsContext";
import { displayContext } from "../../../common/DisplayContext";

function MenuItemsList() {
    const { domains1, knowledgeBases1 } = useContext(domainsContext);
    const {
        languages,
        t,
        t2,
    } = useContext(displayContext);

    // Used only for testing
    //
    // const submenu1 = [
    //     {
    //         title: "AES",
    //         action: "domain",
    //         domain: "aes",
    //     },
    //     {
    //         title: "Auto",
    //         action: "domain",
    //         domain: "auto",
    //     },
    //     {
    //         title: "SOF",
    //         action: "domain",
    //         domain: "sof",
    //     },
    // ];

    const submenu2 = [
        {
            title: "English",
            action: "language",
            language: "en",
        },
        {
            title: "Danish",
            action: "language",
            language: "da",
        },
    ];

    const menuItems = [
        {
            title: t2('settings', 'sysadmin'),

            submenu: [
                // {
                //     title: "Domain",
                //     url: "web-dev",
                //     submenu: domains1[0],
                // },

                // NOTE: EVEN THOUGH THE FOLLOWING ENTRY IS CALLED 'Domain' IT REALLY DETERMINES THE KNOWLEDGE BASE WE ARE USING.
                // I USED THE NAME 'Domain', SO THAT THE END-USERS CANNOT SEE ANY CHANGE. IF WE WANT TO RE-INTRODUCE domains WITHIN A KNOWLEDGE BASE
                // THEN WE HAVE TO DECIDE WHETHER TO STILL CALL THIS ENTRY POINT DOMAIN.
                // title: "Knowledge base",
                {
                    title: t2("knowledgeBase", 'sysadmin'),
                    url: "web-dev",
                    submenu: knowledgeBases1[0],
                },
                {
                    title: t2("resetKnowledgeBase", 'sysadmin'),
                    action: "resetKnowledgeBase",
                },
                // {
                //     title: "Reset domain",
                //     action: "resetDomain",
                // },
                {
                    title: t2("resetAttributes", 'sysadmin'),
                    action: "resetAttributes",
                },
                {
                    title: t2("language", 'sysadmin') + " (language)",
                    url: "web-dev",
                    submenu: submenu2,
                },
                {
                    title: t2("logout", 'sysadmin'),
                    action: "onLogout",
                },
            ],
        },
    ];

    return menuItems;
}

export default MenuItemsList;
