// See this link for a description of how the bubble component is designed: https://www.developerway.com/posts/react-component-as-prop-the-right-way

import React, { useContext, useEffect, useState } from "react";

import { displayContext } from "../../../common/DisplayContext";
import { userCaseContext } from "../../../admin/contexts/UserCaseContext";
import { swiperContext } from "../../../legal/contexts/SwiperContext";

function TM1BubbleUsePage2({ attributeName, text, Icon, attributeValue }) {
    const {
        t2,
        setBubbleInfoIntensity,
        bubbleState,
        setBubbleState,
        timePrompt,
        setTimePrompt,
    } = useContext(displayContext);


    const {
        selectedOptions,
        setSelectedOptions,
    } = useContext(swiperContext);

    const { userCase, setUserCase } = useContext(userCaseContext);

    function OLD_handleBubbleClick(e, id) {
        const updatedUserCase = userCase.map((attribute) => {
            if (attribute.name === id) {
                return {
                    ...attribute,
                    ["test"]: !bubbleState.find(
                        (bubble) => bubble.name == attributeName
                    )["enabled"],
                };
            } else if (
                ["useOneVersion", "useMultipleVersions"].includes(
                    attribute.name
                ) &&
                bubbleState.find((bubble) => bubble.name == attribute.name)[
                "enabled"
                ]
            ) {
                return { ...attribute, ["test"]: false };
            } else {
                return attribute;
            }
        });

        setUserCase(updatedUserCase);

        if (
            bubbleState.find((bubble) => bubble.name == attributeName)[
            "enabled"
            ]
        ) {
            setBubbleInfoIntensity("intensityPromptTm1");
        } else setBubbleInfoIntensity(attributeName);

        setBubbleState((prevState) =>
            prevState.map((bubble) => {
                if (bubble.name == attributeName) {
                    return { ...bubble, ["enabled"]: !bubble.enabled };
                } else if (
                    ["useOneVersion", "useMultipleVersions"].includes(
                        bubble.name
                    ) &&
                    bubbleState.find((bubble) => bubble.name == attributeName)[
                    "enabled"
                    ] == false
                ) {
                    return { ...bubble, ["enabled"]: false };
                } else return bubble;
            })
        );
    }



    function handleBubbleClick(e, id) {
        // const updatedUserCase = userCase.map((attribute) => {
        //     if (attribute.name === id) {
        //         return {
        //             ...attribute,
        //             ["test"]: !bubbleState.find(
        //                 (bubble) => bubble.name == attributeName
        //             )["enabled"],
        //         };
        //     } else if (
        //         ["useOneVersion", "useMultipleVersions"].includes(
        //             attribute.name
        //         ) &&
        //         bubbleState.find((bubble) => bubble.name == attribute.name)[
        //         "enabled"
        //         ]
        //     ) {
        //         return { ...attribute, ["test"]: false };
        //     } else {
        //         return attribute;
        //     }
        // });


        if (id == "useOneVersion") {
            setOption(2, 0, 1, 'replaceInRow')
        } else if (id == "useMultipleVersions") {
            setOption(2, 0, 2, 'replaceInRow')
        }



        // setUserCase(updatedUserCase);

        if (
            bubbleState.find((bubble) => bubble.name == attributeName)[
            "enabled"
            ]
        ) {
            setBubbleInfoIntensity("intensityPromptTm1");
        } else setBubbleInfoIntensity(attributeName);

        setBubbleState((prevState) =>
            prevState.map((bubble) => {
                if (bubble.name == attributeName) {
                    return { ...bubble, ["enabled"]: !bubble.enabled };
                } else if (
                    ["useOneVersion", "useMultipleVersions"].includes(
                        bubble.name
                    ) &&
                    bubbleState.find((bubble) => bubble.name == attributeName)[
                    "enabled"
                    ] == false
                ) {
                    return { ...bubble, ["enabled"]: false };
                } else return bubble;
            })
        );
    }






    function setOption(ball, row, column, mode) {

        // If this option was not already selected,
        // if in replaceInRow mode, then remove other options in the same ball and row from selectedOptions 
        // else, if in replaceInColum mode, then remove other options in the same column from selectedOptions 
        // else, if in add mode, then just update this option.

        // 1. create copy of the original array of selected options
        // 2. add this option to the array of selected options...
        if (selectedOptions.findIndex(item => (item.ball == ball && item.row == row && item.column == column)) === -1) {

            let newSelectedOptions = [];
            // So, in replace mode, create a new array of the original where we filter away other options set for this column...
            if (mode == 'replaceInRow') {
                newSelectedOptions = selectedOptions.filter(item => !(item.ball === ball && item.row == row))
            }

            else if (mode == 'replaceInColumn') {
                newSelectedOptions = selectedOptions.filter(item => !(item.ball === ball && item.column == column))
            }

            else if (mode == 'add') {
                // ...but in add mode, create a new array as a full copy of the original
                newSelectedOptions = [...selectedOptions];
            }

            // add currentOption to selectedOptions
            newSelectedOptions.push({ "ball": ball, "row": row, "column": column });
            setSelectedOptions(newSelectedOptions);
            // ...else, i.e. this option was already selected, remove it from the array of selected options.
        } else {
            // remove currentOption from selectOptions
            setSelectedOptions(selectedOptions.filter(item => !(item.ball === ball && item.row == row && item.column == column)))
        }
    }











    return (
        <div
            className={
                bubbleState.find((bubble) => bubble.name == attributeName)[
                    "enabled"
                ]
                    ? "bubble-item2 bubble-item-enabled"
                    : "bubble-item2 bubble-item-disabled"
            }
            id={attributeName}
            name={attributeName}
            onClick={(event) => handleBubbleClick(event, attributeName)}
        >
            {typeof Icon != "undefined" ? (
                <Icon size={40} />
            ) : typeof text != "undefined" ? (
                text
            ) : (
                <></>
            )}
        </div>
    );
}

export default TM1BubbleUsePage2;
