import React, { useState, useContext, useEffect } from 'react'
import Button from 'react-bootstrap/Button'

import { displayContext } from '../../common/DisplayContext'
import { swiperContext } from '../contexts/SwiperContext'

import styles from '../styles.module.css'

function TrademarkDesktopChatEmbed() {
    const [chatTextLocal, setChatTextLocal] = useState('')

    // const [{ convertToJSON }] = useChatToJSON()

    const { t2, t3 } = useContext(displayContext)

    const {
        chatText,
        setChatText,
        fetchData,
        fetchedChat,
        setSelectedOptions,
        loading,
        optionDescriptions,
    } = useContext(swiperContext)

    function handleChatTextChange(e) {
        setChatTextLocal(e.target.value)
        setChatText(e.target.value)
        localStorage.setItem('chatText', e.target.value)
    }

    async function handleFetchData() {
        console.log('TrademarkDesktopTextInput - now calling fetchdata')
        localStorage.setItem('chatText', chatTextLocal)
        await fetchData(chatTextLocal)
    }

    useEffect(() => {
        setChatTextLocal(localStorage.getItem('chatText'))
    }, [])

    return (
        <div className={styles.chatColumn}>
            <textarea
                className={styles.chatTextArea}
                name="chatText"
                type="text"
                value={chatTextLocal}
                placeholder={t2('chatInfo', 'trademark')}
                onChange={(e) => handleChatTextChange(e)}
            />

            <div>
                <Button variant="success" size="sm" onClick={handleFetchData}>
                    {t2('checkChat', 'trademark')}
                </Button>{' '}
            </div>
        </div>
    )
}

export default TrademarkDesktopChatEmbed
