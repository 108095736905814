import { useContext } from "react";

import { evaluationContext } from "../contexts/EvaluationContext";
import { userCaseContext } from "../contexts/UserCaseContext";
import { errorContext } from "../contexts/ErrorContext";
import { displayContext } from "../../common/DisplayContext";

function useClearCase() {
    const { userCase, setUserCase } = useContext(userCaseContext);

    const {
        setCurrentCaseId,
        setConclusionLastTrue,
        setNoteLastTrue,
        setExplanation,
        setExplanationLastTrue,
    } = useContext(evaluationContext);

    const { setErrors } = useContext(errorContext);

    const {
        setButtonAcceptDisabled,
        setButtonLetterDisabled,
        setButtonRejectDisabled,
        setDisplayMessage,
        setUseMode,
    } = useContext(displayContext);

    function handleClear() {
        const cloneCaseData = [...userCase];

        cloneCaseData.forEach((attribute) => {
            attribute["referenceValue"] = "";
            attribute["referenceMatch"] = "";
            attribute["test"] = "";
        });

        setUserCase(cloneCaseData);
        console.log("useClearCase - SETTING userCase");

        // Clear the Conclusion field
        setConclusionLastTrue("");

        setExplanationLastTrue("");

        // Clear the Explanation field
        setExplanation([]);

        // Clear the Evalation / Note field
        setNoteLastTrue("");

        setErrors("errors");

        setCurrentCaseId("");

        setButtonLetterDisabled(true);
        setButtonAcceptDisabled(true);
        setButtonRejectDisabled(true);

        setDisplayMessage("");

        setUseMode("read");
    }

    return [
        {
            handleClear: handleClear,
        },
    ];
}

export default useClearCase;
