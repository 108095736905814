// This is dropdown implementation is based on https://www.robinwieruch.de/react-dropdown/

import { useState, useContext, cloneElement } from 'react';

import styles from '../styles.module.css';

import { versionContext } from '../contexts/Context';

function Dropdown1({ attributeName, dropdowns }) {
    const { caseNo, setCaseNo } = useContext(versionContext);

    const [open, setOpen] = useState(false);

    const [dropdownLabel, setDropdownLabel] = useState('Vælg sag...');

    const handleOpen = () => {
        setOpen(!open);
    };

    const handleMenuOne = () => {
        // do something
        console.log('Dropdown1 - item 1');
        // setDropdownLabel('abc-1234');
        setCaseNo('abc-1234');
        setOpen(false);
    };

    const handleMenuTwo = () => {
        // do something
        console.log('Dropdown1 - item 2');
        // setDropdownLabel('def-5678');
        setCaseNo('def-5678');
        setOpen(false);
    };

    const Dropdown = ({ trigger, menu }) => {
        const [open, setOpen] = useState(false);

        const handleOpen = () => {
            setOpen(!open);
        };

        return (
            <div className={styles.dropdownrw}>
                {cloneElement(trigger, {
                    onClick: handleOpen,
                })}
                {open ? (
                    <ul className={styles.menurw}>
                        {menu.map((menuItem, index) => (
                            <li key={index} className={styles.menu_item}>
                                {cloneElement(menuItem, {
                                    onClick: () => {
                                        menuItem.props.onClick();
                                        setOpen(false);
                                    },
                                })}
                            </li>
                        ))}
                    </ul>
                ) : null}
            </div>
        );
    };

    return (
        <Dropdown
            trigger={
                <button className={styles.dropdown_button}>
                    {caseNo !== undefined ? caseNo : null}
                </button>
            }
            menu={[
                <button onClick={handleMenuOne}>abc-1234</button>,
                <button onClick={handleMenuTwo}>def-6789</button>,
            ]}
        />
    );
}

export default Dropdown1;
