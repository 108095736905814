import React, { useContext, useEffect } from "react";

import { displayContext } from "../../common/DisplayContext";
import { domainContext } from "../../admin/contexts/DomainContext";
import { userCaseContext } from "../../admin/contexts/UserCaseContext";
import { evaluationContext } from "../../admin/contexts/EvaluationContext";
// import { swiperContext } from "../contexts/SwiperContext";
import { swiperContext } from "../../legal/contexts/SwiperContext";

import useAuth from "../../admin/hooks/useAuth";
import useFetch from "../../admin/hooks/useFetch";
// import { Config } from "../../config";

function useEvaluate() {
    const { t } = useContext(displayContext);

    const {
        currentDomain,
        setCurrentDomain,
        currentKnowledgeBase,
        setCurrentKnowledgeBase,
    } = useContext(domainContext);

    const {
        optionDescriptions,
        selectedOptions,
        useEvaluationResult, setUseEvaluationResult,
        versionEvaluationResult, setVersionEvaluationResult,
        placeEvaluationResult, setPlaceEvaluationResult,
        denmarkEvaluationResult, setDenmarkEvaluationResult,
        onlineEvaluationResult, setOnlineEvaluationResult,
        contextEvaluationResult, setContextEvaluationResult,
        continuationEvaluationResult, setContinuationEvaluationResult,
        processing, setProcessing,
        aggConclusion, setAggConclusion,
    } = useContext(swiperContext);


    const { token } = useAuth();
    
    // This call to evaluate is used to respond to requests for evaluation
    // from the use, time, intensity and geography pages
    async function fetchEvaluate2(obj, caller) {
        // if (obj.currentKnowledgeBase != "noKnowledgeBase") {
        if (obj.knowledgeBase != "noKnowledgeBase") {
            var endpoint = process.env.REACT_APP_FLASK_API + "evaluate";

            // Note: we only send the Authorization header if token is not null. This is
            // because in the backend we have jwt_required(optional=True) which raises
            // an exception if there is an invalid or expired token, but not if there is
            // no Authorization header. So, we dont send the Authorization header if there
            // is not a valid token.
            return fetch(endpoint, {
                method: "POST",

                headers:
                    token != null
                        ? {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                        }
                        : {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                        },

                body: JSON.stringify(obj, caller),
            })
                .then((res) => res.json())
                .then((responseJson) => {
                    return responseJson
                });
        }
    }


    // Create a user case based on the user's selected options for a certain group
    // by copying all attributes from optionDescriptions except
    // when an option was actually selected by the user, in which case we copy
    // the selected value for that option.

    function createUserCase(group) {
        const groupOptions = optionDescriptions.filter((option) => option.attributeGroup == group)

        return groupOptions.map((option) => {
            const selectedOption = selectedOptions.find(selectedOption => (selectedOption.ball == option.ball && selectedOption.row == option.row && selectedOption.column == option.column))

            if (selectedOption != undefined) {
                return {
                    "id": option.attributeName,
                    "category": "null",
                    "name": option.attributeName,
                    "displayName": option.attributeName,
                    "type": "check",
                    "test": '1'
                };
            } else {
                return {
                    "id": option.attributeName,
                    "category": "null",
                    "name": option.attributeName,
                    "displayName": option.attributeName,
                    "type": "check",
                    "test": '0'
                };
            }
        })
    }

    async function handleEvaluate(knowledgeBase, caller) {
        var obj = {};
        var ruleFound = false;

        // Callers:
        // useAsBrand
        // oneVersion
        // place
        // where
        // how
        // continuity

        setProcessing(true);

        // Create a user case based on the user's selected options
        obj.caseData = createUserCase(caller);

        // obj.knowledgeBase = 'trademark';
        obj.knowledgeBase = knowledgeBase;

        obj.domain = currentDomain;
        obj.category = "";
        obj.requestType = "evaluate";
        // obj.location = `${window.location.hostname}`;

        const evaluationResult = await fetchEvaluate2(obj, caller);

        if (caller == "use") {
            setUseEvaluationResult(evaluationResult);
        } else if (caller == "version") {
            setVersionEvaluationResult(evaluationResult);
        } else if (caller == "place") {
            setPlaceEvaluationResult(evaluationResult);
        } else if (caller == "context") {
            setContextEvaluationResult(evaluationResult);
        } else if (caller == "continuation") {
            setContinuationEvaluationResult(evaluationResult);
        }

        setProcessing(false);

        return evaluationResult
    }

    return [
        {
            handleEvaluate,
        },
    ];
}

export default useEvaluate;
