import React, { useContext, useState } from "react";

import { domainContext } from "../../contexts/DomainContext";
import { displayContext } from "../../../common/DisplayContext";
import { attributesContext } from "../../contexts/AttributesContext";
import { rejectedCaseContext } from "../../contexts/CaseContext";

import styles from '../../styles.module.css';

import useAuth from "../../hooks/useAuth";
import ButtonClear from "./ButtonClear";

import { Button } from "react-bootstrap";

import Messages from "../Messages";
// import ButtonSaveAttribute from "./ButtonSaveAttribute";

// import { Config } from "../../../config";

function NewAttribute() {
    const { currentKnowledgeBase, currentDomain } = useContext(domainContext);

    const {
        setDisplayMessage,
        t,
        t2,
    } = useContext(displayContext);

    const { reloadCurrentAttributes, setReloadCurrentAttributes } = useContext(
        attributesContext
    );

    const {
        reloadRejectedCaseList,
        setReloadRejectedCaseList,
        reloadCaseList,
        setReloadCaseList,
    } = useContext(rejectedCaseContext);

    const { token } = useAuth();

    const storedDomain = localStorage.getItem("domain");

    const [attributeDetails, setAttributeDetails] = useState(
        // "attributeDetails",
        {
            shortName: "",
            displayName: "",
            type: "",
            normmin: "",
            normmax: "",
            groupName: "",
            errors: [],
        }
    );

    const [errors, setErrors] = useState("errors");

    const findFormErrors = () => {
        const {
            shortName,
            displayName,
            type,
            normmin,
            normmax,
            groupName,
        } = attributeDetails;
        const newErrors = {};

        if (!shortName || shortName === "")
            newErrors.shortName = "Kriterie skal udfyldes";
        else if (shortName.length > 100)
            newErrors.shortName =
                "Kriterie skal være mindre end 100 tegn langt";

        if (!displayName || displayName === "")
            newErrors.displayName = "Beskrivelse skal udfyldes";
        else if (displayName.length > 500)
            newErrors.displayName =
                "Beskrivelse skal være mindre 500 tegn langt";

        if (!type || type === "") newErrors.type = "Vælg type fra listen";
        else if (!["text", "integer", "real", "check"].includes(type)) {
            newErrors.type = "Vælg type fra listen";
        }

        // The following line is commented out because we actually do allow the norm fields to be empty
        // if ( !normmin && ['integer', 'float'].includes(type)) newErrors.normmin = 'Norm min cannot be empty!'
        // if (!isNumeric(normmin) && ["integer", "real"].includes(type))
        if (["integer", "real"].includes(type) && !isNumeric(normmin))
            newErrors.normmin = "Norm min must be a number!";

        // The following line is commented out because we actually do allow the norm fields to be empty
        // if ( !normmax && ['integer', 'float'].includes(type)) newErrors.normmax = 'Norm min cannot be empty!'
        // if (!isNumeric(normmax) && ["integer", "real"].includes(type))
        if (["integer", "real"].includes(type) && !isNumeric(normmax))
            newErrors.normmax = "Norm max must be a number!";

        //setErrors(newErrors)
        return newErrors;
    };

    ///////////////////////////////////////////////////////////////////////////////////
    //  isNumeric: check if the input string contains a numeric
    //
    //  Input:
    //    str: the string to be checked
    //
    //  Output: true / false
    //
    ///////////////////////////////////////////////////////////////////////////////////

    function isNumeric(str) {
        var code, i, len;

        for (i = 0, len = str.length; i < len; i++) {
            code = str.charCodeAt(i);
            if (
                !(code > 47 && code < 58) // numeric (0-9)
            ) {
                return false;
            }
        }
        return true;
    }

    ///////////////////////////////////////////////////////////////////////////////////
    //  submitHandler: pre-process creation of attribute. Check if there are errors in the form.
    //
    //  Input:
    //    event: the event object for the 'new attribute' form
    //
    //  Output: calling of the function that creates the
    //  new attribute
    //
    ///////////////////////////////////////////////////////////////////////////////////

    function submitHandler(event) {
        event.preventDefault();

        ///////////////////////////////////////////////////////////////////////////////////
        //  handleCreateAttribute: the user has clicked the 'Create Attribute' button, and we send
        //  - domain
        //  - short name
        //  - display name
        //  - type
        //  - normal minimum value for this attribute
        //  - normal maximum value for this attribute
        //  in a call to the backend server API
        //
        //  Input:
        //    domain: the domain to which the attribute belongs
        //    shortName: the attribute's short name
        //    displayName: the attribute's long name
        //    type: the attribute's type
        //    normmin: the minimum value the attribute could normally take
        //    normmax: the maximum value the attribute could normally take
        //
        //  Output:
        //
        ///////////////////////////////////////////////////////////////////////////////////

        function handleCreateAttribute(
            domain,
            shortName,
            displayName,
            type,
            normmin,
            normmax,
            groupName,
        ) {
            setDisplayMessage({
                messageCode: null,
                messageText: "Processing...",
                // messageText: t('processing'),
                displayCode: 0,
            });

            var obj = {};

            obj.knowledgeBase = currentKnowledgeBase;
            obj.domain = currentDomain;

            obj.shortName = shortName;
            obj.displayName = displayName;
            obj.type = type;
            obj.normmin = normmin;
            obj.normmax = normmax;
            obj.groupName = groupName;

            var endpoint = process.env.REACT_APP_FLASK_API + "createAttribute";

            fetch(endpoint, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify(obj),
            })
                .then((res) => res.json())
                .then((responseJson) => {
                    // Display a message for the result of the transaction
                    setDisplayMessage(Messages(responseJson["message"]));

                    console.log(
                        "NewAttribute - calling setReloadCurrentAttributes"
                    );
                    setReloadCurrentAttributes(!reloadCurrentAttributes);
                    setReloadRejectedCaseList(!reloadRejectedCaseList);
                    setReloadCaseList(!reloadCaseList);
                });
        }

        // Get our new errors
        const newErrors = findFormErrors();
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors);
        } else {
            // No errors! Put any logic here for the form submission!

            // setAttributeDetails(attr); // Copy the updated local variable to the state variable

            handleCreateAttribute(
                storedDomain,
                attributeDetails.shortName,
                attributeDetails.displayName,
                attributeDetails.type,
                attributeDetails.normmin,
                attributeDetails.normmax,
                attributeDetails.groupName,
            );
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////
    //  changeHandler: the attribute is composed by details (name, normmin, normmax, etc).
    //  This function updates the attribute detail that was just changed, by updating the attribute
    //  state variable with the data entered by the user. Also reset the error state variable for
    //  this attribute detail.
    //  The attribute details are eventually checked for errors by the submitHandler.
    //
    //  Input:
    //    event: the event object for the 'new attribute' form
    //
    //  Output: updated state variables for the attribute and attribute errors
    //
    ///////////////////////////////////////////////////////////////////////////////////

    // https://www.pluralsight.com/guides/handling-multiple-inputs-with-single-onchange-handler-react
    function changeHandler(event) {
        var attr = {};

        // If the type is changed to 'text' or 'check', then reset the normmin and normmax values to the empty string
        if (
            ([event.target.name] == "type" && [event.target.value] == "text") ||
            [event.target.value] == "check"
        ) {
            attr = {
                ...attributeDetails, // Copy the complete state variable
                [event.target.name]: event.target.value, // Update the relevant property
                normmin: "",
                normmax: "",
            };
        } else {
            attr = {
                ...attributeDetails, // Copy the complete state variable
                [event.target.name]: event.target.value, // Update the relevant property
            };
        }

        setAttributeDetails(attr); // Copy the updated local variable to the state variable

        // Reset the error indicator for this part of the attribute. Remember that the attribute
        // is checked for errors later by the submitHandler.
        if (!!errors[event.target.name])
            setErrors({
                ...errors,
                [event.target.name]: null,
            });
    }






    return (
        <>
            <div className={styles.attributeNewBox1}>
                <div className={[styles.flexColumn, styles.attributesFlexColumn1].join(' ')}>
                    <a className={styles.labelText2}>{t2('name', 'sysadmin')}:</a>
                    <a className={styles.labelText2}>{t2('longName', 'sysadmin')}:</a>
                </div>

                <div className={styles.flexColumn}>
                    <input
                        type="text"
                        name="shortName"
                        className={styles.mentorInput1}
                        placeholder={t("enterName")}
                        value={attributeDetails.shortName}
                        onChange={(e) => changeHandler(e)}
                        isInvalid={!!errors.shortName}
                    />
                    <input
                        type="text"
                        name="displayName"
                        className={styles.mentorInput1}
                        placeholder={t("enterDescription")}
                        value={attributeDetails.displayName}
                        onChange={(e) => changeHandler(e)}
                        isInvalid={!!errors.displayName}
                    />
                </div>
            </div>

            <div className={styles.attributeNewBox1}>
                <div className={[styles.flexColumn, styles.attributesFlexColumn2].join(' ')}>
                    <a className={styles.labelText2}>{t2('normMin', 'sysadmin')}:</a>
                    <a className={styles.labelText2}>{t2('normMax', 'sysadmin')}:</a>
                </div>

                <div className={styles.flexColumn}>
                    <input
                        type="text"
                        name="normmin"
                        className={
                            attributeDetails.type === "text" ||
                                attributeDetails.type === "check"
                                ? styles.mentorInput1Disabled
                                : styles.mentorInput1
                        }
                        placeholder={t("normMinPlaceholder")}
                        value={attributeDetails.normmin}
                        onChange={(e) => changeHandler(e)}
                        isInvalid={!!errors.normmin}
                        disabled={
                            attributeDetails.type === "text" ||
                                attributeDetails.type === "check"
                                ? true
                                : false
                        }
                    />

                    <input
                        type="text"
                        name="normmax"
                        className={
                            attributeDetails.type === "text" ||
                                attributeDetails.type === "check"
                                ? styles.mentorInput1Disabled
                                : styles.mentorInput1
                        }
                        placeholder={t("normMaxPlaceholder")}
                        value={attributeDetails.normmax}
                        onChange={(e) => changeHandler(e)}
                        isInvalid={!!errors.normmax}
                        disabled={
                            attributeDetails.type === "text" ||
                                attributeDetails.type === "check"
                                ? true
                                : false
                        }
                    />
                </div>
            </div>

            <div className={styles.attributeNewBox1}>
                <div className={[styles.flexColumn, styles.attributesFlexColumn3].join(' ')}>
                    <a className={styles.labelText2}>{t2('type', 'sysadmin')}:</a>
                    <a className={styles.labelText2}>{t2('groupName', 'sysadmin')}:</a>
                </div>

                <div className={styles.flexColumn}>
                    <select
                        name="type"
                        className={styles.mentorInput1}
                        value={attributeDetails.type}
                        onChange={changeHandler}
                        isInvalid={!!errors.type}
                    >
                        <option value="">Select...</option>
                        <option value="text">Text</option>
                        <option value="integer">Integer</option>
                        <option value="real">Float</option>
                        <option value="check">Check</option>
                    </select>

                    <input
                        type="text"
                        name="groupName"
                        className={styles.mentorInput1}
                        placeholder={t2("enterGroup", 'sysadmin')}
                        value={attributeDetails.groupName}
                        onChange={(e) => changeHandler(e)}
                        isInvalid={!!errors.groupName}
                    />
                </div>

            </div>

            <Button
                // className="ms-1 me-1"
                className={styles.standardButton}
                variant="success"
                size="sm"
                onClick={submitHandler}
            >
                {t("save")}
            </Button>

            <ButtonClear setInputValues={setAttributeDetails}/>
        </>
    );
}
export default NewAttribute;
