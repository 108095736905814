import React, { useContext } from 'react';
import i18n from "i18next";

import { displayContext } from '../../common/DisplayContext';
import { AlkaContext } from '../contexts/Context';

import styles from '../styles.module.css';

import KnowledgeBaseDropdown from './KnowledgeBaseDropdown';

import {
    Button,
} from "react-bootstrap";

function Topbar() {

    const {
        t2,
    } = useContext(displayContext);

    const {
        knowledgeBase,
    } = useContext(AlkaContext);

    return (

        <div className={styles.desktopTopbar}>
            <div className={styles.desktopTopbarAppTitle}>
                <span className={styles.desktopProductName}>{t2('AppTitle', knowledgeBase)}</span>
                <span className={styles.desktopCatchline}>{t2('AppByline', knowledgeBase)}</span>
                {/* <span className={styles.desktopCatchline}>REACT_APP_FLASK_API:{process.env.REACT_APP_FLASK_API}</span> */}
            </div>

            {/* <KnowledgeBaseDropdown /> */}

            {/* <div className={styles.languages}>
                <Button
                    className={styles.language}
                    onClick={(e) => i18n.changeLanguage("da")}
                >
                    <span class="fi fi-dk"></span>
                </Button>

                <Button
                    className={styles.language}
                    onClick={(e) => i18n.changeLanguage("en")}
                >
                    <span class="fi fi-gb"></span>
                </Button>
            </div> */}


        </div>
    )
};

export default Topbar;
