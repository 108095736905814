import React, { useContext } from "react";


// ...from TrademarkSwiper - start 

import { swiperContext } from '../contexts/SwiperContext';

// // Import Swiper React components
// import { Swiper, SwiperSlide } from 'swiper/react';

// // Import Swiper styles
// import 'swiper/css';
// import 'swiper/css/pagination';


// ...from TrademarkSwiper - end 

function useSwiper() {
    const {
        activeOptions, setActiveOptions,
        selectedOptions, setSelectedOptions,
        activeSlideSelected, setActiveSlideSelected,
        focus, setFocus,
        addEmptyItemsToList, setAddEmptyItemsToList,
    } = useContext(swiperContext);

    function setOption(ball, row, column, mode) {

        // If this option was not already selected,
        // if in replaceInRow mode, then remove other options in the same ball and row from selectedOptions 
        // else, if in replaceInColum mode, then remove other options in the same column from selectedOptions 
        // else, if in add mode, then just update this option.

        // 1. create copy of the original array of selected options
        // 2. add this option to the array of selected options...
        if (selectedOptions.findIndex(item => (item.ball == ball && item.row == row && item.column == column)) === -1) {

            let newSelectedOptions = [];
            // So, in replace mode, create a new array of the original where we filter away other options set for this column...
            if (mode == 'replaceInRow') {
                newSelectedOptions = selectedOptions.filter(item => !(item.ball === ball && item.row == row))
            }

            else if (mode == 'replaceInColumn') {
                newSelectedOptions = selectedOptions.filter(item => !(item.ball === ball && item.column == column))
            }

            else if (mode == 'add') {
                // ...but in add mode, create a new array as a full copy of the original
                newSelectedOptions = [...selectedOptions];
            }

            // add currentOption to selectedOptions
            newSelectedOptions.push({ "ball": ball, "row": row, "column": column });
            setSelectedOptions(newSelectedOptions);
            setActiveSlideSelected(true);
            // ...else, i.e. this option was already selected, remove it from the array of selected options.
        } else {
            // remove currentOption from selectOptions
            setSelectedOptions(selectedOptions.filter(item => !(item.ball === ball && item.row == row && item.column == column)))
            setActiveSlideSelected(false);
        }
    }

    const handleHorizontalSlideChange = (swiper) => {

        let activeOptionInColumn = activeOptions.find(item => item.ball == focus.ball && item.column == swiper.activeIndex)
        if (activeOptionInColumn === undefined) {
            activeOptionInColumn = { ball: focus.ball, row: 0, column: 0 }
        }

        setFocus({ "ball": focus.ball, "row": activeOptionInColumn.row, "column": swiper.activeIndex });
    };

    const handleVerticalSlideChange = (swiper) => {
        setFocus({ "ball": focus.ball, "row": swiper.activeIndex, "column": focus.column });

        // Update the active option for this column...
        // ...remove current activeOption for this column...
        const newActiveOptions = activeOptions.filter(item => !(item.ball === focus.all && item.column == focus.column))

        // ...add new activeOption for this column
        newActiveOptions.push({ "ball": focus.ball, "row": swiper.activeIndex, "column": focus.column });
        setActiveOptions(newActiveOptions);

        setAddEmptyItemsToList(false);
    };

    return [
        {
            setOption,
            handleHorizontalSlideChange,
            handleVerticalSlideChange,
        },
    ];
}

export default useSwiper;
