import React, { useContext } from 'react';

import { displayContext } from '../../../common/DisplayContext';
import { errorContext } from '../../contexts/ErrorContext';

import styles from '../../styles.module.css';

function NewExplanation() {
    const { newExplanation, setNewExplanation } = useContext(displayContext);

    const { errors } = useContext(errorContext);

    function handleExplanationChange(e) {
        delete errors.newExplanation;
        setNewExplanation(e.target.value);
    }

    return (
        <div className={styles.newNodeTextareaWrapper}>
            <textarea
                name="newExplanationMC"
                type="text"
                value={newExplanation}
                onChange={(e) => handleExplanationChange(e)}
                className={errors.newExplanation ? styles.textareaError : ''}
            />

            {errors.newExplanation && (
                <div className={styles.errorOverlay}>
                    {errors.newExplanation}
                </div>
            )}
        </div>
    );
}

export default NewExplanation;
