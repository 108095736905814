import React, { useContext, useEffect, useState } from "react";
import useAuth from "../../../admin/hooks/useAuth";

import { TiDocumentText } from "react-icons/ti";
import {
    BsBook,
    BsBuilding,
    BsCart4,
    BsDisplay,
    BsNewspaper,
    BsTruck,
    BsShare,
    BsShareFill,
    BsFillCalendarDateFill,
    BsSignpost,
} from "react-icons/bs";
import { MdOutlineAlternateEmail, MdOutlineRadio } from "react-icons/md";
import { RiAdvertisementLine } from "react-icons/ri";
import { FaFileInvoiceDollar, FaNewspaper, FaTruck } from "react-icons/fa";
import { BiHomeHeart, BiHome, BiNews, BiRadio } from "react-icons/bi";
import {
    AiOutlineInteraction,
    AiOutlineMail,
    AiFillBackward,
    AiFillForward,
    AiOutlineFundProjectionScreen,
} from "react-icons/ai";
import { HiOutlineIdentification } from "react-icons/hi";
import { SiSmashingmagazine } from "react-icons/si";
import { TbSocialOff, TbAntenna, TbSocial } from "react-icons/tb";


import VMBubble1 from "../EvaluateCase/Bubble1";

import { domainContext } from "../../../admin/contexts/DomainContext";
import { userCaseContext } from "../../../admin/contexts/UserCaseContext";
import { displayContext } from "../../../common/DisplayContext";
import { GiClown } from "react-icons/gi";

function TM1IntensityBubbles() {
    const { token } = useAuth();
    const {
        setDisplayMessage,
        previousPath,
        setUseMode,
        t2,
        setIsEvaluating,
        bubbleInfo,
        bubbleInfoIntensity,
        // bubbleStateGeography,
    } = useContext(displayContext);

    const { userCase, setUserCase, setCaseStatus } =
        useContext(userCaseContext);

    const { currentKnowledgeBase, currentDomain, setCurrentDomain } =
        useContext(domainContext);

    if (userCase == []) {
        setCurrentDomain(currentDomain);
    }

    // Circle stuff
    // https://stackoverflow.com/questions/72270247/react-calculate-dots-position-in-a-circle

    // Get the media width at runtime: https://www.w3schools.com/howto/howto_js_media_queries.asp

    // In order to change the size of the circle, change the 'diameter' variable.
    const diameter = 50;
    let radius = 0;

    // Convert vh to px: https://stackoverflow.com/questions/49268659/javascript-get-100vh-in-pixels
    let _1vh = Math.round(window.innerHeight / 100);
    function vhToPixels(vh) {
        return Math.round(window.innerHeight / (100 / vh));
    }

    function myFunction(x) {
        if (x.matches) {
            // If media query matches
            radius = 150;
        } else {
            // radius = vhToPixels(70 / 2);
            radius = vhToPixels(diameter / 2);
            // radius = 250;
            // radius =
            //     Math.min(
            //         document.documentElement.clientWidth,
            //         document.documentElement.clientHeight
            //     ) / 3.5;
        }
    }

    var x = window.matchMedia("(max-width: 600px)");
    myFunction(x); // Call listener function at run time
    // x.addListener(myFunction) // Attach listener function on state changes

    // Number of menu-items
    const menuItems = 32;        // <<<<<<<<<<<<<<<<

    function getDegrees(menuItem) {
        return (360 / menuItems) * (menuItem - 1);
    }

    // This function returns the x-coordinate in px relative to the center of the circle
    function getX(degrees) {
        if (degrees >= 0 && degrees < 360) {
            if (degrees > 180 && degrees < 360) {
                degrees = 360 - degrees;
                const radians = Math.PI / (180 / degrees);
                return -Math.sin(radians) * radius;
            }
            const radians = Math.PI / (180 / degrees);
            return Math.sin(radians) * radius;
        } else return 0;
    }

    // This function returns the y-coordinate in px relative to the center of the circle
    function getY(degrees) {
        const radians = Math.PI / (180 / degrees);
        return Math.cos(radians) * radius;
    }

    const style1 = {
        left: scaleX(getX(getDegrees(1))),
        top: scaleY(getY(getDegrees(1)), 1),
    };

    const style2 = {
        left: scaleX(getX(getDegrees(2))),
        top: scaleY(getY(getDegrees(2)), 2),
    };

    const style3 = {
        left: scaleX(getX(getDegrees(3))),
        top: scaleY(getY(getDegrees(3)), 3),
    };

    const style4 = {
        left: scaleX(getX(getDegrees(4))),
        top: scaleY(getY(getDegrees(4)), 4),
    };

    const style5 = {
        left: scaleX(getX(getDegrees(5))),
        top: scaleY(getY(getDegrees(5)), 5),
    };

    const style6 = {
        left: scaleX(getX(getDegrees(6))),
        top: scaleY(getY(getDegrees(6)), 6),
    };

    const style7 = {
        left: scaleX(getX(getDegrees(7))),
        top: scaleY(getY(getDegrees(7)), 7),
    };

    const style8 = {
        left: scaleX(getX(getDegrees(8))),
        top: scaleY(getY(getDegrees(8)), 8),
    };

    const style9 = {
        left: scaleX(getX(getDegrees(9))),
        top: scaleY(getY(getDegrees(9)), 9),
    };

    const style10 = {
        left: scaleX(getX(getDegrees(10))),
        top: scaleY(getY(getDegrees(10)), 10),
    };

    const style11 = {
        left: scaleX(getX(getDegrees(11))),
        top: scaleY(getY(getDegrees(11)), 11),
    };

    const style12 = {
        left: scaleX(getX(getDegrees(12))),
        top: scaleY(getY(getDegrees(12)), 12),
    };

    const style13 = {
        left: scaleX(getX(getDegrees(13))),
        top: scaleY(getY(getDegrees(13)), 13),
    };

    const style14 = {
        left: scaleX(getX(getDegrees(14))),
        top: scaleY(getY(getDegrees(14)), 14),
    };

    const style15 = {
        left: scaleX(getX(getDegrees(15))),
        top: scaleY(getY(getDegrees(15)), 15),
    };

    const style16 = {
        left: scaleX(getX(getDegrees(16))),
        top: scaleY(getY(getDegrees(16)), 16),
    };


    const style17 = {
        left: scaleX(getX(getDegrees(17))),
        top: scaleY(getY(getDegrees(17)), 17),
    };

    const style18 = {
        left: scaleX(getX(getDegrees(18))),
        top: scaleY(getY(getDegrees(18)), 18),
    };

    const style19 = {
        left: scaleX(getX(getDegrees(19))),
        top: scaleY(getY(getDegrees(19)), 19),
    };

    const style20 = {
        left: scaleX(getX(getDegrees(20))),
        top: scaleY(getY(getDegrees(20)), 20),
    };

    const style21 = {
        left: scaleX(getX(getDegrees(21))),
        top: scaleY(getY(getDegrees(21)), 21),
    };

    const style22 = {
        left: scaleX(getX(getDegrees(22))),
        top: scaleY(getY(getDegrees(22)), 22),
    };

    const style23 = {
        left: scaleX(getX(getDegrees(23))),
        top: scaleY(getY(getDegrees(23)), 23),
    };

    const style24 = {
        left: scaleX(getX(getDegrees(24))),
        top: scaleY(getY(getDegrees(24)), 24),
    };

    const style25 = {
        left: scaleX(getX(getDegrees(25))),
        top: scaleY(getY(getDegrees(25)), 25),
    };

    const style26 = {
        left: scaleX(getX(getDegrees(26))),
        top: scaleY(getY(getDegrees(26)), 26),
    };

    const style27 = {
        left: scaleX(getX(getDegrees(27))),
        top: scaleY(getY(getDegrees(27)), 27),
    };

    const style28 = {
        left: scaleX(getX(getDegrees(28))),
        top: scaleY(getY(getDegrees(28)), 28),
    };

    const style29 = {
        left: scaleX(getX(getDegrees(29))),
        top: scaleY(getY(getDegrees(29)), 29),
    };

    const style30 = {
        left: scaleX(getX(getDegrees(30))),
        top: scaleY(getY(getDegrees(30)), 30),
    };

    const style31 = {
        left: scaleX(getX(getDegrees(31))),
        top: scaleY(getY(getDegrees(31)), 31),
    };

    const style32 = {
        left: scaleX(getX(getDegrees(32))),
        top: scaleY(getY(getDegrees(32)), 32),
    };

    const style33 = {
        left: scaleX(getX(getDegrees(33))),
        top: scaleY(getY(getDegrees(33)), 33),
    };






    // This function converts the x position from distance from the center of the circle to
    // distance from the top left corner of the div
    function scaleX(x) {
        // return x + radius;
        return x - vhToPixels(4);
    }

    // This function converts the y position from distance from the center of the circle to
    // distance from the top left corner of the div
    function scaleY(y, menuItem) {
        // return -y + radius;
        return -y - vhToPixels(4);
    }

    return (
        <>
            {/* This div centers the child-div which contains the bubble-circle */}
            <div
                style={{
                    // width: "65vw",

                    // The width of the right hand side is set to the width of the window minus the
                    // width of the sidebar.
                    width:
                        document.documentElement.clientWidth -
                        document.getElementById("nav-sidebar").clientWidth,

                    // backgroundColor: "purple",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                {/* This div positions the bubble-circle wihtin it.
                     however, in itself it does not cente the bubble-circle as a whold, which is why we need the parent div*/}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        height: "60vh",
                        width: "78vh",
                        // backgroundColor: "green",
                        alignContent: "center",
                    }}
                >
                    {/* <TM1GeographyBubbles /> */}
                    <div
                        style={{
                            display: "block",
                            position: "relative",
                            borderRadius: "50%",
                            // backgroundColor: "blue",
                        }}
                    >
                        <div className="tm1-info-bubble">
                            {t2(bubbleInfoIntensity, "varemaerke")}
                        </div>
                        <VMBubble1
                            // text={"butik"}
                            Icon={BsCart4}
                            caller="intensity"
                            text="Goods"
                            attributeName={"contextGoods"}
                            left={scaleX(getX(getDegrees(1)))}
                            top={scaleY(getY(getDegrees(1)), 1)}
                        />

                        <VMBubble1
                            // text={"tv"}
                            Icon={BsDisplay}
                            caller="intensity"
                            text={"Services"}
                            attributeName="contextService"
                            left={scaleX(getX(getDegrees(2)))}
                            top={scaleY(getY(getDegrees(2)), 2)}
                        />

                        <VMBubble1
                            attributeName="contextID"
                            caller="intensity"
                            Icon={HiOutlineIdentification}
                            left={scaleX(getX(getDegrees(3)))}
                            top={scaleY(getY(getDegrees(3)), 3)}
                        />

                        <VMBubble1
                            attributeName="contextNewspaper"
                            caller="intensity"
                            Icon={BsNewspaper}
                            left={scaleX(getX(getDegrees(4)))}
                            top={scaleY(getY(getDegrees(4)), 4)}
                        />

                        <VMBubble1
                            attributeName="contextBrochure"
                            caller="intensity"
                            text={"Brochure"}
                            left={scaleX(getX(getDegrees(5)))}
                            top={scaleY(getY(getDegrees(5)), 5)}
                        />

                        <VMBubble1
                            attributeName="contextDenBlaaAvis"
                            caller="intensity"
                            text={"DBA"}
                            Icon={BsNewspaper}
                            left={scaleX(getX(getDegrees(6)))}
                            top={scaleY(getY(getDegrees(6)), 6)}
                        />

                        <VMBubble1
                            attributeName="contextMoviePoster"
                            caller="intensity"
                            Icon={GiClown}
                            left={scaleX(getX(getDegrees(7)))}
                            top={scaleY(getY(getDegrees(7)), 7)}
                        />

                        <VMBubble1
                            attributeName="contextCatalogoue"
                            caller="intensity"
                            text={"Catalogoue"}
                            left={scaleX(getX(getDegrees(8)))}
                            top={scaleY(getY(getDegrees(8)), 8)}
                        />

                        <VMBubble1
                            attributeName="contextMagazine"
                            caller="intensity"
                            Icon={SiSmashingmagazine}
                            left={scaleX(getX(getDegrees(9)))}
                            top={scaleY(getY(getDegrees(9)), 9)}
                        />

                        <VMBubble1
                            attributeName="contextProjectPresentation"
                            caller="intensity"
                            Icon={AiOutlineFundProjectionScreen}
                            left={scaleX(getX(getDegrees(10)))}
                            top={scaleY(getY(getDegrees(10)), 10)}
                        />

                        <VMBubble1
                            attributeName="contextPressRelease"
                            caller="intensity"
                            text={"Press release"}
                            left={scaleX(getX(getDegrees(11)))}
                            top={scaleY(getY(getDegrees(11)), 11)}
                        />

                        <VMBubble1
                            attributeName="contextCalendar"
                            caller="intensity"
                            Icon={BsFillCalendarDateFill}
                            left={scaleX(getX(getDegrees(12)))}
                            top={scaleY(getY(getDegrees(12)), 12)}
                        />

                        <VMBubble1
                            attributeName="contextSign"
                            caller="intensity"
                            Icon={BsSignpost}
                            left={scaleX(getX(getDegrees(13)))}
                            top={scaleY(getY(getDegrees(13)), 13)}
                        />

                        <VMBubble1
                            attributeName="contextPamphlet"
                            caller="intensity"
                            Icon={BiNews}
                            left={scaleX(getX(getDegrees(14)))}
                            top={scaleY(getY(getDegrees(14)), 14)}
                        />

                        <VMBubble1
                            attributeName="contextAdMagazine"
                            caller="intensity"
                            Icon={SiSmashingmagazine}
                            left={scaleX(getX(getDegrees(15)))}
                            top={scaleY(getY(getDegrees(15)), 15)}
                        />

                        <VMBubble1
                            attributeName="contextTv"
                            caller="intensity"
                            Icon={TbAntenna}
                            left={scaleX(getX(getDegrees(16)))}
                            top={scaleY(getY(getDegrees(16)), 16)}
                        />

                        <VMBubble1
                            attributeName="contextRadio"
                            caller="intensity"
                            Icon={BiRadio}
                            left={scaleX(getX(getDegrees(17)))}
                            top={scaleY(getY(getDegrees(17)), 17)}
                        />
                        <VMBubble1
                            attributeName="contextCompanyName"
                            caller="intensity"
                            text={"Comp name"}
                            left={scaleX(getX(getDegrees(18)))}
                            top={scaleY(getY(getDegrees(18)), 18)}
                        />

                        <VMBubble1
                            attributeName="contextCompanyInvoice"
                            caller="intensity"
                            Icon={FaFileInvoiceDollar}
                            left={scaleX(getX(getDegrees(19)))}
                            top={scaleY(getY(getDegrees(19)), 19)}
                        />

                        <VMBubble1
                            attributeName="contextVan"
                            caller="intensity"
                            Icon={FaTruck}
                            left={scaleX(getX(getDegrees(20)))}
                            top={scaleY(getY(getDegrees(20)), 20)}
                        />

                        <VMBubble1
                            attributeName="contextAdCar"
                            caller="intensity"
                            text={"Ad Car"}
                            left={scaleX(getX(getDegrees(21)))}
                            top={scaleY(getY(getDegrees(21)), 21)}
                        />

                        <VMBubble1
                            attributeName="contextHomepageOwn"
                            caller="intensity"
                            text={"Homepage own"}
                            left={scaleX(getX(getDegrees(22)))}
                            top={scaleY(getY(getDegrees(22)), 22)}
                        />

                        <VMBubble1
                            attributeName="contextHomepageOther"
                            caller="intensity"
                            text={"Homepage other"}
                            left={scaleX(getX(getDegrees(23)))}
                            top={scaleY(getY(getDegrees(23)), 23)}
                        />

                        <VMBubble1
                            attributeName="contextSomeOwn"
                            caller="intensity"
                            Icon={TbSocial}
                            left={scaleX(getX(getDegrees(24)))}
                            top={scaleY(getY(getDegrees(24)), 24)}
                        />

                        <VMBubble1
                            attributeName="contextSomeOther"
                            caller="intensity"
                            Icon={TbSocialOff}
                            left={scaleX(getX(getDegrees(24)))}
                            top={scaleY(getY(getDegrees(24)), 24)}
                        />

                        <VMBubble1
                            attributeName="contextNewsletter"
                            caller="intensity"
                            Icon={FaNewspaper}
                            left={scaleX(getX(getDegrees(25)))}
                            top={scaleY(getY(getDegrees(25)), 25)}
                        />

                        <VMBubble1
                            attributeName="contextEmail"
                            caller="intensity"
                            Icon={MdOutlineAlternateEmail}
                            left={scaleX(getX(getDegrees(26)))}
                            top={scaleY(getY(getDegrees(26)), 26)}
                        />

                        <VMBubble1
                            attributeName="contextPressNewspaper"
                            caller="intensity"
                            text={"Press Newspaper"}
                            left={scaleX(getX(getDegrees(27)))}
                            top={scaleY(getY(getDegrees(27)), 27)}
                        />

                        <VMBubble1
                            attributeName="contextPressInternet"
                            caller="intensity"
                            text={"Press Internet"}
                            left={scaleX(getX(getDegrees(28)))}
                            top={scaleY(getY(getDegrees(28)), 28)}
                        />

                        <VMBubble1
                            attributeName="contextPressBook"
                            caller="intensity"
                            text={"Press Book"}
                            left={scaleX(getX(getDegrees(29)))}
                            top={scaleY(getY(getDegrees(29)), 29)}
                        />

                        <VMBubble1
                            attributeName="contextInternalInvoice"
                            caller="intensity"
                            text={"Internal invoice"}
                            left={scaleX(getX(getDegrees(30)))}
                            top={scaleY(getY(getDegrees(30)), 30)}
                        />

                        <VMBubble1
                            attributeName="contextInternalDiscussion"
                            caller="intensity"
                            text={"Internal discussion"}
                            left={scaleX(getX(getDegrees(31)))}
                            top={scaleY(getY(getDegrees(31)), 31)}
                        />
                        <VMBubble1
                            attributeName="contextInternalSale"
                            caller="intensity"
                            text={"Internal sale"}
                            left={scaleX(getX(getDegrees(32)))}
                            top={scaleY(getY(getDegrees(32)), 32)}
                        />



                    </div>{" "}
                </div>
            </div>
        </>
    );
}

export default TM1IntensityBubbles;


// {!token &&
//     // <div class="box arrow-left" style={{ position: "absolute", left: "15vw", bottom: "13vh" }}>
//     <div class="box arrow-left intensity-bubbles-box1">
//         {t2("demoIntensity", "varemaerke")}
//     </div>
// }