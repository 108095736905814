import React, { createContext, useState, useEffect, useContext } from "react";
// import { TokenContext } from "./TokenContext.js";
import useAuth from "../hooks/useAuth.js";
import { domainContext } from "./DomainContext.js";
// import { Config } from "../../config.js";

export const domainsContext = createContext();

const DomainsContextProvider = ({ children }) => {
    // const { token, setTokenHasExpired } = useContext(TokenContext);
    const { token, setTokenHasExpired } = useAuth();
    const { currentKnowledgeBase } = useContext(domainContext);

    // Knowledge bases
    const [knowledgeBases, setKnowledgeBases] = useState([]);
    const [knowledgeBases1, setKnowledgeBases1] = useState([]);

    const [reloadKnowledgeBases, setReloadKnowledgeBases] = useState(0);
    const [trigger, setTrigger] = useState(false);

    useEffect(() => {
        if (trigger) {
            console.log('Triggered useEffect in context');
            // Your effect logic here
        

            if (token !== null) {
                var endpoint =
                    process.env.REACT_APP_FLASK_API + "getKnowledgeBaseNames";
    
                fetch(endpoint, {
                    method: "POST",
                    headers: {
                        Accept: "application/json",
                        "Content-Type": "application/json",
                        Authorization: "Bearer " + token,
                    },
                })
                    .then((res) => res.json())
                    .then((responseJson) => {
                        setKnowledgeBases(responseJson);
                        setKnowledgeBases1(adjustKnowledgeBasesList(responseJson));
                    });
            } else {
                setKnowledgeBases([]);
                setKnowledgeBases1([]);
            }
        
            // Optionally reset the trigger if needed
            setTrigger(false);
        }
    }, [trigger]);
    




    function adjustKnowledgeBasesList(knowledgeBases) {
        const adjustedKnowledgeBases = knowledgeBases.map((knowledgeBase) => {
            return {
                title: knowledgeBase.knowledgeBase,
                action: "knowledgeBase",
                name: knowledgeBase.knowledgeBase,
            };
        });
        return [adjustedKnowledgeBases];
    }

    // Domains
    const [domains, setDomains] = useState([]);
    const [domains1, setDomains1] = useState([]);

    const [reloadDomains, setReloadDomains] = useState(0);

    function adjustDomainsList(domains) {
        const adjustedDomains = domains.map((domain) => {
            return {
                title: domain.domain,
                action: "domain",
                domain: domain.domain,
            };
        });
        return [adjustedDomains];
    }

    // Get all knowledge base names at start
    useEffect(() => {
        if (token !== null) {
            var endpoint =
                process.env.REACT_APP_FLASK_API + "getKnowledgeBaseNames";

            fetch(endpoint, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                // body: JSON.stringify(obj),
            })
                .then((res) => res.json())
                .then((responseJson) => {
                    setKnowledgeBases(responseJson);
                    setKnowledgeBases1(adjustKnowledgeBasesList(responseJson));
                });
        } else {
            setKnowledgeBases([]);
            setKnowledgeBases1([]);
        }
    }, [token, reloadDomains, reloadKnowledgeBases]);

    // Get all domain names at start
    useEffect(() => {
        if (token !== null && currentKnowledgeBase != "noKnowledgeBase") {
            var obj = {};
            obj.knowledgeBase = currentKnowledgeBase;

            var endpoint = process.env.REACT_APP_FLASK_API + "getDomainNames";

            fetch(endpoint, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify(obj),
            })
                .then((res) => res.json())
                .then((responseJson) => {
                    setDomains(responseJson);
                    setDomains1(adjustDomainsList(responseJson));
                });
        } else {
            setDomains([]);
            setDomains1([]);
        }
    }, [token, reloadDomains, reloadKnowledgeBases]);

    return (
        <domainsContext.Provider
            value={{
                domains,
                domains1,
                knowledgeBases,
                knowledgeBases1,
                reloadDomains,
                setReloadDomains,
                setReloadKnowledgeBases,
                setTrigger,
            }}
        >
            {children}
        </domainsContext.Provider>
    );
};

export default DomainsContextProvider;
