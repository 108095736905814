import React, { createContext, useState, useEffect, useContext } from "react";
import i18n from "../../translations/i18n";

import { domainContext } from "./DomainContext";
import { displayContext } from "../../common/DisplayContext";

import useAuth from "../hooks/useAuth";



export const knowledgeContext = createContext();

const KnowledgeContextProvider = ({ children }) => {
    const {
        currentKnowledgeBase,
        currentDomain
    } = useContext(domainContext);

    const {
        t2,
        currentLanguage
    } = useContext(displayContext);


    const { token } = useAuth();

    const [knowledgebaseType, setKnowledgebaseType] = useState('');

    const [activeButton, setActiveButton] = useState('ruleTree');
    const [buttonKnowledgeRulesDisabled, setButtonKnowledgeRulesDisabled] = useState(true);
    const [buttonKnowledgeCasesDisabled, setButtonKnowledgeCasesDisabled] = useState(true);
    const [buttonKnowledgeAttributesDisabled, setButtonKnowledgeAttributesDisabled] = useState(true);
    const [buttonKnowledgeConditionsDisabled, setButtonKnowledgeConditionsDisabled] = useState(true);
    const [buttonKnowledgeFeaturesDisabled, setButtonKnowledgeFeaturesDisabled] = useState(true);

    const [ruleList, setRuleList] = useState([]);
    const [ruleTree, setRuleTree] = useState([]);
    const [ruleGraph, setRuleGraph] = useState({ nodes: [], edges: [] });

    const [caseList, setCaseList] = useState([])
    const [attributeList, setAttributeList] = useState([]);

    const [reloadRuleList, setReloadRuleList] = useState(false);

    const [currentRuleId, setCurrentRuleId] = useState(1);
    const [currentCaseId, setCurrentCaseId] = useState(1);
    const [currentAttributeId, setCurrentAttributeId] = useState(1);
    const [currentAttributeName, setCurrentAttributeName] = useState('');





    // Load rules, attributes, cases, features, conditions
    // ...should probably load with cache... the table front-end supports that...
    useEffect(() => {
        setRuleGraph({ nodes: [], edges: [] });
        
        var obj = {};

        if (token != null && currentKnowledgeBase != "noKnowledgeBase") {
            obj.knowledgeBase = currentKnowledgeBase;
            obj.domain = currentDomain;
            var endpoint = process.env.REACT_APP_FLASK_API + "getKnowledge";

            fetch(endpoint, {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json",
                    Authorization: "Bearer " + token,
                },
                body: JSON.stringify(obj),
            })
                .then((res) => res.json())
                .then((responseJson) => {
                    setRuleList(responseJson["ruleList"]);              // get the rules
                    setCaseList(responseJson["caseList"]);              // get the cases
                    setAttributeList(responseJson["attributeList"])     // get the attributes
                    setRuleGraph(responseJson["rule_graph"])            // get the graph
                    setKnowledgebaseType(responseJson["kb_type"])
                });
        } else {
            setRuleList([]);
        }
    }, [token, currentDomain, i18n.language, currentKnowledgeBase]);


    function renameKeys(obj, oldKey, newKey) {
        if (Array.isArray(obj)) {
            // If obj is an array, apply renameKeys to each element
            return obj.map(item => renameKeys(item, oldKey, newKey));
        } else if (typeof obj === 'object' && obj !== null) {
            // If obj is an object, recursively call renameKeys for each key-value pair
            let newObj = {};
            for (let key in obj) {
                let value = obj[key];
                newObj[key === oldKey ? newKey : key] = renameKeys(value, oldKey, newKey);
            }
            return newObj;
        } else {
            // Base case: return the value unchanged for non-object values
            return obj;
        }
    }

    function translateRuleTree(obj) {
        // Clone the original object to preserve its structure
        let translatedObj = JSON.parse(JSON.stringify(obj));
        // Rename keys recursively
        translatedObj = renameKeys(translatedObj, 'conclusion', t2('conclusion', 'sysadmin'));
        translatedObj = renameKeys(translatedObj, 'rule', t2('rule', 'sysadmin'));
        return translatedObj;
    }

    return (
        <knowledgeContext.Provider
            value={{
                ruleList,
                setRuleList,
                attributeList,
                setAttributeList,
                currentRuleId,
                setCurrentRuleId,
                caseList, setCaseList,
                currentCaseId, setCurrentCaseId,
                activeButton, setActiveButton,
                buttonKnowledgeRulesDisabled, setButtonKnowledgeRulesDisabled,
                buttonKnowledgeCasesDisabled, setButtonKnowledgeCasesDisabled,
                buttonKnowledgeAttributesDisabled, setButtonKnowledgeAttributesDisabled,
                buttonKnowledgeConditionsDisabled, setButtonKnowledgeConditionsDisabled,
                buttonKnowledgeFeaturesDisabled, setButtonKnowledgeFeaturesDisabled,
                currentAttributeId, setCurrentAttributeId,
                currentAttributeName, setCurrentAttributeName,
                ruleTree, setRuleTree,
                ruleGraph,
                knowledgebaseType,
            }}
        >
            {children}
        </knowledgeContext.Provider>
    );
};

export default KnowledgeContextProvider;
