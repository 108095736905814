import React, { createContext, useState, useEffect, useContext } from "react";

export const kbhKContext = createContext();

const KbhKContextProvider = ({ children }) => {

    const knowledgeBase = 'kbhk1';

    const [aggConclusion, setAggConclusion] = useState('');


    // The options that define a case are located within a three-dimensional
    // 'cube' with the dimensions ball, row, column. A location is represented
    // as an object e.g. {"ball": 0, "row": 0, "column": 0}.

    // Focus: this is the location of the end-user's focus within the 
    // options cube dimensions ball, row and column. Only one item can be 
    // in focus at any given time.

    // Active options: these are the options that are in focus in each 
    // ball/column. Each column can have one active option. This is used to 
    // determine if an item in a list should be highlighted on entry (?).

    // Selected options: these are the options that the end-user has clicked.
    // Options are located within the three-dimensional options cube.

    // Active slide selected (should be renamed to 'focusSelected')
    // is true when the item in focus (option or ball) was selected by 
    // the end-user.

    const [swiper1ActiveSlideHorizontal, setSwiper1ActiveSlideHorizontal] = useState(0);
    const [swiper1ActiveSlideVertical, setActiveSlideVertical] = useState([0, 0, 0, 0]);

    // This is the selected option within each vertical swiper
    const [swiper1SelectedVertical, setSwiper1SelectedVertical] = useState([-1, -1, -1, -1]);

    const [focus, setFocus] = useState({ "ball": 0, "row": 0, "column": 0 })

    const [addEmptyItemsToList, setAddEmptyItemsToList] = useState(false)

    // Two-dimensional array representing the currently active slide in Swiper2

    // The position of the option that is the current focus for the end-user
    const [focusedOption, setFocusedOption] = useState({ "ball": 0, "row": 0, "column": 0 });

    // The description under the swipeball describing the option in focus
    const [optionDescription, setOptionDescription] = useState('')



    const [optionDescriptions1, setOptionDescriptions1] = useState([

        { "attributeName": 'sårdannelser', "attributeShort": "Sårdannelser", "attributeText": 'Sårdannelser med risiko for sår i fodsålen med føleforstyrrelser som følge af sukkersyge, kroniske nervelidelser eller ved svær kompromittering af blodomløbet (kredsløbsinsufficiens) (Hjælpemiddelbekendtgørelsens bilag 1 punkt 2)', "attributeGroup": "group1" },
        { "attributeName": 'forfodsfald', "attributeShort": "Svært forfodsfald", "attributeText": 'Svært forfodsfald ved samtidigt dårligt blodomløb, f.eks. klostillede tæer, hammertæer, deforme ledhoveder på mellemfodsknoglerne (Hjælpemiddelbekendtgørelsens bilag 1 punkt 3)', "attributeGroup": "group1" },
        { "attributeName": 'fodrod-stiv', "attributeShort": "Stiv fodrod", "attributeText": 'Stiv fodrod - medfødte sammenvoksninger (coalitio), svære fejlstillinger efter brud på fodrodsknogler, kroniske ledbetændelser eller leddegigt (Hjælpemiddelbekendtgørelsens bilag 1 punkt 4)', "attributeGroup": "group1" },
        { "attributeName": 'platfod', "attributeShort": "Erhvervet platfod", "attributeText": 'Erhvervet platfod som følge af degeneration (Hjælpemiddelbekendtgørelsens bilag 1 punkt 5)', "attributeGroup": "group1" },
        { "attributeName": 'smerte-aflastning', "attributeShort": "Varigt smertende ar", "attributeText": "Aflastning af varigt smertende ar i fodsålen (Hjælpemiddelbekendtgørelsens bilag 1 punkt 6)", "attributeGroup": "group1" },
        { "attributeName": 'andre-foddeformiteter', "attributeShort": "Tilsvarende foddeformiteter", "attributeText": 'Borger har andre foddeformiteter af tilsvarende sværhedsgrad som indikationer nr 1-6 i bilag i hjælpemiddelbekendtgørelsen', "attributeGroup": "group1" },

        { "attributeName": 'tibialis-posterior-ruptur', "attributeShort": "Ruptur af tibialis posterior", "attributeText": 'Borger har ruptur af tibialis posterior senen', "attributeGroup": "group2" },
        { "attributeName": 'foddeformitet', "attributeShort": "Svær foddeformitet", "attributeText": 'Borger har varig og svær foddeformitet i lovens forstand', "attributeGroup": "group2" },
        { "attributeName": 'ortopædisk-fodtøj', "attributeShort": "Alternativet er ortopæisk fodtøj", "attributeText": 'Generne af foddeformiteten kan afhjælpes og borger ville uden brug af indlæg være henvist til at benytte ortopædisk fodtøj (Hjælpemiddelbekendtgørelsen §11)', "attributeGroup": "group2" },
        { "attributeName": 'varig-funktionsnedsættelse', "attributeShort": "Nedsat funktionsevne", "attributeText": 'Borger har varigt nedsat fysisk eller psykisk funktionsevne (Hjælpemiddelbestemmelse i Serviceloven §1)', "attributeGroup": "group2" },
        { "attributeName": 'erhverv', "attributeShort": "Nødvendigt for erhverv", "attributeText": 'Hjælpemidlet er nødvendigt for, at borger kan udøve et erhverv', "attributeGroup": "group2" },
        { "attributeName": 'lette-tilværelse', "attributeShort": "Lette tilværelsen", "attributeText": 'Kan lette tilværelsen væsentligt', "attributeGroup": "group2" },
        { "attributeName": 'væsentlig-afhjælp', "attributeShort": "Afhjælpe følger", "attributeText": "Hjælpemidlet kan i væsentlig grad afhjælpe de varige følger af borgers nedsatte funktionsevne (Hjælpemiddelbestemmelse i Serviceloven §1, 1)", "attributeGroup": "group2" },
    ])


    // The position of the option that is active in each column. Only one option will be 
    // visible to the end-user as any given time (the activeSlide above), but in every 
    // visited column the last visible option will be the activeOption in that column.
    // When a column is visited any existing activeOption object for that column is 
    // removed from actveOption, and the corresponding object { "ball": 0, "row": 0, "column": 0 }
    // for the now visited option is inserted. 
    const [activeOptions, setActiveOptions] = useState([]);
    const [selectedOptions, setSelectedOptions] = useState([]);
    const [activeSlideSelected, setActiveSlideSelected] = useState(false);



    // Variables receiving the evaluation results from the backend

    const [evaluationResult, setEvaluationResult] = useState({
        caseLastTrue: [{}],
        conclusionLastTrue: "",
        noteLastTrue: "",
        explanation: [],
        explanationTrues: [],
        nodeLast: 0,
        nodeLastTrue: 0,

        nodesEvaluated: [
            {
                conclusion: "",
                cornerstonecaseid: null,
                evaluationResult: true,
                id: 0,
                nextnodefalse: 0,
                nextnodetrue: 0,
                rule: "",
            },
        ],

        referenceCase: [],
        req_data: {
            age: "",
            weight: "",
        },
        message: {
            messageCode: 0,
        }
    });





    const [useEvaluationResult, setUseEvaluationResult] = useState({
        caseLastTrue: [{}],
        conclusionLastTrue: "",
        noteLastTrue: "",
        explanation: [],
        explanationTrues: [],
        nodeLast: 0,
        nodeLastTrue: 0,

        nodesEvaluated: [
            {
                conclusion: "",
                cornerstonecaseid: null,
                evaluationResult: true,
                id: 0,
                nextnodefalse: 0,
                nextnodetrue: 0,
                rule: "",
            },
        ],

        referenceCase: [],
        req_data: {
            age: "",
            weight: "",
        },
    });

    const [versionEvaluationResult, setVersionEvaluationResult] = useState({
        caseLastTrue: [{}],
        conclusionLastTrue: "",
        noteLastTrue: "",
        explanation: [],
        explanationTrues: [],
        nodeLast: 0,
        nodeLastTrue: 0,

        nodesEvaluated: [
            {
                conclusion: "",
                cornerstonecaseid: null,
                evaluationResult: true,
                id: 0,
                nextnodefalse: 0,
                nextnodetrue: 0,
                rule: "",
            },
        ],

        referenceCase: [],
        req_data: {
            age: "",
            weight: "",
        },
    });

    const [placeEvaluationResult, setPlaceEvaluationResult] =
        useState({
            caseLastTrue: [{}],
            conclusionLastTrue: "",
            noteLastTrue: "",
            explanation: [],
            explanationTrues: [],
            nodeLast: 0,
            nodeLastTrue: 0,

            nodesEvaluated: [
                {
                    conclusion: "",
                    cornerstonecaseid: null,
                    evaluationResult: true,
                    id: 0,
                    nextnodefalse: 0,
                    nextnodetrue: 0,
                    rule: "",
                },
            ],

            referenceCase: [],
            req_data: {
                age: "",
                weight: "",
            },
        });

    const [denmarkEvaluationResult, setDenmarkEvaluationResult] =
        useState({
            caseLastTrue: [{}],
            conclusionLastTrue: "",
            noteLastTrue: "",
            explanation: [],
            explanationTrues: [],
            nodeLast: 0,
            nodeLastTrue: 0,

            nodesEvaluated: [
                {
                    conclusion: "",
                    cornerstonecaseid: null,
                    evaluationResult: true,
                    id: 0,
                    nextnodefalse: 0,
                    nextnodetrue: 0,
                    rule: "",
                },
            ],

            referenceCase: [],
            req_data: {
                age: "",
                weight: "",
            },
        });

    const [onlineEvaluationResult, setOnlineEvaluationResult] =
        useState({
            caseLastTrue: [{}],
            conclusionLastTrue: "",
            noteLastTrue: "",
            explanation: [],
            explanationTrues: [],
            nodeLast: 0,
            nodeLastTrue: 0,

            nodesEvaluated: [
                {
                    conclusion: "",
                    cornerstonecaseid: null,
                    evaluationResult: true,
                    id: 0,
                    nextnodefalse: 0,
                    nextnodetrue: 0,
                    rule: "",
                },
            ],

            referenceCase: [],
            req_data: {
                age: "",
                weight: "",
            },
        });

    const [contextEvaluationResult, setContextEvaluationResult] =
        useState({
            caseLastTrue: [{}],
            conclusionLastTrue: "",
            noteLastTrue: "",
            explanation: [],
            explanationTrues: [],
            nodeLast: 0,
            nodeLastTrue: 0,

            nodesEvaluated: [
                {
                    conclusion: "",
                    cornerstonecaseid: null,
                    evaluationResult: true,
                    id: 0,
                    nextnodefalse: 0,
                    nextnodetrue: 0,
                    rule: "",
                },
            ],

            referenceCase: [],
            req_data: {
                age: "",
                weight: "",
            },
        });


    const [continuationEvaluationResult, setContinuationEvaluationResult] =
        useState({
            caseLastTrue: [{}],
            conclusionLastTrue: "",
            noteLastTrue: "",
            explanation: [],
            explanationTrues: [],
            nodeLast: 0,
            nodeLastTrue: 0,

            nodesEvaluated: [
                {
                    conclusion: "",
                    cornerstonecaseid: null,
                    evaluationResult: true,
                    id: 0,
                    nextnodefalse: 0,
                    nextnodetrue: 0,
                    rule: "",
                },
            ],

            referenceCase: [],
            req_data: {
                age: "",
                weight: "",
            },
        });

    return (
        <kbhKContext.Provider
            value={{
                swiper1ActiveSlideHorizontal, setSwiper1ActiveSlideHorizontal,
                swiper1ActiveSlideVertical, setActiveSlideVertical,
                swiper1SelectedVertical, setSwiper1SelectedVertical,

                focusedOption, setFocusedOption,
                selectedOptions, setSelectedOptions,
                activeSlideSelected, setActiveSlideSelected,
                activeOptions, setActiveOptions,
                focus, setFocus,
                optionDescription, setOptionDescription,
                // optionDescriptions, setOptionDescriptions,
                optionDescriptions1, setOptionDescriptions1,
                addEmptyItemsToList, setAddEmptyItemsToList,
                useEvaluationResult, setUseEvaluationResult,
                versionEvaluationResult, setVersionEvaluationResult,
                placeEvaluationResult, setPlaceEvaluationResult,
                denmarkEvaluationResult, setDenmarkEvaluationResult,
                onlineEvaluationResult, setOnlineEvaluationResult,
                contextEvaluationResult, setContextEvaluationResult,
                continuationEvaluationResult, setContinuationEvaluationResult,
                aggConclusion, setAggConclusion,
                knowledgeBase,
                evaluationResult, setEvaluationResult,
            }}
        >
            {children}
        </kbhKContext.Provider>
    );
};

export default KbhKContextProvider;
