import React, { useRef, useState, useEffect } from 'react';
import "../legal.css"

import { Button } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';


// import required modules
import { Pagination } from 'swiper/modules';
// import useWindowSize from '../hooks/useWindowSize';

function Legal() {

    const [infoButton, setInfoButton] = useState(false);

    const [width, setWidth] = useState(window.innerWidth);

    // const [{ isMobile }] = useWindowSize();


    let navigate = useNavigate();

    function toggleInfoButton() {
        setInfoButton(!infoButton)
    }

    function testClick(e) {
        console.log('testClick')
    }

    function gotoLegalTypesDesktop(e) {
        navigate("/LegalTypesDesktop");
    }

    function gotoLegalTypesMobile(e) {
        navigate("/LegalTypesMobile");
    }

    if (width <= 600) {
        return (

            <div className='container-blue'>

                <div className='top-bar'></div>

                <div className='boxlegal-1'>
                    <h2 className='app-title'>legal mobile</h2>
                </div>

                <div className='boxlegal-flex' onClick={gotoLegalTypesMobile}>
                    Log in
                </div>

                <div className='boxlegal-3 description-text'></div>

            </div >
        );

    } else {


        return (

            <div className='container-blue'>

                <div className='top-bar'></div>

                <div className='boxlegal-1'>
                    <h2 className='app-title'>legal desktop</h2>
                </div>

                <div className='boxlegal-flex' onClick={gotoLegalTypesDesktop}>
                    Log in
                </div>

                <div className='boxlegal-3 description-text'></div>

            </div >
        );
    }
}
export default Legal;