import React, { useContext, createContext } from 'react';

import { displayContext } from '../../../common/DisplayContext.js';

import styles from '../../styles.module.css';

import ButtonAddNode from './ButtonAddNode.js';
import NewConclusion from './NewConclusion.js';
import NewExplanation from './NewExplanation.js';
import NewRule from './NewRule.js';
import NewNote from './NewNote.js';
import NewConclusionGroup from './NewConclusionGroup.js';

function AddNode() {
    const {
        previousPath,
        t,
        useMode,
        textAreaNewRuleDisabled,
        newConclusion,
        newExplanation,
    } = useContext(displayContext);

    const findFormErrors = () => {
        const newErrors = {};

        if (newConclusion === '') {
            newErrors.newConclusion = 'Ny konklusion skal udfyldes';
        }

        return newErrors;
    };

    return (
        <div className={styles.rejectCaseNewNodeDetailsWrapper}>
            <NewConclusion />
            <NewExplanation />
            <NewRule />
            <NewNote />
            <NewConclusionGroup />
            <ButtonAddNode />
        </div>
    );
}

export default AddNode;
