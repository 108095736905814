import React, { useContext } from "react";

import { displayContext } from "../../common/DisplayContext";
import { domainContext } from "../../admin/contexts/DomainContext";
import { userCaseContext } from "../../admin/contexts/UserCaseContext";
import { evaluationContext } from "../../admin/contexts/EvaluationContext";
import { tmevaluationContext } from "../../TM/contexts/TMEvaluationContext";

import useAuth from "../../admin/hooks/useAuth";
// import { Config } from "../../config";

function useTMEvaluate() {
    const { t } = useContext(displayContext);

    const { userCase, setUserCase, setCaseStatus } =
        useContext(userCaseContext);

    const {
        currentDomain,
        setCurrentDomain,
        currentKnowledgeBase,
        setCurrentKnowledgeBase,
    } = useContext(domainContext);

    const {
        conclusionLastTrue,
        explanationLastTrue,
        currentCaseId,
        nodeLastTrue,
        setConclusionLastTrue,
        setEvaluationResult,
        setExplanation,
        setExplanationLastTrue,
        setExplanationTrues,
        setNodeLast,
        setNodeLastTrue,
        setNodesEvaluated,
        setNoteLastTrue,
        setReferenceCase,
        setReturnCode,
        // setTMUseEvaluationResult,
    } = useContext(evaluationContext);

    const {
        setTMGeographyEvaluationResult,
        setTMIntensityEvaluationResult,
        setTMTimeEvaluationResult,
        setTMUseEvaluationResult,
        tmGeographyEvaluationResult,
        tmIntensityEvaluationResult,
        tmTimeEvaluationResult,
        tmUseEvaluationResult,
    } = useContext(tmevaluationContext);

    // const {
    //     // tmGeographyEvaluationResult,
    //     // tmIntensityEvaluationResult,
    //     // tmTimeEvaluationResult,
    //     // tmUseEvaluationResult,
    //     setTMGeographyEvaluationResult,
    //     setTMIntensityEvaluationResult,
    //     setTMTimeEvaluationResult,
    //     setTMUseEvaluationResult,
    // } = useContext(tmSplitEvaluationContext);

    const { token } = useAuth();

    function handleEvaluate(e, caller) {
        var obj = {};
        var ruleFound = false;

        // obj.caseData = userCase;

        // Reset the variables which is not relevant for the caller
        if (caller == "use") {
            obj.caseData = userCase.map((attribute) => {
                // if (attribute.name == "use") {
                if (["use", "userType"].includes(attribute.name)) {
                    return attribute;
                } else {
                    return { ...attribute, ["test"]: "" };
                }
            });
        } else if (caller == "time") {
            obj.caseData = userCase.map((attribute) => {
                if (["time", "timeYears"].includes(attribute.name)) {
                    return attribute;
                } else {
                    return { ...attribute, ["test"]: "" };
                }
            });
        } else if (caller == "intensity") {
            obj.caseData = userCase.map((attribute) => {
                // if (attribute.name == "intensity") {

                if (
                    [
                        "intensityShops",
                        "intensityAds",
                        "intensityTv",
                        "intensityRadio",
                        "intensityCompanyName",
                        "intensityPrintMedia",
                        "intensityTransportation",
                        "intensityInternet",
                        "intensityProducts",
                        "intensityInternetOwnHomepage",
                        "intensityInternetOthterHomepage",
                        "intensityInternetSocialMedia",
                        "intensityInternetNewsletter",
                        "intensityInternetEmail",
                        "intensityInternetSocialMediaOwnPosts",
                        "intensityInternetSocialMediaOthersPosts",
                    ].includes(attribute.name)
                ) {
                    return attribute;
                } else {
                    return { ...attribute, ["test"]: "" };
                }
            });
        } else if (caller == "geography") {
            obj.caseData = userCase.map((attribute) => {
                // if (attribute.name == "geography") {
                if (
                    [
                        "geographyLocal",
                        "geographyRegional",
                        "geographyDenmark",
                        "geographyFG",
                        "geographyOutsideDenmark",
                        "geographyUseOnline",
                        "geographyLocalCityTown",
                        "geographyLocalAssociation",
                        "geographyLocalRestaurant",
                        "geographyLocalAmusementPark",
                        "geographyLocalHotel",
                        "geographyFGFaroeIslands",
                        "geographyFGGreenland",
                        "geographyOutsideDenmarkDirection",
                        "geographyPopulation",
                    ].includes(attribute.name)
                ) {
                    return attribute;
                } else {
                    return { ...attribute, ["test"]: "" };
                }
            });
        }

        // Convert checkboxes so that
        //  true = '1'
        //  false and undefined means that we dont send the value

        obj.caseData = obj.caseData.map((attribute) => {
            if (attribute.type == "check" && attribute.test) {
                return { ...attribute, ["test"]: "1" };
            } else return attribute;
        });

        obj.caseId = currentCaseId;
        obj.knowledgeBase = currentKnowledgeBase;
        obj.domain = currentDomain;
        obj.category = "";
        obj.nodeLastTrue = nodeLastTrue;
        obj.requestType = "evaluate";

        var endpoint = process.env.REACT_APP_FLASK_API + "evaluate";

        fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
            },
            body: JSON.stringify(obj),
        })
            .then((res) => res.json())
            .then((responseJson) => {
                if (caller == "use") {
                    setTMUseEvaluationResult(responseJson);
                } else if (caller == "time") {
                    setTMTimeEvaluationResult(responseJson);
                } else if (caller == "intensity") {
                    setTMIntensityEvaluationResult(responseJson);
                } else if (caller == "geography") {
                    setTMGeographyEvaluationResult(responseJson);
                }
            })
            .then(console.log("useTMEvaluate - NOW COMPLETE"));
    }

    return [
        {
            handleEvaluate,
        },
    ];
}

export default useTMEvaluate;
