import { useState, useContext } from "react";

import { AlkaContext } from "../contexts/Context";
import styles from '../styles.module.css';

import useHandleOptionClick from "../hooks/useHandleOptionClick";

function RadioGroup({ group }) {

    const [{ setOption }] = useHandleOptionClick();

    const {
        attributesOpenApp, setAttributesOpenApp,
    } = useContext(AlkaContext);

    const [selectedGroupOption, setSelectedGroupOption] = useState(null);

    // const handleRadioGroupChange1 = (event) => {
    //     const newValue = event.target.value;
    //     setSelectedGroupOption((prevValue) => {
    //         return prevValue === newValue ? null : newValue;
    //     })

    //     setOption(event.target.name, newValue, 'add')
    // };

    function handleRadioGroupChange(groupname, value) {
        const newValue = value;

        const updatedAttributesOpenApp = attributesOpenApp.map((attribute) => {
            // return {
            //     ...attribute,
            //     value: (attribute.name == value && attribute.groupname == groupname && selectedGroupOption != attribute.name) ? '1' : '2'
            // }

            return {
                ...attribute,
                value: (attribute.name == value && attribute.groupname == groupname) ? (attribute.value != '1' ? '1' : '2') : (attribute.groupname == groupname ? '2' : attribute.value)
            }
        })


        setAttributesOpenApp(updatedAttributesOpenApp);

        setSelectedGroupOption((prevValue) => {
            // setOption maintains CaseData. If this option was already set, then in the call to setOption we set it to null, otherwise we set it to 1
            // setOption(e.target.id, prevValue === newValue ? null : '1', 'add')
            return prevValue === newValue ? null : newValue;
        })
    };


    return (
        <div className={styles.desktopOptionGroup}>
            <form>
                {attributesOpenApp
                    .filter(attribute => attribute.groupname == group)
                    .map(attribute => (
                        <div key={attribute.label} className={styles.radioGroupLine}>
                            <label for={attribute.label}> {attribute.label}</label><br></br>
                            <input
                                type="radio"
                                id={attribute.name}
                                // name={group}
                                // value={attribute.name}
                                checked={selectedGroupOption === attribute.name}
                                // onClick={handleRadioGroupChange}
                                onClick={() => handleRadioGroupChange(attribute.groupname, attribute.name)}
                            // groupname={attribute.groupname}
                            />
                        </div>
                    )
                    )}
            </form>
        </div>
    )
}

export default RadioGroup;