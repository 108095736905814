import React, { useState, useContext } from "react";
import {
    Container,
    Form,
    Col,
    Row,
    Button,
    InputGroup,
    ButtonGroup,
} from "react-bootstrap";

import DomainTable from "./DomainTable";

import { displayContext } from "../../../common/DisplayContext.js";
// import { TokenContext } from "../../contexts/TokenContext.js";
import { domainContext } from "../../contexts/DomainContext";
import { errorContext } from "../../contexts/ErrorContext";

import styles from '../../styles.module.css';

import useAuth from "../../hooks/useAuth";

import Messages from "../Messages.js";
import ButtonSaveDomain from "./ButtonSaveDomain";
// import { Config } from "../../../config";
///////////////////////////////////////////////////////////////////////////////////
//  Domain: show the domain page with field for creating a new domain
//  and a table listing the existing domains
//
//  Input:
//    domainNames: existing domain names
//    handleCreateDomain: function
//
//  Output: N/A
//
///////////////////////////////////////////////////////////////////////////////////

function DomainPageMain() {
    // const { domains } = useContext(domainsContext);

    const { setDisplayMessage, t } = useContext(displayContext);
    // const { token } = useContext(TokenContext);
    const { token } = useAuth();
    const { newDomain, setNewDomain } = useContext(domainContext);
    const { errors, setErrors } = useContext(errorContext);

    ///////////////////////////////////////////////////////////////////////////////////
    //  handleCreateDomain: store a new domain in the knowledge base
    //
    //  Input:
    //    domain: name of the domain to be stored
    //
    //  Output:
    //
    ///////////////////////////////////////////////////////////////////////////////////

    // The user has clicked the 'Create Domain' button, and we send
    //  - the name of the new domain
    // in a call to the backend server API
    //
    function HandleCreateDomain(domain) {
        // const {
        //   setDisplayMessage,
        // } = useContext(displayContext)

        setDisplayMessage({
            messageCode: null,
            messageText: "Processing...",
            // messageText: t('processing'),
            displayCode: 0,
        });

        var obj = {};

        obj.domain = domain;

        // var endpoint = FLASK_API + "createDomain";
        var endpoint = process.env.REACT_APP_FLASK_API + "createDomain";
        fetch(endpoint, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
                // Authorization: "Bearer " + token,
            },
            body: JSON.stringify(obj),
        })
            .then((res) => res.json())
            .then((responseJson) => {
                // Display a message for the result of the transaction
                setDisplayMessage(Messages(responseJson["message"]));
            });
    }

    const [state, setState] = useState({
        domain: "",
        lname: "",
        email: "",
        city: "",
        state: "",
        zip: "",
        errors: [],
    });

    ///////////////////////////////////////////////////////////////////////////////////
    //  isAlphaNumeric: verify that the string consists of alphanumeric characters
    //
    //  Input:
    //    str: the string to be verified
    //
    //  Output: true / false
    //
    ///////////////////////////////////////////////////////////////////////////////////

    function isAlphaNumeric(str) {
        var code, i, len;

        for (i = 0, len = str.length; i < len; i++) {
            code = str.charCodeAt(i);
            if (
                !(code > 47 && code < 58) && // numeric (0-9)
                !(code > 64 && code < 91) && // upper alpha (A-Z)
                !(code > 96 && code < 123)
            ) {
                // lower alpha (a-z)
                return false;
            }
        }
        return true;
    }

    ///////////////////////////////////////////////////////////////////////////////////
    //  submitHandler: handle when the domain form is submitted
    //
    //  Input:
    //    event: the domain form event
    //
    //  Output: call handleCreateDomain
    //
    ///////////////////////////////////////////////////////////////////////////////////

    function submitHandler(event) {
        event.preventDefault();
        // event.target.className += " was-validated";

        let errors = [];

        // Verify that the domain is entered by the user and that is one word of alpahnumeric characters
        if (state.domain === "" || !isAlphaNumeric(state.domain)) {
            errors.push("domain");
        }

        setState({ errors: errors });

        if (errors.length > 0) {
            alert("ERROR");
        } else {
            HandleCreateDomain(state.domain);
        }
    }

    ///////////////////////////////////////////////////////////////////////////////////
    //  changeHandler: handle when the domain input field is changed
    //
    //  Input:
    //    event: the domain field event
    //
    //  Output: update the state variable for the domain field
    //
    ///////////////////////////////////////////////////////////////////////////////////

    function changeHandler(event) {
        setState({ [event.target.name]: event.target.value });
    }

    function handleChangeNewDomain(e) {
        setNewDomain(e.target.value);
    }

    return (
        <div className={styles.domainPageMain}>
            <div className="grid-domain-middle1">
                <DomainTable />
            </div>
            <div className="grid-domain-middle2">
                <ButtonSaveDomain />
                <input
                    className="mentor-input1"
                    placeholder={t("domain")}
                    type="text"
                    value={newDomain}
                    onChange={(e) => handleChangeNewDomain(e)}
                    isInvalid={!!errors.newDomain}
                />

                <small
                    id="emailHelp"
                    className="form-text text-muted"
                    style={{
                        marginLeft: "0.5rem",
                    }}
                >
                    {t("domainNameRule")}
                </small>
            </div>
        </div>
    );
}

export default DomainPageMain;
