import React, { useContext } from 'react';

import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { domainContext } from '../../contexts/DomainContext.js';
import { evaluationContext } from '../../contexts/EvaluationContext.js';
import { displayContext } from '../../../common/DisplayContext.js';
import { errorContext } from '../../contexts/ErrorContext.js';
import { userCaseContext } from '../../contexts/UserCaseContext.js';
import { rejectedCaseContext } from '../../contexts/CaseContext.js';
import useAuth from '../../hooks/useAuth.js';

import styles from '../../styles.module.css';

import Messages from '../Messages.js';

function ButtonRemoveAndAdd() {
    const { userCase } = useContext(userCaseContext);

    const { buttonAcceptDisabled, setDisplayMessage, setIsLoading, t } =
        useContext(displayContext);

    const { currentKnowledgeBase, currentDomain } = useContext(domainContext);

    const { currentCaseId, nodeLastTrue, setReturnCode } =
        useContext(evaluationContext);

    const { setErrors } = useContext(errorContext);

    const {
        reloadRejectedCaseList,
        setReloadRejectedCaseList,
        reloadCaseList,
        setReloadCaseList,
    } = useContext(rejectedCaseContext);

    const { token } = useAuth();

    // Error handling start

    const findFormErrors = () => {
        const newErrors = {};

        // if (
        //     typeof currentCaseId == "undefined" ||
        //     !currentCaseId ||
        //     currentCaseId === ""
        // )
        //     newErrors.currentCaseId = "case no må ikke være tom";

        if (
            typeof currentCaseId !== 'undefined' &&
            currentCaseId &&
            currentCaseId !== '' &&
            currentCaseId.length > 30
        )
            newErrors.currentCaseId =
                'Case no skal være mindre end 30 tegn langt';

        return newErrors;
    };

    function submitAcceptHandler(e) {
        e.preventDefault();

        // Get our new errors
        const newErrors = findFormErrors();
        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors);
        } else {
            // No errors! Put any logic here for the form submission!

            handleAccept();
        }
    }
    //   Error handling end

    function handleAccept(e) {
        var obj = {};

        obj.mode = 'accept';
        obj.caseData = userCase;

        if (currentCaseId == undefined) {
            obj.busCaseId = '';
        } else {
            obj.busCaseId = currentCaseId;
        }

        obj.knowledgeBase = currentKnowledgeBase;
        obj.domain = currentDomain;
        obj.category = '';
        obj.nodeLastTrue = nodeLastTrue;
        obj.comment = '';
        obj.status = 'accepted';

        // Do we need this?
        // Clear fields in window
        setDisplayMessage({
            messageCode: null,
            messageText: 'Processing...',
            // messageText: t('processing'),
            displayCode: 1,
        });

        // Do we need this?
        setIsLoading(true);

        var endpoint = process.env.REACT_APP_FLASK_API + 'accept';
        fetch(endpoint, {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
                Authorization: 'Bearer ' + token,
            },
            body: JSON.stringify(obj),
        })
            .then((res) => res.json())
            .then((responseJson) => {
                setReturnCode(responseJson['returnCode']);

                setReloadRejectedCaseList(!reloadRejectedCaseList);

                setReloadCaseList(!reloadCaseList);

                // Interpret the return code sent by the server

                setDisplayMessage(Messages(responseJson['message']));
            });
        setIsLoading(false);
    }

    return (
        <OverlayTrigger
            trigger={['click', 'hover', 'focus']}
            delay={400}
            delayHide={0}
            placement={'auto'}
            overlay={<Tooltip>{t('buttonAcceptTooltip')}</Tooltip>}
        >
            <div>
                <Button
                    className={styles.standardButton}
                    variant="warning"
                    disabled={buttonAcceptDisabled}
                    onClick={submitAcceptHandler}
                >
                    {t('removeAndAdd')}
                </Button>
            </div>
        </OverlayTrigger>
    );
}

export default ButtonRemoveAndAdd;
