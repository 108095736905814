import React, { useContext } from 'react';

import { displayContext } from '../../../common/DisplayContext';
import { errorContext } from '../../contexts/ErrorContext';

import styles from '../../styles.module.css';

function NewConclusion() {
    const { newConclusion, setNewConclusion } = useContext(displayContext);

    const { errors } = useContext(errorContext);

    function handleConclusionChange(e) {
        delete errors.newConclusion;
        setNewConclusion(e.target.value);
    }

    return (
        <div className={styles.newNodeTextareaWrapper}>
            <textarea
                name="newConclusionMC"
                type="text"
                value={newConclusion}
                onChange={(e) => handleConclusionChange(e)}
                className={errors.newConclusion ? styles.textareaError : ''}
            />

            {errors.newConclusion && (
                <div className={styles.errorOverlay}>
                    {errors.newConclusion}
                </div>
            )}
        </div>
    );
}

export default NewConclusion;
