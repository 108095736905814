import React, { useContext } from "react";

import styles from '../../styles.module.css';

import ButtonKnowledge from "./ButtonKnowledge";

function KnowledgeBrowserButtonTopbar() {
    return (
        <div className={styles.evaluateCaseButtonBar}>
            <div>
                {/* <ButtonKnowledge name='ruleTree' /> */}
                <ButtonKnowledge name='rules' />
                <ButtonKnowledge name='cases' />
                <ButtonKnowledge name='attributes' />
                <ButtonKnowledge name='ruleGraph' />
                {/* <ButtonKnowledge name='conditions' />
                <ButtonKnowledge name='features' /> */}
            </div>

            {/* <DisplayMessage /> */}

        </div>
    );
}

export default KnowledgeBrowserButtonTopbar;
