import React from "react";

const NoMatch = () => {
    return (
        <>
            <h2>NoMatch (Public)</h2>
        </>
    );
};

export default NoMatch;
