import React from 'react';

import VersionContextProvider from '../contexts/Context';

import styles from '../styles.module.css';

import Topbar from './Topbar';
import Forebyggende from './Forebyggende';
import Bottombar from './Bottombar';

function KarnovForebyggende() {
    return (
        <VersionContextProvider>
            <div
                className={[styles.desktopContainer, styles.desktopFont].join(
                    ' ',
                )}
            >
                <Topbar />

                <Forebyggende />

                <Bottombar />
            </div>
        </VersionContextProvider>
    );
}

export default KarnovForebyggende;
