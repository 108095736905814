import React from "react";
import { Row, Col, Container } from "react-bootstrap";

import styles from '../../styles.module.css';

import Navbar from "../Settings/Navbar";

import CaseTable from "./CaseTable";

///////////////////////////////////////////////////////////////////////////////////
//
///////////////////////////////////////////////////////////////////////////////////

function CasePageMain() {
    /////////////////////////////////////////////////////////////////////////////////////////////
    // Return
    /////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div className={styles.standardPage}>
            <div className={styles.standardPageTop}>
                <Navbar />
            </div>

            <div className={styles.standardPageMiddle}>
                <CaseTable />
            </div>
        </div>
    );
}

export default CasePageMain;
