import React, { useContext } from "react";

import { displayContext } from "../../../common/DisplayContext";

import styles from '../../styles.module.css';

///////////////////////////////////////////////////////////////////////////////////
//
///////////////////////////////////////////////////////////////////////////////////

function DisplayMessage() {

    const {
        t2,
        displayMessage,
    } = useContext(displayContext);

    return (
        <>
            <div className={displayMessage.messageText != undefined  && displayMessage.messageText != "" ? styles.displayMessage : styles.displayMessageEmpty}>
                {displayMessage.messageText != '' && displayMessage.messageText != undefined &&
                        t2(displayMessage.messageText, 'sysadmin')
                    }
            </div>

        </>
    );
}

export default DisplayMessage;
