// See this link for a description of how the bubble component is designed: https://www.developerway.com/posts/react-component-as-prop-the-right-way

import React, { useContext, useEffect, useState } from "react";

import { displayContext } from "../../../common/DisplayContext";
import { userCaseContext } from "../../../admin/contexts/UserCaseContext";
import { swiperContext } from "../../../legal/contexts/SwiperContext";


import "/node_modules/flag-icons/css/flag-icons.min.css";

function VMBubble1({ attributeName, caller, text, Icon, country, left, top }) {
    const {
        t2,
        bubbleState,
        setBubbleState,
        setBubbleInfoGeography,
        setBubbleInfoIntensity,
    } = useContext(displayContext);

    const {
        optionDescriptions,
        selectedOptions,
        setSelectedOptions,
    } = useContext(swiperContext);


    const { userCase, setUserCase } = useContext(userCaseContext);

    function OLD_handleBubbleClick(e, id) {
        const updatedUserCase = userCase.map((attribute) => {
            if (attribute.name === id) {
                return {
                    ...attribute,
                    ["test"]: !bubbleState.find(
                        (bubble) => bubble.name == attributeName
                    )["enabled"],
                };
            } else {
                return attribute;
            }
        });

        setUserCase(updatedUserCase);

        setBubbleState((prevState) =>
            prevState.map((bubble) => {
                if (bubble.name == attributeName) {
                    return { ...bubble, ["enabled"]: !bubble.enabled };
                } else return bubble;
            })
        );
    }



    function handleBubbleClick(e, id) {
        // const updatedUserCase = userCase.map((attribute) => {
        //     if (attribute.name === id) {
        //         return {
        //             ...attribute,
        //             ["test"]: !bubbleState.find(
        //                 (bubble) => bubble.name == attributeName
        //             )["enabled"],
        //         };
        //     } else {
        //         return attribute;
        //     }
        // });

        const option = optionDescriptions.find((item) => (item.attributeName == id))
        setOption(option.ball, option.row, option.column, 'add')

        // setUserCase(updatedUserCase);

        setBubbleState((prevState) =>
            prevState.map((bubble) => {
                if (bubble.name == attributeName) {
                    return { ...bubble, ["enabled"]: !bubble.enabled };
                } else return bubble;
            })
        );
    }

    function setOption(ball, row, column, mode) {

        // If this option was not already selected,
        // if in replaceInRow mode, then remove other options in the same ball and row from selectedOptions 
        // else, if in replaceInColum mode, then remove other options in the same column from selectedOptions 
        // else, if in add mode, then just update this option.

        // 1. create copy of the original array of selected options
        // 2. add this option to the array of selected options...
        if (selectedOptions.findIndex(item => (item.ball == ball && item.row == row && item.column == column)) === -1) {

            let newSelectedOptions = [];
            // So, in replace mode, create a new array of the original where we filter away other options set for this column...
            if (mode == 'replaceInRow') {
                newSelectedOptions = selectedOptions.filter(item => !(item.ball === ball && item.row == row))
            }

            else if (mode == 'replaceInColumn') {
                newSelectedOptions = selectedOptions.filter(item => !(item.ball === ball && item.column == column))
            }

            else if (mode == 'add') {
                // ...but in add mode, create a new array as a full copy of the original
                newSelectedOptions = [...selectedOptions];
            }

            // add currentOption to selectedOptions
            newSelectedOptions.push({ "ball": ball, "row": row, "column": column });
            setSelectedOptions(newSelectedOptions);
            // ...else, i.e. this option was already selected, remove it from the array of selected options.
        } else {
            // remove currentOption from selectOptions
            setSelectedOptions(selectedOptions.filter(item => !(item.ball === ball && item.row == row && item.column == column)))
        }
    }

    const style1 = {
        left: left,
        top: top,
    };

    function showAttributeInfo(e, attributeName) {
        if (caller == "geography") {
            setBubbleInfoGeography(attributeName);
        } else if (caller == "intensity") {
            setBubbleInfoIntensity(attributeName);
        }
    }

    function hideAttributeInfo(e, attributeName) {
        if (caller == "geography") {
            setBubbleInfoGeography(t2("geographyPromptTm1", "varemaerke"));
        } else if (caller == "intensity") {
            setBubbleInfoIntensity(t2("intensityPromptTm1", "varemearke"));
        }
    }

    return (
        <div
            className={
                bubbleState.find((bubble) => bubble.name == attributeName)[
                    "enabled"
                ]
                    ? "bubble-item bubble-item-enabled"
                    : "bubble-item bubble-item-disabled"
            }
            id={attributeName}
            name={attributeName}
            style={style1}
            onClick={(event) => handleBubbleClick(event, attributeName)}
            onMouseEnter={(event) => showAttributeInfo(event, attributeName)}
            onMouseLeave={(event) => hideAttributeInfo(event, attributeName)}
        >
            {typeof Icon != "undefined" ? (
                <Icon size={40} />
            ) : typeof text != "undefined" ? (
                text
            ) : typeof country != "undefined" ? (
                <span
                    style={{ height: "20px", width: "24px" }}
                    className={country}
                ></span>
            ) : (
                // "abc" + <span className={"fi fi-dk"}></span>
                <></>
            )}
        </div>
    );
}

export default VMBubble1;
