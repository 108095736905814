import React, { useState, useContext, useRef } from "react";

import { displayContext } from "../../../common/DisplayContext.js";

import styles from '../../styles.module.css';

import CaseTable from "./CaseTable.js";
import CaseDetails from "./CaseDetails.js";

function CaseMain() {
    const {
        t,
        t2,
    } = useContext(displayContext);

    return (
        <div className={styles.flexRow}>
            <CaseTable />
            <CaseDetails />
        </div>
    );
}

export default CaseMain;
