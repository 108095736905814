// See https://www.robinwieruch.de/react-table-component/ for the tutorial for creating this type of tables
// https://www.robinwieruch.de/categories/react-table-library/
// samples: https://react-table-library.com/?path=/story/getting-started--demo
// https://github.com/table-library/react-table-library
import React, { useEffect, useContext, useState } from 'react';

import {
    useHistory,
    Redirect,
    BrowserRouter as Router,
    Switch,
    Route,
    useNavigate,
} from 'react-router-dom';

import styles from '../../styles.module.css';

import { useLocation } from 'react-router-dom';

import {
    Table,
    Header,
    HeaderRow,
    HeaderCell,
    Body,
    Row,
    Cell,
} from '@table-library/react-table-library/table';

import { useTheme } from '@table-library/react-table-library/theme';
import defaultTableTheme from '../KnowledgeBrowser/DefaultTableTheme';

// import useHandleGetCase2 from "../EvaluateCase/HandleGetCase2";

import {
    useSort,
    // HeaderCellSort,
} from '@table-library/react-table-library/sort';

import {
    // Sort,
    // SortOptions,
    // SortIconPositions,
    SortToggleType,
} from '@table-library/react-table-library/sort';

import useReactTableTheme from '../../hooks/useReactTableTheme';

import { rejectedCaseContext } from '../../contexts/CaseContext';
import { displayContext } from '../../../common/DisplayContext';
import { evaluationContext } from '../../contexts/EvaluationContext';
import { knowledgeContext } from '../../contexts/KnowledgeContext';
import { navigationContext } from '../../contexts/NavigationContext';

// import useNewNodeFields from "../../../hooks/useNewNodeFields";
import useNewNodeFields from '../../hooks/useNewNodeFields';
import useEvaluate from '../../hooks/useEvaluate';
import useGetCase from '../../hooks/useGetCase';

function RejectedCaseTable() {
    const evaluate = useEvaluate();
    const getCase = useGetCase();

    let navigate = useNavigate();
    let location = useLocation();

    const { knowledgebaseType } = useContext(knowledgeContext);

    const { rejectedCaseList, triggerCaseRejected, setTriggerCaseRejected } =
        useContext(rejectedCaseContext);

    const {
        goToEval,
        setButtonAcceptDisabled,
        setButtonLetterDisabled,
        setButtonSaveNewRuleDisabled,
        setGoToEval,
        setPreviousPath,
        setUseMode,
        t,
        setUIModeRejectCaseMC,
    } = useContext(displayContext);

    const { setPage } = useContext(navigationContext);

    const { currentCaseId, setCurrentCaseId } = useContext(evaluationContext);

    const [{ defaultTheme }] = useReactTableTheme();

    const [{ clearNewNodeFields, enableNewNodeFields, disableNewNodeFields }] =
        useNewNodeFields();

    const [data, setData] = useState({ nodes: rejectedCaseList });

    useEffect(() => {
        setData({ nodes: rejectedCaseList });
        console.log('rejectedCaseList triggered: ', rejectedCaseList);
    }, [rejectedCaseList]);

    defaultTableTheme.Table = `
        height: 100%; 
        width: 100%; 
        align-content: baseline; 
        --data-table-library_grid-template-columns: 50% 50%;
    `;
    const theme = useTheme(defaultTableTheme);

    const handleBusCaseIdFilter = (value) => {
        setData({
            nodes:
                value !== ''
                    ? rejectedCaseList.filter(function (item) {
                          return item.busCaseId.includes(value);
                      })
                    : rejectedCaseList,
        });
    };

    const handleCommentFilter = (value) => {
        setData({
            nodes:
                value !== ''
                    ? rejectedCaseList.filter(function (item) {
                          return item.comment.includes(value);
                      })
                    : rejectedCaseList,
        });
    };

    // const handleTestFilter = (value) => {
    //     setData({
    //         nodes:
    //             value !== ""
    //                 ? userCase.filter(function (item) {
    //                       return item.test.includes(value);
    //                   })
    //                 : userCase,
    //     });
    // };

    const sort = useSort(
        data,
        {
            onChange: onSortChange,
        },
        {
            sortToggleType: SortToggleType.AlternateWithReset,
            sortFns: {
                CaseId: (array) =>
                    array.sort((a, b) => a.caseId.localeCompare(b.caseId)),
                // DisplayName: (array) =>
                //     array.sort((a, b) =>
                //         a.displayName.localeCompare(b.displayName)
                //     ),
                // Test: (array) =>
                //     array.sort((a, b) => a.test.localeCompare(b.test)),
            },
        },
    );

    function onSortChange(action, state) {
        console.log(action, state);
    }

    async function HandleRejectedCase(e) {
        setCurrentCaseId(e.buscaseid);
        setUseMode('write');
        enableNewNodeFields();

        await getCase('this', e.buscaseid);
        evaluate();

        setPreviousPath(location.pathname);

        if (knowledgebaseType == 'sc') {
            navigate('/evaluateCase');
        } else if (knowledgebaseType == 'mc') {
            setUIModeRejectCaseMC(true);
            setPage('rejectedCaseMC');
            navigate('/rejectedCaseMC');
        }
    }

    return (
        <div
            className={[
                styles.standardTableWrapper,
                styles.rejectedCaseTableWrapper,
            ].join(' ')}
        >
            <Table
                data={data}
                theme={theme}
                sort={sort}
                layout={{ custom: true }}
            >
                {(tableList) => (
                    <>
                        <Header>
                            <HeaderRow>
                                <HeaderCell>
                                    {/* caseId */}
                                    {t('caseNo')} <br />
                                    <input
                                        className={styles.caseTableFilter}
                                        type="text"
                                        placeholder="Filter..."
                                        onChange={(event) =>
                                            handleBusCaseIdFilter(
                                                event.target.value,
                                            )
                                        }
                                    />
                                </HeaderCell>

                                <HeaderCell>
                                    {t('comment')} <br />
                                    <input
                                        className={styles.caseTableFilter}
                                        type="text"
                                        placeholder="Filter..."
                                        onChange={(event) =>
                                            handleCommentFilter(
                                                event.target.value,
                                            )
                                        }
                                    />
                                </HeaderCell>
                            </HeaderRow>
                        </Header>

                        <Body>
                            {tableList.map((item) => (
                                <Row
                                    key={item.caseId}
                                    item={item}
                                    onClick={(event) =>
                                        HandleRejectedCase(event)
                                    }
                                >
                                    <Cell>{item.buscaseid}</Cell>
                                    <Cell>{item.comment}</Cell>
                                </Row>
                            ))}
                        </Body>
                    </>
                )}
            </Table>
        </div>
    );
}

export default RejectedCaseTable;
