import React from 'react';

import KbhKContextProvider from '../../contexts/KbhKContext';

import styles from '../../styles.module.css';

import Topbar from '../Topbar';
import Main from '../Main';
import Bottombar from '../Bottombar';

function KbhK() {
    return (
        <KbhKContextProvider>
            <div className={[styles.desktopContainer, styles.desktopFont].join(' ')}>

                <Topbar />

                <Main />

                <Bottombar />

            </div >
        </KbhKContextProvider >
    )
};

export default KbhK;
