import React, { useContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

import { displayContext } from "../../../common/DisplayContext";

import styles from '../../styles.module.css';

import UploadCasesPageMain from "./UploadCasesPageMain";
import HeaderLoggedIn from "../Header/HeaderLoggedIn";
import NavigationSideBar from "../Navigation/NavigationSideBar";

function UploadCasesPage() {
    const { setDisplayMessage } = useContext(displayContext);

    let location = useLocation();

    useEffect(() => {
        setDisplayMessage("");
    }, [location.key]);

    return (
        <div className={styles.page}>
            <div className={styles.pageMain}>
                <NavigationSideBar />
                <UploadCasesPageMain />
            </div>
        </div>
    );
}

export default UploadCasesPage;
