import React, { useContext, useEffect } from "react";


import { domainContext } from "../../contexts/DomainContext";
import { userCaseContext } from "../../contexts/UserCaseContext";
import { displayContext } from "../../../common/DisplayContext";

import styles from '../../styles.module.css';

import { useNavigate, useLocation } from "react-router-dom";

import HeaderLoggedIn from "../Header/HeaderLoggedIn";
import NavigationSideBar from "../Navigation/NavigationSideBar";
import KnowledgeBrowserMain from "./KnowledgeBrowserMain";

import Footer from "../Footer";

///////////////////////////////////////////////////////////////////////////////////
//
///////////////////////////////////////////////////////////////////////////////////

function KnowledgeBrowserPage() {
    const { setDisplayMessage, previousPath, setUseMode } =
        useContext(displayContext);

    // let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        setDisplayMessage("");
    }, [location.key]);

    const { currentDomain, setCurrentDomain } = useContext(domainContext);
    const { userCase, setUserCase, setCaseStatus } =
        useContext(userCaseContext);

    if (userCase == []) {
        setCurrentDomain(currentDomain);
    }

    if (previousPath == "/learn") {
        setUseMode("read");
    }

    return (
        <>
            <div className={styles.page}>
                <div className={styles.pageMain}>
                    <NavigationSideBar />
                    <KnowledgeBrowserMain />
                </div>
            </div>
        </>
    );
}

export default KnowledgeBrowserPage;
