import React, { createContext, useState } from "react";

export const errorContext = createContext();

const ErrorContextProvider = (props) => {
    const [errors, setErrors] = useState("errors");
    const [loginError, setLoginError] = useState("");
    // const [originalPath, setOriginalPath] = useState("");

    return (
        <errorContext.Provider
            value={{
                errors,
                setErrors,
                loginError,
                setLoginError,
                // originalPath,
                // setOriginalPath,
            }}
        >
            {props.children}
        </errorContext.Provider>
    );
};

export default ErrorContextProvider;
