import React, { useState, useContext, useEffect, useCallback, useRef } from 'react';
import { useNavigate } from "react-router-dom";

import { commonContext } from '../../common/CommonContext';
import { displayContext } from '../../common/DisplayContext';
import { attributesContext } from '../../admin/contexts/AttributesContext';
import { errorContext } from '../../admin/contexts/ErrorContext';
import { VlfrdContext as VlfrdContext } from '../contexts/Context';

import 'bootstrap/dist/css/bootstrap.min.css';

import styles from '../styles.module.css';

import useEvaluate from '../hooks/useEvaluate';


import { BsTriangleFill } from "react-icons/bs";
import Button from "react-bootstrap/Button";
import Spinner from 'react-bootstrap/Spinner';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

// import { Dropdown, DropdownButton } from 'react-bootstrap';
import RadioGroup from './RadioGroup';
import CheckboxGroup from './CheckboxGroup';
import Dropdown from './Dropdown';

// import MenuItems from './MenuItems';
// import menuItemsList from './menuItemsList';


import Linkify from "react-linkify";

function Main() {

    const {
        t2,
    } = useContext(displayContext);

    const {
        currentAttributes,
    } = useContext(attributesContext);

    const {
        setErrors,
    } = useContext(errorContext);

    const [{ handleEvaluate }] = useEvaluate();




    const {
        evaluationResult, 
        setEvaluationResult,
        attributesOpenApp, 
        setAttributesOpenApp,
        setUnderretningEvaluationResult,
        setScreeningEvaluationResult,
        groupsEvaluationResult, 
        setGroupsEvaluationResult,
    } = useContext(VlfrdContext);

    const {
        processing,
    } = useContext(commonContext);

    const [infoButton, setInfoButton] = useState(false);
    const [showConclusion, setShowConclusion] = useState(false);

    const [pageCleared, setPageCleared] = useState(true)

    const [selectedOption, setSelectedOption] = useState('Select...');

    const [dropdown, setDropdown] = useState(false);

    let allResult = {}

    let risksResult = {}

    let groupsResult = []

    let result = []


    function handleOptionClick(attributeName, value, mode) {
        setOption2(attributeName, value, mode)
    }


    function setOption2(attributeName, value, mode) {
        setAttributesOpenApp(
            prevData => {
                const updatedData = prevData.map(item => {
                    if (item.name == attributeName) {
                        return {
                            ...item,
                            selected: value != item.value,
                            value: value == item.value ? '' : value,
                        }
                    }
                    return item;
                })
                return updatedData
            }
        )
    }

    const setDropdownOption = (e) => {
        console.log('setDropdownOption: ', e)
        setSelectedOption(e);
    }

    function clear() {
        // setSelectedOptions([]);
        setPageCleared(true);

        setAttributesOpenApp(
            attributesOpenApp.map((option) => {
                return {
                    ...option,
                    "value": option.value = '',
                    "test": option.value = 0,
                    "selected": false,
                };
            })
        )
        setShowConclusion(false);
    }


    function findFormErrors() {
        // const errors = attributesOpenApp.some(attribute => attribute.selected == false);

        const errors = false;
        return errors;
    };


    async function evaluate() {

        setPageCleared(false)
        setShowConclusion(false);

        setUnderretningEvaluationResult({})
        setScreeningEvaluationResult({})
        setGroupsEvaluationResult({})

   
        // setAggConclusion('')
        // setRisksEvaluationResult({})

        // Validate input form
        const errors = findFormErrors();
        // Conditional logic:
        if (errors) {
            // We got errors!
            setErrors(errors);
        } else {
            // No errors! Put any logic here for the form submission!

            // if (
            //     risksResult.conclusionLastTrue == 'passed'
            // )
            //     setAggConclusion('dækket:')
            // else
            //     setAggConclusion('ikke dækket:')

            // Here we evaluate each evaluationGroup in isolation, but all in one call to the backend
            // The evaluationGroup variable is set in context.js...
            
            // result = await handleEvaluate('vlfrd', 'all')
            // result = await handleEvaluate('vlfrd', 'byEvaluationGroup')
            groupsResult = await handleEvaluate('vlfrd', 'byEvaluationGroup')

            setShowConclusion(true);
        }
    }


    // // ////////////////////////////////
    // // Overflow indicator arrow - START
    // // ////////////////////////////////

    useEffect(() => {
        checkOverflow('column1-div');
        // checkOverflow('column2-div');
        // checkOverflow('conclusion-div');

        // // Add scroll event listeners to both divs
        document.getElementById('column1-div').addEventListener('scroll', function () {
            handleScroll('column1-div');
        });

        // document.getElementById('column2-div').addEventListener('scroll', function () {
        //     handleScroll('column2-div');
        // });

        // document.getElementById('conclusion-div').addEventListener('scroll', function () {
        //     handleScroll('conclusion-div');
        // });

    }, []);


    // Function to check for overflow and show/hide indicator
    function checkOverflow(divId) {
        var div = document.getElementById(divId);

        if (div.scrollHeight > div.clientHeight) {
            showIndicator(divId);
        } else {
            hideIndicator(divId);
        }
    }


    // Function to handle scroll events and show/hide indicator based on position
    function handleScroll(divId) {
        var div = document.getElementById(divId);

        console.log(div.scrollTop + div.offsetHeight, ', ', div.scrollHeight)

        // if (div.scrollHeight - div.scrollTop === div.clientHeight) {
        if (div.scrollTop + div.offsetHeight >= div.scrollHeight) {
            hideIndicator(divId);
        } else {
            showIndicator(divId);
        }
    }


    // Function to show indicator
    function showIndicator(divId) {
        var indicator = document.getElementById(divId + 'Indicator');
        indicator.style.display = 'block';
    }


    // Function to hide indicator
    function hideIndicator(divId) {
        var indicator = document.getElementById(divId + 'Indicator');
        indicator.style.display = 'none';
    }


    // // ////////////////////////////////
    // // Overflow indicator arrow - END
    // // ////////////////////////////////




    const onMouseEnter = () => {
        window.innerWidth > 960 && setDropdown(true);
    };

    const onMouseLeave = () => {
        window.innerWidth > 960 && setDropdown(false);
    };

    let ref = useRef();


    const dropdownBtn = document.getElementById("btn");
    const dropdownMenu = document.getElementById("dropdown");
    const toggleArrow = document.getElementById("arrow");




    function handleDropdownOption(e) {
        console.log('handleDropdownOption: ', e)
        setDropdownButtonText(e.id)
    }


    const [dropdownButtonText, setDropdownButtonText] = useState('Select...')
    const [dropdownButton, setDropdownButton] = useState(false)

    function dropDownbuttonVisible() {
        setDropdownButton(true)
        console.log('dropDownbuttonVisible')
    }

    function dropDownbuttonHidden() {
        setDropdownButton(false)
        console.log('dropDownbuttonHidden')

    }
    const [selectedValue, setSelectedValue] = useState(null);

    const handleDropdownChange = (event) => {
        setSelectedValue(event.target.value);
        console.log(event.target.value)

    };


    // const handleMouseEnter = (event) => {
    //     event.target.size = event.target.length;
    // };

    // const handleMouseLeave = (event) => {
    //     event.target.size = 1;
    // };

    const [selectedValue1, setSelectedValue1] = useState(null);

    const handleSelectChange = (event) => {
        const selectedOption = event.target.value;
        setSelectedValue1(selectedOption);
        handleOptionClick(event.target.name, selectedOption, 'add')
    };

    function group(group) {
        return (
            <div className={styles.desktopOptionGroup}>
                {attributesOpenApp.filter(attribute => attribute.groupname == group).map(attribute => {

                    if (attribute.uitype == 'check')

                        return (

                            // <div className={pageCleared || attribute.selected ? [styles.selectionBoxNoErrorOption].join(' ') : [styles.selectionBoxNoErrorOption, styles.selectionBoxErrorOption].join(' ')} >
                            <div className={styles.selectionBoxNoErrorOption}>
                                <OverlayTrigger
                                    delay={400}
                                    delayHide={0}
                                    placement={"auto"}
                                    overlay={<Tooltip>{attribute.displayname}</Tooltip>}
                                >
                                    <div className={styles.selectionBoxAttributeText}>
                                        {attribute.label}
                                    </div>
                                </OverlayTrigger>

                                <div className={styles.selectionBoxRadioButtons}>
                                    <div className={styles.desktopSelectOption} onClick={() => handleOptionClick(attribute.name, '1', 'add')}>
                                        <input
                                            type="checkbox"
                                            name="yes"
                                            className={styles.checkboxToRadio}
                                            checked={attribute.value == '1'}
                                        />
                                        {t2("yes", "vlfrd")}
                                    </div>

                                    <div className={styles.desktopSelectOption} onClick={() => handleOptionClick(attribute.name, '2', 'add')}>
                                        <input
                                            type="checkbox"
                                            className="checkbox-to-radio"
                                            checked={attribute.value == '2'}
                                        />
                                        {t2("no", "vlfrd")}
                                    </div>
                                </div>
                            </div>
                        )

                    else if (attribute.uitype == 'dropdown')

                        return (
                            // <div className={pageCleared || attribute.selected ? [styles.selectionBoxNoErrorOption].join(' ') : [styles.selectionBoxNoErrorOption, styles.selectionBoxErrorOption].join(' ')} >
                            <div className={styles.selectionBoxNoErrorOption}>

                                <div className={styles.dropdownLine}>
                                    <OverlayTrigger
                                        delay={400}
                                        delayHide={0}
                                        placement={"auto"}
                                        overlay={<Tooltip>{attribute.displayname}</Tooltip>}
                                    >
                                        <div className={styles.selectionBoxAttributeText}>
                                            {attribute.displayname}
                                        </div>
                                    </OverlayTrigger>

                                    <div className={styles.dropdownContainer}>
                                        <select
                                            className={styles.dropdown}
                                            value={selectedValue}
                                            name={attribute.name}
                                            onChange={handleSelectChange}
                                        >
                                            <option value="select">{t2('selectAnOption', 'vlfrd')}</option>
                                            <option value="ægtefælle">Ægtefælle / samlever</option>
                                            <option value="husstand">Medlem af husstanden</option>
                                            <option value="bruger">Den faste bruger</option>
                                            <option value="anden">Ingen af ovenstående</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        )


                })}
            </div>
        )
    }



    // function handleCheckboxGroupChange(e) {
    //     console.log('handleRadioGroupChange: ', e.target.id)
    // }

    // function checkboxGroup(group) {
    //     return (
    //         <div className={styles.desktopOptionGroup}>
    //             <form onChange={handleCheckboxGroupChange}>
    //                 {attributesOpenApp.filter(attribute => attribute.groupname == group)
    //                     .map(attribute => (
    //                         <div key={attribute.label} className={styles.radioGroupLine}>
    //                             <label for={attribute.label}> {attribute.label}</label><br></br>
    //                             <input type="checkbox" id={attribute.name} name={group} value="option1" />
    //                         </div>
    //                     )
    //                     )}
    //             </form>
    //         </div>
    //     )
    // }






    function isEmptyObject(obj) {
        return obj && typeof obj === 'object' && Object.keys(obj).length === 0;
    }



    function resultForEvaluationGroup(evaluationGroupResults, group) {

        if (!isEmptyObject(evaluationGroupResults)) {
            // Convert object of objects to array of objects
            const evaluationGroupResultsArray = Object.values(evaluationGroupResults);
            const filteredEvaluationGroupResults = evaluationGroupResultsArray.filter(obj => obj.group === group)[0];

            return (
                <>
                    {
                        filteredEvaluationGroupResults.explanationLastTrue != '' && filteredEvaluationGroupResults.explanationLastTrue != null ?

                        <div>
                            <div className={
                                filteredEvaluationGroupResults.conclusionLastTrue == 'passed' ?
                                    'desktop-explanation-box desktop-explanation-approved'
                                    : 'desktop-explanation-box desktop-explanation-rejected'
                            }
                            >
                        
                                <Linkify
                                    componentDecorator={(
                                        decoratedHref,
                                        decoratedText,
                                        key
                                    ) => (
                                        <a
                                            target="blank"
                                            href={decoratedHref}
                                            key={key}
                                        >
                                            {t2("labelLink", "vlfrd")}
                                        </a>
                                    )}
                                >
                                    {t2(filteredEvaluationGroupResults.explanationLastTrue, "vlfrd")}
                                </Linkify>
                            </div>
                            <div>
                            {/* {t2(filteredEvaluationGroupResults.conclusionLastTrue, "vlfrd")} */}
                            
                            <pre style={{ whiteSpace: 'pre-wrap' }}>{filteredEvaluationGroupResults.conclusionLastTrue}</pre>
                            </div>
                        </div>




                            : ''
                    }
                </>
            )
        }
    }











    function resultForAll(evaluationResult) {

        if (!isEmptyObject(evaluationResult)) {
            // Convert object of objects to array of objects

            return (
                <>
                    {
                        evaluationResult.explanationLastTrue != '' ?

                            <div className={
                                evaluationResult.conclusionLastTrue == 'passed' ?
                                    'desktop-explanation-box desktop-explanation-approved'
                                    : 'desktop-explanation-box desktop-explanation-rejected'
                            }
                            >
                                <Linkify
                                    componentDecorator={(
                                        decoratedHref,
                                        decoratedText,
                                        key
                                    ) => (
                                        <a
                                            target="blank"
                                            href={decoratedHref}
                                            key={key}
                                        >
                                            {t2("labelLink", "vlfrd")}
                                        </a>
                                    )}
                                >
                                    {t2(evaluationResult.explanationLastTrue, "vlfrd")}
                                </Linkify>
                            </div>

                            

                            : ''
                    }
                </>
            )
        }
    }

    const førerDropdown = ['vælg...', 'ægtefælle', 'husstand', 'bruger', 'anden']

    return (
        <>
            <div>
                {attributesOpenApp &&
                    <div className={styles.desktopMain}>

                        <div className={styles.desktopColumn1}>
                            <div id='column1-divIndicator'><BsTriangleFill className={[styles.desktopOverflowIcon, styles.desktopOverflowIconColumn1].join(' ')} /></div>
                            <div id='column1-div' className={styles.desktopNoErrorBorder} >

                                <p className={styles.groupHeading}>{t2('underretning', 'vlfrd')}</p>
                                <CheckboxGroup group="underretning" />

                                {/* <p className={styles.groupHeading}>{t2('screening', 'vlfrd')}</p>
                                <CheckboxGroup group="screening" /> */}

                                {/* <p className={styles.groupHeading}>{t2('circumstances', 'vlfrd')}</p>
                                <CheckboxGroup group="uansvarlig" /> */}

                            </div >
                        </div >

                        {/* <div className={styles.desktopColumn2}>
                            <div id='column2-divIndicator'><BsTriangleFill className={[styles.desktopOverflowIcon, styles.desktopOverflowIconcolumn2].join(' ')} /></div>
                            <div id='column2-div' className={styles.desktopNoErrorBorder} >

                                <p className={styles.groupHeading}>{t2('driver', 'vlfrd')}</p>
                                <Dropdown attributeName='fører' dropdowns={førerDropdown} />


                                <p className={styles.groupHeading}>{t2('insured', 'vlfrd')}</p>
                                <CheckboxGroup group='forsikrede' />

                            </div >
                        </div > */}


                        {/* Conclusion column */}
                        <div className={[styles.desktopColumn2, styles.desktopColumnLast].join(' ')}>

                            <div className={styles.desktopConclusionHeading}>
                                <div>
                                    <Button
                                        variant="success"
                                        size="sm"
                                        onClick={evaluate}
                                    >
                                        {t2('check', 'vlfrd')}
                                    </Button>

                                    {' '}

                                    <Button variant="danger" size="sm" onClick={clear}>{t2('clear', 'vlfrd')}</Button>
                                </div>
                            </div>

                            <div>
                                <div className={showConclusion ? 'desktop-show-conclusions' : 'desktop-hide-conclusions'}>

                                    <div id='conclusion-divIndicator'><BsTriangleFill className='desktop-overflow-icon desktop-overflow-icon-conclusion' /></div>

                                    <div id='conclusion-div' className='desktop-explanation-list'>
                                        {/* {resultForAll(evaluationResult)} */}
                                        
                                        {resultForEvaluationGroup(groupsEvaluationResult, 1)}
                                        {resultForEvaluationGroup(groupsEvaluationResult, 2)}
                                        {resultForEvaluationGroup(groupsEvaluationResult, 3)}
                                        {resultForEvaluationGroup(groupsEvaluationResult, 4)}
                                        {resultForEvaluationGroup(groupsEvaluationResult, 5)}
                                        {resultForEvaluationGroup(groupsEvaluationResult, 6)}
                                    
                                    
                                    </div>
                                </div>

                                {processing &&
                                    <div style={{ display: !showConclusion ? 'flex' : 'none' }} className='desktop-spinner-container'>
                                        <div className='desktop-spinner'>
                                            <Spinner
                                                animation="border"
                                                variant="secondary"
                                            />
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div >
                }
            </div >
        </>
    )
};

export default Main;










