import React, { useRef, useState, useContext, useEffect } from 'react';
// import "../legal.css"

import { Button } from "react-bootstrap";

import { swiperContext } from '../contexts/SwiperContext';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import useSwiper from '../hooks/useSwiper';

// Import pictures
// import background from './images/background.png';
import swiperball from '../images/swiperball.png'
import swiperballSelected from '../images/swiperball-selected.png'

import thumbsUp from '../images/thumbs-up1.png'
import thumbsDown from '../images/thumbs-down1.png'

import { useNavigate } from "react-router-dom";

function TrademarkSwiper2() {

    const [{ setOption,
        handleHorizontalSlideChange,
        handleVerticalSlideChange,
    }] = useSwiper();

    let navigate = useNavigate();

    const ball = 2;
    const column = 0;

    const {
        focusedOption, setFocusedOption,
        selectedOptions, setSelectedOptions,
        activeOptions, setActiveOptions,
        activeSlideSelected, setActiveSlideSelected,
        focus, setFocus,
        optionDescription, setOptionDescription,
    } = useContext(swiperContext);

    if (focus.ball != ball) {
        setFocus({ "ball": ball, "row": 0, "column": 0 })
    }
    // (selectedOptions.filter(item => item.ball == focus.ball && item.row == focus.ball && item.column == focus.column) != -1)



    const [ballColorSelected, setBallColorSelected] = useState(false);

    // function setOption(ball, row, column) {

    //     // If this option was not already selected
    //     // 1. remove other selected option in the same column
    //     // 2. add this option to the array of selected options...
    //     if (selectedOptions.findIndex(item => (item.ball == ball && item.row == row && item.column == column)) === -1) {
    //         // const newSelectedOptions = selectedOptions.slice();

    //         // remove other options in the same column from selectedOptions
    //         const newSelectedOptions = selectedOptions.filter(item => !(item.ball === ball && item.column == column))

    //         // add currentOption to selectedOptions
    //         newSelectedOptions.push({ "ball": ball, "row": row, "column": column });
    //         setSelectedOptions(newSelectedOptions);
    //         // setBallColorSelected(true);
    //         setActiveSlideSelected(true);
    //         // ...else, i.e. this option was already selected, remove it from the array of selected options.
    //     } else {
    //         // remove currentOption from selectOptions
    //         setSelectedOptions(selectedOptions.filter(item => !(item.ball === ball && item.row == row && item.column == column)))
    //         // setBallColorSelected(false);
    //         setActiveSlideSelected(false);
    //     }
    // }

    // // function checkBallColor(ball, row, column) {
    // //     setBallColorSelected(selectedOptions.findIndex(item => (item.ball === ball && item.row == row && item.column == column)) != -1)
    // // }

    // const handleHorizontalSlideChange = (swiper) => {
    //     setFocusedOption({ "ball": ball, "row": focusedOption.row, "column": swiper.activeIndex });
    //     // checkBallColor(ball, swiper.activeIndex, focusedOption.row)

    //     // setActiveSlideHorizontal(swiper.activeIndex);
    //     // checkBallColor(swiper.activeIndex, activeSlideVertical[swiper.activeIndex])
    //     // console.log('ActiveSlideHorizontal:ActiveSlideVertical: ', activeSlideHorizontal, ':', activeSlideVertical[activeSlideHorizontal]);
    // };

    // // const handleVerticalSlideChange = (swiper) => {
    // //     setFocusedOption({ "ball": ball, "row": swiper.activeIndex, "column": focusedOption.column });
    // //     checkBallColor(ball, swiper.activeIndex, focusedOption.column)
    // // };


    // const handleVerticalSlideChange = (swiper) => {
    //     // setFocusedOption({ "ball": ball, "row": swiper.activeIndex, "column": focusedOption.column });
    //     setFocus({ "ball": ball, "row": swiper.activeIndex, "column": focus.column });

    //     // Update the active option for this columns...
    //     // ...remove current activeOption for this column...
    //     const newActiveOptions = activeOptions.filter(item => !(item.ball === ball && item.column == column))

    //     // ...add new activeOption for this column
    //     newActiveOptions.push({ "ball": ball, "row": swiper.activeIndex, "column": column });
    //     setActiveOptions(newActiveOptions);
    //     // checkBallColor(ball, swiper.activeIndex, focusedOption.column)
    // };

    // useEffect(() => {
    //     setActiveSlideSelected(selectedOptions.findIndex(item => (item.ball == focusedOption.ball && item.row == focusedOption.row && item.column == focusedOption.column)) != -1);
    // }, [focusedOption]);


    useEffect(() => {
        setActiveSlideSelected(selectedOptions.findIndex(item => (item.ball == focus.ball && item.row == focus.row && item.column == focus.column)) != -1);
    }, [focus]);



    function gotoNext(e) {
        setFocus({ "ball": 3, "row": 0, "column": 0 });
        navigate("/TrademarkSwiper3");
    }

    return (
        <div className='container'>
            <div className='top-bar'></div>
            <div className='boxlegal-1'>
                <h2 className='app-title'>legal</h2>
            </div>


            <div className='boxlegal-flex'>
                {/* {ballColorSelected ?
                    <img src={swiperballSelected} className='swipe-ball' alt="ball" /> : <img src={swiperball} className='swipe-ball' alt="ball" />}
                <div className='centered'> */}

                {activeSlideSelected ?
                    <img src={swiperballSelected} className='swipe-ball' alt="ball" /> : <img src={swiperball} className='swipe-ball' alt="ball" />}
                <div className='centered'>

                    <Swiper
                        className="my-swiper-h"
                        direction={'horizontal'}
                        spaceBetween={50}
                        onSlideChange={handleHorizontalSlideChange}
                    >

                        <SwiperSlide className='swiperSlideTitle1'>swipe & vælg</SwiperSlide>

                        <SwiperSlide>
                            <div>
                                <img src={thumbsUp} className='swiper-icon' alt='' onClick={() => setOption(ball, 0, 1, 'replaceInRow')} />
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div>
                                <img src={thumbsDown} className='swiper-icon' alt='' onClick={() => setOption(ball, 0, 2, 'replaceInRow')} />
                            </div>
                        </SwiperSlide>

                    </Swiper>

                </div>
            </div >

            <div className='boxlegal-3 page-description'>
                {/* Bruger du dit kendetegn uden ændringer? */}
                {optionDescription}
            </div>

            <div className='info-button-overlay'></div>

            <div className='boxlegal-4 
            '>
                <div className='myprogress'>45%</div>
                <div className='button-next' onClick={gotoNext}>Næste</div>
            </div>

        </div >
    );

}
export default TrademarkSwiper2;