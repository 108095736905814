import React, { useRef, useState, useContext, useEffect } from 'react';
// import "../legal.css"

import { swiperContext } from '../contexts/SwiperContext';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import useSwiper from '../hooks/useSwiper';

import TrademarkSwiperList from './TrademarkSwiperList';

// Import pictures
// import background from './images/background.png';
import swiperball from '../images/swiperball.png'
import swiperballSelected from '../images/swiperball-selected.png'

import howRetail from '../images/how-retail.png'
import howAds from '../images/how-ads.png'
import howTv from '../images/how-tv.png'
import howRadio from '../images/how-radio.png'
import howCompany from '../images/how-company.png'
import howTransportation from '../images/how-transportation.png'
import howHomepage from '../images/how-homepage.png'
import howSome from '../images/how-some.png'
import howNewsletter from '../images/how-newsletter.png'
import howEmail from '../images/how-email.png'
import howPress from '../images/how-press.png'
import howInternal from '../images/how-press.png'

import { useNavigate } from "react-router-dom";

function TrademarkSwiper4() {

    const [{ setOption,
        handleHorizontalSlideChange,
        handleVerticalSlideChange,
    }] = useSwiper();

    let navigate = useNavigate();

    const ball = 4;

    const {
        focusedOption, setFocusedOption,
        activeOptions, setActiveOptions,
        selectedOptions, setSelectedOptions,
        activeSlideSelected, setActiveSlideSelected,
        focus, setFocus,
        optionDescription,
    } = useContext(swiperContext);


    if (focus.ball != ball) {
        setFocus({ "ball": ball, "row": 0, "column": 0 })
    }


    const itemsRetail = [
        { text: 'varer' },
        { text: 'ydelser' },

    ]

    const itemsAds = [
        { text: 'adgangskort' },
        { text: 'aviser' },
        { text: 'brochurer' },
        { text: 'Den Blå Avis' },
        { text: 'filmplakater' },
        { text: 'kataloger' },
        { text: 'magasiner' },
        { text: 'projektpræsentation' },
        { text: 'pressemeddelelse' },
        { text: 'reklamekalender' },
        { text: 'skilte' },
        { text: 'tidsskrifter' },
        { text: 'tilbudsaviser' },
    ]

    const itemsCompany = [
        { text: 'selskabsnavn' },
        { text: 'selskabsfaktura' },
    ]

    const itemsTransportation = [
        { text: 'varebil' },
        { text: 'reklamebil' },
    ]

    const itemsHomepage = [
        { text: 'egen hjemmeside' },
        { text: 'andres hjemmeside' },
    ]

    const itemsSome = [
        { text: 'egne opslag' },
        { text: 'andres opslag' },
    ]

    const itemsPress = [
        { text: 'aviser' },
        { text: 'internet' },
        { text: 'bøger' },
    ]

    const itemsInternal = [
        { text: 'nterne fakturaer' },
        { text: 'interne drøftelser' },
        { text: 'internt salg' },
    ]





    // function setOption(ball, row, column) {

    //     // If this option was not already selected
    //     // 1. remove other selected option in the same column
    //     // 2. add this option to the array of selected options...
    //     if (selectedOptions.findIndex(item => (item.ball == ball && item.row == row && item.column == column)) === -1) {
    //         // const newSelectedOptions = selectedOptions.slice();

    //         // remove other options in the same column from selectedOptions
    //         const newSelectedOptions = selectedOptions.filter(item => !(item.ball === ball && item.column == column))

    //         // add currentOption to selectedOptions
    //         newSelectedOptions.push({ "ball": ball, "row": row, "column": column });
    //         setSelectedOptions(newSelectedOptions);
    //         setActiveSlideSelected(true);
    //         // ...else, i.e. this option was already selected, remove it from the array of selected options.
    //     } else {
    //         // remove currentOption from selectOptions
    //         setSelectedOptions(selectedOptions.filter(item => !(item.ball === ball && item.row == row && item.column == column)))
    //         setActiveSlideSelected(false);
    //     }
    // }

    // // function optionSelected(ball, row, column) {
    // //     return selectedOptions.findIndex(item => (item.ball === ball && item.row == row && item.column == column)) != -1
    // // }

    // const handleHorizontalSlideChange = (swiper) => {

    //     let activeOptionInColumn = activeOptions.find(item => item.ball == ball && item.column == swiper.activeIndex)
    //     if (activeOptionInColumn === undefined) {
    //         activeOptionInColumn = { ball: ball, row: 0, column: 0 }
    //     }

    //     // setFocusedOption({ "ball": ball, "row": activeOptionInColumn.row, "column": swiper.activeIndex });
    //     setFocus({ "ball": ball, "row": activeOptionInColumn.row, "column": swiper.activeIndex });

    // };

    // const handleVerticalSlideChange = (swiper) => {
    //     // setFocusedOption({ "ball": ball, "row": swiper.activeIndex, "column": focusedOption.column });
    //     setFocus({ "ball": ball, "row": swiper.activeIndex, "column": focus.column });

    //     // Update the active option for this column...
    //     // ...remove current activeOption for this column...
    //     // const newActiveOptions = activeOptions.filter(item => !(item.ball === ball && item.column == focusedOption.column))
    //     const newActiveOptions = activeOptions.filter(item => !(item.ball === ball && item.column == focus.column))

    //     // ...add new activeOption for this column
    //     // newActiveOptions.push({ "ball": ball, "row": swiper.activeIndex, "column": focusedOption.column });
    //     newActiveOptions.push({ "ball": ball, "row": swiper.activeIndex, "column": focus.column });
    //     setActiveOptions(newActiveOptions);
    // };

    function gotoNext(e) {
        setFocus({ "ball": 5, "row": 0, "column": 0 });
        navigate("/TrademarkSwiper5");
    }

    // useEffect(() => {
    //     setActiveSlideSelected(selectedOptions.findIndex(item => (item.ball == focusedOption.ball && item.row == focusedOption.row && item.column == focusedOption.column)) != -1);
    // }, [focusedOption]);

    useEffect(() => {
        setActiveSlideSelected(selectedOptions.findIndex(item => (item.ball == focus.ball && item.row == focus.row && item.column == focus.column)) != -1);
    }, [focus]);


    return (
        <div className='container'>
            <div className='top-bar'></div>
            <div className='boxlegal-1'>
                <h2 className='app-title'>legal</h2>
            </div>

            <div className='boxlegal-flex'>
                {/* Ball color depending on whether option is selected or not... */}
                {activeSlideSelected ?
                    <img src={swiperballSelected} className='swipe-ball' alt="ball" /> : <img src={swiperball} className='swipe-ball' alt="ball" />
                }

                {/* ...or ball color is always the same */}
                {/* <img src={swiperball} className='swipe-ball' alt="ball" /> */}

                <div className='centered'>
                    <Swiper
                        className="my-swiper-h"
                        spaceBetween={50}
                        onSlideChange={handleHorizontalSlideChange}
                    >

                        <SwiperSlide className='swiperSlideTitle1'>
                            <div className="swiper-ball-text">swipe & vælg</div></SwiperSlide>
                        <SwiperSlide>
                            <Swiper
                                className="my-swiper-v"
                                direction={'vertical'}
                                spaceBetween={50}
                                onSlideChange={handleVerticalSlideChange}
                            >

                                <SwiperSlide>
                                    <div>
                                        <div className="swiper-line-top"></div>
                                        <div className="swiper-line-bottom"></div>
                                        <img src={howRetail} className='swiper-icon1' alt='' />
                                        <p className='swiper-icon-label'>detail</p>
                                    </div>
                                </SwiperSlide>

                                {/* This is the simple list of options... */}
                                <SwiperSlide>
                                    <div className="swiper-line-top"></div>
                                    <div className="swiper-line-bottom"></div>
                                    <TrademarkSwiperList listClassName="retail" ball={ball} column="1" items={itemsRetail} />
                                </SwiperSlide>

                            </Swiper>
                        </SwiperSlide>

                        <SwiperSlide>
                            <Swiper
                                className="my-swiper-v"
                                direction={'vertical'}
                                spaceBetween={50}
                                onSlideChange={handleVerticalSlideChange}
                            >

                                <SwiperSlide>
                                    <div>
                                        <div className="swiper-line-top"></div>
                                        <div className="swiper-line-bottom"></div>
                                        <img src={howAds} className='swiper-icon1' alt='' />
                                        <p className='swiper-icon-label'>annoncer</p>
                                    </div>
                                </SwiperSlide>

                                {/* This is the simple list of options... */}
                                <SwiperSlide>
                                    <div className="swiper-line-top"></div>
                                    <div className="swiper-line-bottom"></div>
                                    <TrademarkSwiperList listClassName="ads" ball={ball} column="2" items={itemsAds} />
                                </SwiperSlide>

                            </Swiper>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div onClick={() => setOption(ball, 0, 3, 'add')}>
                                <img src={howTv} className='swiper-icon1' alt='' />
                                <p className='swiper-icon-label'>tv</p>
                            </div>
                        </SwiperSlide>


                        <SwiperSlide>
                            <div onClick={() => setOption(ball, 0, 4, 'add')}>
                                <img src={howRadio} className='swiper-icon1' alt='' />
                                <p className='swiper-icon-label'>radio</p>
                            </div>
                        </SwiperSlide>


                        <SwiperSlide>
                            <Swiper
                                className="my-swiper-v"
                                direction={'vertical'}
                                spaceBetween={50}
                                onSlideChange={handleVerticalSlideChange}
                            >

                                <SwiperSlide>
                                    <div>
                                        <div className="swiper-line-top"></div>
                                        <div className="swiper-line-bottom"></div>
                                        <img src={howCompany} className='swiper-icon1' alt='' />
                                        <p className='swiper-icon-label'>selskab</p>
                                    </div>
                                </SwiperSlide>


                                <SwiperSlide>
                                    <div className="swiper-line-top"></div>
                                    <div className="swiper-line-bottom"></div>
                                    <TrademarkSwiperList listClassName="company" ball={ball} column="5" items={itemsCompany} />
                                </SwiperSlide>

                            </Swiper>
                        </SwiperSlide>


                        {/* Transportation */}
                        <SwiperSlide>
                            <Swiper
                                className="my-swiper-v"
                                direction={'vertical'}
                                spaceBetween={50}
                                onSlideChange={handleVerticalSlideChange}
                            >

                                <SwiperSlide>
                                    <div>
                                        <div className="swiper-line-top"></div>
                                        <div className="swiper-line-bottom"></div>
                                        <img src={howTransportation} className='swiper-icon1' alt='' />
                                        <p className='swiper-icon-label'>transport-midler</p>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="swiper-line-top"></div>
                                    <div className="swiper-line-bottom"></div>
                                    <TrademarkSwiperList listClassName="transportation" ball={ball} column="6" items={itemsTransportation} />
                                </SwiperSlide>

                            </Swiper>
                        </SwiperSlide>

                        {/* Homepage */}
                        <SwiperSlide>
                            <Swiper
                                className="my-swiper-v"
                                direction={'vertical'}
                                spaceBetween={50}
                                onSlideChange={handleVerticalSlideChange}
                            >

                                <SwiperSlide>
                                    <div>
                                        <div className="swiper-line-top"></div>
                                        <div className="swiper-line-bottom"></div>
                                        <img src={howHomepage} className='swiper-icon1' alt='' />
                                        <p className='swiper-icon-label'>hjemmeside</p>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="swiper-line-top"></div>
                                    <div className="swiper-line-bottom"></div>
                                    <TrademarkSwiperList listClassName="homepage" ball={ball} column="7" items={itemsHomepage} />
                                </SwiperSlide>

                            </Swiper>
                        </SwiperSlide>

                        {/* Social media */}
                        <SwiperSlide>
                            <Swiper
                                className="my-swiper-v"
                                direction={'vertical'}
                                spaceBetween={50}
                                onSlideChange={handleVerticalSlideChange}
                            >

                                <SwiperSlide>
                                    <div>
                                        <div className="swiper-line-top"></div>
                                        <div className="swiper-line-bottom"></div>
                                        <img src={howSome} className='swiper-icon1' alt='' />
                                        <p className='swiper-icon-label'>sociale medier</p>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="swiper-line-top"></div>
                                    <div className="swiper-line-bottom"></div>
                                    <TrademarkSwiperList listClassName="some" ball={ball} column="8" items={itemsSome} />
                                </SwiperSlide>

                            </Swiper>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div onClick={() => setOption(ball, 0, 9, 'add')}>
                                <img src={howNewsletter} className='swiper-icon1' alt='' />
                                <p className='swiper-icon-label'>nyhedsbrev</p>
                            </div>
                        </SwiperSlide>

                        <SwiperSlide>
                            <div onClick={() => setOption(ball, 0, 10, 'add')}>
                                <img src={howEmail} className='swiper-icon1' alt='' />
                                <p className='swiper-icon-label'>email</p>
                            </div>
                        </SwiperSlide>


                        {/* Press coverage */}
                        <SwiperSlide>
                            <Swiper
                                className="my-swiper-v"
                                direction={'vertical'}
                                spaceBetween={50}
                                onSlideChange={handleVerticalSlideChange}
                            >

                                <SwiperSlide>
                                    <div>
                                        <div className="swiper-line-top"></div>
                                        <div className="swiper-line-bottom"></div>
                                        <img src={howPress} className='swiper-icon1' alt='' />
                                        <p className='swiper-icon-label'>presse</p>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="swiper-line-top"></div>
                                    <div className="swiper-line-bottom"></div>
                                    <TrademarkSwiperList listClassName="press" ball={ball} column="11" items={itemsPress} />
                                </SwiperSlide>

                            </Swiper>
                        </SwiperSlide>


                        {/* Internal use */}
                        <SwiperSlide>
                            <Swiper
                                className="my-swiper-v"
                                direction={'vertical'}
                                spaceBetween={50}
                                onSlideChange={handleVerticalSlideChange}
                            >

                                <SwiperSlide>
                                    <div>
                                        <div className="swiper-line-top"></div>
                                        <div className="swiper-line-bottom"></div>
                                        <img src={howInternal} className='swiper-icon1' alt='' />
                                        <p className='swiper-icon-label'>intern brug</p>
                                    </div>
                                </SwiperSlide>

                                <SwiperSlide>
                                    <div className="swiper-line-top"></div>
                                    <div className="swiper-line-bottom"></div>
                                    <TrademarkSwiperList listClassName="internal" ball={ball} column="12" items={itemsInternal} />
                                </SwiperSlide>

                            </Swiper>
                        </SwiperSlide>

                    </Swiper>

                </div>
            </div >

            <div className='boxlegal-3 page-description'>
                {optionDescription}
            </div>

            <div className='info-button-overlay'></div>

            <div className='boxlegal-4'>
                <div className='myprogress'>45%</div>
                <div className='button-next' onClick={gotoNext}>Næste</div>
            </div>

        </div >
    );

}
export default TrademarkSwiper4;