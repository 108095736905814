import React, { useState, useContext, useRef, useEffect } from "react";
import Tree from 'react-d3-tree';
// react-d3-tree GitHub page: https://github.com/bkrem/react-d3-tree/tree/master?tab=readme-ov-file
// Tree properties: https://bkrem.github.io/react-d3-tree/docs/interfaces/_src_tree_types_.treeprops.html#dimensions
// Tree playground: https://bkrem.github.io/react-d3-tree/


import { displayContext } from "../../../common/DisplayContext.js";
import { knowledgeContext } from "../../contexts/KnowledgeContext.js";
import { domainContext } from "../../contexts/DomainContext.js";

import styles from '../../styles.module.css';
import '../../styles.css';


// import RuleTable from "./RuleTable.js";
// import RuleDetails from "./RuleDetails.js";
// import RuleFoundingCaseDetails from "./RuleFoundingCaseDetails.js";

function RuleTreeMain() {
    const {
        t,
        t2,
        currentLanguage,
    } = useContext(displayContext);

    const {
        currentKnowledgeBase,
    } = useContext(domainContext);

    const {
        ruleList,
        ruleTree,
    } = useContext(knowledgeContext);

    const [ruleTreeLocal, setRuleTreeLocal] = useState(ruleTree)

    const [selectedNode, setSelectedNode] = useState(0);

    // const ruleTreeEmpty = ruleTree.length == undefined || ruleTree.length == 0;
    const ruleTreeEmpty = Object.keys(ruleTree).length == 0;


    // This is a simplified example of an org chart with a depth of 2.
    // Note how deeper levels are defined recursively via the `children` property.
    // const orgChart = {
    //     name: 'CEO',
    //     children: [
    //         {
    //             name: 'Manager',
    //             attributes: {
    //                 department: 'Production',
    //             },
    //             children: [
    //                 {
    //                     name: 'Foreman',
    //                     attributes: {
    //                         department: 'Fabrication',
    //                     },
    //                     children: [
    //                         {
    //                             name: 'Worker',
    //                         },
    //                     ],
    //                 },
    //                 {
    //                     name: 'Foreman',
    //                     attributes: {
    //                         department: 'Assembly',
    //                     },
    //                     children: [
    //                         {
    //                             name: 'Worker',
    //                         },
    //                     ],
    //                 },
    //             ],
    //         },
    //     ],
    // };


    // const ruleTree1 = {
    //     "attributes": {
    //         "Conclusion": "Ikke dækket",
    //         "id": 2,
    //         "Rule": "skadeforvoldelse = forsæt or påvirkning = alkohol or kørekort = no or bil-ulovlig-færdselslov-skade = yes"
    //     },
    //     "children": [
    //         {
    //             "attributes": {
    //                 "Conclusion": "Dækket",
    //                 "id": 7,
    //                 "Rule": "fører != ægtefælle and fører != samlever and fører != husstand and fører != fast and kendskab = uvidende"
    //             },
    //             "children": [
    //                 {
    //                     "attributes": {
    //                         "Conclusion": "Dækket",
    //                         "id": 8,
    //                         "Rule": "rettighedshaver = yes and panthaverdeklaration = underskrevet"
    //                     },
    //                     "details": {
    //                         "Conclusion": "Dækket",
    //                         "created_time": "Mon, 15 Apr 2024 15:45:16 GMT",
    //                         "explanation": "Explanation 6",
    //                         "id": 8,
    //                         "Rule": "rettighedshaver = yes and panthaverdeklaration = underskrevet",
    //                         "userid": "philip",
    //                         "type": "true"
    //                     },
    //                     "id": 8
    //                 },
    //                 {
    //                     "attributes": {
    //                         "Conclusion": "Dækket",
    //                         "id": 1111,
    //                         "Rule": "rettighedshaver = yes and panthaverdeklaration = underskrevet"
    //                     },
    //                     "details": {
    //                         "Conclusion": "Dækket",
    //                         "created_time": "Mon, 15 Apr 2024 15:45:16 GMT",
    //                         "explanation": "Explanation 6",
    //                         "id": 1111,
    //                         "Rule": "rettighedshaver = yes and panthaverdeklaration = underskrevet",
    //                         "userid": "philip",
    //                         "type": "false"
    //                     },
    //                     "id": 8
    //                 }




    //             ],
    //             "details": {
    //                 "Conclusion": "Dækket",
    //                 "created_time": "Mon, 15 Apr 2024 15:39:56 GMT",
    //                 "explanation": "Explanation 5",
    //                 "id": 7,
    //                 "Rule": "fører != ægtefælle and fører != samlever and fører != husstand and fører != fast and kendskab = uvidende",
    //                 "userid": "philip"
    //             },
    //             "id": 7
    //         },
    //         {
    //             "attributes": {
    //                 "Conclusion": "Ikke dækket",
    //                 "id": 4,
    //                 "Rule": "bilfejl-skade = yes or væske-drivmiddel-skade = yes or bil-uforsvarlig-ulovlig-ændringer = yes or brug = yes or slid = yes or vejrligspåvirkning = frostsprængning or vejrligspåvirkning = rust or vejrligspåvirkning = tæring or vejrligspåvirkninng = andet or behandling = yes or mekanisk-elektrisk-elektronisk = yes"
    //             },
    //             "children": [
    //                 {
    //                     "attributes": {
    //                         "Conclusion": "Dækket",
    //                         "id": 5,
    //                         "Rule": "mekanisk-elektrisk-elektronisk = yes or transport = yes"
    //                     },
    //                     "children": [
    //                         {
    //                             "attributes": {
    //                                 "Conclusion": "Dækket",
    //                                 "id": 6,
    //                                 "Rule": "behandling = yes and vaskehal = yes"
    //                             },
    //                             "details": {
    //                                 "Conclusion": "Dækket",
    //                                 "created_time": "Mon, 15 Apr 2024 15:35:41 GMT",
    //                                 "explanation": "explanation 4",
    //                                 "id": 6,
    //                                 "Rule": "behandling = yes and vaskehal = yes",
    //                                 "userid": "philip"
    //                             },
    //                             "id": 6
    //                         }
    //                     ],
    //                     "details": {
    //                         "Conclusion": "Dækket",
    //                         "created_time": "Mon, 15 Apr 2024 15:32:27 GMT",
    //                         "explanation": "explanation 3",
    //                         "id": 5,
    //                         "Rule": "mekanisk-elektrisk-elektronisk = yes or transport = yes",
    //                         "userid": "philip",
    //                         "type": "true"
    //                     },
    //                     "id": 5
    //                 },
    //                 {
    //                     "attributes": {
    //                         "Conclusion": "Dækket",
    //                         "id": 777777,
    //                         "Rule": "bla bla bla"
    //                     },
    //                     "details": {
    //                         "Conclusion": "Dækket",
    //                         "created_time": "Mon, 15 Apr 2024 15:32:27 GMT",
    //                         "explanation": "explanation 3",
    //                         "id": 5,
    //                         "Rule": "bla bla bla",
    //                         "userid": "philip",
    //                         "type": "dummy"
    //                     },
    //                     "id": 777777
    //                 }
    //             ],
    //             "details": {
    //                 "Conclusion": "Ikke dækket",
    //                 "created_time": "Mon, 15 Apr 2024 15:19:38 GMT",
    //                 "explanation": "explanation 2",
    //                 "id": 4,
    //                 "Rule": "bilfejl-skade = yes or væske-drivmiddel-skade = yes or bil-uforsvarlig-ulovlig-ændringer = yes or brug = yes or slid = yes or vejrligspåvirkning = frostsprængning or vejrligspåvirkning = rust or vejrligspåvirkning = tæring or vejrligspåvirkninng = andet or behandling = yes or mekanisk-elektrisk-elektronisk = yes",
    //                 "userid": "philip"
    //             },
    //             "id": 4
    //         }
    //     ],
    //     "details": {
    //         "Conclusion": "Ikke dækket",
    //         "created_time": "Mon, 15 Apr 2024 14:36:01 GMT",
    //         "explanation": "bla Explanation",
    //         "id": 2,
    //         "Rule": "skadeforvoldelse = forsæt or påvirkning = alkohol or kørekort = no or bil-ulovlig-færdselslov-skade = yes",
    //         "userid": "philip"
    //     },
    //     "id": 2
    // }


    function onNodeClick(node) {
        console.log('Node has been clicked: ', node.details.id)
        setSelectedNode(node.details.id)
    }


    function onNodeMouseOver(node) {
        console.log('Mouse over')


        // You can customize this function based on your node data structure
        if (node.details.type != 'dummy'){
            setNodeDetails(node);
            setSelectedNode(node.details.id)
        }
    };


    function onNodeMouseOut() {
        console.log('Mouse out')
        setNodeDetails(null);
    }

    const [treeDimensions, setTreeDimensions] = useState({ width: 0, height: 0 });
    const [nodeDetails, setNodeDetails] = useState(null); // State to store details of the hovered node
    const treeContainerRef = useRef(null);

    useEffect(() => {

        if (!ruleTreeEmpty) {
            const updateDimensions = () => {
                const { width, height } = treeContainerRef.current.getBoundingClientRect();
                setTreeDimensions({ width, height });
            };

            // Call the function initially
            updateDimensions();
        }
    }, []);


    // Define a custom component for nodes
    const CustomNode = ({ nodeData }) => {
        return (
            <foreignObject x={-50} y={-15} width={100} height={30}>
                <div style={{ width: '100%', height: '100%', textAlign: 'center', overflowWrap: 'break-word', wordWrap: 'break-word' }}>
                    <p>{nodeData.name}</p> {/* Assuming nodeData contains the attribute 'name' */}
                </div>
            </foreignObject>
        );
    };

    // Configuring the tree
    const config = {
        nodeSvgShape: {
            shape: 'none',
        },
        foreignObjectWrapper: {
            style: {
                pointerEvents: 'none',
            }
        },
        nodeSize: { x: 400, y: 100 }

    };


    // Here we're using `renderCustomNodeElement` render a component that uses
    // both SVG and HTML tags side-by-side.
    // This is made possible by `foreignObject`, which wraps the HTML tags to
    // allow for them to be injected into the SVG namespace.
    const renderForeignObjectNode = ({
        nodeDatum,
        toggleNode,
        foreignObjectProps,
        onMouseOver
    }) => (
        <g transform={`translate(-100, 0)`}>
            {/* <circle r={15} fill="#dedede" stroke="black" strokeWidth="2"></circle> */}
            {/* <circle cx={0} cy={0} r={15} fill="#dedede" stroke="black" strokeWidth="2"></circle> */}
            {/* `foreignObject` requires width & height to be explicitly set. */}
            {/* <foreignObject {...foreignObjectProps}> */}
            {/* <foreignObject x={-50} y={-35} width={200} height={100} {...foreignObjectProps}> */}
            <foreignObject y={-20} width={300} height={90} {...foreignObjectProps} style={{ width: "300" }}
               
            >
            {/* <div className={styles.treeNodeText} onMouseOver={() => onNodeMouseOver(nodeDatum)} onMouseOut={() => onNodeMouseOut()} > */}
            <div 
                // className={styles.treeNodeText}  
                className={nodeDatum.details.id == selectedNode ? styles.treeNodeTextSelectedNode : styles.treeNodeTextNonSelectedNode}
                onMouseOver={() => onNodeMouseOver(nodeDatum)} 
                onClick={() => onNodeClick(nodeDatum)}
            >
                    {nodeDatum.details.type != 'dummy' && (<>
                        <div className={styles.treeNodeId}>{t2('ruleId', 'sysadmin')}: <a>{Object.values(nodeDatum.attributes)[1]}</a></div>
                        <div className={styles.treeNodeConclusion}>{t2('conclusion', 'sysadmin')}: <a>{Object.values(nodeDatum.attributes)[0]}</a></div>
                        <div className={styles.treeNodeRule}>{t2('rule', 'sysadmin')}: <a>{Object.values(nodeDatum.attributes)[2]}</a></div>
                    </>)}
                </div>
            </foreignObject>
        </g>
    );

    const containerStyles = {
        width: "100vw",
        height: "100vh"
    };

    const nodeSize = { x: 200, y: 200 };
    const foreignObjectProps = { width: nodeSize.x, height: nodeSize.y, x: 20 };

    function getPathClass({ source, target }, orientation) {
        if (target.data.details.type == 'true') {
            return 'linkTrueClass'
        } else if (target.data.details.type == 'false') {
            return 'linkFalseClass'
        } else {
            return 'linkUnknownClass'
        }
    }



    return (
        <>
        {!ruleTreeEmpty && (
        <div className={[styles.flexRow, styles.ruleTreeMain].join(' ')}>
            <div ref={treeContainerRef} className={styles.ruleTree}>
                <Tree
                    // data={orgChart}
                    data={ruleTree}
                    rootNodeClassName={styles.nodeRoot}
                    nodeSize={{ x: 200, y: 200 }}
                    renderCustomNodeElement={(rd3tProps) =>
                        renderForeignObjectNode({ ...rd3tProps, foreignObjectProps })
                    }
                    pathClassFunc={getPathClass}
                    pathFunc='diagonal'
                    orientation='vertical'
                    separation={{ nonSiblings: 1.8, siblings: 1.8 }}
                    collapsible={false}
                    draggable={true}
                    onNodeClick={onNodeClick}
                    translate={{ x: treeDimensions.width / 2, y: treeDimensions.height / 4 }}
                    dimensions={{ width: treeDimensions.width, height: treeDimensions.height }}
                    zoom={0.2}
                />
            </div>

            <div className={[styles.box, styles.ruleTreeNodeDetailsWrapper].join(' ')}>
                <div className={styles.ruleTreeNodeDetails}>
                    {nodeDetails && nodeDetails.details.type != 'dummy' && (
                        <>
                            <p>
                                <span className={styles.boldLabel}>{t2("ruleId", "sysadmin")}: </span>
                                {nodeDetails.details.id}
                                <span>{' (F: '}{nodeDetails.details.nextNodeFalse}{', T: '}</span>
                                {nodeDetails.details.nextNodeTrue != 0 ? nodeDetails.details.nextNodeTrue : 'N/A'}{')'}
                            </p>
                            
                            <p><span className={styles.boldLabel}>{t2("creator", "sysadmin")}: </span>{nodeDetails.details.userid}</p>
                            <p><span className={styles.boldLabel}>{t2("created_time", "sysadmin")}: </span>{nodeDetails.details.created_time}</p>
                            <p><span className={styles.boldLabel}>{t2("conclusion", "sysadmin")}:  </span>{t2(nodeDetails.details.Conclusion, currentKnowledgeBase)}</p>
                            <p><span className={styles.boldLabel}>{t2("rule", "sysadmin")}: </span>{nodeDetails.details.Rule}</p>
                            <p><span className={styles.boldLabel}>{t2("explanation", "sysadmin")}: </span>{t2(nodeDetails.details.explanation,currentKnowledgeBase)}</p>
                        </>
                    )}
                </div>
            </div>
        </div>
        )}
        </>
    )
}

export default RuleTreeMain;
