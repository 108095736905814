import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
    Form,
    Row,
    Col,
    InputGroup,
    Button,
    ButtonGroup,
} from "react-bootstrap";

import Modal from "react-modal";

// Flags: https://www.npmjs.com/package/flag-icons
import "/node_modules/flag-icons/css/flag-icons.min.css";

import { domainContext } from "../../admin/contexts/DomainContext";
import { userCaseContext } from "../../admin/contexts/UserCaseContext";
import { displayContext } from "../../common/DisplayContext";
import { swiperContext } from "../../legal/contexts/SwiperContext";

import useTMEvaluateTm1 from "../hooks/useTMEvaluateTm1";

import NavigationSideBarTM2 from "../components/Navigation/NavigationSideBar2";
import Header from "../components/Header";
import ForwardBackward from "../components/Navigation/ForwardBackward";
import TM1UseBubbles from "../components/Use/UseBubbles";

import '../../App.css'


// Ball 1: use as brand
// Ball 2: one version
// Ball 3: place
// Ball 4: context
// Ball 5: continuation


function TM1UsePage() {
    const {
        setDisplayMessage,
        previousPath,
        setUseMode,
        t2,
        bubbleInfo,
        sidebarWidth,
    } = useContext(displayContext);


    const { selectedOptions } = useContext(swiperContext);


    let navigate = useNavigate();
    let location = useLocation();

    useEffect(() => {
        setDisplayMessage("");
    }, [location.key]);

    const { currentDomain, setCurrentDomain } = useContext(domainContext);
    const { userCase, setUserCase, setCaseStatus } =
        useContext(userCaseContext);

    const [{ handleEvaluate }] = useTMEvaluateTm1();

    if (userCase == []) {
        setCurrentDomain(currentDomain);
    }

    if (previousPath == "/learn") {
        setUseMode("read");
    }

    const [modalSelectLanguage, setModalSelectLanguage] = React.useState(false);

    // const [pageStatus, setPageStatus] = useState("");

    function isNumeric(str) {
        if (typeof str != "string") return false; // we only process strings!
        return (
            !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
            !isNaN(parseFloat(str))
        ); // ...and ensure strings of whitespace fail
    }

    const [errors, setErrors] = useState("");

    const findFormErrors = () => {
        const newErrors = {};
        // if (
        //     (!userCase.find((attribute) => attribute.name == "useOwnedByMe")[
        //         "test"
        //     ] == true &&
        //         !userCase.find(
        //             (attribute) => attribute.name == "useNotOwnedByMe"
        //         )["test"] == true) ||
        //     (!userCase.find((attribute) => attribute.name == "useOneVersion")[
        //         "test"
        //     ] == true &&
        //         !userCase.find(
        //             (attribute) => attribute.name == "useMultipleVersions"
        //         )["test"] == true)
        // ) {
        //     newErrors.use = t2("usePageError", "varemaerke");
        // }


        if (
            selectedOptions.findIndex(item => (item.ball == 1)) == -1
            ||
            selectedOptions.findIndex(item => (item.ball == 2)) == -1) {
            newErrors.use = t2("TM1UsePageError", "varemaerke");
        }
        return newErrors;
    };

    function handleChangeLevel1(e) {
        const updatedUserCase = userCase.map((attribute) => {
            if (attribute.name === e.target.name) {
                return {
                    ...attribute,
                    ["test"]: e.target.value,
                };
            } else {
                return attribute;
            }
        });

        setUserCase(updatedUserCase);
    }

    function HandleBackward(e) {
        navigate("/TM1");
    }

    function HandleSubmit(e) {
        // Find errors
        const newErrors = findFormErrors();

        // Conditional logic:
        if (Object.keys(newErrors).length > 0) {
            // We got errors!
            setErrors(newErrors);
        } else {
            // No errors! Put any logic here for the form submission!
            // handleEvaluate(e, "use");

            navigate("/TM1GeographyPage");
        }
    }

    return (
        <>
            <Header />

            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: "8vh"
                }}
            >
                {/* <div style={{ width: sidebarWidth + "vw" }}> */}
                {/* <div style={{ width: "35vw" }}> */}
                <div>
                    <NavigationSideBarTM2 />
                </div>

                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                    }}
                >
                    {/* {TM1UseBubbles(100 - sidebarWidth)} */}
                    <TM1UseBubbles />

                    {ForwardBackward(HandleSubmit, HandleBackward)}

                    {typeof errors.use != "undefined" && (
                        <p
                            className="vm-error-message"
                            style={{ position: "absolute" }}
                        >
                            {errors.use}
                        </p>
                    )}
                </div>
            </div>
        </>
    );
}

export default TM1UsePage;
