import React, { useContext, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import "../../../translations/i18n";
import { domainsContext } from "../../contexts/DomainsContext";
import { displayContext } from "../../../common/DisplayContext";

import styles from '../../styles.module.css';

import { domainTheme } from "../../hooks/useReactTableTheme";
import { useTheme } from "@table-library/react-table-library/theme";
import defaultTableTheme from "../KnowledgeBrowser/DefaultTableTheme";

import {
    BsArrowDown,
    BsArrowUp,
    BsFileArrowDown,
    BsFileArrowUp,
} from "react-icons/bs";

import {
    Table,
    Header,
    HeaderRow,
    HeaderCell,
    Body,
    Row,
    Cell,
} from "@table-library/react-table-library/table";

import {
    useSort,
    SortToggleType,
} from "@table-library/react-table-library/sort";

// import { defaultTheme } from "../../hooks/useReactTableTheme";
import useReactTableTheme from "../../hooks/useReactTableTheme";

// import { useTheme } from "@table-library/react-table-library/theme";


function KnowledgeBaseTable() {
    const { 
        knowledgeBases,
        setTrigger,
    } = useContext(domainsContext);
    
    const {
        t2
    } = useContext(displayContext);

    const [{ defaultTheme }] = useReactTableTheme();


    defaultTableTheme.Table = `
        height: 100%; 
        width: 100%; 
        align-content: baseline; 
        --data-table-library_grid-template-columns: 100%;
    `
    const theme = useTheme(defaultTableTheme)




    const [data, setData] = useState({ nodes: knowledgeBases });

    useEffect(() => {
        setData({ nodes: knowledgeBases });
    }, [knowledgeBases]);

    const handleNameFilter = (value) => {
        setData({
            nodes:
                value !== ""
                    ? knowledgeBases.filter(function (item) {
                        return item.knowledgeBase.includes(value);
                    })
                    : knowledgeBases,
        });
    };

    const sort = useSort(
        data,
        {
            onChange: onSortChange,
        },
        {
            sortToggleType: SortToggleType.AlternateWithReset,
            sortFns: {
                KnowledgeBase: (array) =>
                    array.sort((a, b) =>
                        a.knowledgeBase.localeCompare(b.knowledgeBase)
                    ),
            },
        }
    );

    function onSortChange(action, state) {
        console.log(action, state);
    }

    return (
        <div className={[styles.standardTableWrapper, styles.knowledgeBaseKnowledgeBaseTableWrapper].join(' ')}>
            <Table 
                data={data} 
                theme={theme} 
                sort={sort}
                layout={{custom: true}}
            >
                {(tableList) => (
                    <>
                        <Header>
                            <HeaderRow>
                                <HeaderCell>
                                    {t2("knowledgeBases", 'sysadmin')} <br />
                                    <input
                                        className={styles.caseTableFilter}
                                        type="text"
                                        placeholder="Filter..."
                                        onChange={(event) =>
                                            handleNameFilter(event.target.value)
                                        }
                                    />
                                </HeaderCell>
                            </HeaderRow>
                        </Header>

                        <Body>
                            {tableList.map((item) => (
                                <Row key={item.knowledgeBase} item={item}>
                                    <Cell>{item.knowledgeBase}</Cell>
                                </Row>
                            ))}
                        </Body>
                    </>
                )}
            </Table>
        </div>
    );
}

export default KnowledgeBaseTable;
