import { useContext } from 'react';
import Messages from '../components/Messages';
import { displayContext } from '../../common/DisplayContext';

const useMessages = (message) => {
    const { t2 } = useContext(displayContext);

    if (message == null || message.messageText == null) {
        return message;
    } else {
        return Messages(message);
    }
};

export default useMessages;
