import React, { createContext, useState } from "react";

export const CurrentCategoryContext = createContext();

const CurrentCategoryContextProvider = (props) => {
    const [currentCategory, setCurrentCategory] = useState("");

    return (
        <CurrentCategoryContext.Provider
            value={{
                currentCategory: currentCategory,
                setCurrentCategory: setCurrentCategory,
            }}
        >
            {props.children}
        </CurrentCategoryContext.Provider>
    );
};
export default CurrentCategoryContextProvider;
