import React, { useRef, useState } from 'react';
import "../swipeballDesign.css"

import { Button } from "react-bootstrap";

import { useNavigate } from "react-router-dom";

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';

import '../swipeballDesign.css';

// import required modules
import { Pagination } from 'swiper/modules';

// Import pictures
// import background from './images/background.png';
import logo from '../images/Top-logo-white.png';

function Top() {

    const [infoButton, setInfoButton] = useState(false);

    let navigate = useNavigate();

    function toggleInfoButton() {
        setInfoButton(!infoButton)
    }

    function testClick(e) {
        console.log('testClick')
    }

    function gotoInsuranceTypes(e) {
        navigate("/InsuranceTypes");
    }


    return (

        <div className='container-blue'>

            <div className='top-bar'></div>

            <div className='box-1'>
                <img src={logo} className='logo-white' alt="logo" />
            </div>

            <div className='box-flex' onClick={gotoInsuranceTypes}>
                Log in
            </div>

            <div className='box-3 description-text'></div>

        </div >
    );

}
export default Top;