import React, { createContext, useState } from "react";

export const UserContext = createContext();

const UserContextProvider = (props) => {
    const [username, setUsername] = useState();
    const [password, setPassword] = useState();
    const [loggedIn, setLoggedIn] = useState(false);

    return (
        <UserContext.Provider
            value={{
                loggedIn,
                password,
                setLoggedIn,
                setPassword,
                setUsername,
                username,
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
};

export default UserContextProvider;
