import React, { useState, useContext, useRef } from "react";

import { displayContext } from "../../../common/DisplayContext.js";

import styles from '../../styles.module.css';

import AttributeTable from "./AttributeTable.js";
import AttributeDetails from "./AttributeDetails.js";

function AttributeMain() {
    const {
        t,
        t2,
    } = useContext(displayContext);

    return (
        <div className={styles.flexRow}>
            <AttributeTable />
            <AttributeDetails />
        </div>
    );
}

export default AttributeMain;
