// See https://www.robinwieruch.de/react-table-component/ for the tutorial for creating this type of tables
// https://www.robinwieruch.de/categories/react-table-library/
// samples: https://react-table-library.com/?path=/story/getting-started--demo
// https://github.com/table-library/react-table-library
import React, { useEffect } from "react";

import {
    Table,
    Header,
    HeaderRow,
    HeaderCell,
    Body,
    Row,
    Cell,
} from "@table-library/react-table-library/table";

import {
    useSort,
    // HeaderCellSort,
} from "@table-library/react-table-library/sort";

import {
    // Sort,
    // SortOptions,
    // SortIconPositions,
    SortToggleType,
} from "@table-library/react-table-library/sort";

// import { defaultTheme } from "../../hooks/useReactTableTheme";
import useReactTableTheme from "../../hooks/useReactTableTheme";
import defaultTableTheme from "./DefaultTableTheme";

import { useTheme } from "@table-library/react-table-library/theme";

import { displayContext } from "../../../common/DisplayContext";
import { knowledgeContext } from "../../contexts/KnowledgeContext";
import { domainContext } from "../../contexts/DomainContext";
import { useContext, useState } from "react";

import useAuth from "../../hooks/useAuth";

import styles from '../../styles.module.css';

function CaseDetails() {

    const {
        currentCaseId,
    } = useContext(knowledgeContext);

    const {
        t2,
    } = useContext(displayContext);

    const {
        currentKnowledgeBase,
        currentDomain,
    } = useContext(domainContext);



    const { token } = useAuth();

    const [caseAttributeList, setCaseAttributeList] = useState([]);

    useEffect(() => {
        var obj = {};

        if (token != null && currentKnowledgeBase != "noKnowledgeBase") {
            obj.knowledgeBase = currentKnowledgeBase;
            obj.domain = currentDomain;
            obj.caseId = currentCaseId;
            var endpoint = process.env.REACT_APP_FLASK_API + "getCaseAttributes";

            const fetchData = async () => {
                try {
                    const response = await fetch(endpoint, {
                        method: "POST",
                        headers: {
                            Accept: "application/json",
                            "Content-Type": "application/json",
                            Authorization: "Bearer " + token,
                        },
                        body: JSON.stringify(obj),
                    })
                        .then((res) => res.json())
                        .then((responseJson) => {
                            setCaseAttributeList(responseJson["caseAttributeList"]);   // get the cases
                        });
                } catch (error) {
                    console.error('Error fetching data: ', error)
                }
            }
            fetchData()
        }
    }, [currentCaseId]);


    defaultTableTheme.Table = `
        height: 100%; 
        width: 100%; 
        align-content: baseline; 
        --data-table-library_grid-template-columns: 50% 20% 30%;
    `

    const theme = useTheme(defaultTableTheme)
    
    const [data, setData] = useState({ nodes: caseAttributeList });

    useEffect(() => {
        setData({ nodes: caseAttributeList });
    }, [caseAttributeList]);


    const handleFilter = (value, property) => {
        setData({
            nodes:
                value !== ""
                    ? caseAttributeList.filter(function (item) {
                        return typeof (item[property]) == 'string' ?
                            item[property].includes(value) :
                            item[property] == value

                        // return item.ruleId == value;
                    })
                    : caseAttributeList,
        });
    };

    const sort = useSort(
        data,
        {
            onChange: onSortChange,
        },
        {
            sortToggleType: SortToggleType.AlternateWithReset,
            sortFns: {
                CaseId: (array) =>
                    array.sort((a, b) => a.caseId.localeCompare(b.caseId)),
            },
        }
    );

    function onSortChange(action, state) {
        console.log(action, state);
    }

    function headerCell(property, filter) {
        return (
            <HeaderCell resize>
                {/* {t2(property, "sysadmin")} <br /> */}
                <input
                    className={styles.caseTableFilter}
                    type="text"
                    placeholder={t2(filter, "sysadmin")}
                    onChange={(event) =>
                        handleFilter(
                            event.target.value,
                            property
                        )
                    }
                />
            </HeaderCell>
        )
    }

    return (
        <div className={styles.selectedRuleDetails}>

        <div className={styles.ruleDetails}>

        <div className={[styles.standardTableWrapper, styles.kbCasesCaseDetailsWrapper].join(' ')}>
            <div className={styles.boxHeading}>{t2("caseDetails", "sysadmin")}</div>
            <Table 
                className={styles.standardTable2}
                data={data} 
                theme={theme} 
                sort={sort}
                layout={{custom: true}}
            >
                {(tableList) => (
                    <>
                        <Header>
                            <HeaderRow>
                                {headerCell('attributename', 'attributeNameFilter')}
                                {headerCell('type', 'typeFilter')}
                                {headerCell('value', 'valueFilter')}
                            </HeaderRow>
                        </Header>

                        <Body>
                            {tableList.map((item) => (
                                <Row
                                    key={item.caseId}
                                    item={item}
                                // onClick={(event) => handleRule(event)}
                                >
                                    <Cell>{item.attributename}</Cell>
                                    <Cell><div className={styles.caseTableComment}>{item.type}</div></Cell>
                                    <Cell><div className={styles.caseTableComment}>{item.value}</div></Cell>
                                </Row>
                            ))}
                        </Body>
                    </>
                )}
            </Table>
            </div>
            </div>
            </div>
    )
}

export default CaseDetails;
