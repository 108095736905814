// How to Capture the Value of Dropdown Lists with React-Bootstrap:
// https://www.pluralsight.com/guides/how-to-capture-the-value-of-dropdown-lists-with-react-bootstrap


import React, { useContext, useState } from 'react';

import { displayContext } from '../../common/DisplayContext';
import { kbhKContext } from '../contexts/KbhKContext';

import styles from '../styles.module.css';

import i18n from '../../translations/i18n';

import { Dropdown, DropdownButton } from 'react-bootstrap';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';




function KnowledgeBaseDropdown() {

    const {
        knowledgeBase,
        setKnowledgeBase,
    } = useContext(kbhKContext);

    const setTheKnowledgeBase = (e) => {
        setKnowledgeBase(e)
        i18n.changeLanguage(e);
        i18n.reloadResources();

        if (e == 'kbhk') {
            setKnowledgeBaseLabel('Fodindlæg (ver 1)')
        } else if (e == 'kbhk1') {
            setKnowledgeBaseLabel('Fodindlæg (ver 2)')
        }
    }

    const [knowledgeBaseLabel, setKnowledgeBaseLabel] = useState('Fodindlæg');


    return (

        <div>
            <DropdownButton
                className={styles.dropdown1}

                // className="ms-1 me-1"
                variant="secondary"
                size="sm"
                bsPrefix=''

                id="dropdownBasicButton"
                // title={knowledgeBase}
                title={knowledgeBaseLabel}

                onSelect={setTheKnowledgeBase}
            >
                <Dropdown.Item eventKey="kbhk">Fodindlæg (ver 1)</Dropdown.Item>
                <Dropdown.Item eventKey="kbhk1">Fodindlæg (ver 2)</Dropdown.Item>
            </DropdownButton>




        </div>




    )




};

export default KnowledgeBaseDropdown;
