import React, { useContext, useEffect, useState } from "react";

import useAuth from "../../../admin/hooks/useAuth";

import {
    MdLocationCity, MdHotel, MdShoppingBag, MdFitnessCenter, MdGroups2, MdFastfood, MdFilterCenterFocus,
    MdLocalGroceryStore,
    MdStore
} from "react-icons/md";
import { IoIosPeople, IoIosRestaurant } from "react-icons/io";
import { GiBalloons } from "react-icons/gi";
import { MdVideoCameraBack } from "react-icons/md";
import { BiClinic } from "react-icons/bi";
import { FaGuitar } from "react-icons/fa";

// import { TbWorldWww } from "react-icons/tb";

import VMBubble1 from "../EvaluateCase/Bubble1";

import { domainContext } from "../../../admin/contexts/DomainContext";
import { userCaseContext } from "../../../admin/contexts/UserCaseContext";
import { displayContext } from "../../../common/DisplayContext";

function TM1GeographyBubbles() {
    const { token } = useAuth();
    const {
        setDisplayMessage,
        previousPath,
        setUseMode,
        t2,
        setIsEvaluating,
        bubbleInfo,
        bubbleInfoGeography,
        // bubbleStateGeography,
    } = useContext(displayContext);

    const { userCase, setUserCase, setCaseStatus } =
        useContext(userCaseContext);

    const { currentKnowledgeBase, currentDomain, setCurrentDomain } =
        useContext(domainContext);

    if (userCase == []) {
        setCurrentDomain(currentDomain);
    }

    // Circle stuff
    // https://stackoverflow.com/questions/72270247/react-calculate-dots-position-in-a-circle

    // Get the media width at runtime: https://www.w3schools.com/howto/howto_js_media_queries.asp

    // In order to change the size of the circle, change the 'diameter' variable.
    const diameter = 50;
    let radius = 0;

    // Convert vh to px: https://stackoverflow.com/questions/49268659/javascript-get-100vh-in-pixels
    let _1vh = Math.round(window.innerHeight / 100);
    function vhToPixels(vh) {
        return Math.round(window.innerHeight / (100 / vh));
    }

    function myFunction(x) {
        if (x.matches) {
            // If media query matches
            radius = 150;
        } else {
            // radius = vhToPixels(70 / 2);
            radius = vhToPixels(diameter / 2);
            // radius = 250;
            // radius =
            //     Math.min(
            //         document.documentElement.clientWidth,
            //         document.documentElement.clientHeight
            //     ) / 3.5;
        }
    }

    var x = window.matchMedia("(max-width: 600px)");
    myFunction(x); // Call listener function at run time
    // x.addListener(myFunction) // Attach listener function on state changes

    // Number of menu-items
    const menuItems = 21;        // <<<<<<<<<<<<<<<<<<<<<<<<<<<<<<

    function getDegrees(menuItem) {
        return (360 / menuItems) * (menuItem - 1);
    }

    // This function returns the x-coordinate in px relative to the center of the circle
    function getX(degrees) {
        if (degrees >= 0 && degrees < 360) {
            if (degrees > 180 && degrees < 360) {
                degrees = 360 - degrees;
                const radians = Math.PI / (180 / degrees);
                return -Math.sin(radians) * radius;
            }
            const radians = Math.PI / (180 / degrees);
            return Math.sin(radians) * radius;
        } else return 0;
    }

    // This function returns the y-coordinate in px relative to the center of the circle
    function getY(degrees) {
        const radians = Math.PI / (180 / degrees);
        return Math.cos(radians) * radius;
    }

    const style1 = {
        left: scaleX(getX(getDegrees(1))),
        top: scaleY(getY(getDegrees(1)), 1),
    };

    const style2 = {
        left: scaleX(getX(getDegrees(2))),
        top: scaleY(getY(getDegrees(2)), 2),
    };

    const style3 = {
        left: scaleX(getX(getDegrees(3))),
        top: scaleY(getY(getDegrees(3)), 3),
    };

    const style4 = {
        left: scaleX(getX(getDegrees(4))),
        top: scaleY(getY(getDegrees(4)), 4),
    };

    const style5 = {
        left: scaleX(getX(getDegrees(5))),
        top: scaleY(getY(getDegrees(5)), 5),
    };

    const style6 = {
        left: scaleX(getX(getDegrees(6))),
        top: scaleY(getY(getDegrees(6)), 6),
    };

    const style7 = {
        left: scaleX(getX(getDegrees(7))),
        top: scaleY(getY(getDegrees(7)), 7),
    };

    const style8 = {
        left: scaleX(getX(getDegrees(8))),
        top: scaleY(getY(getDegrees(8)), 8),
    };

    const style9 = {
        left: scaleX(getX(getDegrees(9))),
        top: scaleY(getY(getDegrees(9)), 9),
    };

    const style10 = {
        left: scaleX(getX(getDegrees(10))),
        top: scaleY(getY(getDegrees(10)), 10),
    };

    const style11 = {
        left: scaleX(getX(getDegrees(11))),
        top: scaleY(getY(getDegrees(11)), 11),
    };

    const style12 = {
        left: scaleX(getX(getDegrees(12))),
        top: scaleY(getY(getDegrees(12)), 12),
    };

    const style13 = {
        left: scaleX(getX(getDegrees(13))),
        top: scaleY(getY(getDegrees(13)), 13),
    };

    const style14 = {
        left: scaleX(getX(getDegrees(14))),
        top: scaleY(getY(getDegrees(14)), 14),
    };

    const style15 = {
        left: scaleX(getX(getDegrees(15))),
        top: scaleY(getY(getDegrees(15)), 15),
    };

    const style16 = {
        left: scaleX(getX(getDegrees(16))),
        top: scaleY(getY(getDegrees(16)), 16),
    };

    const style17 = {
        left: scaleX(getX(getDegrees(17))),
        top: scaleY(getY(getDegrees(17)), 17),
    };

    const style18 = {
        left: scaleX(getX(getDegrees(18))),
        top: scaleY(getY(getDegrees(18)), 18),
    };

    const style19 = {
        left: scaleX(getX(getDegrees(19))),
        top: scaleY(getY(getDegrees(19)), 19),
    };

    const style20 = {
        left: scaleX(getX(getDegrees(20))),
        top: scaleY(getY(getDegrees(20)), 20),
    };

    const style21 = {
        left: scaleX(getX(getDegrees(21))),
        top: scaleY(getY(getDegrees(21)), 21),
    };

    const style22 = {
        left: scaleX(getX(getDegrees(22))),
        top: scaleY(getY(getDegrees(22)), 22),
    };

    // This function converts the x position from distance from the center of the circle to
    // distance from the top left corner of the div
    function scaleX(x) {
        // return x + radius;
        return x - vhToPixels(4);
    }

    // This function converts the y position from distance from the center of the circle to
    // distance from the top left corner of the div
    function scaleY(y, menuItem) {
        // return -y + radius;
        return -y - vhToPixels(4);
    }

    return (
        <>
            {/* This div centers the child-div which contains the bubble-circle */}
            <div
                style={{
                    // width: "65vw",

                    // The width of the right hand side is set to the width of the window minus the
                    // width of the sidebar.
                    width:
                        document.documentElement.clientWidth -
                        document.getElementById("nav-sidebar").clientWidth,

                    // backgroundColor: "purple",
                    display: "flex",
                    justifyContent: "center",
                }}
            >
                {/* This div positions the bubble-circle wihtin it.
                     however, in itself it does not cente the bubble-circle as a whold, which is why we need the parent div*/}
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        flexWrap: "wrap",
                        height: "60vh",
                        width: "78vh",
                        // backgroundColor: "green",
                        alignContent: "center",
                    }}
                >
                    {/* <TM1GeographyBubbles /> */}
                    <div
                        style={{
                            display: "block",
                            position: "relative",
                            borderRadius: "50%",
                            // backgroundColor: "blue",
                        }}
                    >
                        <div className="tm1-info-bubble">
                            {t2(bubbleInfoGeography, "varemaerke")}
                        </div>


                        <VMBubble1
                            Icon={MdVideoCameraBack}
                            caller="geography"
                            attributeName={"placeCinema"}
                            left={scaleX(getX(getDegrees(1)))}
                            top={scaleY(getY(getDegrees(1)), 1)}
                        />
                        <VMBubble1
                            Icon={MdShoppingBag}
                            caller="geography"
                            attributeName={"placeShop"}
                            left={scaleX(getX(getDegrees(2)))}
                            top={scaleY(getY(getDegrees(2)), 2)}
                        />
                        <VMBubble1
                            Icon={MdFitnessCenter}
                            caller="geography"
                            attributeName="placeFitnessCenter"
                            left={scaleX(getX(getDegrees(3)))}
                            top={scaleY(getY(getDegrees(3)), 3)}
                        />
                        <VMBubble1
                            attributeName="placeAmusementPark"
                            caller="geography"
                            Icon={GiBalloons}
                            left={scaleX(getX(getDegrees(4)))}
                            top={scaleY(getY(getDegrees(4)), 4)}
                        />
                        <VMBubble1
                            attributeName="placeAssociation"
                            caller="geography"
                            Icon={MdGroups2}
                            left={scaleX(getX(getDegrees(5)))}
                            top={scaleY(getY(getDegrees(5)), 5)}
                        />
                        <VMBubble1
                            attributeName="placeHotel"
                            caller="geography"
                            Icon={MdHotel}
                            text="Hotel"
                            left={scaleX(getX(getDegrees(6)))}
                            top={scaleY(getY(getDegrees(6)), 6)}
                        />
                        <VMBubble1
                            attributeName="placeClinic"
                            caller="geography"
                            text="Region"
                            Icon={BiClinic}
                            left={scaleX(getX(getDegrees(7)))}
                            top={scaleY(getY(getDegrees(7)), 7)}
                        />
                        <VMBubble1
                            attributeName="placeFoodstall"
                            caller="geography"
                            Icon={MdFastfood}
                            country="fi fi-dk"
                            left={scaleX(getX(getDegrees(8)))}
                            top={scaleY(getY(getDegrees(8)), 8)}
                        />

                        <VMBubble1
                            attributeName="placeExhibition"
                            caller="geography"
                            Icon={MdFilterCenterFocus}
                            country="fi fi-gl"
                            left={scaleX(getX(getDegrees(9)))}
                            top={scaleY(getY(getDegrees(9)), 9)}
                        />
                        <VMBubble1
                            attributeName="placeRestaurant"
                            caller="geography"
                            Icon={IoIosRestaurant}
                            country="fi fi-fo"
                            left={scaleX(getX(getDegrees(10)))}
                            top={scaleY(getY(getDegrees(10)), 10)}
                        />
                        <VMBubble1
                            attributeName="placeClub"
                            caller="geography"
                            Icon={FaGuitar}
                            left={scaleX(getX(getDegrees(11)))}
                            top={scaleY(getY(getDegrees(11)), 11)}
                        />
                        <VMBubble1
                            attributeName="placeDepartmentStore"
                            caller="geography"
                            Icon={MdStore}
                            left={scaleX(getX(getDegrees(12)))}
                            top={scaleY(getY(getDegrees(12)), 12)}
                        />
                        <VMBubble1
                            attributeName="placeSupermarket"
                            caller="geography"
                            Icon={MdLocalGroceryStore}
                            left={scaleX(getX(getDegrees(13)))}
                            top={scaleY(getY(getDegrees(13)), 13)}
                        />
                        <VMBubble1
                            attributeName="placeTown"
                            caller="geography"
                            Icon={MdLocationCity}
                            left={scaleX(getX(getDegrees(14)))}
                            top={scaleY(getY(getDegrees(14)), 14)}
                        />
                        <VMBubble1
                            attributeName="placeAbroadTowardDK"
                            caller="geography"
                            text="Toward DK"
                            left={scaleX(getX(getDegrees(15)))}
                            top={scaleY(getY(getDegrees(15)), 15)}
                        />
                        <VMBubble1
                            attributeName="placeAbroadNotTowardDK"
                            caller="geography"
                            text="Not toward DK"
                            left={scaleX(getX(getDegrees(16)))}
                            top={scaleY(getY(getDegrees(16)), 16)}
                        />
                        <VMBubble1
                            attributeName="placeWholeDenmark"
                            caller="geography"
                            text="DK"
                            left={scaleX(getX(getDegrees(17)))}
                            top={scaleY(getY(getDegrees(17)), 17)}
                        />
                        <VMBubble1
                            attributeName="placeRegion"
                            caller="geography"
                            text="Region"
                            left={scaleX(getX(getDegrees(18)))}
                            top={scaleY(getY(getDegrees(18)), 18)}
                        />
                        <VMBubble1
                            attributeName="placeGreenland"
                            caller="geography"
                            text="Greenland"
                            left={scaleX(getX(getDegrees(19)))}
                            top={scaleY(getY(getDegrees(19)), 19)}
                        />
                        <VMBubble1
                            attributeName="placeFaroeIslands"
                            caller="geography"
                            text="Faroe Islands"
                            left={scaleX(getX(getDegrees(20)))}
                            top={scaleY(getY(getDegrees(20)), 20)}
                        />
                        <VMBubble1
                            attributeName="placeOnline"
                            caller="geography"
                            text="WWW"
                            left={scaleX(getX(getDegrees(21)))}
                            top={scaleY(getY(getDegrees(21)), 21)}
                        />
                    </div>{" "}
                </div>
            </div>
        </>
    );
}

export default TM1GeographyBubbles;


// {!token &&
//     <div class="box arrow-left geography-bubbles-box1" >
//         {/* <div class="box arrow-left" style={{ position: "absolute", left: "5vw", bottom: "-33vh" }}> */}
//         {t2("demoGeography", "varemaerke")}
//     </div>
// }