// See https://www.robinwieruch.de/react-table-component/ for the tutorial for creating this type of tables
// https://www.robinwieruch.de/categories/react-table-library/
// samples: https://react-table-library.com/?path=/story/getting-started--demo
// https://github.com/table-library/react-table-library

// Tree tabl
// https://www.robinwieruch.de/react-tree-table/
// https://react-table-library.com/?path=/docs/features-tree--base

import React, { useEffect } from 'react';

import styles from '../../styles.module.css';

import {
    Table,
    Header,
    HeaderRow,
    HeaderCell,
    Body,
    Row,
    Cell,
} from '@table-library/react-table-library/table';

import {
    useRowSelect,
    CellSelect,
} from '@table-library/react-table-library/select';

import { Form, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { useSort } from '@table-library/react-table-library/sort';

import { SortToggleType } from '@table-library/react-table-library/sort';

import useReactTableTheme from '../../hooks/useReactTableTheme';

import { useTheme } from '@table-library/react-table-library/theme';
import defaultTableTheme from '../KnowledgeBrowser/DefaultTableTheme';

import { userCaseContext } from '../../contexts/UserCaseContext';
import { displayContext } from '../../../common/DisplayContext';
import { evaluationContext } from '../../contexts/EvaluationContext';
import { navigationContext } from '../../contexts/NavigationContext';

import { useContext, useState } from 'react';

function CaseTableTestNested() {
    const { userCase, setUserCase, setCaseStatus } =
        useContext(userCaseContext);

    const {
        setButtonAcceptDisabled,
        setButtonLetterDisabled,
        setButtonRejectDisabled,
        setDisplayMessage,
        setUseMode,
        t,
        code,
        setCode,
        uiModeMCAddNode,
    } = useContext(displayContext);

    const { page } = useContext(navigationContext);

    const { setCurrentCaseId, conclusionLastTrue } =
        useContext(evaluationContext);

    const [{ defaultTheme }] = useReactTableTheme();

    // const [nameFilterValue, setNameFilterValue] = useState('');
    // const [displayNameFilterValue, setDisplayNameFilterValue] = useState('');

    const [nameFilter, setNameFilter] = useState('');
    const [descriptionFilter, setDescriptionFilter] = useState('');

    // Determine the CSS class based on the page variable
    const backgroundColorClass =
        page === 'addNodeMC'
            ? 'evaluateCaseCaseTableWrapperDimmed'
            : 'evaluateCaseCaseTableWrapper';

    // defaultTableTheme.Table = `
    //     height: 100%;
    //     align-content: baseline;
    //     --data-table-library_grid-template-columns: 15% 40% 15% 15% 15%;
    // `;

    // defaultTableTheme.Cell = `
    //     padding-left: 10px !important;
    //     text-align: left;
    //     background-color: var(--case-table-background-color-dimmed) !important;
    // `;

    // defaultTableTheme.Row = `
    //     background-color: #C7C7C7 !important;
    // `;

    // defaultTableTheme.HeaderCell = `
    //     background-color: var(--case-table-background-color-dimmed) !important;
    // `;

    const defaultTableTheme = {
        Table: `
            height: 100%; 
            align-content: baseline; 
            --data-table-library_grid-template-columns: 15% 40% 15% 15% 15%;
            border-radius: 10px;
            font-weight: 100;
            font-size: 14px;
        `,

        Cell: `
            padding-left: 10px !important;
            text-align: left; 
        `,

        Row: `
            /* No direct background-color here; use CSS class for styling */
        `,

        HeaderCell: `
            /* No direct background-color here; use CSS class for styling */
        `,
    };

    const theme = useTheme(defaultTableTheme);

    // const theme = useTheme({
    //     Table: `
    //   height: 100%;
    //   align-content: baseline;
    //   --data-table-library_grid-template-columns:  15% 15% 40% 15% 15%;
    // `,
    //     BaseRow: `
    //   font-size: var(--font-size5);
    // `,
    //     HeaderRow: `
    //   font-size: var(--font-size5);

    //   .th {
    // 	font-weight: normal !important;
    // 	border-width: 0px;
    // 	border-bottom: 0px;
    // 	box-shadow: 0px 0px 0px 0px rgba(0,0,0,0)
    //   }
    // `,
    //     Row: `
    //   &:nth-child(odd) {
    //     background-color: #C7C7C7;
    //   }

    //   &:nth-child(even) {
    //     background-color: #F2F2F2;
    //   }

    //   cursor: pointer;

    //   &:hover .td {
    // 	background-color: yellow;
    //   }

    // `,
    //     HeaderCell: `
    // 	padding-left: 10px !important;
    // 	`,
    //     Cell: `
    //   padding-left: 10px !important;
    //   text-align: left;
    // `,
    //     BaseCell: `
    //     padding-top: 4px !important;
    //     padding-bottom: 4px !important;
    //     `,
    // });

    let filter = ['class', 'b1s1'];

    function showRow(row) {
        // Here we need to apply the rules loaded with each attribute from the database...
        return !filter.includes(row.name);
    }

    let userCaseFiltered = userCase.filter(
        (el) => el.name != 'class' && el.name != 'b1s1',
    );

    const [data, setData] = useState({ nodes: userCase });

    const select = useRowSelect(data, { onChange: onSelectChange });

    function onSelectChange(action, state) {
        console.log('CaseTableTestNested - state: ', state);
        setCode(code + state.id);
        // Resetting state.id, so that we do not select/unselect, i.e. have to click
        // two times to get the attribute name again. By resetting, we get the variable
        // name each time we click it.
        state.id = null;
    }

    useEffect(() => {
        userCaseFiltered = userCase.filter(showRow);
        setData({ nodes: userCaseFiltered });
        // When the user sets a value, the userCase is changed and this useEffect fires. That means that 'data', i.e. the data for the table is
        // reset to the full userCase, and any filter settings would be cancelled. Therefore we run the filter again with the latest filter values.
        // If we dont do this, then every time the user enters a value in a filtered table, then the table will immediately expand to show all items.
        // handleNameFilter(nameFilterValue);
        // handleDisplayNameFilter(displayNameFilterValue);
        // reapplyFilters();
    }, [userCase]);

    useEffect(() => {
        const filterByName = (item) => {
            return nameFilter
                ? item.name.toLowerCase().includes(nameFilter.toLowerCase())
                : true;
        };

        const filterByDescription = (item) => {
            return descriptionFilter
                ? item.displayName
                      .toLowerCase()
                      .includes(descriptionFilter.toLowerCase())
                : true;
        };

        const filteredNodes = userCase.filter(
            (item) =>
                item.name !== 'class' &&
                item.name !== 'b1s1' &&
                filterByName(item) &&
                filterByDescription(item),
        );

        setData({ nodes: filteredNodes });
    }, [nameFilter, descriptionFilter, userCase]);

    // const handleNameFilterChange = (event) => {
    //     setNameFilter(event.target.value);
    // };

    function handleNameFilterChange(event) {
        setNameFilter(event.target.value);
    }

    function handleDescriptionFilterChange(event) {
        setDescriptionFilter(event.target.value);
    }

    // const filterByName = (item) => {
    //     return nameFilter
    //         ? item.name.toLowerCase().includes(nameFilter.toLowerCase())
    //         : true;
    // };

    // const filterByDescription = (item) => {
    //     return descriptionFilter
    //         ? item.displayName
    //               .toLowerCase()
    //               .includes(descriptionFilter.toLowerCase())
    //         : true;
    // };

    const sort = useSort(
        data,
        {
            onChange: onSortChange,
        },
        {
            sortToggleType: SortToggleType.AlternateWithReset,
            sortFns: {
                Name: (array) =>
                    array.sort((a, b) => a.name.localeCompare(b.name)),
                DisplayName: (array) =>
                    array.sort((a, b) =>
                        a.displayName.localeCompare(b.displayName),
                    ),
                Test: (array) =>
                    array.sort((a, b) => a.test.localeCompare(b.test)),
            },
        },
    );

    function onSortChange(action, state) {
        console.log(action, state);
    }

    const onChangeText = (e) => {
        e.persist();

        setUseMode('read');
        setCurrentCaseId(null);

        setButtonLetterDisabled(true);
        setButtonAcceptDisabled(true);
        setButtonRejectDisabled(true);
        setDisplayMessage('');

        setUserCase((old) =>
            old.map((node) => {
                if (node.name === e.target.name) {
                    return { ...node, ['test']: e.target.value };
                } else {
                    return node;
                }
            }),
        );
    };

    const handleChange = (e) => {
        e.persist();

        // console.log(
        //     "CaseTableTestNested - handleChange - e.target.name: ",
        //     e.target.name
        // );
        // update the variable 'filter' above by applying the rulefor this row
        // for example if the rule is: show factor 'b1s3' if the value of this factor ('b1s2') is true
        // ... no, instead: for each attribute in the database it is specified if it depends on another
        // attribute and with which rule, e.g. for b1s1:
        //  - depends on: b1s2
        //  - dependence rule: true
        // which means that b1s1 should only be shown if b1s2 is true.

        setUseMode('read');
        setCaseStatus(t('na'));
        setCurrentCaseId(null);

        setButtonLetterDisabled(true);
        setButtonAcceptDisabled(true);
        setButtonRejectDisabled(true);
        setDisplayMessage('');

        setUserCase((old) =>
            old.map((node) => {
                if (node.name === e.target.name) {
                    return { ...node, ['test']: e.target.value };
                } else {
                    return node;
                }
            }),
        );
    };

    const handleClick = (e) => {
        e.persist();

        setCaseStatus(t('na'));
        setCurrentCaseId(null);

        setButtonRejectDisabled(true);
        setDisplayMessage('');

        setUserCase((old) =>
            old.map((node) => {
                if (node.name === e.target.name) {
                    return { ...node, ['test']: 0 };
                } else {
                    return node;
                }
            }),
        );
    };

    const style1 =
        page == 'addNodeMC'
            ? {
                  fontWeight: 100,
                  fontSize: 'large',
                  backgroundColor: 'lightgrey',
              }
            : {
                  fontWeight: 100,
                  fontSize: 'large',
                  backgroundColor: '#FFFFFF',
              };

    const style2 =
        page == 'addNodeMC'
            ? {
                  fontWeight: 100,
                  fontSize: 'large',
                  backgroundColor: 'lightgrey',
                  textAlign: 'center',
                  marginBottom: '0px',
              }
            : {
                  fontWeight: 100,
                  fontSize: 'large',
                  backgroundColor: '#FFFFFF',
                  textAlign: 'center',
                  marginBottom: '0px',
              };

    const radioStyle = {
        marginLeft: '5px',
        marginRight: '5px',
        accentColor: page == 'addNodeMC' ? 'green' : 'blue', // Use green when disabled, otherwise blue
    };

    return (
        <div>
            <div
                className={
                    page == 'addNodeMC'
                        ? styles.divCaseTableMCAddNode
                        : styles.divCaseTable
                }
            >
                {/* <Table data={data} theme={defaultTheme} sort={sort} select={select}> */}
                <Table
                    data={data}
                    theme={theme}
                    sort={sort}
                    select={select}
                    layout={{ custom: true }}
                >
                    {(tableList) => (
                        <>
                            <Header>
                                <HeaderRow
                                    className={
                                        page === 'addNodeMC'
                                            ? styles.evaluateCaseCaseTableWrapperDimmed
                                            : styles.evaluateCaseCaseTableWrapper
                                    }
                                >
                                    <HeaderCell style={style1}>
                                        {t('name')}
                                        <br />
                                        <input
                                            className={styles.caseTableFilter}
                                            type="text"
                                            placeholder="Filter..."
                                            onChange={handleNameFilterChange}
                                        />
                                    </HeaderCell>

                                    <HeaderCell style={style1}>
                                        {t('description')}
                                        <br />
                                        <input
                                            className={styles.caseTableFilter}
                                            type="text"
                                            placeholder="Filter..."
                                            onChange={
                                                handleDescriptionFilterChange
                                            }
                                        />
                                    </HeaderCell>

                                    <HeaderCell style={style1}></HeaderCell>

                                    <HeaderCell style={style1}>
                                        {' '}
                                        {t('referenceCase')}
                                    </HeaderCell>

                                    <HeaderCell style={style1}>
                                        {' '}
                                        {t('referenceMatch')}
                                    </HeaderCell>
                                </HeaderRow>
                            </Header>

                            <Body>
                                {tableList.map((item) => (
                                    <Row key={item.id} item={item}>
                                        <Cell item={item} style={style1}>
                                            {item.name}
                                        </Cell>
                                        <Cell style={style1}>
                                            <div
                                                className={
                                                    styles.attributeDisplayName
                                                }
                                            >
                                                {item.displayName}
                                            </div>
                                        </Cell>

                                        {/* Input column */}
                                        {/* item.type can be integer, text, check */}
                                        {/* <Cell
                                            style={{
                                                textAlign: 'center',
                                                marginBottom: '0px',
                                            }}
                                        > */}
                                        <Cell style={style2}>
                                            {item.type === 'check' && (
                                                <div>
                                                    <form
                                                        onChange={handleChange}
                                                        onClick={handleClick}
                                                    >
                                                        <input
                                                            style={{
                                                                marginLeft:
                                                                    '5px',
                                                                marginRight:
                                                                    '5px',
                                                            }}
                                                            type="radio"
                                                            id="yes"
                                                            value="1"
                                                            checked={
                                                                item.test == 1
                                                            }
                                                            name={item.name}
                                                            disabled={
                                                                page ==
                                                                'addNodeMC'
                                                            }
                                                        />
                                                        <label
                                                            for="yes"
                                                            className={
                                                                item.test == 1
                                                                    ? styles.radioButtonHighlight
                                                                    : styles.RadioButtonDefault
                                                            }
                                                        >
                                                            {t('yes')}
                                                        </label>
                                                        <input
                                                            // style={{
                                                            //     marginLeft:
                                                            //         '5px',
                                                            //     marginRight:
                                                            //         '5px',
                                                            //     accentColor:
                                                            //         'green',
                                                            // }}

                                                            type="radio"
                                                            id="no"
                                                            value="2"
                                                            checked={
                                                                item.test == 2
                                                            }
                                                            name={item.name}
                                                            disabled={
                                                                page ==
                                                                'addNodeMC'
                                                            }
                                                        />
                                                        <label
                                                            for="no"
                                                            className={
                                                                item.test == 2
                                                                    ? styles.radioButtonHighlight
                                                                    : styles.RadioButtonDefault
                                                            }
                                                        >
                                                            {t('no')}
                                                        </label>
                                                    </form>
                                                </div>
                                            )}

                                            {(item.type === 'integer' ||
                                                item.type === 'real' ||
                                                item.type === 'text') && (
                                                // ...on other types return a text-field

                                                <input
                                                    value={item.test}
                                                    onChange={onChangeText}
                                                    className={
                                                        styles.editableCell
                                                    }
                                                    name={item.name}
                                                    style={{
                                                        textAlign: 'center',
                                                        width: '40%',
                                                    }}
                                                    disabled={
                                                        page == 'addNodeMC'
                                                    }
                                                />
                                            )}
                                        </Cell>

                                        <Cell style={style1}>
                                            {item.type === 'check' &&
                                                item.referenceValue === 'yes' &&
                                                t('yes')}
                                            {item.type === 'check' &&
                                                item.referenceValue === 'no' &&
                                                t('no')}
                                            {item.type !== 'check' &&
                                                item.referenceValue}
                                        </Cell>

                                        {/* Mismatch indicator */}
                                        <Cell style={style2}>
                                            {/* For Checkboxes */}
                                            {/* They have the same value, whether both are empty or both are not empty */}
                                            {conclusionLastTrue != '' &&
                                                item.type === 'check' &&
                                                ((item.referenceValue == 'no' &&
                                                    item.test == 2) ||
                                                    (item.referenceValue ==
                                                        'yes' &&
                                                        item.test == 1) ||
                                                    ((typeof item.referenceValue ==
                                                        undefined ||
                                                        item.referenceValue ==
                                                            null ||
                                                        item.referenceValue ==
                                                            '') &&
                                                        (typeof item.test ==
                                                            undefined ||
                                                            item.test == null ||
                                                            item.test == '')))}

                                            {/* One and only one of them is empty */}
                                            {conclusionLastTrue != '' &&
                                                item.type === 'check' &&
                                                // reference is empty and test is not empty
                                                (((typeof item.referenceValue ==
                                                    undefined ||
                                                    item.referenceValue ==
                                                        null ||
                                                    item.referenceValue == '' ||
                                                    item.referenceValue ==
                                                        'unknown') &&
                                                    !(
                                                        typeof item.test ==
                                                            undefined ||
                                                        item.test == null ||
                                                        item.test == ''
                                                    )) ||
                                                    // reference is not empty and test is empty
                                                    (!(
                                                        typeof item.referenceValue ==
                                                            undefined ||
                                                        item.referenceValue ==
                                                            null ||
                                                        item.referenceValue ==
                                                            '' ||
                                                        item.referenceValue ==
                                                            'unknown'
                                                    ) &&
                                                        (typeof item.test ==
                                                            undefined ||
                                                            item.test == null ||
                                                            item.test ==
                                                                ''))) && (
                                                    <div
                                                        className={
                                                            styles.refMatchOneEmpty
                                                        }
                                                    ></div>
                                                )}

                                            {/* None of them is empty and they are different */}
                                            {conclusionLastTrue != '' &&
                                                item.type === 'check' &&
                                                item.referenceValue != null &&
                                                item.test != '' &&
                                                ((item.referenceValue == 'no' &&
                                                    item.test == 1) ||
                                                    (item.referenceValue ==
                                                        'yes' &&
                                                        item.test == 2)) && (
                                                    <div
                                                        className={
                                                            styles.refMatchDifferent
                                                        }
                                                    ></div>
                                                )}

                                            {/* For Text, Integer and Real */}
                                            {/* They have the same value, whether both are empty or both are not empty */}
                                            {conclusionLastTrue != '' &&
                                                (item.type === 'text' ||
                                                    item.type === 'integer' ||
                                                    item.type === 'real') &&
                                                (item.referenceValue ==
                                                    item.test ||
                                                    ((typeof item.referenceValue ==
                                                        undefined ||
                                                        item.referenceValue ==
                                                            null ||
                                                        item.referenceValue ==
                                                            '') &&
                                                        (typeof item.test ==
                                                            undefined ||
                                                            item.test == null ||
                                                            item.test == '')))}

                                            {/* One and only one of them is empty */}
                                            {conclusionLastTrue != '' &&
                                                (item.type === 'text' ||
                                                    item.type === 'integer' ||
                                                    item.type === 'real') &&
                                                // reference is empty and test is not empty
                                                (((typeof item.referenceValue ==
                                                    undefined ||
                                                    item.referenceValue ==
                                                        null ||
                                                    (typeof item.referenceValue ==
                                                        'string' &&
                                                        item.referenceValue ==
                                                            '')) &&
                                                    !(
                                                        typeof item.test ==
                                                            undefined ||
                                                        item.test == null ||
                                                        item.test == ''
                                                    )) ||
                                                    // reference is not empty and test is empty
                                                    (!(
                                                        typeof item.referenceValue ==
                                                            undefined ||
                                                        item.referenceValue ==
                                                            null ||
                                                        (typeof item.referenceValue ==
                                                            'string' &&
                                                            item.referenceValue ==
                                                                '')
                                                    ) &&
                                                        (typeof item.test ==
                                                            undefined ||
                                                            item.test == null ||
                                                            item.test ==
                                                                ''))) && (
                                                    <div
                                                        className={
                                                            styles.refMatchOneEmpty
                                                        }
                                                    ></div>
                                                )}

                                            {/* None of them is empty and they are different */}
                                            {conclusionLastTrue != '' &&
                                                (item.type === 'text' ||
                                                    item.type === 'integer' ||
                                                    item.type === 'real') &&
                                                item.referenceValue != null &&
                                                item.test != '' &&
                                                item.referenceValue !=
                                                    item.test && (
                                                    <div
                                                        className={
                                                            styles.refMatchDifferent
                                                        }
                                                    ></div>
                                                )}
                                        </Cell>
                                    </Row>
                                ))}
                            </Body>
                        </>
                    )}
                </Table>
            </div>
        </div>
    );
}

export default CaseTableTestNested;
