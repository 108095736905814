import React, { useState, useContext, useEffect } from 'react';
import '../test.css'; // Create this CSS file

import trademarkStart from '../images/trademark-start.png'

import { useNavigate } from "react-router-dom";
// import useEvaluate from '../../commonTrademark/hooks/useEvaluate';
import useEvaluate from '../hooks/useEvaluate';

import { displayContext } from '../../common/DisplayContext';
import { swiperContext } from '../contexts/SwiperContext';

import swiperball from '../images/swiperball.png'
import thumbsUp from '../images/thumbs-up1.png'
import { Spinner } from 'react-bootstrap';

import Linkify from 'linkify-react';

function TrademarkResult() {

    let navigate = useNavigate();

    const {
        useEvaluationResult,
        versionEvaluationResult,
        placeEvaluationResult,
        contextEvaluationResult,
        continuationEvaluationResult,
        selectedOptions,
        optionDescriptions,
        processing,
    } = useContext(swiperContext);

    const {
        t2,
    } = useContext(displayContext);

    const [infoButton, setInfoButton] = useState(false);

    const [showConclusion, setShowConclusion] = useState(false);

    function toggleInfoButton() {
        setInfoButton(!infoButton)
    }

    const [{ handleEvaluate }] = useEvaluate();

    useEffect(() => {
        setShowConclusion(false)
        Promise.all([
            handleEvaluate('trademark', 'use'),
            handleEvaluate('trademark', 'version'),
            handleEvaluate('trademark', 'continuation'),
            handleEvaluate('trademark', 'place'),
            handleEvaluate('trademark', 'context'),
        ]).then(([one, two, three, four, five]) => {
            setShowConclusion(true)
        })
    }, []);

    return (
        <div className='container'>
            <div className='top-bar'></div>

            <div className='boxlegal-1'>
                <h2 className='app-title'>legal</h2>
            </div>

            <div className={showConclusion ? 'mobile-show-conclusion' : 'mobile-hide-conclusion'}>

                {useEvaluationResult.conclusionLastTrue == 'acquired' &&
                    versionEvaluationResult.conclusionLastTrue == 'acquired' &&
                    placeEvaluationResult.conclusionLastTrue == 'acquired' &&
                    contextEvaluationResult.conclusionLastTrue == 'acquired' &&
                    continuationEvaluationResult.conclusionLastTrue == 'acquired' ?
                    <div className='boxlegal-3 page-description'>
                        Du har ved brug stiftet en varemærkeret til dit kendetegn
                    </div>
                    :
                    <div className='boxlegal-3 page-description'>
                        Du har ikke stiftet en varemærkeret til dit kendetegn ved brug
                    </div>
                }

                {useEvaluationResult.explanationLastTrue != '' ?

                    <div style={{ marginTop: "1vh" }} className={
                        useEvaluationResult.conclusionLastTrue == 'acquired' ?
                            'mobile-explanation-box mobile-explanation-approved'
                            : 'mobile-explanation-box mobile-explanation-rejected'
                    }
                    >
                        <Linkify
                            componentDecorator={(
                                decoratedHref,
                                decoratedText,
                                key
                            ) => (
                                <a
                                    target="blank"
                                    href={decoratedHref}
                                    key={key}
                                >
                                    {t2("labelSeeDecision", "trademark")}
                                </a>
                            )}
                        >
                            {t2(useEvaluationResult.explanationLastTrue, "trademark")}
                        </Linkify>
                    </div>
                    : ''
                }

                {versionEvaluationResult.explanationLastTrue != '' ?

                    <div className={
                        versionEvaluationResult.conclusionLastTrue == 'acquired' ?
                            'mobile-explanation-box mobile-explanation-approved'
                            : 'mobile-explanation-box mobile-explanation-rejected'
                    }
                    >
                        <Linkify
                            componentDecorator={(
                                decoratedHref,
                                decoratedText,
                                key
                            ) => (
                                <a
                                    target="blank"
                                    href={decoratedHref}
                                    key={key}
                                >
                                    {t2("labelSeeDecision", "trademark")}
                                </a>
                            )}
                        >
                            {t2(versionEvaluationResult.explanationLastTrue, "trademark")}
                        </Linkify>
                    </div>
                    : ''
                }

                {continuationEvaluationResult.explanationLastTrue != '' ?

                    <div className={
                        continuationEvaluationResult.conclusionLastTrue == 'acquired' ?
                            'mobile-explanation-box mobile-explanation-approved'
                            : 'mobile-explanation-box mobile-explanation-rejected'
                    }
                    >
                        <Linkify
                            componentDecorator={(
                                decoratedHref,
                                decoratedText,
                                key
                            ) => (
                                <a
                                    target="blank"
                                    href={decoratedHref}
                                    key={key}
                                >
                                    {t2("labelSeeDecision", "trademark")}
                                </a>
                            )}
                        >
                            {t2(continuationEvaluationResult.explanationLastTrue, "trademark")}
                        </Linkify>
                    </div>
                    : ''
                }

                {placeEvaluationResult.explanationLastTrue != '' ?
                    <div className={
                        placeEvaluationResult.conclusionLastTrue == 'acquired' ?
                            'mobile-explanation-box mobile-explanation-approved'
                            : 'mobile-explanation-box mobile-explanation-rejected'
                    }
                    >

                        <Linkify
                            componentDecorator={(
                                decoratedHref,
                                decoratedText,
                                key
                            ) => (
                                <a
                                    target="blank"
                                    href={decoratedHref}
                                    key={key}
                                >
                                    {t2("labelSeeDecision", "trademark")}
                                </a>
                            )}
                        >
                            {t2(placeEvaluationResult.explanationLastTrue, "trademark")}
                        </Linkify>
                    </div>
                    : ''
                }

                {contextEvaluationResult.explanationLastTrue != '' ?
                    <div className={
                        contextEvaluationResult.conclusionLastTrue == 'acquired' ?
                            'mobile-explanation-box mobile-explanation-approved'
                            : 'mobile-explanation-box mobile-explanation-rejected'
                    }
                    >
                        <Linkify
                            componentDecorator={(
                                decoratedHref,
                                decoratedText,
                                key
                            ) => (
                                <a
                                    target="blank"
                                    href={decoratedHref}
                                    key={key}
                                >
                                    {t2("labelSeeDecision", "trademark")}
                                </a>
                            )}
                        >
                            {t2(contextEvaluationResult.explanationLastTrue, "trademark")}
                        </Linkify>
                    </div>
                    : ''
                }

            </div>

            {/* {processing && */}
            <div style={{ display: !showConclusion ? 'flex' : 'none' }} className='desktop-spinner-container'>
                <div className='desktop-spinner'>
                    <Spinner
                        animation="border"
                        variant="secondary"
                    />
                </div>
            </div>
            {/* } */}

            <div className='info-button-overlay' onClick={toggleInfoButton}></div>
        </div>
    )
};

export default TrademarkResult;
