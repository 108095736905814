import React, { useContext, useEffect } from "react";

import { domainContext } from "../contexts/DomainContext";
import { userCaseContext } from "../contexts/UserCaseContext";
import { displayContext } from "../../common/DisplayContext";
import { evaluationContext } from "../contexts/EvaluationContext";
import { errorContext } from "../contexts/ErrorContext";

const useNewNodeFields = () => {
    const {
        setNewConclusion,
        setNewExplanation,
        setNewNote,

        setNewRule,
        setCode,

        setButtonSaveNewRuleDisabled,
        setButtonLetterDisabled,
        setButtonAcceptDisabled,
        setButtonCancelDisabled,

        setTextAreaNewConclusionDisabled,
        setTextAreaNewExplanationDisabled,
        setTextAreaNewRuleDisabled,
        setTextAreaNewNoteDisabled,
    } = useContext(displayContext);

    function clearNewNodeFields() {
        // Disable the fields used to enter conclusion, explanation, rule and note for when defining a new node
        setNewConclusion("");
        setNewExplanation("");
        setNewRule("");
        setCode("");
        setNewNote("");
    }

    function disableNewNodeFields() {
        // Disable the fields used to enter conclusion, explanation, rule and note for when defining a new node
        setTextAreaNewConclusionDisabled(true);
        setTextAreaNewExplanationDisabled(true);
        setTextAreaNewRuleDisabled(true);
        setTextAreaNewNoteDisabled(true);

        // Disable the buttons related to defining a new node
        setButtonSaveNewRuleDisabled(true);
        setButtonLetterDisabled(true);
        setButtonAcceptDisabled(true);
        setButtonCancelDisabled(true);
    }

    function enableNewNodeFields() {
        // Disable the fields used to enter conclusion, explanation, rule and note for when defining a new node
        setTextAreaNewConclusionDisabled(false);
        setTextAreaNewExplanationDisabled(false);
        setTextAreaNewRuleDisabled(false);
        setTextAreaNewNoteDisabled(false);

        // Disable the buttons related to defining a new node
        setButtonSaveNewRuleDisabled(false);
        setButtonLetterDisabled(false);
        setButtonAcceptDisabled(false);
        setButtonCancelDisabled(false);
    }

    return [
        {
            clearNewNodeFields,
            disableNewNodeFields,
            enableNewNodeFields,
        },
    ];
};

export default useNewNodeFields;
