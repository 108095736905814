import React, { useContext } from 'react';

import { displayContext } from '../../../common/DisplayContext';
import { errorContext } from '../../contexts/ErrorContext';

import styles from '../../styles.module.css';

function NewNote() {
    const { newNote, setNewNote } = useContext(displayContext);

    const { errors } = useContext(errorContext);

    function handleNoteChange(e) {
        setNewNote(e.target.value);
    }

    return (
        <>
            <textarea
                name="newNoteMC"
                // className="NewNote"
                type="text"
                value={newNote}
                onChange={(e) => handleNoteChange(e)}
                // isInvalid={!!errors.NewNote}
            />

            {errors.newConclusion && (
                <div
                    style={{
                        width: '400px',
                        padding: '10px',
                    }}
                >
                    <a type="text" placeholder="You must enter a note" />
                </div>
            )}
        </>
    );
}

export default NewNote;
